import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "antd";
import SmartInboxMenu from "./menu/SmartInboxMenu";
import SmartInboxHeader from "./header/SmartInboxHeader";
import { CommentsGridContainer } from "@containers/Comments/Comments";
import QuickAutomationMenu from "../_common/quickAutomation/QuickAutomationMenu";
import AutomatedHidingMenu from "../_common/automatedHidingMenu/AutomatedHidingMenu";
import { AlertDanger, InboxEmptyState } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SmartInbox.module.less";

import { getInboxRepliesAction } from "@store/comments/actions";
import { getInboxStatsAction } from "@store/inboxStats/actions";

import { filterDataSelector } from "@store/filters/selectors";
import { inboxStatsSelector } from "@store/inboxStats/selectors";
import { assignToMeLoadingSelector } from "@store/assign/selectors";
import { meSelector, meSuccessfulSelector } from "@store/me/selectors";

import { ISmartInboxType } from "./types";
import { useInbox } from "@utils/useInbox";
import { useAppFilters } from "@utils/appFilters";
import { IInboxStats, getDefaultSortOptions } from "@bbdevcrew/bb_ui_kit_fe";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { IGetInboxRepliesPayload, IPostCommentBody } from "@store/comments/types";
import { getSmartInboxErrorOptions, getSortOptions, isSafetyFullyManaged } from "./helpers";

import { AlertTriangleFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const defaultSmartInboxTab = "review";

const SmartInbox = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { isBatchModeActive } = useBatchMode();
  const { updateSearchDataAndUrl } = useAppFilters();

  const queryParamsTab = searchParams.get("tab");

  const [sortValue, setSortValue] = useState<string | undefined>(
    getSortOptions().find(item => item.id === "desc|ai_highlights_score")?.id,
  );
  const { statTabValue, setStatTabValue, onTabChange, assignToMeHandler } = useInbox(
    defaultSmartInboxTab,
    sortValue,
  );
  const [showErrorDisclaimer, setShowErrorDisclaimer] = useState(false);
  const [isTabToggled, setIsTabToggled] = useState(false);

  const me = useSelector(meSelector);
  const filters = useSelector(filterDataSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const inboxStats = useSelector(inboxStatsSelector);
  const assignToMeLoading = useSelector(assignToMeLoadingSelector);

  const fetchReplies = useCallback(
    (body: IPostCommentBody | IGetInboxRepliesPayload) => {
      const sortVals = body.sort?.split("|");
      return getInboxRepliesAction({
        ...body,
        sort: sortVals && sortVals[0],
        sort_field: sortVals && sortVals[1],
        list_type: body.isReplyModal ? "all" : statTabValue,
      });
    },
    [statTabValue],
  );

  useEffect(() => {
    setShowErrorDisclaimer(!!inboxStats?.stats.errors);
  }, [inboxStats]);

  useEffect(() => {
    if (statTabValue === "ai_highlights") {
      setSortValue(getSortOptions().find(item => item.id === "desc|ai_highlights_score")?.id);
    } else {
      setSortValue(getDefaultSortOptions(t).find(item => item.value === "desc")?.value);
    }
  }, [statTabValue, t]);

  useEffect(() => {
    setStatTabValue((queryParamsTab || defaultSmartInboxTab) as keyof IInboxStats);
  }, [queryParamsTab, setStatTabValue]);

  useEffect(() => {
    dispatch(getInboxStatsAction(filters || {}));
  }, [dispatch, filters]);

  const onCloseErrorDisclaimer = () => setShowErrorDisclaimer(false);

  const onFilterAuthorId = (authorIds: string[]) =>
    updateSearchDataAndUrl({ author_ids: authorIds }, "id");

  const handleTabChange = (tab: keyof IInboxStats) => {
    setIsTabToggled(prev => !prev);
    onTabChange(tab);
    if (tab === "assigned_to_me") {
      dispatch(getInboxStatsAction(filters || {}));
    }
  };

  return meFetched && filters ? (
    <div className={s.bbInbox} data-cy="inbox-container">
      <Row gutter={30} className={s.bbInboxContentRow}>
        <Col
          xs={24}
          xl={6}
          xxl={6}
          className={classNames(s.bbInboxContentCol, {
            [s.bbInboxBatchMode]: isBatchModeActive,
          })}
        >
          <SmartInboxMenu
            activeTab={statTabValue}
            onTabChange={key => handleTabChange(key as keyof IInboxStats)}
          />
          {!!inboxStats?.stats.errors && (
            <SmartInboxMenu
              activeTab={statTabValue}
              onTabChange={key => handleTabChange(key as keyof IInboxStats)}
              getInboxOptions={getSmartInboxErrorOptions}
            />
          )}
          {!isSafetyFullyManaged(me) && <AutomatedHidingMenu />}
          <QuickAutomationMenu />
        </Col>
        <div className={s.bbInboxContentWrapper}>
          <SmartInboxHeader
            sortValue={sortValue}
            inboxType={statTabValue as ISmartInboxType}
            onSortChange={val => setSortValue(val)}
            onClickAssignToMe={assignToMeHandler}
          />
          {showErrorDisclaimer && statTabValue === "errors" && (
            <div data-stonly-target={"app-global__inbox-errors-tab--error-disclaimer-box"}>
              <AlertDanger
                size="large"
                align="center"
                className={s.bbErrorDisclaimer}
                onClose={onCloseErrorDisclaimer}
                message={
                  <div className={s.bbErrorDisclaimerMessage}>
                    {t("components:errorsTabDisclaimer1")}
                    <span className={s.bbErrorDisclaimerBox}>
                      <AlertTriangleFilledIcon />
                    </span>
                    {t("components:errorsTabDisclaimer2")}
                  </div>
                }
              />
            </div>
          )}
          <div className={s.bbInboxCommentsGrid}>
            <CommentsGridContainer
              showStarAction
              showArchiveAction
              showHideAction
              showDeleteAction
              showLikeAction
              showDetailsAction
              showLinkedInReactionAction
              withPostPreview
              filters={filters}
              sortValue={sortValue}
              providedFetchAction={fetchReplies}
              key={`comment-grid-${statTabValue}-${isTabToggled}`}
              providedFilterAuthorIdAction={(authorIds: string[]) => onFilterAuthorId(authorIds)}
              EmptyState={
                <InboxEmptyState
                  tab={statTabValue || defaultSmartInboxTab}
                  assignToMeLoading={assignToMeLoading}
                  stats={inboxStats?.stats || {}}
                  onAssignClick={assignToMeHandler}
                />
              }
            />
          </div>
        </div>
      </Row>
    </div>
  ) : null;
};
export default SmartInbox;
