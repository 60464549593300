import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import { Form } from "antd";
import { Button, Modal, addToast } from "@bbdevcrew/bb_ui_kit_fe";
import ZendeskTicketForm from "@components/_common/MessageCard/createZendeskTicketModal/ZendeskTicketForm"; // eslint-disable-line max-len

import { createdTicketSelector, creatingTicketSelector } from "@store/integrations/selectors";
import { meSelector } from "@store/me/selectors";

import { clearTicketStateAction, createTicketAction } from "@store/integrations/actions";

import s from "./CreateZendeskTicketModal.module.less";

import { ICreateTicketPayload } from "@store/integrations/types";
import { CreateZendeskTicketModalProps } from "./CreateZendeskTicketModal.type";

const CreateZendeskTicketModal = ({ isOpen, comment, onClose }: CreateZendeskTicketModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<ICreateTicketPayload>();

  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const me = useSelector(meSelector);
  const isSubmitting = useSelector(creatingTicketSelector);
  const createdTicket = useSelector(createdTicketSelector);

  const { comment_phrase, message, platform, post, id } = comment;
  const formattedNowDate = dayjs().format("DD MMM, YYYY");
  const formattedNowTime = dayjs().format("hh:mm A");
  const description = `${comment.username} ${formattedNowDate} at ${formattedNowTime} (${me?.timezone})\n${comment_phrase?.replaceAll(/\{|\}/g, "")}, ${platform.name}\nURL: ${post?.permalink}\n\n${message}`; // eslint-disable-line max-len
  const onValuesChange = () => {
    const fieldsErrors = form.getFieldsError();
    const mappedErrors = fieldsErrors.reduce(
      (accErrors, error) => ({
        ...accErrors,
        ...(!!error.errors[0] && { [error.name[0]]: error.errors[0] }),
      }),
      {},
    );

    setErrors(mappedErrors);
  };
  const onSubmit = (data: ICreateTicketPayload) => {
    const requester = me
      ? {
          email: me.email as string,
          name: `${me.first_name} ${me.last_name}`,
        }
      : undefined;

    dispatch(createTicketAction({ ...data, comment_id: id, requester }));
  };

  const close = useCallback(() => {
    form.resetFields();
    setErrors({});
    onClose();
  }, [form, onClose]);

  useEffect(() => {
    if (isOpen && createdTicket) {
      addToast({
        type: "success_accent",
        title: t("components:comments:createZendeskTicketModal:toastMessages:success"),
      });
      close();
      dispatch(clearTicketStateAction());
    }
  }, [close, createdTicket, dispatch, isOpen, t]);

  return (
    <Modal
      centered
      responsive
      hideFooter
      onCancel={close}
      open={isOpen}
      width={600}
      title={t("components:comments:createZendeskTicketModal.title")}
      classes={{
        wrapper: s.bbCreateZendeskTicketModal,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{ description }}
        onFinish={onSubmit}
        onFieldsChange={onValuesChange}
        scrollToFirstError
      >
        <ZendeskTicketForm showDescription form={form} errors={errors} />
        <Form.Item>
          <div className={s.bbCreateZendeskTicketModalFooter}>
            <Button _type="secondary" type="button" onClick={close}>
              {t("generic:cancel")}
            </Button>
            <Button _type="primary" type="submit" loading={isSubmitting}>
              {t("generic:create")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateZendeskTicketModal;
