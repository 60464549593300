/* eslint-disable max-len */
import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  TabsScrollable,
  TabPane,
  HelpIcon,
  AlertInfo,
  Tooltip,
  Input,
  SelectStyled,
  FormErrorMessage,
} from "@bbdevcrew/bb_ui_kit_fe";
import AmPmSwitch from "@components/_common/amPmSwitch/AmPmSwitch";
import WeekdaysSelect from "@components/_common/weekdaysSelect/WeekdaysSelect";
import { Form } from "antd";
import Timezone from "@components/_common/Timezone";

import { meSelector } from "@store/me/selectors";

import s from "./EmailAlertWorkflowThreshold.module.less";

import { EmailAlertRangeEnum, IWorkflowEmailAlertAction } from "@store/workflows/types";
import { defaultSelectedDays, timeOptions } from "./EmailAlertWorkflowThreshold.helpers";
import { AmPmType } from "@store/notifications/types";
import { FormInstance } from "antd";
import { getRangeOptions } from "./EmailAlertWorkflowThreshold.helpers";

interface IEmailAlertWorkflowThresholdProps {
  form: FormInstance;
  hideTitle?: boolean;
  actionParent?: string;
  actionParentIndex?: number;
  actionParentType?: "single" | "multiple";
}

const DEFAULT_MERIDIEM = "AM";

export const EmailAlertWorkflowThreshold: React.FC<IEmailAlertWorkflowThresholdProps> = ({
  form,
  hideTitle,
  actionParentIndex = 0,
  actionParent = "action",
  actionParentType = "single",
}) => {
  const { t } = useTranslation();

  const isActionParentMulti = actionParentType === "multiple";
  const isEditMode = !!form.getFieldValue("id");
  const actionParentValue = form.getFieldValue(actionParent) || (isActionParentMulti ? [] : {});
  const values = (actionParentValue || {}) as IWorkflowEmailAlertAction;
  const valuesMulti = ((actionParentValue || []) as IWorkflowEmailAlertAction[])[actionParentIndex];
  const currentValues = isActionParentMulti ? valuesMulti : values;

  const me = useSelector(meSelector);

  const defaultSchedule = {
    hour: currentValues?.schedule?.hour || "10",
    meridiem: currentValues?.schedule?.meridiem || DEFAULT_MERIDIEM,
    week_days: currentValues?.schedule?.week_days || defaultSelectedDays,
    timezone: currentValues?.schedule?.timezone || (!isEditMode ? me?.timezone : undefined),
  };

  const [showThresholdErrorMessage, setShowThresholdErrorMessage] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>(
    t(
      `pages:workflows:form:actions:email_alert:notificationFrequency:${
        currentValues?.range_type || EmailAlertRangeEnum.HOUR
      }`,
      { count: currentValues?.range_number || 1 },
    ),
  );

  const getMultiValue = (newValue: Record<string, unknown>) => {
    return [
      // copying elements before the target index
      ...actionParentValue.slice(0, actionParentIndex),
      {
        ...actionParentValue[actionParentIndex],
        ...newValue,
      },
      // copying elements after the target index
      ...actionParentValue.slice(actionParentIndex + 1),
    ];
  };

  const createFormPayload = (payload: Record<string, unknown>) =>
    isActionParentMulti ? getMultiValue(payload) : payload;

  useEffect(() => {
    if (!currentValues?.range_type) {
      const payload = {
        range_type: EmailAlertRangeEnum.HOUR,
      };

      form.setFieldsValue({
        [actionParent]: createFormPayload(payload),
      });
    }
    // eslint-disable-next-line
  }, []);

  const onTabChange = (key: string) => {
    const payload = {
      range_type:
        key === EmailAlertRangeEnum.DYNAMIC ? EmailAlertRangeEnum.DYNAMIC : EmailAlertRangeEnum.DAY,
      is_single_email: currentValues?.is_single_email,
      user_ids: currentValues?.user_ids,
      threshold: undefined,
      range_number: undefined,
      schedule: defaultSchedule,
    };

    form.setFieldsValue({
      [actionParent]: createFormPayload(payload),
    });

    setShowThresholdErrorMessage(false);
  };

  const onMeridiemChange = (amPm: AmPmType) => {
    const payload = {
      schedule: {
        ...currentValues?.schedule,
        meridiem: amPm,
      },
    };

    form.setFieldsValue({
      [actionParent]: createFormPayload(payload),
    });
  };

  const onTimezoneChange = (val: string) => {
    const payload = {
      schedule: {
        ...currentValues?.schedule,
        timezone: val,
      },
    };

    form.setFieldsValue({
      [actionParent]: createFormPayload(payload),
    });
  };

  const onRangeTypeChange = (value: string) => {
    if (value !== EmailAlertRangeEnum.DAY && currentValues?.schedule) {
      form.resetFields(["schedule"]);
    } else if (value === EmailAlertRangeEnum.DAY) {
      const payload = {
        range_type: value,
        schedule: defaultSchedule,
      };

      form.setFieldsValue({
        [actionParent]: createFormPayload(payload),
      });
    }

    setAlertMessage(
      t(
        `pages:workflows:form:actions:email_alert:notificationFrequency:${value}${
          value === EmailAlertRangeEnum.DAY ? ":sentence1" : ""
        }`,
        { count: currentValues?.range_number || 1 },
      ),
    );
  };

  const onRangeNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const range = e.target.value;
    const rangeNumber = range !== null ? parseFloat(range.toString()) : range;
    const rangeType: string | undefined = currentValues?.range_type;

    form.setFieldsValue({
      [actionParent]: createFormPayload({ range_number: rangeNumber }),
    });

    if (rangeType) {
      setAlertMessage(
        t(
          `pages:workflows:form:actions:email_alert:notificationFrequency:${rangeType}${
            rangeType === EmailAlertRangeEnum.DAY ? ":sentence1" : ""
          }`,
          { count: rangeNumber || 1 },
        ),
      );
    }
  };

  const onThresholdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const threshold = value !== null ? parseFloat(value.toString()) : value;

    form.setFieldsValue({
      [actionParent]: createFormPayload({ threshold }),
    });
  };

  const validateThresholdValue = (value: string) => {
    if (currentValues?.range_type !== "dynamic" && !value) {
      setShowThresholdErrorMessage(true);
    } else if (
      currentValues?.range_type &&
      currentValues?.range_number &&
      currentValues?.threshold
    ) {
      setShowThresholdErrorMessage(false);
    }
  };

  return (
    <div className={s.bbEmailAlertThresholdContainer}>
      <div data-cy="notification-config" className={s.bbEmailAlertThresholdConfig}>
        <div className={s.bbEmailAlertThresholdForm}>
          {!hideTitle && (
            <p className={s.bbEmailAlertThresholdFormLabel}>
              {t("pages:workflows:form:threshold:email_alert:label")}
            </p>
          )}
          <TabsScrollable
            centered
            noUnderline
            activeKey={currentValues?.range_type === "dynamic" ? "dynamic" : "specific"}
            onChange={onTabChange}
            defaultActiveKey="specific"
            className={s.bbNotifyTabs}
          >
            <TabPane
              tab={t("components:notificationsPanel:modals:create:tabs:threshold:title")}
              tabKey="specific"
            >
              <span className={s.bbScheduleText}>
                {t("components:notificationsPanel:modals:create:form:sentence1")}
              </span>
              <Form.Item
                name={
                  isActionParentMulti
                    ? [actionParent, actionParentIndex, "threshold"]
                    : [actionParent, "threshold"]
                }
                className={s.bbFormItemThreshold}
                data-stonly-target="intelligence-notification__create-notification-modal--comments-number"
                style={{
                  width: `${Math.max((currentValues?.threshold ?? 0).toString().length * 9 + 25, 40)}px`,
                }}
                rules={[
                  {
                    validator: (_, value) => {
                      validateThresholdValue(value);
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  type="number"
                  min={1}
                  _size="sm"
                  tabIndex={-1}
                  onChange={onThresholdChange}
                  wrapperClassName={s.bbFormInputNumber}
                />
              </Form.Item>
              <span className={s.bbScheduleText}>
                {t("components:notificationsPanel:modals:create:form:sentence2")}
              </span>
              <Form.Item
                name={
                  isActionParentMulti
                    ? [actionParent, actionParentIndex, "range_number"]
                    : [actionParent, "range_number"]
                }
                className={s.bbFormItemRangeNumber}
                data-stonly-target="intelligence-notification__create-notification-modal--time-number"
                style={{
                  width: `${Math.max((currentValues?.range_number ?? 0).toString().length * 9 + 25, 40)}px`,
                }}
                rules={[
                  {
                    validator: (_, value) => {
                      validateThresholdValue(value);
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  type="number"
                  min={1}
                  _size="sm"
                  tabIndex={-1}
                  onChange={onRangeNumberChange}
                  wrapperClassName={s.bbFormInputNumber}
                />
              </Form.Item>
              <Form.Item
                name={
                  isActionParentMulti
                    ? [actionParent, actionParentIndex, "range_type"]
                    : [actionParent, "range_type"]
                }
                className={s.bbFormRangeType}
                data-stonly-target="intelligence-notification__create-notification-modal--hours-days-months-dropdown"
                rules={[
                  {
                    validator: (_, value) => {
                      validateThresholdValue(value);
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <SelectStyled
                  useDynamicPlacement
                  tabIndex={-1}
                  options={getRangeOptions(currentValues?.range_number || 1, t)}
                  dropdownMatchSelectWidth
                  data-cy="range-type-select"
                  onChange={onRangeTypeChange}
                  wrapperClassName={s.bbFormRangeTypeSelectWrapper}
                />
              </Form.Item>

              <div className={s.bbEmailAlertThresholdError}>
                {showThresholdErrorMessage && (
                  <FormErrorMessage>
                    {t("pages:workflows:form:threshold:email_alert:required")}
                  </FormErrorMessage>
                )}
              </div>

              {currentValues?.range_type !== EmailAlertRangeEnum.DAY ? (
                <AlertInfo
                  size="medium"
                  message={alertMessage}
                  className={s.bbNotificationFrequencyInfo}
                />
              ) : (
                <>
                  <div className={s.bbNotificationFrequencyTime}>
                    <div className={s.bbNotificationFrequencyTimePhrase}>
                      <span>
                        {t(
                          "pages:workflows:form:actions:email_alert:notificationFrequency:day:sentence1",
                          {
                            count: currentValues?.range_number || 1,
                          },
                        )}{" "}
                        {t("generic:at")}
                      </span>
                      <div className={s.bbNotificationFrequencyTimeInputs}>
                        <div className={s.bbNotificationFrequencyTimeHour}>
                          <Form.Item
                            name={
                              isActionParentMulti
                                ? [actionParent, actionParentIndex, "schedule", "hour"]
                                : [actionParent, "schedule", "hour"]
                            }
                            className={s.bbFormItemNotificationHour}
                            rules={[
                              {
                                required: true,
                                message: " ",
                              },
                            ]}
                          >
                            <SelectStyled
                              useDynamicPlacement
                              dropdownMatchSelectWidth
                              tabIndex={-1}
                              options={timeOptions}
                              data-cy="notification-hour-select"
                              wrapperClassName={s.bbFormItemNotificationHourSelectWrapper}
                            />
                          </Form.Item>
                          <Form.Item
                            name={
                              isActionParentMulti
                                ? [actionParent, actionParentIndex, "schedule", "meridiem"]
                                : [actionParent, "schedule", "meridiem"]
                            }
                            className={s.bbMeridiem}
                          >
                            <AmPmSwitch
                              checked={currentValues?.schedule?.meridiem || DEFAULT_MERIDIEM}
                              onChange={e => onMeridiemChange(e.target.value as AmPmType)}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          name={
                            isActionParentMulti
                              ? [actionParent, actionParentIndex, "schedule", "timezone"]
                              : [actionParent, "schedule", "timezone"]
                          }
                          rules={[
                            {
                              required: true,
                              message: " ",
                            },
                          ]}
                        >
                          <p className={s.bbTimezoneLabel}>
                            {t("components:profileUser:timezone:label")}
                          </p>

                          <Timezone
                            className={s.bbTimezone}
                            onChange={onTimezoneChange}
                            value={
                              currentValues?.schedule?.timezone ||
                              (!isEditMode ? me?.timezone : undefined)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={s.bbNotificationFrequencyDays}>
                    {t(
                      "pages:workflows:form:actions:email_alert:notificationFrequency:day:sentence2",
                    )}

                    <Form.Item
                      name={
                        isActionParentMulti
                          ? [actionParent, actionParentIndex, "schedule", "week_days"]
                          : [actionParent, "schedule", "week_days"]
                      }
                    >
                      <WeekdaysSelect
                        selectedDays={currentValues?.schedule?.week_days || defaultSelectedDays}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </TabPane>
            <TabPane
              tab={
                <div className={s.bbDynamicTab} data-cy="email-alert-dynamic-threshold">
                  {t("components:notificationsPanel:modals:create:tabs:dynamic:title")}
                  <Tooltip
                    title={t("components:notificationsPanel:modals:create:tabs:dynamic:warning")}
                  >
                    <span className={s.bbDynamicTabWarning}>
                      <HelpIcon />
                    </span>
                  </Tooltip>
                </div>
              }
              tabKey="dynamic"
            />
          </TabsScrollable>
        </div>
      </div>
    </div>
  );
};
