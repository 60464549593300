import React, { forwardRef } from "react";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import AppFiltersContainer from "@containers/AppFiltersContainer";

import s from "./AppLayoutContainer.module.less";

import { useFilterContext } from "./FilterContext";
import { IFormHandler } from "@bbdevcrew/bb_ui_kit_fe";

export const MobileFiltersModal = forwardRef<IFormHandler>((_, ref) => {
  const filterContext = useFilterContext();

  return (
    <Modal
      hideHeader
      hideFooter
      hideCloseIcon
      noPadding
      open={filterContext.isFiltersModalShowing}
      onCancel={filterContext.closeFilterModal}
      classes={{ wrapper: s.bbMobileFiltersModal, root: s.bbMobileFiltersModalRoot }}
    >
      <AppFiltersContainer ref={ref} />
    </Modal>
  );
});

export default MobileFiltersModal;

MobileFiltersModal.displayName = "MobileFiltersModal";
