import React from "react";

import Post from "./Post";
import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import { InternalNotesContext } from "@components/_common/ReplyModal/InternalNotesContext";

import s from "./Post.module.less";

import { IPostModalProps } from "./Post.type";

const PostModal: React.FC<IPostModalProps> = ({ isOpen, post, onClose, showPostTags = false }) => {
  if (!post) return null;

  return (
    <Modal
      centered
      responsive
      hideFooter
      hideHeader
      hideCloseIcon
      width={800}
      open={isOpen}
      onCancel={onClose}
      classes={{
        modal: s.bbPostModal,
        body: s.bbPostModalBody,
      }}
    >
      <InternalNotesContext>
        <Post post={post} showPostTags={showPostTags} onClose={onClose} />
      </InternalNotesContext>
    </Modal>
  );
};

export default PostModal;
