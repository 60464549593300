export const FETCH_ALERTS = "alerts/FETCH_ALERTS";
export const FETCH_ALERTS_SUCCESS = "alerts/FETCH_ALERTS_SUCCESS";
export const FETCH_ALERTS_FAIL = "alerts/FETCH_ALERTS_FAIL";

export const UPDATE_ALERT = "alerts/UPDATE_ALERT";
export const UPDATE_ALERT_SUCCESS = "alerts/UPDATE_ALERT_SUCCESS";
export const UPDATE_ALERT_FAIL = "alerts/UPDATE_ALERT_FAIL";

export const UPDATE_BULK_ALERTS = "alerts/UPDATE_BULK_ALERTS";
export const UPDATE_BULK_ALERTS_SUCCESS = "alerts/UPDATE_BULK_ALERTS_SUCCESS";
export const UPDATE_BULK_ALERTS_FAIL = "alerts/UPDATE_BULK_ALERTS_FAIL";
