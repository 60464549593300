import dayjs from "dayjs";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import {
  Tooltip,
  Button,
  Accordion,
  Chip,
  Badge,
  SentimentBar,
  InternalNotesContent,
  useInternalNotes,
} from "@bbdevcrew/bb_ui_kit_fe";
import PostDetails from "./PostDetails";
import PostAnalysis from "./PostAnalysis";
import { PostPreviewTooltipFooter } from "@components/_common/PostPreviewTooltip/PostPreviewTooltipFooter"; // eslint-disable-line max-len

import {
  deletePreviewPublishNotesAction,
  postPreviewPublishNotesAction,
  postPublishNotesAction,
} from "@store/internalNotes/actions";
import {
  postShortenedSearchesAction,
  resetShortenedSearchesAction,
} from "@store/shortenedSearches/actions";
import {
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesExplorerLinkSelector,
} from "@store/shortenedSearches/selectors";
import { meSelector } from "@store/me/selectors";
import { filterDataSelector } from "@store/filters/selectors";

import s from "./Post.module.less";

import { IPostProps } from "./Post.type";
import { generateRandomId } from "./helpers";
import { IPost, IMention, getPlatformIconBasicColor } from "@bbdevcrew/bb_ui_kit_fe";

import {
  CloseIcon,
  ListIcon,
  ExportIcon,
  CommentIcon,
  InformationIcon,
  MagicSparklesIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { NoteFilledIcon } from "@assets/index";

const AttachmentPreview = ({ post }: { post: IPost }) => {
  if (post.picture) {
    return (
      <div className={s.bbPreview}>
        <img src={post.picture} className={s.bbPicture} />
      </div>
    );
  }

  if (post.attachments?.length && post.attachments[0].type === "video") {
    return (
      <div className={s.bbPreview}>
        <video className={s.bbVideo}>
          <source src={post.attachments[0].url} />
        </video>
      </div>
    );
  }

  return null;
};

const Post = ({ post, showPostTags, onClose }: IPostProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { internalNotes } = useInternalNotes();
  const [postDetailsCollapsed, setPostDetailsCollapsed] = useState(false);
  const [internalNotesCollapsed, setInternalNotesCollapsed] = useState(true);
  const [imageAnalysisCollapsed, setImageAnalysisCollapsed] = useState(false);
  const day = dayjs(post.created_time_pretty).format("DD MMM");
  const year = dayjs(post.created_time_pretty).format("YYYY");
  const time = dayjs(post.created_time_pretty).format("hh:mm A");

  const me = useSelector(meSelector);
  const filters = useSelector(filterDataSelector);
  const shortenedSearchesExplorerLink = useSelector(shortenedSearchesExplorerLinkSelector);
  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);

  const handleExploreInSearch = () =>
    dispatch(postShortenedSearchesAction({ filters: { ...filters, post_ids: [post.object_id] } }));

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      window.location.href = shortenedSearchesExplorerLink;
      dispatch(resetShortenedSearchesAction());
    }
    // eslint-disable-next-line
  }, [postShortenedSearchesFetched]);

  const onSendNote = useCallback(
    (message, mentionMap) => {
      if (post.id) {
        return dispatch(postPublishNotesAction({ id: post.id, message }));
      }

      dispatch(
        postPreviewPublishNotesAction({
          id: generateRandomId(),
          message,
          author: {
            label: `${me?.first_name} ${me?.last_name}`,
            id: me?.id,
          },
          mentions: mentionMap.map((mention: IMention) => ({
            pretty: mention.label,
            id: mention.id,
          })),
        }),
      );
    },
    [dispatch, me, post.id],
  );

  const onDeleteNoteItem = useCallback(
    noteId => dispatch(deletePreviewPublishNotesAction(noteId)),
    [dispatch],
  );

  const onLinkToPostClick = () => window.open(post.permalink, "_blank");

  return (
    <div className={s.bbPost} data-cy="social-post">
      <div className={s.bbPostLeft}>
        <div className={s.bbPostLeftHeader}>
          <div>
            <div className={s.bbTitle}>
              <div className={classNames(s.bbPlatform, `bbPlatform-${post.platform_type.id}`)}>
                {getPlatformIconBasicColor(post.platform_type.id)}
              </div>
              <div>
                <div className={s.bbAuthor}>{post.author_name}</div>
                <div className={s.bbSubtitle}>{`${day}, ${year} ${t("generic:at")} ${time}`}</div>
              </div>
            </div>
          </div>
          <Chip _size="xxs" text={t("components:postPreview:stats")} _type="grey" />
        </div>
        <div className={s.bbMessage}>{post.message}</div>
        <AttachmentPreview post={post} />
        <div className={s.bbPostIdWrapper}>
          {t("components:postPreview:postId")}
          <span className={s.bbPostId}>{post.asset?.id}</span>
        </div>
        {!!post.comment_metrics?.total_comments && <SentimentBar data={post.comment_metrics} />}
        {!!post.comment_metrics && !!post.post_metrics && (
          <PostPreviewTooltipFooter
            metrics={{
              comments: post.comment_metrics.total_comments,
              impressions: post.post_metrics.total_views,
              reactions: post.post_metrics.total_reactions,
              engagements: post.post_metrics.engagement_rate,
            }}
          />
        )}
        <div className={s.bbPostLeftActions}>
          <Button _type="primary" onClick={handleExploreInSearch}>
            <CommentIcon />
            {t("components:comments:post:exploreLink")}
          </Button>
          <Button _type="secondary" onClick={onLinkToPostClick}>
            <ExportIcon />
            {t("components:comments:post:linkToPost")}
          </Button>
        </div>
      </div>
      <div className={s.bbPostRight}>
        <span className={s.bbPostCloseModal} onClick={onClose}>
          <CloseIcon />
        </span>
        <div className={s.bbPostRightAccordionsWrapper}>
          <Accordion
            type="white"
            className={classNames(s.bbPostRightAccordion, s.bbPostRightAccordionPostDetails)}
            title={<span>{t("components:comments:post:postDetails")}</span>}
            collapsed={postDetailsCollapsed}
            setCollapsed={() => setPostDetailsCollapsed(prev => !prev)}
            headerPrefix={<ListIcon />}
            body={<PostDetails post={post} showPostTags={showPostTags} />}
          />
          <Accordion
            type="white"
            className={s.bbPostRightAccordion}
            title={
              <>
                <span>{t("components:comments:post:internalNotes")}</span>
                <Badge text={internalNotes.length} className={s.bbPostRightAccordionBadge} />
              </>
            }
            collapsed={internalNotesCollapsed}
            setCollapsed={() => setInternalNotesCollapsed(prev => !prev)}
            headerPrefix={<NoteFilledIcon />}
            body={<InternalNotesContent onSendNote={onSendNote} onDeleteNote={onDeleteNoteItem} />}
          />
          {post.post_analysis && (
            <Accordion
              type="white"
              className={s.bbPostRightAccordion}
              title={
                <span>
                  {t("components:comments:post:imageAnalysis")}
                  <Tooltip
                    placement="top"
                    title={t("components:comments:post:tooltips:imageAnalysis")}
                  >
                    <span className={s.bbPostInfoIcon}>
                      <InformationIcon />
                    </span>
                  </Tooltip>
                </span>
              }
              collapsed={imageAnalysisCollapsed}
              setCollapsed={() => setImageAnalysisCollapsed(prev => !prev)}
              headerPrefix={<MagicSparklesIcon />}
              body={<PostAnalysis post={post} />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Post;
