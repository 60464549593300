import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { ISourcesState } from "./types";

const initialState: ISourcesState = {
  sources: [],

  fetchingSourcesList: false,
  fetchedSourcesList: false,
  fetchedSourcesListFail: false,

  fetchingSource: false,
  fetchedSource: false,
  fetchingSourceFailed: false,
  fetchingSourceFailedMessage: "",
};

export const sourcesReducer = createReducer<ISourcesState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getSourcesListAction, state =>
    produce(state, draft => {
      draft.fetchingSourcesList = true;
      draft.fetchedSourcesList = false;
      draft.fetchedSourcesListFail = false;
    }),
  )
  .handleAction(actions.getSourcesListSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.sources = action.payload.items.map(source => ({
        ...source,
        type_id: source.type.id,
        asset_status: source.monitoring_status.status,
      }));
      draft.fetchingSourcesList = false;
      draft.fetchedSourcesList = true;
      draft.fetchedSourcesListFail = false;
    }),
  )
  .handleAction(actions.getSourcesListFailureAction, state =>
    produce(state, draft => {
      draft.sources = [];
      draft.fetchingSourcesList = false;
      draft.fetchedSourcesList = false;
      draft.fetchedSourcesListFail = true;
    }),
  )
  .handleAction(actions.createSourceAction, state =>
    produce(state, draft => {
      draft.fetchingSource = true;
      draft.fetchedSource = false;
      draft.fetchingSourceFailed = false;
    }),
  )
  .handleAction(actions.createSourceSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingSource = false;
      draft.fetchedSource = true;
      draft.fetchingSourceFailed = false;
      draft.sources.push({
        ...action.payload,
        type_id: action.payload.type.id,
        asset_status: action.payload.monitoring_status.status,
      });
    }),
  )
  .handleAction(actions.createSourceFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingSource = false;
      draft.fetchedSource = false;
      draft.fetchingSourceFailed = true;
      draft.fetchingSourceFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.updateSourceAction, state =>
    produce(state, draft => {
      draft.fetchingSource = true;
      draft.fetchedSource = false;
      draft.fetchingSourceFailed = false;
    }),
  )
  .handleAction(actions.updateSourceSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingSource = false;
      draft.fetchedSource = true;
      draft.fetchingSourceFailed = false;

      const sourceIndex = draft.sources.findIndex(ch => ch.id === action.payload.id);
      if (sourceIndex !== -1) {
        draft.sources[sourceIndex] = {
          ...action.payload,
          type_id: action.payload.type.id,
          asset_status: action.payload.monitoring_status.status,
        };
      }
    }),
  )
  .handleAction(actions.updateSourceFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingSource = false;
      draft.fetchedSource = false;
      draft.fetchingSourceFailed = true;
      draft.fetchingSourceFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.deleteSourceAction, state =>
    produce(state, draft => {
      draft.fetchingSource = true;
      draft.fetchedSource = false;
      draft.fetchingSourceFailed = false;
    }),
  )
  .handleAction(actions.deleteSourceSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingSource = false;
      draft.fetchedSource = true;
      draft.fetchingSourceFailed = false;
      draft.sources = draft.sources.filter(source => source.id !== action.payload);
    }),
  )
  .handleAction(actions.deleteSourceFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingSource = false;
      draft.fetchedSource = false;
      draft.fetchingSourceFailed = true;
      draft.fetchingSourceFailedMessage = action.payload;
    }),
  );
