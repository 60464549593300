import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import * as actions from "./actions";

import { RootState } from "..";
import { BASE_URL_API, clientTags } from "@utils/paths";
import { getAuthAPIHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { ICreateTagResponse, ITagsListResponse, TagsActions } from "./types";

const getTagUrl = (type: string) => type?.replace("_", "-");

export const getTagsList = (action$: Observable<TagsActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isActionOf(actions.getTagsListAction)),
    switchMap(() =>
      ajax
        .get<ITagsListResponse>(`${clientTags}?group_type=control_panel`, getAuthAPIHeaders(state$))
        .pipe(
          map(({ response }) => response),
          map(data => actions.getTagsListSuccessAction(data)),
          catchError(e => handleError(e, actions.getTagsListFailureAction)),
        ),
    ),
  );

export const createTag = (action$: Observable<TagsActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isActionOf(actions.createTagAction)),
    switchMap(({ payload }) => {
      const { type, title, ...tagData } = payload;

      return ajax
        .post<ICreateTagResponse>(
          `${BASE_URL_API}/${getTagUrl(type)}`,
          { ...tagData, label: title },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(({ response }) => response),
          map(data => actions.createTagSuccessAction({ ...data, type, ...tagData })),
          catchError(({ response }) => [actions.createTagFailureAction(response.message)]),
        );
    }),
  );

export const createSubTag = (
  action$: Observable<TagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createSubTagAction)),
    switchMap(({ payload }) => {
      const { type, ...tagData } = payload;

      return ajax.post<ICreateTagResponse>(clientTags, tagData, getAuthAPIHeaders(state$)).pipe(
        map(({ response }) => response),
        map(data =>
          actions.createTagSuccessAction({
            ...data,
            type,
            ...tagData,
            isSubTag: true,
            id: data.id,
          }),
        ),
        catchError(({ response }) => [actions.createTagFailureAction(response.message)]),
      );
    }),
  );

export const updateTag = (action$: Observable<TagsActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isActionOf(actions.updateTagAction)),
    switchMap(({ payload }) => {
      const { key: id, title, type, ...tagData } = payload;

      return ajax
        .put<ICreateTagResponse>(
          `${BASE_URL_API}/${getTagUrl(type)}/${id}`,
          { ...tagData, label: title },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(({ response }) => response),
          map(data => actions.updateTagSuccessAction({ ...data, id, type, ...tagData })),
          catchError(({ response }) => [actions.updateTagFailureAction(response.message)]),
        );
    }),
  );

export const updateSubTag = (
  action$: Observable<TagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateSubTagAction)),
    switchMap(({ payload }) => {
      const { id, tag_id, type, ...tagData } = payload;

      return ajax
        .put<ICreateTagResponse>(`${clientTags}/${id}`, tagData, getAuthAPIHeaders(state$))
        .pipe(
          map(({ response }) => response),
          map(data =>
            actions.updateTagSuccessAction({
              ...data,
              id,
              type,
              tag_id,
              ...tagData,
              isSubTag: true,
            }),
          ),
          catchError(({ response }) => [actions.updateTagFailureAction(response.message)]),
        );
    }),
  );
export const deleteTag = (action$: Observable<TagsActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isActionOf(actions.deleteTagAction)),
    switchMap(({ payload }) => {
      const { type, id } = payload;

      return ajax
        .delete(`${BASE_URL_API}/${getTagUrl(type)}/${id}`, getAuthAPIHeaders(state$))
        .pipe(
          map(({ response }) => response),
          map(() => actions.deleteTagSuccessAction({ type, id })),
          catchError(({ response }) => [actions.deleteTagFailureAction(response.message)]),
        );
    }),
  );

export const deleteSubTag = (
  action$: Observable<TagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteSubTagAction)),
    switchMap(({ payload }) => {
      const { id, type, parent_id } = payload;

      return ajax.delete(`${clientTags}/${id}`, getAuthAPIHeaders(state$)).pipe(
        map(({ response }) => response),
        map(() => actions.deleteTagSuccessAction({ id, type, isSubTag: true, parent_id })),
        catchError(({ response }) => [actions.deleteTagFailureAction(response.message)]),
      );
    }),
  );
