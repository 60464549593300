import cn from "classnames/bind";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import PostTagsList from "../../../_common/Social/Post/PostTags/PostTagsList";
import PostTagsDropdown from "../../../_common/Social/Post/PostTags/PostTagsDropdown";
import OptionsPanel from "../OptionsPanel";

import { addPostTagResultSelector, addPostTagSuccessfulSelector } from "@store/postTags/selectors";

import s from "./BBPlatformOptions.module.less";

import { IBBPlatformOptionsProps } from "./types";
import { IPostTag } from "@bbdevcrew/bb_ui_kit_fe";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

export function BBPlatformOptions({ post, updatePost }: IBBPlatformOptionsProps) {
  const { t } = useTranslation();

  const hasTags = !!post.postTags?.length;

  const tagAddedSuccessfully = useSelector(addPostTagSuccessfulSelector);
  const tagCreationResult = useSelector(addPostTagResultSelector);

  useEffect(() => {
    if (tagAddedSuccessfully && !!tagCreationResult && post.postTags?.length) {
      const updatedTagIndex = post.postTags.findIndex(tag => tag.label === tagCreationResult.label);
      const updatedPostTags = [...post.postTags];
      updatedPostTags[updatedTagIndex] = tagCreationResult;

      updatePost({
        ...post,
        postTags: updatedPostTags,
      });
    }
    // eslint-disable-next-line
  }, [tagAddedSuccessfully, tagCreationResult]);

  const onSelectTag = (tag: IPostTag) => {
    updatePost({
      ...post,
      postTags: [...(post.postTags || []), tag],
    });
  };

  const onDeleteTag = (tagId: string) => {
    updatePost({
      ...post,
      postTags: post.postTags?.filter(tag => tag.id !== tagId),
    });
  };

  return (
    <OptionsPanel title={t("components:publish:postCreationModal:bbPlatformOptions:title")}>
      <div className={s.bbPlatformOptionsBodyTitle}>
        {t("components:publish:postCreationModal:bbPlatformOptions:postTags")}
      </div>
      <div className={s.bbPlatformOptionsPostTags}>
        <PostTagsList
          postTags={post.postTags}
          onDeleteTag={onDeleteTag}
          tagClassName={s.bbPlatformOptionsListTag}
          tagIconClassName={s.bbPlatformOptionsListIcon}
        />
        <PostTagsDropdown
          postTags={post.postTags}
          onSelectTag={onSelectTag}
          trigger={
            <Button
              _size="sm"
              _type="secondary"
              className={cx(s.bbPlatformOptionsDropdownTrigger, {
                [s.bbPlatformOptionsDropdownTriggerNoTags]: !hasTags,
              })}
            >
              <PlusIcon />
              {!hasTags && t("components:postTags:addBtn")}
            </Button>
          }
        />
      </div>
    </OptionsPanel>
  );
}
