import React from "react";
import classNames from "classnames";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TopPostTags.module.less";

interface ITopPostTagsActionCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  buttonStyle?: "primary" | "link";
  onClick?: () => void;
}

export const TopPostTagsActionCard: React.FC<ITopPostTagsActionCardProps> = ({
  icon,
  title,
  description,
  buttonText,
  buttonIcon,
  buttonStyle,
  onClick,
}) => {
  return (
    <div className={s.bbTopPostTagsEmptyActionCard}>
      <div className={s.bbTopPostTagsEmptyActionCardIcon}>{icon}</div>
      <div className={s.bbTopPostTagsEmptyActionCardContent}>
        <h3 className={s.bbTopPostTagsEmptyActionCardTitle}>{title}</h3>
        <p className={s.bbTopPostTagsEmptyActionCardDescription}>{description}</p>
        {onClick && (
          <Button
            _type={buttonStyle}
            _size="sm"
            className={classNames(s.bbTopPostTagsEmptyActionCardButton, {
              [s.bbTopPostTagsEmptyActionCardButtonPrimary]: buttonStyle === "primary",
            })}
            onClick={onClick}
          >
            {buttonIcon} {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};
