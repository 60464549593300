import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { FC, useState, useEffect, HTMLAttributes, useMemo } from "react";
import classNames from "classnames";

import { Grid } from "antd";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";

import s from "./../sentimentPieChart/SentimentPieChart.module.less";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { minScreen1840 } from "@bbdevcrew/bb_ui_kit_fe";
import { IPieChartUnit } from "../../sentimentDetails/SentimentDetails.type";
import { normalizePlatformData } from "../../sentimentDetails/helpers";
import { IOverviewPerformanceItem } from "@store/dashboard/types";

interface IPlatformsPieChartProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  filterData: IFilters;
  data: IOverviewPerformanceItem[];
  filterToSpecificItem?: (item: IFilters) => void;
}

const PlatformsPieChart: FC<IPlatformsPieChartProps> = ({
  filterData,
  data,
  loading,
  className,
  filterToSpecificItem,
}) => {
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [commentPlatformsData, setCommentPlatformsData] = useState<IPieChartUnit[]>([]);
  const [total, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);

  const isMobile = screens.md === false;

  useEffect(() => {
    const normalizeData = () => {
      const normalizedPlatformData = normalizePlatformData(data);

      const pieChartTotal = normalizedPlatformData.find(
        (unit: IPieChartUnit) => unit.rawName === "total",
      ) as IPieChartUnit;
      const pieChartData = normalizedPlatformData.filter(
        (unit: IPieChartUnit) => unit.rawName !== "total",
      ) as IPieChartUnit[];

      setTotal(pieChartTotal);
      setCommentPlatformsData(pieChartData);
    };

    normalizeData();
  }, [data]);

  const onChartClick = (event: { payload: { rawName: string } }) => {
    const filters = {
      ...filterData,
      platform_types: [event.payload.rawName],
    };
    filterToSpecificItem?.(filters);
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: { filters },
      },
    );
  };

  const { defaultValue, chartData } = useMemo(() => {
    if (!total || commentPlatformsData.length === 0) {
      return { defaultValue: {} as IPieChartUnit, chartData: [] };
    }

    return {
      defaultValue: total,
      chartData: commentPlatformsData.filter(unit => unit.value !== 0),
    };
  }, [total, commentPlatformsData]);

  return (
    <Card
      headerTitle={t("components:platformsPieChart:headerTitle")}
      headerSubTitle={t("components:platformsPieChart:headerSubTitle")}
      className={classNames(s.bbPlatformsPieChartWrapper, className)}
    >
      <div className={s.bbPlatformsPieChart}>
        <PieChartWidget
          hasLabel={!isMobile}
          showLegendPercentages={!isMobile}
          loading={loading}
          position="center"
          radiuses={[minScreen1840 ? 65 : 50, minScreen1840 ? 90 : 70]}
          sizes={[minScreen1840 ? 200 : 140, minScreen1840 ? 200 : 140]}
          defaultValue={defaultValue}
          onChartClick={onChartClick}
          chartData={chartData}
        />
      </div>
    </Card>
  );
};

export default PlatformsPieChart;
