/* eslint-disable max-len */
import classNames from "classnames/bind";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import OptionsPanel from "../OptionsPanel";
import { TTDiscloseContentToggle } from "./TTDiscloseContentToggle";
import { TTPromoteContentCheckbox } from "./TTPromoteContentCheckbox";
import { TTInteractionAbilityCheckbox } from "./TTInteractionAbilityCheckbox";

import s from "./TTDiscloseContentOptions.module.less";

import { ITTContentOptions } from "@store/publishings/types";
import { optionDisabledTooltip, getDiscloseContentInfoMessage } from "./helpers";
import { InteractionOptionType, ITTDiscloseContentOptionsProps } from "./types";

import { WarningIcon, SettingsIcon, InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

const TTDiscloseContentOptions = ({
  post,
  ttCreatorInfo,
  updatePost,
}: ITTDiscloseContentOptionsProps) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  const isMobile = screens.md === false;

  const tiktokOptions = post.tiktokOptions;

  const allowCommentsDisabled = !ttCreatorInfo?.default_options.allow_comments;
  const allowDuetsDisabled = !ttCreatorInfo?.default_options.allow_duets;
  const allowStitchesDisabled = !ttCreatorInfo?.default_options.allow_stitches;

  const promoteOwnContent = !!tiktokOptions?.promote_own_content;
  const promoteOthersContent = !!tiktokOptions?.promote_others_content;
  const showDiscloseContentInfoMessage = promoteOwnContent || promoteOthersContent;
  const discloseContentRequiredError = post.error === "discloseContentRequired";

  const onInteractionAbilityChange = (
    event: ChangeEvent<HTMLInputElement>,
    interactionOptionName: InteractionOptionType,
  ) => {
    const checked = !!event.target.checked;
    const isPromoteContentOptionOn =
      interactionOptionName === "promote_own_content" ||
      interactionOptionName === "promote_others_content";

    updatePost(
      {
        ...post,
        ...(isPromoteContentOptionOn && discloseContentRequiredError && { error: undefined }),
        tiktokOptions: {
          ...tiktokOptions,
          [interactionOptionName]: checked,
        } as ITTContentOptions,
      },
      "tiktokOptions",
    );
  };

  const handleToggleDiscloseContent = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    updatePost(
      {
        ...post,
        ...(discloseContentRequiredError && { error: undefined }),
        tiktokOptions: {
          ...tiktokOptions,
          disclose_content: checked,
          ...(!checked && {
            promote_own_content: false,
            promote_others_content: false,
          }),
        } as ITTContentOptions,
      },
      "tiktokOptions",
    );
  };

  if (isMobile) {
    return (
      <OptionsPanel
        defaultOpenOnMobile
        titleElement={
          <div className={s.bbTTDiscloseContentTitleMobile}>
            <SettingsIcon />
            {t("components:publish:postCreationModal:ttDisclosureOptions:title")}
          </div>
        }
        className={s.bbYouTubeOptions}
      >
        <div className={classNames(s.bbTTDiscloseContentBody, s.bbTTDiscloseContentBodyMobile)}>
          <div
            className={classNames(s.bbInteractionAbilitySection, {
              [s.bbInteractionAbilitySectionMobile]: isMobile,
            })}
          >
            <TTInteractionAbilityCheckbox
              name="allow_comments"
              checked={!!tiktokOptions?.allow_comments}
              disabled={allowCommentsDisabled}
              onChange={e => onInteractionAbilityChange(e, "allow_comments")}
              label={t("components:publish:postCreationModal:ttDisclosureOptions:allowComments")}
              tooltipText={allowCommentsDisabled ? optionDisabledTooltip : ""}
            />

            <TTInteractionAbilityCheckbox
              name="allow_duets"
              checked={!!tiktokOptions?.allow_duets}
              disabled={allowDuetsDisabled}
              onChange={e => onInteractionAbilityChange(e, "allow_duets")}
              label={t("components:publish:postCreationModal:ttDisclosureOptions:allowDuets")}
              tooltipText={allowDuetsDisabled ? optionDisabledTooltip : ""}
            />

            <TTInteractionAbilityCheckbox
              name="allow_stitches"
              checked={!!tiktokOptions?.allow_stitches}
              disabled={allowStitchesDisabled}
              onChange={e => onInteractionAbilityChange(e, "allow_stitches")}
              label={t("components:publish:postCreationModal:ttDisclosureOptions:allowStitches")}
              tooltipText={allowStitchesDisabled ? optionDisabledTooltip : ""}
            />
          </div>
          <div className={s.bbDicloseContentSection}>
            <TTDiscloseContentToggle
              checked={!!tiktokOptions?.disclose_content}
              onChange={handleToggleDiscloseContent}
            />
            {tiktokOptions?.disclose_content && (
              <>
                <TTPromoteContentCheckbox
                  name="promote_own_content"
                  checked={promoteOwnContent}
                  hasError={discloseContentRequiredError}
                  onChange={e => onInteractionAbilityChange(e, "promote_own_content")}
                  label={t(
                    "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:brandOptionTitle",
                  )}
                  description={t(
                    "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:brandOptionDescription",
                  )}
                />

                <TTPromoteContentCheckbox
                  name="promote_others_content"
                  checked={promoteOthersContent}
                  onChange={e => onInteractionAbilityChange(e, "promote_others_content")}
                  label={t(
                    "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:contentOptionTitle",
                  )}
                  description={t(
                    "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:contentOptionDescription",
                  )}
                />

                {discloseContentRequiredError && (
                  <span className={classNames(s.bbFormFieldError, s.bbDicloseContentSectionError)}>
                    <WarningIcon />
                    {t(
                      "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:errorRequired",
                    )}
                  </span>
                )}
                {showDiscloseContentInfoMessage && (
                  <div className={s.bbDicloseContentSectionInfo}>
                    <InformationIcon />
                    <div className={s.bbDicloseContentSectionInfoText}>
                      {getDiscloseContentInfoMessage(promoteOwnContent, promoteOthersContent)}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </OptionsPanel>
    );
  }

  return (
    <div
      className={classNames(s.bbTTDiscloseContent, {
        [s.bbTTDiscloseContentError]: discloseContentRequiredError,
      })}
    >
      <div className={s.bbTTDiscloseContentTitle}>
        <SettingsIcon />
        {t("components:publish:postCreationModal:ttDisclosureOptions:title")}
      </div>
      <div className={s.bbTTDiscloseContentBody}>
        <div
          className={classNames(s.bbInteractionAbilitySection, {
            [s.bbInteractionAbilitySectionMobile]: isMobile,
          })}
        >
          <TTInteractionAbilityCheckbox
            name="allow_comments"
            checked={!!tiktokOptions?.allow_comments}
            disabled={allowCommentsDisabled}
            onChange={e => onInteractionAbilityChange(e, "allow_comments")}
            label={t("components:publish:postCreationModal:ttDisclosureOptions:allowComments")}
            tooltipText={allowCommentsDisabled ? optionDisabledTooltip : ""}
          />

          <TTInteractionAbilityCheckbox
            name="allow_duets"
            checked={!!tiktokOptions?.allow_duets}
            disabled={allowDuetsDisabled}
            onChange={e => onInteractionAbilityChange(e, "allow_duets")}
            label={t("components:publish:postCreationModal:ttDisclosureOptions:allowDuets")}
            tooltipText={allowDuetsDisabled ? optionDisabledTooltip : ""}
          />

          <TTInteractionAbilityCheckbox
            name="allow_stitches"
            checked={!!tiktokOptions?.allow_stitches}
            disabled={allowStitchesDisabled}
            onChange={e => onInteractionAbilityChange(e, "allow_stitches")}
            label={t("components:publish:postCreationModal:ttDisclosureOptions:allowStitches")}
            tooltipText={allowStitchesDisabled ? optionDisabledTooltip : ""}
          />
        </div>
        <div className={s.bbDicloseContentSection}>
          <TTDiscloseContentToggle
            checked={!!tiktokOptions?.disclose_content}
            onChange={handleToggleDiscloseContent}
          />
          {tiktokOptions?.disclose_content && (
            <>
              <TTPromoteContentCheckbox
                name="promote_own_content"
                checked={promoteOwnContent}
                hasError={discloseContentRequiredError}
                onChange={e => onInteractionAbilityChange(e, "promote_own_content")}
                label={t(
                  "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:brandOptionTitle",
                )}
                description={t(
                  "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:brandOptionDescription",
                )}
              />

              <TTPromoteContentCheckbox
                name="promote_others_content"
                checked={promoteOthersContent}
                onChange={e => onInteractionAbilityChange(e, "promote_others_content")}
                label={t(
                  "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:contentOptionTitle",
                )}
                description={t(
                  "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:contentOptionDescription",
                )}
              />

              {discloseContentRequiredError && (
                <span className={classNames(s.bbFormFieldError, s.bbDicloseContentSectionError)}>
                  <WarningIcon />
                  {t(
                    "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:errorRequired",
                  )}
                </span>
              )}
              {showDiscloseContentInfoMessage && (
                <div className={s.bbDicloseContentSectionInfo}>
                  <InformationIcon />
                  <div className={s.bbDicloseContentSectionInfoText}>
                    {getDiscloseContentInfoMessage(promoteOwnContent, promoteOthersContent)}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TTDiscloseContentOptions;
