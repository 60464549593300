import React from "react";

import {
  ModuleIconMapType,
  FeatureModuleIconMapType,
  // icons
  CommentIcon,
  GridIcon,
  HeadphoneIcon,
  HeartIcon,
  RepeatIcon,
  PostIcon,
  ListIcon,
  UserBlockIcon,
  CheckIcon,
  CloseIcon,
  StarIcon,
  InboxIcon,
  HourGlassIcon,
  ReplyIcon,
  ArchiveIcon,
  UserAssignedIcon,
  UserGroupIcon,
  AlertTriangleFilledIcon,
  AlertTriangleIcon,
  CalendarIcon,
  WorkflowIcon,
  TagIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ChartUpIcon } from "@assets/index";

export const AnalyzeModuleIconMap: FeatureModuleIconMapType = {
  topics: <ListIcon />,
  "report-profiles": <UserBlockIcon />,
  "listen-overview": <GridIcon />,
  "listen-sentiment_details": <HeartIcon />,
  "listen-reviews": <StarIcon />,
  "listen-agent_performance": <UserGroupIcon />,
  "listen-conversation_list": <CommentIcon />,
  "listen_more-trending_hashtags": <ChartUpIcon />,
  "listen_more-listening_sources": <HeadphoneIcon />,
  "report-posts": <PostIcon />,
  "report-compare": <RepeatIcon />,
  "report-alerts": <AlertTriangleIcon />,
};

export const ControlPanelModuleIconMap: FeatureModuleIconMapType = {
  "control_panel-overview": <GridIcon />,
  "control_panel-workflows": <WorkflowIcon />,
  "brandbastion_actions-transparency_dashboard": <ChartUpIcon />,
  "control_panel-tags": <TagIcon />,
};

export const CareCPSModuleIconMap: FeatureModuleIconMapType = {
  all: <InboxIcon />,
  review: <HourGlassIcon />,
  starred: <StarIcon />,
  done: <CheckIcon />,
  replied: <ReplyIcon />,
  ignored: <ArchiveIcon />,
  assigned_to_me: <UserAssignedIcon />,
  cancelled: <CloseIcon />,
  errors: <AlertTriangleFilledIcon />,
};

export const ModuleIconMap: ModuleIconMapType = {
  control_panel: ControlPanelModuleIconMap,
  insights: AnalyzeModuleIconMap,
  care_cps: CareCPSModuleIconMap,
};

export const ShortcutsUrlMap = {
  "shortcuts-connect_assets": "/settings/asset-management",
  "shortcuts-invite_users": "/settings/user-management",
};

export const unreadFeatures = ["assigned_to_me"];

export const MobileMenuIconMap: ModuleIconMapType = {
  all: {
    topics: <ListIcon />,
    "report-profiles": <UserBlockIcon />,
    "listen-overview": <GridIcon />,
    "listen-sentiment_details": <HeartIcon />,
    "listen-reviews": <StarIcon />,
    "listen-agent_performance": <UserGroupIcon />,
    "listen-conversation_list": <CommentIcon />,
    "listen_more-trending_hashtags": <ChartUpIcon />,
    "listen_more-listening_sources": <HeadphoneIcon />,
    "report-posts": <PostIcon />,
    "report-compare": <RepeatIcon />,
    "report-alerts": <AlertTriangleIcon />,
    calendar: <CalendarIcon />,
    transparency: <GridIcon />,
    "control_panel-overview": <GridIcon />,
    "control_panel-workflows": <WorkflowIcon />,
    "control_panel-tags": <TagIcon />,
    "brandbastion_actions-transparency_dashboard": <ChartUpIcon />,
  },
};
