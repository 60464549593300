import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { FC, HTMLAttributes, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { Grid } from "antd";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";

import s from "./SentimentPieChart.module.less";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { minScreen1840 } from "@bbdevcrew/bb_ui_kit_fe";
import { IPieChartUnit } from "../../sentimentDetails/SentimentDetails.type";
import { normalizePlatformData } from "../../sentimentDetails/helpers";
import { IExplorerOverviewSentiments } from "@store/dashboard/types";

interface ISentimentPieChartProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  filterData: IFilters;
  data: IExplorerOverviewSentiments[];
  filterToSpecificItem?: (item: IFilters) => void;
}

const SentimentPieChart: FC<ISentimentPieChartProps> = ({
  loading,
  filterData,
  data,
  className,
  filterToSpecificItem,
}) => {
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [commentSentimentData, setCommentSentimentData] = useState<IPieChartUnit[]>([]);
  const [commentSentimentTotal, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);

  const isMobile = screens.md === false;

  useEffect(() => {
    const initializeSearchData = () => {
      const normalizedSentimentData = normalizePlatformData(data);

      const pieChartTotal = normalizedSentimentData.find(
        (unit: IPieChartUnit) => unit.rawName === "total",
      ) as IPieChartUnit;

      const pieChartData = normalizedSentimentData
        .filter(unit => unit.rawName !== "total")
        .map(unit => {
          if (unit.rawName === "negative" && unit.change_percentage) {
            return { ...unit, type: "negative" };
          }
          return unit;
        }) as IPieChartUnit[];

      setTotal(pieChartTotal);
      setCommentSentimentData(pieChartData);
    };

    initializeSearchData();
  }, [data]);

  const onChartClick = (event: { payload: { rawName: string } }) => {
    const filters = {
      ...filterData,
      sentiments: [event.payload.rawName],
    };
    filterToSpecificItem?.(filters);
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: { filters },
      },
    );
  };

  const { defaultValue, chartData } = useMemo(() => {
    if (!commentSentimentTotal || commentSentimentData.length === 0) {
      return { defaultValue: {} as IPieChartUnit, chartData: [] };
    }

    return {
      defaultValue: commentSentimentTotal,
      chartData: commentSentimentData.filter(unit => unit.value !== 0),
    };
  }, [commentSentimentTotal, commentSentimentData]);

  return (
    <Card
      headerTitle={t("components:sentimentPieChart:headerTitle")}
      headerSubTitle={t("components:sentimentPieChart:headerSubTitle")}
      className={classNames(s.bbSentimentPieChartWrapper, className)}
    >
      <div className={s.bbSentimentPieChart}>
        <PieChartWidget
          hasLabel={!isMobile}
          showLegendPercentages={!isMobile}
          loading={loading}
          position="center"
          radiuses={[minScreen1840 ? 65 : 50, minScreen1840 ? 90 : 70]}
          sizes={[minScreen1840 ? 200 : 140, minScreen1840 ? 200 : 140]}
          onChartClick={onChartClick}
          defaultValue={defaultValue}
          chartData={chartData}
        />
      </div>
    </Card>
  );
};

export default SentimentPieChart;
