import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { ICompanySettingsState } from "./types";

const initialState: ICompanySettingsState = {
  companySettings: {
    user_feature_visibility_flags: [],
    user_feature_visibility_flags_options: [],
    integration_flags: [],
    numeric_flags: [],
  },

  fetchingCompanySettings: false,
  fetchedCompanySettings: false,
  fetchingCompanySettingsFailed: false,

  updatingFeatureVisibilityFlags: false,
  updatedFeatureVisibilityFlags: false,
  updatingFeatureVisibilityFlagsFailed: false,

  updatingIntegrationsFlags: false,
  updatedIntegrationsFlags: false,
  updatingIntegrationsFlagsFailed: false,

  updatingDynamicFlags: false,
  updatedDynamicFlags: false,
  updatingDynamicFlagsFailed: false,
};

export const featureVisibilityFlagsReducer = createReducer<
  ICompanySettingsState,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.getCompanySettingsAction, state =>
    produce(state, draft => {
      draft.fetchingCompanySettings = true;
      draft.fetchedCompanySettings = false;
      draft.fetchingCompanySettingsFailed = false;
    }),
  )
  .handleAction(actions.getCompanySettingsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.companySettings = action.payload;
      draft.fetchingCompanySettings = false;
      draft.fetchedCompanySettings = true;
      draft.fetchingCompanySettingsFailed = false;
    }),
  )
  .handleAction(actions.getCompanySettingsFailureAction, state =>
    produce(state, draft => {
      draft.companySettings = initialState.companySettings;
      draft.fetchingCompanySettings = false;
      draft.fetchedCompanySettings = false;
      draft.fetchingCompanySettingsFailed = true;
    }),
  )
  .handleAction(actions.updateFeatureVisibilityFlagsAction, (state, action) =>
    produce(state, draft => {
      draft.updatingFeatureVisibilityFlags = true;
      draft.updatedFeatureVisibilityFlags = false;
      draft.updatingFeatureVisibilityFlagsFailed = false;
      draft.previousFeatureVisibilityFlags = state.companySettings.user_feature_visibility_flags;
      draft.companySettings.user_feature_visibility_flags = action.payload;
    }),
  )
  .handleAction(actions.updateFeatureVisibilityFlagsSuccessAction, state =>
    produce(state, draft => {
      draft.updatingFeatureVisibilityFlags = false;
      draft.updatedFeatureVisibilityFlags = true;
      draft.updatingFeatureVisibilityFlagsFailed = false;
      draft.previousFeatureVisibilityFlags = undefined;
    }),
  )
  .handleAction(actions.updateFeatureVisibilityFlagsFailureAction, state =>
    produce(state, draft => {
      draft.updatingFeatureVisibilityFlags = false;
      draft.updatedFeatureVisibilityFlags = false;
      draft.updatingFeatureVisibilityFlagsFailed = true;
      if (state.previousFeatureVisibilityFlags) {
        draft.companySettings.user_feature_visibility_flags = state.previousFeatureVisibilityFlags;
      }
      draft.previousFeatureVisibilityFlags = undefined;
    }),
  )
  .handleAction(actions.updateIntegrationsFlagsAction, state =>
    produce(state, draft => {
      draft.updatingIntegrationsFlags = true;
      draft.updatedIntegrationsFlags = false;
      draft.updatingIntegrationsFlagsFailed = false;
    }),
  )
  .handleAction(actions.updateIntegrationsFlagsSuccessAction, state =>
    produce(state, draft => {
      draft.updatingIntegrationsFlags = false;
      draft.updatedIntegrationsFlags = true;
      draft.updatingIntegrationsFlagsFailed = false;
    }),
  )
  .handleAction(actions.updateIntegrationsFlagsFailureAction, state =>
    produce(state, draft => {
      draft.updatingIntegrationsFlags = false;
      draft.updatedIntegrationsFlags = false;
      draft.updatingIntegrationsFlagsFailed = true;
    }),
  )
  .handleAction(actions.updateDynamicFlagsAction, (state, action) =>
    produce(state, draft => {
      draft.updatingDynamicFlags = action.payload;
      draft.updatedDynamicFlags = false;
      draft.updatingDynamicFlagsFailed = false;
    }),
  )
  .handleAction(actions.updateDynamicFlagsSuccessAction, state =>
    produce(state, draft => {
      draft.updatingDynamicFlags = false;
      draft.updatedDynamicFlags = true;
      draft.updatingDynamicFlagsFailed = false;
    }),
  )
  .handleAction(actions.updateDynamicFlagsFailureAction, state =>
    produce(state, draft => {
      draft.updatingDynamicFlags = false;
      draft.updatedDynamicFlags = false;
      draft.updatingDynamicFlagsFailed = true;
    }),
  );
