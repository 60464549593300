import cn from "classnames/bind";
import React from "react";
import { useSelector } from "react-redux";

import { Row, Col, Grid } from "antd";
import { Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";
import TikTokPreviewList from "./TikTokPreview";

import { fetchingTrendingVideosSelector } from "@store/tiktokTrending/selectors";

import s from "./TiktokTrending.module.less";

import { getCountriesFlags } from "@bbdevcrew/bb_ui_kit_fe";
import { COLUMN_SIZES, MAX_SHOW_FLAG_COUNT } from "./TiktokTrending.helpers";
import { TableRowProps } from "./TiktokTrending.types";
import { TrendingModeEnum } from "@store/tiktokTrending/types";

const cx = cn.bind(s);

const TrendingTableRow = React.memo(({ data: row, index, mode }: TableRowProps) => {
  const screens = Grid.useBreakpoint();

  const isHashtagMode = mode === TrendingModeEnum.HASHTAGS;
  const columnsSize = COLUMN_SIZES[mode][screens.lg ? "lg" : screens.md ? "md" : "sm"];

  const isFetchingVideos = useSelector(fetchingTrendingVideosSelector);

  return (
    <Row
      className={cx(s.bbTableRow, {
        [s.bbTableRowColored]: index % 2 === 1,
      })}
    >
      <Col className={s.bbColumn} span={columnsSize[0]}>
        <div className={s.bbRankColumn}>
          <span>{row.rank.position}</span>
          {row.rank.change && (
            <span>
              {row.rank.change.type !== "NEW" ? (
                <PerformanceCardPill
                  percentageValue={
                    row.rank.change.type === "POSITIVE"
                      ? Number(row.rank.change.value)
                      : -Number(row.rank.change.value)
                  }
                  showPercentSymbol={false}
                />
              ) : (
                <span className={s.bbNewChip}>{row.rank.change.value}</span>
              )}
            </span>
          )}
        </div>
      </Col>
      <Col className={cx(s.bbColumn, s.bbHashtagColumn)} span={columnsSize[1]}>
        {isHashtagMode ? (
          row.name
        ) : (
          <div className={s.bbSong}>
            <img src={row.thumbnail_url} alt="Song image" />
            <div className={s.bbSongContent}>
              <span className={s.bbSongName}>{row.name}</span>
              <span className={s.bbSongArtist}>{row.artist}</span>
            </div>
          </div>
        )}
      </Col>
      <Col className={s.bbColumn} span={columnsSize[2]}>
        {isFetchingVideos ? (
          <Loading />
        ) : !!row.videos?.length ? (
          <TikTokPreviewList videos={row.videos} mode={mode} />
        ) : (
          "-"
        )}
      </Col>
      {isHashtagMode ? (
        <>
          <Col className={s.bbColumn} span={columnsSize[3]}>
            {row.numberOfPosts}
          </Col>
          <Col className={s.bbColumn} span={columnsSize[4]}>
            {row.numberOfViews === "-1" ? "-" : row.numberOfViews}
          </Col>
          <Col className={s.bbColumn} span={columnsSize[5]}>
            <span className={s.bbCountryFlag}>
              {getCountriesFlags(row.trendingCountries || [], MAX_SHOW_FLAG_COUNT)}
            </span>
          </Col>
        </>
      ) : (
        <Col className={s.bbColumn} span={columnsSize[3]}>
          {row.genres?.join(", ") || "-"}
        </Col>
      )}
    </Row>
  );
});

TrendingTableRow.displayName = "TrendingTableRow";
export default TrendingTableRow;
