import React from "react";

import { FormErrorMessage } from "@bbdevcrew/bb_ui_kit_fe";

import { RuleObject } from "antd/lib/form";

export const optionRequiredValidator = (errMsg: string) => ({
  required: true,
  validator: (_: RuleObject, value: string[]) => {
    if (!value || value.length === 0) {
      return Promise.reject(<FormErrorMessage>{errMsg}</FormErrorMessage>);
    }
    return Promise.resolve();
  },
});
