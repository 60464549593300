import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Breadcrumbs from "../../_common/breadcrumbs";
import TrendingTable from "./TrendingTable";
import { TiktokTrendingFilters } from "./TiktokTrendingFilters";

import {
  trendingHashtagsSelector,
  fetchingTrendingSelector,
  trendingSongsSelector,
} from "@store/tiktokTrending/selectors";

import { getTrendingItemsAction, getTrendingVideosAction } from "@store/tiktokTrending/actions";

import s from "./TiktokTrending.module.less";

import { getTrendingIds } from "./TiktokTrending.helpers";
import { IFilterValue, TrendingModeEnum } from "@store/tiktokTrending/types";

import { HashIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { MusicIcon } from "@assets/index";

export const TiktokTrending = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filterValues, setFilterValues] = useState<IFilterValue>({
    industryId: "ALL",
    countryId: "US",
    dateRangeId: "7DAY",
    genre: "ALL",
  });
  const [page, setPage] = useState<number>(1);
  const [mode, setMode] = useState<TrendingModeEnum>(TrendingModeEnum.HASHTAGS);

  const isHashtagMode = mode === TrendingModeEnum.HASHTAGS;

  const trendingHashtagsList = useSelector(trendingHashtagsSelector);
  const fetchingTrending = useSelector(fetchingTrendingSelector);
  const trendingSongsList = useSelector(trendingSongsSelector);

  const getTrending = useCallback(
    (data, trendingMode) => dispatch(getTrendingItemsAction(data, trendingMode)),
    [dispatch],
  );

  const currentTrendingHashtagsIds = useMemo(() => {
    return getTrendingIds(trendingHashtagsList, page);
  }, [page, trendingHashtagsList]);

  const currentTrendingSongsIds = useMemo(() => {
    return getTrendingIds(trendingSongsList, page);
  }, [page, trendingSongsList]);

  useEffect(() => {
    const { industryId, genre, ...rest } = filterValues;
    if (isHashtagMode) {
      getTrending({ ...rest, industryId }, TrendingModeEnum.HASHTAGS);
    } else {
      getTrending({ ...rest, genre }, TrendingModeEnum.SONGS);
    }
  }, [filterValues, getTrending, isHashtagMode]);

  useEffect(() => {
    const { industryId, genre, ...rest } = filterValues;
    if (isHashtagMode && !!currentTrendingHashtagsIds.length) {
      dispatch(
        getTrendingVideosAction(
          currentTrendingHashtagsIds.split(","),
          {
            ...rest,
            industryId,
          },
          TrendingModeEnum.HASHTAGS,
        ),
      );
    } else if (!isHashtagMode && !!currentTrendingSongsIds.length) {
      dispatch(
        getTrendingVideosAction(
          currentTrendingSongsIds.split(","),
          {
            ...rest,
            genre,
          },
          TrendingModeEnum.SONGS,
        ),
      );
    }
  }, [
    page,
    filterValues,
    currentTrendingHashtagsIds,
    currentTrendingSongsIds,
    dispatch,
    isHashtagMode,
  ]);

  const onChangeFilter = (filter: keyof IFilterValue) => (value: string) => {
    setFilterValues(prev => ({ ...prev, [filter]: value }));
    setPage(1);
  };

  const onTablePageChange = (value: number) => setPage(value);

  return (
    <>
      <Breadcrumbs title={t("components:listen:trending:title")} />
      <div className={s.bbTTTrending}>
        <div className={s.bbTTTrendingHeader}>
          <div className={s.bbTTTrendingType}>
            <div
              className={cx(s.bbTTTrendingHastags, {
                [s.bbTTTrendingTypeActive]: isHashtagMode,
              })}
              onClick={() => setMode(TrendingModeEnum.HASHTAGS)}
            >
              <HashIcon />
              <span>{t("components:listen:trending:hashtags")}</span>
            </div>
            <div
              className={cx(s.bbTTTrendingSongs, {
                [s.bbTTTrendingTypeActive]: !isHashtagMode,
              })}
              onClick={() => setMode(TrendingModeEnum.SONGS)}
            >
              <MusicIcon />
              <span>{t("components:listen:trending:songs")}</span>
            </div>
          </div>
          <TiktokTrendingFilters
            value={filterValues}
            onChange={onChangeFilter}
            isHashtagMode={isHashtagMode}
          />
        </div>
        <TrendingTable
          data={isHashtagMode ? trendingHashtagsList : trendingSongsList}
          loading={fetchingTrending}
          onChangePage={onTablePageChange}
          page={page}
          mode={mode}
        />
      </div>
    </>
  );
};
