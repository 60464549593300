import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Empty, Grid } from "antd";
import { PieChart, IPieChartSector } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./UserLanguagesChart.module.less";

import { IPieChartUnit } from "../../../sentimentDetails/SentimentDetails.type";
import { IUserLanguagesChartItem } from "@store/overview/types";
import { IUserLanguageSector, IUserLanguagesChartProps } from "./UserLanguagesChart.type";
import classNames from "classnames";

const UserLanguagesChart: React.FC<IUserLanguagesChartProps> = ({
  data,
  chartWidth,
  colorData,
  filterData,
  filterToSpecificItem,
}) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isMobile = screens.md === false;

  const generateChartData = (items: IUserLanguagesChartItem[]) => {
    let chartData: IPieChartUnit[] = [];

    chartData = items.map((item, index) => {
      return {
        name: item.label || "",
        rawName: item.id || "",
        value: item.count || 0,
        color: colorData[index % colorData.length],
        tooltipString: item.label || "",
      };
    });

    return chartData;
  };

  const filterByLanguage = (languageId: string) => {
    const filters = {
      ...filterData,
      languages: [languageId] as string[],
    };
    filterToSpecificItem?.(filters);
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: { filters },
      },
    );
  };

  const onSectorClick = (activeSectorData: IPieChartSector) => {
    const sectorData: IUserLanguageSector = activeSectorData;

    sectorData.rawName && filterByLanguage(sectorData.rawName);
  };

  return (
    <>
      {data?.length ? (
        <>
          <div
            data-cy="overview-user-languages-chart"
            className={classNames(s.bbUserLanguagesChart, {
              [s.bbUserLanguagesChartMobile]: isMobile,
            })}
          >
            <PieChart
              key={Math.random()}
              data={generateChartData(data)}
              width={chartWidth / 2 - 50}
              height={350}
              isShowPercent
              percentagePrecision={1}
              enableCursor="pointer"
              hoverStrokeWidth={90}
              hoverStrokeInnerRadius={90}
              hoverStrokeOuterRadius={90}
              onSectorClick={onSectorClick}
              defaultValue={{
                name: data[0] ? data[0].label : "",
                value: data[0] ? data[0].count : 0,
                color: colorData[0],
              }}
              pieProps={{
                stroke: "none",
                nameKey: "name",
                dataKey: "value",
                cx: 82,
                cy: 140,
                labelLine: false,
                paddingAngle: 0,
                key: Math.random(),
                innerRadius: chartWidth < 350 ? 40 : 50,
                outerRadius: chartWidth < 350 ? 110 : 140,
                animationDuration: 500,
              }}
            />
          </div>
          <div className={s.bbUserLanguagesChartLegend} data-cy="user-languages-chart-legend">
            {data.map((item, index) => (
              <div
                key={item.id}
                className={s.bbUserLanguagesChartLegendItem}
                onClick={() => filterByLanguage(item.id)}
              >
                <div>
                  <div
                    className={s.bbLegendItemBullet}
                    data-cy="user-languages-chart-legend-dot"
                    style={{
                      backgroundColor: colorData[index % colorData.length],
                    }}
                  ></div>
                  <span className={s.bbLegendItemLabel} data-cy="user-languages-chart-legend-label">
                    {item.id}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={s.bbUserLanguagesEmptyContainer}>
          <Empty
            className={s.bbUserLanguagesEmpty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:empty:description")}
          />
        </div>
      )}
    </>
  );
};

export default UserLanguagesChart;
