import { useTranslation } from "react-i18next";
import React, { useMemo, useState, useContext } from "react";
import classNames from "classnames";

import { FormInstance, Grid } from "antd";
import { IPostCreationFormType } from "./helpers/types";
import { PostCreationButton } from "./PostCreationButton";
import { ICreatePublishingPayload } from "@store/publishings/types";
import { Button, DeleteIcon, DeleteConfirmation } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./index.module.less";

import CalendarContext from "../calendar/context/CalendarContext";
import PostCreationContext from "./context/PostCreationContext";

import { AlertTriangleFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IPostCreationFooterProps {
  submitDisabled: boolean;
  hasSelectedAssets: boolean;
  form: FormInstance<IPostCreationFormType>;
  onDelete: (id: string) => void;
  onFinish: (payload: ICreatePublishingPayload) => void;
}

export function PostCreationFooter({
  form,
  submitDisabled,
  hasSelectedAssets,
  onFinish,
  onDelete,
}: IPostCreationFooterProps) {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  const { posts } = useContext(PostCreationContext);
  const { editingPost, isPostModalOpen } = useContext(CalendarContext);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const isMobile = screens.md === false;

  const isEditMode = isPostModalOpen && !!editingPost;
  const postsHaveErrors = useMemo(() => !!posts.some(post => post.error), [posts]);

  const handleToggleDeleteConfirmation = () => {
    setShowDeleteConfirmation(prev => !prev);
  };

  const handleDeletePost = () => {
    if (isEditMode) onDelete(editingPost.id);
  };

  return (
    <div
      className={classNames(s.bbPostCreationFooter, {
        [s.bbPostCreationFooterMobile]: isMobile,
        [s.bbPostCreationFooterMobileWithErrors]: isMobile && postsHaveErrors,
      })}
    >
      {isEditMode && (
        <Button
          _type="link"
          _size="sm"
          className={s.bbDeleteButton}
          onClick={handleToggleDeleteConfirmation}
        >
          <div className={s.bbDeleteButtonIcon}>
            <DeleteIcon />
          </div>
          {t("components:publish:postCreationModal:delete:buttonText")}
        </Button>
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          message={t("components:publish:postCreationModal:delete:confirmationMessage")}
          onConfirm={handleDeletePost}
          onCancel={handleToggleDeleteConfirmation}
          className={s.bbDeleteConfirmation}
        />
      )}
      {postsHaveErrors && (
        <div
          className={classNames(s.bbFormGenericError, { [s.bbFormGenericErrorMobile]: isMobile })}
        >
          <AlertTriangleFilledIcon />
          <div className={s.bbFormGenericErrorText}>
            {t("components:publish:postCreationModal:errors:genericError")}
          </div>
        </div>
      )}
      <PostCreationButton
        form={form}
        onFinish={onFinish}
        disabled={submitDisabled}
        hasSelectedAssets={hasSelectedAssets}
      />
    </div>
  );
}
