import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Help } from "@bbdevcrew/bb_ui_kit_fe";
import MultiAttachmentPreview from "./MultiAttachmentPreview";
import SingleAttachmentPreview from "./SingleAttachmentPreview";
import PostCard from "../../../insights/_common/postCard/PostCard";

import s from "./index.module.less";
import postStyles from "../index.module.less";

import {
  LINK_IMAGE_FRAME_WIDTH,
  MAX_UPLOAD_IMAGE_COUNT_X,
  TEXTAREA_MAX_CHAR_LENGTH_X,
} from "../helpers/constants";
import { IXPostPreviewProps } from "./types";
import { extractDomain } from "../helpers/utilsGeneric";
import { assignStyleToImages, assignPageTopPadding, getPlayIconTopOffset } from "./helpers";

import { PostIcon } from "@bbdevcrew/bb_ui_kit_fe";
import XActions from "@assets/PostPreview/PostPreviewXActions.svg";

const XPostPreview: FC<IXPostPreviewProps> = ({ post, attachments = [] }) => {
  const { t } = useTranslation();

  const totalNumberOfAttachments = attachments.length || 0;

  const imagesWithStyles = assignStyleToImages(attachments);
  const windowPadding = totalNumberOfAttachments > 1 ? assignPageTopPadding(attachments.length) : 0;

  const customImageRender = (
    <>
      {post.asset.preview_link ? (
        <div>
          <div className={s.bbImagePreviewWrapper}>
            <div className={s.bbLinkPreview}>
              {
                <div
                  style={{
                    minWidth: LINK_IMAGE_FRAME_WIDTH,
                  }}
                  className={s.bbImgWrapper}
                >
                  {!!post.asset.preview_link.image ? (
                    <img src={post.asset.preview_link.image} alt={post.asset.preview_link.title} />
                  ) : (
                    <PostIcon />
                  )}
                  <div className={s.bbLinkPreviewMetaTitle}>{post.asset.preview_link.title}</div>
                </div>
              }
            </div>
          </div>
          <div className={s.bbLinkPreviewMetaUrl}>
            {t("generic:from")}&nbsp;
            {extractDomain(post.asset.preview_link.url) || post.asset.preview_link.url}
          </div>
        </div>
      ) : attachments.length ? (
        <div className={s.bbImagePreviewWrapper} style={{ paddingTop: `${windowPadding}%` }}>
          {totalNumberOfAttachments === 1 ? (
            <SingleAttachmentPreview attachment={attachments[0]} />
          ) : (
            <>
              {imagesWithStyles?.map((image, index) => (
                <MultiAttachmentPreview
                  attachment={image.image}
                  style={{
                    ...image.styles,
                  }}
                  playIconTopOffset={getPlayIconTopOffset(totalNumberOfAttachments, index)}
                  key={image.image.id}
                />
              ))}
            </>
          )}
        </div>
      ) : null}
    </>
  );

  if (!attachments || attachments.length > MAX_UPLOAD_IMAGE_COUNT_X) return null;

  return (
    <>
      <div className={postStyles.bbPostCreationPreviewTitle}>
        {t("components:publish:postCreationModal:preview:title", {
          platform: t("generic:platforms:x"),
        })}
        <Help
          iconType="info"
          iconClassName={postStyles.bbPostPreviewInfo}
          tooltipProps={{
            title: t("components:publish:postCreationModal:preview:tooltip"),
          }}
        />
      </div>
      <div className={s.bbXPostPreviewWrapper}>
        <div className={s.bbXPostPreview}>
          <PostCard
            showMoreText
            message={post.message}
            postTypeText={`• ${t("generic:now")}`}
            className={s.bbPostPreviewCard}
            maxMessageLength={TEXTAREA_MAX_CHAR_LENGTH_X}
            renderCardFooter={() => (
              <div className={s.bbXPostPreviewFooter}>
                <XActions />
              </div>
            )}
            pageImageProps={{
              pageName: post.asset.label,
              pageImage: post.asset.picture,
              pageImageShape: "circle",
            }}
            postImageProps={{ customImageRender }}
          />
        </div>
      </div>
    </>
  );
};

export default XPostPreview;
