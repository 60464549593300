import classNames from "classnames";
import React, { useCallback, useMemo } from "react";

import { SideNav } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./WorkflowsMenu.module.less";

import { useWorkflows } from "../helpers";
import { mapWorkflowCategoriesToSideNav } from "./helpers";

const WorkflowsMenu = () => {
  const { categories, items, activeCategory, setCategory } = useWorkflows();

  const categoryItemCount = useCallback(
    (ids: string[]) => items.filter(({ id }) => ids.includes(id)).length,
    [items],
  );

  const navItems = useMemo(
    () =>
      !!activeCategory && !!categories
        ? mapWorkflowCategoriesToSideNav(categories, categoryItemCount, activeCategory, setCategory)
        : [],
    [categories, activeCategory, categoryItemCount, setCategory],
  );

  return (
    <div className={classNames(s.bbWorkflowsMenu)}>
      <SideNav items={navItems} data-cy="workflows-menu" />
    </div>
  );
};

export default WorkflowsMenu;
