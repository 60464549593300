import React from "react";
import { Trans } from "react-i18next";

import s from "../index.module.less";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { IPostItem } from "../helpers/types";

interface ITTMusicDisclaimerProps {
  post: IPostItem;
}

export function TTMusicDisclaimer({ post }: ITTMusicDisclaimerProps) {
  const handleMusicUsageConfirmationClick = () => {
    window.open("https://www.tiktok.com/legal/page/global/music-usage-confirmation/en", "_blank");
  };

  const handleBrandContentPolicyClick = () => {
    window.open("https://www.tiktok.com/legal/page/global/bc-policy/en", "_blank");
  };

  const musicUsageConfirmationLink = (
    <span onClick={handleMusicUsageConfirmationClick}>Music Usage Confirmation</span>
  );

  return (
    <div className={s.bbTTMusicDisclaimer}>
      <InformationIcon />
      {post.tiktokOptions?.promote_others_content ? (
        <Trans i18nKey={"components:publish:postCreationModal:disclaimers:tiktokOthersBrand"}>
          <span onClick={handleBrandContentPolicyClick}>Branded Content Policy</span>
          {" and "}
          {musicUsageConfirmationLink}.
        </Trans>
      ) : (
        <Trans i18nKey={"components:publish:postCreationModal:disclaimers:tiktokOwnBrand"}>
          {musicUsageConfirmationLink}.
        </Trans>
      )}
    </div>
  );
}
