import { createAction } from "typesafe-actions";
import {
  DELETE_INTERNAL_NOTES,
  DELETE_INTERNAL_NOTES_FAILURE,
  DELETE_INTERNAL_NOTES_SUCCESS,
  DELETE_PREVIEW_PUBLISH_NOTES,
  GET_INTERNAL_NOTES,
  GET_INTERNAL_NOTES_FAILURE,
  GET_INTERNAL_NOTES_SUCCESS,
  POST_INTERNAL_NOTES,
  POST_INTERNAL_NOTES_FAILURE,
  POST_INTERNAL_NOTES_SUCCESS,
  POST_PREVIEW_PUBLISH_NOTES,
  POST_PUBLISH_NOTES,
  POST_PUBLISH_NOTES_FAILURE,
  POST_PUBLISH_NOTES_SUCCESS,
  RESET_INTERNAL_NOTES,
} from "./actionTypes";
import { IGetInternalNotesPayload, IPostNotesPayload, IPublishNotesPayload } from "./types";
import { IInternalNoteItem } from "@bbdevcrew/bb_ui_kit_fe";

export const getInternalNotesAction = createAction(GET_INTERNAL_NOTES)<IGetInternalNotesPayload>();
export const getInternalNotesSuccessAction = createAction(GET_INTERNAL_NOTES_SUCCESS)<
  IInternalNoteItem[]
>();
export const getInternalNotesFailureAction = createAction(GET_INTERNAL_NOTES_FAILURE)();

export const postInternalNotesAction = createAction(POST_INTERNAL_NOTES)<IPostNotesPayload>();
export const postInternalNotesSuccessAction = createAction(POST_INTERNAL_NOTES_SUCCESS)<
  IInternalNoteItem[]
>();
export const postInternalNotesFailureAction = createAction(POST_INTERNAL_NOTES_FAILURE)();

export const deleteInternalNoteAction = createAction(DELETE_INTERNAL_NOTES)<string>();
export const deleteInternalNoteSuccessAction = createAction(
  DELETE_INTERNAL_NOTES_SUCCESS,
)<string>();
export const deleteInternalNoteFailureAction = createAction(DELETE_INTERNAL_NOTES_FAILURE)();

export const postPublishNotesAction = createAction(POST_PUBLISH_NOTES)<IPostNotesPayload>();
export const postPublishNotesSuccessAction = createAction(POST_PUBLISH_NOTES_SUCCESS)<
  IInternalNoteItem[]
>();
export const postPublishNotesFailureAction = createAction(POST_PUBLISH_NOTES_FAILURE)();

export const postPreviewPublishNotesAction = createAction(
  POST_PREVIEW_PUBLISH_NOTES,
)<IPublishNotesPayload>();
export const deletePreviewPublishNotesAction = createAction(DELETE_PREVIEW_PUBLISH_NOTES)<string>();

export const resetInternalNotesAction = createAction(RESET_INTERNAL_NOTES)();
