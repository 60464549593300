import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Col, Row } from "antd";
import SectionDivider from "@components/insights/overview/OverviewSectionDivider";
import ProtectBreakdown from "./ProtectBreakdown";
import Timeline from "@components/_common/TimelineChart";
import DownloadCSV from "@components/_common/DownloadCSV";
import { ProtectComments } from "./ProtectComments";
import SafetySection from "../externalAppsOverview/SafetySection";

import {
  getDashboardHiddenCommentsSelector,
  hiddenBreakdownSelector,
} from "@store/dashboard/selectors";
import { meSelector } from "@store/me/selectors";

import s from "./Protect.module.less";

import {
  CHART_HEIGHT,
  getCSVTableHeaders,
  getCSVTimelineData,
  getTimelineLines,
} from "../Transparency.helpers";
import { IManagedProtectProps } from "../Transparency.types";
import { useYTSelected } from "@utils/useYTselected";

import { DownloadIcon } from "@assets/index";
import { SafetyIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ManagedProtects = ({
  filters,
  filterToSpecificItem,
  isLoading,
  timelineData,
}: IManagedProtectProps) => {
  const { t } = useTranslation();

  const isYTFilterSelected = useYTSelected(filters.platform_types || []);

  const me = useSelector(meSelector);
  const hiddenComments = useSelector(getDashboardHiddenCommentsSelector);
  const hiddenBreakdown = useSelector(hiddenBreakdownSelector);

  return (
    <div className={s.bbProtectWrapper}>
      <SectionDivider
        icon={<SafetyIcon />}
        title={t("components:managed:protect:title")}
        description={t("components:managed:protect:description", { name: me?.client?.name })}
      />
      <div className={s.bbProtectSection}>
        <Row gutter={[28, 28]}>
          <SafetySection withChartLegend={false} loading={isLoading} data={hiddenComments} />
          <Col xs={24} id="col-timeline">
            <Timeline
              filters={filters}
              chartHeight={CHART_HEIGHT}
              downloadCSVButton={
                <DownloadCSV
                  icon={<DownloadIcon />}
                  data={getCSVTimelineData(timelineData || [])}
                  fileNamePrefix="timeline"
                  tableHeaders={getCSVTableHeaders()}
                />
              }
              lines={getTimelineLines()}
              data={timelineData || []}
              filterToSpecificItem={filterToSpecificItem}
              headerClassName={s.bbTimelineHeader}
              customLegend={
                <span className={s.bbProtectBreakdownLegendSentiment}>
                  <div></div>
                  <span>{t("components:managed:protect:breakdown:legendTitle")}</span>
                </span>
              }
            />
          </Col>
          <Col xs={24} className={s.bbProtectBreakdown}>
            <ProtectBreakdown
              data={hiddenBreakdown}
              filterToSpecificItem={filterToSpecificItem}
              disabled={isYTFilterSelected}
            />
          </Col>
          <Col xs={24}>
            <ProtectComments filterToSpecificItem={filterToSpecificItem} filters={filters} />
          </Col>
        </Row>
      </div>
    </div>
  );
};
