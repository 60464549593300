import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useLocation, Route, Routes, useSearchParams } from "react-router-dom";

import LoginRoutes from "./LoginRoutes";
import CareRoutes from "./CareRoutes";
import PublishRoutes from "./PublishRoutes";
import SettingsRoutes from "./SettingsRoutes";
import InsightsRoutes from "./InsightsRoutes";
import ManagedRoutes from "./ManagedRoutes";
import ReportRoutes from "./ReportRoutes";
import ControlPanelRoutes from "./ControlPanelRoutes";

import {
  getTokenSelector,
  currentSessionSelector,
  currentSessionSuccessfulSelector,
} from "@store/auth/selectors";
import { getMeAction, patchMeAction } from "@store/me/actions";
import { meSelector, meSuccessfulSelector } from "@store/me/selectors";
import { currentAuthenticateUserAction, getCurrentSessionAction } from "@store/auth/actions";

import { CleanPathAndNavigate } from "./helpers";
import { IAppRoutesContainerProps } from "./Routes.type";
import { dayjs, addToast, deleteAllCookies, removeItemUrlSearch } from "@bbdevcrew/bb_ui_kit_fe";

const RoutesContainer: FC<IAppRoutesContainerProps> = ({ appBaseName }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [path, setPath] = useState("/");

  const currentUser = useCallback(() => dispatch(currentAuthenticateUserAction()), [dispatch]);
  const currentSession = useCallback(() => dispatch(getCurrentSessionAction()), [dispatch]);

  const fetchMe = useCallback(() => dispatch(getMeAction()), [dispatch]);
  const updateMe = useCallback(data => dispatch(patchMeAction(data)), [dispatch]);

  const me = useSelector(meSelector);
  const isLogged = useSelector(getTokenSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const session = useSelector(currentSessionSelector);
  const sessionFetched = useSelector(currentSessionSuccessfulSelector);

  // Check current user and session
  useEffect(() => {
    currentUser();
    currentSession();
    // eslint-disable-next-line
  }, []);

  // check session, redirect to login if session expired
  useEffect(() => {
    if (
      sessionFetched &&
      typeof session === "string" &&
      session === "No current user" &&
      appBaseName !== "login"
    ) {
      // Save the current page to local storage
      localStorage.setItem("savedPreAuthURL", window.location.pathname + window.location.search);

      window.location.href = process.env.BASE_URL + window.location.search;
    }
    // eslint-disable-next-line
  }, [sessionFetched, session]);

  useEffect(() => {
    if (isLogged && !meFetched) fetchMe();
  }, [isLogged, meFetched, fetchMe]);

  const clearModuleSpecificQueryParams = useCallback(() => {
    const hasParams = (params: string[]) => {
      return params.filter(param => searchParams.has(param));
    };

    const listenSpecificParams = ["section"];

    if (
      searchParams &&
      hasParams(listenSpecificParams).length &&
      appBaseName !== "insights" &&
      appBaseName !== "control_panel"
    ) {
      listenSpecificParams.forEach(param => removeItemUrlSearch(param));
    }
  }, [searchParams, appBaseName]);

  useEffect(() => {
    if (location.pathname !== path) {
      setPath(location.pathname);
    }

    clearModuleSpecificQueryParams();
  }, [location, path, clearModuleSpecificQueryParams]);

  const handleUnverifiedEmail = useCallback(() => {
    addToast({
      type: "danger_accent",
      title: t("components:unverifiedEmail:message"),
      message: t("components:unverifiedEmail:description"),
    });

    setTimeout(() => {
      Auth.signOut({ global: true })
        .then(() => {
          window.location.href = `${process.env.BASE_URL}`;
          localStorage.removeItem("query");
        })
        .catch(() => {
          deleteAllCookies();
          window.location.reload();
        });
    }, 2000);
  }, [t]);

  useEffect(() => {
    if (meFetched && !me?.email_verified) {
      handleUnverifiedEmail();
    } else {
      if (meFetched && !me?.timezone) {
        updateMe({
          timezone: (dayjs as any).tz.guess(), // eslint-disable-line
        });
      }
    }
  }, [meFetched, me, updateMe, handleUnverifiedEmail]);

  return (
    <>
      {isLogged && me?.features && path && meFetched ? (
        <>
          {appBaseName === "insights" ? (
            <InsightsRoutes
              path={path}
              menu={me?.features || []}
              hasMenu={!!me?.has_assets}
              pendingAssets={me?.pending_assets || []}
            />
          ) : appBaseName === "care" ? (
            <CareRoutes
              path={path}
              menu={me?.features || []}
              hasMenu={!!me?.has_assets}
              pendingAssets={me?.pending_assets || []}
            />
          ) : appBaseName === "publish" ? (
            <PublishRoutes path={path} menu={me?.features || []} />
          ) : appBaseName === "managed" ? (
            <ManagedRoutes path={path} menu={me?.features || []} />
          ) : appBaseName === "control_panel" ? (
            <ControlPanelRoutes path={path} menu={me?.features || []} />
          ) : appBaseName === "settings" ? (
            <SettingsRoutes path={path} menu={me?.features || []} />
          ) : appBaseName === "report" ? (
            <ReportRoutes path={path} menu={[]} />
          ) : (
            <Routes>
              <Route path="*" element={<CleanPathAndNavigate />} />
            </Routes>
          )}
        </>
      ) : (
        <>{(appBaseName === "login" || window.location.pathname === "/") && <LoginRoutes />}</>
      )}
    </>
  );
};

export default RoutesContainer;
