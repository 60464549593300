import React, { useCallback, useEffect, useRef, useState } from "react";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";

import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { isUserTag, useTagsContext } from "../../../TagsModal.helpers";

import { IProductTag, IUserTag } from "../../../TagsModal.types";
import { ITagProps } from "./Tag.types";

import s from "./Tag.module.less";

import { DragIndicationIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const Tag: React.FC<ITagProps> = ({ tag, ...restProps }) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const deleteRef = useRef<HTMLButtonElement>(null);
  const { offset, addTag, deleteTag } = useTagsContext();

  const [boundaryOffset, setBoundaryOffset] = useState(0);
  const [initialBoundaryCalculated, setInitialBoundaryCalculated] = useState(false);

  const calculateBoundaryOffset = useCallback(() => {
    if (innerRef.current) {
      const tagOffset = innerRef.current.getBoundingClientRect();
      const right = Math.max(
        0,
        tagOffset.x + tagOffset.width - boundaryOffset - (offset.x + offset.width),
      );
      const left = Math.min(0, tagOffset.x - offset.x - boundaryOffset);
      const distance = 0 - (left === 0 ? right : left);
      setBoundaryOffset(distance !== 0 ? (distance > 0 ? distance - 12 : distance + 12) : 0);
    }
  }, [offset, boundaryOffset]);

  const onDragStop = useCallback(
    (e: DraggableEvent, { x, y }: DraggableData) => {
      const newX = x / offset.width;
      const newY = y / offset.height;
      addTag?.({
        ...tag,
        x: newX,
        y: newY,
      });
      calculateBoundaryOffset();
    },
    [offset, tag, addTag, calculateBoundaryOffset],
  );

  useEffect(() => {
    if (innerRef.current && offset.x && !initialBoundaryCalculated) {
      calculateBoundaryOffset();
      setInitialBoundaryCalculated(true);
    }
  }, [calculateBoundaryOffset, offset, initialBoundaryCalculated]);

  return (
    <Draggable
      position={{
        x: tag.x * offset.width,
        y: tag.y * offset.height,
      }}
      bounds={{
        left: 0,
        top: 0,
        right: offset.width,
        bottom: offset.height,
      }}
      onStart={e => {
        const deleteClicked =
          e.target &&
          deleteRef.current &&
          (deleteRef.current === e.target || deleteRef.current.contains(e.target as Node));
        if (!deleteClicked) setBoundaryOffset(0);
      }}
      onStop={onDragStop}
    >
      <div className={s.bbTag} {...restProps}>
        <span className={s.bbTagArrow}></span>
        <div
          className={s.bbTagInner}
          ref={innerRef}
          style={{ transform: `translate(calc(-50% + ${boundaryOffset}px), 6px)` }}
        >
          <DragIndicationIcon />
          {isUserTag(tag) ? (tag as IUserTag).username : (tag as IProductTag).product_name}
          <button
            ref={deleteRef}
            onClick={() => {
              deleteTag(tag);
            }}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </Draggable>
  );
};
