import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useCallback, useState, MouseEvent } from "react";

import { Form, Typography } from "antd";
import { Modal, Button, Filters, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import ProjectListItem from "./ProjectListItem";

import { clientDataSelector } from "@store/me/selectors";
import { deleteCompareProject } from "@store/compareProjects/actions";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./CompareHeader.module.less";

import {
  debounce,
  IDynamicFilter,
  IDateRangeProps,
  IFormFilterRequest,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ICompareHeaderProps } from "./Compare.type";
import { RangeValue } from "rc-picker/lib/interface";
import { getFetchValuesFieldName } from "@utils/filters";
import { ICompareProject } from "@store/compareProjects/types";
import { useGetDefaultFiltersConfig } from "../../_common/AppFilter/config";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const CompareHeader: FC<ICompareHeaderProps> = ({
  onFilter,
  filterData,
  onProjectChange,
  selectedProjectId,
  onNewProjectCreate,
  onEditProjectClick,
  compareProjectOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filtersForm] = Form.useForm<IFormFilterRequest>();
  const filtersConfig = useGetDefaultFiltersConfig(t, false, filtersForm);

  const [projectToRemove, setProjectToRemove] = useState<ICompareProject | undefined>();
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);

  const clientData = useSelector(clientDataSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  const selectedProject = compareProjectOptions.find(project => project.id === selectedProjectId);
  const isCustomProject = selectedProject?.compare_type === "custom";
  const isYTSelected = selectedProject?.enable_all_time_date_filters === false;

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const getCustomFilters = () =>
    filtersConfig
      .filter(({ name }) => name === "data_range_option")
      .map((filter: IDynamicFilter<IDateRangeProps>) => ({
        ...filter,
        tooltip: isCustomProject
          ? t("components:comparesPanel:header:customProjectDateRangeTooltip")
          : filter.tooltip,
        props: {
          ...filter.props,
          placeholder: t("generic:choose"),
          isYTSelected: () => isYTSelected,
          // Antd v3+ does not trigger onValuesChange on `form.setFieldValue()` no more.
          // Suggestion: push for regular form handling (with Apply btn), rather than this on fly handling
          onChangeDataRangeOption: (value: string) => {
            if (value === "custom") return;

            if (value === "") return onFilter({});

            return onFilter(value ? { data_range_option: value } : {});
          },
          onCalendarChange: (value: RangeValue<Dayjs>) => {
            // Trigger if both dates are set
            // TODO: Remove once new DatePicker component is implemented
            if (value?.length === 2 && value[1] !== null) {
              return onFilter({
                data_range_option: "custom",
                start_time: value[0]?.toISOString(),
                end_time: value[1].toISOString(),
              });
            }
          },
          onClear: () => onFilter({}),
        },
      }));

  const removeCompareProject = useCallback(
    (id: string) => dispatch(deleteCompareProject(id)),
    [dispatch],
  );

  const onRemoveCompareProjectClick = (event: MouseEvent, project: ICompareProject) => {
    event.stopPropagation();
    setProjectToRemove(project);
    setIsProjectDropdownOpen(false);
  };

  const onInternalEditProjectClick = (event: MouseEvent, project: ICompareProject) => {
    onEditProjectClick(event, project);
    setIsProjectDropdownOpen(false);
  };

  const onConfirmRemoveProject = () => {
    if (projectToRemove?.id) {
      removeCompareProject(projectToRemove.id);
      setProjectToRemove(undefined);
    }
  };

  return (
    <div className={s.bbCompareHeader} data-cy="compare-panel-header">
      <div className={s.bbCompareLeft} data-cy="compare-panel-header-left">
        <div className={s.bbHeaderSelectField}>
          <label className={s.bbProjectsSelectLabel} data-cy="compare-panel-project-select-label">
            {t("components:comparesPanel:header:comparisonProject")}
          </label>

          <SelectStyled
            useDynamicPlacement
            open={isProjectDropdownOpen}
            onDropdownOpen={() => setIsProjectDropdownOpen(true)}
            onDropdownClose={() => setIsProjectDropdownOpen(false)}
            _size="sm"
            dropdownMatchSelectWidth={false}
            tabIndex={-1}
            options={compareProjectOptions.map(project => ({
              ...project,
              id: project.id as string,
              label: project.name,
            }))}
            value={selectedProjectId}
            onChange={onProjectChange}
            placeholder={t("generic:choose")}
            data-cy="compare-panel-project-select"
            data-stonly-target="intelligence-compare__comparison-project-dropdown"
            wrapperClassName={s.bbProjectsSelect}
            ListItemVariation={ProjectListItem({
              onEditProjectClick: onInternalEditProjectClick,
              onRemoveCompareProjectClick,
            })}
          />
        </div>

        <div className={s.bbHeaderSelectField}>
          <Filters
            form={filtersForm}
            onFilter={() => {}} // eslint-disable-line
            initialFilterData={filterData}
            filtersConfig={getCustomFilters()}
            clientPlatformTypes={clientData?.platform_types || []}
            autocompleteOptions={autocompleteOptions}
            getAutocompleteOptions={getAutocompleteOptions}
            sanitizeFieldName={getFetchValuesFieldName}
          />
        </div>
      </div>
      <div className={s.bbCompareRight} data-cy="compare-panel-header-right">
        <Button
          _type="primary"
          onClick={onNewProjectCreate}
          className={s.bbCompareRightCreateBtn}
          data-cy="compare-panel-header-create-btn"
          data-stonly-target="intelligence-compare__new-project-button"
        >
          <span className={s.bbCompareRightCreateBtnIcon}>
            <PlusIcon />
          </span>
          {t("components:comparesPanel:header:newProject")}
        </Button>
      </div>

      <Modal
        onOk={onConfirmRemoveProject}
        open={!!projectToRemove}
        onCancel={() => setProjectToRemove(undefined)}
        title={t("components:comparesPanel:removeProjectModal:title")}
      >
        <Typography.Paragraph>
          {t("components:comparesPanel:removeProjectModal:text", {
            deleteName: projectToRemove?.name || "",
          })}
        </Typography.Paragraph>
      </Modal>
    </div>
  );
};

export default CompareHeader;
