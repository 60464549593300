import dayjs, { Dayjs } from "dayjs";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form } from "antd";
import { DatePicker, FormErrorMessage, Label, TimePicker, Toggle } from "@bbdevcrew/bb_ui_kit_fe";
import Timezone from "@components/_common/Timezone";

import { meSelector } from "@store/me/selectors";

import s from "./HideWorkflowAction.module.less";

import { FormInstance } from "antd";

interface IHideWorkflowActionFieldsWrapperProps {
  fieldId: string;
  fieldLabel: string;
  checked: boolean;
  error?: string;
  value: string;
  onCheckChange: (value: boolean) => void;
  onDateChange: (date: Dayjs) => void;
  onTimeChange: (date: Moment | null) => void;
}

const HideWorkflowActionFieldsWrapper = ({
  fieldId,
  fieldLabel,
  checked,
  error,
  value,
  onCheckChange,
  onDateChange,
  onTimeChange,
}: IHideWorkflowActionFieldsWrapperProps) => {
  const me = useSelector(meSelector);

  return (
    <div className={s.bbWorkflowItemFormHideActionToggleWrapper}>
      <div className={s.bbWorkflowItemFormHideActionToggle}>
        <Toggle
          id={`${fieldId}-toggle`}
          checked={checked}
          onChange={() => onCheckChange(!checked)}
        />
        <Label htmlFor={`${fieldId}-toggle`} text={fieldLabel} />
      </div>
      {checked && (
        <>
          <Form.Item noStyle name={fieldId.split(".")} />
          <div className={s.bbWorkflowItemFormHideActionPickersWrapper}>
            <DatePicker
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              picker="date"
              id={fieldId}
              name={fieldId}
              format="DD MMM, YYYY"
              value={value ? dayjs(value) : null}
              allowClear={false}
              onChange={onDateChange}
              className={s.bbWorkflowItemFormHideActionField}
            />
            <TimePicker
              id={fieldId}
              name={fieldId}
              use12Hours={me?.calendar_12_hour_format}
              format={me?.calendar_12_hour_format ? "hh:mm" : "HH:mm"}
              value={value ? moment(value) : null}
              allowClear={false}
              onChange={onTimeChange}
              className={s.bbWorkflowItemFormHideActionField}
            />
          </div>
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </>
      )}
    </div>
  );
};

const HideWorkflowAction = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation();
  const startModerationAtFromForm = form.getFieldValue(["trigger", "filters", "start_time"]);
  const endModerationAtFromForm = form.getFieldValue(["trigger", "filters", "end_time"]);
  const [showStartModerationAt, setShowStartModerationAt] = useState(!!startModerationAtFromForm);
  const [showEndModerationAt, setShowEndModerationAt] = useState(!!endModerationAtFromForm);
  const [moderationDateRange, setModerationDateRange] = useState({
    start_time: startModerationAtFromForm,
    end_time: endModerationAtFromForm,
  });
  const [moderationDateRangeErrors, setModerationDateRangeErrors] = useState({
    start_time: "",
    end_time: "",
  });

  const me = useSelector(meSelector);

  const setModerationDateRangeByName = (
    name: "start_time" | "end_time",
    dateString: string | undefined,
  ) => {
    form.setFieldValue(["trigger", "filters", name], dateString);
    setModerationDateRange(preState => ({ ...preState, [name]: dateString }));
  };

  const setModerationDateRangeErrorsByName = (name: "start_time" | "end_time", error?: string) => {
    form.setFields([{ name: ["trigger", "filters", name], errors: error ? [error] : [] }]);
    setModerationDateRangeErrors(preState => ({ ...preState, [name]: error || "" }));
  };

  const checkDatesAndSetErrorsAndValues = (
    name: "start_time" | "end_time",
    selectedDateInstance?: Dayjs | Moment,
  ) => {
    setModerationDateRangeByName(name, selectedDateInstance?.format("YYYY-MM-DDTHH:mm:ss"));

    if (!selectedDateInstance) return;

    const isStartModerationDateAfterEndModerationDate = selectedDateInstance.isAfter(
      moderationDateRange.end_time,
    );
    const startDateAfterEndDateError = t(
      "pages:workflows:form:actions:hide:errors:startDateAfterEndDate",
    );
    const endDateBeforeStartDateError = t(
      "pages:workflows:form:actions:hide:errors:endDateBeforeStartDate",
    );

    if (
      name === "start_time" &&
      moderationDateRange.end_time &&
      isStartModerationDateAfterEndModerationDate
    ) {
      setModerationDateRangeErrorsByName("end_time", endDateBeforeStartDateError);

      return setModerationDateRangeErrorsByName(name, startDateAfterEndDateError);
    }

    const isEndModerationDateBeforeStartModerationDate = selectedDateInstance.isBefore(
      moderationDateRange.start_time,
    );

    if (
      name === "end_time" &&
      moderationDateRange.start_time &&
      isEndModerationDateBeforeStartModerationDate
    ) {
      setModerationDateRangeErrorsByName("start_time", startDateAfterEndDateError);

      return setModerationDateRangeErrorsByName(name, endDateBeforeStartDateError);
    }

    setModerationDateRangeErrorsByName("start_time");
    setModerationDateRangeErrorsByName("end_time");
  };

  const onDateChange = (name: "start_time" | "end_time") => (date: Dayjs) => {
    const selectedDateInstance = dayjs(moderationDateRange[name])
      .set("date", date.date())
      .set("month", date.month())
      .set("year", date.year());

    checkDatesAndSetErrorsAndValues(name, selectedDateInstance);
  };
  const onTimeChange = (name: "start_time" | "end_time") => (date: Moment | null) => {
    if (!date) return;

    const selectedDateInstance = dayjs(moderationDateRange[name])
      .set("hours", date.hours())
      .set("minutes", date.minutes());

    checkDatesAndSetErrorsAndValues(name, selectedDateInstance);
  };

  useEffect(() => {
    if (!showStartModerationAt) {
      return checkDatesAndSetErrorsAndValues("start_time", undefined);
    }

    if (startModerationAtFromForm) {
      return checkDatesAndSetErrorsAndValues("start_time", dayjs(startModerationAtFromForm));
    }

    const initialStartModerationAt = dayjs();

    checkDatesAndSetErrorsAndValues("start_time", initialStartModerationAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStartModerationAt]);

  useEffect(() => {
    if (!showEndModerationAt) {
      return checkDatesAndSetErrorsAndValues("end_time", undefined);
    }

    if (endModerationAtFromForm) {
      return checkDatesAndSetErrorsAndValues("end_time", dayjs(endModerationAtFromForm));
    }

    const initialEndModerationAt = dayjs().add(1, "day");

    checkDatesAndSetErrorsAndValues("end_time", initialEndModerationAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEndModerationAt]);

  useEffect(() => {
    form.setFieldValue(["trigger", "filters", "timezone"], me?.timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.timezone]);

  useEffect(() => {
    if (!showEndModerationAt && !showStartModerationAt) {
      form.setFieldValue(["trigger", "filters", "timezone"], undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEndModerationAt, showEndModerationAt]);

  return (
    <>
      <div className={s.bbWorkflowItemFormHideActionToggles}>
        <HideWorkflowActionFieldsWrapper
          fieldId="trigger.filters.start_time"
          fieldLabel={t("pages:workflows:form:actions:hide:startTime")}
          checked={showStartModerationAt}
          value={moderationDateRange.start_time}
          error={moderationDateRangeErrors.start_time}
          onCheckChange={setShowStartModerationAt}
          onDateChange={onDateChange("start_time")}
          onTimeChange={onTimeChange("start_time")}
        />
        <HideWorkflowActionFieldsWrapper
          fieldId="trigger.filters.end_time"
          fieldLabel={t("pages:workflows:form:actions:hide:endTime")}
          checked={showEndModerationAt}
          value={moderationDateRange.end_time}
          error={moderationDateRangeErrors.end_time}
          onCheckChange={setShowEndModerationAt}
          onDateChange={onDateChange("end_time")}
          onTimeChange={onTimeChange("end_time")}
        />
      </div>
      {(showStartModerationAt || showEndModerationAt) && (
        <Form.Item
          name={["trigger", "filters", "timezone"]}
          label={t("pages:workflows:form:actions:hide:timezone")}
          rules={[
            {
              required: true,
              message: t("pages:workflows:form:actions:hide:errors:timezone"),
            },
          ]}
          className={s.bbWorkflowItemFormTimezoneFormItem}
          initialValue={me?.timezone}
        >
          <Timezone />
        </Form.Item>
      )}
    </>
  );
};

export default HideWorkflowAction;
