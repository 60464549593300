import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PostID from "../PostView/PostID";
import PostView from "../PostView/PostView";
import PostInsights from "./PostInsights/PostInsights";
import { Accordion, Divider } from "@bbdevcrew/bb_ui_kit_fe";
import PostAnalysis from "../Social/Post/PostAnalysis";
import PostTagsList from "../Social/Post/PostTags/PostTagsList";

import {
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesExplorerLinkSelector,
} from "@store/shortenedSearches/selectors";

import {
  resetShortenedSearchesAction,
  postShortenedSearchesAction,
} from "@store/shortenedSearches/actions";
import { deleteTagFromPostAction } from "@store/postTags/actions";

import s from "./PostDetails.module.less";

import {
  isPrivateConversation,
  PostPlacementEnum,
  isInstagramPlatform,
  isTiktokPlatform,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ICommentDetails } from "./PostDetails.type";
import { useReplyingComment } from "@utils/comments";
import { MAX_TAG_LIST_LENGTH } from "./PostDetailsConfig";
import usePostTagsManagement from "@utils/usePostTagsManagement";

export const PostDetails: FC<ICommentDetails> = ({ filters, replyData, listId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { postSelected, setPostSelected } = usePostTagsManagement();

  const [isAIInsightAccordionOpen, setIsAIInsightAccordionOpen] = useState(true);

  const isReview = replyData.post_placement === PostPlacementEnum.REVIEW;
  const postType = replyData.post_preview?.type;
  const platformId = replyData.author_detail.platform_type.id;
  const isTTMention =
    isTiktokPlatform(platformId) &&
    (postType === "CAPTION_MENTION" || postType === "HASHTAG_MENTION" || postType === "MENTION");
  const isIGHashtagMention = isInstagramPlatform(platformId) && postType === "HASHTAG_MENTION";
  const replyingComment = useReplyingComment(replyData, listId);
  const isPrivateMessage =
    !!replyData?.post_placement && isPrivateConversation(replyData.post_placement);

  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);
  const shortenedSearchesExplorerLink = useSelector(shortenedSearchesExplorerLinkSelector);

  const deleteTagFromPost = (tagId: string) => {
    replyData.post_preview?.id &&
      dispatch(
        deleteTagFromPostAction({ post_tag_id: tagId, post_id: replyData.post_preview?.id }),
      );
  };

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      window.location.href = shortenedSearchesExplorerLink;
      dispatch(resetShortenedSearchesAction());
    }
    // eslint-disable-next-line
  }, [postShortenedSearchesFetched]);

  useEffect(() => {
    setPostSelected(replyData?.post_preview);
  }, [replyData?.post_preview, setPostSelected]);

  const onExploreClick = () => {
    if (!replyingComment?.post_id) return;

    dispatch(
      postShortenedSearchesAction({ filters: { ...filters, post_ids: [replyingComment.post_id] } }),
    );
  };

  return (
    <div className={s.bbPostDetails}>
      {!!replyingComment && (
        <>
          {!isPrivateMessage && !isReview && (
            <PostView
              type="comment_details"
              post_id={replyingComment?.post_id}
              post={replyData.post_preview}
              onExploreClick={onExploreClick}
            />
          )}
          {(isTTMention || isIGHashtagMention) && (
            <PostInsights
              commentsCount={replyData.post_preview?.comment_metrics?.total_comments || 0}
              likesCount={replyData.post_preview?.reactions?.like || 0}
              sharesCount={
                isTTMention ? replyData.post_preview?.post_metrics.total_shares || 0 : undefined
              }
            />
          )}
          {!isPrivateMessage && <PostID post_id={replyingComment?.post_id} />}
          <PostTagsList
            showAddTagButton
            post={replyData.post_preview}
            aiPostTags={replyData.post_preview?.ai_post_tags}
            postTags={postSelected?.post_tags}
            maxTagListLength={MAX_TAG_LIST_LENGTH}
            onDeleteTag={deleteTagFromPost}
          />
          {replyData.post_preview?.post_analysis && (
            <>
              <Divider className={s.bbPostDetailsDivider} />
              <Accordion
                type="white"
                className={s.bbPostDetailsAIInsightsAccordion}
                title={t("components:comments:postDetails:aiInsightsTitle")}
                collapsed={isAIInsightAccordionOpen}
                setCollapsed={() => setIsAIInsightAccordionOpen(!isAIInsightAccordionOpen)}
                body={<PostAnalysis post={replyData.post_preview} />}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
