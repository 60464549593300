import React, { useState } from "react";
import cx from "classnames";

import { Icon, Input, SelectedExcludedIndicator } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Sources.module.less";

import { ISourceKeywordProps } from "./Sources.types";

import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const SourceKeyword: React.FC<ISourceKeywordProps> = ({
  value,
  form,
  onChange,
  setError,
}) => {
  const [keyword, setKeyword] = useState(value || "");
  const [isKeywordSet, setIsKeywordSet] = useState(!!value);

  const setFormError = (hasError: boolean) => {
    form.validateFields(["source_id"]);
    setError(hasError);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (isKeywordSet) {
      setFormError(true);
      return;
    }
    if (val.includes(" ")) {
      return;
    }
    setKeyword(val);
    onChange?.(val);
    setFormError(false);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      if (isKeywordSet) {
        setKeyword("");
        onChange?.("");
        setIsKeywordSet(false);
        setFormError(false);
      }
    } else if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      if (keyword.trim() !== "") {
        if (isKeywordSet) {
          setFormError(true);
        } else {
          setIsKeywordSet(true);
        }
      }
    }
  };

  const handleCloseIconClick = () => {
    setKeyword("");
    onChange?.("");
    setIsKeywordSet(false);
    setFormError(false);
  };

  const onInputBlur = () => {
    if (keyword.trim() !== "" && !isKeywordSet) {
      setIsKeywordSet(true);
    }
  };

  return (
    <div
      className={cx(s.bbSourceModalKeyword, {
        [s.bbSourceModalKeywordSelected]: isKeywordSet,
      })}
    >
      <Input
        _size="md"
        name="source_id"
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        onBlur={onInputBlur}
        value={keyword}
        spellCheck={false}
        suffixEl={
          isKeywordSet && <Icon _size="sm" icon={CloseIcon} onClick={handleCloseIconClick} />
        }
      />
      {isKeywordSet && <SelectedExcludedIndicator selected={keyword} />}
    </div>
  );
};
