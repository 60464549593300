import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";

import { ISingleAttachmentPreviewProps } from "./types";

import s from "./SingleAttachmentPreview.module.less";

import { PlayIcon } from "@bbdevcrew/bb_ui_kit_fe";

const SingleAttachmentPreview: FC<ISingleAttachmentPreviewProps> = ({ attachment }) => {
  const [rootImageWrapperHeight, setRootImageWrapperHeight] = useState("calc(100.04%)");

  const wrapperWidth = "100%";

  const isImage = attachment.type === "photo";

  useEffect(() => {
    if (isImage) {
      const img = new Image();
      img.src = attachment.url;
      img.onload = () => {
        const { width, height } = img;
        if (width / height < 3 / 4) {
          setRootImageWrapperHeight(`${(100 * 4) / 3}%`);
        }
      };
    }
  }, [isImage, attachment.url]);

  return (
    <div className={s.bbSingleImageContainer}>
      <div>
        <a className={s.bbImageLink}>
          <div className={s.bbMainWrapper}>
            <div className={s.bbImageDimensions}>
              <div className={s.bbImageRootWrapper} style={{ paddingTop: rootImageWrapperHeight }}>
                <div
                  className={classNames(s.bbImageWrapper, { [s.bbImageWrapperVideo]: !isImage })}
                  style={{ top: "0%", width: wrapperWidth, height: "calc(100.04%)" }}
                >
                  {isImage ? (
                    <img src={attachment.url} className={s.bbImage} />
                  ) : (
                    <>
                      <video src={attachment.url} className={s.bbImage} />
                      <PlayIcon />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={s.bbMainWrapperSupporter}></div>
        </a>
      </div>
    </div>
  );
};

export default SingleAttachmentPreview;
