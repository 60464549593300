export const GET_INTERNAL_NOTES = "INTERNAL_NOTES/GET_INTERNAL_NOTES";
export const GET_INTERNAL_NOTES_SUCCESS = "INTERNAL_NOTES/GET_INTERNAL_NOTES_SUCCESS";
export const GET_INTERNAL_NOTES_FAILURE = "INTERNAL_NOTES/GET_INTERNAL_NOTES_FAILURE";

export const POST_INTERNAL_NOTES = "INTERNAL_NOTES/POST_INTERNAL_NOTES";
export const POST_INTERNAL_NOTES_SUCCESS = "INTERNAL_NOTES/POST_INTERNAL_NOTES_SUCCESS";
export const POST_INTERNAL_NOTES_FAILURE = "INTERNAL_NOTES/POST_INTERNAL_NOTES_FAILURE";

export const DELETE_INTERNAL_NOTES = "INTERNAL_NOTES/DELETE_INTERNAL_NOTES";
export const DELETE_INTERNAL_NOTES_SUCCESS = "INTERNAL_NOTES/DELETE_INTERNAL_NOTES_SUCCESS";
export const DELETE_INTERNAL_NOTES_FAILURE = "INTERNAL_NOTES/DELETE_INTERNAL_NOTES_FAILURE";

export const POST_PUBLISH_NOTES = "INTERNAL_NOTES/POST_PUBLISH_NOTES";
export const POST_PUBLISH_NOTES_SUCCESS = "INTERNAL_NOTES/POST_PUBLISH_NOTES_SUCCESS";
export const POST_PUBLISH_NOTES_FAILURE = "INTERNAL_NOTES/POST_PUBLISH_NOTES_FAILURE";

export const POST_PREVIEW_PUBLISH_NOTES = "INTERNAL_NOTES/POST_PREVIEW_PUBLISH_NOTES";
export const DELETE_PREVIEW_PUBLISH_NOTES = "INTERNAL_NOTES/DELETE_PREVIEW_PUBLISH_NOTES";

export const RESET_INTERNAL_NOTES = "INTERNAL_NOTES/RESET_INTERNAL_NOTES";
