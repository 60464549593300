import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "antd";
import { TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";
import TabTitle from "./TabTitle";
import TagsList from "./TagsList";
import TagModal from "./TagModal";

import { getTagsListPendingSelector, getTagsListSelector } from "@store/tagsManagement/selectors";
import { meSelector } from "@store/me/selectors";

import {
  deleteSubTagAction,
  deleteTagAction,
  getTagsListAction,
  resetTagsManagementDataAction,
} from "@store/tagsManagement/actions";

import s from "./TagsManagement.module.less";

import { TAGS_TABS } from "./TagsManagement.helpers";
import useTagModal from "@utils/useTagModal";

const TagsManagementView: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    activeTabKey,
    setActiveTabKey,
    isModalOpen,
    editingTag,
    fetchingTag,
    tagsList,
    isBBTagsTab,
    openCreateModal,
    openEditModal,
    closeModal,
    handleSubmit,
    getModalTitle,
  } = useTagModal();

  const tagsGroups = useSelector(getTagsListSelector);
  const fetchingTagsList = useSelector(getTagsListPendingSelector);
  const me = useSelector(meSelector);

  const isBBUser = me?.is_bb_user;

  useEffect(() => {
    dispatch(getTagsListAction());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetTagsManagementDataAction());
    };
  }, [dispatch]);

  const onDeleteConfirm = ({ id, parent_id }: { id: string; parent_id?: string }) => {
    if (isBBTagsTab) {
      dispatch(deleteSubTagAction({ type: activeTabKey, id, parent_id }));
    } else dispatch(deleteTagAction({ type: activeTabKey, id }));
  };

  return (
    <div className={s.bbTagsManagementView}>
      <Row justify="start" align="middle">
        <Col xs={24}>
          <TabsScrollable
            activeKey={activeTabKey}
            onChange={(key: string) => setActiveTabKey(key)}
            defaultActiveKey={activeTabKey}
          >
            {TAGS_TABS.map(tag => {
              const tags = tagsGroups.find(group => group.key === tag)?.children || [];
              return (
                <TabPane
                  tabKey={tag}
                  key={tag}
                  tab={
                    <TabTitle
                      text={t(`components:controlPanel:tagsManagement:${tag}:title`)}
                      tagsAmount={tags.length}
                    />
                  }
                >
                  <TagsList
                    isLoading={fetchingTagsList}
                    isBBTagsTab={isBBTagsTab}
                    title={t(`components:controlPanel:tagsManagement:${tag}:title`)}
                    tags={tags}
                    onAddClick={
                      !isBBUser && tag === "brand_bastion_tags" ? undefined : openCreateModal
                    }
                    onEditClick={
                      !isBBUser && tag === "brand_bastion_tags" ? undefined : openEditModal
                    }
                    onDeleteConfirm={onDeleteConfirm}
                  />
                </TabPane>
              );
            })}
          </TabsScrollable>
        </Col>
      </Row>
      <TagModal
        isOpen={isModalOpen}
        isSubmitting={fetchingTag}
        editingTag={editingTag}
        onCloseModal={closeModal}
        onSubmit={handleSubmit}
        title={getModalTitle()}
        tagsList={tagsList}
        isSubTag={isBBTagsTab}
      />
    </div>
  );
};

export default TagsManagementView;
