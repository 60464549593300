import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Help } from "@bbdevcrew/bb_ui_kit_fe";
import LIPDFPreview from "./LIPDFPreview";
import LILinkPreview from "./LILinkPreview";
import MultiImagePreview from "../previewFb/MultiImagePreview";
import SingleImagePreview from "../previewFb/SingleImagePreview";
import PostCard from "../../../insights/_common/postCard/PostCard";

import { ILIPostPreviewProps } from "./types";
import { analyzeImages, assignStyleToImages, assignPageTopPadding } from "../previewFb/helpers";

import s from "../previewFb/index.module.less";
import postStyles from "../index.module.less";

import VideoPlayerIcon from "@assets/VideoPlayer.svg";
import LIActions from "@assets/PostPreview/PostPreviewLIActions.svg";

export const LI_POST_CAPTION_MAX_LENGTH = 250;

const LIPostPreview: FC<ILIPostPreviewProps> = ({ post, images = [], video, link, pdf }) => {
  const { t } = useTranslation();

  const imagesToUse = analyzeImages(images);
  const totalNumberOfImages = images.length || 0;

  const windowPadding =
    totalNumberOfImages > 1
      ? assignPageTopPadding(imagesToUse.analyzedImages.length, imagesToUse.numberOfPortraits)
      : 0;
  const imagesWithStyles = assignStyleToImages(
    imagesToUse.numberOfPortraits,
    imagesToUse.analyzedImages,
  );

  const imagePreview = (
    <div className={s.bbImagePreviewWrapper} style={{ paddingTop: `${windowPadding}%` }}>
      {totalNumberOfImages === 1 ? (
        <SingleImagePreview image={imagesToUse.analyzedImages[0]} />
      ) : (
        <>
          {imagesWithStyles?.map((image, index) => (
            <MultiImagePreview
              image={image.image}
              style={image.styles}
              key={image.image.id}
              showExcess={index === 4 && totalNumberOfImages > 5}
              excessNumber={totalNumberOfImages - 5}
            />
          ))}
        </>
      )}
    </div>
  );

  const videoPreview = (
    <div className={s.bbVideoPlayerPreview}>
      <video width="282" poster={video?.poster}>
        <source src={video?.src} />
      </video>
      <VideoPlayerIcon />
    </div>
  );

  const linkPreview = (
    <div className={s.bbImagePreviewWrapper}>
      {!!link?.image && <LILinkPreview post={post} linkMetaData={link} />}
    </div>
  );

  const pdfPreview = <>{pdf && <LIPDFPreview fileSrc={pdf} />}</>;

  if (!imagesToUse && !video?.src && !link?.image && !pdf) return null;

  return (
    <>
      <div className={postStyles.bbPostCreationPreviewTitle}>
        {t("components:publish:postCreationModal:preview:title", {
          platform: t("generic:platforms:linkedin"),
        })}
        <Help
          iconType="info"
          iconClassName={postStyles.bbPostPreviewInfo}
          tooltipProps={{
            title: t("components:publish:postCreationModal:preview:tooltip"),
          }}
        />
      </div>
      <div className={s.bbFbPostPreviewWrapper}>
        <div className={s.bbFbPostPreview}>
          <PostCard
            showMoreText
            message={post.message}
            maxMessageLength={LI_POST_CAPTION_MAX_LENGTH}
            postTypeText={t("generic:now")}
            className={s.bbPostPreviewCard}
            pageImageProps={{
              pageName: post.asset.label,
              pageImage: post.asset.picture,
            }}
            postImageProps={{
              customImageRender: (
                <>
                  {imagesToUse.analyzedImages.length
                    ? imagePreview
                    : video?.src
                      ? videoPreview
                      : pdf
                        ? pdfPreview
                        : link?.image
                          ? linkPreview
                          : null}
                </>
              ),
            }}
            renderCardFooter={() => (
              <div className={s.bbFbPostPreviewFooter}>
                <LIActions />
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default LIPostPreview;
