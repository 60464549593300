import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip, SelectableTag } from "@bbdevcrew/bb_ui_kit_fe";

import { PositiveIcon, NegativeIcon, NeutralIcon } from "@bbdevcrew/bb_ui_kit_fe";

export type SentimentValue = "positive" | "negative" | "neutral";

type SentimentIconType = {
  [key in SentimentValue]: JSX.Element;
};

interface ISentimentPickerProps {
  value?: SentimentValue;
  onChange?: (value: SentimentValue) => void;
}

const SENTIMENT_ICONS: SentimentIconType = {
  positive: <PositiveIcon />,
  negative: <NegativeIcon />,
  neutral: <NeutralIcon />,
};

const sentiments: SentimentValue[] = ["positive", "negative", "neutral"];

const SentimentPicker: React.FC<ISentimentPickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <>
      {sentiments.map(sentiment => (
        <Tooltip title={t(`generic:sentiment:${sentiment}`)} key={sentiment}>
          <span>
            <SelectableTag
              data-cy={`workflows-sentiment-value-${sentiment}`}
              checked={sentiment === value}
              onChange={checked => {
                if (checked) {
                  onChange?.(sentiment as SentimentValue);
                }
              }}
            >
              {SENTIMENT_ICONS[sentiment]}
            </SelectableTag>
          </span>
        </Tooltip>
      ))}
    </>
  );
};

export default SentimentPicker;
