import { createReducer } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IAIHighlightsState, IAIHighlightsActions, WidgetState } from "./types";

const initialState: IAIHighlightsState = {
  data: undefined,
  initialFetchDone: false,
  widgetState: WidgetState.COLLAPSED,
  getAiHighlights: {
    fetching: false,
    fetched: false,
    fetchFail: false,
    data: undefined,
  },
};

export const aiHighlightsReducer = createReducer<IAIHighlightsState, IAIHighlightsActions>(
  initialState,
)
  .handleAction(actions.getAiHighlightsAction, state =>
    produce(state, draft => {
      draft.getAiHighlights.fetching = true;
      draft.getAiHighlights.fetched = false;
      draft.getAiHighlights.fetchFail = false;
    }),
  )
  .handleAction(actions.getAiHighlightsSuccess, (state, { payload }) =>
    produce(state, draft => {
      draft.getAiHighlights.fetching = false;
      draft.getAiHighlights.fetched = true;
      draft.getAiHighlights.data = payload;
      draft.data = payload;
      draft.initialFetchDone = true;
    }),
  )
  .handleAction(actions.getAiHighlightsFailure, state =>
    produce(state, draft => {
      draft.getAiHighlights.fetching = false;
      draft.getAiHighlights.fetchFail = true;
    }),
  )
  .handleAction(actions.resetAiHighlightsAction, state =>
    produce(state, draft => {
      const { initialFetchDone } = draft;
      Object.assign(draft, initialState);
      draft.widgetState = WidgetState.COLLAPSED;
      draft.initialFetchDone = initialFetchDone;
    }),
  )
  .handleAction(actions.setWidgetStateAction, (state, { payload }) =>
    produce(state, draft => {
      draft.widgetState = payload;
    }),
  );
