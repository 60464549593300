import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CoverUploadLink.module.less";

import { ICoverUploadLinkProps } from "./CoverUploadLink.types";

import { AlertTriangleIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const CoverUploadLink: React.FC<ICoverUploadLinkProps> = ({ isYouTubeLong }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbCoverUploadLinkWrapper}>
      <a className={s.bbCoverUploadLink}>
        {t("components:publish:postCreationModal:uploads:uploadCover")}
        {isYouTubeLong && (
          <Tooltip
            title={t("components:publish:postCreationModal:youtubeOptions:coverUploadWarning")}
          >
            <span className={s.bbCoverUploadLinkWarning}>
              <AlertTriangleIcon />
            </span>
          </Tooltip>
        )}
      </a>
    </div>
  );
};
