import React from "react";
import { Chip, cropText, getTagStyles, IAxisTick } from "@bbdevcrew/bb_ui_kit_fe";
import { ISentimentBar, ITopPostTagsData } from "./TopPostTags.types";
import { TFunction } from "react-i18next";

import s from "./topPostTagsChart/TopPostTagsChart.module.less";

import { POST_TAG_MAX_LENGTH } from "@components/_common/Social/Post/PostTags/PostTagsList";
import { ITopPostTagsItem } from "@store/posts/types";

export const getSentimentBars = (t: TFunction): ISentimentBar[] => {
  return [
    {
      dataKey: "positive",
      stackId: "sentiment",
      fill: "var(--color-success-1)",
      name: t("components:topPostTags:positive"),
    },
    {
      dataKey: "negative",
      stackId: "sentiment",
      fill: "var(--color-danger-1)",
      name: t("components:topPostTags:negative"),
    },
    {
      dataKey: "userTags",
      stackId: "sentiment",
      fill: "var(--color-black)",
      name: t("components:topPostTags:userTags"),
    },
    {
      dataKey: "neutral",
      stackId: "sentiment",
      fill: "var(--color-surface-6)",
      name: t("components:topPostTags:neutral"),
    },
  ];
};

const ChartTick: React.FC<
  IAxisTick & {
    getColor: (label: string) => string | undefined;
  }
> = ({ getColor, y, payload: { value: label } }) => {
  const color = getColor(label);

  return (
    <foreignObject x={5} y={y - 12} cursor="pointer" className={s.bbTopPostTagsChartLabel}>
      <Chip
        _size="xxs"
        _type={color ? "default" : "grey"}
        style={color ? getTagStyles(color) : {}}
        text={cropText(label, POST_TAG_MAX_LENGTH)}
      />
    </foreignObject>
  );
};

export const renderYLabel =
  // eslint-disable-next-line react/display-name
  (getColor: (label: string) => string | undefined) => (props: IAxisTick) => (
    <ChartTick {...props} getColor={getColor} />
  );

export const generateChartData = (items: ITopPostTagsItem[]): ITopPostTagsData[] => {
  return items.map(item => ({
    id: item.id,
    label: item.label,
    color: item.color,
    posts: item.posts,
    positive: item.breakdown.find(breakdown => breakdown.id === "comment_metrics_positive_sum")
      ?.count,
    negative: item.breakdown.find(breakdown => breakdown.id === "comment_metrics_negative_sum")
      ?.count,
    neutral: item.breakdown.find(breakdown => breakdown.id === "comment_metrics_neutral_sum")
      ?.count,
    userTags: item.breakdown.find(breakdown => breakdown.id === "comment_metrics_user_tags_sum")
      ?.count,
    count: item.breakdown.find(breakdown => breakdown.id === "post_count")?.count,
  }));
};

export const generateScatterData = (data: ITopPostTagsData[]) => {
  return data.map(item => ({
    x: item.count,
    y: item.label,
    label: item.label,
    count: item.count,
    color: item.color,
  }));
};

export const renderScatterShape =
  (onClickPostCount: (label: string) => void) =>
  // eslint-disable-next-line react/display-name
  (props: { cx: number; cy: number; payload: { label: string } }) => {
    const { cx, cy } = props;
    const dotWidth = 14;
    const height = 14;

    const showTooltip = () => {
      const tooltip = document.querySelector(".recharts-tooltip-wrapper");
      if (tooltip) {
        tooltip.classList.add("visible");
        (tooltip as HTMLElement).style.transform = `translate(${cx + 10}px, ${cy - 40}px)`;
        (tooltip as HTMLElement).style.visibility = "visible";
      }
    };

    const hideTooltip = () => {
      const tooltip = document.querySelector(".recharts-tooltip-wrapper");
      if (tooltip) {
        tooltip.classList.remove("visible");
        (tooltip as HTMLElement).style.visibility = "hidden";
      }
    };

    return (
      <g>
        <ellipse
          cx={cx}
          cy={cy}
          rx={dotWidth / 2}
          ry={height / 2}
          fill="var(--color-primary-1)"
          stroke="white"
          strokeWidth={2}
          className={s.bbTopPostTagsChartPostCountDot}
          style={{ cursor: "pointer" }}
          onMouseOver={showTooltip}
          onMouseOut={hideTooltip}
          onClick={() => {
            onClickPostCount(props.payload.label);
          }}
        />
      </g>
    );
  };

export const maxTooltipPreviews = 5;
