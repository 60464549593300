export const imageStyles = [
  // 2 images
  [
    {
      imageContainerStyle: {
        inset: "calc(0% + 0px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)",
      },
      wrapperStyle: { paddingTop: "150%" },
      rootStyle: { height: "100%", top: "0%", width: "100%" },
    },
    {
      imageContainerStyle: {
        inset: "calc(0% + 0px) calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)",
      },
      wrapperStyle: {
        paddingTop: "150%",
      },
      rootStyle: { height: "100%", top: "0%", width: "100%" },
    },
  ],

  // 3 images
  [
    {
      imageContainerStyle: {
        inset: `calc(0% + 0px) calc(50% + 1.01px) calc(0% + 0px)
                    calc(0% + 0px)`,
      },
      wrapperStyle: { paddingTop: "200%" },
      rootStyle: { height: "100%", left: "0%", width: "100%" },
    },
    {
      imageContainerStyle: {
        inset: `calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)
                    calc(50% + 1.01px)`,
      },
      wrapperStyle: {
        paddingTop: "100%",
      },
      rootStyle: { height: "100%", left: "0%", width: "100%" },
    },
    {
      imageContainerStyle: {
        inset: `calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                    calc(50% + 1.01px)`,
      },
      wrapperStyle: {
        paddingTop: "100%",
      },
      rootStyle: { height: "100%", top: "0%", width: "100%" },
    },
  ],

  // 4 images
  [
    {
      imageContainerStyle: {
        inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px)
            calc(0% + 0px)`,
      },
      wrapperStyle: { paddingTop: "100%" },
      rootStyle: {
        height: "100%",
        top: "0%",
        width: "100%",
      },
    },
    {
      imageContainerStyle: {
        inset: `calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)
            calc(50% + 1.01px)`,
      },
      wrapperStyle: {
        paddingTop: "100%",
      },
      rootStyle: {
        height: "100%",
        top: "0%",
        width: "100%",
      },
    },
    {
      imageContainerStyle: {
        inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)
            calc(0% + 0px)`,
      },
      wrapperStyle: {
        paddingTop: "100%",
      },
      rootStyle: {
        height: "100%",
        top: "0%",
        width: "100%",
      },
    },
    {
      imageContainerStyle: {
        inset: `calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)
            calc(50% + 1.01px)`,
      },
      wrapperStyle: {
        paddingTop: "100%",
      },
      rootStyle: {
        height: "100%",
        top: "0%",
        width: "100%",
      },
    },
  ],
];

export default imageStyles;
