import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import MessageCard from "../../MessageCard/MessageCard";
import ReplyPhrase from "../../MessageCard/replyPhrase/ReplyPhrase";

import { cropText } from "@bbdevcrew/bb_ui_kit_fe";
import { IBrandReplyProps, IBrandReplyMessageProps, REPLY_MAX_LENGTH } from "./helpers";

import s from "./BrandReply.module.less";

const BrandReplyMessage: FC<IBrandReplyMessageProps> = ({ message, onClickReply }) => {
  const { t } = useTranslation();

  return (
    <>
      {cropText(message || "", REPLY_MAX_LENGTH)}
      {message.length > REPLY_MAX_LENGTH && (
        <Button _size="sm" _type="link" onClick={onClickReply} className={s.bbBrandReplyMessage}>
          {t("components:reply:showReply")}
        </Button>
      )}
    </>
  );
};

const BrandReply: FC<IBrandReplyProps> = ({ comment, onReplyClick }) => {
  const reply = comment.reply;
  const reply_phrase = comment.reply_phrase;
  const tooltip = reply_phrase?.timestamp;
  const message = reply.raw_message || reply.message;

  const onClickReply = () =>
    comment.actions.can_reply && onReplyClick && onReplyClick(comment.replying_comment_id);

  return (
    <div className={s.bbBrandReply}>
      <MessageCard
        type="highlighted"
        author={reply.replied_as?.name}
        avatarTooltip={reply?.replied_as?.name}
        message={<BrandReplyMessage message={message} onClickReply={onClickReply} />}
        attachments={[]}
        onAttachmentClose={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
        onAttachmentOpen={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
        footer={
          reply_phrase && (
            <ReplyPhrase
              tooltip={tooltip}
              phrase={reply_phrase?.phrase}
              workflow={reply.workflow}
              platform_user={reply_phrase?.platform_user}
            />
          )
        }
      />
    </div>
  );
};

export default BrandReply;
