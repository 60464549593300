import { createReducer } from "typesafe-actions";
import produce from "immer";
import * as actions from "./actions";
import { ControlPanelActionsType, IControlPanelState } from "./types";

const initialState: IControlPanelState = {
  news: {},

  fetchingNews: false,
  fetchedNews: false,
  fetchedFailNews: false,
};

export const controlPanelReducer = createReducer<IControlPanelState, ControlPanelActionsType>(
  initialState,
)
  .handleAction(actions.getNewsAction, state => {
    return produce(state, draft => {
      draft.fetchingNews = true;
      draft.fetchedNews = false;
      draft.fetchedFailNews = false;
    });
  })
  .handleAction(actions.getNewsSuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.fetchingNews = false;
      draft.fetchedNews = true;
      draft.fetchedFailNews = false;
      draft.news = action.payload;
    });
  })
  .handleAction(actions.getNewsFailureAction, state => {
    return produce(state, draft => {
      draft.fetchingNews = false;
      draft.fetchedNews = false;
      draft.fetchedFailNews = true;
    });
  });
