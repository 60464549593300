import { useTranslation } from "react-i18next";

import { IFormFilterRequest } from "@bbdevcrew/bb_ui_kit_fe";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";

export const DEFAULT_TAG_COLOR = "#676F85";

export const BRANDBASTION_TAG_COLORS = {
  universally_harmful: palette.colorUniversallyHarmful,
  sensitive: palette.colorSensitive,
  customer_engagement: palette.colorCustomerEngagement,
  non_harmful: palette.colorNonHarmful,
};

export const DEFAULT_COLOR_PRESETS: [number, number, number][] = [
  [80, 140, 244],
  [117, 212, 229],
  [167, 213, 88],
  [232, 196, 0],
  [249, 141, 31],
  [230, 101, 95],
  [213, 101, 190],
  [141, 75, 223],
  [194, 198, 210],
  [19, 21, 27],
];

export const TAGS_TABS = ["custom_tags", "brand_bastion_tags", "post_tags"];

export const AVAILABLE_FILTERS = [
  "sentiments",
  "message_types",
  "languages",
  "author_followers",
  "keyword_query",
];

export const useTagManagementFilters = () => {
  const { t } = useTranslation();

  const filters = useGetDefaultFiltersConfig(t, true);
  const availableFilters = filters.filter(({ name }) => AVAILABLE_FILTERS.includes(name));

  const filterNamesToModify = new Set(["message_types", "languages", "author_followers"]);

  availableFilters.forEach(filter => {
    if (filterNamesToModify.has(filter.name)) {
      filter.props = {
        ...filter.props,
        dropdownPlacement: "topRight",
        dropdownMatchSelectWidth: true,
      };
    }
  });

  return { filtersConfig: availableFilters };
};

export const isFilterValid = (obj: IFormFilterRequest) => {
  if (!obj || typeof obj !== "object") return false;

  return Object.values(obj).some(value => value !== undefined && value.length > 0);
};
