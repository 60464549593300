import { isIG, isTT, isLI, hasIGFileRatioError, validatedMessageForPost } from "./utilsValidations";
import { IPostItem } from "./types";
import { useContext, useState } from "react";
import { tabsHaveBeenChanged } from "./utilsGeneric";
import { PROPERTIES_TO_IGNORE_ON_SYNC } from "./constants";
import PostCreationContext from "../context/PostCreationContext";
import { isDocument } from "@bbdevcrew/bb_ui_kit_fe";

const useUpdatePost = () => {
  const [originalSyncTabId, setOriginalSyncTabId] = useState<string | undefined>(undefined);

  const { posts, setPosts, isSynced, setIsSynced } = useContext(PostCreationContext);

  const propertyShouldBeSynced = (propertyName?: keyof IPostItem) => {
    return !PROPERTIES_TO_IGNORE_ON_SYNC.find(prop => prop === propertyName);
  };

  const setPlatformSpecficErrorsAfterSync = (_post: IPostItem) => {
    const post = { ..._post };

    if (hasIGFileRatioError(post)) post.error = "fileRatio";

    if (
      !isIG(post) &&
      (post.error === "fileRatio" ||
        post.error === "hashtagCount" ||
        post.error === "requiredFields")
    )
      post.error = undefined;

    if (!isLI(post) && !isTT(post) && post.error === "videoDuration") post.error = undefined;

    if (
      !isTT(post) &&
      (post.error === "postingRestricted" || post.error === "discloseContentRequired")
    )
      post.error = undefined;

    if (!isLI(post) && post.error === "documentSize") post.error = undefined;

    return post;
  };

  const syncTabs = (currentPosts: IPostItem[], updatedPost: IPostItem) => {
    return currentPosts.map(_post => {
      const asset = {
        ..._post.asset,
        preview_link: updatedPost.asset.preview_link,
      };
      _post = {
        ...updatedPost,
        uploadedFiles: isLI(_post)
          ? updatedPost.uploadedFiles
          : updatedPost.uploadedFiles.filter(file => !!file.type && !isDocument(file.type)),
        // the publishing type can be different only for IG
        publishing_type: isIG(_post) ? updatedPost.publishing_type : _post.publishing_type,
        // documents only get synced between LI pages
        previewPDFFiles: isLI(_post) ? updatedPost.previewPDFFiles : [],
      };
      _post.asset = asset; // we don't want to copy the asset
      _post.hashtagCount = validatedMessageForPost(_post).hashtagCount;
      _post.error = validatedMessageForPost(_post).error;
      _post = setPlatformSpecficErrorsAfterSync(_post);

      return _post;
    });
  };

  const updatePost = (updatedPost: IPostItem, updatedPropertyName?: keyof IPostItem) => {
    let postsCopy = [...posts];

    const updatedPostIdx = postsCopy.findIndex(post => updatedPost.asset.id === post.asset.id);
    const isFirstChange = originalSyncTabId === undefined;
    const sameTabWasUpdated = updatedPost.asset.id === originalSyncTabId;

    if (isFirstChange && propertyShouldBeSynced(updatedPropertyName))
      setOriginalSyncTabId(updatedPost.asset.id);

    if (
      isSynced &&
      (sameTabWasUpdated || isFirstChange) &&
      propertyShouldBeSynced(updatedPropertyName)
    ) {
      postsCopy = syncTabs(posts, updatedPost);
    } else {
      let post = { ...updatedPost };
      if (updatedPropertyName === "message") post = validatedMessageForPost(post);

      postsCopy[updatedPostIdx] = post;

      if (tabsHaveBeenChanged(posts, postsCopy)) setIsSynced(false);
    }

    setPosts(postsCopy);
  };

  return {
    updatePost,
  };
};

export default useUpdatePost;
