export const GET_POSTS = "POSTS/GET_POSTS";
export const GET_POSTS_SUCCESS = "POSTS/GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "POSTS/GET_POSTS_FAILURE";

export const GET_POST = "POST/GET_POST";
export const GET_POST_SUCCESS = "POST/GET_POST_SUCCESS";
export const GET_POST_FAILURE = "POST/GET_POST_FAILURE";
export const CLEAR_POST = "POST/CLEAR_POST";

export const GET_POST_ADS = "POST/GET_POST_ADS";
export const GET_POST_ADS_SUCCESS = "POST/GET_POST_ADS_SUCCESS";
export const GET_POST_ADS_FAILURE = "POST/GET_POST_ADS_FAILURE";
export const CLEAR_POST_ADS = "POST/CLEAR_POST_ADS";

export const GET_POSTS_CSV = "POSTS/GET_POSTS_CSV";
export const GET_POSTS_CSV_SUCCESS = "POSTS/GET_POSTS_CSV_SUCCESS";
export const GET_POSTS_CSV_FAILURE = "POSTS/GET_POSTS_CSV_FAILURE";
export const RESET_POSTS_CSV_STATE = "POSTS/RESET_POSTS_CSV_STATE";

export const GET_TOP_POST_TAGS = "POSTS/GET_TOP_POST_TAGS";
export const GET_TOP_POST_TAGS_SUCCESS = "POSTS/GET_TOP_POST_TAGS_SUCCESS";
export const GET_TOP_POST_TAGS_FAILURE = "POSTS/GET_TOP_POST_TAGS_FAILURE";
