import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import { IStoryPreviewProps } from "./types";

import s from "./StoryPreview.module.less";

import { HeartIcon, SendIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

const PREVIEW_WIDTH = 240;
const POSTED_TIME_LABEL = "3h";

const StoryPreview: FC<IStoryPreviewProps> = ({ video, image, pageName, pageAvatar }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbStoryPreview}>
      <div className={s.bbStoryWrapper}>
        <div className={s.bbTopBar}>
          <div className={s.bbStoryProgressBorder}>
            <div className={s.bbStoryProgressFill}></div>
          </div>
          <div className={s.bbAssetImageWrapper}>
            <div className={s.bbAssetImage}>
              <UserAvatar img={pageAvatar} firstName={pageName} />
            </div>
            <span className={s.bbPageName}>{pageName}</span>
            <span className={s.bbPostedTimeLabel}>{POSTED_TIME_LABEL}</span>
          </div>
          <div className={s.bbCloseIcon}>
            <CloseIcon />
          </div>
        </div>
        {!!video ? (
          <video width={PREVIEW_WIDTH}>
            <source src={video?.object_src} />
          </video>
        ) : !!image ? (
          <img src={image.src} width={PREVIEW_WIDTH} />
        ) : null}
        <div className={s.bbGradientOverlay}></div>
      </div>
      <div className={s.bbBottomBar}>
        <div className={s.bbSendMessageBox}>{t("components:publish:story:sendMessage")}</div>
        <div className={s.bbActionIcon}>
          <HeartIcon />
        </div>
        <div className={s.bbActionIcon}>
          <SendIcon />
        </div>
      </div>
    </div>
  );
};

export default StoryPreview;
