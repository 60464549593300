import { PostMentionType } from "@bbdevcrew/bb_ui_kit_fe";

export const isEarnedMessage = (postType: PostMentionType) => {
  return (
    postType === "CAPTION_MENTION" ||
    postType === "COMMENT_MENTION" ||
    postType === "HASHTAG_MENTION" ||
    postType === "QUOTE_MENTION" ||
    postType === "MENTION" ||
    postType === "REVIEW" ||
    postType === "KEYWORD" ||
    postType === "MEDIA_TAG_MENTION"
  );
};

export const isMentionMessage = (postType: PostMentionType) => {
  return (
    postType === "CAPTION_MENTION" ||
    postType === "COMMENT_MENTION" ||
    postType === "HASHTAG_MENTION" ||
    postType === "QUOTE_MENTION" ||
    postType === "MENTION" ||
    postType === "MEDIA_TAG_MENTION"
  );
};
