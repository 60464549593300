import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { FormErrorMessage, ModalFooter, TextareaNew } from "@bbdevcrew/bb_ui_kit_fe";
import { Form, FormInstance } from "antd";

import FileContextModal from "../FileContextModal";

import { ALT_TEXT_MAXLEN } from "./AltTextModal.helpers";

import s from "./AltTextModal.module.less";

import { IAltTextModalProps } from "./AltTextModal.types";

export const AltTextModal: React.FC<IAltTextModalProps> = ({
  value,
  previewSrc,
  onSave,
  onCancel,
  open,
  ...restProps
}) => {
  const formRef = useRef<FormInstance>(null);

  const { t } = useTranslation();

  return (
    <FileContextModal onCancel={onCancel} open={open} {...restProps}>
      <div className={s.bbAltTextModal}>
        <div className={s.bbAltTextModalPreview}>
          <img src={previewSrc} width={520} height="auto" />
        </div>
        <Form
          onFinish={values => {
            onSave(values.altText);
          }}
          initialValues={{ altText: value }}
          layout="vertical"
          className={s.bbAltTextModalSettings}
          ref={formRef}
        >
          <div className={s.bbAltTextModalSettingsControls}>
            <h2 className={s.bbAltTextModalSettingsTitle}>
              {t("components:publish:postCreationModal:altText:title")}
            </h2>
            <p className={s.bbAltTextModalSettingsDescription}>
              {t("components:publish:postCreationModal:altText:description")}
            </p>
            <Form.Item
              name="altText"
              label={t("components:publish:postCreationModal:altText:label")}
              rules={[
                {
                  max: ALT_TEXT_MAXLEN,
                  message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
                },
              ]}
            >
              <TextareaNew
                className={s.bbAltTextModalSettingsTextarea}
                maxLength={ALT_TEXT_MAXLEN}
                _size="sm"
              />
            </Form.Item>
          </div>
          <div className={s.bbAltTextModalSettingsFooter}>
            <ModalFooter
              onOk={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
              confirmLabel={t("generic:save")}
              onCancel={onCancel}
            />
          </div>
        </Form>
      </div>
    </FileContextModal>
  );
};
