import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import {
  getPostTagsSuccessAction,
  getPostTagsFailureAction,
  createPostTagSuccessAction,
  createPostTagFailureAction,
  deletePostTagSuccessAction,
  deletePostTagFailureAction,
  deleteTagFromPostSuccessAction,
  deleteTagFromPostFailureAction,
  getPostTagsForFilterSuccessAction,
  getPostTagsForFilterFailureAction,
} from "./actions";
import {
  GET_POST_TAGS,
  ADD_POST_TAG,
  DELETE_POST_TAG,
  DELETE_TAG_FROM_POST,
  GET_POST_TAGS_FOR_FILTER,
} from "./actionTypes";

import { RootState } from "..";
import { clientTags, postTags } from "@utils/paths";
import { IPostTagsForFilterResponse, PostTagsActionsType } from "./types";
import { getAuthAPIHeaders, getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { IPostTag } from "@bbdevcrew/bb_ui_kit_fe";

export const getCustomTags = (
  action$: Observable<PostTagsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_POST_TAGS)),
    switchMap(() => {
      return ajax
        .get<{ items: IPostTag[] }>(
          postTags,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getPostTagsSuccessAction(data)),
          catchError(e => handleError(e, getPostTagsFailureAction)),
        );
    }),
  );

export const getPostTagsList = (
  action$: Observable<PostTagsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_POST_TAGS_FOR_FILTER)),
    switchMap(() =>
      ajax
        .get<IPostTagsForFilterResponse>(
          `${clientTags}?group_type=post_tags`,
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(({ response }) => response),
          map(data => getPostTagsForFilterSuccessAction(data)),
          catchError(e => handleError(e, getPostTagsForFilterFailureAction)),
        ),
    ),
  );

export const createPostTag = (
  action$: Observable<PostTagsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(ADD_POST_TAG)),
    switchMap(a => {
      return ajax
        .post<IPostTag>(
          postTags,
          {
            post_id: a.payload.post_id,
            label: a.payload.label,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => [
            createPostTagSuccessAction(
              { ...data, color: a.payload.color },
              a.payload.post_id || "",
            ),
          ]),
          catchError(e => {
            return [
              createPostTagFailureAction(a.payload.post_id),
              handleError(e, createPostTagFailureAction),
            ];
          }),
        );
    }),
  );

export const deletePostTagFromDropdown = (
  action$: Observable<PostTagsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_POST_TAG)),
    switchMap(a => {
      return ajax
        .delete<IPostTag>(
          `${postTags}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deletePostTagSuccessAction(a.payload)),
          catchError(e => handleError(e, deletePostTagFailureAction)),
        );
    }),
  );

export const deletePostTagFromPost = (
  action$: Observable<PostTagsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_TAG_FROM_POST)),
    switchMap(a => {
      return ajax
        .delete(
          `${postTags}/${a.payload.post_tag_id}/posts/${a.payload.post_id}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteTagFromPostSuccessAction(a.payload)),
          catchError(e => handleError(e, deleteTagFromPostFailureAction)),
        );
    }),
  );
