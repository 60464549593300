import React from "react";
import { useTranslation } from "react-i18next";

import { SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TiktokTrending.module.less";

import { useSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { ITiktokTrendingCommonFilterProps } from "./TiktokTrending.types";

export const TiktokTrendingCommonFilter: React.FC<ITiktokTrendingCommonFilterProps> = ({
  options,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const {
    options: searchedOptions,
    SearchInput,
    searchTerm,
    setSearchTerm,
  } = useSearch(options, t("generic:search"));

  return (
    <SelectStyled
      _size="sm"
      onChange={onChange}
      value={value}
      options={searchedOptions}
      DropdownHeader={SearchInput}
      highlightTerm={searchTerm}
      onDropdownClose={() => setSearchTerm("")}
      wrapperClassName={s.bbTTTrendingDropdownWrapper}
      dropdownMenuClassName={s.bbTTTrendingDropdown}
      dropdownPlacement="bottomRight"
      disabled={!options?.length}
    />
  );
};
