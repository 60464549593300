import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { ChipAction, SimpleSelectSingle } from "@bbdevcrew/bb_ui_kit_fe";

import { updateAlert } from "@store/alerts/actions";

import s from "./AlertsTable.module.less";

import { getStatusOptions } from "./AlertsTable.helpers";

import { IStatusTriggerProps, IStatusChipProps } from "./AlertsTable.types";
import { AlertStatusType } from "@store/alerts/types";

import { ChevronDownIcon, CircleFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

const StatusTrigger: React.FC<IStatusTriggerProps> = ({ value }) => {
  const { t } = useTranslation();

  const isArchived = value === "archived";

  return (
    <ChipAction
      text={t(`components:listen:alerts:status:${value}`)}
      _size="xs"
      _type={isArchived ? "white" : "success"}
      className={classNames(s.bbAlertsTableChip, {
        [s.bbAlertsTableChipShadow]: isArchived,
      })}
      actionIcon={ChevronDownIcon}
      leftIcon={<CircleFilledIcon />}
    />
  );
};

const StatusChip: React.FC<IStatusChipProps> = ({ value, alertId }) => {
  const dispatch = useDispatch();

  const updateAlertStatus = useCallback(
    (status: AlertStatusType) => {
      dispatch(updateAlert(alertId, { status }));
    },
    [alertId, dispatch],
  );

  return (
    <SimpleSelectSingle
      options={getStatusOptions()}
      value={value}
      trigger={() => <StatusTrigger value={value} />}
      onChange={nextValue => {
        updateAlertStatus(nextValue as AlertStatusType);
      }}
    />
  );
};

export default StatusChip;
