import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";

import { Empty } from "antd";
import { Card, Loading, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";
import TopInfluencersChart from "./topInfluencersChart/TopInfluencersChart";

import s from "./TopInfluencers.module.less";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { ITopInfluencersItem } from "@store/overview/types";

interface ITopInfluencersProps {
  loading: boolean;
  data: ITopInfluencersItem[];
  filterData: IFilters;
  filterToSpecificItem?: (item: IFilters) => void;
}

const TopInfluencers: FC<ITopInfluencersProps> = ({
  filterData,
  data,
  loading,
  filterToSpecificItem,
}) => {
  const { t } = useTranslation();

  const [selectedTabKey, setSelectedTabKey] = useState("all");

  const onChangeTab = (activeKey: string) => {
    setSelectedTabKey(activeKey);
  };

  const getBarChartData = () => {
    const selectedTabDataIndex = data?.findIndex(item => item.sentiment.id === selectedTabKey);

    return data[selectedTabDataIndex].sentiment.items;
  };

  const generateTab = () => {
    return (
      <ReactResizeDetector
        handleWidth
        refreshMode={"throttle"}
        refreshOptions={{ leading: true, trailing: true }}
      >
        {(props: { width: number }) => {
          return getBarChartData().length ? (
            <TopInfluencersChart
              data={getBarChartData()}
              chartWidth={props.width}
              filterData={filterData}
              sentiment={selectedTabKey}
              filterToSpecificItem={filterToSpecificItem}
            />
          ) : (
            <Empty
              className={s.bbTopInfluencersEmpty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("components:empty:description")}
              style={{
                margin: 0,
              }}
            />
          );
        }}
      </ReactResizeDetector>
    );
  };

  return (
    <>
      {!filterData.saved_filter && (
        <div
          className={s.bbTopInfluencers}
          data-cy="top-commenters-container"
          data-stonly-target="intelligence-overview__top-commenters"
        >
          <Card
            headerTitle={t("components:topInfluencers:headerTitle")}
            headerSubTitle={t("components:topInfluencers:headerSubTitle")}
          >
            {loading ? (
              <div className={s.bbTopInfluencersTrendsLoading}>
                <Loading isLoading />
              </div>
            ) : data?.length ? (
              <>
                <TabsScrollable
                  centered
                  noUnderline
                  defaultActiveKey="all"
                  activeKey={selectedTabKey}
                  onChange={onChangeTab}
                >
                  <TabPane tab={t(`components:topInfluencers:tabs:all`)} tabKey="all">
                    {generateTab()}
                  </TabPane>
                  <TabPane tab={t(`components:topInfluencers:tabs:positive`)} tabKey="positive">
                    {generateTab()}
                  </TabPane>
                  <TabPane tab={t(`components:topInfluencers:tabs:negative`)} tabKey="negative">
                    {generateTab()}
                  </TabPane>
                </TabsScrollable>
              </>
            ) : (
              <Empty
                className={s.bbTopInfluencersEmpty}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t("components:empty:description")}
              />
            )}
          </Card>
        </div>
      )}
    </>
  );
};

export default TopInfluencers;
