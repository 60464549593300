import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import { IRequestState } from "@store/types";

export type IAIHighlightsActions = ActionType<typeof actions>;

export enum WidgetState {
  COLLAPSED = "collapsed",
  EXPANDED = "expanded",
}

export interface IAIHighlightsResponse {
  id: string;
  start_date: string;
  end_date: string;
  message: string;
  last_updated: string;
  limit: number;
  consumed: number;
}

export interface IAIHighlightsState {
  data?: IAIHighlightsResponse;
  initialFetchDone: boolean;
  widgetState: WidgetState;
  getAiHighlights: IRequestState<IAIHighlightsResponse>;
}
