const objectsAreEqual = (obj1: object, obj2: object): boolean => {
  if (obj1 === obj2) return true;
  if (!obj1 || !obj2) return false;
  if (typeof obj1 !== "object" || typeof obj2 !== "object") return obj1 === obj2;

  const filteredObj1 = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj1).filter(([_, value]) => value !== undefined),
  );

  const filteredObj2 = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj2).filter(([_, value]) => value !== undefined),
  );

  const keys1 = Object.keys(filteredObj1).sort();
  const keys2 = Object.keys(filteredObj2).sort();

  if (keys1.length !== keys2.length) return false;

  return keys1.every(key => {
    const val1 = filteredObj1[key];
    const val2 = filteredObj2[key];

    if (typeof val1 === "object" && val1 !== null && typeof val2 === "object" && val2 !== null) {
      return objectsAreEqual(val1, val2);
    }

    return val1 === val2;
  });
};

export default objectsAreEqual;
