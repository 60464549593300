import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { FC, ChangeEventHandler } from "react";

import { AmPmType } from "@store/notifications/types";

import s from "./AmPmSwitch.module.less";

const cx = cn.bind(s);

interface IAmPmSwitchProps {
  checked: AmPmType;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name?: string;
}

const AmPmSwitch: FC<IAmPmSwitchProps> = ({ checked, onChange, name = "switch" }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbSwitchWrapper}>
      <input
        value="AM"
        type="radio"
        name={name}
        id={`switch-on-${name}`}
        onChange={onChange}
        checked={!!(checked === "AM")}
        className={cx(s.bbSwitch, s.bbSwitchLeft)}
      />
      <label htmlFor={`switch-on-${name}`} className={cx(s.bbSwitchButton, s.bbSwitchButtonLeft)}>
        <span>{t("generic:am")}</span>
      </label>
      <input
        value="PM"
        type="radio"
        name={name}
        id={`switch-off-${name}`}
        onChange={onChange}
        checked={!!(checked === "PM")}
        className={cx(s.bbSwitch, s.bbSwitchRight)}
      />
      <label htmlFor={`switch-off-${name}`} className={cx(s.bbSwitchButton, s.bbSwitchButtonRight)}>
        <span>{t("generic:pm")}</span>
      </label>
    </div>
  );
};

export default AmPmSwitch;
