import { t } from "../../../../../../App";
import { IObjectOption, IAutocompleteOption } from "@bbdevcrew/bb_ui_kit_fe";

export const getTagActionTypeOptions = () => [
  {
    label: t("pages:workflows:form:actions:tag:options:custom_tag"),
    id: "custom_tag",
  },
  {
    label: t("pages:workflows:form:actions:tag:options:bb_tag"),
    id: "bb_tag",
  },
];

export const postTagToOption = (tagOpt: IAutocompleteOption) => {
  const option: IObjectOption = {
    id: tagOpt.id || tagOpt.value,
    label: tagOpt.label,
  };

  return option;
};
