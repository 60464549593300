export const CHECK_STATUS = "AI_HIGHLIGHTS/CHECK_STATUS";
export const CHECK_STATUS_SUCCESS = "AI_HIGHLIGHTS/CHECK_STATUS_SUCCESS";
export const CHECK_STATUS_FAILURE = "AI_HIGHLIGHTS/CHECK_STATUS_FAILURE";
export const CHECK_STATUS_TERMS_NOT_ACCEPTED = "AI_HIGHLIGHTS/CHECK_STATUS/TERMS_NOT_ACCEPTED";
export const CHECK_STATUS_CLIENT_LIMIT_REACHED = "AI_HIGHLIGHTS/CHECK_STATUS/CLIENT_LIMIT_REACHED";

export const GET_AI_HIGHLIGHTS = "AI_HIGHLIGHTS/GET_AI_HIGHLIGHTS";
export const GET_AI_HIGHLIGHTS_SUCCESS = "AI_HIGHLIGHTS/GET_AI_HIGHLIGHTS_SUCCESS";
export const GET_AI_HIGHLIGHTS_FAILURE = "AI_HIGHLIGHTS/GET_AI_HIGHLIGHTS_FAILURE";

export const RESET_AI_HIGHLIGHTS = "AI_HIGHLIGHTS/RESET_AI_HIGHLIGHTS";

export const SET_WIDGET_STATE = "AI_HIGHLIGHTS/SET_WIDGET_STATE";
