import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../_common/breadcrumbs";
import { ProfilesSection } from "./ProfilesSection";
import { ProfilesTableWrapper } from "./profilesTable/ProfilesTableWrapper";
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";

import {
  getFollowersSelector,
  getFollowersFetchingSelector,
  getImpressionsSelector,
  getImpressionsFetchingSelector,
  getEngagementsSelector,
  getEngagementsFetchingSelector,
} from "@store/dashboard/selectors";

import { triggerFilteringWithoutUrlAction } from "@store/filters/actions";
import {
  getDashboardFollowersAction,
  getDashboardImpressionsAction,
  getDashboardEngagementsAction,
} from "@store/dashboard/actions";

import s from "./Profiles.module.less";

import { normalizePlatformData } from "./Profiles.helpers";
import { useCurrentFilters } from "@utils/useCurrentFilters";

export const Profiles: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useCurrentFilters();

  const customFilterData = useMemo(() => {
    return {
      data_range_option: filters?.data_range_option || "last_30_days",
      platform_types: filters?.platform_types?.filter(
        item => item !== "trustpilot" && item !== "google_business",
      ),
      asset_ids: filters?.asset_ids,
      start_time: filters?.start_time,
      end_time: filters?.end_time,
    };
  }, [filters]);

  const followersData = useSelector(getFollowersSelector);
  const impressionsData = useSelector(getImpressionsSelector);
  const engagementsData = useSelector(getEngagementsSelector);
  const followersDataFetching = useSelector(getFollowersFetchingSelector);
  const impressionsDataFetching = useSelector(getImpressionsFetchingSelector);
  const engagementsDataFetching = useSelector(getEngagementsFetchingSelector);

  const fetchDashboardData = useCallback(() => {
    dispatch(getDashboardFollowersAction(customFilterData));
    dispatch(getDashboardImpressionsAction(customFilterData));
    dispatch(getDashboardEngagementsAction(customFilterData));
  }, [dispatch, customFilterData]);

  const resetFilterIfNeeded = useCallback(() => {
    if (!filters?.data_range_option) {
      dispatch(triggerFilteringWithoutUrlAction(customFilterData));
    }
  }, [dispatch, filters, customFilterData]);

  useEffect(() => {
    if (filters) {
      fetchDashboardData();
      resetFilterIfNeeded();
    }
  }, [filters, fetchDashboardData, resetFilterIfNeeded]);

  const normalizeFollowersChart = normalizePlatformData(
    followersData?.platforms || [],
    "followers_count",
    "growth",
  );

  const normalizeImpressionsChart = normalizePlatformData(
    impressionsData?.platforms || [],
    "total_impressions",
    "total_impression_growth",
  );

  const normalizeEngagementsChart = normalizePlatformData(
    engagementsData?.platforms || [],
    "engagements",
    "engagements_growth",
  );

  return (
    <div className={s.bbProfiles}>
      <div>
        <Breadcrumbs
          title={
            <div className={s.bbProfilesTitle}>
              <span className={s.bbProfilesTitleLabel}>
                {t("components:listen:profiles:title")}
              </span>
              <span className={s.bbProfilesTitleTag}>{t("generic:beta")}</span>
            </div>
          }
        />
        <MobileFiltersButton />
      </div>

      <ProfilesSection
        chartData={normalizeFollowersChart}
        tableData={followersData?.items || []}
        loading={followersDataFetching}
        type="followers"
      />
      <ProfilesSection
        chartData={normalizeImpressionsChart}
        tableData={impressionsData?.items || []}
        loading={impressionsDataFetching}
        type="impressions"
      />

      <div className={s.bbProfilesImpressionsTable}>
        <span>{t(`components:listen:profiles:impressions:tableTitle`)}</span>
        <ProfilesTableWrapper
          type="impressions-platform"
          data={(impressionsData?.platforms || []).filter(item => item.id !== "total")}
          loading={impressionsDataFetching}
          total={(impressionsData?.platforms || []).find(item => item.id === "total")}
        />
      </div>

      <ProfilesSection
        chartData={normalizeEngagementsChart}
        tableData={engagementsData?.items || []}
        loading={engagementsDataFetching}
        type="engagements"
      />
    </div>
  );
};
