import { action } from "typesafe-actions";
import {
  GET_COMPARE_PROJECTS,
  GET_COMPARE_PROJECTS_SUCCESS,
  GET_COMPARE_PROJECTS_FAILURE,
  POST_COMPARE_PROJECT,
  POST_COMPARE_PROJECT_SUCCESS,
  POST_COMPARE_PROJECT_FAILURE,
  EDIT_COMPARE_PROJECT,
  EDIT_COMPARE_PROJECT_SUCCESS,
  EDIT_COMPARE_PROJECT_FAILURE,
  DELETE_COMPARE_PROJECT,
  DELETE_COMPARE_PROJECT_SUCCESS,
  DELETE_COMPARE_PROJECT_FAILURE,
  GET_COMPARE_PROJECT_AGGREGATIONS,
  GET_COMPARE_PROJECT_AGGREGATIONS_SUCCESS,
  GET_COMPARE_PROJECT_AGGREGATIONS_FAILURE,
  CLEAR_PROJECT_AGGREGATIONS,
} from "./actionTypes";
import { ICompareProject, ICompareProjectAggregation } from "./types";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

// GET
export const getCompareProjects = () => action(GET_COMPARE_PROJECTS);
export const getCompareProjectsSuccess = (projects: ICompareProject[]) =>
  action(GET_COMPARE_PROJECTS_SUCCESS, projects);
export const getCompareProjectsFailure = () => action(GET_COMPARE_PROJECTS_FAILURE);

// POST
export const postCompareProject = (project: ICompareProject) =>
  action(POST_COMPARE_PROJECT, project);
export const postCompareProjectSuccess = (project: ICompareProject) =>
  action(POST_COMPARE_PROJECT_SUCCESS, project);
export const postCompareProjectFailure = () => action(POST_COMPARE_PROJECT_FAILURE);

// EDIT
export const editCompareProject = (project: ICompareProject) =>
  action(EDIT_COMPARE_PROJECT, project);
export const editCompareProjectSuccess = (project: ICompareProject) =>
  action(EDIT_COMPARE_PROJECT_SUCCESS, project);
export const editCompareProjectFailure = () => action(EDIT_COMPARE_PROJECT_FAILURE);

// DELETE
export const deleteCompareProject = (id: string) => action(DELETE_COMPARE_PROJECT, id);
export const deleteCompareProjectSuccess = (id: string) =>
  action(DELETE_COMPARE_PROJECT_SUCCESS, id);
export const deleteCompareProjectFailure = () => action(DELETE_COMPARE_PROJECT_FAILURE);

// GET AGGREGATIONS
export const getCompareProjectAggregations = (id: string, filters: IFilters) =>
  action(GET_COMPARE_PROJECT_AGGREGATIONS, {
    id: id,
    filters: filters,
  });
export const getCompareProjectAggregationsSuccess = (aggregations: ICompareProjectAggregation[]) =>
  action(GET_COMPARE_PROJECT_AGGREGATIONS_SUCCESS, aggregations);
export const getCompareProjectAggregationsFailure = () =>
  action(GET_COMPARE_PROJECT_AGGREGATIONS_FAILURE);

// CLEAR AGGREGATIONS
export const clearProjectAggregations = () => action(CLEAR_PROJECT_AGGREGATIONS);
