import React from "react";
import { useTranslation } from "react-i18next";

import s from "./../UserTags.module.less";

import UserTagsEmptyIllustration from "@assets/UserTagsEmptyIllustration.svg";

export const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <div className={s.bbUserTagsEmpty}>
      <UserTagsEmptyIllustration />
      <h3>{t("components:publish:postCreationModal:mediaTags:userTagsEmptyState:title")}</h3>
      <p>{t("components:publish:postCreationModal:mediaTags:userTagsEmptyState:body")}</p>
    </div>
  );
};
