import imageStyles from "./imageDimensions";
import { IImage } from "./types";

export const isPortrait = (width: number, height: number) => {
  return width < height;
};

export const analyzeImages = (images: IImage[]) => {
  let numberOfPortraits = 0;
  let numberOfLandscapes = 0;
  const analyzedImages: IImage[] = [];
  const arrayLength = images.length < 5 ? images.length : 5;

  for (let i = 0; i < arrayLength; i++) {
    const isPortraitImage = isPortrait(Number(images[i].width), Number(images[i].height));

    if (isPortraitImage) numberOfPortraits++;
    else numberOfLandscapes++;

    const analyzedImage = { ...images[i], isPortraitImage };

    analyzedImages.push(analyzedImage);
  }

  // Put all portraits to the beginning of the array
  analyzedImages.sort((a, b) => Number(b.isPortraitImage) - Number(a.isPortraitImage));

  return { analyzedImages, numberOfPortraits, numberOfLandscapes };
};

// This will assign the paddingTop of Post Image Window
export const assignPageTopPadding = (numberOfImages: number, numberOfPortraits: number) => {
  if (numberOfImages === 2 && numberOfPortraits === 1) return 50;
  else if (numberOfImages >= 5 && numberOfPortraits >= 4) return 83.3333333;
  else return 100;
};

const styleOptionResult = (numberOfPortraits: number) => {
  switch (numberOfPortraits) {
    case 0:
      return "0P";
    case 1:
      return "1P";
    case 2:
      return "2P";
    case 3:
      return "3P";
    case 4:
      return "4P";
    default:
      return "5P";
  }
};

export const assignStyleToImages = (numberOfPortraits: number, images: IImage[]) => {
  let selectedStyle;
  const numberOfImages = images.length;
  let styleOption: "0P" | "1P" | "2P" | "3P" | "4P" | "5P";

  if (numberOfImages >= 2 && numberOfImages <= 5) {
    const index = numberOfImages - 2;
    const styleGroup = imageStyles[index];

    if (numberOfPortraits >= 0 && numberOfPortraits <= 5 && styleGroup) {
      styleOption = styleOptionResult(numberOfPortraits);
      if (styleGroup[styleOption]) {
        selectedStyle = styleGroup[styleOption];
      }
    }
  }

  if (selectedStyle) {
    const styleAssignedImages = [];

    for (let i = 0; i < images.length; i++) {
      const styleAssigned = { image: images[i], styles: selectedStyle[i] };
      styleAssignedImages.push(styleAssigned);
    }

    return styleAssignedImages;
  }

  return null;
};
