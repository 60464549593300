import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";

import {
  Chip,
  Toggle,
  Tooltip,
  SelectStyledMulti,
  ClearableTrigger,
  useSearch,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";

import { meSelector } from "@store/me/selectors";
import { workflowsUserGroupsSelector } from "@store/workflows/selectors";
import { getWorkflowsUserGroupsAction } from "@store/workflows/actions";

import s from "./WorkflowForm.module.less";

import {
  IObjectOption,
  IClearableTriggerProps,
  handleMultiselectState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { optionRequiredValidator } from "@utils/optionRequiredFormValidator";
import { IWorkflowFormUserPermissionsProps } from "./WorkflowForm.types";

const WorkflowFormUserPermissions: FC<IWorkflowFormUserPermissionsProps> = ({ form, workflow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [advancedUserPermissionsChecked, setAdvancedUserPermissionsChecked] = useState(
    !!workflow.id && !!workflow.visibility_access?.users?.length,
  );
  const [editableByGroupsValues, setEditableByUserGroupsValues] = useState<string[]>([]);
  const [visibleToUserGroupsValues, setVisibleToUserGroupsValues] = useState<string[]>([]);

  const me = useSelector(meSelector);
  const workflowsUserGroups = useSelector(workflowsUserGroupsSelector);

  const meUserValue = me?.id ? [me.id] : [];
  const isBackofficeUser = me?.is_bb_backoffice_user;
  const meText = t("pages:workflows:form:advancedUserPermissions:me");

  const findUserById = (userId: string): string => {
    for (const group of workflowsUserGroups) {
      const user = group.users.find(u => u.id === userId);
      if (user)
        return (
          user.first_name +
          " " +
          (user.last_name || "") +
          (user.id === me?.id ? ` (${meText.toLowerCase()})` : "")
        );
    }
    return "";
  };

  const visibleToUserGroupsNormalized: IObjectOption[] = useMemo(
    () =>
      workflowsUserGroups.map(group => ({
        id: group.group_id,
        label: group.group_label,
        options: group.users
          .filter(u => u.id !== "all")
          .map(user => ({
            id: user.id,
            label: findUserById(user.id),
          })),
      })),
    // eslint-disable-next-line
    [workflowsUserGroups],
  );

  // Only the users that are in the visibleToGroups
  const editableByUserGroupsNormalized: IObjectOption[] = useMemo(() => {
    return visibleToUserGroupsNormalized
      .map(group => ({
        ...group,
        options: group.options?.filter(user => visibleToUserGroupsValues.includes(user.id)),
      }))
      .filter(group => !!group.options?.length);
  }, [visibleToUserGroupsNormalized, visibleToUserGroupsValues]);

  const {
    SearchInput: VisibleToSearchInput,
    options: filteredVisibleToUsers,
    searchTerm: visibleToSearchTerm,
    setSearchTerm: setVisibleToSearchTerm,
  } = useSearch(visibleToUserGroupsNormalized, t("generic:search"));

  const {
    SearchInput: EditableBySearchInput,
    options: filteredEditableByUsers,
    searchTerm: editableBySearchTerm,
    setSearchTerm: setEditableBySearchTerm,
  } = useSearch(editableByUserGroupsNormalized, t("generic:search"));

  const getWorkflowsUserGroups = useCallback(
    () => dispatch(getWorkflowsUserGroupsAction()),
    [dispatch],
  );

  useEffect(() => {
    if (isBackofficeUser) getWorkflowsUserGroups();
  }, [isBackofficeUser, getWorkflowsUserGroups]);

  useEffect(() => {
    form.setFieldsValue({ visibility_access: { users: visibleToUserGroupsValues } });
    // eslint-disable-next-line
  }, [visibleToUserGroupsValues]);

  useEffect(() => {
    form.setFieldsValue({ editability_access: { users: editableByGroupsValues } });
    // eslint-disable-next-line
  }, [editableByGroupsValues]);

  useEffect(() => {
    if (
      workflow.id &&
      workflow.visibility_access?.users?.length &&
      workflow.editability_access?.users?.length
    ) {
      setEditableByUserGroupsValues(workflow.editability_access.users);
      setVisibleToUserGroupsValues(workflow.visibility_access.users);
    }
    // eslint-disable-next-line
  }, [workflow.id]);

  const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {
    return arr1.length === arr2.length && [...arr1].sort().join(",") === [...arr2].sort().join(",");
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps, triggerId: string) => {
    if (!form.getFieldValue(triggerId)?.users?.length) {
      return ClearableTrigger(tooltipProps);
    }

    const values: string[] =
      triggerId === "visibility_access" ? visibleToUserGroupsValues : editableByGroupsValues;

    const tooltipElement = (
      <>
        {values?.map(tagFilterValue => (
          <span key={tagFilterValue} className={s.bbDisplayBlock}>
            {findUserById(tagFilterValue)}
          </span>
        ))}
      </>
    );

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: () => {
        if (triggerId === "visibility_access") setVisibleToUserGroupsValues(meUserValue);
        else if (triggerId === "editability_access") setEditableByUserGroupsValues(meUserValue);

        form.setFieldsValue({
          [triggerId]: { users: meUserValue },
        });
      },
      customSelectedIndicator: areArraysEqual(values, meUserValue) ? (
        <Tooltip placement="topLeft" title={me?.id ? findUserById(me.id) : ""}>
          <Chip _size="xxs" _type="primary" text={meText} className={s.bbIndicatorSelected} />
        </Tooltip>
      ) : undefined,
      ...tooltipProps,
    });
  };

  const VisibleToTrigger = (tooltipProps: IClearableTriggerProps) => {
    return TriggerWithTooltip(tooltipProps, "visibility_access");
  };

  const EditableByTrigger = (tooltipProps: IClearableTriggerProps) => {
    return TriggerWithTooltip(tooltipProps, "editability_access");
  };

  const onVisibleToChange = (currentValue: string) => {
    const newState = handleMultiselectState(
      visibleToUserGroupsNormalized,
      visibleToUserGroupsValues,
      currentValue,
    );

    setVisibleToUserGroupsValues(newState);

    // If the user is not in the visibleToGroups, remove it from the editableByGroups
    const filteredEditableByGroups = editableByGroupsValues.filter(item => newState.includes(item));
    setEditableByUserGroupsValues(filteredEditableByGroups);
  };

  const onEditableByChange = (currentValue: string) => {
    const newState = handleMultiselectState(
      editableByUserGroupsNormalized,
      editableByGroupsValues,
      currentValue,
    );

    setEditableByUserGroupsValues(newState);
  };

  const onAdvancedUserPermissionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && me?.id && !!findUserById(me.id)) {
      setVisibleToUserGroupsValues(meUserValue);
      setEditableByUserGroupsValues(meUserValue);
      form.setFieldsValue({
        visibility_access: { users: meUserValue },
        editability_access: { users: meUserValue },
      });
    }
    setAdvancedUserPermissionsChecked(event.target.checked);
  };

  return (
    <>
      {isBackofficeUser && (
        <div className={s.bbAdvancedUserPermissionsToggle}>
          <Toggle
            id="advanced_user_permissions"
            name="advanced_user_permissions"
            checked={advancedUserPermissionsChecked}
            onChange={onAdvancedUserPermissionsChange}
          />
          {t("pages:workflows:form:advancedUserPermissions:toggle")}
        </div>
      )}
      {advancedUserPermissionsChecked && (
        <div className={s.bbAdvancedUserPermissionsSection}>
          <Form.Item
            name={["visibility_access", "users"]}
            label={t("pages:workflows:form:advancedUserPermissions:visibleTo")}
            rules={[
              optionRequiredValidator(t("pages:workflows:form:advancedUserPermissions:required")),
            ]}
          >
            <SelectStyledMulti
              _size="sm"
              id="visibility_access"
              options={filteredVisibleToUsers || []}
              onChange={onVisibleToChange}
              value={visibleToUserGroupsValues}
              TriggerVariation={VisibleToTrigger}
              highlightTerm={visibleToSearchTerm}
              onDropdownClose={() => setVisibleToSearchTerm("")}
              dropdownMatchSelectWidth
              isNestedOptionsOpenByDefault
              DropdownHeader={VisibleToSearchInput}
            />
          </Form.Item>
          <Form.Item
            name={["editability_access", "users"]}
            label={t("pages:workflows:form:advancedUserPermissions:editableBy")}
            rules={[
              optionRequiredValidator(t("pages:workflows:form:advancedUserPermissions:required")),
            ]}
          >
            <SelectStyledMulti
              _size="sm"
              id="editability_access"
              options={filteredEditableByUsers || []}
              onChange={onEditableByChange}
              value={editableByGroupsValues}
              TriggerVariation={EditableByTrigger}
              highlightTerm={editableBySearchTerm}
              onDropdownClose={() => setEditableBySearchTerm("")}
              dropdownMatchSelectWidth
              isNestedOptionsOpenByDefault
              DropdownHeader={EditableBySearchInput}
            />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default WorkflowFormUserPermissions;
