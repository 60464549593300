import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";

import { TabsScrollable, TabPane, InternalNotesContent } from "@bbdevcrew/bb_ui_kit_fe";
import { InternalNotesContext } from "@components/_common/ReplyModal/InternalNotesContext";

import { internalNotesSelector } from "@store/internalNotes/selectors";
import { meSelector } from "@store/me/selectors";

import {
  deletePreviewPublishNotesAction,
  getInternalNotesAction,
  postPreviewPublishNotesAction,
  postPublishNotesAction,
} from "@store/internalNotes/actions";

import s from "./Post.module.less";

import { IPostTabsProps } from "./Post.type";
import { generateRandomId } from "./helpers";
import { IMention } from "@bbdevcrew/bb_ui_kit_fe";

export const PostTabs: FC<IPostTabsProps> = ({ id, children, className, notesClassName }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const searchedPostId = searchParams.get("publication_id");

  const [activeTab, setActiveTab] = React.useState(
    id === searchedPostId ? "internal_notes" : "preview",
  );

  const notesList = useSelector(internalNotesSelector);
  const me = useSelector(meSelector);

  const getInternalNotes = useCallback(
    postId => {
      dispatch(getInternalNotesAction({ id: postId, isPublishPost: true }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (id) {
      getInternalNotes(id);
    }
  }, [id, getInternalNotes]);

  const onSendNote = useCallback(
    (message, mentionMap) => {
      if (id) dispatch(postPublishNotesAction({ id, message }));
      else
        dispatch(
          postPreviewPublishNotesAction({
            id: generateRandomId(),
            message,
            author: {
              label: `${me?.first_name} ${me?.last_name}`,
              id: me?.id,
            },
            mentions: mentionMap.map((mention: IMention) => ({
              pretty: mention.label,
              id: mention.id,
            })),
          }),
        );
    },
    [dispatch, me, id],
  );

  const onDeleteNoteItem = useCallback(
    noteId => {
      dispatch(deletePreviewPublishNotesAction(noteId));
    },

    [dispatch],
  );

  return (
    <TabsScrollable
      activeKey={activeTab}
      onChange={setActiveTab}
      className={s.bbPostTabs}
      tabContentClassName={classNames(
        {
          [s.bbPostTabsNotes]: activeTab === "internal_notes",
        },
        className,
      )}
    >
      <TabPane
        tabKey="preview"
        tab={
          <div className={s.bbPostTabsTitle}>
            <span>{t("components:publish:postCreationModal:notes:previewTab")}</span>
          </div>
        }
      >
        {children}
      </TabPane>
      <TabPane
        tabKey="internal_notes"
        tab={
          <div className={s.bbPostTabsTitle}>
            <span>{t("components:publish:postCreationModal:notes:notesTab")}</span>
            {!!notesList.length && (
              <span className={s.bbPostTabsNotesBadge}>{notesList.length}</span>
            )}
          </div>
        }
      >
        <InternalNotesContext>
          <InternalNotesContent
            className={classNames(s.bbPostTabsNotesContent, notesClassName)}
            onSendNote={onSendNote}
            onDeleteNote={!id ? onDeleteNoteItem : undefined}
          />
        </InternalNotesContext>
      </TabPane>
    </TabsScrollable>
  );
};
