import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import { IReelPreviewProps } from "./types";

import s from "./ReelPreview.module.less";

import {
  PlayIcon,
  SendIcon,
  HeartIcon,
  MapPinFilledIcon,
  CommentIcon,
  MoreActionsIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { MusicIcon, PhotoIcon } from "@assets/index";

const ReelPreview: FC<IReelPreviewProps> = ({
  video,
  caption,
  pageName,
  pageAvatar,
  coverImage,
  location,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbReelPreview}>
      <div className={s.bbReelWrapper}>
        {coverImage ? (
          <img width="240" src={coverImage.src} />
        ) : (
          <video width="240">
            <source src={video?.object_src} />
          </video>
        )}
        <div className={s.bbGradientOverlay}>
          <PlayIcon />
        </div>
        <div className={s.bbRightVerticalActions}>
          <div className={s.bbPhotoIcon}>
            <PhotoIcon />
          </div>
          <div className={s.bbLikeAction}>
            <HeartIcon />
          </div>
          <div className={s.bbCommentAction}>
            <CommentIcon />
          </div>
          <div className={s.bbShareAction}>
            <SendIcon />
          </div>
          <div className={s.bbMoreAction}>
            <MoreActionsIcon />
          </div>
          <div className={s.bbAssetImage}>
            <UserAvatar img={pageAvatar} firstName={pageName} />
          </div>
        </div>
        <div className={s.bbBottomHorizontalActions}>
          <div className={s.bbAssetImage}>
            <UserAvatar img={pageAvatar} firstName={pageName} />
            {pageName}
          </div>
          <div className={s.bbCaption}>{caption}</div>
          <div
            className={classNames(s.bbAudioWrapper, { [s.bbAudioAndLocationWrapper]: !!location })}
          >
            <div className={s.bbAudio}>
              <MusicIcon />
              {pageName} • {t("components:publish:postCreationModal:preview:reelOriginalAudio")}
            </div>
            {!!location && (
              <div className={s.bbLocation}>
                <MapPinFilledIcon />
                <span>{location}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReelPreview;
