import { useSelector } from "react-redux";
import React, { FC, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Calendar from "@components/publish/calendar/Calendar";
import AppLayoutContainer from "@components/_common/AppLayoutContainer/AppLayoutContainer";
import NotFoundView from "@containers/NotFoundView/NotFoundView";

import { meSelector, publishAppFeature } from "@store/me/selectors";

import { IMenuItem } from "@bbdevcrew/bb_ui_kit_fe";
import { IRestrictedRoutesProps } from "./Routes.type";
import { PublishSubFeatureComponents } from "./helpers";
import { useRoutes } from "@utils/useRoutes";

const PublishRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const { extraMenuItems, redirectCallback } = useRoutes(path);

  const me = useSelector(meSelector);
  const feature = useSelector(publishAppFeature);
  const subfeatures = useMemo(() => feature?.subfeatures || [], [feature]);

  return (
    <AppLayoutContainer
      basename="publish"
      extra={extraMenuItems}
      avatarUrl={me?.client?.logo}
      defaultSelectedKey="calendar"
      menu={menu as unknown as IMenuItem[]}
      selectedKey={(path && path.replace(/\//g, "")) || "calendar"}
    >
      {{
        content: (
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={{
                    pathname: subfeatures.length ? subfeatures[0].id : "calendar",
                    search: window.location.search,
                  }}
                />
              }
            />
            {subfeatures.length ? (
              subfeatures?.map(({ id, is_disabled }) => {
                const RouteComponent =
                  PublishSubFeatureComponents[id as keyof typeof PublishSubFeatureComponents] ||
                  null;

                return (
                  <Route
                    key={id}
                    path={id}
                    element={
                      <PrivateRoute isDisabled={is_disabled}>
                        <RouteComponent />
                      </PrivateRoute>
                    }
                  />
                );
              })
            ) : (
              <Route
                key="calendar"
                path="calendar"
                element={
                  <PrivateRoute isDisabled={false}>
                    <Calendar />
                  </PrivateRoute>
                }
              />
            )}
            <Route
              path="unauthorized"
              element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
            />
            <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
          </Routes>
        ),
      }}
    </AppLayoutContainer>
  );
};

export default PublishRoutes;
