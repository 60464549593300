import { IMultiSelectFilter } from "@store/me/types";
import { IMultiSelectFilterOption } from "@bbdevcrew/bb_ui_kit_fe";

export const selectedPostTypes = (data?: IMultiSelectFilter) => {
  const flattenPostTypes: IMultiSelectFilterOption[] = [];

  data?.items?.forEach(item => {
    const parent = {
      id: item.id,
      label: item.label,
      selected: item.selected,
      description: item.description,
    };

    flattenPostTypes.push(parent);

    if (item.items) {
      item.items.forEach(i => flattenPostTypes.push(i));
    }
  });

  return flattenPostTypes.filter(item => item.selected).map(item => item.id);
};
