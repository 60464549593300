import { createReducer } from "typesafe-actions";
import produce from "immer";
import * as actions from "./actions";
import { IInternalNotesState, InternalNotesActionsType } from "./types";

const initialState = {
  fetching: false,
  fetched: false,
  fetchFail: false,
  list: [],
};

export const internalNotesReducer = createReducer<IInternalNotesState, InternalNotesActionsType>(
  initialState,
)
  .handleAction(actions.getInternalNotesAction, state => {
    return produce(state, draft => {
      draft.fetching = true;
      draft.fetched = false;
      draft.fetchFail = false;
    });
  })
  .handleAction(actions.getInternalNotesSuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.list = action.payload;
      draft.fetching = false;
      draft.fetched = true;
      draft.fetchFail = false;
    });
  })
  .handleAction(actions.getInternalNotesFailureAction, state => {
    return produce(state, draft => {
      draft.list = [];
      draft.fetching = false;
      draft.fetched = false;
      draft.fetchFail = true;
    });
  })
  .handleAction(actions.postInternalNotesAction, state => {
    return produce(state, draft => {
      draft.fetching = true;
      draft.fetched = false;
      draft.fetchFail = false;
    });
  })
  .handleAction(actions.postInternalNotesFailureAction, state => {
    return produce(state, draft => {
      draft.fetching = false;
      draft.fetched = false;
      draft.fetchFail = true;
    });
  })
  .handleAction(actions.deleteInternalNoteAction, state => {
    return produce(state, draft => {
      draft.fetching = false;
      draft.fetched = false;
      draft.fetchFail = false;
    });
  })
  .handleAction(actions.deleteInternalNoteSuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.list = draft.list.filter(note => note.id !== action.payload);
      draft.fetching = false;
      draft.fetched = true;
      draft.fetchFail = false;
    });
  })
  .handleAction(actions.deleteInternalNoteFailureAction, state => {
    return produce(state, draft => {
      draft.fetching = false;
      draft.fetched = false;
      draft.fetchFail = true;
    });
  })
  .handleAction(actions.postPreviewPublishNotesAction, (state, action) => {
    return produce(state, draft => {
      draft.list = [
        ...state.list,
        {
          ...action.payload,
          author: {
            pretty: action.payload.author.label,
            id: action.payload.author.id || "",
          },
          mentions: action.payload.mentions || [],
        },
      ];
      draft.fetching = true;
      draft.fetched = false;
      draft.fetchFail = false;
    });
  })
  .handleAction(actions.deletePreviewPublishNotesAction, (state, action) => {
    return produce(state, draft => {
      draft.list = draft.list.filter(note => note.id !== action.payload);
    });
  })
  .handleAction(actions.resetInternalNotesAction, state => {
    return produce(state, draft => {
      draft.list = [];
    });
  });
