export enum SignUpSourceEnum {
  PILOT = "pilot",
  ANALYSIS = "analysis",
  REPUTATION = "reputation",
  ENGAGE = "engage",
}

export interface IFormStep1 {
  email?: string;
  password?: string;
  terms_accepted?: boolean;
  confirm_password?: string;
  sales_analysis?: boolean;
  managed_pilot?: boolean;
  source?: SignUpSourceEnum;
}

export type IStep1CallbackData = Omit<IFormStep1, "confirm_password">;

export interface IStep1Props {
  authState?: string;
  isAgencyView: boolean;
  signUpSource?: SignUpSourceEnum;
  setIsAgencyView: (isAgency: boolean) => void;
  onFinish: (data: IStep1CallbackData) => void;
  onStateChange?: (authState: string, data?: unknown) => void;
}
