import dayjs from "dayjs";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import PostsTable from "./postsTable/PostsTable";
import { AlertInfo, Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import CSVDownloadInfo from "../../_common/CSVDownloadInfo/CSVDownloadInfo";
import Header from "../_common/header";
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";
import TopPostTags from "./topPostTags";

import {
  getPostsCSVSelector,
  getPostsCSVSelectorPending,
  getPostsCSVSelectorSuccessful,
  getPostsPendingSelector,
  getPostsSelector,
} from "@store/posts/selectors";
import { meSuccessfulSelector } from "@store/me/selectors";
import { clearPostsCSVAction, getPostsCSVAction } from "@store/posts/actions";
import { resetTriggerFilterClickWithoutUrlAction } from "@store/filters/actions";

import s from "./Posts.module.less";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { useYTSelected } from "@utils/useYTselected";
import { getDefaultFilterValue } from "@utils/filters";
import { PostsCSVPayloadType } from "@store/posts/types";
import { useCurrentFilters } from "@utils/useCurrentFilters";
import { IPostsTableRef } from "./postsTable/PostsTable.types";
import { getPostsPageAllowedFilters } from "./postsTable/helpers";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";

import { DownloadIcon } from "@assets/index";

const POSTS_CSV_LIMIT = 5000;

const Posts: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterData = useCurrentFilters();
  const postsTableRef = useRef<IPostsTableRef>(null);
  const { platform_types } = getDefaultFilterValue();
  const defaultFilters = useGetDefaultFiltersConfig(t);

  const [filters, setFilters] = useState<IFilters>({
    data_range_option: "last_30_days",
    platform_types,
  });
  const [showDisclaimer, setShowDisclaimer] = useState(
    JSON.parse(localStorage.getItem("PostsPageDisclaimer") || "true"),
  );
  const [commentsDownloadModalOpen, setPostsDownloadModalOpen] = useState(false);

  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const posts = useSelector(getPostsSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const postsPending = useSelector(getPostsPendingSelector);
  const postsCSVFetched = useSelector(getPostsCSVSelectorSuccessful);
  const postsCSVPending = useSelector(getPostsCSVSelectorPending);
  const postsCSV = useSelector(getPostsCSVSelector);

  const downloadCSVDefaultTooltip = t("components:posts:downloadCSV:tooltip", {
    limit: POSTS_CSV_LIMIT,
  });

  const triggerFilterReset = useCallback(
    () => dispatch(resetTriggerFilterClickWithoutUrlAction()),
    [dispatch],
  );

  const getPostsCSV = useCallback(
    (data: PostsCSVPayloadType) => dispatch(getPostsCSVAction(data)),
    [dispatch],
  );

  const clearPostsCSV = useCallback(() => dispatch(clearPostsCSVAction()), [dispatch]);

  const onFilter = (data: IFilters) => {
    setFilters(data);
    triggerFilterReset();
  };

  useEffect(() => {
    if (filterData) onFilter(filterData);
    // eslint-disable-next-line
  }, [filterData]);

  useEffect(() => {
    if (postsCSV && postsCSVFetched) {
      const now = dayjs();
      fileDownload(postsCSV, `posts_${now.format("YYYY-MM-DD")}_${now.format("hh_mm_A")}.csv`);
      clearPostsCSV();
    }
  }, [postsCSV, postsCSVFetched, clearPostsCSV]);

  const onCloseBtnClick = () => {
    localStorage.setItem("PostsPageDisclaimer", "false");
    setShowDisclaimer(false);
  };

  const triggerPostsDownload = () => {
    setPostsDownloadModalOpen(true);

    const filtersData = getPostsPageAllowedFilters(filters, defaultFilters);

    getPostsCSV({
      filters: filtersData,
      sort: postsTableRef?.current?.getSortValue(),
    });
  };

  return meFetched ? (
    <div data-cy="posts-container">
      {showDisclaimer && (
        <AlertInfo
          size="large"
          align="center"
          icon={<></>}
          data-cy="posts-disclaimer"
          className={s.bbPostsDisclaimerAlert}
          onClose={() => onCloseBtnClick()}
          message={t("components:posts:disclaimer:copy")}
          action={
            <a className={s.bbPostsDisclaimerAlertAction} onClick={() => onCloseBtnClick()}>
              {t("components:posts:disclaimer:action")}
            </a>
          }
        />
      )}
      <div className={s.bbPostsTableTopContainer}>
        <Header className={s.bbPostTableHeader}>
          <MobileFiltersButton />
          {!!posts.length && (
            <Tooltip
              placement="top"
              title={
                isYTFilterSelected ? t("pages:insights:ytNoDownload") : downloadCSVDefaultTooltip
              }
            >
              <span>
                <Button
                  _type="secondary"
                  className={s.bbDownloadPostsCSV}
                  disabled={isYTFilterSelected || postsPending}
                  onClick={() => !isYTFilterSelected && triggerPostsDownload()}
                >
                  <DownloadIcon />
                  {t("generic:download")}
                </Button>
              </span>
            </Tooltip>
          )}
        </Header>
      </div>

      {filters && (
        <>
          <PostsTable filters={filters} ref={postsTableRef} />
          <TopPostTags filters={filters} />
        </>
      )}

      <CSVDownloadInfo
        loading={postsCSVPending}
        open={commentsDownloadModalOpen}
        csvDownloaded={postsCSVFetched}
        downloadAgain={triggerPostsDownload}
        closeModal={() => setPostsDownloadModalOpen(false)}
        successText={t("components:posts:downloadCSV:csvDownloaded")}
        loadingText={t("components:posts:downloadCSV:preparingData")}
        exportInfoText={t("components:posts:downloadCSV:exportInfo", {
          limit: POSTS_CSV_LIMIT,
        })}
      />
    </div>
  ) : null;
};

export default Posts;
