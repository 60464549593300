import { WeekdayType } from "@store/notifications/types";

export const weekdays: WeekdayType[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
