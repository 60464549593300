import { RootState } from "../";
import { IAIHighlightPrompt } from "./types";

const fromAIHighlightPrompts = (state: RootState) => state.aiHighlightPrompts;

export const aiHighlightPromptsSelector = (state: RootState): IAIHighlightPrompt[] =>
  fromAIHighlightPrompts(state).prompts || [];

export const aiHighlightCurrentPromptSelector = (state: RootState): IAIHighlightPrompt | null =>
  fromAIHighlightPrompts(state)?.currentPrompt || null;

export const aiHighlightPromptByIdLoadingSelector = (state: RootState): boolean =>
  fromAIHighlightPrompts(state)?.getPromptById?.fetching || false;

export const aiHighlightPromptByIdFetchedSelector = (state: RootState): boolean =>
  fromAIHighlightPrompts(state)?.getPromptById?.fetched || false;

export const defaultAIHighlightPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).prompts?.find((item: IAIHighlightPrompt) => item.is_default);

export const fetchingPromptsSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).getPrompts.fetching;
export const fetchedPromptsSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).getPrompts.fetched;
export const fetchPromptsFailSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).getPrompts.fetchFail;

export const creatingPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).createPrompt.fetching;
export const createdPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).createPrompt.fetched;
export const createPromptFailSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).createPrompt.fetchFail;

export const updatingPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).updatePrompt.fetching;
export const updatedPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).updatePrompt.fetched;
export const updatePromptFailSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).updatePrompt.fetchFail;

export const deletingPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).deletePrompt.fetching;
export const deletedPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).deletePrompt.fetched;
export const deletePromptFailSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).deletePrompt.fetchFail;

export const settingDefaultPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).setDefaultPrompt.fetching;
export const setDefaultPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).setDefaultPrompt.fetched;
export const setDefaultPromptFailSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).setDefaultPrompt.fetchFail;

export const isSavingPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).createPrompt.fetching ||
  fromAIHighlightPrompts(state).updatePrompt.fetching;

export const savedPromptSelector = (state: RootState) =>
  fromAIHighlightPrompts(state).createPrompt.fetched ||
  fromAIHighlightPrompts(state).updatePrompt.fetched;
