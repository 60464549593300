import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import { Grid } from "antd";
import { AIModalIcon } from "./AIModalIcon";

import { filtersOpenSelector } from "@store/filters/selectors";

import s from "./AIModal.module.less";

import { IAIModalProps } from "./AIModal.types";

const cx = classNames.bind(s);

export const AIModal: React.FC<IAIModalProps> = ({
  isCollapsed,
  isOpen,
  onIconClick,
  children,
  iconSrc,
  iconClassName,
}) => {
  const screens = Grid.useBreakpoint();

  const isMobile = screens.md === false;

  const filtersPanelOpen = useSelector(filtersOpenSelector);

  return (
    <>
      {isCollapsed && (
        <AIModalIcon
          className={cx(
            s.bbAIIcon,
            {
              [s.bbAIIconWithFilters]: filtersPanelOpen && !isMobile,
            },
            iconClassName,
          )}
          onClick={onIconClick}
          src={iconSrc}
        />
      )}

      <Modal
        open={isOpen}
        hideCloseIcon
        hideFooter
        hideHeader
        mask={false}
        classes={{
          wrapper: cx(s.bbAIModalWrapper, {
            [s.bbAIModalWrapperCollapsed]: isCollapsed,
          }),
          root: s.bbAIModalRoot,
          modal: s.bbAIModal,
          body: s.bbAIModalBody,
        }}
      >
        <div className={s.bbAIModalContent}>{children}</div>
      </Modal>
    </>
  );
};
