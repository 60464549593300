import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Col, Row } from "antd";
import { EngageComments } from "./EngageComments";
import { EngageUpgrade } from "./EngageUpgrade";
import CareSection from "../externalAppsOverview/CareSection";
import { Button, InputWhite, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import SectionDivider from "@components/insights/overview/OverviewSectionDivider";

import { meSelector } from "@store/me/selectors";
import { overviewCareSelector } from "@store/dashboard/selectors";

import s from "./Engage.module.less";

import { IManagedProtectProps } from "../Transparency.types";
import { getReplySelectOptions } from "../externalAppsOverview/helpers";

import { ArrowUpIcon } from "@assets/index";
import { CommentIcon, InboxIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ManagedEngage = ({
  isLoading,
  filterToSpecificItem,
  filters,
}: IManagedProtectProps) => {
  const { t } = useTranslation();

  const queryParams = localStorage.getItem("query") || "";

  const [replySelectValue, setReplySelectValue] = useState(getReplySelectOptions(t).REPLIED_BY_BB);

  const me = useSelector(meSelector);
  const overviewCareData = useSelector(overviewCareSelector);

  const clientPlan = me?.client?.client_plan?.id;
  const isPrime = clientPlan === "PRIME";
  const isGrowthSemi = clientPlan === "GROWTH_SEMI";
  const isFeatureLocked = !isPrime && !isGrowthSemi;

  const handleViewAllClick = () => {
    const queryFilters = !!queryParams ? `/${queryParams}&` : "?";
    window.location.href = `${window.location.origin}/care${queryFilters}tab=care_highlights`;
  };

  return (
    <div className={s.bbEngageWrapper}>
      <SectionDivider
        icon={isGrowthSemi ? <InboxIcon /> : <CommentIcon />}
        title={
          isGrowthSemi
            ? t("components:managed:engage:semi:title")
            : t("components:managed:engage:title")
        }
        description={
          isGrowthSemi
            ? t("components:managed:engage:semi:description")
            : t("components:managed:engage:description", { name: me?.client?.name })
        }
        additionalAction={
          <>
            {isPrime
              ? replySelectValue && (
                  <SelectStyled
                    _size="md"
                    value={replySelectValue}
                    onChange={setReplySelectValue}
                    InputVariation={InputWhite}
                    wrapperClassName={s.bbReplySelect}
                    options={Object.values(getReplySelectOptions(t))}
                  />
                )
              : !isFeatureLocked && (
                  <Button onClick={handleViewAllClick} className={s.bbEngageHeaderButton}>
                    <span>{t("components:managed:protect:comments:viewAll")}</span>
                    <ArrowUpIcon />
                  </Button>
                )}
          </>
        }
      />

      <div className={s.bbEngageSection}>
        <Row gutter={[28, 28]}>
          {!isFeatureLocked && (
            <CareSection
              loading={isLoading}
              replySelectValue={replySelectValue}
              data={overviewCareData}
              showPrimeWidgets={isPrime}
            />
          )}
          {isPrime && (
            <Col xs={24}>
              <EngageComments filterToSpecificItem={filterToSpecificItem} filters={filters} />
            </Col>
          )}
          {isFeatureLocked && <EngageUpgrade />}
        </Row>
      </div>
    </div>
  );
};
