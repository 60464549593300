import React, { useState, useEffect } from "react";

import s from "./ExpansionPanel.module.less";

import { IExpansionPanelGroupProps } from "./ExpansionPanel.types";

export const ExpansionPanelGroup: React.FC<IExpansionPanelGroupProps> = ({
  children,
  initialOpenPanel = 0,
}) => {
  const [openPanel, setOpenPanel] = useState<number | null>(initialOpenPanel);
  const [forcedOpenPanels, setForcedOpenPanels] = useState<Set<number>>(new Set());

  useEffect(() => {
    React.Children.forEach(children, (child, index) => {
      const childElement = child as React.ReactElement;
      if (childElement.props.open === true) {
        setForcedOpenPanels(prev => new Set(prev).add(index));
      }
    });
  }, [children]);

  const handleToggle = (index: number) => {
    if (openPanel === index) {
      setOpenPanel(null);
      setForcedOpenPanels(prev => {
        const next = new Set(prev);
        next.delete(index);
        return next;
      });
    } else {
      setOpenPanel(index);
    }
  };

  return (
    <div className={s.bbExpansionPanelGroup}>
      {React.Children.map(children, (child, index) => {
        const childElement = child as React.ReactElement;
        const isOpen =
          childElement.props.open || openPanel === index || forcedOpenPanels.has(index);

        return React.cloneElement(childElement, {
          step: index + 1,
          open: isOpen,
          onToggle: () => handleToggle(index),
        });
      })}
    </div>
  );
};
