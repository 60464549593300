import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Form } from "antd";
import AutomatedHidingKeyword from "./AutomatedHidingKeyword";
import AutomatedHidingPlatforms from "./AutomatedHidingPlatforms";
import AutomatedHidingClassifiers from "./AutomatedHidingClassifiers";
import AutomatedHidingDisclaimer from "./AutomatedHidingDisclaimer";
import AutomatedHidingPostTypes from "./AutomatedHidingPostTypes";
import AutoHidingConfirmModal from "./AutoHidingConfirmModal";
import AutoHidingSaveUnsavedModal from "./AutoHidingSaveUnsavedModal";
import { Button, HelpIcon, addToast, AssetsMultiFilter } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AutomatedHidingMenu.module.less";

import {
  getHidingConfigSelector,
  getHidingConfigSelectorSuccessful,
} from "@store/hidingConfig/selectors";
import { meSelector } from "@store/me/selectors";
import { patchMeClientAction } from "@store/me/actions";
import { getHidingConfigAction } from "@store/hidingConfig/actions";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import {
  setUnsavedInfo,
  cleanUnsavedInfo,
  getSavedAssets,
  getSavedKeywords,
  getSavedPlatforms,
  getSavedPostTypes,
  getSavedClassifiers,
  getSelectedPostTypeIds,
} from "./helpers";
import arraysAreEqual from "@utils/arraysAreEqual";
import { IConfigRequest } from "@store/hidingConfig/types";
import { IGetAutocompleteOptionsPayload, debounce } from "@bbdevcrew/bb_ui_kit_fe";

import { SettingsIcon, ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AutomatedHidingMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [selectedClassifiers, setSelectedClassifiers] = useState<string[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [selectedPostTypes, setSelectedPostTypes] = useState<string[]>([]);
  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false);

  const [initialState, setInitialState] = useState<IConfigRequest>({
    assets: [],
    post_types: [],
    classifiers: [],
    platforms: [],
    keywords: [],
    keyword_blocker_in_translation: true,
  });

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [arePlatformsDirty, setArePlatformsDirty] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(
    localStorage.getItem("automated_hiding_unsaved_changes") === "true" || false,
  );

  const me = useSelector(meSelector);
  const hidingConfig = useSelector(getHidingConfigSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const hidingConfigFetched = useSelector(getHidingConfigSelectorSuccessful);

  const autoHidingKeywordTranslation =
    me?.client?.auto_hiding_config?.keyword_blocker_in_translation || false;

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const getHidingConfig = useCallback(() => dispatch(getHidingConfigAction()), [dispatch]);
  const updateHidingConfig = useCallback(
    (data: IConfigRequest) => {
      dispatch(
        patchMeClientAction({
          hiding_config: data,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    getHidingConfig();
  }, [getHidingConfig]);

  useEffect(() => {
    if (hidingConfigFetched && hidingConfig) {
      const keywordsSelected = hidingConfig.keywords;
      const classifiersSelected = hidingConfig.classifiers.filter(c => c.selected).map(c => c.id);

      const platformsSelected = hidingConfig.platforms.filter(p => p.selected).map(p => p.id);

      const assetsSelected = hidingConfig.assets
        .filter(asset => asset.selected)
        .map(asset => asset.id);

      const postTypesSelected = getSelectedPostTypeIds(hidingConfig.post_types);

      setSelectedClassifiers(classifiersSelected);
      setSelectedPlatforms(platformsSelected);
      setSelectedKeywords(keywordsSelected);
      setSelectedAssets(assetsSelected);
      setSelectedPostTypes(postTypesSelected);

      form.setFieldsValue({
        keyword_blocker: keywordsSelected,
        post_types: postTypesSelected,
        keyword_blocker_in_translation: autoHidingKeywordTranslation,
      });

      setInitialState({
        platforms: platformsSelected,
        classifiers: classifiersSelected,
        keywords: keywordsSelected,
        keyword_blocker_in_translation: autoHidingKeywordTranslation,
        assets: assetsSelected,
        post_types: postTypesSelected,
      });
    }
  }, [hidingConfigFetched, hidingConfig, form, autoHidingKeywordTranslation]);

  const hasNoAssets = useCallback(() => {
    if (hidingConfigFetched && hidingConfig) return hidingConfig.platforms.length === 0;

    return false;
  }, [hidingConfigFetched, hidingConfig]);

  const isKeywordVisible = useCallback(() => {
    return selectedClassifiers.includes("keyword_blocker");
  }, [selectedClassifiers]);

  const getSelectedKeywords = () => {
    const isKeywordBlockerSelected = selectedClassifiers.includes("keyword_blocker");
    return isKeywordBlockerSelected ? selectedKeywords : [];
  };

  const getSelectedClassifiers = () => {
    return selectedClassifiers.filter(classifier => classifier !== "keyword_blocker");
  };

  const boxIsInInitialState = () => {
    if (
      arraysAreEqual(selectedClassifiers, initialState.classifiers) &&
      arraysAreEqual(selectedPlatforms, initialState.platforms) &&
      arraysAreEqual(getSelectedKeywords(), initialState.keywords) &&
      arraysAreEqual(selectedAssets, initialState.assets) &&
      arraysAreEqual(selectedPostTypes, initialState.post_types)
    )
      return true;

    return false;
  };

  const isSaveBtnDisabled = () => {
    const noPlatformsSelected = !selectedPlatforms.length;
    const classifiersOrKeywordsSelected = !!selectedClassifiers.length;

    return boxIsInInitialState() || (noPlatformsSelected && classifiersOrKeywordsSelected);
  };

  const isToggleSelectedFirstTime = (nextSelectedToggles: string[]) => {
    return (
      !selectedClassifiers.length && !selectedPlatforms.length && nextSelectedToggles.length === 1
    );
  };

  const isKeywordSelectedFirstTime = (nextSelectedKeywords: string[]) => {
    return (
      !selectedPlatforms.length && !selectedClassifiers.length && nextSelectedKeywords.length === 1
    );
  };

  const selectPlatformsBasedOnClassifiers = (classifiers: string[]) => {
    const hasClassifiersSelected = !!classifiers.length;
    const allPlatforms = hidingConfig.platforms.map(p => p.id);

    if (hasClassifiersSelected && !arePlatformsDirty) {
      setSelectedPlatforms(allPlatforms);
    }

    if (isToggleSelectedFirstTime(classifiers)) setSelectedPlatforms(allPlatforms);

    if (!hasClassifiersSelected) setSelectedPlatforms([]);
  };

  const onClassifierToggleClick = (id: string) => {
    const isSelected = !!selectedClassifiers.find(classifierId => classifierId === id);
    const nextSelectedToggles = isSelected
      ? selectedClassifiers.filter(classifierId => classifierId !== id)
      : [...selectedClassifiers, id];

    setIsFormDirty(true);
    setSelectedClassifiers(nextSelectedToggles);
    selectPlatformsBasedOnClassifiers(nextSelectedToggles);
  };

  const onPlatformClick = (id: string) => {
    const isChecked = !!selectedPlatforms.find(platformId => platformId === id);
    const nextSelectedPlatforms = isChecked
      ? selectedPlatforms.filter(platformId => platformId !== id)
      : [...selectedPlatforms, id];

    setSelectedPlatforms(nextSelectedPlatforms);
    setArePlatformsDirty(true);
    setIsFormDirty(true);
  };

  const onSaveChanges = () => {
    setIsConfirmationModalVisible(true);
    setUnsavedInfo(
      selectedClassifiers,
      selectedPlatforms,
      getSelectedKeywords(),
      selectedAssets,
      selectedPostTypes,
    );
  };

  const hasUnsavedChanges = () => {
    const savedClassifiers = getSavedClassifiers();
    const savedPlatforms = getSavedPlatforms();
    const savedKeywords = getSavedKeywords();
    const savedAssets = getSavedAssets();
    const savedPostTypes = getSavedPostTypes();

    return (
      arraysAreEqual(selectedClassifiers, savedClassifiers) &&
      arraysAreEqual(selectedPlatforms, savedPlatforms) &&
      arraysAreEqual(getSelectedKeywords(), savedKeywords) &&
      arraysAreEqual(selectedAssets, savedAssets) &&
      arraysAreEqual(selectedPostTypes, savedPostTypes)
    );
  };

  const onKeywordChange = (nextSelectedKeywords: string[] = []) => {
    if (!arraysAreEqual(selectedKeywords, nextSelectedKeywords)) {
      if (isKeywordSelectedFirstTime(nextSelectedKeywords)) {
        setSelectedPlatforms(hidingConfig.platforms.map(p => p.id));
      }

      if (!nextSelectedKeywords.length && !selectedClassifiers.length) setSelectedPlatforms([]);

      setIsFormDirty(true);
      setSelectedKeywords(nextSelectedKeywords || []);
    }
  };

  const handleAssetsChange = (assetIds: string[]) => {
    if (arraysAreEqual(selectedAssets, assetIds)) return;

    setSelectedAssets(assetIds);
    setIsFormDirty(true);
  };

  const handlePostTypesChange = (postTypes: string[]) => {
    if (arraysAreEqual(selectedPostTypes, postTypes)) return;

    setSelectedPostTypes(postTypes);
    setIsFormDirty(true);
  };

  const onConfirmChanges = () => {
    cleanUnsavedInfo();

    updateHidingConfig({
      platforms: selectedPlatforms,
      classifiers: selectedClassifiers,
      keywords: getSelectedKeywords(),
      keyword_blocker_in_translation: form.getFieldValue("keyword_blocker_in_translation"),
      assets: selectedAssets,
      post_types: selectedPostTypes,
    } as IConfigRequest);

    setInitialState({
      platforms: selectedPlatforms,
      classifiers: selectedClassifiers,
      keywords: getSelectedKeywords(),
      keyword_blocker_in_translation: form.getFieldValue("keyword_blocker_in_translation"),
      assets: selectedAssets,
      post_types: selectedPostTypes,
    });

    addToast({
      type: "success_accent",
      title: t("generic:success"),
      message: t("components:automatedHiding:hidingSuccessMessage"),
    });
    setIsConfirmationModalVisible(false);
  };

  const onDiscardChanges = () => {
    cleanUnsavedInfo();

    // needs to reset the form to update it with the initial values
    form.resetFields();

    setIsConfirmationModalVisible(false);
    getHidingConfig();
  };

  const isUserAdmin = useCallback(() => {
    return me?.is_admin;
  }, [me]);

  return (
    <>
      <div className={s.bbAutomatedHidingMenu} data-cy="automated-hiding-config-menu">
        <h3>
          {t("components:automatedHiding:title")}
          <div className={s.bbAutomatedHidingMenuIcon}>
            <HelpIcon />
          </div>
        </h3>

        {hasNoAssets() && (
          <AutomatedHidingDisclaimer message={t("components:automatedHiding:noAsset")} />
        )}

        {!isUserAdmin() && (
          <AutomatedHidingDisclaimer
            message={t("components:automatedHiding:notAnAdminDisclaimer")}
          />
        )}

        {!boxIsInInitialState() ? <h4>{t("generic:unsavedChanges")}</h4> : null}
        <div className={s.bbAutomatedHidingMenuItems}>
          <AutomatedHidingClassifiers
            disabled={!hidingConfig.editable}
            selectedClassifiers={selectedClassifiers}
            classifiers={hidingConfig.classifiers || []}
            onClassifierToggleClick={onClassifierToggleClick}
          />
          {isKeywordVisible() && (
            <AutomatedHidingKeyword
              form={form}
              setKeywords={onKeywordChange}
              isDisabled={!hidingConfig.editable}
              keywords={hidingConfig.keywords}
            />
          )}
        </div>
        <button
          className={classNames(s.bbAutomatedHidingSettingsButton, {
            [s.bbAutomatedHidingSettingsButtonExpanded]: optionsExpanded,
          })}
          onClick={() => setOptionsExpanded(!optionsExpanded)}
        >
          <label className={s.bbAutomatedHidingSettingsButtonLabel}>
            <SettingsIcon />
            {t("components:automatedHiding:moreOptions")}
          </label>
          <span className={s.bbAutomatedHidingSettingsButtonIcon}>
            <ChevronDownIcon />
          </span>
        </button>
        <div
          style={{
            display: optionsExpanded ? "block" : "none",
          }}
        >
          {!!hidingConfig?.platforms?.length && (
            <AutomatedHidingPlatforms
              disabled={!hidingConfig.editable}
              onPlatformClick={onPlatformClick}
              platforms={hidingConfig.platforms}
              selectedKeywords={getSelectedKeywords()}
              selectedPlatforms={selectedPlatforms}
              selectedClassifiers={selectedClassifiers}
            />
          )}

          {hidingConfig?.assets && (
            <div className={s.bbAutomatedHidingMenuItems}>
              <div className={s.bbAssetsDisclaimer}>
                {t("components:automatedHiding:assetsDisclaimer")}
              </div>
              <div className={s.bbAssetsFilter}>
                <AssetsMultiFilter
                  value={selectedAssets}
                  onChange={handleAssetsChange}
                  disabled={!hidingConfig.editable}
                  autocompleteOptions={autocompleteOptions}
                  getAutocompleteOptions={getAutocompleteOptions}
                />
              </div>
            </div>
          )}

          {hidingConfig?.post_types && (
            <div className={s.bbAutomatedHidingMenuItems}>
              <div className={s.bbPostTypesDisclaimer}>
                {t("components:automatedHiding:postTypesDisclaimer")}
              </div>
              <AutomatedHidingPostTypes
                form={form}
                postTypes={getSelectedPostTypeIds(hidingConfig.post_types)}
                setPostTypes={handlePostTypesChange}
                disabled={!hidingConfig.editable}
              />
            </div>
          )}
        </div>

        {isFormDirty && (
          <Button
            _size="sm"
            _type="primary"
            onClick={onSaveChanges}
            disabled={isSaveBtnDisabled()}
            className={s.bbSaveChangesButton}
            data-cy="save-config-changes-button"
          >
            {t("generic:saveChanges")}
          </Button>
        )}
      </div>

      {hasUnsavedChanges() ? (
        <AutoHidingConfirmModal
          onCancel={onDiscardChanges}
          onConfirmChanges={onConfirmChanges}
          open={isConfirmationModalVisible}
          selectedAssets={selectedAssets}
          selectedPostTypes={selectedPostTypes}
          selectedPlatforms={selectedPlatforms}
          selectedKeywords={getSelectedKeywords()}
          selectedClassifiers={getSelectedClassifiers()}
        />
      ) : (
        <AutoHidingSaveUnsavedModal
          onCancel={onDiscardChanges}
          onConfirmChanges={onConfirmChanges}
          open={isConfirmationModalVisible}
          selectedAssets={getSavedAssets()}
          selectedPostTypes={getSavedPostTypes()}
          selectedKeywords={getSavedKeywords()}
          selectedPlatforms={getSavedPlatforms()}
          selectedClassifiers={getSavedClassifiers()}
        />
      )}
    </>
  );
};

export default AutomatedHidingMenu;
