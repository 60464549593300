import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import UserRow from "../UserRow";
import { EmptyState } from "./EmptyState";
import TagHeader from "../TagHeader";

import { useTagsContext } from "../../../TagsModal.helpers";

import s from "./../UserTags.module.less";

import { IUsersViewProps } from "./UsersView.types";
import UserTagInput from "../../../ImageView/UserTagInput";

export const UsersView: React.FC<IUsersViewProps> = ({ tags, showEmptyState }) => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const { isReel, maxTagsReached, addTag } = useTagsContext();

  return showEmptyState ? (
    <EmptyState />
  ) : (
    <>
      <TagHeader
        title={t("components:publish:postCreationModal:mediaTags:subtitle:users")}
        disabled={
          maxTagsReached
            ? t("components:publish:postCreationModal:mediaTags:limitReached")
            : undefined
        }
        addLabel={t("components:publish:postCreationModal:mediaTags:addTag")}
        onAddClick={isReel ? () => setShowInput(true) : undefined}
      />
      <ul className={s.bbUserTagsList}>
        {tags.map(tag => (
          <UserRow key={tag.username} tag={tag} />
        ))}
      </ul>
      {showInput && (
        <UserTagInput
          x={0.5}
          y={isReel ? 0.6 : 0.5}
          onAdd={tag => {
            addTag({ ...tag });
            setShowInput(false);
          }}
          onCancel={() => setShowInput(false)}
        />
      )}
    </>
  );
};
