import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  addPostTagResultSelector,
  addingPostTagSelector,
  addPostTagSuccessfulSelector,
  deleteTagFromPostSuccessfulSelector,
  deletedTagIdSelector,
  addingPostTagLabelSelector,
} from "@store/postTags/selectors";

import { IPost, IPostTag } from "@bbdevcrew/bb_ui_kit_fe";

const usePostTagsManagement = () => {
  const [postSelected, setPostSelected] = useState<IPost | undefined>();

  const tagCreationResult = useSelector(addPostTagResultSelector);
  const addingPostTag = useSelector(addingPostTagSelector);
  const tagAddedSuccessfully = useSelector(addPostTagSuccessfulSelector);
  const tagDeletedSuccessfully = useSelector(deleteTagFromPostSuccessfulSelector);
  const deletedTagId = useSelector(deletedTagIdSelector);
  const addingPostTagLabel = useSelector(addingPostTagLabelSelector);

  useEffect(() => {
    if (addingPostTag && !!addingPostTagLabel) {
      setPostSelected(prevPost => {
        if (!prevPost) return prevPost;
        return {
          ...prevPost,
          post_tags: [...(prevPost?.post_tags || []), { label: addingPostTagLabel } as IPostTag],
        };
      });
    }
  }, [addingPostTag, addingPostTagLabel]);

  useEffect(() => {
    if (tagAddedSuccessfully && !!tagCreationResult && postSelected?.post_tags?.length) {
      setPostSelected(prevPost => {
        if (!prevPost) return prevPost;
        const updatedPostTags = prevPost.post_tags.map(tag =>
          tag.label === tagCreationResult.label ? tagCreationResult : tag,
        );
        return { ...prevPost, post_tags: updatedPostTags };
      });
    }
  }, [tagAddedSuccessfully, tagCreationResult, postSelected?.post_tags?.length]);

  useEffect(() => {
    if (tagDeletedSuccessfully && !!deletedTagId && postSelected?.post_tags?.length) {
      setPostSelected(prevPost => {
        if (!prevPost) return prevPost;
        return {
          ...prevPost,
          post_tags: prevPost.post_tags.filter(tag => tag.id !== deletedTagId),
        };
      });
    }
  }, [tagDeletedSuccessfully, deletedTagId, postSelected?.post_tags?.length]);

  return {
    postSelected,
    setPostSelected,
  };
};

export default usePostTagsManagement;
