import React, { FC } from "react";

import TagBadge from "../../../_common/TagBadge";

import s from "./ImagePreview.module.less";

import { IImagePreviewProps } from "./types";
import { isPortrait } from "../previewFb/helpers";

import { UserFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { ShoppingBagIGFilledIcon } from "@assets/index";

const ImagePreview: FC<IImagePreviewProps> = ({ image, hasUserTags, hasProductTags }) => {
  const paddingTop = isPortrait(+image.width, +image.height) ? "110%" : "90%";

  return (
    <div className={s.bbSingleImageContainer}>
      <div>
        <a className={s.bbImageLink}>
          <div className={s.bbMainWrapper}>
            {(hasUserTags || hasProductTags) && (
              <div className={s.bbTagBadgeWrapper}>
                {hasUserTags && (
                  <TagBadge>
                    <UserFilledIcon />
                  </TagBadge>
                )}
                {hasProductTags && (
                  <TagBadge>
                    <ShoppingBagIGFilledIcon />
                  </TagBadge>
                )}
              </div>
            )}
            <div className={s.bbImageDimensions}>
              <div className={s.bbImageRootWrapper} style={{ paddingTop: paddingTop }}>
                <div
                  className={s.bbImageWrapper}
                  style={{ height: "calc(100.04%)", top: "0%", width: "100%" }}
                >
                  <img
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    className={s.bbImage}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={s.bbBorders}></div>
          <div className={s.bbMainWrapperSupporter}></div>
        </a>
      </div>
    </div>
  );
};

export default ImagePreview;
