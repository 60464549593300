import { t } from "../../../App";
import { ICompareProject, ICompareProjectAggregation } from "@store/compareProjects/types";

export const LABEL_LARGE = 250;
export const LABEL_SMALL = 150;
export const CHART_HEIGHT = 430;
export const BAR_SIZE = 25;

export const DEFAULT_PROJECT: ICompareProject = {
  name: "",
  compare_values: [],
  compare_type: "asset",
};

export const getCompareCSVData = (aggregation?: ICompareProjectAggregation) => {
  if (aggregation) {
    return aggregation?.items.map(item => {
      const obj = {
        name: item.label,
        total: "0",
      };

      item.comment_stats.forEach(stat => {
        obj[stat.label as keyof typeof obj] = stat.count.toString();

        const total = parseFloat(obj.total) + stat.count;
        obj.total = total.toString();
      });

      return obj;
    });
  }
  return [];
};

export const getCompareCSVHeaders = (aggregation?: ICompareProjectAggregation) => {
  if (aggregation?.items && aggregation?.items.length)
    return [
      t("generic:asset"),
      t("components:tags:values:total"),
      ...aggregation.items[0].comment_stats.map(stat => stat.label),
    ];
  return [];
};
