/* eslint-disable max-len */
import React from "react";
import { TFunction } from "i18next";
import { Chip } from "@bbdevcrew/bb_ui_kit_fe";
import { ICaptionsAIPrompt } from "@store/captionsAIPrompts/types";

export const getAIPromptSectionsConfig = (t: TFunction, initialValues?: ICaptionsAIPrompt) => {
  return [
    {
      title: t("components:AIPrompts:edit:introSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:introSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:captionsAIPrompts:introSection:settingSentenceChip1")}
          />
          {t("components:AIPrompts:captionsAIPrompts:introSection:settingSentence2")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:captionsAIPrompts:introSection:settingSentenceChip2")}
          />
          {t("components:AIPrompts:captionsAIPrompts:introSection:settingSentence3")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:captionsAIPrompts:introSection:settingSentenceChip3")}
          />
        </>
      ),
      fields: [
        {
          id: "brand_name",
          label: t("components:AIPrompts:captionsAIPrompts:introSection:variable1:label"),
          maxCharLimit: 50,
          enabled: initialValues?.brand_name_enabled || false,
          rowNumber: 1,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:introSection:variable1:placeholder",
          ),
          errorMessage: t("components:AIPrompts:captionsAIPrompts:introSection:variable1:error"),
        },
        {
          id: "brand_industry",
          label: t("components:AIPrompts:captionsAIPrompts:introSection:variable2:label"),
          maxCharLimit: 50,
          enabled: initialValues?.brand_industry_enabled || false,
          rowNumber: 1,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:introSection:variable2:placeholder",
          ),
          errorMessage: t("components:AIPrompts:captionsAIPrompts:introSection:variable2:error"),
        },
        {
          id: "platform",
          label: t("components:AIPrompts:captionsAIPrompts:introSection:variable3:label"),
          maxCharLimit: 50,
          enabled: initialValues?.platform_enabled || false,
          rowNumber: 1,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:introSection:variable3:placeholder",
          ),
          errorMessage: t("components:AIPrompts:captionsAIPrompts:introSection:variable3:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:captionsAIPrompts:toneOfVoiceSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:toneOfVoiceSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t(
              "components:AIPrompts:captionsAIPrompts:toneOfVoiceSection:settingSentenceVariable",
            )}
          />
          {t("components:AIPrompts:captionsAIPrompts:toneOfVoiceSection:settingSentence2")}
        </>
      ),
      fields: [
        {
          id: "voice_tone",
          label: t("components:AIPrompts:captionsAIPrompts:toneOfVoiceSection:variable:label"),
          maxCharLimit: 50,
          enabled: initialValues?.voice_tone_enabled || false,
          rowNumber: 4,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:toneOfVoiceSection:variable:placeholder",
          ),
          errorMessage: t(
            "components:AIPrompts:captionsAIPrompts:toneOfVoiceSection:variable:error",
          ),
        },
      ],
    },
    {
      title: t("components:AIPrompts:captionsAIPrompts:emojisSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:emojisSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:captionsAIPrompts:emojisSection:settingSentenceVariable")}
          />
          {t("components:AIPrompts:captionsAIPrompts:emojisSection:settingSentence2")}
        </>
      ),
      inlineToggle: {
        id: "emoji",
        enabled: initialValues ? initialValues.emoji_enabled : true,
      },
    },
    {
      title: t("components:AIPrompts:captionsAIPrompts:languageSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:languageSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t(
              "components:AIPrompts:captionsAIPrompts:languageSection:settingSentenceVariable",
            )}
          />
          {t("components:AIPrompts:captionsAIPrompts:languageSection:settingSentence2")}
        </>
      ),
      fields: [
        {
          id: "language",
          label: t("components:AIPrompts:captionsAIPrompts:languageSection:variable:label"),
          maxCharLimit: 50,
          enabled: initialValues?.language_enabled || false,
          rowNumber: 2,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:languageSection:variable:placeholder",
          ),
          errorMessage: t("components:AIPrompts:captionsAIPrompts:languageSection:variable:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:captionsAIPrompts:hashtagsSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:hashtagsSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t(
              "components:AIPrompts:captionsAIPrompts:hashtagsSection:settingSentenceVariable",
            )}
          />
        </>
      ),
      inlineToggle: {
        id: "hashtags",
        enabled: initialValues ? initialValues.hashtags_enabled : false,
      },
      fields: [
        {
          id: "customize_hashtags",
          label: t("components:AIPrompts:captionsAIPrompts:hashtagsSection:variable:label"),
          maxCharLimit: 50,
          enabled: !!initialValues?.hashtags?.length || false,
          rowNumber: 2,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:hashtagsSection:variable:placeholder",
          ),
          errorMessage: t("components:AIPrompts:captionsAIPrompts:hashtagsSection:variable:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:captionsAIPrompts:goalSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:goalSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:captionsAIPrompts:goalSection:settingSentenceVariable")}
          />
        </>
      ),
      fields: [
        {
          id: "goal",
          label: t("components:AIPrompts:captionsAIPrompts:goalSection:variable:label"),
          maxCharLimit: 50,
          enabled: initialValues?.goal_enabled || false,
          rowNumber: 2,
          placeholder: t("components:AIPrompts:captionsAIPrompts:goalSection:variable:placeholder"),
          errorMessage: t("components:AIPrompts:captionsAIPrompts:goalSection:variable:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:captionsAIPrompts:lengthSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:lengthSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:captionsAIPrompts:lengthSection:settingSentenceVariable")}
          />
        </>
      ),
      fields: [
        {
          id: "length",
          label: t("components:AIPrompts:captionsAIPrompts:lengthSection:variable:label"),
          maxCharLimit: 50,
          enabled: initialValues?.length_enabled || false,
          rowNumber: 2,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:lengthSection:variable:placeholder",
          ),
          errorMessage: t("components:AIPrompts:captionsAIPrompts:lengthSection:variable:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:captionsAIPrompts:additionalDetailsSection:title"),
      description: (
        <>
          {t("components:AIPrompts:captionsAIPrompts:additionalDetailsSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t(
              "components:AIPrompts:captionsAIPrompts:additionalDetailsSection:settingSentenceVariable",
            )}
          />
        </>
      ),
      fields: [
        {
          id: "additional_data",
          label: t(
            "components:AIPrompts:captionsAIPrompts:additionalDetailsSection:variable:label",
          ),
          maxCharLimit: 150,
          enabled: initialValues?.additional_data_enabled || false,
          rowNumber: 3,
          placeholder: t(
            "components:AIPrompts:captionsAIPrompts:additionalDetailsSection:variable:placeholder",
          ),
          errorMessage: t(
            "components:AIPrompts:captionsAIPrompts:additionalDetailsSection:variable:error",
          ),
        },
      ],
    },
  ];
};
