import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { Form } from "antd";
import { KeywordsFilter } from "@bbdevcrew/bb_ui_kit_fe";

import arraysAreEqual from "@utils/arraysAreEqual";

import s from "./../../WorkflowForm.module.less";

import { IKeywordTriggerProps } from "./KeywordTrigger.types";
import { IWorkflowKeywordsTrigger } from "@store/workflows/types";

export const KeywordTrigger: React.FC<IKeywordTriggerProps> = ({
  value,
  onChange,
  hasLabel = true,
  inputClassName,
  indicatorClassName,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [currentValue, setCurrentValue] = useState(value || []);

  const handleValuesChange = (changedValues: { keywords: IWorkflowKeywordsTrigger }) => {
    if (
      changedValues.keywords !== undefined &&
      !arraysAreEqual(currentValue, changedValues.keywords)
    ) {
      setCurrentValue(changedValues.keywords);
    }
  };

  useEffect(() => {
    const keywordField = form.getFieldValue("keywords");

    setCurrentValue(keywordField);
    // eslint-disable-next-line
  }, [form, form.getFieldValue("keywords")]);

  const portal = createPortal(
    <Form form={form} initialValues={{ keywords: value }} onValuesChange={handleValuesChange}>
      <Form.Item shouldUpdate>
        {() => {
          return (
            <Form.Item shouldUpdate>
              {() => {
                return <Form.Item name="keywords" />;
              }}
            </Form.Item>
          );
        }}
      </Form.Item>
    </Form>,
    document.createElement("div"),
  );

  const currentValueChanged = !arraysAreEqual(value || [], currentValue);

  useEffect(() => {
    if (currentValueChanged) {
      onChange?.(currentValue);
    }
  }, [currentValueChanged, currentValue, onChange]);

  return (
    <>
      {portal}
      <div className={s.bbWorkflowItemFormTrigger}>
        {hasLabel && <label>{t("pages:workflows:form:triggers:keywords:label")}</label>}
        <KeywordsFilter
          inputClassName={classNames(s.bbWorkflowItemFormKeywordSelect, inputClassName)}
          indicatorClassName={indicatorClassName}
          placeholder={t("pages:workflows:form:triggers:keywords:placeholder")}
          customprops={{
            form,
            name: "keywords",
          }}
        />
      </div>
    </>
  );
};
