import { RootState } from "..";

const getAlertsState = (state: RootState) => state.alerts;

export const getAlerts = (state: RootState) => getAlertsState(state).alerts;

export const getFetchingAlerts = (state: RootState) => getAlertsState(state).fetchingAlerts;

export const getFetchedAlerts = (state: RootState) => getAlertsState(state).fetchedAlerts;

export const getFetchAlertsFailed = (state: RootState) => getAlertsState(state).fetchAlertsFailed;

export const getUpdatingAlert = (state: RootState) => getAlertsState(state).updatingAlert;

export const getUpdateAlertFailed = (state: RootState) => getAlertsState(state).updateAlertFailed;

export const getUpdatingBulkAlerts = (state: RootState) => getAlertsState(state).updatingBulkAlerts;

export const getUpdateBulkAlertsFailed = (state: RootState) =>
  getAlertsState(state).updateBulkAlertsFailed;

export const getUpdateBulkAlertsSuccess = (state: RootState) =>
  getAlertsState(state).updateBulkAlertsSuccess;

export const getTotal = (state: RootState) => getAlertsState(state).total;
