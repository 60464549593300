import React from "react";
import { useSelector } from "react-redux";

import { BBPostTagWorkflowAction } from "./BBPostTagWorkflowAction";
import { CustomPostTagWorkflowAction } from "./CustomPostTagWorkflowAction";

import { meSelector } from "@store/me/selectors";

import { IFormComponent } from "@components/control-panel/workflows/types";

export const PostTagWorkflowAction: React.FC<IFormComponent<string[]>> = ({ value, onChange }) => {
  const me = useSelector(meSelector);

  const isBBUser = !!me?.is_bb_user;

  return isBBUser ? (
    <BBPostTagWorkflowAction value={value} onChange={onChange} />
  ) : (
    <CustomPostTagWorkflowAction value={value} onChange={onChange} />
  );
};
