import React from "react";

const nl2br = (text: string): React.ReactNode[] => {
  return text
    .split("\n")
    .map((line, i, arr) =>
      React.createElement(
        React.Fragment,
        { key: i },
        line,
        i < arr.length - 1 ? React.createElement("br") : null,
      ),
    );
};

export default nl2br;
