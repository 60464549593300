import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import s from "./Breadcrumbs.module.less";

import { getCurrentModuleName } from "@utils/paths";
import { IAvailableSectionsType } from "@store/modules/types";

interface IBreadcrumbsProps {
  title?: React.ReactNode;
  parentLabel?: string;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ parentLabel, title }) => {
  const [searchParams] = useSearchParams();

  const moduleId = getCurrentModuleName();

  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[moduleId as IAvailableSectionsType];

  const parent = useMemo(
    () =>
      sections?.find(section =>
        section.features?.find(feature => feature.id === searchParams.get("section")),
      ),
    [sections, searchParams],
  );

  const childLabel = useMemo(() => {
    const childFeature = parent?.features?.find(
      feature => feature.id === searchParams.get("section"),
    );
    return childFeature?.label;
  }, [searchParams, parent?.features]);

  return (
    <>
      {((!!sections?.length && parent) || (parentLabel && title)) && (
        <h5 className={s.bbBreadcrumbs} data-cy="breadcrumbs">
          <span className={s.bbBreadcrumbsParent}>{parentLabel || parent?.label}</span>
          <span className={s.bbBreadcrumbsChild}>{title || childLabel}</span>
        </h5>
      )}
    </>
  );
};
