import React, { FC } from "react";

import { IMultiAttachmentPreviewProps } from "./types";

import s from "./MultiAttachmentPreview.module.less";

import { PlayIcon } from "@bbdevcrew/bb_ui_kit_fe";

const MultiAttachmentPreview: FC<IMultiAttachmentPreviewProps> = ({
  attachment,
  playIconTopOffset,
  style,
}) => {
  const isImage = attachment.type === "photo";

  return (
    <div className={s.bbImageContainer} style={style?.imageContainerStyle}>
      <a className={s.bbImageLink}>
        <div className={s.bbImageFlows}>
          <div style={style?.wrapperStyle} className={s.bbWrapper}>
            <div className={s.bbRoot} style={style?.rootStyle}>
              {isImage ? (
                <img alt={attachment.id} src={attachment.url} className={s.bbImage} />
              ) : (
                <>
                  <video src={attachment.url} className={s.bbImage} />
                  <i
                    className={s.bbPlayButton}
                    style={playIconTopOffset ? { top: `${playIconTopOffset}px` } : {}}
                  >
                    <PlayIcon />
                  </i>
                </>
              )}
            </div>
          </div>
          <div className={s.bbBottomSupport}></div>
        </div>
      </a>
    </div>
  );
};

export default MultiAttachmentPreview;
