import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Menu } from "antd";
import UpgradePlanButton from "./UpgradePlanButton";
import TrialTimeLeftCircle from "./TrialTimeLeftCircle";
import { ExtraMenuItemDivider } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./AppLayoutContainer.module.less";

import { IExtraItem, daysFromNow } from "@bbdevcrew/bb_ui_kit_fe";
import { useTrialSubscription } from "@utils/useTrialSubscription";

interface IUpgradePlanCTAProps {
  item: IExtraItem;
  onSubscriptionModalOpen: () => void;
}

const UpgradePlanCTA: React.FC<IUpgradePlanCTAProps> = ({ item, onSubscriptionModalOpen }) => {
  const { t } = useTranslation();

  const isTrialSubscription = useTrialSubscription();

  const me = useSelector(meSelector);

  const trialDaysLeft = daysFromNow(new Date(me?.client?.subscription?.period_end || ""));

  return (
    <>
      {isTrialSubscription ? (
        <>
          <Menu.Item key={item.id} className={s[item.id]}>
            <div className={s.bbSubscriptionUpgrade}>
              <div className={s.bbSubscriptionUpgradeCircleWrapper}>
                <TrialTimeLeftCircle trialPeriodEnd={me?.client?.subscription?.period_end || ""} />
                <span className={s.bbSubscriptionUpgradeTrialEndsText}>
                  {t("header:trialEndsText", { count: trialDaysLeft })}
                </span>
              </div>
              <div className={s.bbSubscriptionUpgradeBtn}>
                <UpgradePlanButton onClick={onSubscriptionModalOpen} />
              </div>
            </div>
          </Menu.Item>
          <ExtraMenuItemDivider id={item.id} />
        </>
      ) : null}
    </>
  );
};

export default UpgradePlanCTA;
