import { useTranslation } from "react-i18next";
import React from "react";

import { Button, DropdownMenu, DropdownMenuItem } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AlertsTable.module.less";

import { IContextMenuProps } from "./AlertsTable.types";

import { MoreActionsIcon, EditIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ContextMenu: React.FC<IContextMenuProps> = ({ onEdit }) => {
  const { t } = useTranslation();

  return (
    <>
      <DropdownMenu
        trigger={() => (
          <Button _type="link" className={s.bbAlertsTableContextMenu}>
            <MoreActionsIcon />
          </Button>
        )}
      >
        {closeMenu => (
          <div className={s.bbAlertsTableContextMenuWrapper}>
            <DropdownMenuItem
              onClick={() => {
                onEdit();
                closeMenu();
              }}
            >
              <EditIcon />
              {t("generic:edit")}
            </DropdownMenuItem>
          </div>
        )}
      </DropdownMenu>
    </>
  );
};
