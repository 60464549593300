import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AdIcon, Button, Modal, PostIcon, RepeatIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { TopPostTagsActionCard } from "./TopPostTagsActionCard";
import WorkflowCreationModal from "@components/control-panel/workflows/creationModal/WorkflowCreationModal"; // eslint-disable-line max-len
import { PostCreationModal } from "@components/publish/postCreation/PostCreationModal";
import TagModal from "@components/control-panel/tagsManagement/TagModal";

import { meSelector } from "@store/me/selectors";

import s from "./TopPostTags.module.less";

import { useWorkflowModal } from "@utils/useWorkflowModal";
import useTagModal from "@utils/useTagModal";
import CalendarContextWrapper from "@components/publish/calendar/context/ContextWrapper";
import CalendarContext from "@components/publish/calendar/context/CalendarContext";

import { ChevronUpIcon, CalendarIcon, PlusIcon, WorkflowIcon } from "@bbdevcrew/bb_ui_kit_fe";
import IllustrationSearch from "@assets/IllustrationSearch.svg";

const CreatePostActionCard = () => {
  const { setIsPostModalOpen } = useContext(CalendarContext);
  const { t } = useTranslation();

  return (
    <TopPostTagsActionCard
      icon={<CalendarIcon />}
      title={t("components:topPostTags:empty:noData:actionCards:tagWhenPublishing:title")}
      description={t(
        "components:topPostTags:empty:noData:actionCards:tagWhenPublishing:description",
      )}
      buttonIcon={<CalendarIcon />}
      buttonText={t("components:topPostTags:empty:noData:actionCards:tagWhenPublishing:buttonText")}
      buttonStyle="link"
      onClick={() => {
        setIsPostModalOpen(true);
      }}
    />
  );
};

export const TopPostTagsEmpty: React.FC<{ variant: "noTags" | "noData" }> = ({ variant }) => {
  const { t } = useTranslation();
  const { workflowModalOpen, selectedWorkflow, openCreateModal, closeModal } = useWorkflowModal();
  const {
    isModalOpen,
    editingTag,
    fetchingTag,
    tagsList,
    isBBTagsTab,
    openCreateModal: openTagModal,
    closeModal: closeTagModal,
    handleSubmit,
    getModalTitle,
  } = useTagModal();

  const me = useSelector(meSelector);

  return (
    <div className={s.bbTopPostTagsEmpty}>
      <IllustrationSearch />
      <h2 className={s.bbTopPostTagsEmptyTitle}>
        {t(`components:topPostTags:empty:${variant}:title`)}
      </h2>
      <p className={s.bbTopPostTagsEmptyDescription}>
        {t(`components:topPostTags:empty:${variant}:description`)}
      </p>
      {variant === "noTags" && (
        <>
          <div className={s.bbTopPostTagsEmptyCta}>
            <Button
              _type="primary"
              _size="sm"
              className={s.bbTopPostTagsEmptyCtaButton}
              onClick={openTagModal}
            >
              <PlusIcon /> {t(`components:topPostTags:empty:${variant}:createPostTag`)}
            </Button>
          </div>
          <TagModal
            isOpen={isModalOpen}
            isSubmitting={fetchingTag}
            editingTag={editingTag}
            onCloseModal={closeTagModal}
            onSubmit={handleSubmit}
            title={getModalTitle()}
            tagsList={tagsList}
            isSubTag={isBBTagsTab}
          />
        </>
      )}
      <div className={s.bbTopPostTagsEmptySubtitle}>
        {t(`components:topPostTags:empty:${variant}:subtitle`)}
      </div>

      <div className={s.bbTopPostTagsEmptyActionCards}>
        {variant === "noData" ? (
          <>
            <TopPostTagsActionCard
              icon={<PlusIcon />}
              title={t("components:topPostTags:empty:noData:actionCards:manuallyAddTags:title")}
              description={t(
                "components:topPostTags:empty:noData:actionCards:manuallyAddTags:description",
              )}
              buttonIcon={<ChevronUpIcon />}
              buttonText={t(
                "components:topPostTags:empty:noData:actionCards:manuallyAddTags:buttonText",
              )}
              buttonStyle="link"
              onClick={() => {
                document.getElementById("layout-content")?.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <TopPostTagsActionCard
              icon={<WorkflowIcon />}
              title={t("components:topPostTags:empty:noData:actionCards:automateTagging:title")}
              description={t(
                "components:topPostTags:empty:noData:actionCards:automateTagging:description",
              )}
              buttonIcon={<PlusIcon />}
              buttonText={t(
                "components:topPostTags:empty:noData:actionCards:automateTagging:buttonText",
              )}
              buttonStyle="primary"
              onClick={openCreateModal}
            />
            {me?.permissions?.includes("create_publishing") && (
              <CalendarContextWrapper>
                <CreatePostActionCard />
                <PostCreationModal />
              </CalendarContextWrapper>
            )}
            <Modal
              open={workflowModalOpen}
              onCancel={closeModal}
              width={800}
              centered
              noPadding
              responsive
              hideFooter
              hideHeader
            >
              <WorkflowCreationModal item={selectedWorkflow} workflowType="post_tag" />
            </Modal>
          </>
        ) : (
          <>
            <TopPostTagsActionCard
              icon={<AdIcon />}
              title={t("components:topPostTags:empty:noTags:actionCards:autotagCampaigns:title")}
              description={t(
                "components:topPostTags:empty:noTags:actionCards:autotagCampaigns:description",
              )}
            />

            <TopPostTagsActionCard
              icon={<RepeatIcon />}
              title={t("components:topPostTags:empty:noTags:actionCards:compareStrategies:title")}
              description={t(
                "components:topPostTags:empty:noTags:actionCards:compareStrategies:description",
              )}
            />

            <TopPostTagsActionCard
              icon={<PostIcon />}
              title={t("components:topPostTags:empty:noTags:actionCards:organizeByPillars:title")}
              description={t(
                "components:topPostTags:empty:noTags:actionCards:organizeByPillars:description",
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};
