import React from "react";
import classNames from "classnames";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AddTagButton.module.less";

import { IAddTagButtonProps } from "./AddTagButton.types";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const AddTagButton: React.FC<IAddTagButtonProps> = ({ label, className, ...restProps }) => (
  <Button
    className={classNames(s.bbAddTagButton, className)}
    _size="sm"
    type="button"
    {...restProps}
  >
    <PlusIcon /> {label}
  </Button>
);
