import React from "react";
import classNames from "classnames";

import s from "../CommentCard/commentActions/CommentActions.module.less";

import { TFunction } from "i18next";
import { PostMediaType, IObjectOption, MediaTypeIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const getSentimentOptions = (t: TFunction): IObjectOption[] => [
  {
    id: "positive",
    value: "positive",
    label: t("components:comments:actions:sentiments:positive"),
    iconPosition: "left",
    icon: (
      <span className={classNames(s.bbActionCircleClass, s[`bbActionCircleClass-positive`])}>
        {" "}
      </span>
    ),
  },
  {
    id: "negative",
    value: "negative",
    label: t("components:comments:actions:sentiments:negative"),
    iconPosition: "left",
    icon: (
      <span className={classNames(s.bbActionCircleClass, s[`bbActionCircleClass-negative`])}>
        {" "}
      </span>
    ),
  },
  {
    id: "neutral",
    value: "neutral",
    label: t("components:comments:actions:sentiments:neutral"),
    iconPosition: "left",
    icon: (
      <span className={classNames(s.bbActionCircleClass, s[`bbActionCircleClass-neutral`])}> </span>
    ),
  },
];

export const getMediaTypeIcon = (post_media_id: PostMediaType) => {
  const Icon = MediaTypeIcon[post_media_id as keyof typeof MediaTypeIcon];

  if (!Icon) return null;
  return <Icon />;
};
