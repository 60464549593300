import { TFunction } from "i18next";

export const dateList = (t: TFunction, isYTSelected: boolean) => [
  {
    value: "current_day",
    label: t("components:filters:comments:items:dataRangeOptions:current_day"),
  },
  {
    value: "yesterday",
    label: t("components:filters:comments:items:dataRangeOptions:yesterday"),
  },
  {
    value: "last_7_days",
    label: t("components:filters:comments:items:dataRangeOptions:last_7_days"),
  },
  {
    value: "last_30_days",
    label: t("components:filters:comments:items:dataRangeOptions:last_30_days"),
  },
  {
    value: "last_12_months",
    label: t("components:filters:comments:items:dataRangeOptions:last_12_months"),
    disabled: isYTSelected,
    tooltip: isYTSelected ? t("components:filters:dates:ytNoAnalysis") : "",
  },
  {
    value: "all_time",
    label: t("components:filters:comments:items:dataRangeOptions:all_time"),
    disabled: isYTSelected,
    tooltip: isYTSelected ? t("components:filters:dates:ytNoAnalysis") : "",
  },
  {
    value: "custom",
    label: t("components:filters:comments:items:dataRangeOptions:custom"),
  },
];
