import React, { FC } from "react";
import cn from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Help } from "@bbdevcrew/bb_ui_kit_fe";
import PostCard from "../../../insights/_common/postCard/PostCard";

import s from "./LinkPreview.module.less";
import postStyles from "../index.module.less";

import { LINK_IMAGE_FRAME_HEIGHT, LINK_IMAGE_FRAME_WIDTH } from "../helpers/constants";
import { ILinkPreviewProps } from "./types";
import { extractDomain } from "../helpers/utilsGeneric";

const cx = cn.bind(s);

const LinkPreview: FC<ILinkPreviewProps> = ({
  post,
  linkMetaData,
  maxMessageLength,
  footer,
  className,
  postTypeText,
  pageImageShape,
}) => {
  const { t } = useTranslation();

  const LinkMetaDataImage = (
    <div className={s.bbImagePreviewWrapper}>
      {!!linkMetaData.image && (
        <div
          style={{
            minWidth: LINK_IMAGE_FRAME_WIDTH,
            minHeight: LINK_IMAGE_FRAME_HEIGHT,
          }}
          className={s.bbImgWrapper}
        >
          <img src={linkMetaData.image} alt={linkMetaData.title} />
        </div>
      )}
    </div>
  );

  const renderCardFooter = () => (
    <>
      <div className={s.bbLinkPreviewMeta}>
        <div className={s.bbLinkPreviewMetaUrl}>
          {extractDomain(linkMetaData.url) || linkMetaData.url}
        </div>
        <div className={s.bbLinkPreviewMetaTitle}>{linkMetaData.title}</div>
        <div className={s.bbLinkPreviewMetaDescription}>{linkMetaData.description}</div>
      </div>
      <div className={s.bbLinkPreviewFooter}>{footer}</div>
    </>
  );

  if (!linkMetaData) return null;

  return (
    <>
      <div className={postStyles.bbPostCreationPreviewTitle}>
        {t("components:publish:postCreationModal:preview:title", {
          platform: t(`generic:platforms:${post.asset.platform}`),
        })}
        <Help
          iconType="info"
          iconClassName={postStyles.bbPostPreviewInfo}
          tooltipProps={{
            title: t("components:publish:postCreationModal:preview:tooltip"),
          }}
        />
      </div>
      <div className={s.bbLinkPreviewWrapper}>
        <div className={cx(s.bbLinkPreview, className)}>
          <PostCard
            showMoreText
            message={post.message}
            maxMessageLength={maxMessageLength}
            postTypeText={postTypeText || t("generic:now")}
            className={s.bbPostPreviewCard}
            renderCardFooter={renderCardFooter}
            pageImageProps={{
              pageName: post.asset.label,
              pageImage: post.asset.picture,
              pageImageShape: pageImageShape,
            }}
            postImageProps={{
              customImageRender: LinkMetaDataImage,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LinkPreview;
