import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { CommentsGridContainer } from "@containers/Comments/Comments";

import s from "./Protect.module.less";

import { IProtectCommentsProps } from "../Transparency.types";
import { COMMENTS_LIMIT } from "../Transparency.helpers";
import { postCommentsSearchAction } from "@store/comments/actions";
import { IGetInboxRepliesPayload, IPostCommentBody } from "@store/comments/types";

export const ProtectComments = ({ filters, filterToSpecificItem }: IProtectCommentsProps) => {
  const { t } = useTranslation();

  const fetchConversationList = useCallback((body: IPostCommentBody | IGetInboxRepliesPayload) => {
    return postCommentsSearchAction({
      ...body,
      conversation_list_id: "managed-hidden",
      limit: COMMENTS_LIMIT,
      sort: "desc",
    });
  }, []);

  return (
    <>
      <div className={s.bbProtectCommentsHeader}>
        <h5>{t("components:managed:protect:comments:title")}</h5>
        {/*
        Temporarily hide the "View All" button until the new filter is complete.

        <Button onClick={handleViewAllClick} className={s.bbProtectCommentsHeaderButton}>
          <span>{t("components:managed:protect:comments:viewAll")}</span>
          <ArrowUpIcon />
        </Button> */}
      </div>
      <div className={s.bbProtectCommentsWrapper}>
        <CommentsGridContainer
          showStarAction
          showHideAction
          showDeleteAction
          showLikeAction
          showDetailsAction
          showLinkedInReactionAction
          showArchiveAction
          filters={filters}
          loadMoreOnScroll={false}
          providedFetchAction={fetchConversationList}
          listId="managed-hidden"
          providedFilterAuthorIdAction={authorIds =>
            filterToSpecificItem({ author_ids: authorIds })
          }
        />
      </div>
    </>
  );
};
