import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button, IButtonProps } from "@bbdevcrew/bb_ui_kit_fe";

import { isTiktokApp } from "@utils/isTiktokApp";

import s from "./AppLayoutContainer.module.less";

import { ArrowUpIcon } from "@assets/index";

const UpgradePlanButton = (props: IButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      _size="sm"
      _type="secondary"
      className={classNames(s.bbUpgradePlanButton, {
        [s.bbUpgradePlanButtonTT]: isTiktokApp(),
      })}
    >
      <span className={s.bbUpgradePlanButtonIcon}>
        <ArrowUpIcon />
      </span>
      {isTiktokApp() ? t("header:upgradeBtn:short") : t("header:upgradeBtn:default")}
    </Button>
  );
};

export default UpgradePlanButton;
