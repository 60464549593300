import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import ManagedSummary from "@components/managed/Transparency/summary";
import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import {
  getDashboardManagedSummarySelector,
  getDashboardSelectorPending,
} from "@store/dashboard/selectors";
import { meSelector } from "@store/me/selectors";
import { workflowCategoriesSelector } from "@store/workflows/selectors";

import s from "./Overview.module.less";

import { getShowActionCard, getSummaryCardTooltips } from "./Overview.helpers";

import { CheckCircleFilledIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const OverviewSummary: React.FC = () => {
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const dashboardSummaryData = useSelector(getDashboardManagedSummarySelector);
  const dashboardLoading = useSelector(getDashboardSelectorPending);
  const me = useSelector(meSelector);
  const workflowCategories = useSelector(workflowCategoriesSelector);

  const hasEssentialPlan = me?.client?.client_plan?.id === "ESSENTIAL";
  const isNotManagedClient =
    hasEssentialPlan && !me?.client?.is_care_managed && !me?.client?.is_moderation_managed;

  const setActiveSection = (id: string) => () => {
    setSearchParams(
      {
        ...Object.fromEntries(new URL(window.location.href).searchParams),
        section: id,
      },
      { replace: true },
    );
  };

  const onClickCardAction = (id: string) => {
    if (id === "hide" && isNotManagedClient) {
      window.location.href = "/care";
    } else {
      setActiveSection("control_panel-workflows")();
    }
  };

  return (
    <div className={s.bbOverviewSummary}>
      <div className={s.bbOverviewSummaryTitle}>
        <CheckCircleFilledIcon />
        <span>
          {t("components:controlPanel:overview:summary:title", { name: me?.client?.name })}
        </span>
      </div>
      <ManagedSummary
        data={dashboardSummaryData}
        isLoading={dashboardLoading}
        skeletonCount={6}
        cardTooltips={getSummaryCardTooltips(hasEssentialPlan, t)}
        action={item =>
          getShowActionCard(item, workflowCategories, t, onClickCardAction, isNotManagedClient)
        }
      />

      <Button
        _type="secondary"
        _size="sm"
        className={s.bbOverviewSummaryAction}
        disabled={hasEssentialPlan}
        onClick={setActiveSection("brandbastion_actions-transparency_dashboard")}
      >
        <Tooltip
          title={
            hasEssentialPlan ? t("components:controlPanel:overview:summary:buttonTooltip") : ""
          }
          showArrow={false}
        >
          {t("components:controlPanel:overview:summary:button")}
          <ChevronRightIcon />
        </Tooltip>
      </Button>
    </div>
  );
};
