import { action } from "typesafe-actions";

import {
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAILURE,
  CREATE_WORKFLOW,
  CREATE_WORKFLOW_SUCCESS,
  CREATE_WORKFLOW_FAILURE,
  EDIT_WORKFLOW,
  EDIT_WORKFLOW_SUCCESS,
  EDIT_WORKFLOW_FAILURE,
  DELETE_WORKFLOW,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  ENABLE_WORKFLOW,
  ENABLE_WORKFLOW_SUCCESS,
  ENABLE_WORKFLOW_FAILURE,
  DISABLE_WORKFLOW,
  DISABLE_WORKFLOW_SUCCESS,
  DISABLE_WORKFLOW_FAILURE,
  SET_WORKFLOW_FORM_DIRTY,
  RESET_WORKFLOWS,
  GET_WORKFLOWS_USER_GROUPS,
  GET_WORKFLOWS_USER_GROUPS_SUCCESS,
  GET_WORKFLOWS_USER_GROUPS_FAILURE,
  GET_WORKFLOWS_SAVED_REPLIES,
  GET_WORKFLOWS_SAVED_REPLIES_SUCCESS,
  GET_WORKFLOWS_SAVED_REPLIES_FAILURE,
  CLEAR_MATCHING_COMMENTS,
  GET_MATCHING_COMMENTS_FAILURE,
  GET_MATCHING_COMMENTS_SUCCESS,
  GET_MATCHING_COMMENTS,
} from "./actionTypes";
import {
  IGetWorkflowsResponseMapped,
  IWorkflowItemPayload,
  IWorkflowSavedReplyPayload,
  IWorkflowUserGroup,
} from "./types";
import { ISavedReply } from "@store/replies/types";
import { IComment } from "@bbdevcrew/bb_ui_kit_fe";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export const getWorkflowsAction = () => action(GET_WORKFLOWS);
export const getWorkflowsSuccessAction = (data: IGetWorkflowsResponseMapped) =>
  action(GET_WORKFLOWS_SUCCESS, data);
export const getWorkflowsFailureAction = (error: string) => action(GET_WORKFLOWS_FAILURE, error);

export const createWorkflowAction = (workflow: IWorkflowItemPayload) =>
  action(CREATE_WORKFLOW, workflow);
export const createWorkflowSuccessAction = (workflow: IWorkflowItemPayload) =>
  action(CREATE_WORKFLOW_SUCCESS, workflow);
export const createWorkflowFailureAction = () => action(CREATE_WORKFLOW_FAILURE);

export const editWorkflowAction = (workflow: IWorkflowItemPayload) =>
  action(EDIT_WORKFLOW, workflow);
export const editWorkflowSuccessAction = () => action(EDIT_WORKFLOW_SUCCESS);
export const editWorkflowFailureAction = () => action(EDIT_WORKFLOW_FAILURE);

export const deleteWorkflowAction = (id: string) => action(DELETE_WORKFLOW, id);
export const deleteWorkflowSuccessAction = () => action(DELETE_WORKFLOW_SUCCESS);
export const deleteWorkflowFailureAction = () => action(DELETE_WORKFLOW_FAILURE);

export const enableWorkflowAction = (id: string) => action(ENABLE_WORKFLOW, id);
export const enableWorkflowSuccessAction = () => action(ENABLE_WORKFLOW_SUCCESS);
export const enableWorkflowFailureAction = (id: string) => action(ENABLE_WORKFLOW_FAILURE, id);

export const disableWorkflowAction = (id: string) => action(DISABLE_WORKFLOW, id);
export const disableWorkflowSuccessAction = () => action(DISABLE_WORKFLOW_SUCCESS);
export const disableWorkflowFailureAction = (id: string) => action(DISABLE_WORKFLOW_FAILURE, id);

export const setWorkflowFormDirty = (dirty: boolean) => action(SET_WORKFLOW_FORM_DIRTY, dirty);

export const resetWorkflowsAction = () => action(RESET_WORKFLOWS);

export const getWorkflowsUserGroupsAction = () => action(GET_WORKFLOWS_USER_GROUPS);
export const getWorkflowsUserGroupsSuccessAction = (data: IWorkflowUserGroup[]) =>
  action(GET_WORKFLOWS_USER_GROUPS_SUCCESS, data);
export const getWorkflowsUserGroupsFailureAction = (error: string) =>
  action(GET_WORKFLOWS_USER_GROUPS_FAILURE, error);

export const getWorkflowsSavedRepliesAction = (payload: IWorkflowSavedReplyPayload) =>
  action(GET_WORKFLOWS_SAVED_REPLIES, payload);
export const getWorkflowsSavedRepliesSuccessAction = (data: ISavedReply[]) =>
  action(GET_WORKFLOWS_SAVED_REPLIES_SUCCESS, data);
export const getWorkflowsSavedRepliesFailureAction = (error: string) =>
  action(GET_WORKFLOWS_SAVED_REPLIES_FAILURE, error);

export const getMatchingCommentsAction = (payload: IFilters) =>
  action(GET_MATCHING_COMMENTS, payload);
export const getMatchingCommentsSuccessAction = (comments: IComment[], total_count: number) =>
  action(GET_MATCHING_COMMENTS_SUCCESS, { comments, total_count });
export const getMatchingCommentsFailureAction = (error: string) =>
  action(GET_MATCHING_COMMENTS_FAILURE, error);
export const clearMatchingCommentsAction = () => action(CLEAR_MATCHING_COMMENTS);
