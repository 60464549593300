import React from "react";
import classNames from "classnames";

import s from "./ExpansionPanel.module.less";

import { IExpansionPanelBadgeProps } from "./ExpansionPanel.types";

export const ExpansionPanelBadge: React.FC<IExpansionPanelBadgeProps> = ({ children, danger }) => {
  return (
    <span
      className={classNames(s.bbExpansionPanelBadge, {
        [s.bbExpansionPanelBadgeDanger]: danger,
      })}
    >
      {children}
    </span>
  );
};
