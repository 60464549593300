import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { Grid } from "antd";
import { addToast, ArchiveIcon, Button, Table, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import Breadcrumbs from "@components/_common/breadcrumbs";
import AlertsTableRow from "./AlertsTableRow";
import EmptyState from "./EmptyState";
import WorkflowCreationModal from "@components/control-panel/workflows/creationModal/WorkflowCreationModal"; // eslint-disable-line max-len
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";

import { fetchAlerts, updateBulkAlerts } from "@store/alerts/actions";
import {
  getAlerts,
  getFetchedAlerts,
  getFetchingAlerts,
  getTotal,
  getUpdatingBulkAlerts,
} from "@store/alerts/selectors";
import { getWorkflowsAction } from "@store/workflows/actions";
import { resetWorkflowsAction } from "@store/workflows/actions";
import { workflowEditedSelector } from "@store/workflows/selectors";

import s from "./AlertsTable.module.less";

import { defaultSort, getTableColumns, TABLE_PAGE_SIZE } from "./AlertsTable.helpers";
import { useCurrentFilters } from "@utils/useCurrentFilters";
import { WORKFLOW_TOASTS_DURATION } from "@components/control-panel/workflows/WorkflowsContent";
import { PAGE_SIZE_OPTIONS } from "../posts/postsTable/helpers";
import { useWorkflowModal } from "@utils/useWorkflowModal";

import { ITableRow, ITableColumn, SortType } from "@bbdevcrew/bb_ui_kit_fe";
import { IAlert, ISort } from "@store/alerts/types";

export const AlertsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useCurrentFilters();
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;

  const [pagination, setPagination] = useState({ size: TABLE_PAGE_SIZE, page: 1 });
  const [sort, setSort] = useState<ISort>(defaultSort);

  const { workflowModalOpen, selectedWorkflow, openEditModal, closeModal } = useWorkflowModal();

  const alerts = useSelector(getAlerts);
  const loading = useSelector(getFetchingAlerts);
  const fetched = useSelector(getFetchedAlerts);
  const total = useSelector(getTotal);
  const updatingBulkAlerts = useSelector(getUpdatingBulkAlerts);
  const workflowEdited = useSelector(workflowEditedSelector);

  const archiveAllAlerts = useCallback(() => {
    dispatch(
      updateBulkAlerts(
        alerts.map(alert => alert.id),
        "archived",
      ),
    );
  }, [dispatch, alerts]);

  useEffect(() => {
    if (filters) {
      const {
        data_range_option,
        start_time,
        end_time,
        asset_ids,
        platform_types,
        post_types,
        post_ids,
        post_tags,
      } = filters;

      dispatch(
        fetchAlerts({
          filters: {
            data_range_option,
            start_time,
            end_time,
            asset_ids,
            platform_types,
            post_types,
            post_ids,
            post_tags,
          },
          pagination,
          sort,
        }),
      );
    }
  }, [pagination, sort, filters, dispatch]);

  useEffect(() => {
    dispatch(getWorkflowsAction());

    return () => {
      dispatch(resetWorkflowsAction());
    };
  }, [dispatch, workflowEdited]);

  useEffect(() => {
    if (workflowEdited) {
      addToast({
        type: "success_accent",
        title: t("pages:workflows:workflowCreationModal:editSuccess"),
        duration: WORKFLOW_TOASTS_DURATION,
      });
    }
  }, [workflowEdited, t]);

  const TableRow = useCallback(
    (props: ITableRow<IAlert>) => {
      return <AlertsTableRow {...props} index={props.index} onEdit={openEditModal} />;
    },
    [openEditModal],
  );

  const columns = useMemo(() => getTableColumns(isMobile), [isMobile]);

  const onSort = useCallback((column: ITableColumn, order?: SortType) => {
    setSort(
      order
        ? {
            field: column.id_name,
            order,
          }
        : defaultSort,
    );
  }, []);

  return (
    <>
      <div className={s.bbAlertsTable}>
        <div
          className={classNames(s.bbAlertsTableHeader, {
            [s.bbAlertsTableHeaderMobile]: isMobile,
          })}
        >
          <Breadcrumbs title={t("components:listen:alerts:title")} />
          {!isMobile && (
            <Button
              _size="sm"
              _type="on-surface"
              onClick={archiveAllAlerts}
              className={s.bbArchiveButton}
              loading={updatingBulkAlerts}
            >
              <ArchiveIcon />
              {t("generic:archiveAll")}
            </Button>
          )}
          {isMobile && <MobileFiltersButton />}
        </div>

        {fetched && alerts.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <p className={s.bbAlertsTableDescription}>
              {t("components:listen:alerts:description")}
            </p>
            <Table<IAlert>
              data={alerts}
              onSort={onSort}
              loading={loading}
              tableRow={TableRow}
              columns={columns}
              pagination={{
                total: total,
                pageSize: pagination.size,
                currentPage: pagination.page,
                onChangePage: (page: number, pageSize?: number) => {
                  setPagination({
                    size: pageSize || TABLE_PAGE_SIZE,
                    page,
                  });
                },
                showSizeChanger: true,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                pageSizeTitle: !isMobile ? t("components:sortableTable:pageSizeTitle") : undefined,
              }}
              emptyTableText={t("generic:emptyTable")}
              pageOutOfText={[
                t("components:sortableTable:pageOutOfPart1"),
                t("components:sortableTable:pageOutOfPart2"),
              ]}
            />
          </>
        )}
      </div>
      <Modal
        open={workflowModalOpen}
        onCancel={closeModal}
        width={800}
        centered
        noPadding
        responsive
        hideFooter
        hideHeader
      >
        <WorkflowCreationModal item={selectedWorkflow} />
      </Modal>
    </>
  );
};
