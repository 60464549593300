import imageStyles from "./imageDimensions";
import { IAttachment } from "./types";

// This will assign the paddingTop of Post Image Window
export const assignPageTopPadding = (numberOfImages: number) => {
  if (numberOfImages === 2) return 50;
  return 70;
};

export const assignStyleToImages = (images: IAttachment[]) => {
  let selectedStyle;
  const numberOfImages = images.length;

  if (numberOfImages >= 2 && numberOfImages <= 4 && imageStyles[numberOfImages - 2]) {
    const styleAssignedImages = [];

    selectedStyle = imageStyles[numberOfImages - 2];

    for (let i = 0; i < images.length; i++) {
      const styleAssigned = { image: images[i], styles: selectedStyle[i] };
      styleAssignedImages.push(styleAssigned);
    }

    return styleAssignedImages;
  }

  return null;
};

export const getPlayIconTopOffset = (totalNumberOfAttachments: number, index: number) =>
  (() => {
    switch (totalNumberOfAttachments) {
      case 1:
      case 2:
        return 105;
      case 3:
        return [148, 74, 74][index];
      case 4:
        return 74;
      default:
        return 0;
    }
  })() - 27;
