import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { filterDataSelector, filterDataWithoutUrlSelector } from "@store/filters/selectors";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export const useCurrentFilters = () => {
  const [filters, setFilters] = useState<IFilters | undefined>(undefined);
  const filtersData = useSelector(filterDataSelector);
  const filterDataWithoutUrl = useSelector(filterDataWithoutUrlSelector);

  useEffect(() => {
    if (filterDataWithoutUrl) {
      setFilters(filterDataWithoutUrl);
    }
  }, [filterDataWithoutUrl, setFilters]);

  useEffect(() => {
    if (filtersData) {
      setFilters(filtersData);
    }
  }, [filtersData, setFilters]);

  return filters;
};
