import dayjs from "dayjs";
import { useContext, useMemo } from "react";

import { IPublishingPost } from "@store/publishings/types";
import CalendarContext from "../../calendar/context/CalendarContext";

const getDatePickerDefaultValue = (
  isPostModalVisible: boolean,
  editingPost: IPublishingPost | null,
  daySelected?: dayjs.Dayjs,
) => {
  if (isPostModalVisible && !!editingPost) {
    return dayjs.unix(editingPost.created_time);
  }

  if (daySelected) {
    const momentDay = dayjs(daySelected.format());

    const isToday = dayjs().date() === momentDay.date();
    const preferablePostHour = 11;

    return isToday && dayjs().hour() >= preferablePostHour
      ? dayjs().add(2, "hours")
      : momentDay.set("hour", preferablePostHour);
  }

  return undefined;
};

export const useDatePickerDefaultValue = () => {
  const { editingPost, isPostModalOpen, daySelected } = useContext(CalendarContext);

  const datePickerDefaultValue = useMemo(
    () => getDatePickerDefaultValue(isPostModalOpen, editingPost, daySelected),
    [isPostModalOpen, editingPost, daySelected],
  );

  return datePickerDefaultValue;
};
