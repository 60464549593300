/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { IPostItem } from "../helpers/types";
import { IAutocompleteAsset } from "@bbdevcrew/bb_ui_kit_fe";

interface IPostCreationContext {
  posts: IPostItem[];
  setPosts: (posts: IPostItem[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  isSynced: boolean;
  setIsSynced: (isSynced: boolean) => void;

  activeAssetTab?: IAutocompleteAsset;
  setActiveAssetTab: (asset?: IAutocompleteAsset) => void;
  selectedAssets: IAutocompleteAsset[];
  setSelectedAssets: (assets: IAutocompleteAsset[]) => void;
  allAssets: IAutocompleteAsset[];
  setAllAssets: (assets: IAutocompleteAsset[]) => void;

  activePostScheduleTab: string;
  setActivePostScheduleTab: (tab: string) => void;
  isScheduled: boolean;
  setIsScheduled: (isScheduled: boolean) => void;
}

const PostCreationContext = React.createContext<IPostCreationContext>({
  activeAssetTab: undefined,
  setActiveAssetTab: () => {},
  selectedAssets: [],
  setSelectedAssets: () => {},
  allAssets: [],
  setAllAssets: () => {},
  activePostScheduleTab: "",
  setActivePostScheduleTab: () => {},
  isScheduled: false,
  setIsScheduled: () => {},
  posts: [],
  setPosts: () => {},
  loading: false,
  setLoading: () => {},
  isSynced: false,
  setIsSynced: () => {},
});

export default PostCreationContext;
