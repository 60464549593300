import { IYouTubeOptions } from "@bbdevcrew/bb_ui_kit_fe";

export const YOUTUBE_OPTIONS_DEFAULT_COUNTRY_CODE = "US";
export const YOUTUBE_PRIVACY_STATUSSES = ["public", "private", "unlisted"];
export const YOUTUBE_LICENSES = ["youtube", "creativeCommon"];

export const defaultYouTubeOptions: Partial<IYouTubeOptions> = {
  license: "youtube",
  embeddable: true,
  notify_subscribers: true,
};

export const YOUTUBE_VALIDATION_MAX_TITLE = 100;
