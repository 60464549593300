import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./topPostTagsChart/TopPostTagsChart.module.less";

import { maxTooltipPreviews } from "./TopPostTags.helpers";
import { ITopPostTagsTooltipProps } from "./TopPostTags.types";

const TopPostTagsTooltip: FC<ITopPostTagsTooltipProps> = ({ active, label, data }) => {
  const { t } = useTranslation();

  if (!active) return null;

  const tagItem = data.find(item => item.label === label);
  if (!tagItem) return null;

  const postCount = tagItem?.posts?.length || 0;
  const filteredPosts = tagItem.posts?.filter(post => post.image_url) || [];
  const visiblePosts = filteredPosts.slice(0, maxTooltipPreviews);
  const remainingCount = postCount > maxTooltipPreviews ? postCount - maxTooltipPreviews : 0;

  return (
    <div className={s.bbTopPostTagsChartTooltip}>
      {filteredPosts.length > 0 && (
        <div className={s.bbTopPostTagsChartTooltipImages}>
          {visiblePosts.map(post => (
            <div key={post.post_id} className={s.bbTopPostTagsChartTooltipImageWrapper}>
              <img
                src={post.image_url}
                alt={post.post_id}
                className={s.bbTopPostTagsChartTooltipImage}
              />
            </div>
          ))}
          {remainingCount > 0 && (
            <div className={s.bbTopPostTagsChartTooltipMoreCount}>+{remainingCount}</div>
          )}
        </div>
      )}
      <div className={s.bbTopPostTagsChartTooltipTitle}>
        {t("components:topPostTags:clickToFilter")}
      </div>
    </div>
  );
};

export default TopPostTagsTooltip;
