import React, { ChangeEvent, Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  InputWhite,
  ItemCard,
  Loading,
} from "@bbdevcrew/bb_ui_kit_fe";
import TabTitle from "./TabTitle";

import s from "./TagsManagement.module.less";

import { BRANDBASTION_TAG_COLORS, DEFAULT_TAG_COLOR } from "./TagsManagement.helpers";
import { ITag } from "@store/tagsManagement/types";
import { ITagsListProps } from "./TagsManagement.types";

import EmptyList from "@assets/EmptyList.svg";
import { PlusIcon, SearchIcon } from "@bbdevcrew/bb_ui_kit_fe";

const TagsList = ({
  isLoading,
  tags,
  onAddClick,
  onEditClick,
  onDeleteConfirm,
  title,
  isBBTagsTab,
}: ITagsListProps) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [collapsedItems, setCollapsedItems] = useState<Record<string, boolean>>({});

  const isAnyExpanded = useMemo(
    () => tags.some(tag => !collapsedItems[tag.key]),
    [collapsedItems, tags],
  );

  const handleToggleCollapse = (tagKey: string) => {
    setCollapsedItems(prevState => ({
      ...prevState,
      [tagKey]: !prevState[tagKey],
    }));
  };

  const handleToggleAll = () => {
    const updatedItems = tags.reduce(
      (acc, tag) => {
        acc[tag.key] = isAnyExpanded;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    setCollapsedItems(updatedItems);
  };

  const getTagColor = (tag: ITag) => {
    if (isBBTagsTab && tag.category) {
      return BRANDBASTION_TAG_COLORS[tag.category] || DEFAULT_TAG_COLOR;
    }

    return tag.color || DEFAULT_TAG_COLOR;
  };

  return (
    <>
      <div className={s.bbTagsManagementViewHeader}>
        <div className={s.bbTagsManagementViewHeaderTitle}>{title}</div>
        <div className={s.bbTagsManagementViewHeaderActions}>
          <div className={s.bbTagsManagementViewHeaderActionsSearchInput}>
            <InputWhite
              _size="sm"
              value={searchTerm}
              prefixEl={<SearchIcon />}
              placeholder={t("generic:search")}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
              wrapperClassName={s.bbTagsManagementViewHeaderActionsSearchInputWrapper}
            />
          </div>
          {isBBTagsTab && (
            <Button
              onClick={handleToggleAll}
              _type="on-surface"
              _size="sm"
              className={s.bbAllTagsCollapsed}
            >
              {isAnyExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              <span>
                {t(
                  `components:controlPanel:tagsManagement:${
                    isAnyExpanded ? "collapseAll" : "expandAll"
                  }`,
                )}
              </span>
            </Button>
          )}
          {onAddClick && (
            <Button
              _type="primary"
              onClick={onAddClick}
              className={s.bbTagsManagementViewHeaderActionsCreateBtn}
              _size="sm"
            >
              <PlusIcon />
              {t("components:controlPanel:tagsManagement:addTag")}
            </Button>
          )}
        </div>
      </div>
      <div className={s.bbTagsManagementViewList}>
        {isLoading && (
          <div className={s.bbTagsManagementViewListLoading}>
            <Loading isLoading />
          </div>
        )}
        {!isLoading && (
          <>
            {!!tags.length ? (
              tags
                ?.filter(tag => tag.title?.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(tag => (
                  <Fragment key={tag.key}>
                    <ItemCard
                      name={
                        !!tag.children?.length ? (
                          <TabTitle text={tag.title} tagsAmount={tag.children.length} />
                        ) : (
                          tag.title
                        )
                      }
                      color={getTagColor(tag)}
                      onItemClick={() => onEditClick?.(tag)}
                      key={tag.key}
                      suffix={
                        !!tag.children?.length && (
                          <div
                            className={classNames(s.bbTagArrowIcon, {
                              [s.bbTagArrowIconCollapsed]: collapsedItems[tag.key],
                            })}
                            onClick={() => handleToggleCollapse(tag.key)}
                          >
                            <ChevronDownIcon />
                          </div>
                        )
                      }
                      actions={
                        tag.is_editable && !tag.children
                          ? [
                              {
                                key: "edit",
                                label: "generic:edit",
                                onActionClick: () => onEditClick?.(tag),
                              },
                              {
                                key: "delete",
                                label: "generic:delete",
                                onActionClick: () => onDeleteConfirm?.({ id: tag.key }),
                                confirmationText: t(
                                  "components:controlPanel:tagsManagement:deleteTag",
                                ),
                              },
                            ]
                          : undefined
                      }
                    />
                    <div
                      className={classNames(s.bbSubTagWrapper, {
                        [s.bbSubTagWrapperCollapsed]: collapsedItems[tag.key],
                      })}
                    >
                      {tag.children?.map(child => (
                        <ItemCard
                          name={child.name}
                          key={child.id}
                          className={s.bbSubTag}
                          actions={
                            onEditClick
                              ? [
                                  {
                                    key: "edit",
                                    label: "generic:edit",
                                    onActionClick: () => onEditClick?.(child),
                                  },
                                  {
                                    key: "delete",
                                    label: "generic:delete",
                                    onActionClick: () =>
                                      onDeleteConfirm?.({ id: child.id, parent_id: tag.key }),
                                    confirmationText: t(
                                      "components:controlPanel:tagsManagement:deleteTag",
                                    ),
                                  },
                                ]
                              : undefined
                          }
                        />
                      ))}
                    </div>
                  </Fragment>
                ))
            ) : (
              <div className={s.bbTagsManagementViewListEmpty}>
                <EmptyList />
                <h3>{t("components:controlPanel:tagsManagement:empty:title")}</h3>
                <p>{t("components:controlPanel:tagsManagement:empty:description")}</p>
                {onAddClick && (
                  <Button
                    _type="primary"
                    onClick={onAddClick}
                    className={s.bbTagsManagementViewListEmptyButton}
                  >
                    <PlusIcon />
                    {t("components:controlPanel:tagsManagement:addTag")}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TagsList;
