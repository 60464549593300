import { useSelector } from "react-redux";
import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import AppLayoutContainer from "@components/_common/AppLayoutContainer/AppLayoutContainer";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import ControlPanel from "@components/control-panel";

import { meSelector } from "@store/me/selectors";

import s from "./Routes.module.less";

import { IRestrictedRoutesProps } from "./Routes.type";
import { useRoutes } from "@utils/useRoutes";
import { IMenuItem } from "@bbdevcrew/bb_ui_kit_fe";

const ControlPanelRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const { redirectCallback, extraMenuItems } = useRoutes(path);

  const me = useSelector(meSelector);

  return (
    <>
      <Routes>
        <Route
          path="*"
          element={
            <AppLayoutContainer
              basename="control_panel"
              avatarUrl={me?.client?.logo}
              extra={extraMenuItems}
              menu={menu as unknown as IMenuItem[]}
              contentClassName={s.bbControlPanelContent}
            >
              {{
                content: (
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <ControlPanel />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="unauthorized"
                      element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
                    />
                    <Route
                      path="*"
                      element={<NotFoundView redirectCallback={redirectCallback} />}
                    />
                  </Routes>
                ),
              }}
            </AppLayoutContainer>
          }
        />
      </Routes>
    </>
  );
};

export default ControlPanelRoutes;
