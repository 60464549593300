import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SingleMail.module.less";

interface ISingleMailProps {
  name: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function SingleMail({ onChange, name, checked }: ISingleMailProps) {
  const { t } = useTranslation();

  return (
    <div className={s.bbSingleMail}>
      <span>
        <Checkbox id={name} name={name} _size="md" onChange={onChange} checked={checked} />
      </span>
      <label htmlFor={name}>
        <span className={s.bbSingleMailTitle}>
          {t("components:notificationsPanel:modals:create:singleEmail")}
        </span>
      </label>
    </div>
  );
}
