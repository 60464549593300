import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";

import { Grid } from "antd";
import ChartLabel from "./ChartLabel";
import ReactResizeDetector from "react-resize-detector";
import DownloadCSV from "@components/_common/DownloadCSV";
import BreakdownChartTooltip from "./BreakdownChartTooltip";
import { BreakdownChart } from "../../../_common/BreakdownChart/BreakdownChart";
import { Button, Card, TabPane, TabsScrollable, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Protect.module.less";

import {
  LABEL_WIDTH,
  LABEL_SMALL,
  LABEL_LARGE,
  CHART_HEIGHT_SMALL,
  CHART_HEIGHT_LARGE,
} from "./Protect.helpers";
import { IChartLabelClickEvent, IProtectBreakdownProps } from "./Protect.types";
import { getCSVBreakdownData, getCSVTableHeaders } from "../Transparency.helpers";

import { DownloadIcon } from "@assets/index";

const ProtectBreakdown: FC<IProtectBreakdownProps> = ({ filterToSpecificItem, data, disabled }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const chartLabelWidth = screens.md ? LABEL_LARGE : LABEL_SMALL;
  const [height, setHeight] = useState(CHART_HEIGHT_SMALL);

  const [activeTabKey, setActiveTabKey] = useState("platform_type");

  useEffect(() => {
    if (data?.length) {
      const currentDataLenth = data.find(unit => unit.id === activeTabKey)?.items.length;
      if (currentDataLenth && currentDataLenth > 5) {
        setHeight(CHART_HEIGHT_LARGE);
      } else {
        setHeight(CHART_HEIGHT_SMALL);
      }
    }
  }, [activeTabKey, data]);

  const onYAxisLabelClick = (clickedItem: IChartLabelClickEvent) => {
    if (data?.length) {
      const safetyBreakdownUnit = data.find(unit => unit.id === activeTabKey);

      if (safetyBreakdownUnit && filterToSpecificItem) {
        filterToSpecificItem({
          [safetyBreakdownUnit.search_param as string]: [clickedItem.value],
        });
      }
    }
  };

  const getSelectedItemLabel = () => {
    return data?.find(item => item.id === activeTabKey)?.label || "";
  };

  return (
    <div className={s.bbBreakdown}>
      <Card
        id="card-breakdown"
        className={s.bbBreakdownCard}
        data-stonly-target="safety-moderation__breakdown-chart"
        headerTitle={t("components:breakdown:title", {
          selected: getSelectedItemLabel(),
        })}
        headerTooltip={t("components:breakdown:info", {
          selected: getSelectedItemLabel(),
        })}
        headerAction={
          <>
            {
              <Tooltip title={disabled ? t("pages:insights:ytNoDownload") : ""}>
                <span>
                  <Button className={s.bbDownloadButton} disabled={disabled}>
                    {data && (
                      <DownloadCSV
                        icon={<DownloadIcon />}
                        fileNamePrefix="breakdown"
                        disabled={disabled}
                        data={getCSVBreakdownData(data, activeTabKey)}
                        tableHeaders={getCSVTableHeaders(getSelectedItemLabel())}
                      />
                    )}
                  </Button>
                </span>
              </Tooltip>
            }
          </>
        }
      >
        <TabsScrollable
          activeKey={activeTabKey}
          onChange={key => setActiveTabKey(key)}
          defaultActiveKey={(data && data[0]?.id) || "platform_type"}
        >
          {data?.map(breakdownUnit => (
            <TabPane tab={breakdownUnit.label} tabKey={breakdownUnit.id} key={breakdownUnit.id}>
              <ReactResizeDetector
                handleWidth
                refreshRate={2000}
                refreshMode={"throttle"}
                refreshOptions={{ leading: true, trailing: true }}
              >
                {(resizeProps: { width: number }) => {
                  return (
                    <>
                      <BreakdownChart
                        data={breakdownUnit}
                        hasStripePattern={false}
                        height={height}
                        labelWidth={LABEL_WIDTH}
                        customTooltip={<BreakdownChartTooltip />}
                        width={resizeProps.width || 0}
                        generateYLabel={props => (
                          <ChartLabel
                            type={activeTabKey}
                            labelTickProps={props}
                            breakdownUnit={breakdownUnit}
                            tooltipLabel={getSelectedItemLabel()}
                            chartLabelWidth={chartLabelWidth}
                          />
                        )}
                        onYAxisLabelClick={onYAxisLabelClick}
                      />

                      <span className={s.bbProtectBreakdownLegendSentiment}>
                        <div></div>
                        {t("components:managed:protect:breakdown:legendTitle")}
                      </span>
                    </>
                  );
                }}
              </ReactResizeDetector>
            </TabPane>
          ))}
        </TabsScrollable>
      </Card>
    </div>
  );
};

export default ProtectBreakdown;
