import React, { FC, useMemo } from "react";
import ReactResizeDetector from "react-resize-detector";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Scatter,
  ComposedChart,
} from "recharts";
import TopPostTagsTooltip from "../TopPostTagsTooltip";

import s from "./TopPostTagsChart.module.less";

import {
  getSentimentBars,
  renderYLabel,
  generateScatterData,
  renderScatterShape,
} from "../TopPostTags.helpers";
import { numberToQuantity } from "@bbdevcrew/bb_ui_kit_fe";
import { ITopPostTagsChartProps } from "../TopPostTags.types";

import { CommentIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { triggerFilteringWithoutUrlAction } from "@store/filters/actions";

export const TopPostTagsChart: FC<ITopPostTagsChartProps> = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const maxPostCount = Math.max(...data.map(item => item.count || 0));
  const postCountRange = Math.ceil(maxPostCount / 10) * 10;

  const sentimentBars = useMemo(() => {
    return getSentimentBars(t);
  }, [t]);

  const getColor = (label: string) => {
    return data.find(item => item.label === label)?.color;
  };

  const onClickPostCount = (label: string) => {
    const tagItem = data.find(item => item.label === label);
    if (tagItem?.id) {
      dispatch(triggerFilteringWithoutUrlAction({ post_tags: [`custom_post_tag::${tagItem.id}`] }));
    }
  };

  return (
    <div className={s.bbTopPostTagsChartContainer}>
      <div className={s.bbTopPostTagsChart}>
        <ReactResizeDetector handleWidth refreshMode="throttle">
          {({ width }: { width: number }) => (
            <ResponsiveContainer width="100%" height={data.length * 60 + 130}>
              <ComposedChart
                width={width}
                data={data}
                layout="vertical"
                margin={{ top: 45, right: 30, left: 20, bottom: 70 }}
                barSize={32}
                barGap={0}
                barCategoryGap={16}
              >
                <XAxis
                  type="number"
                  tickFormatter={numberToQuantity}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: "var(--font-size-md)" }}
                />
                <YAxis
                  dataKey="label"
                  type="category"
                  width={140}
                  tick={renderYLabel(getColor)}
                  axisLine={false}
                  tickLine={false}
                />
                <RechartsTooltip
                  content={<TopPostTagsTooltip data={data} />}
                  wrapperStyle={{ zIndex: 100 }}
                  cursor={false}
                  active={false}
                />
                {sentimentBars.map(bar => (
                  <Bar
                    key={bar.dataKey}
                    dataKey={bar.dataKey}
                    stackId={bar.stackId}
                    fill={bar.fill}
                    name={bar.name}
                    radius={bar.dataKey === "neutral" ? [0, 8, 8, 0] : 0}
                    isAnimationActive={false}
                    className={bar.className}
                    shape={
                      bar.width
                        ? props => {
                            const { x, y, width: barWidth, height: barHeight, fill } = props;
                            return (
                              <rect
                                x={x + barWidth - (bar.width || 0)}
                                y={y}
                                width={bar.width || 0}
                                height={barHeight}
                                fill={fill}
                              />
                            );
                          }
                        : undefined
                    }
                  />
                ))}
                <Scatter
                  data={generateScatterData(data)}
                  xAxisId="postCount"
                  fill="var(--color-primary-1)"
                  name={t("components:topPostTags:postCount")}
                  isAnimationActive={false}
                  dataKey="x"
                  shape={renderScatterShape(onClickPostCount)}
                />
                <XAxis
                  xAxisId="postCount"
                  orientation="top"
                  type="number"
                  dataKey="count"
                  domain={[0, postCountRange + 5]}
                  tickFormatter={numberToQuantity}
                  height={35}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: "var(--font-size-md)" }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </ReactResizeDetector>
        <div className={s.bbTopPostTagsChartCustomAxisLabel}>
          <div className={s.bbTopPostTagsChartAxisLabelCircle}></div>
          <span className={s.bbTopPostTagsChartAxisLabelText}>
            {t("components:topPostTags:numberOfPosts")}
          </span>
        </div>
        <div className={s.bbTopPostTagsChartCustomCommentsLabel}>
          <i className={s.bbTopPostTagsChartCommentsIcon}>
            <CommentIcon />
          </i>
          <span className={s.bbTopPostTagsChartCommentsLabelText}>
            {t("components:topPostTags:comments")}
          </span>
        </div>
      </div>
    </div>
  );
};
