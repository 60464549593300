import cn from "classnames/bind";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AutomatedHidingModal.module.less";

import { IAutomatedHidingModalProps, applySpanTags } from "./helpers";
import { getPlatformIconBasicColor, platformIconName } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

export const AutoHidingSaveUnsavedModal: FC<IAutomatedHidingModalProps> = ({
  selectedClassifiers,
  selectedPlatforms,
  selectedKeywords,
  selectedAssets,
  selectedPostTypes,
  onConfirmChanges,
  ...modalProps
}) => {
  const { t } = useTranslation();

  if (!modalProps.open) {
    return null;
  }

  return (
    <Modal
      centered
      width={575}
      confirmLabel={
        !!selectedClassifiers.length || !!selectedKeywords.length
          ? t("components:automatedHiding:modals:confirmModal:startActionBtn")
          : t("components:automatedHiding:modals:confirmModal:stopActionBtn")
      }
      cancelLabel={t("generic:discardChanges")}
      title={t("generic:unsavedChanges")}
      onOk={onConfirmChanges}
      {...modalProps}
    >
      <div className={s.bbAutomatedHidingModalContainer}>
        <div className={s.bbUnsavedPhrase}>
          {t("components:automatedHiding:modals:unsavedChangesModal:unsavedPhrase")}
        </div>
        {!!selectedClassifiers.length || !!selectedKeywords.length ? (
          <div className={s.bbAutomatedHidingModalDescriptionStartHiding}>
            {t("components:automatedHiding:modals:confirmModal:confirmPhraseCheckedPart1")}
            <br />
            {applySpanTags(selectedClassifiers, "and")}
            {selectedKeywords.length > 0 && (
              <>
                {t("components:automatedHiding:modals:confirmModal:confirmPhraseCheckedPart2")}
                <Tooltip
                  title={selectedKeywords.map(keyword => (
                    <div key={keyword}>{keyword}</div>
                  ))}
                >
                  <span className={s.bbAutomatedHidingModalChip}>
                    {t("components:automatedHiding:modals:confirmModal:confirmPhraseCheckedPart3", {
                      count: selectedKeywords.length,
                    })}
                  </span>
                </Tooltip>
              </>
            )}
            {t("components:automatedHiding:modals:confirmModal:connector")}
            {selectedPlatforms.map(platform => (
              <span key={platform} className={cx(s.bbPlatformIcon, `bbPlatformIcon-${platform}`)}>
                {getPlatformIconBasicColor(platformIconName(platform))}
              </span>
            ))}
            {!!selectedAssets.length && (
              <>
                {" "}
                {t("generic:within")}{" "}
                <span className={s.bbAutomatedHidingModalChip}>
                  {t("components:automatedHiding:modals:confirmModal:confirmPhraseCheckedPart4", {
                    count: selectedAssets.length,
                  })}
                </span>
              </>
            )}
            {!!selectedPostTypes.length && (
              <>
                {" "}
                {!!selectedAssets.length ? t("generic:and") : t("generic:within")}{" "}
                <span className={s.bbAutomatedHidingModalChip}>
                  {t("components:automatedHiding:modals:confirmModal:confirmPhraseCheckedPart5", {
                    count: selectedPostTypes.length,
                  })}
                </span>
              </>
            )}
            {t("components:automatedHiding:modals:confirmModal:confirmPhraseCheckedPart6")}
          </div>
        ) : (
          <div>{t("components:automatedHiding:modals:unsavedChangesModal:uncheckedAllPhrase")}</div>
        )}
        <div className={s.bbAutomatedHidingModalSaveQuestion}>
          {t("components:automatedHiding:modals:unsavedChangesModal:saveQuestion")}
        </div>
      </div>
    </Modal>
  );
};

export default AutoHidingSaveUnsavedModal;
