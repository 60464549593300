import React from "react";
import { t } from "../../../../App";

import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ProfilesTable.module.less";

import { ProfileDataStatusEnum } from "@store/dashboard/types";

export const ColumnWithGrowth = ({
  value,
  growth,
  showPercentage,
  status,
}: {
  value?: number | string | null;
  growth?: number | string | null;
  showPercentage?: boolean;
  status?: ProfileDataStatusEnum;
}) => {
  if (status && status !== ProfileDataStatusEnum.ACTIVE) {
    return (
      <span className={s.bbColumnNotAvailable}>
        {t(`components:listen:profiles:status:${status}`)}
      </span>
    );
  } else if (value == null && !growth) {
    return (
      <span className={s.bbColumnNotAvailable}>
        {t("components:listen:profiles:status:no_data")}
      </span>
    );
  }

  return (
    <>
      {value != null ? `${value.toLocaleString("en-US")}${showPercentage ? "%" : ""}` : ""}
      {!!growth && (
        <PerformanceCardPill percentageValue={Number(growth)} className={s.bbColumnPercentPill} />
      )}
    </>
  );
};
