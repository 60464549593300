import React from "react";

import { IModalProps, humanize } from "@bbdevcrew/bb_ui_kit_fe";
import { IHidingConfigPostTypeGroup } from "@store/hidingConfig/types";

import s from "./AutomatedHidingModal.module.less";

export interface IAutomatedHidingModalProps extends IModalProps {
  selectedAssets: string[];
  selectedPostTypes: string[];
  selectedPlatforms: string[];
  selectedClassifiers: string[];
  selectedKeywords: string[];
  onConfirmChanges: () => void;
  visible?: boolean;
}

export const setUnsavedInfo = (
  selectedClassifiers: string[],
  selectedPlatforms: string[],
  keywords: string[],
  assets: string[],
  postTypes: string[],
) => {
  localStorage.setItem("automated_hiding_unsaved_changes", "true");
  localStorage.setItem(
    "automated_hiding_selected_classifiers",
    JSON.stringify(selectedClassifiers),
  );
  localStorage.setItem("automated_hiding_selected_platforms", JSON.stringify(selectedPlatforms));
  localStorage.setItem("automated_hiding_selected_keywords", JSON.stringify(keywords));
  localStorage.setItem("automated_hiding_selected_assets", JSON.stringify(assets));
  localStorage.setItem("automated_hiding_selected_post_types", JSON.stringify(postTypes));
};

export const cleanUnsavedInfo = () => {
  localStorage.setItem("automated_hiding_unsaved_changes", "false");
  localStorage.setItem("automated_hiding_selected_classifiers", JSON.stringify([]));
  localStorage.setItem("automated_hiding_selected_platforms", JSON.stringify([]));
  localStorage.setItem("automated_hiding_selected_keywords", JSON.stringify([]));
  localStorage.setItem("automated_hiding_selected_assets", JSON.stringify([]));
  localStorage.setItem("automated_hiding_selected_post_types", JSON.stringify([]));
};

export const getSavedClassifiers = () => {
  const savedClassifiers = localStorage.getItem("automated_hiding_selected_classifiers");
  return savedClassifiers?.length ? JSON.parse(savedClassifiers) : [];
};

export const getSavedPlatforms = () => {
  const savedPlatforms = localStorage.getItem("automated_hiding_selected_platforms");
  return savedPlatforms?.length ? JSON.parse(savedPlatforms) : [];
};

export const getSavedKeywords = () => {
  const savedKeywords = localStorage.getItem("automated_hiding_selected_keywords");
  return savedKeywords?.length ? JSON.parse(savedKeywords) : [];
};

export const getSavedAssets = () => {
  const savedAssets = localStorage.getItem("automated_hiding_selected_assets");
  return savedAssets?.length ? JSON.parse(savedAssets) : [];
};

export const getSavedPostTypes = () => {
  const savedPostTypes = localStorage.getItem("automated_hiding_selected_post_types");
  return savedPostTypes?.length ? JSON.parse(savedPostTypes) : [];
};

export const getSelectedPostTypeIds = (groups: IHidingConfigPostTypeGroup[]) => {
  const selectedIds = [];

  for (const { items: types } of groups) {
    for (const type of types) {
      if (type.selected) {
        selectedIds.push(type.id);
      }
    }
  }

  return selectedIds;
};

export const applySpanTags = (array: string[], letter: string) => {
  return array.map((item, index) => {
    return (
      <>
        <span key={item} className={s.bbAutomatedHidingModalChip}>
          {humanize(item)}
        </span>{" "}
        {index === array.length - 1 ? "" : index === array.length - 2 ? ` ${letter} ` : " , "}
      </>
    );
  });
};
