import { useEffect } from "react";
import { ActionType } from "typesafe-actions";
import { useDispatch, useSelector } from "react-redux";

import {
  triggerNextCommentBatch,
  performCommentActionSuccessAction,
} from "@store/comments/actions";
import { getReplyCommentsSelector } from "@store/replies/selectors";
import { commentsSelector } from "@store/comments/selectors";

import {
  ICommentsState,
  ICommentActionPayload,
  DEFAULT_COMMENT_LIST_KEY,
} from "@store/comments/types";
import { RootState } from "@store/index";
import { i18nextInstance } from "../App";
import {
  PlatformType,
  IComment,
  ICommentReply,
  CommentListType,
  IReplyData,
} from "@bbdevcrew/bb_ui_kit_fe";
import { IRepliesState } from "@store/replies/types";

export const getAiInboxSortOptions = () => [
  {
    id: "desc|ai_inbox_score",
    label: i18nextInstance.t("components:inbox:sort:higherIntent"),
  },
  {
    id: "asc|ai_inbox_score",
    label: i18nextInstance.t("components:inbox:sort:lowerIntent"),
  },
  {
    id: "desc|created_time",
    label: i18nextInstance.t("components:inbox:sort:newest"),
  },
  {
    id: "asc|created_time",
    label: i18nextInstance.t("components:inbox:sort:oldest"),
  },
];

/**
 * TODO: Not really ideal. We should look to adjust our contracts, so that comment actions
 * match inboxStats and comment.reply props.
 */
export enum ActionToReplyProp {
  like = "is_liked",
  hide = "is_hidden",
  flag = "is_flagged",
  ignore = "is_ignored",
  review = "is_reviewed",
  delete = "is_deleted",
  star = "is_starred",
  dismiss_errors = "is_error_dismissed",
  reply_cancel = "is_cancelled",
  reply_uncancel = "is_cancelled",
}

export const canReplyToComment = (comment: IComment, isHidden: boolean) => {
  if (isHidden) {
    if (
      comment.platform_type.id === "facebook" ||
      comment.platform_type.id === "twitter" ||
      (comment.platform_type.id === "tiktok" && !comment.has_ads)
    ) {
      return true;
    }
    return false;
  }

  return true;
};

// Sets comment `reply` props
export function getNewReplyState(
  currentState: ICommentReply,
  commentActionPayload: ICommentActionPayload,
) {
  let state: ICommentReply = {
    ...currentState,
    [ActionToReplyProp[commentActionPayload.action]]: commentActionPayload.value,
  };

  if (commentActionPayload.action === "ignore") {
    state = {
      ...state,
      is_starred: false,
    };
  }

  return state;
}

export function handleSuccessfulCommentActionState<S extends ICommentsState | IRepliesState>(
  state: S,
  action: ActionType<typeof performCommentActionSuccessAction>,
): S {
  const comment = state.cache[action.payload.commentAction.id];

  if (comment) {
    if (action.payload.commentAction.action.includes("hide")) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.commentAction.id]: {
            ...comment,
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            moderation: {
              ...comment.moderation,
              is_reviewed: action.payload.commentAction.value,
            },
            actions: {
              ...comment.actions,
              can_reply: canReplyToComment(comment, action.payload.commentAction.value),
            },
            is_hidden: action.payload.commentAction.value,
          },
        },
      };
    }

    if (action.payload.commentAction.action.includes("delete")) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.commentAction.id]: {
            ...comment,
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            moderation: {
              ...comment.moderation,
              is_reviewed: action.payload.commentAction.value,
            },
            is_deleted: action.payload.commentAction.value,
          },
        },
      };
    }

    if (action.payload.commentAction.action.includes("like")) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.commentAction.id]: {
            ...comment,
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            is_liked: action.payload.commentAction.value,
          },
        },
      };
    }

    if (
      action.payload.commentAction.action.includes("cancel") ||
      action.payload.commentAction.action.includes("uncancel")
    ) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.commentAction.id]: {
            ...comment,
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            actions: {
              ...comment.actions,
              reply_cancel_capability: {
                ...comment.actions.reply_cancel_capability,
                is_cancelled: action.payload.commentAction.value,
              },
            },
          },
        },
      };
    }

    if (action.payload.commentAction.action.match(/review|flag/)) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.commentAction.id]: {
            ...comment,
            actionsProcessing: state.actionsProcessing.filter(
              actionProcessing => actionProcessing !== action.payload.commentAction.action,
            ),
            moderation: {
              ...comment.moderation,
              is_reviewed: action.payload.commentAction.value,
            },
          },
        },
      };
    }

    if (action.payload.commentAction.action.includes("dismiss_errors")) {
      const listKey = action.payload.commentAction.listId || DEFAULT_COMMENT_LIST_KEY;
      const commentsStateCopy = [...state.lists[listKey].comments].filter(id => id !== comment.id);

      return {
        ...state,
        lists: {
          ...state.lists,
          [listKey]: {
            ...state.lists[listKey],
            comments: commentsStateCopy,
          },
        },
      };
    }

    // Flag/ignore actions are handled in `reply` prop of comment
    return {
      ...state,
      cache: {
        ...state.cache,
        [action.payload.commentAction.id]: {
          ...comment,
          actionsProcessing: state.actionsProcessing.filter(
            actionProcessing => actionProcessing !== action.payload.commentAction.action,
          ),
          reply: getNewReplyState(comment.reply, action.payload.commentAction),
        },
      },
    };
  }

  return state;
}

export function handleFailedCommentActionState<S extends ICommentsState | IRepliesState>(state: S) {
  return {
    ...state,
    cache: Object.entries(state.cache).reduce(
      (acc, [id, comment]) => {
        acc[id] = {
          ...comment,
          actionsProcessing: [],
        };
        return acc;
      },
      {} as Record<string, IComment>,
    ),
  };
}

export const getHideUnhideTooltip = (
  isHidden: boolean,
  platformType: PlatformType,
  hideUnhideTooltip?: string,
) => {
  if (!!hideUnhideTooltip) {
    return hideUnhideTooltip;
  }

  if (isHidden) {
    return i18nextInstance.t(`components:comments:tooltips:unhide:${platformType}`);
  }

  return "";
};

const findCommentById = (comments: IComment[], replyingCommentId: string) =>
  comments?.find(({ id }) => id === replyingCommentId);

export const useReplyingComment = (replyData?: IReplyData, listId?: CommentListType) => {
  const dispatch = useDispatch();
  const listKey = listId || DEFAULT_COMMENT_LIST_KEY;
  const comments = useSelector((state: RootState) => commentsSelector(state, listKey));
  const replyComments = useSelector((state: RootState) => getReplyCommentsSelector(state, listKey));

  const commentId = replyData?.replying_comment_id;

  const replyingComment = commentId
    ? findCommentById(comments, commentId) || findCommentById(replyComments, commentId)
    : undefined;

  useEffect(() => {
    if (!replyingComment && commentId) {
      dispatch(triggerNextCommentBatch(commentId));
    }
  }, [replyingComment, commentId, dispatch]);

  return replyingComment;
};
