import { RootState } from "../types";

export const fromCaptionsAIPrompts = (state: RootState) => state.captionsAIPrompts;

export const captionsAIPromptsSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).captionsAIPrompts;

export const creatingCaptionsAIPromptSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).postingCaptionsAIPrompt;
export const createdCaptionsAIPromptSuccessfulSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).postedCaptionsAIPrompt;

export const updatingCaptionsAIPromptSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).updatingCaptionsAIPrompt;
export const updatedCaptionsAIPromptSuccessfulSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).updatedCaptionsAIPrompt;

export const captionsAIPromptSuggestionSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).captionsAISuggestion;
export const fetchingCaptionsAIPromptSuggestionSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).fetchingCaptionsPromptAISuggestion;
export const fetchedCaptionsAIPromptSuggestionSelector = (state: RootState) =>
  fromCaptionsAIPrompts(state).fetchedCaptionsPromptAISuggestion;
