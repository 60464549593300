import { RootState } from "..";

export const fromSources = (state: RootState) => state.sources;

export const getSourcesListSelector = (state: RootState) => fromSources(state).sources;
export const getSourcesListPendingSelector = (state: RootState) =>
  fromSources(state).fetchingSourcesList;
export const getSourcesListSuccessfulSelector = (state: RootState) =>
  fromSources(state).fetchedSourcesList;
export const getSourcesListFailureSelector = (state: RootState) =>
  fromSources(state).fetchedSourcesListFail;

export const fetchingSourceSelector = (state: RootState) => fromSources(state).fetchingSource;
export const fetchedSourceSelector = (state: RootState) => fromSources(state).fetchedSource;
export const fetchingSourceFailedSelector = (state: RootState) =>
  fromSources(state).fetchingSourceFailed;
export const fetchingSourceFailedMessageSelector = (state: RootState) =>
  fromSources(state).fetchingSourceFailedMessage;
