import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Post.module.less";

import { IPostAnalysisProps } from "./Post.type";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

const PostAnalysis = ({ post }: IPostAnalysisProps) => {
  const { t } = useTranslation();
  const isFailed = post.post_analysis?.is_failed;
  const failedMessage = t("components:comments:post:analysisFailed");

  return (
    <>
      <div className={s.bbPostRightImageAnalysisLabel}>
        {t("components:comments:post:textOCRLabel")}
        <Tooltip placement="top" title={t("components:comments:post:tooltips:textOCR")}>
          <span className={s.bbPostInfoIcon}>
            <InformationIcon />
          </span>
        </Tooltip>
      </div>
      <p className={s.bbPostRightImageAnalysisValue}>
        {isFailed ? failedMessage : post.post_analysis?.text}
      </p>
      <div className={s.bbPostRightImageAnalysisLabel}>
        {t("components:comments:post:descriptionLabel")}
        <Tooltip placement="top" title={t("components:comments:post:tooltips:description")}>
          <span className={s.bbPostInfoIcon}>
            <InformationIcon />
          </span>
        </Tooltip>
      </div>
      <p className={s.bbPostRightImageAnalysisValue}>
        {isFailed ? failedMessage : post.post_analysis?.description}
      </p>
      <div className={s.bbPostRightImageAnalysisLabel}>
        {t("components:comments:post:intentionLabel")}
        <Tooltip placement="top" title={t("components:comments:post:tooltips:intention")}>
          <span className={s.bbPostInfoIcon}>
            <InformationIcon />
          </span>
        </Tooltip>
      </div>
      <p className={s.bbPostRightImageAnalysisValue}>
        {isFailed ? failedMessage : post.post_analysis?.intention}
      </p>
    </>
  );
};

export default PostAnalysis;
