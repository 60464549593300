import React, { useCallback, useMemo } from "react";

import { Grid } from "antd";
import XPostPreview from "./previewX";
import FBPostPreview from "./previewFb";
import LIPostPreview from "./previewLI";
import FBLinkPreview from "./previewFb/FBLinkPreview";
import LILinkPreview from "./previewLI/LILinkPreview";
import IGPostPreview from "./previewIG/IgPostPreview";
import TTPostPreview from "./previewTT/TTPostPreview";
import YTPostPreview from "./PreviewYT";
import PostPreviewEmptyState from "./previewEmptyState/PreviewEmptyState";
import { PostTabs } from "@components/_common/Social/Post/PostTabs";

import s from "./index.module.less";

import {
  isFB,
  isIG,
  isX,
  isTT,
  isLI,
  isYT,
  validatePostVideoFilesCount,
  validatePostAttachmentFilesCount,
} from "./helpers/utilsValidations";

import { IPostItem } from "./helpers/types";
import { IAttachment } from "./previewX/types";
import classNames from "classnames";
interface IPostCreationPreviewProps {
  post?: IPostItem;
  id?: string;
}

export function PostCreationPreview({ post, id }: IPostCreationPreviewProps) {
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;

  const onlyVideosOrImagesUploaded = useMemo(
    () => !!post?.previewImageFiles.length !== !!post?.previewVideoFiles.length,
    [post?.previewImageFiles, post?.previewVideoFiles],
  );

  const onlyVideosAndImagesUploaded = useMemo(
    () => !!post?.previewImageFiles.length || !!post?.previewVideoFiles.length,
    [post?.previewImageFiles, post?.previewVideoFiles],
  );

  const hasContent = useMemo(
    () =>
      !!post?.previewImageFiles.length ||
      !!post?.previewVideoFiles.length ||
      !!post?.previewPDFFiles.length ||
      !!post?.message.length,
    [post?.previewImageFiles, post?.previewVideoFiles, post?.previewPDFFiles, post?.message],
  );

  const showFBPreview = () => {
    return (
      !!post &&
      isFB(post) &&
      validatePostVideoFilesCount(post) &&
      (onlyVideosOrImagesUploaded || !!post?.message.length)
    );
  };

  const showIGPreview = () => {
    return !!post && isIG(post) && validatePostVideoFilesCount(post) && onlyVideosOrImagesUploaded;
  };

  const showXPreview = useCallback(() => {
    return (
      !!post &&
      isX(post) &&
      validatePostAttachmentFilesCount(post) &&
      (onlyVideosAndImagesUploaded || !!post?.message.length)
    );
  }, [post, onlyVideosAndImagesUploaded]);

  const showTTPreview = () => {
    return !!post && isTT(post) && validatePostVideoFilesCount(post);
  };

  const showLIPreview = () => {
    return !!post && isLI(post) && validatePostVideoFilesCount(post) && hasContent;
  };

  const showYTPreview = () => {
    return (
      !!post &&
      isYT(post) &&
      validatePostVideoFilesCount(post) &&
      hasContent &&
      !!post.previewVideoFiles[0]?.metadata
    );
  };

  const noPreview =
    !showFBPreview() &&
    !showIGPreview() &&
    !showXPreview() &&
    !showTTPreview() &&
    !showLIPreview() &&
    !showYTPreview();

  const xPreviewAttachments: IAttachment[] = useMemo(() => {
    if (post && showXPreview()) {
      const files = [
        ...post.previewImageFiles.map(
          file =>
            ({
              id: file.uid,
              url: file.src,
              type: "photo",
            }) as IAttachment,
        ),
        ...post.previewVideoFiles.map(
          file =>
            ({
              id: file.uid,
              url: file.object_src,
              type: "video",
            }) as IAttachment,
        ),
      ];

      const sortedIds = post.orderedFileIds || [];
      files.sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
      return files;
    }
    return [];
  }, [post, showXPreview]);

  return (
    <div
      className={classNames(s.bbPostCreationPreview, { [s.bbPostCreationPreviewMobile]: isMobile })}
    >
      <PostTabs id={id} notesClassName={s.bbPostCreationPreviewNotes}>
        {!!post && showFBPreview() && (
          <>
            {post?.asset.preview_link ? (
              <FBLinkPreview post={post} linkMetaData={post?.asset.preview_link} />
            ) : (
              <FBPostPreview
                post={post}
                video={{
                  src: post?.previewVideoFiles[0]?.object_src,
                  poster: post?.previewVideoFiles[0]?.poster,
                }}
                images={post?.previewImageFiles.map(file => {
                  return {
                    id: file.uid,
                    src: file.src,
                    width: file.width,
                    height: file.height,
                  };
                })}
              />
            )}
          </>
        )}
        {!!post && showIGPreview() && (
          <IGPostPreview
            post={post}
            coverImage={post?.coverImage}
            videos={post?.previewVideoFiles}
            images={post?.previewImageFiles.map(file => {
              return {
                id: file.uid,
                src: file.src,
                width: file.width,
                height: file.height,
              };
            })}
          />
        )}
        {!!post && showXPreview() && <XPostPreview post={post} attachments={xPreviewAttachments} />}
        {!!post && showTTPreview() && (
          <TTPostPreview
            post={post}
            creatorInfo={post?.creatorInfo}
            videoSrc={post?.previewVideoFiles[0]?.object_src}
          />
        )}
        {!!post && showLIPreview() && (
          <>
            {post?.asset.preview_link ? (
              <LILinkPreview post={post} linkMetaData={post?.asset.preview_link} />
            ) : (
              <LIPostPreview
                post={post}
                pdf={post?.previewPDFFiles[0]?.src}
                video={{
                  src: post?.previewVideoFiles[0]?.object_src,
                  poster: post?.previewVideoFiles[0]?.poster,
                }}
                images={post?.previewImageFiles.map(file => {
                  return {
                    id: file.uid,
                    src: file.src,
                    width: file.width,
                    height: file.height,
                  };
                })}
              />
            )}
          </>
        )}
        {!!post && showYTPreview() && <YTPostPreview post={post} />}
        {(!post || noPreview) && <PostPreviewEmptyState />}
      </PostTabs>
    </div>
  );
}
