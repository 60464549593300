import { useEffect } from "react";

import { isYoutubePlatform } from "@bbdevcrew/bb_ui_kit_fe";
import { fetchVideoMetadata } from "@utils/fetchVideoMetadata";

import { IPostItem } from "./types";
import { UpdatePostType } from "./utilsValidations";

const useVideoMetadata = (posts: IPostItem[], updatePost: UpdatePostType) => {
  useEffect(() => {
    posts.forEach(async post => {
      if (
        isYoutubePlatform(post.asset.platform) &&
        post.previewVideoFiles.length > 0 &&
        post.previewVideoFiles
      ) {
        const video = post.previewVideoFiles[0];
        if (!video.metadata) {
          try {
            const metadata = await fetchVideoMetadata(video.object_src);
            updatePost({
              ...post,
              previewVideoFiles: [
                {
                  ...post.previewVideoFiles[0],
                  metadata,
                },
              ],
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
    });
  }, [posts, updatePost]);
};

export default useVideoMetadata;
