export const GET_TAGS_LIST = "TAGS_MANAGEMENT/GET_TAGS_LIST";
export const GET_TAGS_LIST_SUCCESS = "TAGS_MANAGEMENT/GET_TAGS_LIST_SUCCESS";
export const GET_TAGS_LIST_FAILURE = "TAGS_MANAGEMENT/GET_TAGS_LIST_FAILURE";

export const CREATE_TAG = "TAGS_MANAGEMENT/CREATE_TAG";
export const CREATE_TAG_SUCCESS = "TAGS_MANAGEMENT/CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILURE = "TAGS_MANAGEMENT/CREATE_TAG_FAILURE";
export const CREATE_SUBTAG = "TAGS_MANAGEMENT/CREATE_SUBTAG";

export const UPDATE_TAG = "TAGS_MANAGEMENT/UPDATE_TAG";
export const UPDATE_SUBTAG = "TAGS_MANAGEMENT/UPDATE_SUBTAG";
export const UPDATE_TAG_SUCCESS = "TAGS_MANAGEMENT/UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAILURE = "TAGS_MANAGEMENT/UPDATE_TAG_FAILURE";

export const DELETE_TAG = "TAGS_MANAGEMENT/DELETE_TAG";
export const DELETE_SUBTAG = "TAGS_MANAGEMENT/DELETE_SUBTAG";
export const DELETE_TAG_SUCCESS = "TAGS_MANAGEMENT/DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "TAGS_MANAGEMENT/DELETE_TAG_FAILURE";

export const RESET_TAG = "TAGS_MANAGEMENT/RESET_TAG";
