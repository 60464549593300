import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

import AddTagButton from "../AddTagButton";

import { IProductRowProps } from "./ProductTags.types";

import s from "./ProductTags.module.less";
import "react-lazy-load-image-component/src/effects/opacity.css";

export const ProductRow = React.forwardRef<HTMLDivElement, IProductRowProps>(function ProductRow(
  { product, onAdd, onDelete, ...restProps },
  ref,
) {
  const { t } = useTranslation();

  return (
    <div className={s.bbProductTagsItem} ref={ref} {...restProps}>
      <LazyLoadImage
        className={s.bbProductTagsItemImage}
        src={product.image_url}
        alt={product.product_name}
        height={40}
        width={40}
        effect="opacity"
      />
      <h3 className={s.bbProductTagsItemTitle}>{product.product_name}</h3>
      {!!onDelete ? (
        <button className={s.bbProductTagsItemDelete} onClick={onDelete}>
          <CloseIcon />
        </button>
      ) : (
        <AddTagButton
          disabled={!onAdd}
          onClick={() => onAdd?.({ ...product, x: 0.5, y: 0.5 })}
          label={t("generic:add")}
        />
      )}
    </div>
  );
});
