import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import NoMatch from "./NoMatch";
import PostView from "@components/_common/PostView/PostView";
import { Button, Loading, CommentCard } from "@bbdevcrew/bb_ui_kit_fe";

import {
  fetchedRepliesAttachmentsSelector,
  fetchingRepliesAttachmentsSelector,
} from "@store/replies/selectors";
import {
  fetchedCommentsAttachmentsSelector,
  fetchingCommentsAttachmentsSelector,
} from "@store/comments/selectors";
import { meSelector } from "@store/me/selectors";
import { openedAttachmentSelector } from "@store/attachment/selectors";
import { matchingCommentsSelector } from "@store/workflows/selectors";
import { closeAttachmentAction, openAttachmentAction } from "@store/attachment/actions";
import { clearMatchingCommentsAction, getMatchingCommentsAction } from "@store/workflows/actions";

import s from "./Step.module.less";

import { IStep3Props } from "./types";
import { useWorkflowSubmitHandler } from "../helpers";
import { IAttachment, IPrivateAttachment, IComment } from "@bbdevcrew/bb_ui_kit_fe";

import { LoadingOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";

const CommentsPreview: FC<{ comments: IComment[]; totalCount: number }> = ({
  comments,
  totalCount,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const me = useSelector(meSelector);
  const openedAttachment = useSelector(openedAttachmentSelector);
  const isFetchingRepliesAttachments = useSelector(fetchingRepliesAttachmentsSelector);
  const fetchedRepliesAttachments = useSelector(fetchedRepliesAttachmentsSelector);
  const isFetchingCommentsAttachments = useSelector(fetchingCommentsAttachmentsSelector);
  const fetchedCommentsAttachments = useSelector(fetchedCommentsAttachmentsSelector);

  const onAttachmentOpen = (attachment: IAttachment | IPrivateAttachment) => {
    dispatch(openAttachmentAction(attachment));
  };

  const onAttachmentClose = () => dispatch(closeAttachmentAction());

  const moreCommentsCount = totalCount - comments.length;

  return (
    <div className={s.bbWorkflowPreviewInner}>
      <h2 className={s.bbWorkflowPreviewTitle}>
        {t("pages:workflows:workflowCreationModal:step3:subtitle")}
      </h2>
      <p className={s.bbWorkflowPreviewDescription}>
        {t("pages:workflows:workflowCreationModal:step3:description", {
          count: totalCount,
        })}
      </p>
      <div className={s.bbWorkflowPreviewList}>
        {comments.map((comment, index) => (
          <CommentCard
            key={comment.id}
            commentIndex={index}
            comment={comment}
            previewMode
            headerPostView={PostView}
            userTimezone={me?.timezone || "GMT"}
            onAttachmentOpen={onAttachmentOpen}
            onAttachmentClose={onAttachmentClose}
            openedAttachment={openedAttachment}
            isFetchingRepliesAttachments={isFetchingRepliesAttachments}
            fetchedRepliesAttachments={fetchedRepliesAttachments}
            isFetchingCommentsAttachments={isFetchingCommentsAttachments}
            fetchedCommentsAttachments={fetchedCommentsAttachments}
          />
        ))}
        {moreCommentsCount > 0 && (
          <div className={s.bbWorkflowPreviewListMore}>
            {t("pages:workflows:workflowCreationModal:step3:more", {
              count: moreCommentsCount,
            })}
          </div>
        )}
      </div>
      <p className={s.bbWorkflowPreviewInfo}>
        {t("pages:workflows:workflowCreationModal:step3:info")}
      </p>
    </div>
  );
};

const Step3: FC<IStep3Props> = ({ setCurrentStep, workflowData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { handleSubmit, isLoading } = useWorkflowSubmitHandler();

  const { data, fetching, fetched } = useSelector(matchingCommentsSelector);

  const { comments = [], totalCount = 0 } = data || {};
  const noMatchingComments = !fetching && fetched && comments?.length === 0;

  useEffect(() => {
    dispatch(
      getMatchingCommentsAction({
        data_range_option: "last_12_months",
        ...workflowData?.trigger?.filters,
      }),
    );

    return () => {
      dispatch(clearMatchingCommentsAction());
    };
  }, [dispatch, workflowData?.trigger?.filters]);

  return (
    <div className={s.bbWorkflowCreationStep} data-cy="workflow-creation-step3">
      <div className={s.bbWorkflowModalHeader}>
        <h2 className={s.bbWorkflowModalTitle}>
          {t("pages:workflows:workflowCreationModal:step3:title")}
        </h2>
      </div>

      <div className={s.bbWorkflowPreview}>
        {noMatchingComments ? (
          <NoMatch />
        ) : fetching ? (
          <Loading
            isLoading
            type="spinning"
            size="small"
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
          />
        ) : (
          <CommentsPreview comments={comments} totalCount={totalCount} />
        )}
      </div>

      <div className={s.bbWorkflowCreationStepFooter}>
        <div className={s.bbFlexGrow}>
          <Button
            _type="secondary"
            onClick={() => setCurrentStep(1)}
            className={s.bbWorkflowCreationStepBackBtn}
            disabled={isLoading}
            data-cy="workflow-creation-step3-back-btn"
          >
            <ChevronLeftIcon />
            {t("generic:back")}
          </Button>
        </div>
        <Button
          _type="primary"
          onClick={() => handleSubmit(workflowData)}
          className={s.bbWorkflowCreationStepBtn}
          loading={isLoading}
          disabled={isLoading}
          data-cy="workflow-creation-step3-submit-btn"
        >
          {workflowData.enabled
            ? t("pages:workflows:workflowCreationModal:step2:okBtn")
            : t("pages:workflows:workflowCreationModal:step2:okBtnDisabled")}
        </Button>
      </div>
    </div>
  );
};

export default Step3;
