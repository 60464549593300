const formatPercentage = (value: number): string => {
  if (typeof value !== "number" || isNaN(value)) {
    return "0%";
  }

  const sign = value < 0 ? "-" : "";
  const absValue = Math.abs(Math.round(value));
  return `${sign}${absValue}%`;
};

export default formatPercentage;
