import classNames from "classnames";
import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import s from "./LinkPreview.module.less";

import { urlPreviewsSelector } from "@store/publishings/selectors";

import { LINK_IMAGE_FRAME_WIDTH, LINK_IMAGE_FRAME_HEIGHT } from "../helpers/constants";
import { extractDomain } from "../helpers/utilsGeneric";

import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { clearUrlPreviewsAction } from "@store/publishings/actions";

const LinkPreviewInline: FC = () => {
  const dispatch = useDispatch();

  const linkMetaData = useSelector(urlPreviewsSelector)?.[0];

  const clearLinkPreviews = useCallback(() => dispatch(clearUrlPreviewsAction(true)), [dispatch]);

  return (
    <>
      {!!linkMetaData?.title && (
        <div className={s.bbInlineLinkPreviewWrapper}>
          {!!linkMetaData.image && (
            <div
              style={{
                minWidth: LINK_IMAGE_FRAME_WIDTH,
                minHeight: LINK_IMAGE_FRAME_HEIGHT,
              }}
              className={s.bbImgWrapper}
            >
              <img src={linkMetaData.image} alt={linkMetaData.title} />
            </div>
          )}

          <div className={s.bbInlineLinkPreview}>
            <div className={s.bbInlineLinkPreviewUrl}>
              {extractDomain(linkMetaData.url) || linkMetaData.url}
            </div>
            <div className={s.bbInlineLinkPreviewTitle}>{linkMetaData.title}</div>
            <div
              className={classNames(s.bbInlineLinkPreviewDescription, {
                [s.bbDescriptionWithImage]: !!linkMetaData.image,
              })}
            >
              {linkMetaData.description}
            </div>
            <div className={s.bbCloseIcon} onClick={clearLinkPreviews}>
              <CloseIcon />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkPreviewInline;
