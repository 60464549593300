import { action } from "typesafe-actions";
import {
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS,
  TIKTOK_GET_TRENDING_SONGS_FILTERS,
  TIKTOK_GET_TRENDING_SONGS_FILTERS_FAILURE,
  TIKTOK_GET_TRENDING_SONGS_FILTERS_SUCCESS,
  TIKTOK_GET_TRENDING_VIDEOS,
  TIKTOK_GET_TRENDING_VIDEOS_SUCCESS,
  TIKTOK_GET_TRENDING_VIDEOS_FAILURE,
  TIKTOK_GET_TRENDING_ITEMS_FAILURE,
  TIKTOK_GET_TRENDING_ITEMS,
  TIKTOK_GET_TRENDING_ITEMS_SUCCESS,
} from "./actionTypes";
import {
  IFilterValue,
  ITrendingItem,
  ITrendingFilters,
  ITrendingHashtagsVideoPayload,
  TrendingModeEnum,
} from "./types";

export const getTrendingItemsAction = (payload: IFilterValue, mode: TrendingModeEnum) =>
  action(TIKTOK_GET_TRENDING_ITEMS, { ...payload, mode });
export const getTrendingItemsSuccessAction = (data: ITrendingItem[], mode: TrendingModeEnum) =>
  action(TIKTOK_GET_TRENDING_ITEMS_SUCCESS, { data, mode });
export const getTrendingItemsFailureAction = () => action(TIKTOK_GET_TRENDING_ITEMS_FAILURE);

export const getTrendingHashtagsFiltersAction = () => action(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS);
export const getTrendingHashtagsFiltersSuccessAction = (data: ITrendingFilters) =>
  action(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS, data);
export const getTrendingHashtagsFiltersFailureAction = () =>
  action(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE);

export const getTrendingVideosAction = (
  ids: string[],
  filters: IFilterValue,
  mode: TrendingModeEnum,
) => action(TIKTOK_GET_TRENDING_VIDEOS, { ids, filters, mode });
export const getTrendingVideosSuccessAction = (data: {
  items: ITrendingHashtagsVideoPayload[];
  mode: TrendingModeEnum;
}) => action(TIKTOK_GET_TRENDING_VIDEOS_SUCCESS, data);
export const getTrendingVideosFailureAction = () => action(TIKTOK_GET_TRENDING_VIDEOS_FAILURE);

export const getTrendingSongsFiltersAction = () => action(TIKTOK_GET_TRENDING_SONGS_FILTERS);
export const getTrendingSongsFiltersSuccessAction = (data: ITrendingFilters) =>
  action(TIKTOK_GET_TRENDING_SONGS_FILTERS_SUCCESS, data);
export const getTrendingSongsFiltersFailureAction = () =>
  action(TIKTOK_GET_TRENDING_SONGS_FILTERS_FAILURE);
