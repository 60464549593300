import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useState } from "react";

import { Form } from "antd";
import { FormErrorMessage, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { TimeFrameTrigger } from "./TagWorkflowTriggerTimeFrame";
import { AllFiltersWorkflowTrigger } from "../allFiltersWorkflowTrigger";

import { meSelector } from "@store/me/selectors";

import s from "./TagWorkflowTrigger.module.less";

import { ITimeFrameTrigger } from "@store/workflows/types";
import { getDefaultTimeFrameValues, getOptions } from "./TagWorkflowTrigger.helpers";
import { ITagWorkflowTriggerProps } from "./TagWorkflowTrigger.types";

export const TagWorkflowTrigger: React.FC<ITagWorkflowTriggerProps> = ({
  value,
  form,
  hiddenFilters,
  onChange,
  validateFiltersValue,
  showFiltersErrorMessage,
  setShowFiltersErrorMessage,
}) => {
  const { t } = useTranslation();

  const [triggerType, setTriggerType] = useState(
    value?.time_frame ? "time_frame" : "filters_match",
  );

  const isTimeFrame = triggerType === "time_frame";
  const isFiltersMatch = triggerType === "filters_match";

  const me = useSelector(meSelector);

  const timeFrameValues = value?.time_frame || getDefaultTimeFrameValues(me?.timezone);

  const onTriggerTypeChange = (type: string) => {
    setTriggerType(type);

    if (type === "time_frame") {
      onChange?.({
        time_frame: timeFrameValues,
      });
      setShowFiltersErrorMessage(false);
    } else {
      onChange?.({ time_frame: undefined });
    }
  };

  const onTimeFrameChange = (time_frame: ITimeFrameTrigger) => onChange?.({ time_frame });

  return (
    <div className={s.bbTagTrigger}>
      <div className={s.bbTagTriggerType}>
        <span>{t("generic:when")}</span>
        <SelectStyled
          wrapperClassName={s.bbTagTriggerSelect}
          value={triggerType}
          onChange={onTriggerTypeChange}
          options={getOptions()}
        />
        {!isFiltersMatch && <span>{t("generic:is")}</span>}
      </div>
      {isTimeFrame && (
        <Form.Item
          name={["trigger", "time_frame"]}
          initialValue={timeFrameValues}
          className={s.bbTagTriggerFormItem}
        >
          <TimeFrameTrigger onChange={onTimeFrameChange} />
        </Form.Item>
      )}
      {isFiltersMatch && (
        <Form.Item
          name={["trigger", "filters"]}
          className={s.bbTagTriggerFormItem}
          rules={[
            {
              validator: (_, val) => {
                validateFiltersValue(val);
                return Promise.resolve();
              },
            },
          ]}
        >
          {showFiltersErrorMessage && (
            <FormErrorMessage>
              {t("pages:workflows:form:triggers:keywords:required")}
            </FormErrorMessage>
          )}
          <AllFiltersWorkflowTrigger
            form={form}
            workflowType="tag"
            hiddenFilters={hiddenFilters}
            additionalFilters={["regex_keyword"]}
          />
        </Form.Item>
      )}
    </div>
  );
};
