import React, { FC } from "react";

import s from "./SingleImagePreview.module.less";

import { ISingleImagePreviewProps } from "./types";

const SingleImagePreview: FC<ISingleImagePreviewProps> = ({ image, heightScalingPercent }) => {
  const paddingTop = image.isPortraitImage
    ? "110%"
    : heightScalingPercent
      ? `${heightScalingPercent}%`
      : "66.6667%";

  return (
    <div className={s.bbSingleImageContainer}>
      <div>
        <a className={s.bbImageLink}>
          <div className={s.bbMainWrapper}>
            <div className={s.bbImageDimensions}>
              <div className={s.bbImageRootWrapper} style={{ paddingTop: paddingTop }}>
                <div
                  className={s.bbImageWrapper}
                  style={{ height: "calc(100.04%)", top: "0%", width: "100%" }}
                >
                  <img
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    className={s.bbImage}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={s.bbBorders}></div>
          <div className={s.bbMainWrapperSupporter}></div>
        </a>
      </div>
    </div>
  );
};

export default SingleImagePreview;
