import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Help } from "@bbdevcrew/bb_ui_kit_fe";
import MultiImagePreview from "./MultiImagePreview";
import SingleImagePreview from "./SingleImagePreview";
import PostCard from "../../../insights/_common/postCard/PostCard";

import { IFBPostPreviewProps } from "./types";
import { analyzeImages, assignStyleToImages, assignPageTopPadding } from "./helpers";

import s from "./index.module.less";
import postStyles from "../index.module.less";

import VideoPlayerIcon from "@assets/VideoPlayer.svg";
import FbActions from "@assets/PostPreview/PostPreviewFbActions.svg";

export const FB_POST_CAPTION_MAX_LENGTH = 100;

const FBPostPreview: FC<IFBPostPreviewProps> = ({ post, images = [], video }) => {
  const { t } = useTranslation();

  const imagesToUse = analyzeImages(images);
  const totalNumberOfImages = images.length || 0;

  const windowPadding =
    totalNumberOfImages > 1
      ? assignPageTopPadding(imagesToUse.analyzedImages.length, imagesToUse.numberOfPortraits)
      : 0;
  const imagesWithStyles = assignStyleToImages(
    imagesToUse.numberOfPortraits,
    imagesToUse.analyzedImages,
  );

  const imagePreview = (
    <div className={s.bbImagePreviewWrapper} style={{ paddingTop: `${windowPadding}%` }}>
      {totalNumberOfImages === 1 ? (
        <SingleImagePreview image={imagesToUse.analyzedImages[0]} />
      ) : (
        <>
          {imagesWithStyles?.map((image, index) => (
            <MultiImagePreview
              image={image.image}
              style={image.styles}
              key={image.image.id}
              showExcess={index === 4 && totalNumberOfImages > 5}
              excessNumber={totalNumberOfImages - 5}
            />
          ))}
        </>
      )}
    </div>
  );

  const videoPreview = (
    <div className={s.bbVideoPlayerPreview}>
      <video width="282" poster={video?.poster}>
        <source src={video?.src} />
      </video>
      <VideoPlayerIcon />
    </div>
  );

  const FbImagePreview = (
    <>{imagesToUse.analyzedImages.length ? imagePreview : video?.src ? videoPreview : null}</>
  );

  if (!imagesToUse && !video?.src) return null;

  return (
    <>
      <div className={postStyles.bbPostCreationPreviewTitle}>
        {t("components:publish:postCreationModal:preview:title", {
          platform: t("generic:platforms:facebook"),
        })}
        <Help
          iconType="info"
          iconClassName={postStyles.bbPostPreviewInfo}
          tooltipProps={{
            title: t("components:publish:postCreationModal:preview:tooltip"),
          }}
        />
      </div>
      <div className={s.bbFbPostPreviewWrapper}>
        <div className={s.bbFbPostPreview}>
          <PostCard
            showMoreText
            message={post.message}
            maxMessageLength={FB_POST_CAPTION_MAX_LENGTH}
            postTypeText={t("generic:now")}
            className={s.bbPostPreviewCard}
            renderCardFooter={() => (
              <div className={s.bbFbPostPreviewFooter}>
                <FbActions />
              </div>
            )}
            pageImageProps={{
              pageName: post.asset.label,
              pageImage: post.asset.picture,
            }}
            postImageProps={{
              customImageRender: FbImagePreview,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FBPostPreview;
