import {
  getReplyCommentsSelector,
  replyCommentsNavigationIdSelector,
  replyCommentsCursorSelector,
  fetchingReplyCommentsSelector,
} from "@store/replies/selectors";
import {
  commentsSelector,
  commentsPageCursorSelector,
  commentsNavigationIdSelector,
  fetchingCommentsSelector,
  postCommentsSuccessfulSelector,
} from "@store/comments/selectors";
import { RootState } from "@store/index";
import { CommentsViewType } from "@bbdevcrew/bb_ui_kit_fe";

export const REPLY_MODAL_COMMENTS_FETCH_LIMIT = 200;

export const getCommentsSelector = (
  view: CommentsViewType,
  conversationListId?: string | undefined,
) => {
  const COMMENTS_SELECTORS_MAP = {
    all_comments: {
      data: (state: RootState) => commentsSelector(state, conversationListId),
      cursor: (state: RootState) => commentsPageCursorSelector(state, conversationListId),
      navigationId: (state: RootState) => commentsNavigationIdSelector(state, conversationListId),
      loading: (state: RootState) => fetchingCommentsSelector(state, conversationListId),
      fetched: (state: RootState) => postCommentsSuccessfulSelector(state, conversationListId),
    },
    comment_details: {
      data: (state: RootState) => getReplyCommentsSelector(state, conversationListId),
      cursor: (state: RootState) => replyCommentsCursorSelector(state, conversationListId),
      navigationId: (state: RootState) =>
        replyCommentsNavigationIdSelector(state, conversationListId),
      loading: (state: RootState) => fetchingReplyCommentsSelector(state, conversationListId),
      fetched: (state: RootState) => postCommentsSuccessfulSelector(state, conversationListId),
    },
  };

  return COMMENTS_SELECTORS_MAP[view];
};
