import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, ChangeEvent, useCallback, useContext } from "react";

import { Form, Grid } from "antd";
import { PostCreationUpload } from "./PostCreationUpload";
import LinkPreviewInline from "./linkPreview/LinkPreviewInline";
import { LocationSelect } from "../LocationSelect/LocationSelect";
import { PostCreationFormAIPrompts } from "./PostCreationAIPrompts";
import { RadioGroup, WarningIcon, TextareaWithEmoji } from "@bbdevcrew/bb_ui_kit_fe";

import { TTCreatorInfosSelector, isUrlPreviewRemovedSelector } from "@store/publishings/selectors";
import {
  captionsAIPromptSuggestionSelector,
  fetchedCaptionsAIPromptSuggestionSelector,
} from "@store/captionsAIPrompts/selectors";
import { meSelector } from "@store/me/selectors";
import { clearCaptionsAISuggestionAction } from "@store/captionsAIPrompts/actions";
import { clearUrlPreviewsAction, getUrlPreviewsAction } from "@store/publishings/actions";

import s from "./index.module.less";

import {
  isFB,
  isIG,
  isTT,
  isLI,
  isX,
  getMessageMaxLength,
  getMaxUploadImageCount,
  hasIGFileRatioError,
  validateVideoDuration,
  clearErrorsAfterFileRemove,
} from "./helpers/utilsValidations";
import {
  IFileUid,
  IFileLink,
  IPostItem,
  IPreviewPDFFile,
  IPreviewImageFile,
  IPreviewVideoFile,
  IPostCreationFormBodyProps,
  AllowedPlatformsForUploadType,
  IUploadFile,
} from "./helpers/types";
import { isPDF, isVideo } from "@bbdevcrew/bb_ui_kit_fe";
import { RcFile, UploadFile, UploadChangeParam } from "antd/lib/upload/interface";
import {
  LINK_REGEX,
  MAX_UPLOAD_VIDEO_DURATION,
  getPostCreationErrors,
  X_LINK_REGEX,
  YT_CHARS_REGEX,
} from "./helpers/constants";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import CalendarContext from "../calendar/context/CalendarContext";
import { IAutocompleteOption, isYoutubePlatform, PublishingType } from "@bbdevcrew/bb_ui_kit_fe";

export function PostCreationFormBody({
  form,
  post,
  updatePost,
  setIsFileUploading,
}: IPostCreationFormBodyProps) {
  const screens = Grid.useBreakpoint();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isMobile = screens.md === false;

  const [previewImages, setPreviewImages] = useState<IPreviewImageFile[]>(post.previewImageFiles);
  const [previewVideos, setPreviewVideos] = useState<IPreviewVideoFile[]>(post.previewVideoFiles);
  const [previewPDFs, setPreviewPDFs] = useState<IPreviewPDFFile[]>(post.previewPDFFiles);
  const [fileLinks, setFileLinks] = useState<IFileLink[]>([]);
  const [hasVideoDurationError, setHasVideoDurationError] = useState<boolean>(
    post.error === "videoDuration",
  );
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const me = useSelector(meSelector);
  const isUrlPreviewRemoved = useSelector(isUrlPreviewRemovedSelector);
  const AIPromptSuggestion = useSelector(captionsAIPromptSuggestionSelector);
  const fetchedAIPromptSuggestion = useSelector(fetchedCaptionsAIPromptSuggestionSelector);
  const ttCreatorInfoList = useSelector(TTCreatorInfosSelector);

  const { editingPost, isPostModalOpen } = useContext(CalendarContext);
  const isEditMode = isPostModalOpen && !!editingPost;
  const postError =
    post.error &&
    getPostCreationErrors(t)[post.asset.platform as AllowedPlatformsForUploadType][post.error];

  const clearUrlPreviews = useCallback(() => dispatch(clearUrlPreviewsAction()), [dispatch]);

  const getUrlPreviews = useCallback(
    (links: string[]) => dispatch(getUrlPreviewsAction(links)),
    [dispatch],
  );

  useEffect(() => {
    if (fetchedAIPromptSuggestion && AIPromptSuggestion) {
      const updatedPost = {
        ...post,
        message: AIPromptSuggestion,
        hashtagCount: isIG(post) ? AIPromptSuggestion.match(/#/g)?.length : post.hashtagCount,
      };

      updatePost(updatedPost, "message");
      form.setFieldsValue({ body: updatedPost.message });
    }

    return () => {
      dispatch(clearCaptionsAISuggestionAction());
    };
    // eslint-disable-next-line
  }, [fetchedAIPromptSuggestion, AIPromptSuggestion]);

  useEffect(() => {
    const updatedError = hasVideoDurationError ? "videoDuration" : undefined;

    if (post.error !== updatedError) {
      updatePost({
        ...post,
        error: updatedError,
      });
    }
    // eslint-disable-next-line
  }, [hasVideoDurationError]);

  const updateFilePreviewsAfterFileRemove = (fileList: UploadFile[], updatedPost: IPostItem) => {
    const newPost = { ...updatedPost };

    newPost.previewImageFiles = fileList
      .map(file => {
        return previewImages.find(
          previewImage => previewImage.uid === file.uid,
        ) as IPreviewImageFile;
      })
      .filter(f => !!f);

    newPost.previewVideoFiles = fileList
      .map(file => {
        return previewVideos.find(
          previewVideo => previewVideo.uid === file.uid,
        ) as IPreviewVideoFile;
      })
      .filter(f => !!f);

    newPost.previewPDFFiles = fileList
      .map(file => {
        return previewPDFs.find(previewPDF => previewPDF.uid === file.uid) as IPreviewPDFFile;
      })
      .filter(f => !!f);

    return newPost;
  };

  const handleFileChange = ({ fileList }: UploadChangeParam) => {
    const postCopy = {
      ...post,
      coverImage: undefined,
      coverNameLink: undefined,
    };

    let updatedPost: IPostItem = {
      ...postCopy,
      uploadedFiles: fileList,
      previewImageFiles: previewImages,
      previewVideoFiles: previewVideos,
      previewPDFFiles: previewPDFs,
    };

    const fileListDone = fileList.filter(({ status }) => status === "done");

    const fileHasBeenRemoved = isX(post)
      ? fileListDone.length < previewImages.length + previewVideos.length
      : fileListDone.length < previewImages.length ||
        fileListDone.length < previewVideos.length ||
        fileListDone.length < previewPDFs.length;
    const fileHasBeenAdded = !fileHasBeenRemoved;

    if (fileHasBeenRemoved) {
      updatedPost = updateFilePreviewsAfterFileRemove(fileList, updatedPost);
      updatedPost = clearErrorsAfterFileRemove(fileList, updatedPost);

      if (isTT(updatedPost) && hasVideoDurationError && updatedPost.error === "videoDuration") {
        setHasVideoDurationError(false);
        updatedPost.error = undefined;
      }

      updatePost(updatedPost);
    } else if (fileHasBeenAdded && hasIGFileRatioError(updatedPost)) {
      updatedPost.error = "fileRatio";
    }

    // Link preview shouldn't show if there are any files uploaded
    if (updatedPost.uploadedFiles.length && updatedPost.asset.preview_link)
      updatedPost.asset.preview_link = undefined;

    // Add the name_link property to the files for the API
    const files = (fileLinks?.length ? fileLinks : updatedPost.uploadedFiles) as (
      | IFileLink
      | IUploadFile
    )[];
    updatedPost.uploadedFiles = updatedPost.uploadedFiles.map(uploadedFile => ({
      ...uploadedFile,
      name_link: files.find(link => link.uid === uploadedFile.uid)?.name_link,
    }));

    updatePost(updatedPost);
  };

  const onImageUploadSuccess = (file: RcFile, image: HTMLImageElement) => {
    const maxUploadImageCount = getMaxUploadImageCount(post.asset.platform);

    if (post.previewImageFiles.length >= maxUploadImageCount) return;

    const previewImage = {
      ...file,
      src: image.src,
      width: image.width,
      height: image.height,
    };

    setPreviewImages(prevState => [...prevState, previewImage]);
  };

  const validateVideoDurationOnUpload = (file: IFileUid) => {
    const creatorInfo = ttCreatorInfoList.find(item => item.asset_id === post.asset.id);
    const maxVideoDuration =
      isTT(post) && creatorInfo
        ? creatorInfo.max_video_post_duration_sec
        : MAX_UPLOAD_VIDEO_DURATION[post.asset.platform as keyof typeof MAX_UPLOAD_VIDEO_DURATION];

    if (isTT(post) || isLI(post)) {
      validateVideoDuration(file, post.asset.platform, maxVideoDuration, setHasVideoDurationError);
    }
  };

  const onFileUploadSuccess = (file: IFileUid, fileNameLink: string) => {
    validateVideoDurationOnUpload(file);

    if (isVideo(file.type)) {
      const videoPreview = {
        ...file,
        object_src: URL.createObjectURL(file),
      };

      setPreviewVideos(prevState => [...prevState, videoPreview]);
    } else if (isPDF(file.type)) {
      const pdfPreview = {
        ...file,
        src: URL.createObjectURL(file),
      };

      setPreviewPDFs(prevState => [...prevState, pdfPreview]);
    }

    setFileLinks(prevLinks => [
      ...prevLinks,
      { uid: file.uid, name_link: fileNameLink } as IFileLink,
    ]);
  };

  const onRemoveFileClick = (removedFile: UploadFile) => {
    const updatedPost = {
      ...post,
      coverImage: undefined,
      coverNameLink: undefined,
    };

    const newUploadedFiles = post.uploadedFiles.filter(file => file.uid !== removedFile.uid);
    const newUploadedFileLinks = fileLinks.filter(fileLink => fileLink.uid !== removedFile.name);
    setFileLinks(newUploadedFileLinks);

    if (post.previewImageFiles.length) {
      updatedPost.previewImageFiles = updatedPost.previewImageFiles.filter(
        file => file.uid !== removedFile.uid,
      );
      setPreviewImages(updatedPost.previewImageFiles);
    }

    if (post.previewVideoFiles.length) {
      updatedPost.previewVideoFiles = updatedPost.previewVideoFiles.filter(
        file => file.uid !== removedFile.uid,
      );
      setPreviewVideos(updatedPost.previewVideoFiles);
    }

    if (post.previewPDFFiles.length) {
      updatedPost.previewPDFFiles = updatedPost.previewPDFFiles.filter(
        file => file.uid !== removedFile.uid,
      );
      setPreviewPDFs(updatedPost.previewPDFFiles);
    }

    updatedPost.uploadedFiles = newUploadedFiles;

    updatePost(updatedPost);
  };

  const validateHashtagCount = (value: string) => {
    if (isIG(post)) updatePost({ ...post, hashtagCount: value.match(/#/g)?.length });
  };

  const onMessageChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    const messageText = target.value;

    validateHashtagCount(messageText);

    const links = messageText.match(isX(post) ? X_LINK_REGEX : LINK_REGEX);
    const lastLink = links?.at(-1);
    if (!lastLink) {
      clearUrlPreviews();
    }

    if (
      lastLink &&
      lastLink !== post.asset.preview_link?.url &&
      !post.uploadedFiles.length &&
      !isUrlPreviewRemoved
    ) {
      getUrlPreviews([lastLink]);
    }

    const updatedPost = {
      ...post,
      message: messageText,
      hashtagCount: isIG(post) ? messageText.match(/#/g)?.length : post.hashtagCount,
    };

    updatePost(updatedPost, "message");
    form.setFieldsValue({ body: updatedPost.message });
  };

  const onPublishingTypeChange = (value: string) => {
    const updatedPost = { ...post, publishing_type: value as PublishingType };
    updatePost(updatedPost, "publishing_type");
  };

  const onLocationChange = (location?: IAutocompleteOption) => {
    updatePost({
      ...post,
      location,
    });
  };

  const validateMessageBody = (_: RuleObject, value: StoreValue) => {
    // Normalize all whitespaces/tabs/newlines to single spaces and trim
    const normalizedValue = value.replace(/\s+/g, " ").trim();

    if (
      isYoutubePlatform(post.asset?.platform) &&
      normalizedValue.trim() &&
      !YT_CHARS_REGEX.test(normalizedValue)
    ) {
      return Promise.reject(
        new Error(
          getPostCreationErrors(t)[
            post.asset.platform as AllowedPlatformsForUploadType
          ].descriptionText,
        ),
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (isEditMode && isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }

    handleFileChange({ fileList: post.uploadedFiles } as UploadChangeParam);
  }, [previewVideos, previewImages, previewPDFs, fileLinks]); // eslint-disable-line

  return (
    <>
      {isIG(post) && (
        <RadioGroup
          value={post.publishing_type}
          className={s.bbPostCreationBodyRadioGroup}
          onChange={onPublishingTypeChange}
        >
          <RadioGroup.Button _size="sm" value={"POST"}>
            {t("generic:post")}
          </RadioGroup.Button>
          <RadioGroup.Button _size="sm" value={"STORY"}>
            {t("components:publish:story:title")}
          </RadioGroup.Button>
        </RadioGroup>
      )}

      {post.publishing_type === "POST" && (
        <Form.Item
          name="body"
          className={s.bbPostCreationBodySection}
          rules={[
            () => ({
              validator: validateMessageBody,
            }),
          ]}
        >
          <TextareaWithEmoji
            noEmojiPicker={isMobile}
            message={post.message}
            hashtagCount={post.hashtagCount}
            className={s.bbCaptionInput}
            onChange={onMessageChange}
            maxLength={getMessageMaxLength(post.asset?.platform)}
            placeholder={t("components:publish:postCreationModal:message:placeholder")}
            additionalAction={
              <>
                {isIG(post) && me?.client?.publishing_location_enabled && (
                  <LocationSelect
                    initialValue={post?.location}
                    pageIdentifier={post.asset.id}
                    onChange={onLocationChange}
                  />
                )}
                <PostCreationFormAIPrompts topic={post.message} />
              </>
            }
          />
        </Form.Item>
      )}

      {(isFB(post) || isLI(post) || isX(post)) && !post.uploadedFiles.length && (
        <LinkPreviewInline />
      )}

      <PostCreationUpload
        post={post}
        updatePost={updatePost}
        handleFileChange={handleFileChange}
        onRemoveFileClick={onRemoveFileClick}
        onFileUploadSuccess={onFileUploadSuccess}
        onImageUploadSuccess={onImageUploadSuccess}
        selectedPlatform={post.asset.platform || ""}
        setIsFileUploading={setIsFileUploading}
      />

      {!!postError && (
        <div className={s.bbMediaError}>
          <WarningIcon />
          <span className={s.bbPublishingDisclaimer}>{postError}</span>
        </div>
      )}

      {post.publishing_type === "STORY" && (
        <div className={s.bbMediaDisclaimer}>
          <WarningIcon />
          <span className={s.bbPublishingDisclaimer}>
            {t("components:publish:story:ratioDisclaimer")}
          </span>
        </div>
      )}
    </>
  );
}
