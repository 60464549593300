import React, { Ref, forwardRef } from "react";

import s from "./ReelView.module.less";

import { IReelViewProps } from "./ReelView.types";

import { PlayIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ReelView = forwardRef<HTMLImageElement | HTMLVideoElement, IReelViewProps>(
  ({ src, isCover = false }, ref) => {
    return (
      <div className={s.bbReelView}>
        <i className={s.bbReelViewIcon}>
          <PlayIcon />
        </i>
        {isCover ? (
          <img src={src} ref={ref as Ref<HTMLImageElement>} />
        ) : (
          <video src={src} ref={ref as Ref<HTMLVideoElement>} controls={false} />
        )}
      </div>
    );
  },
);

ReelView.displayName = "ReelView";
