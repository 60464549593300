import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import AlertsTable from "./AlertsTable";
import Topics from "./topics";
import Reviews from "./reviews";
import Sources from "./sources";
import Overview from "./overview";
import SentimentDetails from "./sentimentDetails";
import ConversationList from "./conversationList";
import AgentPerformance from "./agentPerformance";
import Posts from "./posts/Posts";
import TiktokTrending from "./trending";
import Compare from "./compare/Compare";
import Profiles from "./profiles";

import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import s from "./index.module.less";

import { IAvailableSectionsType, IInsightsModuleFeatureType } from "@store/modules/types";
import { getCurrentModuleName } from "@utils/paths";

const Insights: React.FC = () => {
  const [searchParams] = useSearchParams();
  const conversationListId = searchParams.get("section") as IInsightsModuleFeatureType;

  const moduleId = getCurrentModuleName();

  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[moduleId as IAvailableSectionsType];
  const nonAvailableFeatures = sections
    ?.flatMap(section => section.features || [])
    .filter(feature => !feature.is_visible || feature.is_locked)
    .map(feature => feature.id);

  const isFeatureAvailable = (featureName: IInsightsModuleFeatureType) =>
    !nonAvailableFeatures?.includes(featureName);

  return (
    <div className={s.bbListenContainer}>
      {isFeatureAvailable("listen-overview") && conversationListId === "listen-overview" && (
        <Overview />
      )}
      {isFeatureAvailable("listen-sentiment_details") &&
        conversationListId === "listen-sentiment_details" && <SentimentDetails />}
      {isFeatureAvailable("topics") && conversationListId === "topics" && <Topics />}
      {isFeatureAvailable("listen-reviews") && conversationListId === "listen-reviews" && (
        <Reviews />
      )}
      {isFeatureAvailable("listen-agent_performance") &&
        conversationListId === "listen-agent_performance" && <AgentPerformance />}
      {isFeatureAvailable("listen-conversation_list") &&
        conversationListId === "listen-conversation_list" && <ConversationList />}
      {isFeatureAvailable("listen_more-trending_hashtags") &&
        conversationListId === "listen_more-trending_hashtags" && <TiktokTrending />}
      {isFeatureAvailable("listen_more-listening_sources") &&
        conversationListId === "listen_more-listening_sources" && <Sources />}
      {isFeatureAvailable("report-posts") && conversationListId === "report-posts" && <Posts />}
      {isFeatureAvailable("report-compare") && conversationListId === "report-compare" && (
        <Compare />
      )}
      {isFeatureAvailable("report-profiles") && conversationListId === "report-profiles" && (
        <Profiles />
      )}
      {isFeatureAvailable("report-alerts") && conversationListId === "report-alerts" && (
        <AlertsTable />
      )}
    </div>
  );
};

export default Insights;
