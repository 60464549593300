import { action, createAction } from "typesafe-actions";
import {
  GET_POST_TAGS,
  GET_POST_TAGS_SUCCESS,
  GET_POST_TAGS_FAILURE,
  ADD_POST_TAG,
  ADD_POST_TAG_SUCCESS,
  ADD_POST_TAG_FAILURE,
  DELETE_POST_TAG,
  DELETE_POST_TAG_SUCCESS,
  DELETE_POST_TAG_FAILURE,
  DELETE_TAG_FROM_POST,
  DELETE_TAG_FROM_POST_SUCCESS,
  DELETE_TAG_FROM_POST_FAILURE,
  GET_POST_TAGS_FOR_FILTER,
  GET_POST_TAGS_FOR_FILTER_SUCCESS,
  GET_POST_TAGS_FOR_FILTER_FAILURE,
} from "./actionTypes";
import { ICreatePostTagPayload, IDeletePostTagPayload, IPostTagsForFilterResponse } from "./types";
import { IPostTag } from "@bbdevcrew/bb_ui_kit_fe";

export const getPostTagsAction = () => action(GET_POST_TAGS);
export const getPostTagsSuccessAction = (data: { items: IPostTag[] }) =>
  action(GET_POST_TAGS_SUCCESS, data);
export const getPostTagsFailureAction = () => action(GET_POST_TAGS_FAILURE);

export const getPostTagsForFilterAction = createAction(GET_POST_TAGS_FOR_FILTER)();
export const getPostTagsForFilterSuccessAction = createAction(
  GET_POST_TAGS_FOR_FILTER_SUCCESS,
)<IPostTagsForFilterResponse>();
export const getPostTagsForFilterFailureAction = createAction(
  GET_POST_TAGS_FOR_FILTER_FAILURE,
)<void>();

export const createPostTagAction = (data: ICreatePostTagPayload) => action(ADD_POST_TAG, data);
export const createPostTagSuccessAction = (data: IPostTag, postId: string) =>
  action(ADD_POST_TAG_SUCCESS, { tag: data, postId });
export const createPostTagFailureAction = (postId?: string) => action(ADD_POST_TAG_FAILURE, postId);

export const deletePostTagAction = (id: string) => action(DELETE_POST_TAG, id);
export const deletePostTagSuccessAction = (id: string) => action(DELETE_POST_TAG_SUCCESS, id);
export const deletePostTagFailureAction = () => action(DELETE_POST_TAG_FAILURE);

export const deleteTagFromPostAction = (payload: IDeletePostTagPayload) =>
  action(DELETE_TAG_FROM_POST, payload);
export const deleteTagFromPostSuccessAction = (payload: IDeletePostTagPayload) =>
  action(DELETE_TAG_FROM_POST_SUCCESS, payload);
export const deleteTagFromPostFailureAction = () => action(DELETE_TAG_FROM_POST_FAILURE);
