import React from "react";
import classNames from "classnames/bind";

import { Form } from "antd";
import { Tooltip, Checkbox } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TTDiscloseContentOptions.module.less";

import { formItemNoMargin } from "./helpers";
import { ITTInteractionAbilityCheckboxProps } from "./types";

export const TTInteractionAbilityCheckbox = ({
  onChange,
  name,
  label,
  checked,
  disabled,
  tooltipText,
}: ITTInteractionAbilityCheckboxProps) => (
  <Form.Item style={formItemNoMargin}>
    <Tooltip title={tooltipText}>
      <Checkbox
        id={name}
        _size="md"
        checked={checked}
        disabled={disabled}
        onChange={e => onChange(e, name)}
      />
      <label
        className={classNames(s.bbInteractionAbilityLabel, {
          [s.bbInteractionAbilityLabelDisabled]: disabled,
        })}
      >
        {label}
      </label>
    </Tooltip>
  </Form.Item>
);
