import { EmailAlertRangeEnum } from "@store/workflows/types";
import { TFunction } from "i18next";
import { weekdays } from "@components/_common/weekdaysSelect/helpers";

export const getRangeOptions = (count: number, t: TFunction) => [
  {
    id: EmailAlertRangeEnum.HOUR,
    label: t("components:notificationsPanel:modals:create:form:threshold:hour", {
      count,
    }),
  },
  {
    id: EmailAlertRangeEnum.DAY,
    label: t("components:notificationsPanel:modals:create:form:threshold:day", {
      count,
    }),
  },
  {
    id: EmailAlertRangeEnum.WEEK,
    label: t("components:notificationsPanel:modals:create:form:threshold:week", {
      count,
    }),
  },
  {
    id: EmailAlertRangeEnum.MONTH,
    label: t("components:notificationsPanel:modals:create:form:threshold:month", {
      count,
    }),
  },
];

export const defaultSelectedDays = weekdays.filter(day => day !== "Saturday" && day !== "Sunday");

export const timeOptions = Array.from({ length: 12 }, (_, i) => ({
  id: String(i + 1),
  label: String(i + 1),
}));
