import { createAction } from "typesafe-actions";
import {
  GET_AI_HIGHLIGHTS_SUCCESS,
  GET_AI_HIGHLIGHTS_FAILURE,
  GET_AI_HIGHLIGHTS,
  RESET_AI_HIGHLIGHTS,
  SET_WIDGET_STATE,
} from "./actionTypes";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { IAIHighlightsResponse, WidgetState } from "./types";

export const getAiHighlightsAction = createAction(GET_AI_HIGHLIGHTS)<{
  filters: IFilters;
  refresh?: boolean;
  promptId?: string;
}>();
export const getAiHighlightsSuccess =
  createAction(GET_AI_HIGHLIGHTS_SUCCESS)<IAIHighlightsResponse>();
export const getAiHighlightsFailure = createAction(GET_AI_HIGHLIGHTS_FAILURE)();

export const resetAiHighlightsAction = createAction(RESET_AI_HIGHLIGHTS)();

export const setWidgetStateAction = createAction(SET_WIDGET_STATE)<WidgetState>();
