export const SCHEDULED_REPORT_TYPES = ["daily", "weekly", "monthly", "quarterly"];

export const generateTimeOptions = () => {
  const timeOptions = [];
  for (let hour = 1; hour <= 12; hour++) {
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    timeOptions.push({
      id: `${formattedHour}:00`,
      label: `${hour}`,
    });
  }
  return timeOptions;
};
