import React, { FC } from "react";

import AutomatedHidingMenuItem from "./MenuItem";

import { IHidingConfigItem } from "@store/hidingConfig/types";

interface IAutomatedHidingClassifiersProps {
  disabled: boolean;
  selectedClassifiers: string[];
  classifiers: IHidingConfigItem[];
  onClassifierToggleClick: (classifier: string) => void;
}

export const AutomatedHidingClassifiers: FC<IAutomatedHidingClassifiersProps> = ({
  disabled,
  classifiers,
  selectedClassifiers,
  onClassifierToggleClick,
}) => {
  return (
    <>
      {classifiers?.map(({ id, label }) => {
        const onClassifierClick = () => {
          !disabled && onClassifierToggleClick(id);
        };

        const isKeywordBlocker = id === "keyword_blocker";

        const props = {
          id,
          label,
          disabled: disabled,
          checked: selectedClassifiers.includes(id),
          showAIChip: !isKeywordBlocker,
          onChange: onClassifierClick,
        };

        return <AutomatedHidingMenuItem key={id} {...props} />;
      })}
    </>
  );
};

export default AutomatedHidingClassifiers;
