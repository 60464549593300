import React, { FC } from "react";

import { Help, numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCardPill } from "../performanceCard/Pill";

import s from "./CommentsChart.module.less";

import { ICommentsChartProps } from "./CommentsChart.types";

const CommentsChart: FC<ICommentsChartProps> = ({
  width,
  data,
  legendItems,
  withAxes = true,
  shortName = false,
  innerLegend = false,
  sentimentType = "total",
  showCalculatedPercentage = true,
  showPerformancePill = true,
}) => {
  const getPercentageWidth = (percentage: number) => (width * percentage) / 100;

  const calculateRelativeWidth = (currentValue: number, maxWidth: number) => {
    const maxValue = Math.max(...data.map(item => item.count));

    return (currentValue * maxWidth) / maxValue;
  };

  const legend = (
    <div className={s.bbCommentsChartLegend}>
      {legendItems?.map(({ id, label, color, tooltipProps }) => (
        <div className={s.bbLegendItem} key={id}>
          <div
            className={s.bbLegendItemMark}
            style={{
              background: color,
            }}
          ></div>
          <div className={s.bbLegendItemName}>{label}</div>
          {tooltipProps?.title && <Help tooltipProps={tooltipProps} />}
        </div>
      ))}
    </div>
  );

  return (
    <div className={s.bbCommentsWrapper}>
      {data.map((item, index) => (
        <div key={index} className={s.bbCommentsChartItem}>
          {withAxes && (
            <div
              className={s.bbCommentsChartName}
              style={{ width: getPercentageWidth(shortName ? 10 : 20) }}
            >
              {item.name}
            </div>
          )}
          <div
            className={s.bbCommentsChartBar}
            style={{
              backgroundColor: item.color,
              width: calculateRelativeWidth(item.count, getPercentageWidth(shortName ? 60 : 50)),
            }}
          ></div>
          <div
            style={{ width: getPercentageWidth(withAxes ? 30 : 50) }}
            className={s.bbCommentsChartLabel}
          >
            <span className={s.bbCommentsChartValue}>
              {item.count_pretty || numberFormatter(item.count)}
            </span>
            {showCalculatedPercentage && item?.percentage_of_total !== undefined && (
              <span className={s.bbCommentsChartPercentage}>
                ({item?.percentage_of_total || 0}%)
              </span>
            )}
            {showPerformancePill && (
              <PerformanceCardPill
                type={sentimentType}
                percentageValue={item?.change_percentage || 0}
              />
            )}
          </div>
        </div>
      ))}
      <div className={s.bbInnerLegendWrapper}>
        {withAxes && innerLegend && (
          <div style={{ width: getPercentageWidth(shortName ? 10 : 20) }} />
        )}
        {!!legendItems?.length && (
          <div style={{ width: innerLegend ? getPercentageWidth(80) : "auto" }}>{legend}</div>
        )}
      </div>
    </div>
  );
};

export default CommentsChart;
