import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import WorkflowCreationModal from "@components/control-panel/workflows/creationModal/WorkflowCreationModal"; // eslint-disable-line max-len

import s from "./AlertsTable.module.less";

import { useWorkflowModal } from "@utils/useWorkflowModal";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const EmptyState: React.FC = () => {
  const { t } = useTranslation();
  const { workflowModalOpen, selectedWorkflow, openCreateModal, closeModal } = useWorkflowModal();

  return (
    <>
      <div className={s.bbEmptyState}>
        <h2 className={s.bbEmptyStateTitle}>{t("components:listen:alerts:emptyState:title")}</h2>
        <p className={s.bbEmptyStateDescription}>
          {t("components:listen:alerts:emptyState:description")}
        </p>
        <Button
          _size="md"
          _type="primary"
          className={s.bbEmptyStateButton}
          onClick={openCreateModal}
        >
          <PlusIcon />
          {t("components:listen:alerts:emptyState:buttonText")}
        </Button>
      </div>
      <Modal
        open={workflowModalOpen}
        onCancel={closeModal}
        width={800}
        centered
        noPadding
        responsive
        hideFooter
        hideHeader
      >
        <WorkflowCreationModal item={selectedWorkflow} workflowType="email_alert" />
      </Modal>
    </>
  );
};

export default EmptyState;
