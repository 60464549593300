import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError, first } from "rxjs/operators";

import { UserActionsType } from "./types";
import { RootState } from "..";
import { GET_USERS, POST_USER, PUT_USER, PATCH_USER_STATUS } from "./actionTypes";
import {
  getUsersFailureAction,
  getUsersSuccessAction,
  patchUserStatusFailureAction,
  patchUserStatusSuccessAction,
  postUserFailureAction,
  postUserSuccessAction,
  putUserFailureAction,
  putUserSuccessAction,
} from "./actions";

import { users } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { IUser, handleError, generateQueryParams } from "@bbdevcrew/bb_ui_kit_fe";

const normalizeUserResponse = (usersResponse: IUser[], loggedInUserId?: string) => {
  return usersResponse.map(user => {
    if (loggedInUserId === user.id) {
      return {
        ...user,
        display_name: "Me",
      };
    }

    return { ...user, display_name: `${user.first_name} ${user.last_name}` };
  });
};

// GET USERS
export const getUsersEpic = (
  action$: Observable<UserActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_USERS)),
    first(),
    switchMap(a => {
      return ajax
        .get<{ items: IUser[] }>(
          `${users}${generateQueryParams(a.payload)}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            getUsersSuccessAction(normalizeUserResponse(data.items, state$.value.me.data?.id)),
          ),
          catchError(e => handleError(e, getUsersFailureAction)),
        );
    }),
  );

// PUT USER
export const putUserEpic = (
  action$: Observable<UserActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PUT_USER)),
    switchMap(a => {
      return ajax
        .put<IUser>(
          `${users}/${a.payload.id}`,
          {
            ...a.payload,
            id: undefined,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => putUserSuccessAction(data)),
          catchError(e => handleError(e, putUserFailureAction)),
        );
    }),
  );

// PATCH USER STATUS
export const patchUserStatus = (
  action$: Observable<UserActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PATCH_USER_STATUS)),
    switchMap(a => {
      return ajax
        .patch<IUser>(
          `${users}/${a.payload.id}/is_disabled`,
          {
            is_disabled: a.payload.status === true ? false : true,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => patchUserStatusSuccessAction(data)),
          catchError(e => handleError(e, patchUserStatusFailureAction)),
        );
    }),
  );

// POST USER
export const postUser = (
  action$: Observable<UserActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_USER)),
    switchMap(a => {
      return ajax
        .post<IUser>(
          `${users}`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => {
            return [postUserSuccessAction(data)];
          }),
          catchError(e => handleError(e, postUserFailureAction)),
        );
    }),
  );
