import { createReducer, ActionType } from "typesafe-actions";

import { ITiktokState } from "./types";
import {
  getTrendingHashtagsFiltersSuccessAction,
  getTrendingItemsSuccessAction,
  getTrendingVideosSuccessAction,
} from "./actions";
import {
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE,
  TIKTOK_GET_TRENDING_SONGS_FILTERS,
  TIKTOK_GET_TRENDING_SONGS_FILTERS_FAILURE,
  TIKTOK_GET_TRENDING_SONGS_FILTERS_SUCCESS,
  TIKTOK_GET_TRENDING_VIDEOS,
  TIKTOK_GET_TRENDING_VIDEOS_FAILURE,
  TIKTOK_GET_TRENDING_VIDEOS_SUCCESS,
  TIKTOK_GET_TRENDING_ITEMS,
  TIKTOK_GET_TRENDING_ITEMS_SUCCESS,
  TIKTOK_GET_TRENDING_ITEMS_FAILURE,
} from "./actionTypes";

const initialState = {
  trendingHashtags: [],
  fetchingTrending: false,
  fetchingTrendingVideos: false,
  fetchedTrending: false,
  fetchedTrendingFail: false,

  trendingHashtagsFilters: {
    industries: [],
    countries: [],
    dateRangeOptions: [],
    genres: [],
  },
  fetchingTrendingHashtagsFilters: false,
  fetchedTrendingHashtagsFilters: false,
  fetchedTrendingHashtagsFiltersFail: false,

  trendingSongs: [],
  trendingSongsFilters: {
    industries: [],
    countries: [],
    dateRangeOptions: [],
    genres: [],
  },
  fetchingTrendingSongsFilters: false,
  fetchedTrendingSongsFilters: false,
  fetchedTrendingSongsFiltersFail: false,
};

export const tiktokHashtagsReducer = createReducer<ITiktokState>(initialState, {
  [TIKTOK_GET_TRENDING_ITEMS]: (state: ITiktokState) => ({
    ...state,
    fetchingTrending: true,
    fetchedTrending: false,
    fetchedTrendingFail: false,
  }),
  [TIKTOK_GET_TRENDING_ITEMS_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof getTrendingItemsSuccessAction>,
  ) => {
    const { mode, data } = action.payload;
    return {
      ...state,
      ...(mode === "hashtags"
        ? {
            trendingHashtags: data,
          }
        : mode === "songs"
          ? {
              trendingSongs: data,
            }
          : {}),
      fetchingTrending: false,
      fetchedTrending: true,
      fetchedTrendingFail: false,
    };
  },
  [TIKTOK_GET_TRENDING_VIDEOS]: (state: ITiktokState) => ({
    ...state,
    fetchingTrendingVideos: true,
  }),
  [TIKTOK_GET_TRENDING_VIDEOS_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof getTrendingVideosSuccessAction>,
  ) => {
    const { mode, items } = action.payload;

    return {
      ...state,
      ...(mode === "hashtags"
        ? {
            trendingHashtags: state.trendingHashtags.map(hashtag => ({
              ...hashtag,
              videos: items.find(item => item.id === hashtag.id)?.items || [],
            })),
          }
        : mode === "songs"
          ? {
              trendingSongs: state.trendingSongs.map(song => ({
                ...song,
                videos: items.find(item => item.id === song.id)?.items || [],
              })),
            }
          : {}),

      fetchingTrendingVideos: false,
    };
  },
  [TIKTOK_GET_TRENDING_VIDEOS_FAILURE]: (state: ITiktokState) => ({
    ...state,
    fetchingTrendingVideos: false,
  }),
  [TIKTOK_GET_TRENDING_ITEMS_FAILURE]: (state: ITiktokState) => ({
    ...state,
    trendingHashtags: [],
    fetchingTrending: false,
    fetchedTrending: false,
    fetchedTrendingFail: true,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_FILTERS]: (state: ITiktokState) => ({
    ...state,
    fetchingTrendingHashtagsFilters: true,
    fetchedTrendingHashtagsFilters: false,
    fetchedTrendingHashtagsFiltersFail: false,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof getTrendingHashtagsFiltersSuccessAction>,
  ) => ({
    ...state,
    trendingHashtagsFilters: action.payload,
    fetchingTrendingHashtagsFilters: false,
    fetchedTrendingHashtagsFilters: true,
    fetchedTrendingHashtagsFiltersFail: false,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE]: (state: ITiktokState) => ({
    ...state,
    fetchingTrendingHashtagsFilters: false,
    fetchedTrendingHashtagsFilters: false,
    fetchedTrendingHashtagsFiltersFail: true,
  }),
  [TIKTOK_GET_TRENDING_SONGS_FILTERS]: (state: ITiktokState) => ({
    ...state,
    fetchingTrendingSongsFilters: true,
    fetchedTrendingSongsFilters: false,
    fetchedTrendingSongsFiltersFail: false,
  }),
  [TIKTOK_GET_TRENDING_SONGS_FILTERS_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof getTrendingHashtagsFiltersSuccessAction>,
  ) => ({
    ...state,
    trendingSongsFilters: action.payload,
    fetchingTrendingSongsFilters: false,
    fetchedTrendingSongsFilters: true,
    fetchedTrendingSongsFiltersFail: false,
  }),
  [TIKTOK_GET_TRENDING_SONGS_FILTERS_FAILURE]: (state: ITiktokState) => ({
    ...state,
    trendingSongsFilters: {
      industries: [
        {
          id: "ALL",
          label: "All industries",
        },
        {
          id: "VEHICLE_AND_TRANSPORTATION",
          label: "Vehicle and transportation",
          emoji: "🚗",
          subIndustries: [
            {
              id: "AUTOMOTIVE",
              label: "Automotive",
            },
            {
              id: "OTHER_TRANSPORTATION",
              label: "Other transportation",
            },
          ],
        },
      ],
      countries: [
        {
          id: "AD",
          label: "Andorra",
        },
        {
          id: "AE",
          label: "United Arab Emirates",
        },
        {
          id: "AF",
          label: "Afghanistan",
        },
        {
          id: "AG",
          label: "Antigua and Barbuda",
        },
        {
          id: "AI",
          label: "Anguilla",
        },
        {
          id: "AL",
          label: "Albania",
        },
        {
          id: "AM",
          label: "Armenia",
        },
        {
          id: "AO",
          label: "Angola",
        },
        {
          id: "AQ",
          label: "Antarctica",
        },
        {
          id: "AR",
          label: "Argentina",
        },
        {
          id: "AS",
          label: "American Samoa",
        },
        {
          id: "AT",
          label: "Austria",
        },
        {
          id: "AU",
          label: "Australia",
        },
        {
          id: "AW",
          label: "Aruba",
        },
        {
          id: "AX",
          label: "Aland",
        },
        {
          id: "AZ",
          label: "Azerbaijan",
        },
        {
          id: "BA",
          label: "Bosnia & Herzegovina",
        },
        {
          id: "BB",
          label: "Barbados",
        },
        {
          id: "BD",
          label: "Bangladesh",
        },
        {
          id: "BE",
          label: "Belgium",
        },
        {
          id: "BF",
          label: "Burkina Faso",
        },
        {
          id: "BG",
          label: "Bulgaria",
        },
        {
          id: "BH",
          label: "Bahrain",
        },
        {
          id: "BI",
          label: "Burundi",
        },
        {
          id: "BJ",
          label: "Benin",
        },
        {
          id: "BL",
          label: "Saint Barthélemy",
        },
        {
          id: "BM",
          label: "Bermuda",
        },
        {
          id: "BN",
          label: "Brunei",
        },
        {
          id: "BO",
          label: "Bolivia",
        },
        {
          id: "BQ",
          label: "Caribbean Netherlands",
        },
        {
          id: "BR",
          label: "Brazil",
        },
        {
          id: "BS",
          label: "Commonwealth of the Bahamas",
        },
        {
          id: "BT",
          label: "Bhutan",
        },
        {
          id: "BV",
          label: "Bouvet Island",
        },
        {
          id: "BW",
          label: "Botswana",
        },
        {
          id: "BY",
          label: "Belarus",
        },
        {
          id: "BZ",
          label: "Belize",
        },
        {
          id: "CA",
          label: "Canada",
        },
        {
          id: "CC",
          label: "Cocos (Keeling) Islands",
        },
        {
          id: "CD",
          label: "Democratic Republic of the Congo",
        },
        {
          id: "CF",
          label: "Central African Republic",
        },
        {
          id: "CG",
          label: "Republic of the Congo",
        },
        {
          id: "CH",
          label: "Switzerland",
        },
        {
          id: "CI",
          label: "Cote D'Ivoire",
        },
        {
          id: "CK",
          label: "Cook Islands",
        },
        {
          id: "CL",
          label: "Chile",
        },
        {
          id: "CM",
          label: "Cameroon",
        },
        {
          id: "CN",
          label: "the Chinese mainland",
        },
        {
          id: "CO",
          label: "Colombia",
        },
        {
          id: "CR",
          label: "Costa Rica",
        },
        {
          id: "CU",
          label: "Cuba",
        },
        {
          id: "CV",
          label: "Cape Verde",
        },
        {
          id: "CX",
          label: "Christmas Island",
        },
        {
          id: "CY",
          label: "Cyprus",
        },
        {
          id: "CZ",
          label: "Czech Republic",
        },
        {
          id: "DE",
          label: "Germany",
        },
        {
          id: "DJ",
          label: "Djibouti",
        },
        {
          id: "DK",
          label: "Denmark",
        },
        {
          id: "DM",
          label: "Dominica",
        },
        {
          id: "DO",
          label: "Dominican Republic",
        },
        {
          id: "DZ",
          label: "Algeria",
        },
        {
          id: "EC",
          label: "Ecuador",
        },
        {
          id: "EE",
          label: "Estonia",
        },
        {
          id: "EG",
          label: "Egypt",
        },
        {
          id: "EH",
          label: "Western Sahara",
        },
        {
          id: "ER",
          label: "Eritrea",
        },
        {
          id: "ES",
          label: "Spain",
        },
        {
          id: "ET",
          label: "Ethiopia",
        },
        {
          id: "FI",
          label: "Finland",
        },
        {
          id: "FJ",
          label: "Fiji",
        },
        {
          id: "FK",
          label: "Falkland Islands",
        },
        {
          id: "FM",
          label: "Micronesia",
        },
        {
          id: "FO",
          label: "Faroe Islands",
        },
        {
          id: "FR",
          label: "France",
        },
        {
          id: "GA",
          label: "Gabon",
        },
        {
          id: "GB",
          label: "United Kingdom",
        },
        {
          id: "GD",
          label: "Grenada",
        },
        {
          id: "GE",
          label: "Georgia",
        },
        {
          id: "GF",
          label: "French Guiana",
        },
        {
          id: "GG",
          label: "Guernsey",
        },
        {
          id: "GH",
          label: "Ghana",
        },
        {
          id: "GI",
          label: "Gibraltar",
        },
        {
          id: "GL",
          label: "Greenland",
        },
        {
          id: "GM",
          label: "Gambia",
        },
        {
          id: "GN",
          label: "Guinea",
        },
        {
          id: "GP",
          label: "Guadeloupe",
        },
        {
          id: "GQ",
          label: "Equatorial Guinea",
        },
        {
          id: "GR",
          label: "Greece",
        },
        {
          id: "GS",
          label: "South Georgia and the South Sandwich Islands",
        },
        {
          id: "GT",
          label: "Guatemala",
        },
        {
          id: "GU",
          label: "Guam",
        },
        {
          id: "GW",
          label: "Guinea-Bissau",
        },
        {
          id: "GY",
          label: "Guyana",
        },
        {
          id: "HK",
          label: "Hong Kong, China",
        },
        {
          id: "HM",
          label: "Heard Island and McDonald Islands",
        },
        {
          id: "HN",
          label: "Honduras",
        },
        {
          id: "HR",
          label: "Croatia",
        },
        {
          id: "HT",
          label: "Haiti",
        },
        {
          id: "HU",
          label: "Hungary",
        },
        {
          id: "ID",
          label: "Indonesia",
        },
        {
          id: "IE",
          label: "Ireland",
        },
        {
          id: "IL",
          label: "Israel",
        },
        {
          id: "IM",
          label: "Isle of Man",
        },
        {
          id: "IN",
          label: "India",
        },
        {
          id: "IO",
          label: "British Indian Ocean Territory",
        },
        {
          id: "IQ",
          label: "Iraq",
        },
        {
          id: "IR",
          label: "Iran",
        },
        {
          id: "IS",
          label: "Iceland",
        },
        {
          id: "IT",
          label: "Italy",
        },
        {
          id: "JE",
          label: "Jersey",
        },
        {
          id: "JM",
          label: "Jamaica",
        },
        {
          id: "JO",
          label: "Jordan",
        },
        {
          id: "JP",
          label: "Japan",
        },
        {
          id: "KE",
          label: "Kenya",
        },
        {
          id: "KG",
          label: "Kyrgyzstan",
        },
        {
          id: "KH",
          label: "Cambodia",
        },
        {
          id: "KI",
          label: "Kiribati",
        },
        {
          id: "KM",
          label: "Comoros",
        },
        {
          id: "KN",
          label: "Saint Christopher and Nevis",
        },
        {
          id: "KP",
          label: "North Korea",
        },
        {
          id: "KR",
          label: "Korea, South",
        },
        {
          id: "KW",
          label: "Kuwait",
        },
        {
          id: "KY",
          label: "Cayman Islands",
        },
        {
          id: "KZ",
          label: "Kazakhstan",
        },
        {
          id: "LA",
          label: "Laos",
        },
        {
          id: "LB",
          label: "Lebanon",
        },
        {
          id: "LC",
          label: "Saint Lucia",
        },
        {
          id: "LI",
          label: "Liechtenstein",
        },
        {
          id: "LK",
          label: "Sri Lanka",
        },
        {
          id: "LR",
          label: "Liberia",
        },
        {
          id: "LS",
          label: "Lesotho",
        },
        {
          id: "LT",
          label: "Lithuania",
        },
        {
          id: "LU",
          label: "Luxembourg",
        },
        {
          id: "LV",
          label: "Latvia",
        },
        {
          id: "LY",
          label: "Libya",
        },
        {
          id: "MA",
          label: "Morocco",
        },
        {
          id: "MC",
          label: "Monaco",
        },
        {
          id: "MD",
          label: "Moldova",
        },
        {
          id: "ME",
          label: "Montenegro",
        },
        {
          id: "MG",
          label: "Madagascar",
        },
        {
          id: "MH",
          label: "Marshall Islands",
        },
        {
          id: "MK",
          label: "Macedonia",
        },
        {
          id: "ML",
          label: "Mali",
        },
        {
          id: "MM",
          label: "Myanmar",
        },
        {
          id: "MN",
          label: "Mongolia",
        },
        {
          id: "MO",
          label: "Macao, China",
        },
        {
          id: "MP",
          label: "Northern Mariana Islands",
        },
        {
          id: "MQ",
          label: "Martinique",
        },
        {
          id: "MR",
          label: "Mauritania",
        },
        {
          id: "MS",
          label: "Montserrat",
        },
        {
          id: "MT",
          label: "Malta",
        },
        {
          id: "MU",
          label: "Mauritius",
        },
        {
          id: "MV",
          label: "Maldives",
        },
        {
          id: "MW",
          label: "Malawi",
        },
        {
          id: "MX",
          label: "Mexico",
        },
        {
          id: "MY",
          label: "Malaysia",
        },
        {
          id: "MZ",
          label: "Mozambique",
        },
        {
          id: "NA",
          label: "Namibia",
        },
        {
          id: "NC",
          label: "New Caledonia",
        },
        {
          id: "NE",
          label: "Niger",
        },
        {
          id: "NF",
          label: "Norfolk Island",
        },
        {
          id: "NG",
          label: "Nigeria",
        },
        {
          id: "NI",
          label: "Nicaragua",
        },
        {
          id: "NL",
          label: "Netherlands",
        },
        {
          id: "NO",
          label: "Norway",
        },
        {
          id: "NP",
          label: "Nepal",
        },
        {
          id: "NR",
          label: "Nauru",
        },
        {
          id: "NU",
          label: "Niue",
        },
        {
          id: "NZ",
          label: "New Zealand",
        },
        {
          id: "OM",
          label: "Oman",
        },
        {
          id: "PA",
          label: "Panama",
        },
        {
          id: "PE",
          label: "Peru",
        },
        {
          id: "PF",
          label: "French Polynesia",
        },
        {
          id: "PG",
          label: "Papua New Guinea",
        },
        {
          id: "PH",
          label: "Philippines",
        },
        {
          id: "PK",
          label: "Pakistan",
        },
        {
          id: "PL",
          label: "Poland",
        },
        {
          id: "PM",
          label: "Saint Pierre and Miquelon",
        },
        {
          id: "PN",
          label: "Pitcairn Islands",
        },
        {
          id: "PR",
          label: "Puerto Rico",
        },
        {
          id: "PS",
          label: "Palestine",
        },
        {
          id: "PT",
          label: "Portugal",
        },
        {
          id: "PW",
          label: "Palau",
        },
        {
          id: "PY",
          label: "Paraguay",
        },
        {
          id: "QA",
          label: "Qatar",
        },
        {
          id: "RE",
          label: "Réunion",
        },
        {
          id: "RO",
          label: "Romania",
        },
        {
          id: "RS",
          label: "Serbia",
        },
        {
          id: "RU",
          label: "Russia",
        },
        {
          id: "RW",
          label: "Rwanda",
        },
        {
          id: "SA",
          label: "Saudi Arabia",
        },
        {
          id: "SB",
          label: "Solomon Islands",
        },
        {
          id: "SC",
          label: "Seychelles",
        },
        {
          id: "SD",
          label: "Sudan",
        },
        {
          id: "SE",
          label: "Sweden",
        },
        {
          id: "SG",
          label: "Singapore",
        },
        {
          id: "SH",
          label: "Saint Helena",
        },
        {
          id: "SI",
          label: "Slovenia",
        },
        {
          id: "SJ",
          label: "Svalbard and Jan Mayen",
        },
        {
          id: "SK",
          label: "Slovakia",
        },
        {
          id: "SL",
          label: "Sierra Leone",
        },
        {
          id: "SM",
          label: "San Marino",
        },
        {
          id: "SN",
          label: "Senegal",
        },
        {
          id: "SO",
          label: "Somali",
        },
        {
          id: "SR",
          label: "Suriname",
        },
        {
          id: "SS",
          label: "South Sudan",
        },
        {
          id: "ST",
          label: "Sao Tomé and Príncipe",
        },
        {
          id: "SV",
          label: "El Salvador",
        },
        {
          id: "SY",
          label: "Syria",
        },
        {
          id: "SZ",
          label: "Swaziland",
        },
        {
          id: "TC",
          label: "Turks and Caicos Islands",
        },
        {
          id: "TD",
          label: "Chad",
        },
        {
          id: "TF",
          label: "French Southern and Antarctic Lands",
        },
        {
          id: "TG",
          label: "Togo",
        },
        {
          id: "TH",
          label: "Thailand",
        },
        {
          id: "TJ",
          label: "Tajikistan",
        },
        {
          id: "TK",
          label: "Tokelau",
        },
        {
          id: "TL",
          label: "Timor-Leste",
        },
        {
          id: "TM",
          label: "Turkmenistan",
        },
        {
          id: "TN",
          label: "Tunisia",
        },
        {
          id: "TO",
          label: "Tonga",
        },
        {
          id: "TR",
          label: "Turkey",
        },
        {
          id: "TT",
          label: "Trinidad and Tobago",
        },
        {
          id: "TV",
          label: "Tuvalu",
        },
        {
          id: "TW",
          label: "Taiwan, China",
        },
        {
          id: "TZ",
          label: "Tanzania",
        },
        {
          id: "UA",
          label: "Ukraine",
        },
        {
          id: "UG",
          label: "Uganda",
        },
        {
          id: "UM",
          label: "United States Minor Outlying Islands",
        },
        {
          id: "US",
          label: "United States",
        },
        {
          id: "UY",
          label: "Uruguay",
        },
        {
          id: "UZ",
          label: "Uzbekistan",
        },
        {
          id: "VA",
          label: "Vatican City",
        },
        {
          id: "VC",
          label: "Saint Vincent and The Grenadines",
        },
        {
          id: "VE",
          label: "Venezuela",
        },
        {
          id: "VG",
          label: "British Virgin Islands",
        },
        {
          id: "VI",
          label: "Virgin Islands of the United States",
        },
        {
          id: "VN",
          label: "Vietnam",
        },
        {
          id: "VU",
          label: "Vanuatu",
        },
        {
          id: "WF",
          label: "Wallis and Futuna",
        },
        {
          id: "WS",
          label: "Samoa",
        },
        {
          id: "YE",
          label: "Yemen",
        },
        {
          id: "YT",
          label: "Mayotte",
        },
        {
          id: "ZA",
          label: "South Africa",
        },
        {
          id: "ZM",
          label: "Zambia",
        },
        {
          id: "ZW",
          label: "Zimbabwe",
        },
      ],
      dateRangeOptions: [
        {
          id: "1DAY",
          label: "Last 1 day",
        },
        {
          id: "7DAY",
          label: "Last 7 days",
        },
        {
          id: "30DAY",
          label: "Last 30 days",
        },
        {
          id: "120DAY",
          label: "Last 120 days",
        },
      ],
      genres: [
        "ALL",
        "POP",
        "ROCK",
        "HIP_HOP",
        "ELECTRONIC",
        "DANCE",
        "COUNTRY",
        "JAZZ",
        "CLASSICAL",
      ],
    },
    fetchingTrendingSongsFilters: false,
    fetchedTrendingSongsFilters: false,
    fetchedTrendingSongsFiltersFail: true,
  }),
});

// trendingSongs: [
//   {
//     id: "7363314575675541521",
//     rank: {
//       position: "1",
//       change: {
//         value: 0,
//         type: "NEW",
//       },
//     },
//     name: "Ok I Like It",
//     artist: "Milky Chance",
//     thumbnail_url:
//       "https://p16-sg-default.akamaized.net/aweme/100x100/tos-alisg-v-2774/oEYDQ071KYgWfAAA02fF4talmGAfnQfBmN9BrA.jpeg",
//     genres: ["ALTERNATIVE/INDIE", "METAL"],
//   },
// ],
