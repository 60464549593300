import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { forwardRef, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { Form, Grid } from "antd";

import { clientDataSelector, meSelector } from "@store/me/selectors";
import { predefinedFiltersSelector } from "@store/savedFilters/selectors";

import { saveFilterAction, triggerFilteringWithoutUrlAction } from "@store/filters/actions";

import {
  IFilters,
  IFormFilterRequest,
  IFormHandler,
  ISavedFilter,
  debounce,
  removeItemUrlSearch,
} from "@bbdevcrew/bb_ui_kit_fe";
import { useAppFilters } from "@utils/appFilters";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { getDefaultFilterValue } from "@utils/filters";
import { getCustomFiltersBySection } from "./AppLayout.helpers";
import { FilterContext } from "./FilterContext";
import { getSubFeatureName } from "@utils/feature";
import { useCurrentFilters } from "@utils/useCurrentFilters";
import { useGetDefaultFiltersConfig } from "../AppFilter/config";
import { getPostsPageAllowedFilters } from "@components/insights/posts/postsTable/helpers";

interface IFilterProviderProps {
  children: ReactNode;
}

const FilterProvider = forwardRef<IFormHandler, IFilterProviderProps>(
  ({ children }, filtersRef) => {
    const screens = Grid.useBreakpoint();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { isBatchModeActive } = useBatchMode();
    const filters = useCurrentFilters();
    const [form] = Form.useForm<IFormFilterRequest>();
    const defaultFilters = useGetDefaultFiltersConfig(t, false, form);

    const isMobile = screens.md === false;
    const subFeatureName = getSubFeatureName();
    const { updateSearchDataAndUrl } = useAppFilters();

    const [stickyFilterValue, setStickyFilterValue] = useState<string | null>(null);
    const [isBottomReached, setIsBottomReached] = useState(false);
    const [isFiltersModalShowing, setIsFiltersModalShowing] = useState(false);

    const layout = document.getElementById("layout-content");

    const me = useSelector(meSelector);
    const clientData = useSelector(clientDataSelector);

    const predefinedFilters = useSelector(predefinedFiltersSelector);

    const triggerFilterClick = useCallback(
      (data: IFilters) => dispatch(triggerFilteringWithoutUrlAction(data)),
      [dispatch],
    );

    const setSavedFilterValue = useCallback(
      (id: string | number | null) => dispatch(saveFilterAction(id)),
      [dispatch],
    );

    const getDefaultFilters = useCallback(
      () => getDefaultFilterValue(me),
      // eslint-disable-next-line
      [clientData],
    );

    const hideSavedFilters = useMemo(() => {
      return ["report-profiles", "report-alerts"].includes(searchParams.get("section") || "");
    }, [searchParams]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onWindowScroll = useCallback(
      debounce(() => {
        if (layout && layout.scrollHeight - (layout.scrollTop + layout.clientHeight) < 50) {
          return setIsBottomReached(true);
        }

        setIsBottomReached(false);
      }, 50),
      [layout],
    );

    useEffect(() => {
      layout?.addEventListener("scroll", onWindowScroll);

      return () => {
        layout?.removeEventListener("scroll", onWindowScroll);
      };
    }, [onWindowScroll, layout]);

    const getCustomFilters = useCallback(() => {
      const section = searchParams.get("section");
      return getCustomFiltersBySection(section, defaultFilters, t);
    }, [searchParams, defaultFilters, t]);

    const onFilterInternal = useCallback(
      (data: IFilters, param?: string | ISavedFilter | undefined) => {
        const isPostsPage = searchParams.get("section") === "report-posts";
        const postsPageFilters = getPostsPageAllowedFilters(data, defaultFilters);
        const appliedFilters = isPostsPage ? postsPageFilters : data;

        if (
          isPostsPage ||
          searchParams.get("section") === "brandbastion_actions-transparency_dashboard" ||
          subFeatureName === "transparency"
        ) {
          triggerFilterClick(appliedFilters);
        }

        updateSearchDataAndUrl(appliedFilters, param);

        if (isMobile) setIsFiltersModalShowing(false);
      },
      [
        searchParams,
        subFeatureName,
        updateSearchDataAndUrl,
        isMobile,
        defaultFilters,
        triggerFilterClick,
      ],
    );

    const onSavedStickyChange = useCallback(
      (filterId?: string | number) => {
        const savedFilters = predefinedFilters.find(({ id }) => id === "savedFilters");
        const stickyFilters = predefinedFilters.find(({ id }) => id === "stickyFilters");

        const savedFilter = savedFilters?.items.find(
          ({ id: savedFilterId }) => savedFilterId === Number(filterId),
        );
        const stickyFilter = stickyFilters?.items.find(
          ({ id: stickyFilterId }) => stickyFilterId === filterId,
        );

        const filter = savedFilter || stickyFilter || null;
        const param = savedFilter ? ({ saved_filter: filterId } as ISavedFilter) : "id";

        onFilterInternal(filter?.request || getDefaultFilters(), param);
      },
      [predefinedFilters, onFilterInternal, getDefaultFilters],
    );

    const onResetFilters = useCallback(() => {
      setSavedFilterValue(null);
      setStickyFilterValue(null);
      onSavedStickyChange("");

      (filtersRef as React.RefObject<IFormHandler>)?.current?.resetFields();
      (filtersRef as React.RefObject<IFormHandler>)?.current?.initializeFormValues(
        getDefaultFilters(),
      );
      removeItemUrlSearch("id");
      removeItemUrlSearch("saved_filter");

      if (isMobile) setIsFiltersModalShowing(false);
    }, [setSavedFilterValue, onSavedStickyChange, getDefaultFilters, isMobile, filtersRef]);

    const onSavedFilterChange = useCallback(
      (id: string | number | null) => {
        const validId = id === "null" ? null : id;

        if (validId) onSavedStickyChange(validId);

        if (validId === null) onResetFilters();
        else setSavedFilterValue(validId);
      },
      [onSavedStickyChange, onResetFilters, setSavedFilterValue],
    );

    const filterContextValue = {
      clientData,
      filters,
      hideSavedFilters,
      stickyFilterValue,
      isBottomReached,
      onFilterInternal,
      onResetFilters,
      form,
      customFilters: getCustomFilters(),
      onSavedStickyChange,
      onSavedFilterChange,
      setStickyFilterValue,
      isBatchModeActive,
      isFiltersModalShowing,
      showFilterModal: () => setIsFiltersModalShowing(true),
      closeFilterModal: () => setIsFiltersModalShowing(false),
    };

    return <FilterContext.Provider value={filterContextValue}>{children}</FilterContext.Provider>;
  },
);

export default FilterProvider;

FilterProvider.displayName = "FilterProvider";
