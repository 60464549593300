import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AIPrompts } from "@bbdevcrew/bb_ui_kit_fe";
import { CaptionsAIPromptsForm } from "../../_common/CaptionsAIPromptsForm/AIPromptsForm";

import {
  captionsAIPromptsSelector,
  fetchingCaptionsAIPromptSuggestionSelector,
} from "@store/captionsAIPrompts/selectors";
import {
  deleteCaptionsPromptAction,
  getCaptionsPromptAISuggestionAction,
  getCaptionsPromptsAction,
  setCaptionsPromptAsDefaultAction,
} from "@store/captionsAIPrompts/actions";

import s from "./index.module.less";

import { ICaptionsAIPromptCommentPayload } from "@store/captionsAIPrompts/types";

const MIN_MESSAGE_LENGTH_TO_PROMPT = 3;

interface IPostCreationFormAIPromptsProps {
  topic: string;
}

export function PostCreationFormAIPrompts({ topic }: IPostCreationFormAIPromptsProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const generateDisabled = topic.length < MIN_MESSAGE_LENGTH_TO_PROMPT;

  const aIPrompts = useSelector(captionsAIPromptsSelector);
  const fetchingAIPromptSuggestion = useSelector(fetchingCaptionsAIPromptSuggestionSelector);

  const promptAISuggestion = useCallback(
    (payload: ICaptionsAIPromptCommentPayload) =>
      dispatch(getCaptionsPromptAISuggestionAction(payload)),
    [dispatch],
  );

  const fetchAIPromts = useCallback(
    (query?: string) => {
      dispatch(getCaptionsPromptsAction(query));
    },
    [dispatch],
  );

  const deleteAIPrompt = useCallback(
    (id: string) => {
      dispatch(deleteCaptionsPromptAction(id));
    },
    [dispatch],
  );

  const setDefaultAIPrompt = useCallback(
    (id: string) => {
      dispatch(setCaptionsPromptAsDefaultAction(id));
    },
    [dispatch],
  );

  const getAIPromptSuggestion = (promptId?: string) => {
    if (promptId)
      promptAISuggestion({
        prompt_id: promptId,
        topic: topic,
      });
  };

  const onGenerateDefaultPromptClick = () => {
    promptAISuggestion({
      topic: topic,
    });
  };

  return (
    <div className={s.bbAiPromptsWrapper}>
      <AIPrompts
        aIPrompts={aIPrompts}
        aIPromptsForm={CaptionsAIPromptsForm as any} // eslint-disable-line
        fetchAIPrompts={fetchAIPromts}
        deleteAIPrompt={deleteAIPrompt}
        setDefaultAIPrompt={setDefaultAIPrompt}
        getAIPromptSuggestion={getAIPromptSuggestion}
        onGenerateDefaultPromptClick={onGenerateDefaultPromptClick}
        generateDefaultPromptDisabled={generateDisabled}
        generateDefaultPromptLoading={fetchingAIPromptSuggestion}
        mainBtnTooltipText={t(
          `components:publish:postCreationModal:aiPrompts:generateBtnTooltip:${
            generateDisabled ? "disabled" : "enabled"
          }`,
        )}
      />
    </div>
  );
}
