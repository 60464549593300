import React, { FC, useRef } from "react";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./InputNumber.module.less";

interface IInputNumberProps {
  min: number;
  max: number;
  value?: number;
  onChange?: (value: number) => void;
}

export const InputNumber: FC<IInputNumberProps> = ({ min, max, value = min, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    if (onChange) onChange(newValue);
  };

  const updateValue = (newValue: number) => {
    if (inputRef.current) {
      inputRef.current.value = String(newValue);
      inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
      if (onChange) onChange(newValue);
    }
  };

  const onDecrementClick = () => {
    if (inputRef.current) {
      const currentValue = inputRef.current.value ? parseInt(inputRef.current.value, 10) : 0;
      updateValue(Math.max(min, currentValue - 1));
    }
  };

  const onIncrementClick = () => {
    if (inputRef.current) {
      const currentValue = inputRef.current.value ? parseInt(inputRef.current.value, 10) : 0;
      updateValue(Math.min(max, currentValue + 1));
    }
  };

  return (
    <div className={s.bbNumberSelectInputWrapper}>
      <Button
        type="button"
        _type="on-surface"
        className={s.bbDecrementButton}
        disabled={value <= min}
        onClick={onDecrementClick}
      >
        -
      </Button>
      <input
        ref={inputRef}
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        className={s.bbNumberSelectInput}
      />
      <Button
        type="button"
        _type="on-surface"
        disabled={value >= max}
        className={s.bbIncrementButton}
        onClick={onIncrementClick}
      >
        +
      </Button>
    </div>
  );
};
