import React, { FC, Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import WorkflowTypeCard from "./WorkflowTypeCard";
import {
  Button,
  RadioGroup,
  ChevronRightIcon,
  WarningIcon,
  Tooltip,
} from "@bbdevcrew/bb_ui_kit_fe";
import UsageMeter from "@components/_common/UsageMeter";

import { workflowCategoriesSelector } from "@store/workflows/selectors";

import s from "./Step.module.less";

import { IStep1Props } from "./types";
import { WorkflowItemCategoryType } from "@store/workflows/types";
import { WorkflowCategoryIconMap } from "../menu/helpers";
import { useWorkflowLimits } from "../helpers";

const Step1: FC<IStep1Props> = ({
  setCurrentStep,
  selectedWorkflowType,
  setSelectedWorkflowType,
}) => {
  const { t } = useTranslation();
  const { hasWorkflowLimits, ruleBasedLimits, nlpBasedLimits } = useWorkflowLimits();

  const [hasWorkflowTypeRequiredError, setHasWorkflowTypeRequiredError] = useState(false);

  const workflowCategories = useSelector(workflowCategoriesSelector);

  const onCreateWorkflowClick = () => {
    if (selectedWorkflowType === undefined) {
      setHasWorkflowTypeRequiredError(true);
    } else {
      setHasWorkflowTypeRequiredError(false);
      setSelectedWorkflowType(selectedWorkflowType);
      setCurrentStep(1);
    }
  };

  const onWorkflowTypeClick = (value: WorkflowItemCategoryType) => {
    setHasWorkflowTypeRequiredError(false);
    setSelectedWorkflowType(value);
  };

  const selectedWorkflowTypeLimitReached = useMemo(() => {
    if (selectedWorkflowType === "nlp") {
      return nlpBasedLimits.current >= nlpBasedLimits.max;
    }
    return ruleBasedLimits.current >= ruleBasedLimits.max;
  }, [selectedWorkflowType, nlpBasedLimits, ruleBasedLimits]);

  const createWorkflowButton = (
    <Button
      _type="primary"
      onClick={onCreateWorkflowClick}
      className={s.bbWorkflowCreationStepBtn}
      data-cy="workflow-creation-step1-btn"
      disabled={hasWorkflowLimits && selectedWorkflowTypeLimitReached}
    >
      {t("pages:workflows:workflowCreationModal:step1:okBtn")}
      <ChevronRightIcon />
    </Button>
  );

  return (
    <div className={s.bbWorkflowCreationStep} data-cy="workflow-creation-step1">
      <h2 className={s.bbWorkflowModalTitle}>
        {t("pages:workflows:workflowCreationModal:step1:title")}
      </h2>
      {hasWorkflowTypeRequiredError && (
        <div className={s.bbWorkflowTypeError}>
          <WarningIcon />
          {t("pages:workflows:workflowCreationModal:step1:requiredError")}
        </div>
      )}
      <div>
        <RadioGroup
          value={selectedWorkflowType}
          onChange={value => onWorkflowTypeClick(value as WorkflowItemCategoryType)}
          className={s.bbWorkflowSectionRadio}
        >
          {workflowCategories
            .filter(category => {
              return (
                category.id !== "all" &&
                !!category?.is_visible &&
                !!category?.sub_categories?.some(subCategory => !!subCategory?.is_visible)
              );
            })
            .map(category => {
              const Icon = WorkflowCategoryIconMap[category.id];
              return (
                <Fragment key={category.id}>
                  <div className={s.bbWorkflowCreationSectionTitle}>
                    {Icon && <Icon />}
                    {t(`pages:workflows:workflowCreationModal:workflowTypes:${category.id}`)}
                  </div>
                  <div className={s.bbWorkflowTypeCardWrapper}>
                    {category?.sub_categories
                      ?.filter(
                        subCategory => subCategory.id !== "assign" && !!subCategory?.is_visible,
                      )
                      .map(subCategory => (
                        <WorkflowTypeCard
                          key={subCategory.id}
                          type={subCategory.id as WorkflowItemCategoryType}
                          selected={selectedWorkflowType === subCategory.id}
                          showUpgrade={!!subCategory.is_locked}
                        />
                      ))}
                  </div>
                </Fragment>
              );
            })}
        </RadioGroup>
      </div>
      {hasWorkflowLimits && (
        <div className={s.bbWorkflowLimits}>
          <UsageMeter
            title={t("pages:workflows:workflowCreationModal:step1:limits:ruleBased")}
            current={ruleBasedLimits.current}
            max={ruleBasedLimits.max}
          />
          <UsageMeter
            title={t("pages:workflows:workflowCreationModal:step1:limits:nlpBased")}
            current={nlpBasedLimits.current}
            max={nlpBasedLimits.max}
          />
        </div>
      )}
      <div className={s.bbWorkflowCreationStepFooter}>
        {selectedWorkflowTypeLimitReached ? (
          <Tooltip
            title={t("pages:workflows:workflowCreationModal:step1:limits:maxWorkflowsReached", {
              count: ruleBasedLimits.max + nlpBasedLimits.max,
            })}
          >
            <>{createWorkflowButton}</>
          </Tooltip>
        ) : (
          createWorkflowButton
        )}
      </div>
    </div>
  );
};

export default Step1;
