import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import { ICountry } from "@bbdevcrew/bb_ui_kit_fe";

export type TiktokTrendingActionsType = ActionType<typeof actions>;

export interface IFilterValue {
  industryId?: string;
  dateRangeId?: string;
  countryId?: string;
  genre?: string;
}

export interface ITrendingHashtagsVideo {
  share_url: string;
  thumbnail_url: string;
}

export interface ITrendingHashtagsVideoPayload {
  id: string;
  items: ITrendingHashtagsVideo[];
}

export interface ITrendingItem {
  id: string;
  rank: {
    position: number | string;
    change?: {
      value: string | number;
      type: "POSITIVE" | "NEGATIVE" | "NEW";
    };
  };
  numberOfPosts?: string;
  numberOfViews?: string;
  name: string;
  trendingCountries?: ICountry[];
  videos?: ITrendingHashtagsVideo[];
  artist?: string;
  thumbnail_url: string;
  genres?: string[];
}

export interface IInudstryFilterItem {
  id: string;
  label: string;
  emoji?: string;
  subIndustries?: IInudstryFilterItem[];
}

export interface ITrendingFilters {
  industries: IInudstryFilterItem[];
  countries: ICountry[];
  dateRangeOptions: { id: string; label: string }[];
  genres: string[];
}

export enum TrendingModeEnum {
  SONGS = "songs",
  HASHTAGS = "hashtags",
}

export interface ITiktokState {
  trendingHashtags: ITrendingItem[];
  fetchingTrending: boolean;
  fetchingTrendingVideos: boolean;
  fetchedTrending: boolean;
  fetchedTrendingFail: boolean;
  trendingHashtagsFilters: ITrendingFilters;
  fetchingTrendingHashtagsFilters: boolean;
  fetchedTrendingHashtagsFilters: boolean;
  fetchedTrendingHashtagsFiltersFail: boolean;

  trendingSongs: ITrendingItem[];
  trendingSongsFilters: ITrendingFilters;
  fetchingTrendingSongsFilters: boolean;
  fetchedTrendingSongsFilters: boolean;
  fetchedTrendingSongsFiltersFail: boolean;
}
