import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";

import { Trigger } from "./Trigger";
import { UserAvatar, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";

import { useSearch, handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AssignUser.module.less";

import { IUser } from "@bbdevcrew/bb_ui_kit_fe";

interface IAssignUserProps {
  users: IUser[];
  value?: string[];
  currentUserId: string;
  triggerClassName?: string;
  triggerPlaceholder?: string;
  hideTriggerPlaceholder?: boolean;
  hideTriggerChevron?: boolean;
  onChange?: (value: string[]) => void;
}

function AssignUser({
  users,
  value = [],
  currentUserId,
  triggerClassName,
  triggerPlaceholder,
  hideTriggerPlaceholder = false,
  hideTriggerChevron = false,
  onChange,
}: IAssignUserProps) {
  const { t } = useTranslation();

  const [assignedUsers, setAssignedUsers] = useState<string[]>(value);

  const transformedUsers = useMemo(() => {
    return (users || []).map(({ id, first_name, last_name, avatar, is_disabled }) => ({
      id,
      label: `${first_name} ${last_name} ${
        is_disabled ? `(${t("generic:disabled")})`.toUpperCase() : ""
      }`,
      iconPosition: "left" as const,
      disabled: is_disabled,
      icon: (
        <UserAvatar
          img={avatar}
          lastName={last_name}
          firstName={first_name}
          className={s.bbAssignUserAvatar}
        />
      ),
    }));
  }, [users, t]);

  useEffect(() => {
    setAssignedUsers(value);
  }, [value]);

  const { options: searchedOptions, SearchInput } = useSearch(
    transformedUsers,
    t("generic:search"),
  );

  const _onChange = (val: string) => {
    const user = users.find(({ id }) => id === val);

    /**
     * Prevent change on `is_disabled` users, but allow it if they've been selected.
     * See: https://brandbastion.atlassian.net/browse/DEV-7620
     */
    if (!assignedUsers.includes(val) && user?.is_disabled) {
      return;
    }

    const newState = handleMultiselectState(searchedOptions, assignedUsers, val);

    setAssignedUsers(newState);
  };

  return (
    <SelectStyledMulti
      _size="sm"
      onChange={_onChange}
      value={assignedUsers}
      options={searchedOptions}
      DropdownHeader={SearchInput}
      dropdownPlacement="bottomRight"
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      onDropdownClose={() => onChange && onChange(assignedUsers)}
      TriggerVariation={props => (
        <Trigger
          {...props}
          users={users}
          currentUserId={currentUserId}
          className={triggerClassName}
          hideTriggerPlaceholder={hideTriggerPlaceholder}
          placeholder={assignedUsers?.length ? "" : triggerPlaceholder}
          hideTriggerChevron={hideTriggerChevron}
        />
      )}
    />
  );
}

export default AssignUser;
