import React from "react";
import { useTranslation } from "react-i18next";

import { Upload } from "antd";
import { BBUploadList } from "./bbUploadList/BBUploadList";

import s from "./Upload.module.less";

import { IBBUploadProps } from "./Upload.type";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";

import { ImageUploadIcon } from "@assets/index";

const { Dragger } = Upload;

const BBUpload: React.FC<IBBUploadProps> = ({
  maxCount = 10,
  multiple = true,
  fileList = [],
  uploadText,
  validTypes,
  onChange,
  onRemove,
  previewImages,
  contextMenu,
  tagBadge,
  ...props
}) => {
  const { t } = useTranslation();

  const onFileRemoveInternal = (file: UploadFile) => {
    onRemove?.(file);
    onChange?.({
      fileList: fileList?.filter(f => f.uid !== file.uid),
    } as UploadChangeParam);
  };

  return (
    <>
      <Dragger
        name="file"
        fileList={fileList}
        maxCount={maxCount}
        multiple={multiple}
        className={s.bbFileUpload}
        accept={validTypes.join(",")}
        showUploadList={false}
        onChange={onChange}
        {...props}
      >
        <div
          className={s.bbFileUploadContainer}
          data-stonly-target="app-global__upload--file-upload-section"
        >
          <ImageUploadIcon />
          <span
            className={s.bbFileUploadText}
            data-stonly-target="app-global__upload--file-upload-section-text"
          >
            {uploadText || t("components:publish:postCreationModal:uploads:placeholder1")}
            <span>{t("components:publish:postCreationModal:uploads:placeholder2")}</span>
          </span>
        </div>
      </Dragger>
      <BBUploadList
        fileList={fileList}
        previewImages={previewImages}
        onRemove={onFileRemoveInternal}
        contextMenu={contextMenu}
        tagBadge={tagBadge}
      />
    </>
  );
};

export default BBUpload;
