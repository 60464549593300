import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import { Button, ChevronLeftIcon, Toggle } from "@bbdevcrew/bb_ui_kit_fe";

import WorkflowForm from "../form/WorkflowForm";
import { defaultValues, useWorkflowSubmitHandler } from "../helpers";

import { IWorkflowItem } from "@store/workflows/types";
import { IStep2Props } from "./types";

import s from "./Step.module.less";

const Step2: FC<IStep2Props> = ({
  setCurrentStep,
  workflowType,
  item = defaultValues,
  setFormData,
  savedFormData,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IWorkflowItem>();
  const { handleSubmit, isLoading } = useWorkflowSubmitHandler();
  const isEditMode = !!item.id;
  const isReplyWorkflow = workflowType === "reply";

  const [enabled, setEnabled] = useState(savedFormData?.enabled ?? item.enabled);

  useEffect(() => {
    const initialValues = savedFormData || {
      ...item,
      category: workflowType,
    };
    form.setFieldsValue(initialValues);
  }, [form, item, savedFormData, workflowType]);

  const onFinish = (values: IWorkflowItem) => {
    const formattedData = {
      ...values,
      enabled,
      category: workflowType,
    };

    if (isReplyWorkflow) {
      setFormData(formattedData);
      setCurrentStep(2);
    } else {
      handleSubmit(formattedData);
    }
  };

  return (
    <div className={s.bbWorkflowCreationStep} data-cy="workflow-creation-step2">
      <div className={s.bbWorkflowModalHeader}>
        <h2 className={s.bbWorkflowModalTitle}>
          {isEditMode
            ? t("pages:workflows:workflowCreationModal:step2:editTitle")
            : t("pages:workflows:workflowCreationModal:step2:title")}
        </h2>
        <span className={s.bbWorkflowModalHeaderToggle}>
          <Toggle
            id="enabled-toggle"
            _size="md"
            checked={enabled}
            onChange={value => setEnabled(value.target.checked)}
          />
          <label className={s.bbWorkflowModalHeaderToggleLabel} htmlFor="enabled-toggle">
            {t("generic:active")}
          </label>
        </span>
      </div>
      <WorkflowForm
        form={form}
        onFinish={onFinish}
        item={{
          ...item,
          category: workflowType,
        }}
        enabled={enabled}
        workflowType={workflowType}
      />
      <div className={s.bbWorkflowCreationStepFooter}>
        {!isEditMode && (
          <div className={s.bbFlexGrow}>
            <Button
              _type="secondary"
              onClick={() => setCurrentStep(0)}
              className={s.bbWorkflowCreationStepBackBtn}
              disabled={isLoading}
              data-cy="workflow-creation-step2-back-btn"
            >
              <ChevronLeftIcon />
              {t("generic:back")}
            </Button>
          </div>
        )}
        <Button
          _type="primary"
          onClick={form.submit}
          className={s.bbWorkflowCreationStepBtn}
          loading={isLoading}
          disabled={isLoading}
          data-cy="workflow-creation-step2-submit-btn"
        >
          {isReplyWorkflow
            ? t("pages:workflows:workflowCreationModal:step2:reviewBtn")
            : isEditMode
              ? t("pages:workflows:workflowCreationModal:step2:editBtn")
              : t(
                  `pages:workflows:workflowCreationModal:step2:${
                    enabled ? "okBtn" : "okBtnDisabled"
                  }`,
                )}
        </Button>
      </div>
    </div>
  );
};

export default Step2;
