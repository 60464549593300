import { Form } from "antd";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";

import BBUpload from "../../_common/BBUpload/Upload";
import TagsModal from "./TagsModal";
import AltTextModal from "./AltTextModal";
import Upload from "antd/lib/upload";

import {
  isLI,
  isTT,
  isIGReel,
  getMaxUploadFileCount,
  getValidTypesPerPlatform,
} from "./helpers/utilsValidations";
import { RcFile } from "antd/lib/upload";
import { IG_VALID_COVER_TYPES } from "./helpers/constants";
import { isYouTubeLong, loadImage } from "./helpers/utilsGeneric";
import { UploadRequestError, UploadRequestOption } from "rc-upload/lib/interface";
import { useFileContextMenu } from "./helpers/useFileContextMenu";
import { fetchData, fetchUpload } from "@bbdevcrew/bb_ui_kit_fe";

import { getTokenSelector } from "@store/auth/selectors";

import s from "./index.module.less";

import { IFileUid, IUploadingUrlResponse, IPostCreationUploadProps } from "./helpers/types";
import { PreviewElementsType } from "../../_common/BBUpload/Upload.type";
import CoverUploadLink from "./CoverUploadLink";

export function PostCreationUpload({
  post,
  selectedPlatform,
  updatePost,
  onRemoveFileClick,
  handleFileChange,
  onImageUploadSuccess,
  onFileUploadSuccess,
  setIsFileUploading,
}: IPostCreationUploadProps) {
  const { t } = useTranslation();

  const {
    mediaTags,
    contextFile,
    previewSrc,
    previewType,
    contextType,
    getContextMenu,
    saveTags,
    cancelFileContext,
    getTagBadge,
  } = useFileContextMenu(post);

  const authToken = useSelector(getTokenSelector);

  const handleBeforeUpload = (file: RcFile, selectedFileList: RcFile[]) => {
    const currentUploadedFilesCount = post.previewImageFiles.length + post.previewVideoFiles.length;
    const allFilesLength = currentUploadedFilesCount + selectedFileList.length;
    const maxUploadFileCount = getMaxUploadFileCount(selectedPlatform);

    if (
      maxUploadFileCount > 1 &&
      allFilesLength > maxUploadFileCount &&
      selectedFileList[maxUploadFileCount] == file
    ) {
      addToast({
        type: "danger_accent",
        title: t("components:publish:postCreationModal:errors:fileCountLimit", {
          count: maxUploadFileCount,
        }),
      });
    }

    post = {
      ...post,
      coverImage: undefined,
      coverNameLink: undefined,
    };

    updatePost(post);

    // when all files have been uploaded and assigned an id
    // we have to save the initial upload order, since the uploads are asynchronous
    if (selectedFileList.every(f => !!f.uid)) {
      updatePost({
        ...post,
        orderedFileIds: [...(post.orderedFileIds || []), ...selectedFileList.map(f => f.uid)],
      });
    }
    loadImage(file, image => onImageUploadSuccess(file, image));
    return true;
  };

  const uploadFile =
    (onUploadSuccess: (file: IFileUid, fileName: string) => void, checkedOrderedFiles = false) =>
    (options: UploadRequestOption) => {
      setIsFileUploading?.(true);
      const { onSuccess, onError } = options;
      const file = options.file as IFileUid;

      if (checkedOrderedFiles && !post.orderedFileIds?.includes(file.uid)) {
        return;
      }

      try {
        fetchData<IUploadingUrlResponse>("upload/signed-url", "POST", authToken, {
          content_type: file.type,
        })
          .then(res => {
            fetchUpload(res.url, file).then(response => {
              if (response.status === 200) {
                onUploadSuccess(file, res.file_name);
                // eslint-disable-next-line
                // @ts-ignore
                onSuccess?.("any");
                setIsFileUploading?.(false);
              } else {
                onError?.(t("generic:error"));
                setIsFileUploading?.(false);
              }
            });
          })
          .catch(err => {
            const errorMessage = new Error(err.message) as UploadRequestError;
            onError?.(errorMessage);
            setIsFileUploading?.(false);
          });
      } catch (err) {
        console.error(err);
        setIsFileUploading?.(false);
      }
    };

  const getUploadText = () => {
    if (isLI(post)) {
      return t("components:publish:postCreationModal:uploads:placeholder1Media");
    }

    if (isTT(post)) {
      return t("components:publish:postCreationModal:uploads:placeholder1VideoOnly");
    }
  };

  const enableCoverImage = isIGReel(post) || isYouTubeLong(post);

  const previewImages: PreviewElementsType = useMemo(
    () => ({
      ...post.previewImageFiles.reduce((acc, file) => {
        return {
          ...acc,
          [file.uid]: <img src={file.src} />,
        };
      }, {}),
      ...post.previewVideoFiles.reduce((acc, file) => {
        return {
          ...acc,
          [file.uid]: <video src={file.object_src} />,
        };
      }, {}),
    }),
    [post.previewImageFiles, post.previewVideoFiles],
  );

  if (enableCoverImage && post.coverImage && post.previewVideoFiles.length) {
    const { uid } = post.previewVideoFiles[0];
    previewImages[uid] = <img src={post.coverImage.src} />;
  }

  // eslint-disable-next-line
  const coverUploadRequest = useCallback(
    uploadFile((file, coverNameLink) => {
      const currentFile = file as RcFile;

      loadImage(currentFile, image => {
        return updatePost({
          ...post,
          coverImage: {
            ...currentFile,
            size: currentFile.size,
            src: image.src,
            width: image.width,
            height: image.height,
          },
          coverNameLink,
        });
      });
    }),
    [post],
  );

  const saveAltText = useCallback(
    alttext => {
      if (!!contextFile)
        updatePost({
          ...post,
          altText: {
            ...post.altText,
            [contextFile.uid]: alttext,
          },
        });
      cancelFileContext();
    },
    [post, contextFile, updatePost, cancelFileContext],
  );

  const showContextModal = !!previewSrc;

  return (
    <Form.Item
      name="uploads"
      className={classNames(s.bbPostCreationBodySection, {
        [s.bbPostCreationBodySectionLarge]:
          post.publishing_type === "STORY" && !post.uploadedFiles.length,
      })}
      data-stonly-target="app-global__upload--file-upload-section-wrapper"
    >
      <BBUpload
        multiple
        method="POST"
        fileList={post.uploadedFiles}
        onChange={handleFileChange}
        onRemove={onRemoveFileClick}
        uploadText={getUploadText()}
        previewImages={previewImages}
        beforeUpload={handleBeforeUpload}
        maxCount={getMaxUploadFileCount(selectedPlatform)}
        validTypes={getValidTypesPerPlatform(selectedPlatform)}
        customRequest={uploadFile(onFileUploadSuccess, true)}
        contextMenu={getContextMenu}
        tagBadge={getTagBadge}
      />
      {enableCoverImage && (
        <Upload
          name="cover"
          multiple={false}
          accept={IG_VALID_COVER_TYPES.join(",")}
          showUploadList={false}
          customRequest={coverUploadRequest}
        >
          <CoverUploadLink isYouTubeLong={isYouTubeLong(post)} />
        </Upload>
      )}
      {showContextModal &&
        (contextType === "alt" ? (
          <AltTextModal
            value={contextFile ? post?.altText?.[contextFile.uid] || "" : ""}
            previewSrc={previewSrc}
            previewType={previewType}
            open={!!contextFile}
            onSave={saveAltText}
            onCancel={cancelFileContext}
          />
        ) : (
          <TagsModal
            previewSrc={previewSrc}
            previewType={previewType}
            isReel={isIGReel(post)}
            tags={contextFile ? mediaTags[contextFile.uid] || [] : []}
            assetId={post.asset.id}
            open={!!contextFile}
            tagCategory={contextType}
            onCancel={cancelFileContext}
            onSave={saveTags}
          />
        ))}
    </Form.Item>
  );
}
