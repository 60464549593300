import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import CalendarContext from "./CalendarContext";

import { getCurrentISOWeekNumber } from "../helpers/utils";
import { IPublishingPost } from "@store/publishings/types";

interface IContextWrapperProps {
  children: React.ReactNode;
}

export default function ContextWrapper(props: IContextWrapperProps) {
  const [searchParams] = useSearchParams();

  const weekParam = searchParams.has("week")
    ? parseFloat(searchParams.get("week") || "")
    : getCurrentISOWeekNumber();

  const [weekIndex, setWeekIndex] = useState<number>(weekParam);
  const [monthIndex, setMonthIndex] = useState<number>(dayjs().month());
  const [daySelected, setDaySelected] = useState<Dayjs | undefined>(dayjs());
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [editingPost, setEditingPost] = useState<IPublishingPost | null>(null);

  return (
    <CalendarContext.Provider
      value={{
        weekIndex,
        setWeekIndex,
        monthIndex,
        setMonthIndex,
        daySelected,
        setDaySelected,
        isPostModalOpen,
        setIsPostModalOpen,
        editingPost,
        setEditingPost,
      }}
    >
      {props.children}
    </CalendarContext.Provider>
  );
}
