import { Epic } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { filter, switchMap, map, catchError } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "..";
import * as actions from "./actions";
import { getAuthAPIHeaders } from "../../utils/headers";
import { alerts } from "@utils/paths";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

import { AlertsActionsType, IAlert, IAlertListResponse } from "./types";

export const fetchAlertsEpic: Epic<AlertsActionsType, AlertsActionsType, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchAlerts)),
    switchMap(action =>
      ajax<IAlertListResponse>({
        url: `${alerts}/list`,
        method: "POST",
        body: action.payload,
        headers: getAuthAPIHeaders(state$),
      }).pipe(
        map(response => response.response),
        map(actions.fetchAlertsSuccess),
        catchError(() => of(actions.fetchAlertsFail())),
      ),
    ),
  );

export const updateAlertEpic: Epic<AlertsActionsType, AlertsActionsType, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateAlert)),
    switchMap(action =>
      ajax<IAlert>({
        url: `${alerts}/${action.payload.alertId}`,
        method: "PATCH",
        body: action.payload.updates,
        headers: getAuthAPIHeaders(state$),
      }).pipe(
        map(response => response.response),
        map(actions.updateAlertSuccess),
        catchError(err => handleError(err, actions.updateAlertFail)),
      ),
    ),
  );

export const updateBulkAlertsEpic: Epic<AlertsActionsType, AlertsActionsType, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateBulkAlerts)),
    switchMap(action =>
      ajax<IAlert[]>({
        url: `${alerts}/bulk/status`,
        method: "PATCH",
        body: {
          ids: action.payload.ids,
          status: action.payload.status,
        },
        headers: getAuthAPIHeaders(state$),
      }).pipe(
        map(response => response.response),
        map(() => actions.updateBulkAlertsSuccess(action.payload.status)),
        catchError(err => handleError(err, actions.updateBulkAlertsFail)),
      ),
    ),
  );
