import React, { useMemo } from "react";

import { ScrollView } from "@bbdevcrew/bb_ui_kit_fe";

import CollaboratorsView from "./CollaboratorsView";
import UsersView from "./UsersView";

import { useTagsContext } from "../../TagsModal.helpers";

import s from "./UserTags.module.less";

import { IUserTagsProps } from "./UserTags.types";

export const UserTags: React.FC<IUserTagsProps> = ({ tags }) => {
  const { isReel } = useTagsContext();

  const [users, collaborators] = useMemo(
    () => [
      tags.filter(({ collaborator = false }) => !collaborator),
      tags.filter(({ collaborator = false }) => !!collaborator),
    ],
    [tags],
  );

  return (
    <ScrollView className={s.bbUserTagsScrollView}>
      <div className={s.bbUserTags}>
        <CollaboratorsView collaborators={collaborators} />
        <UsersView tags={users} showEmptyState={!isReel && tags.length === 0} />
      </div>
    </ScrollView>
  );
};
