import { IModalProps } from "@bbdevcrew/bb_ui_kit_fe";
import { IScheduledReport, ScheduledReportType } from "@store/reports/types";

export const MAX_REPORT_NAME_LENGTH = 55;

export interface IManageReportsModalProps extends IModalProps {
  onEditReportClick: (report: IScheduledReport) => void;
}

export interface IReportsSuffixProps {
  reportType: ScheduledReportType;
  numberOfUsers: number;
}
