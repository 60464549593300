import React from "react";

import { Reply as ReplyComponent } from "@bbdevcrew/bb_ui_kit_fe";
import { SavedReplies } from "./SavedReplies";

import { IReplyProps } from "@bbdevcrew/bb_ui_kit_fe";

export const Reply: React.FC<Omit<IReplyProps, "children">> = props => {
  return (
    <ReplyComponent {...props}>
      {{
        SavedReplies,
      }}
    </ReplyComponent>
  );
};

export default Reply;
