import React from "react";
import dayjs from "dayjs";

import Markdown from "react-markdown";

import s from "./NewsItem.module.less";

import { IControlPanelNewsItem } from "@store/controlPanel/types";

interface INewsItemProps extends IControlPanelNewsItem {
  showDate?: boolean;
}

export const NewsItem: React.FC<INewsItemProps> = ({
  title,
  description,
  tags,
  date,
  showDate,
}) => {
  return title ? (
    <div className={s.bbNewsItem}>
      <span className={s.bbNewsItemTitle}>{title}</span>
      {showDate && <span className={s.bbNewsItemDate}>{dayjs(date).format("DD MMM, YYYY")}</span>}
      <span>
        <Markdown
          components={{
            a: ({ href, children }) => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
          }}
        >
          {description}
        </Markdown>
      </span>
      <div className={s.bbNewsItemTags}>
        {tags?.map((tag, index) => (
          <span className={s.bbNewsItemTag} key={index}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  ) : null;
};
