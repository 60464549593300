import { createAction } from "typesafe-actions";
import {
  FETCH_THREADS,
  FETCH_THREADS_SUCCESS,
  FETCH_THREADS_FAILURE,
  FETCH_THREAD_MESSAGES,
  FETCH_THREAD_MESSAGES_SUCCESS,
  FETCH_THREAD_MESSAGES_FAILURE,
  SEND_THREAD_MESSAGE,
  SEND_THREAD_MESSAGE_SUCCESS,
  SEND_THREAD_MESSAGE_FAILURE,
  FETCH_AI_RESPONSE,
  FETCH_AI_RESPONSE_SUCCESS,
  FETCH_AI_RESPONSE_FAILURE,
  SET_AI_MODAL_STATE,
} from "./actionTypes";
import {
  AIModalStateEnum,
  IGetMessagesResponsePayload,
  IGetMessagesResponseSuccessPayload,
  IGetThreadMessagesData,
  IGetThreadMessagesPayload,
  IPostThreadsPayload,
  ISendThreadMessagesPayload,
  ISendThreadMessagesSuccessPayload,
} from "./types";

export const toggleOpenAIModalAction = createAction(SET_AI_MODAL_STATE)<AIModalStateEnum>();

export const fetchThreadsAction = createAction(FETCH_THREADS)<string>();
export const fetchThreadsSuccess = createAction(FETCH_THREADS_SUCCESS)<IPostThreadsPayload>();
export const fetchThreadsFailure = createAction(FETCH_THREADS_FAILURE)();

export const fetchThreadMessagesAction =
  createAction(FETCH_THREAD_MESSAGES)<IGetThreadMessagesPayload>();
export const fetchThreadMessagesSuccess = createAction(
  FETCH_THREAD_MESSAGES_SUCCESS,
)<IGetThreadMessagesData>();
export const fetchThreadMessagesFailure = createAction(FETCH_THREAD_MESSAGES_FAILURE)();

export const sendThreadMessageAction =
  createAction(SEND_THREAD_MESSAGE)<ISendThreadMessagesPayload>();
export const sendThreadMessageSuccess = createAction(
  SEND_THREAD_MESSAGE_SUCCESS,
)<ISendThreadMessagesSuccessPayload>();
export const sendThreadMessageFailure = createAction(SEND_THREAD_MESSAGE_FAILURE)();

export const fetchAIResponseAction = createAction(FETCH_AI_RESPONSE)<IGetMessagesResponsePayload>();
export const fetchAIResponseSuccess =
  createAction(FETCH_AI_RESPONSE_SUCCESS)<IGetMessagesResponseSuccessPayload>();
export const fetchAIResponseFailure = createAction(FETCH_AI_RESPONSE_FAILURE)();
