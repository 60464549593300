import React from "react";
import { useTranslation } from "react-i18next";

import { CloseIcon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { onUserTagExternalLinkClick } from "../UserTags.helpers";

import { IUserRowProps } from "../UserTags.types";
import { useTagsContext } from "../../../TagsModal.helpers";

import { ExportIcon, UserIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const UserRow: React.FC<IUserRowProps> = ({ tag, ...restProps }) => {
  const { t } = useTranslation();
  const { deleteTag } = useTagsContext();
  const { username } = tag;

  return (
    <li {...restProps}>
      <span>
        <UserIcon />
      </span>
      <span>{username}</span>
      <Tooltip title={t("components:publish:postCreationModal:mediaTags:externalLink")}>
        <button onClick={onUserTagExternalLinkClick(username)}>
          <ExportIcon />
        </button>
      </Tooltip>
      <button onClick={() => deleteTag(tag)}>
        <CloseIcon />
      </button>
    </li>
  );
};
