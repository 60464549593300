import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Toggle, ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./HumanReview.module.less";

import { DiamondIcon } from "@assets/index";

interface IHumanReviewProps {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function HumanReview({ checked, onChange }: IHumanReviewProps) {
  const { t } = useTranslation();

  const humanReviewChipTitle = t(
    "components:notificationsPanel:modals:create:humanReviewChipTitle",
  );
  const humanReviewChipDescription = t(
    "components:notificationsPanel:modals:create:humanReviewChipDescription",
  );

  return (
    <div className={s.bbHumanReview} data-cy="human-review">
      <div className={s.bbHumanReviewText}>
        <div className={s.bbHumanReviewChipWrapper}>
          <ChipIcon
            _size="xs"
            _type="default"
            iconPosition="left"
            icon={DiamondIcon}
            text={humanReviewChipTitle}
            className={s.bbHumanReviewChip}
            iconClassName={s.bbHumanReviewChipIcon}
          />
        </div>

        <span className={s.bbHumanReviewDescription}>{humanReviewChipDescription}</span>
      </div>
      <Toggle
        id="is_managed"
        name="is_managed"
        onChange={onChange}
        checked={checked}
        className={s.bbHumanReviewToggle}
      />
    </div>
  );
}

export { HumanReview };
