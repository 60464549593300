import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Grid } from "antd";

import { meSelector } from "@store/me/selectors";
import { inboxStatsSelector } from "@store/inboxStats/selectors";
import { getInboxStatsAction } from "@store/inboxStats/actions";
import { getModuleSectionsListAction } from "@store/modules/actions";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import {
  IMenuItem,
  IInboxStats,
  IInboxOptions,
  IModuleFeature,
  useInboxOptions,
  numberToQuantity,
  filterQueryParams,
  onFeatureRedirect,
  removeItemUrlSearch,
  mapNestedOptionsToChildren,
} from "@bbdevcrew/bb_ui_kit_fe";
import {
  DashboardComponentType,
  IAvailableSectionsType,
  SidebarAvailableSections,
} from "@store/modules/types";
import { useInbox } from "@utils/useInbox";
import { getCurrentModuleName } from "@utils/paths";
import { filterDataSelector } from "@store/filters/selectors";
import { defaultInboxTab } from "@components/care/inbox/Inbox";
import { defaultSmartInboxTab } from "@components/care/smartInbox/SmartInbox";

const listenSectionId = "listen";
const reportAndListenMoreSectionId = "report_and_listen_more";
const careSectionId = "care";
const publishSectionId = "publish";
const managedSectionId = "managed";
const controlPanelSectionId = "control_panel";

export function useMobileMenuItems() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentModuleId = getCurrentModuleName();
  const { options: inboxOptions } = useInboxOptions();

  const [mobileMenuItems, setMobileMenuItems] = useState<IModuleFeature<DashboardComponentType>[]>(
    [],
  );

  const me = useSelector(meSelector);
  const appFilters = useSelector(filterDataSelector);
  const inboxStats = useSelector(inboxStatsSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const searchParamsSection = searchParams.get("section");
  const searchParamsTab = searchParams.get("tab");

  const menu = (me?.features || []) as unknown as IMenuItem[];
  const sections = modulesSections[SidebarAvailableSections as IAvailableSectionsType] || [];
  const engage = menu.find(feature => feature.id === "care");
  const listen = sections.find(section => section.id === "listen");
  const listenMore = sections.find(section => section.id === "listen_more");
  const report = sections.find(section => section.id === "report");
  const publish = menu.find(feature => feature.id === "publish");
  const managed = menu.find(feature => feature.id === "managed");
  const controlPanel = menu.find(feature => feature.id === "control_panel");
  const inbox = engage?.subfeatures?.find(
    subfeature => subfeature.id === "inbox" && !subfeature.is_disabled,
  );
  const smartInbox = engage?.subfeatures?.find(
    subfeature => subfeature.id === "smart-inbox" && !subfeature.is_disabled,
  );
  const defaultCareInboxTab = inbox ? defaultInboxTab : defaultSmartInboxTab;
  const { statTabValue, setStatTabValue, onTabChange } = useInbox();

  const getModuleSectionsList = useCallback(
    (module_id: string) => {
      dispatch(getModuleSectionsListAction(module_id));
    },
    [dispatch],
  );

  useEffect(() => {
    getModuleSectionsList(SidebarAvailableSections);
  }, [getModuleSectionsList]);

  useEffect(() => {
    setStatTabValue(searchParamsTab as keyof IInboxStats);

    if (!searchParamsTab && currentModuleId === "care") {
      setStatTabValue(defaultCareInboxTab);
    }
    // eslint-disable-next-line
  }, [searchParamsTab]);

  const mobileMenuExpandedKey = useMemo(() => {
    if (currentModuleId === "care") return careSectionId;
    if (currentModuleId === "publish") return publishSectionId;
    if (currentModuleId === "managed") return managedSectionId;
    if (currentModuleId === "control_panel") return controlPanelSectionId;
    if (currentModuleId === "insights" && searchParamsSection) {
      const listenMenuItem = mobileMenuItems.find(item => item.id === listenSectionId);
      const reportAndListenMoreMenuItem = mobileMenuItems.find(
        item => item.id === reportAndListenMoreSectionId,
      );

      if (listenMenuItem?.features?.find(feature => feature.id === searchParamsSection))
        return listenSectionId;
      if (
        reportAndListenMoreMenuItem?.features?.find(feature => feature.id === searchParamsSection)
      )
        return reportAndListenMoreSectionId;
    }

    return listenSectionId;
  }, [currentModuleId, searchParamsSection, mobileMenuItems]);

  useEffect(() => {
    // Jump to the default tab, if all errors were dismissed
    if (inboxStats?.stats?.errors === 0 && statTabValue === "errors")
      onTabChange(defaultCareInboxTab);
    // eslint-disable-next-line
  }, [inboxStats?.stats?.errors]);

  useEffect(() => {
    if (engage && appFilters && !screens.lg)
      dispatch(getInboxStatsAction(appFilters, engage.basename, inbox?.id || smartInbox?.id));
  }, [dispatch, appFilters, engage, screens.lg, inbox, smartInbox]);

  const setActiveSection = useCallback(
    (id: string) => {
      setSearchParams(
        {
          ...Object.fromEntries(new URL(window.location.href).searchParams),
          section: id,
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (!searchParamsSection && currentModuleId === "insights") {
      const DEFAULT_SECTION = sections
        ?.flatMap(section => section.features)
        .find(feature => feature?.selected)?.id;

      if (DEFAULT_SECTION) setActiveSection(DEFAULT_SECTION);
    }
    // eslint-disable-next-line
  }, [searchParams, setActiveSection, sections]);

  const isMobileMenuItemActive = (menuItem: IModuleFeature<DashboardComponentType>) => {
    if (menuItem.is_locked || menuItem.is_disabled) return false;

    const isFeature = ["publish", "managed"].includes(currentModuleId);
    const isSection = ["insights", "control_panel"].includes(currentModuleId);
    const isInbox = currentModuleId === "care";

    const isActiveFeature = menuItem.basename === currentModuleId;
    const isActiveSection = menuItem.id === searchParamsSection;
    const isActiveInboxItem = menuItem.id === searchParamsTab;

    if (isFeature) return isActiveFeature;
    if (isSection) return isActiveSection;
    if (isInbox) return isActiveInboxItem;

    return false;
  };

  const onInternalFeatureRedirect = (
    menuItem: IMenuItem,
    additionalQueryParamKey: string,
    additionalQueryParamValue: string,
  ) => {
    removeItemUrlSearch("tab");
    removeItemUrlSearch("section");
    const { url } = menuItem;

    const isAbsPath = url?.startsWith("https://");
    const redirectUrl = isAbsPath ? url : `${window.location.origin}${url}`;
    let queryParams =
      "preserveFilters" in menuItem && menuItem?.preserveFilters
        ? filterQueryParams(`${localStorage.getItem("query") || ""}`)
        : "";
    queryParams += `&${additionalQueryParamKey}=${additionalQueryParamValue}`;

    const noExistingQueryParams =
      redirectUrl && Array.from(new URL(redirectUrl).searchParams).length === 0;
    window.location.href =
      redirectUrl + (!!queryParams ? `${noExistingQueryParams ? "?" : "&"}${queryParams}` : "");
  };

  const onMobileMenuItemClick = (feature: IModuleFeature<DashboardComponentType>) => {
    // from insights to insights
    if (feature.basename === "insights" && currentModuleId === "insights") {
      setActiveSection(feature.id);
      // from other feature to insights
    } else if (feature.basename === "insights" && currentModuleId !== "insights") {
      const menuItem = menu?.find(f => f.id === feature.basename);

      if (menuItem) onInternalFeatureRedirect(menuItem, "section", feature.id);
    } else {
      const menuItem = menu
        ?.find(f => f.id === feature.basename)
        ?.subfeatures?.find(subfeature => subfeature.id === feature.id);
      if (menuItem) onFeatureRedirect(menuItem);
    }
  };

  const inboxMenuItems = useMemo(() => {
    const onInboxTabChange = (tab: keyof IInboxStats) => {
      if (currentModuleId === "care") {
        onTabChange(tab);
        if (tab === "assigned_to_me") dispatch(getInboxStatsAction(appFilters || {}));
      } else {
        const menuItem = menu?.find(f => f.id === "care");
        if (menuItem) onInternalFeatureRedirect(menuItem, "tab", tab);
      }
    };

    return inboxStats
      ? mapNestedOptionsToChildren(
          inboxOptions.map(({ statKey, nested, label, icon, showBadge }: IInboxOptions) => {
            const key = statKey as keyof IInboxStats;
            const count =
              typeof inboxStats?.stats[key] === "number" ? (inboxStats?.stats[key] as number) : 0;

            return {
              key,
              label: String(label || t(`components:inboxMenu:tabs:${key}Label`)),
              icon,
              badge: numberToQuantity(count),
              showBadge: true,
              showUnread: showBadge && count > 0,
              active: statTabValue === statKey,
              onClick() {
                onInboxTabChange(key);
              },
              nested,
            };
          }),
        )
      : [];
    // eslint-disable-next-line
  }, [inboxStats, inboxOptions, statTabValue]);

  useEffect(() => {
    const newMenu: IModuleFeature<DashboardComponentType>[] = [];

    if (listen)
      newMenu.push({
        ...listen,
        features: [...(listen.features || [])?.map(f => ({ ...f, basename: "insights" }))],
      });

    if (listenMore?.features && report?.features) {
      newMenu.push({
        id: reportAndListenMoreSectionId,
        label: t("mobileMenu:reportListenMore"),
        is_visible: listenMore?.is_visible && report?.is_visible,
        is_locked: listenMore?.is_locked && report?.is_locked,
        is_disabled: listenMore?.is_disabled && report?.is_disabled,
        features: [
          ...(report.features || [])?.map(f => ({
            ...f,
            basename: "insights",
          })),
          ...(listenMore.features || [])?.map(f => ({
            ...f,
            basename: "insights",
          })),
        ],
      });
    }

    if (engage && inboxMenuItems) {
      newMenu.push({
        id: engage.id,
        label: engage.label,
        is_visible: true,
        is_locked: engage?.is_disabled && engage?.is_disabled,
        is_disabled: engage?.is_disabled && engage?.is_disabled,
      });
    }

    if (publish) {
      newMenu.push({
        id: publish.id,
        label: publish.label,
        is_visible: true,
        is_locked: publish?.is_disabled,
        is_disabled: publish?.is_disabled,
        features: publish.subfeatures?.map(subfeature => ({
          id: subfeature.id,
          label: subfeature.label,
          is_visible: true,
          basename: "publish",
          is_locked: subfeature?.is_disabled,
          is_disabled: subfeature?.is_disabled,
        })),
      });
    }

    if (managed) {
      newMenu.push({
        id: managed.id,
        label: managed.label,
        is_visible: true,
        is_locked: managed?.is_disabled,
        is_disabled: managed?.is_disabled,
        features: managed.subfeatures?.map(subfeature => ({
          id: subfeature.id,
          label:
            subfeature.id === "transparency"
              ? t("mobileMenu:transparencyDashboard")
              : subfeature.label,
          basename: "managed",
          is_visible: true,
          is_locked: subfeature?.is_disabled,
          is_disabled: subfeature?.is_disabled,
        })),
      });
    }

    if (controlPanel) {
      newMenu.push({
        id: controlPanel.id,
        label: controlPanel.label,
        is_visible: true,
        is_locked: controlPanel?.is_disabled,
        is_disabled: controlPanel?.is_disabled,
        features: controlPanel.subfeatures?.map(subfeature => ({
          id: subfeature.id,
          label: subfeature.label,
          basename: "control_panel",
          is_visible: true,
          is_locked: subfeature?.is_disabled,
          is_disabled: subfeature?.is_disabled,
        })),
      });
    }

    setMobileMenuItems(newMenu);
    // eslint-disable-next-line
  }, [menu, sections.length]);

  return {
    mobileMenuItems,
    inboxMobileMenuItems: inboxMenuItems,
    mobileMenuExpandedKey,
    onMobileMenuItemClick,
    isMobileMenuItemActive,
  };
}
