import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useContext } from "react";

import { Form, FormInstance, Grid } from "antd";
import { AccountsTrigger } from "./accountsTrigger/AccountsTrigger";
import { AssetsMultiFilter, IGetAutocompleteOptionsPayload } from "@bbdevcrew/bb_ui_kit_fe";

import {
  debounce,
  isLinkedinPlatform,
  IAutocompleteAsset,
  isTiktokPlatform,
} from "@bbdevcrew/bb_ui_kit_fe";
import PostCreationContext from "./context/PostCreationContext";
import { IPostCreationFormType, IPostItem } from "./helpers/types";
import { PROPERTIES_TO_IGNORE_ON_SYNC } from "./helpers/constants";
import { isLI, revalidateFilesAfterAssetSync } from "./helpers/utilsValidations";
import { generateEmptyPostItem, copyObjectProperties } from "./helpers/utilsGeneric";

import {
  TTCreatorInfosSelector,
  fetchingTTCreatorInfosSelector,
} from "@store/publishings/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { clearUrlPreviewsAction, getTiktokCreatorInfoAction } from "@store/publishings/actions";

import s from "./index.module.less";

interface IPostCreationTabBarExtraProps {
  form: FormInstance<IPostCreationFormType>;
}

export function PostCreationTabBarExtra({ form }: IPostCreationTabBarExtraProps) {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = screens.md === false;

  const {
    isSynced,
    setLoading,
    posts,
    setPosts,
    selectedAssets,
    setSelectedAssets,
    allAssets,
    setAllAssets,
    activeAssetTab,
    setActiveAssetTab,
  } = useContext(PostCreationContext);

  const ttCreatorInfoList = useSelector(TTCreatorInfosSelector);
  const fetchingTTCreatorInfo = useSelector(fetchingTTCreatorInfosSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  const clearUrlPreviews = useCallback(() => dispatch(clearUrlPreviewsAction()), [dispatch]);

  const getTiktokCreatorInfo = useCallback(
    (asset_id: string) => dispatch(getTiktokCreatorInfoAction(asset_id)),
    [dispatch],
  );

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const syncNewlyAddedAssets = (assetOption: IAutocompleteAsset) => {
    const sourcePost = posts[0];
    const emptyPostItem = generateEmptyPostItem(assetOption);
    const propertiesToIgnore = PROPERTIES_TO_IGNORE_ON_SYNC;

    // LI files shouldn't be copied to other platforms
    if (isLI(sourcePost) && !isLinkedinPlatform(assetOption.id)) {
      propertiesToIgnore.push("uploadedFiles", "previewPDFFiles", "orderedFileIds");
    }

    let newPost: IPostItem = {
      ...sourcePost,
      asset: assetOption,
    };

    // Create a clean newPost object
    // but ignore and don't copy props from propertiesToIgnore
    newPost = copyObjectProperties(emptyPostItem, newPost, propertiesToIgnore);

    return newPost;
  };

  const resetScheduledDateField = () => {
    form.setFieldsValue({
      scheduled_at: undefined,
    });
  };

  const onAssetsInputChange = (assetIds: string[]) => {
    const assets = assetIds.map(id => {
      return [...allAssets, ...selectedAssets].find(asset => asset.id === id) as IAutocompleteAsset;
    });

    // Sort the assets and the posts according to the rules
    const updatedPosts: IPostItem[] = [];
    const updatedAssets = [...assets];

    updatedAssets.forEach(assetOption => {
      const correspondingPost = posts.find(post => post.asset.id === assetOption.id);

      if (correspondingPost) {
        updatedPosts.push(correspondingPost);
      } else if (isSynced && posts.length) {
        const newPost = syncNewlyAddedAssets(assetOption);
        const validatedPost = revalidateFilesAfterAssetSync(posts, newPost);
        updatedPosts.push(validatedPost);
      } else {
        updatedPosts.push(generateEmptyPostItem(assetOption));
      }

      if (
        isTiktokPlatform(assetOption?.platform) &&
        !correspondingPost?.creatorInfo &&
        !fetchingTTCreatorInfo.find(item => item === assetOption.id) &&
        !ttCreatorInfoList.find(item => item.asset_id === assetOption.id)
      ) {
        getTiktokCreatorInfo(assetOption.id);
      }
    });

    // If the active asset was removed from the selected list, automatically select the first tab
    const currentActiveTab = updatedAssets.find(
      selectedAsset => selectedAsset.id === activeAssetTab?.id,
    );

    if (!currentActiveTab) {
      if (updatedAssets.length) setActiveAssetTab(updatedAssets[0]);
      else {
        setActiveAssetTab(undefined);
        resetScheduledDateField();
      }
    }

    // If all assets have been removed, remove any link previews
    if (!updatedAssets.length) clearUrlPreviews();

    // Set new states
    setSelectedAssets(updatedAssets);
    setPosts(updatedPosts);

    form.setFieldsValue({
      asset_ids: assets.map(({ id }) => id),
    });
  };

  const onAssetsFilterAutoComplete = (assets: IAutocompleteAsset[]) => {
    setAllAssets(assets);
    setLoading(false);
  };

  return (
    <Form.Item
      name="asset_ids"
      className={classNames(s.bbPostCreationBodySection, {
        [s.bbPostCreationBodySectionAssetsEmpty]: !selectedAssets.length,
        [s.bbPostCreationBodySectionAssets]: selectedAssets.length,
      })}
      shouldUpdate={(prevValues: { asset_ids: string[] }, curValues: { asset_ids: string[] }) =>
        prevValues.asset_ids !== curValues.asset_ids
      }
    >
      <AssetsMultiFilter
        id="asset_ids"
        fetchFilters="assets_for_publishing"
        value={selectedAssets.map(({ id }) => id)}
        onChange={onAssetsInputChange}
        additionalLabelFields={["id"]}
        TriggerVariation={<AccountsTrigger hasAssetsSelected={!!selectedAssets.length} />}
        customprops={{ form, name: "asset_ids" }}
        dropdownPlacement="bottom"
        placeholder={t("components:publish:postCreationModal:asset_ids:placeholder")}
        getAutocompleteOptionsCallback={onAssetsFilterAutoComplete}
        getAutocompleteOptions={getAutocompleteOptions}
        autocompleteOptions={autocompleteOptions}
        menuWrapperClassName={classNames({ [s.bbPostCreationAccountsMenuWrapperMobile]: isMobile })}
      />
    </Form.Item>
  );
}
