import React from "react";
import { useSelector } from "react-redux";

import { CustomTagWorkflowAction } from "./CustomTagWorkflowAction";
import { BBTagWorkflowAction } from "./BBTagWorkflowAction";

import { meSelector } from "@store/me/selectors";

import { IFormComponent } from "@components/control-panel/workflows/types";

export const TagWorkflowAction: React.FC<IFormComponent<string[]>> = ({ value, onChange }) => {
  const me = useSelector(meSelector);

  const isBBUser = !!me?.is_bb_user;

  return isBBUser ? (
    <BBTagWorkflowAction value={value} onChange={onChange} />
  ) : (
    <CustomTagWorkflowAction value={value} onChange={onChange} />
  );
};
