import { action } from "typesafe-actions";
import {
  SAVE_FILTER,
  TRIGGER_FILTERING,
  RESET_TRIGGER_FILTERING,
  TOGGLE_OPEN_FILTERS_PANEL,
  TRIGGER_FILTERING_WITHOUT_URL,
  RESET_TRIGGER_FILTERING_WITHOUT_URL,
} from "./actionTypes";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export const toggleOpenFiltersPanelAction = (open: boolean) =>
  action(TOGGLE_OPEN_FILTERS_PANEL, open);

export const triggerFilteringWithoutUrlAction = (data: IFilters) =>
  action(TRIGGER_FILTERING_WITHOUT_URL, data);
export const resetTriggerFilterClickWithoutUrlAction = () =>
  action(RESET_TRIGGER_FILTERING_WITHOUT_URL);

export const triggerFilteringAction = (data: IFilters) => action(TRIGGER_FILTERING, data);
export const resetTriggerFilterClickAction = () => action(RESET_TRIGGER_FILTERING);

export const saveFilterAction = (savedFilterValue: number | string | null) =>
  action(SAVE_FILTER, savedFilterValue);
