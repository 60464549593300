import { createAction } from "typesafe-actions";
import * as types from "./actionTypes";
import { IAlert, IAlertListRequest, AlertStatusType, IAlertListResponse } from "./types";

export const fetchAlerts = createAction(
  types.FETCH_ALERTS,
  (payload: IAlertListRequest) => payload,
)();

export const fetchAlertsSuccess = createAction(
  types.FETCH_ALERTS_SUCCESS,
  (payload: IAlertListResponse) => payload,
)();

export const fetchAlertsFail = createAction(types.FETCH_ALERTS_FAIL)();

export const updateAlert = createAction(
  types.UPDATE_ALERT,
  (alertId: string, updates: Partial<IAlert>) => ({ alertId, updates }),
)();

export const updateAlertSuccess = createAction(
  types.UPDATE_ALERT_SUCCESS,
  (alert: IAlert) => alert,
)();

export const updateAlertFail = createAction(types.UPDATE_ALERT_FAIL)();

export const updateBulkAlerts = createAction(
  types.UPDATE_BULK_ALERTS,
  (ids: string[], status: AlertStatusType) => ({ ids, status }),
)();

export const updateBulkAlertsSuccess = createAction(
  types.UPDATE_BULK_ALERTS_SUCCESS,
  (status: AlertStatusType) => status,
)();

export const updateBulkAlertsFail = createAction(types.UPDATE_BULK_ALERTS_FAIL)();
