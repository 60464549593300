import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, ItemCard, ModalHeaderActions, ScrollView, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CustomPrompts.module.less";

import { ICustomPromptsListProps } from "./CustomPrompts.types";

import { HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const CustomPromptsList: React.FC<ICustomPromptsListProps> = ({
  prompts,
  onAddClick,
  onEditClick,
  onDeleteClick,
  onSelectClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbCustomPromptsList}>
      <ModalHeaderActions
        title={
          <div className={s.bbCustomPromptsListHeaderTitle}>
            {t("components:customPrompts:title")}
            <i
              className={s.bbCustomPromptsListHeaderTitleHelpIcon}
              data-stonly-target="app-AI-Insights__prompts--tooltip"
            >
              <HelpIcon />
            </i>
          </div>
        }
        onAddClick={onAddClick}
        hideSearch
        className={s.bbCustomPromptsListHeader}
      />
      <ScrollView className={s.bbCustomPromptsListScrollView}>
        <>
          {prompts
            .slice()
            .sort((a, b) => (a.id === "default" ? -1 : b.id === "default" ? 1 : 0))
            .map(prompt => {
              const { id, name } = prompt;

              return (
                <Tooltip key={id} title={t("components:customPrompts:tooltips:generateInsights")}>
                  <div>
                    <ItemCard
                      name={
                        <div className={s.bbCustomPromptsListName}>
                          {name}
                          {!!prompt.is_default && (
                            <Chip
                              _type="primary"
                              _size="xxs"
                              text={t("components:customPrompts:labels:defaultChip")}
                            />
                          )}
                        </div>
                      }
                      onItemClick={() => onSelectClick(prompt)}
                      actions={
                        prompt.id !== "default"
                          ? [
                              {
                                key: "edit",
                                label: "generic:edit",
                                onActionClick: () => onEditClick(prompt),
                              },
                              {
                                key: "delete",
                                label: "generic:delete",
                                onActionClick: () => onDeleteClick(prompt),
                              },
                            ]
                          : []
                      }
                    />
                  </div>
                </Tooltip>
              );
            })}
        </>
      </ScrollView>
    </div>
  );
};

export default CustomPromptsList;
