import { useTranslation } from "react-i18next";
import React, { FC, useState, ChangeEvent } from "react";

import s from "./WeekdaysSelect.module.less";

import { weekdays } from "./helpers";
import { WeekdayType } from "@store/notifications/types";

interface IWeekdaysSelectProps {
  selectedDays?: WeekdayType[];
  onChange?: (selectedDays: WeekdayType[]) => void;
}

const WeekdaysSelect: FC<IWeekdaysSelectProps> = ({ onChange, selectedDays }) => {
  const { t } = useTranslation();

  const [markedDays, setMarkedDays] = useState<string[]>(selectedDays || []);

  const onWeekdaysChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checkbox = e.target;

    if (checkbox.checked) {
      setMarkedDays([...markedDays, checkbox.id]);
      onChange?.([...markedDays, checkbox.id] as WeekdayType[]);
    } else {
      setMarkedDays(markedDays.filter(day => day !== checkbox.id));
      onChange?.(markedDays.filter(day => day !== checkbox.id) as WeekdayType[]);
    }
  };

  return (
    <div className={s.bbWeekdaysGroupSelect}>
      {weekdays.map(weekday => (
        <div key={weekday} className={s.bbWeekdaySelect}>
          <input
            id={weekday}
            value={weekday}
            type="checkbox"
            onChange={onWeekdaysChange}
            name="weekdaysSelect"
            checked={!!markedDays?.find(day => day === weekday)}
          />
          <label htmlFor={weekday}>{t(`generic:weekdayInitials:${weekday.toLowerCase()}`)}</label>
        </div>
      ))}
    </div>
  );
};

export default WeekdaysSelect;
