import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import { getRepliesAction, getThreadRepliesAction } from "@store/replies/actions";
import { getRepliesSuccessfulSelector } from "@store/replies/selectors";
import { commentsNavigationIdSelector } from "@store/comments/selectors";

import { ReplyModalViewType } from "./ReplyModal.types";
import { IAuthorDetailCategory, IComment } from "@bbdevcrew/bb_ui_kit_fe";

export function useReplyModalView(categories: IAuthorDetailCategory[] = []) {
  const dispatch = useDispatch();

  const defaultSelectedCategory =
    categories?.find(category => category.selected) || categories?.[0];

  const [currentView, setCurrentView] = useState<ReplyModalViewType>("details");
  const [selectedCategory, setSelectedCategory] =
    useState<IAuthorDetailCategory>(defaultSelectedCategory);

  const commentsNavigationId = useSelector(commentsNavigationIdSelector);
  const repliesFetched = useSelector(getRepliesSuccessfulSelector);

  const fetchReplies = useCallback(
    commentId => dispatch(getRepliesAction(commentId, commentsNavigationId)),
    [dispatch, commentsNavigationId],
  );

  const fetchThreadReplies = useCallback(
    (privateReplyCommentId, threadId, comment, usePrivateReplyId) =>
      dispatch(getThreadRepliesAction(privateReplyCommentId, threadId, comment, usePrivateReplyId)),
    [dispatch],
  );

  useEffect(() => {
    if (defaultSelectedCategory) {
      setSelectedCategory(defaultSelectedCategory);
    }
  }, [defaultSelectedCategory]);

  useEffect(() => {
    if (repliesFetched && categories.length > 0) {
      const savedReplyModalState = localStorage.getItem("SelectedReplyModalState");

      if (savedReplyModalState) {
        const { view, categoryId } = JSON.parse(savedReplyModalState);
        const category = categories.find(c => c.id === categoryId);

        if (category) setSelectedCategory(category);
        if (view) setCurrentView(view as ReplyModalViewType);
      }
    }
  }, [repliesFetched, categories]);

  const handleChangeCategory = (
    category: IAuthorDetailCategory,
    comment?: IComment,
    usePrivateReplyId?: boolean,
  ) => {
    if (category.id === "private_messages") {
      setCurrentView("details");

      if (comment?.object_id && comment.actions.private_reply_capability.thread_id)
        fetchThreadReplies(
          comment.object_id,
          comment.actions.private_reply_capability.thread_id,
          comment,
          usePrivateReplyId,
        );
      else fetchReplies(category.reply_thread?.id);
    } else if (category.id === "hashtag_mentions" || category.id === "listening") {
      setCurrentView("details");
    } else {
      setCurrentView("default");
    }

    localStorage.removeItem("SelectedReplyModalState");
    setSelectedCategory(category);
  };

  return {
    currentView,
    setCurrentView,
    selectedCategory,
    setSelectedCategory,
    handleChangeCategory,
  };
}
