import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ZendeskTicketForm from "@components/_common/MessageCard/createZendeskTicketModal/ZendeskTicketForm"; // eslint-disable-line max-len

import s from "./ZendeskWorkflowAction.module.less";

import { ZendeskWorkflowActionProps } from "./ZendeskWorkflowAction.type";

import { ZendeskIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ZendeskWorkflowAction = ({ form }: ZendeskWorkflowActionProps) => {
  const { t } = useTranslation();
  const zendesk = form.getFieldsValue(true).zendesk;

  useEffect(() => {
    if (zendesk) {
      form.setFieldsValue({ action: zendesk });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={s.bbWorkflowZendeskActionTitle}>
        <div className={s.bbWorkflowZendeskActionTitleIcon}>
          <ZendeskIcon />
        </div>
        <div className={s.bbWorkflowZendeskActionTitleText}>
          {t("pages:workflows:form:actions:zendesk:title")}
        </div>
      </div>
      <ZendeskTicketForm form={form} dataPrefix="action" />
    </>
  );
};

export default ZendeskWorkflowAction;
