import React from "react";

import s from "./AppLayoutContainer.module.less";

import { QuestionCircleIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AppHeaderKnowledgeBaseWidget = () => (
  <div className={s.bbKBStonlyWidget} data-stonly-target="app-global__header--knowledge_base">
    <QuestionCircleIcon />
  </div>
);

export default AppHeaderKnowledgeBaseWidget;
