import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";

import { Form, FormInstance, Grid } from "antd";
import { TTMusicDisclaimer } from "./ttMusicDisclaimer/TTMusicDislaimer";
import { DatePicker, WarningIcon, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import { isTT } from "./helpers/utilsValidations";
import { LOCAL_DATE_FORMAT } from "./helpers/constants";
import PostCreationContext from "./context/PostCreationContext";
import { IPostCreationFormType, IPostItem } from "./helpers/types";

import s from "./index.module.less";
import classNames from "classnames";

interface IPostCreationTabsProps {
  form: FormInstance<IPostCreationFormType>;
  currentPost?: IPostItem;
}

export function PostCreationTabs({ form, currentPost }: IPostCreationTabsProps) {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const dateRequiredError = t("components:publish:postCreationModal:errors:dateRequired");

  const isMobile = screens.md === false;

  const { isScheduled, activePostScheduleTab, setActivePostScheduleTab } =
    useContext(PostCreationContext);

  const [scheduledDate, setScheduledDate] = useState<dayjs.Dayjs>(dayjs());

  const me = useSelector(meSelector);

  const resetScheduledDateField = () => {
    form.setFieldsValue({
      scheduled_at: undefined,
    });
  };

  const handleChangePostScheduleTab = (tab: string) => {
    setActivePostScheduleTab(tab);
    if (tab !== "saveAsDraft") resetScheduledDateField();
  };

  const getDatePickerShowTime = () => {
    const isToday = dayjs().date() === scheduledDate.date();

    const hours = dayjs().hour();
    const todayDisabledHours = Array.from(Array(hours), (_, i) => i);

    const minutes = dayjs().minute();
    const currentHourDisabledMinutes = Array.from(Array(minutes), (_, i) => i);

    return {
      disabledHours: () => (isToday ? todayDisabledHours : null),
      disabledMinutes: () =>
        isToday && hours === scheduledDate.hour() ? currentHourDisabledMinutes : null,
      use12Hours: me?.calendar_12_hour_format,
    };
  };

  const getDatePickerDisabledDate = (date: dayjs.Dayjs) => date && date < dayjs().startOf("day");

  return (
    <div
      className={classNames(s.bbSchedulingSectionWrapper, {
        [s.bbSchedulingSectionWrapperMobile]: isMobile,
      })}
    >
      <div className={s.bbSchedulingSection}>
        <TabsScrollable
          defaultActiveKey="schedule"
          className={s.bbSchedulingTabs}
          activeKey={activePostScheduleTab}
          onChange={handleChangePostScheduleTab}
        >
          <TabPane
            tabKey="schedule"
            tab={t("generic:schedule")}
            className={s.bbSchedulingTab}
            wrapperClassName={s.bbSchedulingTabWrapper}
          />
          <TabPane
            tabKey="postNow"
            tab={t("generic:postNow")}
            className={s.bbSchedulingTab}
            wrapperClassName={s.bbSchedulingTabWrapper}
          />
          <TabPane
            tabKey="saveAsDraft"
            tab={t("generic:saveAsDraft")}
            className={s.bbSchedulingTab}
            wrapperClassName={s.bbSchedulingTabWrapper}
          />
        </TabsScrollable>

        {activePostScheduleTab !== "postNow" && (
          <>
            <Form.Item
              name="scheduled_at"
              hidden={activePostScheduleTab === "saveAsDraft"}
              rules={[
                {
                  required: isScheduled,
                  message: (
                    <span className={s.bbFormFieldError}>
                      <WarningIcon />
                      {dateRequiredError}
                    </span>
                  ),
                },
              ]}
              style={{
                margin: 0,
              }}
            >
              <DatePicker
                // eslint-disable-next-line
                // @ts-ignore
                picker="date"
                round
                showTime={getDatePickerShowTime()}
                name="scheduled_at"
                format={LOCAL_DATE_FORMAT}
                style={{ backgroundColor: "white" }}
                className={s.bbPostCreationBodySection}
                disabledDate={getDatePickerDisabledDate}
                placeholder={t("components:publish:postCreationModal:schedule:placeholder")}
                onSelect={setScheduledDate}
                // eslint-disable-next-line
                // @ts-ignore
                value={scheduledDate}
              />
            </Form.Item>
            {activePostScheduleTab !== "saveAsDraft" && (
              <div className={s.bbPostCreationBodySectionTimezone}>{me?.timezone}</div>
            )}
          </>
        )}
        {currentPost && isTT(currentPost) && activePostScheduleTab !== "saveAsDraft" && (
          <TTMusicDisclaimer post={currentPost} />
        )}
      </div>
    </div>
  );
}
