import React, { FC } from "react";

import s from "./AutomatedHidingMenu.module.less";

import { AlertTriangleIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AutomatedHidingDisclaimer: FC<{ message: string }> = ({ message }) => {
  return (
    <div className={s.bbAutomatedHidingMenuDisclaimer}>
      <AlertTriangleIcon />
      <p> {message}</p>
    </div>
  );
};

export default AutomatedHidingDisclaimer;
