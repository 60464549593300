import React, { FC } from "react";
import classNames from "classnames/bind";

import { IWorkflowItemCardProps } from "../helpers";

import s from "./WorkflowItemCard.module.less";

import { Button, PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";
import WorkflowArrowDownIcon from "@assets/WorkflowArrowDown.svg";
import WorkflowAdditionalActionPointerIcon from "@assets/WorkflowAdditionalActionPointer.svg";

const WorkflowItemCard: FC<IWorkflowItemCardProps> = ({
  title,
  icon: Icon,
  className = "",
  pointArrow = false,
  hasAdditionalAction = false,
  pointAdditionalAction = false,
  onAdditionalActionClick,
  children,
}) => (
  <div className={classNames(s.WorkflowItemCard, className)}>
    <h2 className={s.WorkflowItemCardTitle}>
      <span className={s.WorkflowItemCardTitleText}>
        {Icon && <Icon />} {title}
      </span>
    </h2>
    <div className={s.WorkflowItemCardContent}>{children}</div>
    {pointArrow && (
      <div className={s.WorkflowItemCardArrow}>
        <WorkflowArrowDownIcon />
      </div>
    )}
    {pointAdditionalAction && (
      <div className={s.WorkflowItemCardArrow}>
        <Button
          _type="link"
          onClick={onAdditionalActionClick}
          className={s.WorkflowItemCardAddActionBtn}
        >
          <PlusIcon />
        </Button>
        <WorkflowArrowDownIcon />
      </div>
    )}
    {hasAdditionalAction && (
      <div className={s.WorkflowItemCardArrow}>
        <WorkflowAdditionalActionPointerIcon />
      </div>
    )}
  </div>
);

export default WorkflowItemCard;
