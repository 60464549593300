import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import { Form } from "antd";
import {
  Button,
  Input,
  Modal,
  AssetsSingleFilter,
  Help,
  FormErrorMessage,
  KeywordsFilter,
} from "@bbdevcrew/bb_ui_kit_fe";
import { SourceKeyword } from "./SourceKeyword";

import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";

import s from "./Sources.module.less";

import { debounce, IGetAutocompleteOptionsPayload } from "@bbdevcrew/bb_ui_kit_fe";
import { ISourceModalForm, SourceModalProps } from "./Sources.types";
import { getPlatformIconBasicColor } from "@bbdevcrew/bb_ui_kit_fe";

import { HashIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const SourceModal = ({
  isOpen,
  isSubmitting,
  editingSource,
  onClose,
  onSubmit,
}: SourceModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "components:listen:sources" });
  const { t: tGeneric } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<ISourceModalForm>();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isYTSelected, setIsYTSelected] = useState(false);
  const [hasKeywordError, setHasKeywordError] = useState(false);

  const isEditMode = isOpen && !!editingSource;
  const title = isEditMode ? t("editSourceModal:title") : t("addSourceModal:title");
  const submitButtonText = isEditMode
    ? tGeneric("generic:save")
    : t("addSourceModal:submitButtonText");

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    if (editingSource) {
      form.setFieldsValue({
        asset_id: editingSource.asset_id,
        source_id: editingSource.source_id,
        excluded_keywords: editingSource.excluded_keywords,
      });
      if (editingSource.platform) {
        setIsYTSelected(!!editingSource.platform.includes("youtube"));
      }
    }
    if (!isOpen) {
      setIsYTSelected(false);
      form.resetFields();
    }
  }, [form, editingSource, isOpen]);

  const onConfirmUnsavedChanges = () => {
    setIsConfirmationOpen(false);
    form.resetFields();
    onClose();
  };

  const onAssetsChange = (assetId: string) => {
    setIsYTSelected(
      autocompleteOptions.asset.find(asset => asset.id === assetId)?.platform === "youtube",
    );
  };

  const onInternalSubmit = (data: ISourceModalForm) => {
    onSubmit({
      ...data,
      type: isYTSelected ? "keyword" : "hashtag",
    });
  };

  return (
    <Modal
      centered
      responsive
      hideFooter
      onCancel={() => setIsConfirmationOpen(true)}
      open={isOpen}
      title={title}
      width={800}
    >
      {editingSource && (
        <div className={s.bbSourceModalPlatformIconWrapper}>
          {t("form:platform")}
          <div className={s.bbSourceModalPlatformIcon}>
            {getPlatformIconBasicColor(editingSource?.platform)}
          </div>
        </div>
      )}
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onInternalSubmit}
        initialValues={editingSource}
        requiredMark="optional"
        scrollToFirstError
      >
        <Form.Item
          name="asset_id"
          data-cy="source-asset"
          label={
            <div className={s.bbSourceModalFormItemLabel}>
              <span>{t("form:asset:label")}</span>
              <Help
                tooltipProps={{
                  title: t("form:asset:helperText"),
                }}
              />
            </div>
          }
          className={s.bbSourceModalFormItem}
          rules={[
            {
              required: true,
              message: <FormErrorMessage>{t("form:asset:error")}</FormErrorMessage>,
            },
          ]}
        >
          <AssetsSingleFilter
            id="asset_ids"
            additionalLabelFields={["id"]}
            fetchFilters="assets_for_listen_source"
            className={s.bbSourceModalAssetsSelect}
            onChange={onAssetsChange}
            data-cy="source-asset-input"
            customprops={{
              form,
              name: "asset_id",
            }}
            placeholder={t("form:asset:placeholder")}
            _size="md"
            autocompleteOptions={autocompleteOptions}
            getAutocompleteOptions={getAutocompleteOptions}
          />
        </Form.Item>

        <Form.Item
          name="source_id"
          data-cy="source-label"
          key={isYTSelected ? "keyword" : "hashtag"}
          label={t(`form:${isYTSelected ? "keyword" : "hashtag"}:label`)}
          className={s.bbSourceModalFormItem}
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t(`form:${isYTSelected ? "keyword" : "hashtag"}:error`)}
                </FormErrorMessage>
              ),
            },
            {
              validator(_, value) {
                if (value && hasKeywordError && isYTSelected) {
                  return Promise.reject(
                    <FormErrorMessage>{t("form:keyword:maxError")}</FormErrorMessage>,
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {isYTSelected ? (
            <SourceKeyword form={form} setError={setHasKeywordError} />
          ) : (
            <Input
              _size="md"
              name="source_id"
              data-cy="source-label-input"
              placeholder={t("form:hashtag:placeholder")}
              className={s.bbSourceModalSourceLabelInput}
              prefixEl={
                <div className={s.bbSourceModalSourceLabelInputPrefix}>
                  <HashIcon />
                </div>
              }
            />
          )}
        </Form.Item>
        {isYTSelected && (
          <Form.Item
            name="excluded_keywords"
            label={t("form:excluded_keywords:label")}
            className={s.bbSourceModalFormItem}
          >
            <KeywordsFilter
              inputClassName={s.bbSourceModalExcludeKeywords}
              indicatorClassName={s.bbSourceModalExcludeKeywordsIndicator}
              customprops={{
                form,
                name: "excluded_keywords",
              }}
            />
          </Form.Item>
        )}
        <div className={s.bbSourceModalFooter}>
          <Button _type="secondary" type="button" onClick={() => setIsConfirmationOpen(true)}>
            {tGeneric("generic:cancel")}
          </Button>
          <Button _type="primary" type="submit" loading={isSubmitting}>
            {submitButtonText}
          </Button>
        </div>
      </Form>

      <Modal
        centered
        responsive
        onCancel={() => setIsConfirmationOpen(false)}
        onOk={onConfirmUnsavedChanges}
        cancelLabel={t("unsavedChanges:cancelText")}
        confirmLabel={tGeneric("generic:discardChanges")}
        open={isConfirmationOpen}
        title={t("unsavedChanges:title")}
      >
        <p>{t("unsavedChanges:text")}</p>
      </Modal>
    </Modal>
  );
};
