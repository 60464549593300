import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Col, Row } from "antd";
import ManagedSummary from "./summary";
import { ManagedProtects } from "./protect/Protect";
import { ManagedEngage } from "./engage/Engage";
import ShareReportButton from "./ShareReportButton";
import MobileFiltersButton from "@components/_common/AppFilter/mobileFiltersButton";

import { postDashboardAction } from "@store/dashboard/actions";
import { resetTriggerFilterClickWithoutUrlAction } from "@store/filters/actions";

import {
  getDashboardSelectorPending,
  getDashboardManagedSummarySelector,
  hiddenTimelineSelector,
} from "@store/dashboard/selectors";
import { isFilteringWithoutUrlSelector } from "@store/filters/selectors";
import { meSelector, meSuccessfulSelector } from "@store/me/selectors";

import s from "./Transparency.module.less";

import { useAppFilters } from "@utils/appFilters";
import { useCurrentFilters } from "@utils/useCurrentFilters";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { IDashboardPayload } from "@store/dashboard/types";

export const Transparency = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shouldDashboardFetch = useRef(true);
  const { updateSearchDataAndUrl } = useAppFilters();
  const filters = useCurrentFilters();

  const me = useSelector(meSelector);
  const isFiltering = useSelector(isFilteringWithoutUrlSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const dashboardLoading = useSelector(getDashboardSelectorPending);
  const dashboardSummaryData = useSelector(getDashboardManagedSummarySelector);
  const dashboardHiddenTimelineData = useSelector(hiddenTimelineSelector);

  const getDashboardData = useCallback(
    (data: IDashboardPayload) => dispatch(postDashboardAction(data)),
    [dispatch],
  );

  const triggerFilterReset = useCallback(
    () => dispatch(resetTriggerFilterClickWithoutUrlAction()),
    [dispatch],
  );

  const getManagedData = useCallback(() => {
    if (shouldDashboardFetch.current) {
      getDashboardData({
        filters,
        components: [
          "action_summary_stats",
          "hidden_comments",
          "hidden_breakdown",
          "explorer_overview_care",
          "hidden_timeline",
        ],
      });
      shouldDashboardFetch.current = false;
    }
  }, [filters, getDashboardData]);

  useEffect(() => {
    if (me && meFetched && filters && !dashboardLoading) {
      getManagedData();
    }
  }, [me, meFetched, filters, getManagedData, dashboardLoading]);

  const onFilter = () => {
    shouldDashboardFetch.current = true;
    triggerFilterReset();
  };

  useEffect(() => {
    if (filters && isFiltering) onFilter();
    // eslint-disable-next-line
  }, [isFiltering, filters]);

  const filterToSpecificItem = (item: IFilters) => {
    const newDataStats = {
      ...filters,
      ...item,
    };
    updateSearchDataAndUrl(newDataStats, "id");
  };

  return meFetched && filters ? (
    <div className={s.bbManagedPanel}>
      <Row gutter={[20, 28]}>
        <Col xs={24}>
          <div className={s.bbManagedPanelHeader}>
            <h5 className={s.bbManagedPanelTitle}>{t("components:managed:summary:title")}</h5>
            <div className={s.bbManagedPanelHeaderButtons}>
              <MobileFiltersButton />
              <ShareReportButton />
            </div>
          </div>
          <ManagedSummary data={dashboardSummaryData} isLoading={dashboardLoading} />
        </Col>
        <Col xs={24}>
          <ManagedProtects
            filters={filters}
            filterToSpecificItem={filterToSpecificItem}
            isLoading={dashboardLoading}
            timelineData={dashboardHiddenTimelineData}
          />
        </Col>
        <Col xs={24}>
          <ManagedEngage
            filters={filters}
            filterToSpecificItem={filterToSpecificItem}
            isLoading={dashboardLoading}
          />
        </Col>
      </Row>
    </div>
  ) : null;
};
