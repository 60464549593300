import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Empty } from "antd";
import { ReportsSuffix } from "./ReportsSuffix";
import { InputWhiteBordered, Modal, Loading, ItemCard } from "@bbdevcrew/bb_ui_kit_fe";

import {
  scheduledReportsSelector,
  fetchedScheduledReportsSelector,
  fetchingScheduledReportsSelector,
  deletingScheduledReportSelector,
} from "@store/reports/selectors";
import { deleteScheduledReportAction, getScheduledDocumentsAction } from "@store/reports/actions";

import s from "./ManageReportsModal.module.less";

import { cropText } from "@bbdevcrew/bb_ui_kit_fe";
import { IScheduledReport } from "@store/reports/types";
import { IManageReportsModalProps, MAX_REPORT_NAME_LENGTH } from "./ManageReportsModal.types";

import { SearchIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ManageReportsModal: React.FC<IManageReportsModalProps> = ({
  open,
  onEditReportClick,
  ...restProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [reports, setReports] = useState<IScheduledReport[]>([]);

  const scheduledReports = useSelector(scheduledReportsSelector);
  const fetchingScheduledReports = useSelector(fetchingScheduledReportsSelector);
  const fetchedScheduledReports = useSelector(fetchedScheduledReportsSelector);
  const deletingScheduledReport = useSelector(deletingScheduledReportSelector);

  const getScheduledDocuments = useCallback(
    () => dispatch(getScheduledDocumentsAction()),
    [dispatch],
  );

  useEffect(() => {
    if (open) getScheduledDocuments();
  }, [open, getScheduledDocuments]);

  useEffect(() => {
    if (fetchedScheduledReports) setReports(scheduledReports);
  }, [fetchedScheduledReports, scheduledReports, setReports]);

  useEffect(() => {
    if (searchTerm) {
      setReports(
        scheduledReports.filter(report =>
          report.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
        ),
      );
    } else setReports(scheduledReports);
    // eslint-disable-next-line
  }, [searchTerm]);

  const deleteScheduledReport = useCallback(
    id => {
      dispatch(deleteScheduledReportAction(id));
    },
    [dispatch],
  );

  const onClickDeleteScheduledReport = (id: string) => {
    deleteScheduledReport(id);
  };

  return (
    <Modal
      noPadding
      hideFooter
      hideHeader
      open={open}
      width={600}
      hideCloseIcon={true}
      {...restProps}
    >
      <div className={s.bbManageReportsModal}>
        <div className={s.bbManageReportsModalHeader}>
          <div className={s.bbManageReportsModalHeaderTitle}>
            {t("components:managed:reportsList:modalTitle")}
          </div>
          {fetchedScheduledReports && (
            <div className={s.bbManageReportsModalHeaderSearch}>
              <InputWhiteBordered
                _size="sm"
                id="report-search"
                value={searchTerm}
                wrapperClassName={s.bbSearchWrapper}
                placeholder={t("generic:search")}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(event.target.value)
                }
                prefixEl={
                  <span className={s.bbSearchIcon}>
                    <SearchIcon />
                  </span>
                }
              />
            </div>
          )}
        </div>
        <div className={s.bbManageReportsModalList}>
          {deletingScheduledReport && (
            <div className={s.bbManageReportsModalListDeleting}>
              <Loading isLoading />
            </div>
          )}
          {fetchingScheduledReports ? (
            <div className={s.bbManageReportsModalListLoading}>
              <Loading isLoading />
            </div>
          ) : fetchedScheduledReports && reports.length ? (
            <>
              {reports.map(report => {
                return (
                  <ItemCard
                    key={report.id}
                    name={cropText(report.name || "", MAX_REPORT_NAME_LENGTH)}
                    tooltipProps={
                      report.name && report.name.length > MAX_REPORT_NAME_LENGTH
                        ? {
                            title: report.name,
                          }
                        : undefined
                    }
                    suffix={
                      <ReportsSuffix
                        reportType={report.type}
                        numberOfUsers={report.user_ids?.length || 0}
                      />
                    }
                    actions={[
                      {
                        key: "edit",
                        label: t("generic:edit"),
                        onActionClick: () => onEditReportClick(report),
                      },
                      {
                        key: "delete",
                        label: t("generic:delete"),
                        onActionClick: () => report.id && onClickDeleteScheduledReport(report.id),
                        confirmationText: t(
                          "components:managed:reportsList:reportDeleteConfirmation",
                        ),
                      },
                    ]}
                  />
                );
              })}
            </>
          ) : (
            <Empty
              className={s.bbManageReportsModalListEmpty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("components:empty:description")}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
