import { XAxisProps, YAxisProps } from "recharts";
import { numberToQuantity } from "@bbdevcrew/bb_ui_kit_fe";
import { ITimelineProps } from "./TimelineChart.types";

export const HEIGHT = 200;
export const CONTAINER_HEIGHT = 280;

export const getTimelineDisplayData = (data: ITimelineProps["data"] = []) => {
  // eslint-disable-next-line
  return data.map(({ total, ...displayData }) => displayData);
};

export const legendTooltipStyle = { width: 270, fontSize: 14 };

export const CHART_MARGIN = {
  left: 20,
  right: 90,
};

export const MOBILE_CHART_MARGIN = {
  left: -30,
  right: 90,
};

export const xAxisProps: XAxisProps = {
  dataKey: "date",
  height: 40,
};

export const yAxisProps: YAxisProps = {
  type: "number",
  tickFormatter: numberToQuantity,
};
