import React from "react";
import { useTranslation } from "react-i18next";

import s from "./ClientPlanBadge.module.less";

import { IClientPlan, ClientPlanType } from "@store/me/types";

import { GrowthIcon, PrimeIcon, AgencyIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const getPlanIcon = (type: ClientPlanType = "ESSENTIAL") => {
  const icons: Record<ClientPlanType, JSX.Element> = {
    ESSENTIAL: <AgencyIcon />,
    GROWTH: <GrowthIcon />,
    GROWTH_SEMI: <GrowthIcon />,
    PRIME: <PrimeIcon />,
    AGENCY: <AgencyIcon />,
  };
  return icons[type];
};

export interface IClientPlanBadgeProps {
  type: IClientPlan;
  showTitle?: boolean;
}

const ClientPlanBadge: React.FC<IClientPlanBadgeProps> = ({ type, showTitle = true }) => {
  const { t } = useTranslation();

  return (
    <div
      className={s.bbClientPlanBadge}
      data-stonly-target={`app-global__header--${
        type.id === "GROWTH_SEMI" ? "growth" : type.id?.toLowerCase()
      }`}
    >
      <div className={s.bbClientPlanBadgeIcon}>{getPlanIcon(type.id)}</div>
      {showTitle && <span>{t(`header:clientPlanBadge:${type.id.toLowerCase()}`)}</span>}
    </div>
  );
};

export default ClientPlanBadge;
