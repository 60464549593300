import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  createTagAction,
  updateTagAction,
  createSubTagAction,
  updateSubTagAction,
  resetTagsManagementDataAction,
} from "@store/tagsManagement/actions";
import {
  fetchingTagSelector,
  fetchedTagSelector,
  fetchedTagFailedSelector,
  fetchedTagFailedMessageSelector,
  getTagsListSelector,
} from "@store/tagsManagement/selectors";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { ITag, ISubTag } from "@store/tagsManagement/types";

interface IUseTagModalProps {
  defaultTagType?: string;
  isSubTag?: boolean;
}

const useTagModal = ({ defaultTagType = "custom_tags" }: IUseTagModalProps = {}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTag, setEditingTag] = useState<ITag | ISubTag | undefined>(undefined);
  const [activeTabKey, setActiveTabKey] = useState(defaultTagType);

  const tagsGroups = useSelector(getTagsListSelector);
  const fetchingTag = useSelector(fetchingTagSelector);
  const fetchedTag = useSelector(fetchedTagSelector);
  const fetchedTagFailed = useSelector(fetchedTagFailedSelector);
  const fetchedTagFailedMessage = useSelector(fetchedTagFailedMessageSelector);

  const isBBTagsTab = activeTabKey === "brand_bastion_tags";

  const tagsList = isBBTagsTab
    ? tagsGroups.find(group => group.key === "brand_bastion_tags")?.children || []
    : undefined;

  const closeModal = useCallback(() => {
    setEditingTag(undefined);
    setIsModalOpen(false);
  }, []);

  const openCreateModal = useCallback(() => {
    setEditingTag(undefined);
    setIsModalOpen(true);
  }, []);

  const openEditModal = useCallback((tag: ITag | ISubTag) => {
    setEditingTag(tag);
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (fetchedTag) {
      addToast({
        type: "success_accent",
        title: t("components:integrations:toastMessages:success"),
      });

      closeModal();

      return () => {
        dispatch(resetTagsManagementDataAction());
      };
    }
  }, [fetchedTag, t, closeModal, dispatch]);

  useEffect(() => {
    if (fetchedTagFailed && fetchedTagFailedMessage) {
      addToast({
        type: "danger_accent",
        title: fetchedTagFailedMessage,
      });
    }
  }, [fetchedTagFailed, fetchedTagFailedMessage]);

  const handleSubmit = useCallback(
    (data: ITag | ISubTag) => {
      if (editingTag) {
        if ("parent_id" in editingTag) {
          dispatch(updateSubTagAction({ ...data, type: activeTabKey, id: editingTag.id }));
        } else if ("title" in data) {
          dispatch(
            updateTagAction({
              ...data,
              key: editingTag.key,
              type: activeTabKey,
            }),
          );
        }
      } else {
        if ("parent_id" in data) {
          dispatch(createSubTagAction({ ...data, type: activeTabKey }));
        } else {
          dispatch(createTagAction({ ...data, type: activeTabKey }));
        }
      }

      closeModal();
    },
    [activeTabKey, dispatch, editingTag, closeModal],
  );

  const getModalTitle = useCallback(() => {
    return t(
      `components:controlPanel:tagsManagement:modal:${
        isModalOpen && !!editingTag ? "edit" : "create"
      }Title:${activeTabKey}`,
    );
  }, [activeTabKey, editingTag, isModalOpen, t]);

  return {
    isModalOpen,
    editingTag,
    activeTabKey,
    fetchingTag,
    tagsList,
    isBBTagsTab,
    setActiveTabKey,
    openCreateModal,
    openEditModal,
    closeModal,
    handleSubmit,
    getModalTitle,
  };
};

export default useTagModal;
