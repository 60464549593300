import { action } from "typesafe-actions";

import { GET_INBOX_STATS, GET_INBOX_STATS_SUCCESS, GET_INBOX_STATS_FAILURE } from "./actionTypes";
import { IRepliesStatsResponse } from "./types";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export const getInboxStatsAction = (
  filters: IFilters,
  featureName?: string,
  subfeatureName?: string,
) => action(GET_INBOX_STATS, { filters, subfeatureName, featureName });
export const getInboxStatsSuccess = (payload: IRepliesStatsResponse) =>
  action(GET_INBOX_STATS_SUCCESS, payload);
export const getInboxStatsFailure = () => action(GET_INBOX_STATS_FAILURE);
