import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import UserTagInput from "../../../ImageView/UserTagInput";
import UserRow from "../UserRow";

import { useTagsContext } from "../../../TagsModal.helpers";
import { MAX_COLLABORATORS } from "./CollaboratorsView.helpers";

import s from "./../UserTags.module.less";

import { ICollaboratorsProps } from "./CollaboratorsView.types";
import TagHeader from "../TagHeader";

export const CollaboratorsView: React.FC<ICollaboratorsProps> = ({ collaborators }) => {
  const { t } = useTranslation();
  const [collaboratorInput, setCollaboratorInput] = useState(false);
  const { addTag, isReel, maxTagsReached } = useTagsContext();

  const hasCollaborators = collaborators.length > 0;
  const inviteCollaboratorsDisabled = collaborators.length >= MAX_COLLABORATORS || maxTagsReached;

  return (
    <div className={s.bbUserTagsCollaborators}>
      <TagHeader
        title={
          hasCollaborators
            ? t("components:publish:postCreationModal:mediaTags:subtitle:collaborators")
            : undefined
        }
        disabled={
          inviteCollaboratorsDisabled
            ? maxTagsReached
              ? t("components:publish:postCreationModal:mediaTags:limitReached")
              : t("components:publish:postCreationModal:mediaTags:collaboratorLimitReached")
            : undefined
        }
        addLabel={t("components:publish:postCreationModal:mediaTags:inviteCollaborators")}
        onAddClick={() => setCollaboratorInput(true)}
      />
      {hasCollaborators && (
        <>
          <p className={s.bbUserTagsDescription}>
            {t("components:publish:postCreationModal:mediaTags:collaboratorDescription")}
          </p>
          <ul className={s.bbUserTagsList}>
            {collaborators.map(tag => (
              <UserRow key={tag.username} tag={tag} />
            ))}
          </ul>
        </>
      )}
      {collaboratorInput && (
        <UserTagInput
          x={0.5}
          y={isReel ? 0.6 : 0.5}
          onAdd={tag => {
            addTag({ ...tag, collaborator: true });
            setCollaboratorInput(false);
          }}
          onCancel={() => setCollaboratorInput(false)}
        />
      )}
    </div>
  );
};
