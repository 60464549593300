import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Checkbox,
  FormErrorMessage,
  InlineAlert,
  InputWhiteBordered,
  SimpleSelectMulti,
  SimpleSelectSingle,
  TagInput,
  Toggle,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";

import OptionsPanel from "../OptionsPanel";

import { getYouTubeCategoriesAction, getYouTubePlaylistsAction } from "@store/publishings/actions";
import {
  fetchingYouTubePlaylistsSelector,
  youtubeCategoriesSelector,
  youTubePlaylistsSelector,
} from "@store/publishings/selectors";

import { useFuseSearch } from "@utils/useFuseSearch";

import {
  defaultYouTubeOptions,
  YOUTUBE_LICENSES,
  YOUTUBE_OPTIONS_DEFAULT_COUNTRY_CODE,
  YOUTUBE_PRIVACY_STATUSSES,
  YOUTUBE_VALIDATION_MAX_TITLE,
} from "./YouTubeOptions.helpers";
import { YT_CHARS_REGEX } from "../helpers/constants";

import s from "./YouTubeOptions.module.less";

import { IYouTubeOptions } from "@bbdevcrew/bb_ui_kit_fe";
import { ITagInputWrapperProps, IYouTubeOptionsProps } from "./YouTubeOptions.types";

const TagInputWrapper: React.FC<ITagInputWrapperProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <TagInput
      tags={value}
      setTags={(tags: string[]) => onChange?.(tags)}
      placeholder={t("components:publish:postCreationModal:youtubeOptions:tagsPlaceholder")}
      maxLength={500}
    />
  );
};

export const YouTubeOptions: React.FC<IYouTubeOptionsProps> = ({ post, updatePost, form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const youtubeCategories = useSelector(youtubeCategoriesSelector(post.asset.id));
  const youtubePlaylists = useSelector(youTubePlaylistsSelector(post.asset.id));
  const fetchingPlaylists = useSelector(fetchingYouTubePlaylistsSelector);
  const [query, setQuery] = useState("");

  const youtubeCategoryOptions = useMemo(
    () =>
      [...youtubeCategories]
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(({ title: label, id: value }) => ({
          label,
          value,
        })),
    [youtubeCategories],
  );

  const youtubePlaylistOptions = useMemo(
    () =>
      youtubePlaylists.map(({ title: label, id: value }) => ({
        label,
        value,
      })),
    [youtubePlaylists],
  );

  const filteredYoutubeCategoryOptions = useFuseSearch<{ label: string; value: string }>({
    collection: youtubeCategoryOptions,
    query,
    fields: ["label", "value"],
    searchOptions: {
      threshold: 0.2,
    },
  });

  const privacyStatusOptions = useMemo(
    () =>
      YOUTUBE_PRIVACY_STATUSSES.map(option => ({
        label: t(
          `components:publish:postCreationModal:youtubeOptions:privacyStatus:options:${option}`,
        ),
        value: option,
      })),
    [t],
  );

  const licenseOptions = useMemo(
    () =>
      YOUTUBE_LICENSES.map(option => ({
        label: t(`components:publish:postCreationModal:youtubeOptions:licenseOptions:${option}`),
        value: option,
      })),
    [t],
  );

  useEffect(() => {
    dispatch(getYouTubeCategoriesAction(post.asset.id, YOUTUBE_OPTIONS_DEFAULT_COUNTRY_CODE));
    dispatch(getYouTubePlaylistsAction(post.asset.id));
  }, [post.asset.id, dispatch]);

  useEffect(() => {
    if (post.youtubeOptions) {
      const optionsKeys: (keyof IYouTubeOptions)[] = [
        "title",
        "category_id",
        "playlists",
        "privacy_status",
        "is_video_for_kids",
        "embeddable",
        "notify_subscribers",
        "license",
        "tags",
      ];
      form.setFieldsValue({
        ...optionsKeys.reduce(
          (result, propertyName) => ({
            ...result,
            [`youtubeOptions.${propertyName}`]: post.youtubeOptions?.[propertyName],
          }),
          {},
        ),
      });
    }
  }, [form, post.youtubeOptions]);

  useEffect(() => {
    updatePost({
      ...post,
      youtubeOptions: {
        title: "",
        ...defaultYouTubeOptions,
        ...post.youtubeOptions,
      },
    });
  }, []); // eslint-disable-line

  return (
    <>
      <OptionsPanel
        defaultOpenOnMobile
        title={t("components:publish:postCreationModal:youtubeOptions:heading")}
        className={s.bbYouTubeOptions}
      >
        <Form.Item
          name="youtubeOptions.title"
          label={t("components:publish:postCreationModal:youtubeOptions:title")}
          rules={[
            {
              required: true,
              message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
            },
            {
              max: YOUTUBE_VALIDATION_MAX_TITLE,
              message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
            },
            {
              pattern: YT_CHARS_REGEX,
              message: (
                <FormErrorMessage>
                  {t("components:publish:postCreationModal:errors:youtube:descriptionText")}
                </FormErrorMessage>
              ),
            },
          ]}
        >
          <InputWhiteBordered _size="sm" maxLength={100} />
        </Form.Item>
        <div className={s.bbYouTubeOptionsInlineWrapper}>
          <Form.Item
            name="youtubeOptions.category_id"
            label={t("components:publish:postCreationModal:youtubeOptions:category")}
            rules={[
              {
                required: true,
                validateTrigger: "onChange",
                message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
              },
            ]}
          >
            <SimpleSelectSingle
              placeholder={t(
                "components:publish:postCreationModal:youtubeOptions:categoryPlaceholder",
              )}
              searchInputPlaceholder={t("generic:search")}
              options={query ? filteredYoutubeCategoryOptions : youtubeCategoryOptions}
              allowSearch
              showResultsBeforeSearch
              onSearch={value => setQuery(value || "")}
              emptyStatePlaceholder={t("generic:emptyTable")}
            />
          </Form.Item>
          <Form.Item
            name="youtubeOptions.privacy_status"
            rules={[
              {
                required: true,
                message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
              },
            ]}
            label={t("components:publish:postCreationModal:youtubeOptions:privacyStatus:label")}
          >
            <SimpleSelectSingle options={privacyStatusOptions} placeholder={t("generic:select")} />
          </Form.Item>
        </div>
        <Form.Item
          name="youtubeOptions.playlists"
          label={t("components:publish:postCreationModal:youtubeOptions:playlists")}
        >
          <SimpleSelectMulti
            placeholder={t(
              "components:publish:postCreationModal:youtubeOptions:playlistPlaceholder",
            )}
            multiPlaceholder={t("generic:countSelected", {
              count: post.youtubeOptions?.playlists?.length || 0,
            })}
            options={youtubePlaylistOptions}
            optionsLoading={fetchingPlaylists}
            emptyStatePlaceholder={t("generic:emptyTable")}
          />
        </Form.Item>
        <Form.Item
          label={t("components:publish:postCreationModal:youtubeOptions:forKids")}
          valuePropName="checked"
          name="youtubeOptions.is_video_for_kids"
        >
          <Toggle id="youtubeOptions.is_video_for_kids" />
        </Form.Item>
        <Form.Item
          label={t("components:publish:postCreationModal:youtubeOptions:tags")}
          name="youtubeOptions.tags"
        >
          <TagInputWrapper />
        </Form.Item>
        <Form.Item
          name="youtubeOptions.license"
          label={t("components:publish:postCreationModal:youtubeOptions:license")}
        >
          <SimpleSelectSingle options={licenseOptions} placeholder={t("generic:select")} />
        </Form.Item>
        <div className={s.bbYouTubeOptionsCheckbox}>
          <Form.Item
            label={t("components:publish:postCreationModal:youtubeOptions:embeddable")}
            valuePropName="checked"
            name="youtubeOptions.embeddable"
          >
            <Checkbox id="youtubeOptions.embeddable" />
          </Form.Item>
        </div>
        <div className={s.bbYouTubeOptionsCheckbox}>
          <Form.Item
            label={t("components:publish:postCreationModal:youtubeOptions:notifySubscribers")}
            valuePropName="checked"
            name="youtubeOptions.notify_subscribers"
          >
            <Checkbox id="youtubeOptions.notify_subscribers" />
          </Form.Item>
        </div>
      </OptionsPanel>
      <InlineAlert className={s.bbYouTubeOptionsInlineAlert}>
        {t("components:publish:postCreationModal:youtubeOptions:shortsExplainer")}
      </InlineAlert>
    </>
  );
};
