import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import WorkflowsContent from "./WorkflowsContent";
import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import {
  workflowInitialFetchDoneSelector,
  workflowsFetchedSelector,
} from "@store/workflows/selectors";
import { meLoadingSelector } from "@store/me/selectors";
import { getWorkflowsAction, resetWorkflowsAction } from "@store/workflows/actions";

import { useWorkflows } from "./helpers";

import s from "./Workflows.module.less";

export const Workflows = () => {
  const dispatch = useDispatch();
  const { activeCategory } = useWorkflows();

  const meFetching = useSelector(meLoadingSelector);
  const workflowsFetchedBefore = useSelector(workflowInitialFetchDoneSelector);
  const workflowsFetched = useSelector(workflowsFetchedSelector);

  const initialFetchPending = !workflowsFetchedBefore && !workflowsFetched;
  const showLoading = initialFetchPending || meFetching;

  useEffect(() => {
    if (activeCategory) {
      dispatch(getWorkflowsAction());
    }

    return () => {
      dispatch(resetWorkflowsAction());
    };
  }, [activeCategory, dispatch]);

  return (
    <div className={s.bbWorkflows} data-cy="workflows-container">
      {showLoading ? <Loading isLoading type="bb" /> : <WorkflowsContent />}
    </div>
  );
};
