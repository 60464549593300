import dayjs from "dayjs";
import { useSelector } from "react-redux";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Form, FormInstance } from "antd";
import { Button, ButtonPermissionGuard } from "@bbdevcrew/bb_ui_kit_fe";

import {
  hasCaptionErrors,
  hasTTRestrictedError,
  validateFileTypes,
  validateFileSizes,
  validateRequiredFields,
  validateMediaCombination,
  validateTTDiscloseContentOptions,
  validateVideoDurationBeforePublish,
} from "./helpers/utilsValidations";
import { getRelativePath } from "./helpers/utilsGeneric";

import { mapIgTagsToBackend } from "./helpers";
import useUpdatePost from "./helpers/useUpdatePost";
import PostCreationContext from "./context/PostCreationContext";

import {
  creatingPostPublishingSelector,
  updatingPostPublishingSelector,
} from "@store/publishings/selectors";
import { internalNotesSelector } from "@store/internalNotes/selectors";

import s from "./index.module.less";

import usePermissions from "@utils/usePermissions";
import { IPostCreationFormType } from "./helpers/types";
import { IPostPayload, ICreatePublishingPayload } from "@store/publishings/types";

import { CalendarIcon, EditIcon, SendIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IPostCreationButtonProps {
  form: FormInstance<IPostCreationFormType>;
  disabled: boolean;
  hasSelectedAssets: boolean;
  onFinish: (payload: ICreatePublishingPayload) => void;
}

export function PostCreationButton({
  form,
  disabled,
  hasSelectedAssets,
  onFinish,
}: IPostCreationButtonProps) {
  const { t } = useTranslation();
  const permissions = usePermissions();

  const { posts, activePostScheduleTab, setPosts, setIsScheduled } =
    useContext(PostCreationContext);

  const { updatePost } = useUpdatePost();

  const creatingPostPublishing = useSelector(creatingPostPublishingSelector);
  const updatingPostPublishing = useSelector(updatingPostPublishingSelector);
  const internalNotes = useSelector(internalNotesSelector);

  const isButtonLoading = creatingPostPublishing || updatingPostPublishing;

  const allValidationsPassed = () =>
    !hasTTRestrictedError(posts) &&
    !hasCaptionErrors(posts) &&
    validateRequiredFields(posts, setPosts) &&
    validateTTDiscloseContentOptions(posts, setPosts) &&
    validateFileTypes(posts, updatePost) &&
    validateFileSizes(posts, updatePost) &&
    validateMediaCombination(posts, setPosts) &&
    validateVideoDurationBeforePublish(posts);

  const onSubmitPostClick = (type: "schedule" | "saveAsDraft" | "postNow") => {
    if (allValidationsPassed()) {
      setIsScheduled(type === "schedule");

      const formattedAssets = posts.map(post => {
        const orderedFiles = post.orderedFileIds
          ?.map(id => post.uploadedFiles.find(obj => obj.uid === id))
          .filter(file => file);
        const orderedFileLinks = orderedFiles?.map(file => file?.name_link || file?.url);

        const alt_texts = post.altText
          ? Object.keys(post.altText).map(fileId => {
              const file = post.uploadedFiles.find(({ uid }) => {
                return uid === fileId.toString();
              });

              return {
                url: !!file?.name_link ? file?.name_link : getRelativePath(file?.url || ""),
                text: post?.altText?.[fileId] || "",
              };
            })
          : undefined;

        const media_tags = post.mediaTags
          ? Object.keys(post.mediaTags).map(fileId => {
              const localFile = post.uploadedFiles.find(({ uid }) => {
                return uid === fileId.toString();
              });

              const items = mapIgTagsToBackend(post?.mediaTags?.[fileId] || []);

              return {
                url:
                  localFile?.name_link ||
                  (localFile?.url ? getRelativePath(localFile.url) : undefined),
                items,
              };
            })
          : undefined;

        return {
          asset_id: post.asset.id,
          message: post.message,
          uploads: orderedFileLinks,
          publishing_type: post.publishing_type,
          preview_link: post.asset.preview_link?.url,
          thumbnail: post.coverNameLink?.startsWith("http")
            ? getRelativePath(post.coverNameLink)
            : post.coverNameLink,
          tiktok_options: post.tiktokOptions,
          post_tag_ids: post.postTags?.map(tag => tag.id),
          media_tags,
          alt_texts,
          location: post.location,
          youtube_options: post.youtubeOptions,
        } as IPostPayload;
      });

      form
        .validateFields()
        .then((values: IPostCreationFormType) => {
          onFinish({
            assets: formattedAssets,
            is_draft: type === "saveAsDraft",
            scheduled_at: type === "postNow" ? undefined : dayjs(values.scheduled_at).format(),
            initial_notes: internalNotes.map(note => note.message),
          });
        })
        .catch(error => {
          console.error(t("generic:validationError"), error);
        });
    }
  };

  return (
    <div className={s.bbFormSubmitButtonWrapper}>
      <Form.Item noStyle>
        {!hasSelectedAssets || activePostScheduleTab === "schedule" ? (
          <ButtonPermissionGuard
            message={t("permissions:publishPost")}
            hasPermission={permissions["canPublishPost"]}
          >
            <Button
              type="submit"
              _type="primary"
              onClick={() => onSubmitPostClick("schedule")}
              className={s.bbFormSubmitButton}
              disabled={disabled}
              loading={isButtonLoading}
            >
              <div className={s.bbFormSubmitButtonIcon}>
                <CalendarIcon />
              </div>
              {t("generic:schedule")}
            </Button>
          </ButtonPermissionGuard>
        ) : activePostScheduleTab === "postNow" ? (
          <ButtonPermissionGuard
            message={t("permissions:publishPost")}
            hasPermission={permissions["canPublishPost"]}
          >
            <Button
              type="submit"
              _type="primary"
              onClick={() => onSubmitPostClick("postNow")}
              className={s.bbFormSubmitButton}
              loading={isButtonLoading}
            >
              <div className={s.bbFormSubmitButtonIcon}>
                <SendIcon />
              </div>
              {t("generic:postNow")}
            </Button>
          </ButtonPermissionGuard>
        ) : (
          <Button
            type="submit"
            _type="primary"
            onClick={() => onSubmitPostClick("saveAsDraft")}
            className={s.bbFormSubmitButton}
            loading={isButtonLoading}
          >
            <div className={s.bbFormSubmitButtonIcon}>
              <EditIcon />
            </div>
            {t("generic:saveAsDraft")}
          </Button>
        )}
      </Form.Item>
    </div>
  );
}
