import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import Markdown from "react-markdown";

import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { AstraChat } from "./AstraChat";
import { AstraProcessing } from "./AstraProcessing";

import {
  genAiTermsAcceptedSelector,
  meSelector,
  patchMeClientFetchingSelector,
} from "@store/me/selectors";
import { patchMeClientAction } from "@store/me/actions";
import {
  getAiHighlightsAction,
  resetAiHighlightsAction,
  setWidgetStateAction,
} from "@store/aiHighlights/actions";
import {
  aiHighlightsFetchingSelector,
  aiHighlightsFetchedSelector,
  aiHighlightsFetchFailSelector,
  aiHighlightsLimitSelector,
  aiHighlightsConsumedSelector,
  aiHighlightsMessageSelector,
  aiHighlightsStartDateSelector,
  aiHighlightsEndDateSelector,
  aiHighlightsLastUpdatedSelector,
  aiHighlightsInitialFetchDoneSelector,
  aiHighlightsWidgetStateSelector,
} from "@store/aiHighlights/selectors";

import s from "./AIHighlights.module.less";

import { getDefaultFilterValue } from "@utils/filters";
import nl2br from "@utils/nl2br";
import { WidgetState } from "@store/aiHighlights/types";

import { MagicSparklesIcon, RefreshIcon } from "@bbdevcrew/bb_ui_kit_fe";
import IllustrationSearch from "@assets/IllustrationSearch.svg";
import CustomPrompts from "./CustomPrompts";
import { IAIHighlightPrompt } from "@store/aiHighlightsPrompts/types";
import objectsAreEqual from "@utils/objectsAreEqual";
import { filterDataSelector } from "@store/filters/selectors";

import { ChevronDownIcon, InformationIcon, SettingsIcon } from "@bbdevcrew/bb_ui_kit_fe";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const AIHighlights: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setTimeUpdate] = useState(0);
  const [selectedPrompt, setSelectedPrompt] = useState<IAIHighlightPrompt>();

  const me = useSelector(meSelector);
  const genAiTermsAccepted = useSelector(genAiTermsAcceptedSelector);
  const fetchingPatchMe = useSelector(patchMeClientFetchingSelector);
  const fetching = useSelector(aiHighlightsFetchingSelector);
  const fetched = useSelector(aiHighlightsFetchedSelector);
  const fetchFail = useSelector(aiHighlightsFetchFailSelector);
  const initialFetchDone = useSelector(aiHighlightsInitialFetchDoneSelector);
  const limit = useSelector(aiHighlightsLimitSelector);
  const consumed = useSelector(aiHighlightsConsumedSelector);
  const message = useSelector(aiHighlightsMessageSelector);
  const startDate = useSelector(aiHighlightsStartDateSelector);
  const endDate = useSelector(aiHighlightsEndDateSelector);
  const lastUpdated = useSelector(aiHighlightsLastUpdatedSelector);
  const widgetState = useSelector(aiHighlightsWidgetStateSelector);

  const filters = useSelector(filterDataSelector);
  const defaultFilters = useMemo(() => getDefaultFilterValue(me), [me]);
  const currentFiltersAreDefault = useMemo(
    () => !!filters && objectsAreEqual(filters, defaultFilters),
    [filters, defaultFilters],
  );
  const filtersPayload = currentFiltersAreDefault ? defaultFilters : filters;

  const hasReachedLimit = useMemo(
    () => consumed !== undefined && limit !== undefined && consumed >= limit,
    [consumed, limit],
  );
  const brandName = me?.client?.name;
  const promptId = selectedPrompt?.id || "default";

  const isCollapsed = widgetState === WidgetState.COLLAPSED;

  const shouldExpandOnInitialLoad =
    genAiTermsAccepted &&
    currentFiltersAreDefault &&
    !initialFetchDone &&
    !fetchFail &&
    promptId === "default";

  useEffect(() => {
    if (!lastUpdated) return;
    const timer = setInterval(() => setTimeUpdate(prev => prev + 1), 60000);
    return () => clearInterval(timer);
  }, [lastUpdated]);

  useEffect(() => {
    if (isCollapsed) {
      dispatch(resetAiHighlightsAction());
    }
  }, [isCollapsed, dispatch]);

  const handleRefresh = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (filtersPayload && !hasReachedLimit) {
      dispatch(setWidgetStateAction(WidgetState.EXPANDED));
      dispatch(getAiHighlightsAction({ filters: filtersPayload, refresh: true, promptId }));
    }
  };

  const toggleCollapse = useCallback(() => {
    if (fetching || fetchingPatchMe) return;

    if (isCollapsed && !hasReachedLimit) {
      if (!genAiTermsAccepted) {
        dispatch(setWidgetStateAction(WidgetState.EXPANDED));
        dispatch(patchMeClientAction({ chat_enabled: true }));
      } else {
        dispatch(setWidgetStateAction(WidgetState.EXPANDED));
        if (filtersPayload && !hasReachedLimit && !fetched && !fetchFail && !fetching) {
          dispatch(getAiHighlightsAction({ filters: filtersPayload, promptId }));
        }
      }
    } else {
      dispatch(setWidgetStateAction(WidgetState.COLLAPSED));
    }
  }, [
    fetching,
    fetchingPatchMe,
    isCollapsed,
    genAiTermsAccepted,
    hasReachedLimit,
    filtersPayload,
    promptId,
    fetched,
    fetchFail,
    dispatch,
  ]);

  const handleSelectPrompt = (prompt: IAIHighlightPrompt) => {
    setSelectedPrompt(prompt);
    dispatch(setWidgetStateAction(WidgetState.COLLAPSED));
  };

  useEffect(() => {
    if (shouldExpandOnInitialLoad) {
      toggleCollapse();
    }
  }, [shouldExpandOnInitialLoad, toggleCollapse]);

  useEffect(() => {
    if (!currentFiltersAreDefault) {
      dispatch(resetAiHighlightsAction());
    }
  }, [currentFiltersAreDefault, dispatch]);

  useEffect(() => {
    if (filters && !currentFiltersAreDefault) {
      dispatch(resetAiHighlightsAction());
    }
  }, [filters, currentFiltersAreDefault, dispatch]);

  const showLoading = !isCollapsed && (fetching || fetchingPatchMe);
  const showError = !isCollapsed && !showLoading && !fetching && fetchFail;
  const showContent =
    !isCollapsed && genAiTermsAccepted && !showLoading && !showError && fetched && !fetchFail;

  if (!filters || hasReachedLimit || (!me?.is_admin && !genAiTermsAccepted)) {
    return null;
  }

  return (
    <div className={s.bbAIHighlights}>
      <div className={s.bbAIHighlightsHeader} onClick={toggleCollapse}>
        <div className={s.bbAIHighlightsHeaderTitle}>
          <MagicSparklesIcon /> {t("components:aiHighlights:title")}
          {consumed !== undefined && limit !== undefined && (
            <span className={s.bbAIHighlightsHeaderTitleSubtext}>
              {consumed}/{limit}
            </span>
          )}
          <Tooltip title={nl2br(t("components:aiHighlights:titleTooltip"))}>
            <span className={s.bbAIHighlightsHeaderTitleTooltip}>
              <InformationIcon />
            </span>
          </Tooltip>
          {!genAiTermsAccepted && brandName && (
            <span
              className={classNames(
                s.bbAIHighlightsHeaderTitleSubtext,
                s.bbAIHighlightsHeaderTitleDateRange,
              )}
            >
              {t("components:aiHighlights:consent:message", { brandName })}
            </span>
          )}
          {!!(startDate && endDate) && (
            <span
              className={classNames(
                s.bbAIHighlightsHeaderTitleSubtext,
                s.bbAIHighlightsHeaderTitleDateRange,
              )}
            >
              {`${dayjs(startDate).format("D MMM")} - ${dayjs(endDate).format("D MMM, YYYY")}`}
            </span>
          )}
        </div>
        <div className={s.bbAIHighlightsHeaderActions}>
          {!!lastUpdated && (
            <div className={s.bbAIHighlightsHeaderLastUpdated}>
              {t("components:aiHighlights:lastUpdated", {
                lastUpdated: dayjs().to(dayjs.utc(lastUpdated).local()),
              })}
            </div>
          )}
          {!!(genAiTermsAccepted && fetched) && (
            <Tooltip
              title={nl2br(
                t("components:aiHighlights:refreshTooltip", {
                  dateRange:
                    startDate && endDate
                      ? `\n${dayjs(startDate).format("D MMM")} - ${dayjs(endDate).format("D MMM, YYYY")}` // eslint-disable-line max-len
                      : "",
                }),
              )}
            >
              <Button
                _type="custom"
                _size="sm"
                className={s.bbAIHighlightsHeaderActionsButton}
                onClick={handleRefresh}
                disabled={fetching || fetchingPatchMe}
              >
                <RefreshIcon />
              </Button>
            </Tooltip>
          )}
          {!!genAiTermsAccepted && (
            <CustomPrompts onSelectPrompt={handleSelectPrompt}>
              {handleOpen => (
                <Button
                  className={s.bbAIHighlightsHeaderActionsButton}
                  _type="custom"
                  _size="sm"
                  onClick={handleOpen}
                >
                  <SettingsIcon />
                </Button>
              )}
            </CustomPrompts>
          )}
          <Button
            _type="custom"
            _size="sm"
            className={classNames(
              s.bbAIHighlightsHeaderActionsButton,
              s.bbAIHighlightsHeaderActionsCollapseToggle,
              {
                [s.bbAIHighlightsHeaderActionsCollapseToggleActive]: isCollapsed,
              },
            )}
            onClick={toggleCollapse}
            disabled={fetchingPatchMe}
          >
            <ChevronDownIcon />
          </Button>
        </div>
      </div>

      {!isCollapsed && (
        <div className={s.bbAIHighlightsContent}>
          {showLoading && (
            <div className={s.bbAIHighlightsContentLoading}>
              <AstraProcessing />
              <h4>{nl2br(t("components:aiHighlights:processing"))}</h4>
            </div>
          )}

          {showError && (
            <div className={s.bbAIHighlightsContentError}>
              <div className={s.bbAIHighlightsContentErrorImage}>
                <IllustrationSearch />
              </div>
              <div className={s.bbAIHighlightsContentErrorText}>
                <h4>{t("components:aiHighlights:error:title")}</h4>
                <p>{t("components:aiHighlights:error:message")}</p>
              </div>
            </div>
          )}

          {showContent && (
            <>
              <div className={s.bbAIHighlightsContentResult}>
                <Markdown>{message}</Markdown>
              </div>
              <AstraChat />
            </>
          )}
        </div>
      )}
    </div>
  );
};
