/* eslint-disable max-len */
import React from "react";
import { t } from "i18next";

import s from "./TTDiscloseContentOptions.module.less";

export const formItemNoMargin = {
  margin: 0,
};

export const promoteOthersContentTooltip = (
  <span className={s.bbDicloseContentPromoteOthersTooltip}>
    {t(
      "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:promoteOthersContentTooltip:description",
    )}
    <ul>
      <li>
        {t(
          "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:promoteOthersContentTooltip:publicLabel",
        )}
      </li>
      <li>
        {t(
          "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:promoteOthersContentTooltip:followersLabel",
        )}
      </li>
    </ul>
  </span>
);

export const optionDisabledTooltip = t(
  "components:publish:postCreationModal:ttDisclosureOptions:optionUnavailable",
);

export const getDiscloseContentInfoMessage = (
  promoteOwnContent: boolean,
  promoteOthersContent: boolean,
) => {
  const onlyPromoteOwnContent = promoteOwnContent && !promoteOthersContent;
  const onlyPromoteOthersContent = !promoteOwnContent && promoteOthersContent;
  const promoteBothContentTypes = promoteOwnContent && promoteOthersContent;

  if (promoteBothContentTypes || onlyPromoteOthersContent) {
    return t(
      "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:paidPartnershipDisclaimer",
    );
  }

  if (onlyPromoteOwnContent) {
    return t(
      "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:promotionalContentDisclaimer",
    );
  }
};
