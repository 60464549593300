import React from "react";
import { useTranslation } from "react-i18next";

import { Form, FormInstance } from "antd";
import { KeywordTrigger } from "../KeywordTrigger";
import { CommentTagsTrigger } from "../CommentTagsTrigger";
import { FormErrorMessage } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./../../WorkflowForm.module.less";

export const KeywordTagTrigger: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();

  const handleValueChange = () => {
    form.validateFields(["trigger"]);
  };

  return (
    <>
      <Form.Item
        name="trigger"
        className={s.bbWorkflowItemTrigger}
        rules={[
          {
            validator: async () => {
              const triggerValues = form.getFieldsValue()?.trigger;
              if (!triggerValues?.keyword_query?.length && !triggerValues?.comment_tags?.length) {
                return Promise.reject(
                  <FormErrorMessage>
                    {t("pages:workflows:form:triggers:keywords:required")}
                  </FormErrorMessage>,
                );
              }
              return Promise.resolve();
            },
            validateTrigger: "onChange",
          },
        ]}
      >
        <Form.Item name={["trigger", "keyword_query"]} className={s.bbWorkflowItemTrigger}>
          <KeywordTrigger onChange={handleValueChange} />
        </Form.Item>
        <Form.Item name={["trigger", "comment_tags"]} className={s.bbWorkflowItemTrigger}>
          <CommentTagsTrigger onChange={handleValueChange} />
        </Form.Item>
      </Form.Item>
    </>
  );
};
