import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import loadable from "@loadable/component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Grid } from "antd";
import UpgradePlanCTA from "./UpgradePlanCTA";
import FilterProvider from "./FilterProvider";
import { ChangeClient } from "./ChangeClient";
import AIInsightsModal from "../AIInsightsModal";
import { ChangeClientTitle } from "./ChangeClient";
import MobileFiltersModal from "./MobileFiltersModal";
import TrialSubscription from "./TrialSubscription";
import ClientPlanBadge from "../ClientPlanBadge/ClientPlanBadge";
import { Notifications } from "./notifications/Notifications";
import AppHeaderKnowledgeBaseWidget from "./AppHeaderKBWidget";
import AppFiltersContainer from "@containers/AppFiltersContainer";
import { AppLayout, ExtraMenuItemDivider } from "@bbdevcrew/bb_ui_kit_fe";
import AppSideBarContainer from "./AppSidebar";

import { filtersOpenSelector, savedFilterValueSelector } from "@store/filters/selectors";
import { isAIInsightsModalExpandedSelector } from "@store/aiInsights/selectors";
import { aiOnboardingAvailable, aiOnboardingModalExpanded } from "@store/accounts/selector";
import { meSelector } from "@store/me/selectors";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import { toggleOpenAIModalAction } from "@store/aiInsights/actions";
import { getNotificationsAction } from "@store/notifications/actions";
import { saveFilterAction, toggleOpenFiltersPanelAction } from "@store/filters/actions";
import { checkOnboardingAIAction, toggleAIOnboardingAction } from "@store/accounts/actions";

import s from "./AppLayoutContainer.module.less";

import {
  IExtraItem,
  IFormHandler,
  IAppLayout,
  MenuItemDecorator,
  AppSidebarProvider,
} from "@bbdevcrew/bb_ui_kit_fe";
import { getCurrentModuleName } from "@utils/paths";
import { getSubFeatureName } from "@utils/feature";
import { useTrialSubscription } from "@utils/useTrialSubscription";
import { IAvailableSectionsType } from "@store/modules/types";
import { AIModalStateEnum } from "@store/aiInsights/types";
import { unreadFeatures, MobileMenuIconMap } from "@utils/appSidebar";
import { useMobileMenuItems } from "./useMobileMenuItems";
import { AppHeaderIconMap } from "./AppLayout.helpers";
import WorkflowsMenu from "@components/control-panel/workflows/menu/WorkflowsMenu";

const AIOnboarding = loadable(() => import("@components/_common/AIOnboarding"));

type IAppLayoutContainerProps = Omit<
  IAppLayout,
  | "MobileModuleIconMap"
  | "unreadFeatures"
  | "mobileMenu"
  | "inboxMobileMenu"
  | "mobileMenuExpandedKey"
  | "onMobileMenuItemClick"
  | "isMobileMenuItemActive"
>;

const AppLayoutContainer: React.FC<IAppLayoutContainerProps> = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;
  const [searchParams] = useSearchParams();
  const FiltersRef = useRef<IFormHandler>(null);

  const isTrialSubscription = useTrialSubscription();
  const currentModuleId = getCurrentModuleName();
  const subFeatureName = getSubFeatureName();
  const {
    mobileMenuItems,
    inboxMobileMenuItems,
    mobileMenuExpandedKey,
    onMobileMenuItemClick,
    isMobileMenuItemActive,
  } = useMobileMenuItems();

  const hasSidebar = ["insights", "control_panel"].includes(getCurrentModuleName());
  const isFooterFixed = window.location.pathname.includes("/settings/asset-management/add-assets");

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(
    location.search === "?subscription-modal=true",
  );

  const me = useSelector(meSelector);
  const isAIInsightsModalExpanded = useSelector(isAIInsightsModalExpandedSelector);
  const isAIOnboardingModalExpanded = useSelector(aiOnboardingModalExpanded);
  const filtersPanelOpen = useSelector(filtersOpenSelector);
  const isAIOnboardingAvailable = useSelector(aiOnboardingAvailable);
  const savedFilterValue = useSelector(savedFilterValueSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const isAIModalOpen = isAIInsightsModalExpanded || isAIOnboardingModalExpanded;
  const allSections = modulesSections[currentModuleId as IAvailableSectionsType];
  const sections = allSections?.map(section => ({
    ...section,
    suffix:
      section.id === "control_panel" &&
      searchParams.get("section") === "control_panel-workflows" ? (
        <WorkflowsMenu />
      ) : undefined,
  }));

  const setSavedFilterValue = useCallback(
    (id: string | number | null) => dispatch(saveFilterAction(id)),
    [dispatch],
  );

  const hasFilters = useMemo(() => {
    return (
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      searchParams.get("section") !== "control_panel-workflows" &&
      searchParams.get("section") !== "control_panel-tags" &&
      (["insights", "control_panel"].includes(getCurrentModuleName()) ||
        ["dashboard", "inbox", "smart-inbox", "transparency"].includes(subFeatureName))
    );
  }, [searchParams, subFeatureName]);

  const isAIModalAvailable =
    getCurrentModuleName() === "insights" &&
    !isMobile &&
    (searchParams.get("section") === "listen-overview" ||
      searchParams.get("section") === "listen-sentiment_details" ||
      searchParams.get("section") === "topics");

  const closeAiModal = useCallback(() => {
    dispatch(toggleOpenAIModalAction(AIModalStateEnum.Closed));
    dispatch(toggleAIOnboardingAction(AIModalStateEnum.Closed));
  }, [dispatch]);

  const toggleFiltersPanel = useCallback(
    (open: boolean) => dispatch(toggleOpenFiltersPanelAction(open)),
    [dispatch],
  );

  useEffect(() => {
    if (!isAIModalAvailable) closeAiModal();
  }, [isAIModalAvailable, closeAiModal]);

  useEffect(() => {
    const savedFilterSearchParam = searchParams.get("saved_filter");
    if (savedFilterSearchParam && !savedFilterValue) setSavedFilterValue(savedFilterSearchParam);
  }, [searchParams, savedFilterValue, setSavedFilterValue]);

  useEffect(() => {
    dispatch(getNotificationsAction());
    if (currentModuleId === "control_panel") {
      toggleFiltersPanel(false);
    }
  }, [dispatch, toggleFiltersPanel, currentModuleId]);

  useEffect(() => {
    if (
      localStorage.getItem("isOnboardingModalSkipped") !== "true" &&
      isAIModalAvailable &&
      !!me?.is_bb_user
    )
      dispatch(checkOnboardingAIAction());
  }, [dispatch, isAIModalAvailable, me?.is_bb_user]);

  const onSubscriptionModalOpen = () => {
    setShowSubscriptionModal(true);
  };

  const decorateExtraItem: MenuItemDecorator = (item: IExtraItem, subMenuItem) => {
    if (item.id === "upgrade-plan") {
      return <UpgradePlanCTA item={item} onSubscriptionModalOpen={onSubscriptionModalOpen} />;
    }

    if (item.id === "change-client") {
      return (
        <>
          {item.includeDividerBefore && <ExtraMenuItemDivider id={item.id} />}
          {subMenuItem(item, <ChangeClientTitle />, <ChangeClient />)}
          {item.includeDividerAfter && <ExtraMenuItemDivider id={item.id} />}
        </>
      );
    }
  };

  return (
    <FilterProvider ref={FiltersRef}>
      <AppSidebarProvider>
        <AppLayout
          {...props}
          menu={props.menu?.map(item => {
            if (item.basename === "control_panel") return { ...item, hasDivider: true };
            return item;
          })}
          hasSidebar
          mobileMenu={mobileMenuItems}
          mobileMenuExpandedKey={mobileMenuExpandedKey}
          inboxMobileMenu={inboxMobileMenuItems}
          isMobileMenuItemActive={isMobileMenuItemActive}
          onMobileMenuItemClick={onMobileMenuItemClick}
          MobileModuleIconMap={MobileMenuIconMap}
          unreadFeatures={unreadFeatures}
          moduleSections={sections}
          toggleFiltersPanel={toggleFiltersPanel}
          isFooterFixed={isFooterFixed}
          decorateExtraItem={decorateExtraItem}
          appHeaderIconMap={AppHeaderIconMap}
        >
          {{
            ...(props.children as object),
            content: <>{props.children.content}</>,
            belowContent: (
              <>
                {hasFilters && (
                  <>
                    {isMobile ? (
                      <MobileFiltersModal ref={FiltersRef} />
                    ) : (
                      <AppFiltersContainer ref={FiltersRef} />
                    )}
                  </>
                )}
                {isAIModalOpen && !filtersPanelOpen && (
                  <div className={s.bbLayoutContentWithAIModal} />
                )}
                {isAIModalAvailable && <AIInsightsModal />}
                {isAIModalAvailable && isAIOnboardingAvailable && <AIOnboarding />}
              </>
            ),
            appSideBar: hasSidebar && <AppSideBarContainer />,
            headerActions: (
              <>
                <AppHeaderKnowledgeBaseWidget />
                <Notifications />
              </>
            ),
            headerActionsPrefix: isTrialSubscription && (
              <TrialSubscription
                showSubscriptionModal={showSubscriptionModal}
                setShowSubscriptionModal={setShowSubscriptionModal}
              />
            ),
            submenuSection: !!me?.client?.client_plan && (
              <ClientPlanBadge type={me.client.client_plan} />
            ),
          }}
        </AppLayout>
      </AppSidebarProvider>
    </FilterProvider>
  );
};

export default AppLayoutContainer;
