import {
  GET_MODULE_SECTIONS_LIST,
  GET_MODULE_SECTIONS_LIST_SUCCESS,
  GET_MODULE_SECTIONS_LIST_FAILURE,
  POST_MODULE_SECTIONS_LIST,
  RESET_MODULE_SECTIONS_LIST,
} from "./actionTypes";
import { createAction } from "typesafe-actions";
import { DashboardComponentType } from "./types";
import { IModuleFeature } from "@bbdevcrew/bb_ui_kit_fe";

export const getModuleSectionsListAction = createAction(
  GET_MODULE_SECTIONS_LIST,
  (module_id: string) => ({
    id: module_id,
  }),
)();

export const postModuleSectionsListAction = createAction(
  POST_MODULE_SECTIONS_LIST,
  payload => payload,
)();

export const getModuleSectionsListSuccessAction = createAction(
  GET_MODULE_SECTIONS_LIST_SUCCESS,
  (items: IModuleFeature<DashboardComponentType>[], module_id: string) => ({
    items,
    id: module_id,
  }),
)();
export const getModuleSectionsListFailureAction = createAction(
  GET_MODULE_SECTIONS_LIST_FAILURE,
  (module_id: string) => ({
    id: module_id,
  }),
)();

export const resetModuleSectionsListAction = createAction(RESET_MODULE_SECTIONS_LIST)();
