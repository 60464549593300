import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { TiktokTrendingCommonFilter } from "./TiktokTrendingCommonFilter";

import {
  trendingHashtagsFiltersSelector,
  trendingSongsFiltersSelector,
} from "@store/tiktokTrending/selectors";
import {
  getTrendingHashtagsFiltersAction,
  getTrendingSongsFiltersAction,
} from "@store/tiktokTrending/actions";

import s from "./TiktokTrending.module.less";

import { useSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { ITiktokTrendingFiltersProps } from "./TiktokTrending.types";
import { getGenreOptions, getIndustryOptions } from "./TiktokTrending.helpers";

export const TiktokTrendingFilters: React.FC<ITiktokTrendingFiltersProps> = ({
  value,
  onChange,
  isHashtagMode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hashtagFiltersOptions = useSelector(trendingHashtagsFiltersSelector);
  const songsFiltersOptions = useSelector(trendingSongsFiltersSelector);

  const {
    options: searchedOptions,
    SearchInput,
    searchTerm,
    setSearchTerm,
  } = useSearch(hashtagFiltersOptions?.countries || [], t("generic:search"));

  const getTrendingHashtagsFilters = useCallback(
    () => dispatch(getTrendingHashtagsFiltersAction()),
    [dispatch],
  );

  const getTrendingSongsFilters = useCallback(
    () => dispatch(getTrendingSongsFiltersAction()),
    [dispatch],
  );

  useEffect(() => {
    if (isHashtagMode && !hashtagFiltersOptions?.industries?.length) getTrendingHashtagsFilters();
    else if (!isHashtagMode && !songsFiltersOptions?.genres?.length) getTrendingSongsFilters();

    // eslint-disable-next-line
  }, [getTrendingHashtagsFilters, getTrendingSongsFilters, isHashtagMode]);

  return (
    <div className={s.bbTTTrendingFilters}>
      <TiktokTrendingCommonFilter
        key={isHashtagMode ? "industryId" : "genre"}
        options={
          isHashtagMode
            ? getIndustryOptions(hashtagFiltersOptions?.industries || [])
            : getGenreOptions(songsFiltersOptions?.genres || [], t)
        }
        value={isHashtagMode ? value.industryId : value.genre}
        onChange={onChange(isHashtagMode ? "industryId" : "genre")}
      />
      <SelectStyled
        _size="sm"
        value={value.countryId}
        onChange={onChange("countryId")}
        highlightTerm={searchTerm}
        DropdownHeader={SearchInput}
        onDropdownClose={() => setSearchTerm("")}
        dropdownPlacement="bottomRight"
        wrapperClassName={s.bbTTTrendingDropdownWrapper}
        dropdownMenuClassName={s.bbTTTrendingDropdown}
        options={searchedOptions}
        disabled={!hashtagFiltersOptions?.countries?.length}
      />
      <SelectStyled
        _size="sm"
        dropdownPlacement="bottomRight"
        disabled={!hashtagFiltersOptions?.dateRangeOptions?.length}
        value={value.dateRangeId}
        onChange={onChange("dateRangeId")}
        wrapperClassName={s.bbTTTrendingDropdownWrapper}
        dropdownMenuClassName={s.bbTTTrendingDropdown}
        options={hashtagFiltersOptions?.dateRangeOptions || []}
      />
    </div>
  );
};
