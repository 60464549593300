import { ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";
import { IInudstryFilterItem, ITrendingItem, TrendingModeEnum } from "@store/tiktokTrending/types";
import { TFunction } from "react-i18next";
import { ITrendingTableSort } from "./TiktokTrending.types";

export const TABLE_PAGE_SIZE = 10;
export const MAX_SHOW_FLAG_COUNT = 5;

export const COLUMN_SIZES = {
  hashtags: {
    sm: [10, 14, 0, 0, 0, 0],
    md: [4, 7, 0, 4, 4, 5],
    lg: [2, 6, 8, 2, 2, 4],
  },
  songs: {
    sm: [10, 14, 0, 0],
    md: [4, 10, 0, 10],
    lg: [2, 6, 11, 5],
  },
};

const COLUMNS = {
  hashtags: [
    "rank",
    "name",
    "trendingPosts",
    "numberOfPosts",
    "numberOfViews",
    "trendingCountries",
  ],
  songs: ["rank", "song", "trendingPosts", "genre"],
};

export const getTableColumns = (
  type: TrendingModeEnum,
  screens: Partial<Record<string, boolean>>,
  t: TFunction,
): ISimpleTableColumn[] => {
  const columnsSize = COLUMN_SIZES[type][screens.lg ? "lg" : screens.md ? "md" : "sm"];

  return COLUMNS[type].map((column, index) => ({
    name: t(`components:listen:trending:table:columns:${column}`),
    id_name: column,
    colSpan: columnsSize[index],
    isSortable: ["rank", "numberOfPosts", "numberOfViews"].includes(column),
  }));
};

export const getIndustryOptions = (options: IInudstryFilterItem[]) => {
  return options.map(item => ({
    id: item.id,
    label: item.emoji ? `${item.emoji} ${item.label}` : item.label,
    options: item.subIndustries?.map(subIndustry => ({
      id: subIndustry.id,
      label: subIndustry.label,
    })),
  }));
};

export const getGenreOptions = (options: string[], t: TFunction) => {
  return options.map(item => ({
    id: item,
    label: item === "ALL" ? t("components:listen:trending:allGenres") : item,
  }));
};

const units: { [key: string]: number } = {
  k: 1e3,
  m: 1e6,
  bi: 1e9,
};

export const parseValue = (value: string | number) => {
  if (typeof value === "number") return value;

  const match = value.match(/^(\d+(?:\.\d+)?)([kKmMBi]+)?$/);
  if (!match) return parseFloat(value);

  const [, number, unit] = match;
  return parseFloat(number) * (units[unit?.toLowerCase()] || 1);
};

export const getTrendingIds = (trendingList: ITrendingItem[], page: number) => {
  return trendingList
    .slice((page - 1) * TABLE_PAGE_SIZE, page * TABLE_PAGE_SIZE)
    .map(({ id }) => id)
    .join(",");
};

export const getSortedData = (data: ITrendingItem[], page: number, sort?: ITrendingTableSort) => {
  const startIndex = (page - 1) * TABLE_PAGE_SIZE;
  const endIndex = startIndex + TABLE_PAGE_SIZE;

  const sortedData = sort
    ? [...data].sort((a, b) => {
        const { field, order } = sort;
        const valueA = parseValue(field === "rank" ? a.rank?.position ?? 0 : a[field] ?? 0);
        const valueB = parseValue(field === "rank" ? b.rank?.position ?? 0 : b[field] ?? 0);
        if (valueA === valueB) return 0;
        const comparison = valueA > valueB ? 1 : -1;
        return order === "asc" ? comparison : -comparison;
      })
    : data;

  return sortedData.slice(startIndex, endIndex);
};
