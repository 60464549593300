import React from "react";
import classNames from "classnames";

import s from "./EmailAlertWorkflowAction.module.less";

import { IUser, IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { DefaultOptionType } from "antd/lib/select";
import { IWorkflowRiskOptions } from "@store/workflows/types";

import { ExclamationIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ADDITIONAL_TEXT_MAXLEN = 1024;

export const getSubscribersFilterOptions = (
  users: IUser[],
  input: string,
  option?: DefaultOptionType,
) => {
  if (!option?.value || !input) return false;
  return users.some(
    ({ id, first_name, last_name, email }) =>
      option.value === id &&
      (first_name.includes(input) || last_name.includes(input) || email.includes(input)),
  );
};

export const getRiskLevelOptions = (options: IWorkflowRiskOptions["risk_lvl"]): IObjectOption[] =>
  options.map(level => {
    return {
      ...level,
      leftLabel: (
        <div className={classNames(s.bbIconRisk, s[`bbIconRisk-${level.id}`])}>
          <ExclamationIcon />
        </div>
      ),
    };
  });
