import {
  GET_SOURCES_LIST,
  GET_SOURCES_LIST_SUCCESS,
  GET_SOURCES_LIST_FAILURE,
  CREATE_SOURCE,
  CREATE_SOURCE_SUCCESS,
  CREATE_SOURCE_FAILURE,
  UPDATE_SOURCE,
  UPDATE_SOURCE_SUCCESS,
  UPDATE_SOURCE_FAILURE,
  DELETE_SOURCE,
  DELETE_SOURCE_SUCCESS,
  DELETE_SOURCE_FAILURE,
} from "./actionTypes";
import { createAction } from "typesafe-actions";
import { ICreateSourcePayload, ISourcesListResponse, ISource, IUpdateSourcePayload } from "./types";

export const getSourcesListAction = createAction(GET_SOURCES_LIST)();
export const getSourcesListSuccessAction =
  createAction(GET_SOURCES_LIST_SUCCESS)<ISourcesListResponse>();
export const getSourcesListFailureAction = createAction(GET_SOURCES_LIST_FAILURE)<void>();

export const createSourceAction = createAction(CREATE_SOURCE)<ICreateSourcePayload>();
export const createSourceSuccessAction = createAction(CREATE_SOURCE_SUCCESS)<ISource>();
export const createSourceFailureAction = createAction(CREATE_SOURCE_FAILURE)<string>();

export const updateSourceAction = createAction(UPDATE_SOURCE)<IUpdateSourcePayload>();
export const updateSourceSuccessAction = createAction(UPDATE_SOURCE_SUCCESS)<ISource>();
export const updateSourceFailureAction = createAction(UPDATE_SOURCE_FAILURE)<string>();

export const deleteSourceAction = createAction(DELETE_SOURCE)<string>();
export const deleteSourceSuccessAction = createAction(DELETE_SOURCE_SUCCESS)<string>();
export const deleteSourceFailureAction = createAction(DELETE_SOURCE_FAILURE)<string>();
