import React, { useState } from "react";

import { Tooltip, ActionsDropdown } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ContextMenu.module.less";

import { IContextMenuProps } from "./ContextMenu.types";

export const ContextMenu: React.FC<IContextMenuProps> = ({ items, tooltip }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const actionsDropdown = (
    <div className={s.bbContextMenu}>
      <ActionsDropdown
        items={items.map(item => ({
          ...item,
          onClick: () => {
            item.onClick?.();
            setTooltipVisible(false);
          },
        }))}
        triggerClass={s.bbContextMenuTrigger}
        triggerMode="click"
      />
    </div>
  );

  return tooltip ? (
    <Tooltip visible={tooltipVisible} onVisibleChange={setTooltipVisible} title={tooltip}>
      {actionsDropdown}
    </Tooltip>
  ) : (
    actionsDropdown
  );
};
