import React from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import SentimentPicker, {
  SentimentValue,
} from "../../../../_common/SentimentPicker/SentimentPicker";
import HideWorkflowAction from "./hideWorkflowAction";
import { TagWorkflowAction } from "./tagWorkflowAction";
import { PostTagWorkflowAction } from "./tagWorkflowAction";
import WorkflowCategoryIcon from "../../categoryIcon/WorkflowCategoryIcon";

import s from "./../WorkflowForm.module.less";

import { FormInstance } from "antd";
import { IFormComponent, ITagWorkflowAction } from "../../types";
import { WorkflowItemCategoryType } from "@store/workflows/types";

const Action: React.FC<{ type: WorkflowItemCategoryType; className?: string }> = ({
  type,
  className = "",
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(s.bbWorkflowItemFormAction, className)}>
      <div
        className={s.bbWorkflowItemFormActionHeader}
        data-stonly-target={`workflows-${type}__action-${type}`}
      >
        <WorkflowCategoryIcon type={type} />
        <label className={s.bbWorkflowItemFormActionLabel}>
          {t(`pages:workflows:form:actions:${type}:label`)}
        </label>
      </div>
      {children}
    </div>
  );
};

export const SentimentAction: React.FC<IFormComponent<SentimentValue>> = ({ value, onChange }) => (
  <Action type="sentiment">
    <div className={s.bbWorkflowItemFormActionSentimentSelect}>
      <SentimentPicker value={value} onChange={onChange} />
    </div>
  </Action>
);

export const TagAction: React.FC<ITagWorkflowAction> = ({ type, value, onChange }) => (
  <Action type={type}>
    {type === "post_tag" ? (
      <PostTagWorkflowAction value={value} onChange={onChange} />
    ) : (
      <TagWorkflowAction value={value} onChange={onChange} />
    )}
  </Action>
);

export const ArchiveAction: React.FC = () => <Action type="archive" />;

export const HideAction = ({ form }: { form: FormInstance }) => {
  return (
    <div className={s.bbWorkflowItemFormActionHideWrapper}>
      <Action type="hide" className={s.bbWorkflowItemFormActionHide}>
        <HideWorkflowAction form={form} />
      </Action>
    </div>
  );
};
