import { useMemo } from "react";
import Fuse, { IFuseOptions } from "fuse.js";

export interface IUseFuseSearchProps<T> {
  collection: T[];
  query: string;
  fields: string[];
  searchOptions?: IFuseOptions<T>;
}

export const useFuseSearch = <T>({
  collection,
  query,
  fields,
  searchOptions,
}: IUseFuseSearchProps<T>) => {
  const fuse = useMemo(() => {
    const options: IFuseOptions<T> = {
      keys: fields,
      ...searchOptions,
    };
    return new Fuse(collection, options);
    // eslint-disable-next-line
  }, [fields, searchOptions]);

  const results = useMemo(() => {
    return fuse.search(query).map(result => result.item);
  }, [fuse, query]);

  return results;
};
