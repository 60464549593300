import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export interface IMostCommentedPostsProps {
  containerWidth: number;
  filters: IFilters;
  filterToSpecificItem?: (item: IFilters) => void;
}

export const responsive = {
  desktopLg: {
    breakpoint: { max: 3000, min: 1900 },
    items: 5,
    partialVisibilityGutter: 60,
  },
  desktopMd: {
    breakpoint: { max: 1900, min: 1600 },
    items: 4,
    partialVisibilityGutter: 50,
  },
  desktopSm: {
    breakpoint: { max: 1600, min: 1300 },
    items: 3,
    partialVisibilityGutter: 50,
  },
  tablet: {
    breakpoint: { max: 1300, min: 900 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};
