import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { CustomPostTagWorkflowAction } from "./CustomPostTagWorkflowAction";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./TagWorkflowAction.module.less";

import { useSearch, debounce, IGetAutocompleteOptionsPayload } from "@bbdevcrew/bb_ui_kit_fe";
import { IFormComponent } from "@components/control-panel/workflows/types";
import { getTagActionTypeOptions, postTagToOption } from "./TagWorkflowAction.helpers";

import { ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const BBPostTagWorkflowAction: FC<IFormComponent<string[]>> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tagType, setTagType] = useState(
    value?.[0]?.startsWith("managed_tag") ? "bb_tag" : "custom_tag",
  );

  const postTags = useSelector(autocompleteOptionsSelector).post_tags;

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const optionsFormatted = useMemo(() => {
    return postTags.map(postTagToOption);
  }, [postTags]);

  // eslint-disable-next-line
  const { options, SearchInput, searchTerm, setSearchTerm } = useSearch(
    optionsFormatted,
    t("generic:search"),
  );

  useEffect(() => {
    // TODO: BB post tag options will be introduced in the future sprints, for now these are temporarily here
    getAutocompleteOptions({
      field: "post_tags",
      query: searchTerm,
    });
  }, [getAutocompleteOptions, searchTerm]);

  const onTypeChange = (type: string) => {
    setTagType(type);
    onChange?.([]);
  };

  const getSelectedPostTagLabel = useCallback(
    (tagId: string) => {
      return postTags.find(tag => tag.id === tagId)?.label || "";
    },
    [postTags],
  );

  return (
    <div className={s.bbTagAction}>
      <SelectStyled
        options={getTagActionTypeOptions()}
        onChange={onTypeChange}
        value={tagType}
        dropdownMatchSelectWidth={true}
        wrapperClassName={s.bbTagActionTypeSelect}
      />
      <span>{t("generic:as")}</span>
      {tagType === "custom_tag" ? (
        <CustomPostTagWorkflowAction value={value} onChange={onChange} />
      ) : (
        <SelectStyled
          options={[]} // TODO: BB post tag options will be introduced in the future sprints
          // options={options}
          wrapperClassName={s.bbTagActionTypeSelect}
          onChange={val => onChange?.([val])}
          value={value?.[0]}
          highlightTerm={searchTerm || ""}
          dropdownPlacement="topRight"
          onDropdownClose={() => setSearchTerm("")}
          DropdownHeader={
            <div className={s.bbTagsDropdownHeader}>
              <span>{t("pages:workflows:form:triggers:time_frame:tags:label")}</span>
              {SearchInput}
            </div>
          }
          TriggerVariation={
            <Button className={s.bbWorkflowItemFormTagSelect} type="button">
              <span className={s.bbWorkflowItemFormTagSelectLabel}>
                {(value?.[0] && getSelectedPostTagLabel(value?.[0])) ||
                  t("pages:workflows:form:actions:tag:placeholder")}
              </span>
              <i className={s.bbWorkflowItemFormTagSelectChevron}>
                <ChevronDownIcon />
              </i>
            </Button>
          }
        />
      )}
    </div>
  );
};
