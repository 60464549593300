import React, { forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "antd";
import {
  debounce,
  Filters,
  FormErrorMessage,
  IFilters,
  IFormHandler,
  IGetAutocompleteOptionsPayload,
  InformationIcon,
  SelectStyled,
  Toggle,
} from "@bbdevcrew/bb_ui_kit_fe";

import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";

import s from "./TagsManagement.module.less";

import { isFilterValid, useTagManagementFilters } from "./TagsManagement.helpers";
import { ISubTagModalSectionProps } from "./TagsManagement.types";
import { getFetchValuesFieldName } from "@utils/filters";

export const SubTagModalSection = forwardRef<IFormHandler, ISubTagModalSectionProps>(
  (
    {
      form,
      filtersForm,
      options,
      initialFilterData,
      disabled,
      isFilterEnabled,
      onFilterSwitch,
    }: ISubTagModalSectionProps,
    ref,
  ) => {
    const { t } = useTranslation("en-US", {
      keyPrefix: "components:controlPanel:tagsManagement:modal.form",
    });
    const dispatch = useDispatch();

    const { filtersConfig } = useTagManagementFilters();

    const autocompleteOptions = useSelector(autocompleteOptionsSelector);

    // eslint-disable-next-line
    const getAutocompleteOptions = useCallback(
      debounce((payload: IGetAutocompleteOptionsPayload) => {
        dispatch(getAutocompleteOptionsAction(payload));
      }, 300),
      [dispatch],
    );

    const onFilterChange = (filterValues: IFilters) => {
      form.setFieldsValue({
        quick_automation_filters: filterValues,
      });
      filtersForm.setFieldsValue(filterValues);
    };

    return (
      <div className={s.bbParentTagSection}>
        <div className={s.bbParentTagSectionToggle}>
          <Toggle id="subtag" checked={true} />
          <span>{t("isSubTag")}</span>
        </div>
        <Form.Item
          name="parent_id"
          label={t("parentTag")}
          required
          rules={[
            {
              required: true,
              message: <FormErrorMessage>{t("parentTagError")}</FormErrorMessage>,
            },
          ]}
        >
          <SelectStyled
            options={options}
            dropdownPlacement="topLeft"
            disabled={disabled}
            dropdownMatchSelectWidth
          />
        </Form.Item>
        <div className={s.bbQuickAutoSection}>
          <div className={s.bbParentTagSectionToggle}>
            <Form.Item name="quick_automation_enabled" valuePropName="checked">
              <Toggle id="quick_automation_enabled" onChange={onFilterSwitch} />
            </Form.Item>
            <span>{t("quickAutoToggle")}</span>
          </div>
          {isFilterEnabled && (
            <div className={s.bbQuickAutoFiltersContainer}>
              <div className={s.bbQuickAutoFiltersAlert}>
                <InformationIcon />
                <span>{t("filtersAlert")}</span>
              </div>
              <Form.Item
                name="quick_automation_filters"
                className={s.bbQuickAutoFilters}
                rules={[
                  {
                    validator: async () => {
                      const formValues = filtersForm.getFieldsValue();
                      if (!isFilterValid(formValues)) {
                        return Promise.reject(
                          <FormErrorMessage>{t("filterError")}</FormErrorMessage>,
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Filters
                  ref={ref}
                  form={filtersForm}
                  initialFilterData={initialFilterData}
                  onChangeCallback={onFilterChange}
                  filtersConfig={filtersConfig}
                  onFilter={() => {}} // eslint-disable-line
                  clientPlatformTypes={[]}
                  autocompleteOptions={autocompleteOptions}
                  getAutocompleteOptions={getAutocompleteOptions}
                  sanitizeFieldName={getFetchValuesFieldName}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    );
  },
);

SubTagModalSection.displayName = "SubTagModalSection";
