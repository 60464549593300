import classNames from "classnames";
import React, { FC } from "react";

import s from "./WorkflowCategoryIcon.module.less";

import { WorkflowCategoryIconMap } from "../menu/helpers";

import { WorkflowItemCategoryType } from "@store/workflows/types";

const WorkflowItemTypeIcon: FC<{ type: WorkflowItemCategoryType }> = ({ type }) => {
  const Icon = WorkflowCategoryIconMap[type];

  return <i className={classNames(s.bbWorkflowItemTypeIcon, s[type])}>{Icon && <Icon />}</i>;
};

export default WorkflowItemTypeIcon;
