import dayjs, { Dayjs } from "dayjs";

import { ITimeFrameTrigger } from "@store/workflows/types";
import { t } from "../../../../../../App";

export const TIME_FORMAT = "HH:mm";
export const SHORT_TIME_FORMAT = "H:mm";

export const timeStringToDayjs = (time?: string) => dayjs(time, TIME_FORMAT);
export const dayjsToTimeString = (time: Dayjs): string => time.format(TIME_FORMAT);

export const getOptions = () => [
  { id: "time_frame", label: t("components:tags:time_frame") },
  { id: "filters_match", label: t("components:tags:filters_match") },
];

export const getDefaultTimeFrameValues = (timezone?: string): ITimeFrameTrigger => {
  return {
    start_time: { period: "AM", time: "09:00" },
    end_time: { period: "PM", time: "05:00" },
    week_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    timezone: timezone || "Europe/London",
  };
};

export const getDisabledHours = () => Array.from({ length: 24 }, (_, i) => i).slice(13, 24);
