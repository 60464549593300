import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AstraChat.module.less";

import { toggleOpenAIModalAction } from "@store/aiInsights/actions";
import { AIModalStateEnum } from "@store/aiInsights/types";

import { BbLogoColorIcon } from "@bbdevcrew/bb_ui_kit_fe";
import AstraImg from "@assets/astra.png";

export const AstraChat: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenChat = useCallback(() => {
    dispatch(toggleOpenAIModalAction(AIModalStateEnum.Expanded));
  }, [dispatch]);

  return (
    <div className={s.bbAstraChat}>
      <div className={s.bbAstraChatAvatarContainer}>
        <div className={s.bbAstraChatAvatar}>
          <img src={AstraImg} className={s.bbAstraChatAvatarImage} />
          <i className={s.bbAstraChatAvatarBadge}>
            <BbLogoColorIcon />
          </i>
        </div>
        <h2 className={s.bbAstraChatTitle}>{t("components:aiHighlights:moreWithAstra")}</h2>
      </div>
      <Button _type="link" _size="sm" className={s.bbAstraChatButton} onClick={handleOpenChat}>
        {t("components:aiHighlights:openChat")}
      </Button>
    </div>
  );
};
