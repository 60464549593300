import { createAction, ActionType } from "typesafe-actions";
import {
  GET_AI_HIGHLIGHT_PROMPTS,
  GET_AI_HIGHLIGHT_PROMPTS_SUCCESS,
  GET_AI_HIGHLIGHT_PROMPTS_FAILURE,
  GET_AI_HIGHLIGHT_PROMPT_BY_ID,
  GET_AI_HIGHLIGHT_PROMPT_BY_ID_SUCCESS,
  GET_AI_HIGHLIGHT_PROMPT_BY_ID_FAILURE,
  CREATE_AI_HIGHLIGHT_PROMPT,
  CREATE_AI_HIGHLIGHT_PROMPT_SUCCESS,
  CREATE_AI_HIGHLIGHT_PROMPT_FAILURE,
  UPDATE_AI_HIGHLIGHT_PROMPT,
  UPDATE_AI_HIGHLIGHT_PROMPT_SUCCESS,
  UPDATE_AI_HIGHLIGHT_PROMPT_FAILURE,
  DELETE_AI_HIGHLIGHT_PROMPT,
  DELETE_AI_HIGHLIGHT_PROMPT_SUCCESS,
  DELETE_AI_HIGHLIGHT_PROMPT_FAILURE,
  SET_AI_HIGHLIGHT_PROMPT_DEFAULT,
  SET_AI_HIGHLIGHT_PROMPT_DEFAULT_SUCCESS,
  SET_AI_HIGHLIGHT_PROMPT_DEFAULT_FAILURE,
  RESET_AI_HIGHLIGHT_PROMPTS_STATE,
  GET_AI_HIGHLIGHT_PROMPT_BY_ID_RESET,
} from "./actionTypes";
import { IAIHighlightPromptResponse, IAIHighlightPrompt, IAIHighlightPromptUpdate } from "./types";

export const getPromptsAction = createAction(GET_AI_HIGHLIGHT_PROMPTS)();
export const getPromptsSuccessAction = createAction(
  GET_AI_HIGHLIGHT_PROMPTS_SUCCESS,
)<IAIHighlightPromptResponse>();
export const getPromptsFailureAction = createAction(GET_AI_HIGHLIGHT_PROMPTS_FAILURE)();

export const getPromptByIdAction = createAction(GET_AI_HIGHLIGHT_PROMPT_BY_ID)<string>();
export const getPromptByIdSuccessAction = createAction(
  GET_AI_HIGHLIGHT_PROMPT_BY_ID_SUCCESS,
)<IAIHighlightPrompt>();
export const getPromptByIdFailureAction = createAction(GET_AI_HIGHLIGHT_PROMPT_BY_ID_FAILURE)();
export const getPromptByIdResetAction = createAction(GET_AI_HIGHLIGHT_PROMPT_BY_ID_RESET)();

export const createPromptAction = createAction(CREATE_AI_HIGHLIGHT_PROMPT)<IAIHighlightPrompt>();
export const createPromptSuccessAction = createAction(CREATE_AI_HIGHLIGHT_PROMPT_SUCCESS)();
export const createPromptFailureAction = createAction(CREATE_AI_HIGHLIGHT_PROMPT_FAILURE)();

export const updatePromptAction = createAction(
  UPDATE_AI_HIGHLIGHT_PROMPT,
)<IAIHighlightPromptUpdate>();
export const updatePromptSuccessAction = createAction(
  UPDATE_AI_HIGHLIGHT_PROMPT_SUCCESS,
)<IAIHighlightPrompt>();
export const updatePromptFailureAction = createAction(UPDATE_AI_HIGHLIGHT_PROMPT_FAILURE)();

export const deletePromptAction = createAction(DELETE_AI_HIGHLIGHT_PROMPT)<string>();
export const deletePromptSuccessAction = createAction(DELETE_AI_HIGHLIGHT_PROMPT_SUCCESS)<string>();
export const deletePromptFailureAction = createAction(DELETE_AI_HIGHLIGHT_PROMPT_FAILURE)();

export const setPromptAsDefaultAction = createAction(SET_AI_HIGHLIGHT_PROMPT_DEFAULT)<string>();
export const setPromptAsDefaultSuccessAction = createAction(
  SET_AI_HIGHLIGHT_PROMPT_DEFAULT_SUCCESS,
)<string>();
export const setPromptAsDefaultFailureAction = createAction(
  SET_AI_HIGHLIGHT_PROMPT_DEFAULT_FAILURE,
)();

export const resetStateAction = createAction(RESET_AI_HIGHLIGHT_PROMPTS_STATE)();

const actions = {
  getPromptsAction,
  getPromptsSuccessAction,
  getPromptsFailureAction,
  getPromptByIdAction,
  getPromptByIdSuccessAction,
  getPromptByIdFailureAction,
  createPromptAction,
  createPromptSuccessAction,
  createPromptFailureAction,
  updatePromptAction,
  updatePromptSuccessAction,
  updatePromptFailureAction,
  deletePromptAction,
  deletePromptSuccessAction,
  deletePromptFailureAction,
  setPromptAsDefaultAction,
  setPromptAsDefaultSuccessAction,
  setPromptAsDefaultFailureAction,
  resetStateAction,
};

export type AIHighlightPromptsAction = ActionType<typeof actions>;
