import React from "react";
import classNames from "classnames";

import { Tooltip, Loading } from "@bbdevcrew/bb_ui_kit_fe";

import ContextMenu from "../ContextMenu";
import TagBadge from "../../TagBadge";

import s from "./../Upload.module.less";

import { IBBUploadListProps } from "../Upload.type";
import { cropText, isDocument, isVideo } from "@bbdevcrew/bb_ui_kit_fe";

import { FileIcon, CloseIcon, PlayIcon } from "@bbdevcrew/bb_ui_kit_fe";

const DOC_FILENAME_MAX_LENGTH = 8;

export const BBUploadList: React.FC<IBBUploadListProps> = ({
  fileList,
  previewImages,
  onRemove,
  contextMenu,
  tagBadge,
}) => (
  <ul className={s.bbFileUploadList}>
    {fileList.map(file => {
      const { uid } = file;
      const isLoading = file.status !== "done";
      const isVideoFile = !!file.type && isVideo(file.type);
      const isDocFile = !!file.type && isDocument(file.type);
      const contextMenuProps = contextMenu && contextMenu(file);
      const tagBadgeProps = tagBadge && tagBadge(file);
      const isLoadedVideoFile = !isLoading && isVideoFile;
      const showContextMenu = contextMenuProps && contextMenuProps.items.length > 0;

      return (
        <li className={s.bbFileUploadItem} key={uid}>
          {!isLoading && (
            <>
              <button className={s.bbFileUploadItemRemove} onClick={() => onRemove?.(file)}>
                <CloseIcon />
              </button>
              {isVideoFile && (
                <div className={s.bbFileUploadItemVideo}>
                  <PlayIcon />
                </div>
              )}

              {isDocFile && (
                <div className={s.bbFileUploadItemDocument}>
                  <FileIcon />
                  {cropText(file.name, DOC_FILENAME_MAX_LENGTH)}
                </div>
              )}

              {showContextMenu && <ContextMenu {...contextMenuProps} />}
              {tagBadgeProps && (
                <>
                  {tagBadgeProps.tooltip ? (
                    <Tooltip title={tagBadgeProps.tooltip}>
                      <span className={s.bbUploadFileTagBadge}>
                        <TagBadge>{tagBadgeProps.icon}</TagBadge>
                      </span>
                    </Tooltip>
                  ) : (
                    <span className={s.bbUploadFileTagBadge}>
                      <TagBadge>{tagBadgeProps.icon}</TagBadge>
                    </span>
                  )}
                </>
              )}
            </>
          )}
          <div
            className={classNames(s.bbFileUploadItemInner, {
              isLoadedVideoFile,
            })}
          >
            {isLoading ? <Loading isLoading /> : previewImages?.[uid]}
          </div>
        </li>
      );
    })}
  </ul>
);
