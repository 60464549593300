import classNames from "classnames";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { Row, Col } from "antd";
import WordCloudTrends from "./wordCloudTrends/WordCloudTrends";
import KeyPhrasesTrends from "./keyPhrasesTrends/KeyPhrasesTrends";
import YTNotAvailableChip from "../../_common/ytNotAvailableChip";

import s from "./AnalysisTrends.module.less";

import { IWordCloudItems, ITopInfluencersItem } from "@store/overview/types";

import CloudIcon from "@assets/Cloud.svg";
import StreamIcon from "@assets/Stream.svg";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { useYTSelected } from "@utils/useYTselected";

interface IAnalysisTrendsProps {
  loading: boolean;
  filters: IFilters;
  wordCloudData: IWordCloudItems[];
  keywordPhrasesData: ITopInfluencersItem[];
  filterToSpecificItem?: (item: IFilters) => void;
}

const AnalysisTrends: FC<IAnalysisTrendsProps> = ({
  loading,
  filters,
  wordCloudData,
  keywordPhrasesData,
  filterToSpecificItem,
}) => {
  const { t } = useTranslation();
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const [tabKey, setTabKey] = useState(0);

  const getHeaderTitle = () => {
    return tabKey === 0
      ? t("components:analysisTrends:wordCloud:title")
      : t("components:analysisTrends:phrases:title");
  };

  const getSubHeaderSubTitle = () => {
    return tabKey === 0
      ? t("components:analysisTrends:wordCloud:subTitle")
      : t("components:analysisTrends:phrases:subTitle");
  };

  return (
    <>
      {!filters.saved_filter && (
        <div
          data-cy="top-keywords-container"
          className={s.bbAnalysisTrendsCardWrapper}
          data-stonly-target="intelligence-overview__sentiment-wordcloud"
        >
          <Card
            headerTitle={getHeaderTitle()}
            headerSubTitle={getSubHeaderSubTitle()}
            headerAction={isYTFilterSelected ? <YTNotAvailableChip /> : null}
          >
            <div className={s.bbRootAnalysisTrends}>
              <Row>
                <Col xs={24}>
                  <Row justify="space-around" className={s.bbAnalysisTrendsContent}>
                    <Col flex={1}>
                      {tabKey === 0 ? (
                        <WordCloudTrends
                          loading={loading}
                          data={wordCloudData}
                          filters={filters}
                          filterToSpecificItem={filterToSpecificItem}
                        />
                      ) : (
                        <KeyPhrasesTrends
                          loading={loading}
                          data={keywordPhrasesData}
                          filters={filters}
                          filterToSpecificItem={filterToSpecificItem}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="end" className={s.bbAnalysisTrendsContent}>
                <Col className={s.bbAnalysisTrendsButtons} data-cy="top-keywords-buttons">
                  <Tooltip
                    placement="top"
                    title={t("components:analysisTrends:wordCloud:tooltip:button")}
                  >
                    <Button
                      onClick={() => setTabKey(0)}
                      _type={tabKey === 0 ? "primary" : "link"}
                      className={classNames(s.bbContentAnalysisWordcloud, {
                        [s.bbContentAnalysisSelected]: tabKey === 0,
                      })}
                    >
                      <CloudIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    placement="top"
                    title={t("components:analysisTrends:phrases:tooltip:button")}
                  >
                    <Button
                      onClick={() => setTabKey(1)}
                      _type={tabKey === 1 ? "primary" : "link"}
                      className={classNames(s.bbContentAnalysisPhrases, {
                        [s.bbContentAnalysisSelected]: tabKey === 1,
                      })}
                    >
                      <StreamIcon />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default AnalysisTrends;
