import React from "react";
import classNames from "classnames/bind";

import { Form } from "antd";
import { Checkbox } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TTDiscloseContentOptions.module.less";

import { ITTPromoteContentCheckboxProps } from "./types";

export const TTPromoteContentCheckbox = ({
  name,
  label,
  checked,
  hasError,
  description,
  onChange,
}: ITTPromoteContentCheckboxProps) => (
  <Form.Item className={s.bbDicloseContentPromoteOwn}>
    <div className={s.bbDicloseContentSectionCheckbox}>
      <Checkbox
        id={name}
        _size="md"
        checked={checked}
        hasError={hasError}
        onChange={e => onChange(e, name)}
        className={classNames({
          [s.bbDicloseContentSectionCheckboxError]: hasError,
        })}
      />
      <label className={s.bbDicloseContentSectionCheckboxLabel}>{label}</label>
    </div>
    <p className={s.bbDicloseContentSectionCheckboxDescription}>{description}</p>
  </Form.Item>
);
