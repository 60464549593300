import { RootState } from "..";

export const fromTagsManagement = (state: RootState) => state.tagsManagement;

export const getTagsListSelector = (state: RootState) => fromTagsManagement(state).tagsGroups;
export const getTagsListPendingSelector = (state: RootState) =>
  fromTagsManagement(state).fetchingTagsList;
export const getTagsListSuccessfulSelector = (state: RootState) =>
  fromTagsManagement(state).fetchedTagsList;
export const getTagsListFailureSelector = (state: RootState) =>
  fromTagsManagement(state).fetchedTagsListFail;

export const fetchingTagSelector = (state: RootState) => fromTagsManagement(state).fetchingTag;
export const fetchedTagSelector = (state: RootState) => fromTagsManagement(state).fetchedTag;
export const fetchedTagFailedSelector = (state: RootState) =>
  fromTagsManagement(state).fetchedTagFailed;
export const fetchedTagFailedMessageSelector = (state: RootState) =>
  fromTagsManagement(state).fetchedTagFailedMessage;
