import React, {
  MouseEventHandler,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { debounce } from "@bbdevcrew/bb_ui_kit_fe";

import { ImageObj } from "./ImageObj";
import ReelView from "./ReelView";
import UserTagInput from "./UserTagInput";
import TagsOverlay from "./TagsOverlay";

import {
  MODAL_TRANSITION_DURATION,
  OFFSET_UPDATE_DEBOUNCE_TIME,
  useWindowResizeListener,
} from "./ImageView.helpers";
import { useTagsContext } from "../TagsModal.helpers";

import s from "./ImageView.module.less";

import { IImageViewProps } from "./ImageView.types";

export const ImageView: React.FC<IImageViewProps> = ({ previewSrc, previewType, tags = [] }) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement | HTMLVideoElement>();

  const [showTagsOverlay, setShowTagsOverlay] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(previewType === "video");

  const { coors, setCoors, offset, setOffset, addTag, maxTagsReached, isReel } = useTagsContext();

  const caclulateOffset = useCallback(() => {
    if (imageRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (imageRef.current) {
          setOffset(imageRef.current.getBoundingClientRect());
        }
      });
      resizeObserver.observe(imageRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [setOffset]);

  const onImageClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      caclulateOffset();
      if (offset.width > 0) {
        const x = (event.clientX - offset.x) / offset.width;
        const y = (event.clientY - offset.y) / offset.height;
        setCoors(false);
        setTimeout(() => {
          if (!maxTagsReached) {
            setCoors([x, y]);
          }
        }, 0);
      }
    },
    [maxTagsReached, offset, setCoors, caclulateOffset],
  );

  const offsetUpdate = useCallback(() => {
    caclulateOffset();
    setShowTagsOverlay(true);
  }, [caclulateOffset]);

  const offsetUpdateDebounced = useMemo(
    () => debounce(offsetUpdate, OFFSET_UPDATE_DEBOUNCE_TIME),
    [offsetUpdate],
  );

  const debouncedOffsetListener = useCallback(() => {
    setShowTagsOverlay(false);
    offsetUpdateDebounced();
  }, [offsetUpdateDebounced]);

  useWindowResizeListener(
    useCallback(() => {
      setShowTagsOverlay(false);
      return offsetUpdate;
    }, [offsetUpdate]),
  );

  useEffect(() => {
    if (previewLoaded) {
      setTimeout(() => {
        offsetUpdate();
      }, MODAL_TRANSITION_DURATION);
    }
  }, [previewLoaded, offsetUpdate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        imageRef.current &&
        !imageRef.current.contains(event.target as Node)
      ) {
        setCoors(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setCoors]);

  useEffect(() => {
    const modalContainers = document.querySelectorAll('[class*="bbModalWrapper--"]');
    if (modalContainers) {
      modalContainers.forEach(modalContainer => {
        modalContainer.addEventListener("scroll", debouncedOffsetListener);
      });
      return () => {
        modalContainers.forEach(modalContainer => {
          modalContainer.removeEventListener("scroll", debouncedOffsetListener);
        });
      };
    }
  }, [debouncedOffsetListener]);

  useEffect(() => {
    if (previewType === "image") {
      const img = new Image();
      img.src = previewSrc;
      img.onload = () => {
        setPreviewLoaded(true);
      };
    } else {
      setPreviewLoaded(true);
    }
  }, [previewSrc, previewType]);

  return (
    <div className={s.bbImageView}>
      {previewLoaded && (
        <>
          {isReel ? (
            <ReelView
              src={previewSrc}
              isCover={previewType === "image"}
              ref={imageRef as Ref<HTMLVideoElement>}
            />
          ) : (
            <>
              <ImageObj
                src={previewSrc}
                onClick={isReel ? undefined : onImageClick}
                ref={imageRef as Ref<HTMLImageElement>}
              />
              <TagsOverlay visible={showTagsOverlay} tags={tags} />
            </>
          )}
          {coors && (
            <UserTagInput
              x={coors[0]}
              y={coors[1]}
              onAdd={tag => {
                addTag(tag);
                setCoors(false);
              }}
              onCancel={() => setCoors(false)}
              ref={inputRef}
            />
          )}
        </>
      )}
    </div>
  );
};
