import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import { CommentAction } from "@bbdevcrew/bb_ui_kit_fe";

import { PostIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IActionDetailsProps {
  className?: string;
  onClickViewDetails?: () => void;
}

const ActionDetails: FC<IActionDetailsProps> = ({ className = "", onClickViewDetails }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  return (
    <CommentAction
      type="primary"
      className={className}
      onClick={onClickViewDetails}
      tooltipProps={{
        title: t("components:comments:actions:viewDetails"),
      }}
    >
      <PostIcon />
      {screens.sm && <span>{t("components:comments:actions:details")}</span>}
    </CommentAction>
  );
};

export default ActionDetails;
