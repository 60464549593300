import React from "react";
import classNames from "classnames";

import { ProcessorIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AiBadge.module.less";

const AiBadge: React.FC<{ danger?: boolean }> = ({ danger }) => (
  <span className={classNames(s.bbAiBadge, { [s.bbAiBadgeDanger]: danger })}>
    <ProcessorIcon />
  </span>
);

export default AiBadge;
