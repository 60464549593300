import React from "react";
import classNames from "classnames";

import { Avatar } from "antd";
import { Tooltip, WarningFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./index.module.less";

import {
  IAutocompleteAsset,
  platformIconName,
  isYoutubePlatform,
  getPlatformIconRoundFilled,
} from "@bbdevcrew/bb_ui_kit_fe";

interface IPostCreationAssetTabProps {
  asset: IAutocompleteAsset;
  hasError: boolean;
}

export function PostCreationAssetTab({ asset, hasError }: IPostCreationAssetTabProps) {
  const isYouTube = isYoutubePlatform(asset.platform);

  return (
    <Tooltip title={asset.label}>
      <div
        className={classNames(s.bbAssetImageContainer, {
          [s.bbAssetImageContainerError]: hasError,
        })}
      >
        <div className={s.bbAssetImageAvatar}>
          <Avatar
            size={32}
            shape="circle"
            src={asset.picture}
            data-cy="asset-image"
            className={s.bbAssetImage}
          />
        </div>
        {hasError && (
          <div className={s.bbErrorIcon}>
            <WarningFilledIcon />
          </div>
        )}
        <div
          className={classNames(s.bbAssetPlatformIcon, {
            [s.bbAssetPlatformIconYouTube]: isYouTube,
          })}
        >
          {getPlatformIconRoundFilled(platformIconName(asset.platform))}
        </div>
      </div>
    </Tooltip>
  );
}
