import React from "react";
import classNames from "classnames";

import s from "./ButtonTabs.module.less";

import { IButtonTabsProps } from "./ButtonTabs.types";

export const ButtonTabs: React.FC<IButtonTabsProps> = ({ tabs }) => {
  return (
    <div className={s.bbButtonTabs}>
      {tabs.map(tab => (
        <button
          className={classNames(s.bbButtonTabsTab, {
            [s.bbButtonTabsTabActive]: tab.active,
          })}
          key={tab.key}
          onClick={tab.onClick}
          data-cy={`button-tab-${tab.key}`}
        >
          {tab.icon}
        </button>
      ))}
    </div>
  );
};
