import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { NewsItem } from "../_common/NewsItem";

import { getControlPanelNewsSelector } from "@store/controlPanel/selector";

import s from "./Overview.module.less";

import { NoteFilledIcon } from "@assets/index";

export const OverviewSocialMedia: React.FC = () => {
  const { t } = useTranslation();

  const news = useSelector(getControlPanelNewsSelector);

  const socialMediaNews = news.social_media_news;

  const showNewsSection = !!socialMediaNews?.length;

  if (!showNewsSection) return null;
  return (
    <div className={s.bbOverviewNews}>
      <div className={s.bbOverviewNewsHeader}>
        <NoteFilledIcon />
        <div className={s.bbOverviewNewsHeaderText}>
          <span>{t("components:controlPanel:overview:news:socialMedia:title")}</span>
          <span>{t("components:controlPanel:overview:news:socialMedia:subTitle")}</span>
        </div>
      </div>
      <div className={s.bbOverviewNewsSection}>
        <div className={s.bbOverviewNewsList}>
          {socialMediaNews?.map(item => <NewsItem key={item.id} {...item} showDate />)}
        </div>
      </div>
    </div>
  );
};
