import { useTranslation } from "react-i18next";
import React, { FC, Fragment, useState } from "react";

import { Form, FormInstance } from "antd";
import { EmailAlertWorkflowAction } from "../emailAlertWorkflowAction";
import { Accordion, Button, Chip, DeleteConfirmation } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./../../WorkflowForm.module.less";

import { IWorkflowItem } from "@store/workflows/types";
import { EmailAlertWorkflowThreshold } from "../emailAlertWorkflowAction/emailAlertWorkflowThreshold"; // eslint-disable-line max-len

import { DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";

export interface IHideWorkflowEmailAlertAdditionalAction {
  form: FormInstance<IWorkflowItem>;
  additional_actions: JSX.Element[];
  onRemoveAdditionalAction: (index: number) => void;
}

export const HideWorkflowEmailAlertAdditionalAction: FC<
  IHideWorkflowEmailAlertAdditionalAction
> = ({ form, additional_actions, onRemoveAdditionalAction }) => {
  const { t } = useTranslation();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [hideActionThresholdCollapsed, setHideActionThresholdCollapsed] = useState(false);

  const handleToggleDeleteConfirmation = () => {
    setShowDeleteConfirmation(prev => !prev);
  };

  return (
    <Fragment>
      <Accordion
        type="white"
        className={s.bbThresholdTriggerActionAccordion}
        title={
          <span className={s.bbThresholdTriggerActionTitle}>
            {t("pages:workflows:form:threshold:email_alert:label")}
          </span>
        }
        collapsed={hideActionThresholdCollapsed}
        setCollapsed={() => setHideActionThresholdCollapsed(prev => !prev)}
        headerPrefix={
          <Chip
            _size="sm"
            _type="default"
            className={s.bbThresholdTriggerActionChip}
            text={t("pages:workflows:form:threshold:email_alert:chipTitle")}
          />
        }
        body={
          <>
            <Form.Item name="additional_actions" className={s.bbWorkflowItemTrigger}>
              <EmailAlertWorkflowThreshold
                form={form}
                hideTitle
                actionParentIndex={additional_actions?.length}
                actionParent={"additional_actions"}
                actionParentType="multiple"
              />
            </Form.Item>
          </>
        }
      />

      <Form.Item name="additional_actions" className={s.bbWorkflowItemTrigger}>
        <EmailAlertWorkflowAction
          form={form}
          actionParentIndex={additional_actions?.length}
          actionParent={"additional_actions"}
          actionParentType="multiple"
        />
      </Form.Item>

      <Button
        _type="danger-subtle"
        className={s.bbWorkflowItemRemoveButton}
        onClick={handleToggleDeleteConfirmation}
      >
        <DeleteIcon />
        {t("pages:workflows:form:additionalActions:removeAction")}
      </Button>

      {showDeleteConfirmation && (
        <DeleteConfirmation
          message={t("pages:workflows:form:additionalActions:removeConfirmation")}
          onConfirm={() => onRemoveAdditionalAction(additional_actions?.length)}
          onCancel={handleToggleDeleteConfirmation}
          className={s.bbWorkflowItemRemoveConfirmation}
        />
      )}
    </Fragment>
  );
};

export default HideWorkflowEmailAlertAdditionalAction;
