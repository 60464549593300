import { useTranslation } from "react-i18next";
import React, { FC, useRef, useEffect } from "react";

import { UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import { ITTVideoPreviewProps } from "./types";

import s from "./VideoPreview.module.less";

import { PlusIcon, HeartFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { DiscIcon, MusicIcon, CommentsIcon, SavesIcon, ShareFilledIcon } from "@assets/index";

const TTVideoPreview: FC<ITTVideoPreviewProps> = ({ videoSrc, caption, pageName, pageAvatar }) => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [videoSrc]);

  return (
    <div className={s.bbVideoPreview}>
      <div className={s.bbVideoWrapper}>
        <video width="240" ref={videoRef}>
          <source src={videoSrc} />
        </video>
        <div className={s.bbGradientOverlay}></div>
        <div className={s.bbRightVerticalActions}>
          <div className={s.bbAssetImage}>
            <UserAvatar img={pageAvatar} firstName={pageName} />
            <div className={s.bbPlusIcon}>
              <PlusIcon />
            </div>
          </div>
          <div className={s.bbLikeAction}>
            <HeartFilledIcon />
          </div>
          <div className={s.bbCommentAction}>
            <CommentsIcon />
          </div>
          <div className={s.bbSaveAction}>
            <SavesIcon />
          </div>
          <div className={s.bbShareAction}>
            <ShareFilledIcon />
          </div>
          <div className={s.bbDiscIcon}>
            <DiscIcon />
          </div>
        </div>
        <div className={s.bbBottomHorizontalActions}>
          <div className={s.bbPageName}>{pageName}</div>
          <div className={s.bbCaption}>{caption}</div>
          <div className={s.bbAudio}>
            <MusicIcon />
            {t("components:publish:postCreationModal:preview:originalSound")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TTVideoPreview;
