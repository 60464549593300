import React from "react";
import { createPortal } from "react-dom";

import Tag from "./Tag";

import { isUserTag, useTagsContext } from "../../TagsModal.helpers";

import s from "./TagsOverlay.module.less";

import { IProductTag, IUserTag } from "../../TagsModal.types";
import { ITagsOverlayProps } from "./TagsOverlay.types";

export const TagsOverlay: React.FC<ITagsOverlayProps> = ({ tags, visible }) => {
  const { offset } = useTagsContext();

  const wrapperStyles = {
    left: `${offset.left}px`,
    top: `${offset.top}px`,
    width: `${offset.width}px`,
    height: `${offset.height}px`,
    opacity: visible ? 1 : 0,
  };

  return createPortal(
    <div className={s.bbTagsOverlay} style={wrapperStyles}>
      {tags.map(currentTag => (
        <Tag
          key={
            isUserTag(currentTag)
              ? (currentTag as IUserTag).username
              : (currentTag as IProductTag).product_id
          }
          tag={currentTag}
        />
      ))}
    </div>,
    document.body,
  );
};
