import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import React, { FC, MouseEvent, useEffect, useState } from "react";

import { Chip, ChipAction, Tooltip, CloseIcon, Button } from "@bbdevcrew/bb_ui_kit_fe";
import PostTagsDropdown from "./PostTagsDropdown";

import {
  addingPostTagSelector,
  deleteTagFromPostSuccessfulSelector,
} from "@store/postTags/selectors";

import s from "./PostTagsList.module.less";

import { getTagStyles, cropText } from "@bbdevcrew/bb_ui_kit_fe";

import { LoadingOutlined } from "@ant-design/icons";
import { IPostTagsListProps } from "./PostTags.type";
import { IPostTag } from "@bbdevcrew/bb_ui_kit_fe";

import { MagicGradientColorIcon } from "@assets/index";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const POST_TAG_MAX_LENGTH = 30;

const PostTagsList: FC<IPostTagsListProps> = ({
  post,
  showAddTagButton = false,
  aiPostTags = [],
  postTags = [],
  maxTagListLength,
  onDeleteTag,
}) => {
  const mappedAIPostTags = aiPostTags.map(tag => ({
    isAI: true,
    ...tag,
  }));
  const allTags = [...mappedAIPostTags, ...postTags] as (IPostTag & { isAI?: boolean })[];
  const listedTags = maxTagListLength ? allTags.slice(0, maxTagListLength) : allTags;
  const restOfTheTags = allTags.slice(maxTagListLength);

  const [deletingTagId, setDeletingTagId] = useState<string | null>(null);

  const tagFromPostDeleted = useSelector(deleteTagFromPostSuccessfulSelector);
  const isAddingTag = useSelector(addingPostTagSelector);

  const getRestTooltipTitle = () => (
    <div className={s.bbRestOfTheTags}>
      {restOfTheTags.map(tag => (
        <div key={tag.id || tag.label}>{tag.label}</div>
      ))}
    </div>
  );

  useEffect(() => {
    if (tagFromPostDeleted) setDeletingTagId(null);
  }, [tagFromPostDeleted]);

  useEffect(() => {
    setDeletingTagId(null);
  }, [postTags.length]);

  const deleteTag = (event: MouseEvent<HTMLButtonElement>, tagId?: string) => {
    event.preventDefault();

    if (tagId) {
      setDeletingTagId(tagId);
      onDeleteTag?.(tagId);
    }
  };

  const renderActionIcon = (tag: IPostTag, isLastTag: boolean) => {
    const color = tag.color ? getTagStyles(tag.color).color : undefined;

    if (tag.id === deletingTagId || (isAddingTag && isLastTag)) {
      return <LoadingOutlined spin className={s.bbLoadingIcon} />;
    }

    if (!onDeleteTag) return null;

    return (
      <span className={s.bbPostTagActionIcon} style={{ color }}>
        <CloseIcon />
      </span>
    );
  };

  return (
    <span className={s.bbPostTagsList}>
      {listedTags.map((tag, index) => {
        const { id, label } = tag;
        const tagExceedsMaxLength = label.length > POST_TAG_MAX_LENGTH;
        const isWhiteColor = tag.color?.toLowerCase() === "#ffffff";
        const isLoadingAction = tag.id === deletingTagId;
        const hasActionFunction =
          !!onDeleteTag && id && id !== deletingTagId && !tag.isAI && !isLoadingAction;
        const isLastTag = allTags.length === listedTags.length && listedTags.length - 1 === index;

        return (
          <Tooltip key={id || label} title={tagExceedsMaxLength ? label : ""}>
            <span
              key={id || label}
              className={classNames(s.bbPostTagWrapper, {
                [s.bbPostTagWrapperWithTooltip]: tagExceedsMaxLength,
              })}
            >
              <ChipAction
                _size="xxs"
                _type={(tag.color || tag.isAI) && !isWhiteColor ? "default" : "grey"}
                style={tag.color && !tag.isAI && !isWhiteColor ? getTagStyles(tag.color) : {}}
                leftIcon={tag.isAI ? <MagicGradientColorIcon /> : null}
                text={
                  <span className={classNames({ [s.bbPostAITagText]: tag.isAI })}>
                    {cropText(label, POST_TAG_MAX_LENGTH)}
                  </span>
                }
                actionIcon={!tag.isAI ? renderActionIcon(tag, isLastTag) : null}
                wrapperClassName={s.bbPostTagWrapperInternal}
                className={classNames(s.bbPostTag, { [s.bbPostAITag]: tag.isAI })}
                onActionClick={hasActionFunction ? e => deleteTag(e, id) : undefined}
              />
            </span>
          </Tooltip>
        );
      })}
      {maxTagListLength && !!restOfTheTags.length && (
        <Tooltip title={getRestTooltipTitle()}>
          <span className={s.bbPostTagWrapper}>
            <Chip
              _size="xxs"
              _type="grey"
              text={"+" + restOfTheTags.length}
              className={s.bbRestOfTagsChip}
            />
          </span>
        </Tooltip>
      )}
      {showAddTagButton && post && (
        <PostTagsDropdown
          postTags={post.post_tags}
          postId={post.id}
          trigger={
            <Button _size="sm" _type="secondary" className={s.bbPostTagsListAddButton}>
              <PlusIcon />
            </Button>
          }
        />
      )}
    </span>
  );
};

export default PostTagsList;
