import React from "react";

import { Badge } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TagsManagement.module.less";

import { ITabTitleProps } from "./TagsManagement.types";

const TabTitle = ({ text, tagsAmount }: ITabTitleProps) => (
  <span className={s.bbTagsManagementViewTabTitle}>
    {text}
    <Badge text={tagsAmount} className={s.bbTagsManagementViewTabTitleBadge} />
  </span>
);

export default TabTitle;
