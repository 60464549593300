import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import { Grid } from "antd";
import { SourceModal } from "./SourceModal";
import SourcesTableRow from "./SourcesTableRow";
import { Button, Modal, SimpleTable } from "@bbdevcrew/bb_ui_kit_fe";
import Breadcrumbs from "../../_common/breadcrumbs";

import {
  getSourcesListPendingSelector,
  getSourcesListSelector,
  fetchingSourceSelector,
  fetchedSourceSelector,
  fetchingSourceFailedSelector,
  fetchingSourceFailedMessageSelector,
} from "@store/sources/selectors";

import {
  createSourceAction,
  deleteSourceAction,
  getSourcesListAction,
  updateSourceAction,
} from "@store/sources/actions";

import s from "./Sources.module.less";

import { getSourceTableData, getTableColumns, TABLE_PAGE_SIZE } from "./Sources.helpers";
import { ISource } from "@store/sources/types";
import { addToast, ISimpleTableColumn, SortType } from "@bbdevcrew/bb_ui_kit_fe";
import { ISourceModalForm, ISourcesTableSort, SourcesColumnSortType } from "./Sources.types";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const Sources = () => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [editingSource, setEditingSource] = useState<ISource | undefined>(undefined);
  const [sourceToDelete, setSourceToDelete] = useState<ISource | undefined>(undefined);
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [sort, setSort] = useState<ISourcesTableSort>();

  const listenSources = useSelector(getSourcesListSelector);
  const fetchingListenSources = useSelector(getSourcesListPendingSelector);
  const fetchingSource = useSelector(fetchingSourceSelector);
  const fetchedSource = useSelector(fetchedSourceSelector);
  const fetchingSourceFailed = useSelector(fetchingSourceFailedSelector);
  const fetchingSourceFailedMessage = useSelector(fetchingSourceFailedMessageSelector);

  const isMobile = screens.md === false;

  const getListenSources = useCallback(() => dispatch(getSourcesListAction()), [dispatch]);

  useEffect(() => {
    getListenSources();
  }, [getListenSources]);

  useEffect(() => {
    if (fetchedSource) {
      addToast({
        type: "success_accent",
        title: t("components:listen:sources:toastMessages:success"),
      });
    } else if (fetchingSourceFailed && fetchingSourceFailedMessage)
      addToast({ type: "danger_accent", title: fetchingSourceFailedMessage });
  }, [fetchedSource, t, fetchingSourceFailed, fetchingSourceFailedMessage]);

  const editSource = (source: ISource) => {
    setEditingSource(source);
    setIsSourceModalOpen(true);
  };

  const onDeleteSource = (source: ISource) => {
    setSourceToDelete(source);
  };

  const onSubmitSourceForm = (data: ISourceModalForm) => {
    dispatch(
      !!editingSource
        ? updateSourceAction({ ...data, id: editingSource.id })
        : createSourceAction(data),
    );
    setIsSourceModalOpen(false);
    setEditingSource(undefined);
  };

  const deleteSource = () => {
    if (!sourceToDelete) return;
    dispatch(deleteSourceAction(sourceToDelete.id));
    setSourceToDelete(undefined);
  };

  const onCloseSourceModal = () => {
    setIsSourceModalOpen(false);
    setEditingSource(undefined);
  };

  const onCloseDeleteModal = () => {
    setSourceToDelete(undefined);
  };

  const onSort = (column: ISimpleTableColumn, _sort?: SortType) => {
    if (!_sort) {
      setSort(undefined);
    } else {
      setSort({
        order: _sort,
        field: column.id_name as SourcesColumnSortType,
      });
    }
  };

  return (
    <>
      <div className={s.bbSources}>
        <div className={s.bbSourcesHeader}>
          <Breadcrumbs />
          <Button _size="md" _type="primary" onClick={() => setIsSourceModalOpen(true)}>
            <PlusIcon />
            <span>{t("components:listen:sources:addSource")}</span>
          </Button>
        </div>

        <SimpleTable
          data={getSourceTableData(listenSources, page, sort)}
          customClassName={s.bbSourcesTable}
          onSort={onSort}
          loading={fetchingListenSources}
          tableRow={SourcesTableRow({ editSource, deleteSource: onDeleteSource }, isMobile)}
          columns={getTableColumns({ isMobile })}
          pagination={{
            total: listenSources.length,
            pageSize: TABLE_PAGE_SIZE,
            currentPage: page,
            onChangePage: setPage,
          }}
          emptyTableText={t("generic:emptyTable")}
          pageOutOfText={[
            t("components:sortableTable:pageOutOfPart1"),
            t("components:sortableTable:pageOutOfPart2"),
          ]}
        />

        <SourceModal
          isOpen={isSourceModalOpen}
          isSubmitting={fetchingSource}
          editingSource={editingSource}
          onClose={onCloseSourceModal}
          onSubmit={onSubmitSourceForm}
        />

        <Modal
          centered
          responsive
          onCancel={onCloseDeleteModal}
          onOk={deleteSource}
          okButtonProps={{ _type: "danger" }}
          cancelLabel={t("generic:cancel")}
          confirmLabel={t("components:listen:sources:deleteConfirmationModal:confirmText")}
          open={!!sourceToDelete}
          title={t("components:listen:sources:deleteConfirmationModal:title")}
          confirmLoading={fetchingSource}
        >
          <p>{t("components:listen:sources:deleteConfirmationModal:text")}</p>
        </Modal>
      </div>
    </>
  );
};
