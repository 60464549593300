import { RootState } from "..";

export const fromTiktok = (state: RootState) => state.tiktokHashtags;

export const trendingHashtagsSelector = (state: RootState) => fromTiktok(state).trendingHashtags;
export const fetchingTrendingSelector = (state: RootState) => fromTiktok(state).fetchingTrending;
export const fetchedTrendingSelector = (state: RootState) => fromTiktok(state).fetchedTrending;
export const fetchedTrendingSelectorFaild = (state: RootState) =>
  fromTiktok(state).fetchedTrendingFail;

export const trendingHashtagsFiltersSelector = (state: RootState) =>
  fromTiktok(state).trendingHashtagsFilters;
export const fetchingtrendingHashtagsFiltersSelector = (state: RootState) =>
  fromTiktok(state).fetchingTrendingHashtagsFilters;
export const fetchedTrendingHashtagsFiltersSelector = (state: RootState) =>
  fromTiktok(state).fetchedTrendingHashtagsFilters;
export const fetchedTrendingHashtagsFiltersSelectorFaild = (state: RootState) =>
  fromTiktok(state).fetchedTrendingHashtagsFiltersFail;

export const fetchingTrendingVideosSelector = (state: RootState) =>
  fromTiktok(state).fetchingTrendingVideos;

export const trendingSongsSelector = (state: RootState) => fromTiktok(state).trendingSongs;

export const trendingSongsFiltersSelector = (state: RootState) =>
  fromTiktok(state).trendingSongsFilters;
export const fetchingtrendingSongsFiltersSelector = (state: RootState) =>
  fromTiktok(state).fetchingTrendingSongsFilters;
export const fetchedTrendingSongsFiltersSelector = (state: RootState) =>
  fromTiktok(state).fetchedTrendingSongsFilters;
export const fetchedTrendingSongsFiltersSelectorFaild = (state: RootState) =>
  fromTiktok(state).fetchedTrendingSongsFiltersFail;
