import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ModalFooter, NavBadge, TabPane, TabsResponsive } from "@bbdevcrew/bb_ui_kit_fe";

import UserTags from "./UserTags";
import ProductTags from "./ProductTags";

import { isProductTag, isUserTag, useTagsContext } from "../TagsModal.helpers";

import s from "./TagsView.module.less";

import { ITagsViewProps } from "./TagsView.types";
import { IProductTag, IUserTag, TagCategoryType } from "../TagsModal.types";

export const TagsView: React.FC<ITagsViewProps> = ({
  assetId,
  tags,
  activeTab,
  onTabChange,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { isReel } = useTagsContext();

  const [userTags, productTags] = useMemo(
    () => [tags.filter(isUserTag) as IUserTag[], tags.filter(isProductTag) as IProductTag[]],
    [tags],
  );

  return (
    <div className={s.bbTagsView}>
      <h2 className={s.bbTagsViewTitle}>
        {t(`components:publish:postCreationModal:mediaTags:${isReel ? "reelsTitle" : "title"}`)}
      </h2>
      <TabsResponsive
        defaultActiveKey="user"
        activeKey={activeTab}
        onChange={activeKey => onTabChange(activeKey as TagCategoryType)}
        tabHeadersClassName={s.bbTagsViewTabsHeaders}
        className={s.bbTagsViewTabs}
      >
        <TabPane
          tabKey="user"
          tab={
            <span className={s.bbTagsViewTabsLabel}>
              {t("components:publish:postCreationModal:mediaTags:tabs:users")}{" "}
              {userTags.length > 0 && <NavBadge>{userTags.length}</NavBadge>}
            </span>
          }
          className={s.bbTagsViewTab}
          wrapperClassName={s.bbTagsViewTabWrapper}
        >
          <div className={s.bbTagsViewContent}>
            <UserTags tags={userTags} />
          </div>
        </TabPane>
        <TabPane
          tabKey="product"
          tab={
            <span className={s.bbTagsViewTabsLabel}>
              {t("components:publish:postCreationModal:mediaTags:tabs:products")}{" "}
              {productTags.length > 0 && <NavBadge>{productTags.length}</NavBadge>}
            </span>
          }
          className={s.bbTagsViewTab}
          wrapperClassName={s.bbTagsViewTabWrapper}
        >
          <div className={s.bbTagsViewContent}>
            <ProductTags assetId={assetId} tags={productTags} />
          </div>
        </TabPane>
      </TabsResponsive>
      <div className={s.bbTagsViewFooter}>
        <ModalFooter
          onOk={() => onSave(tags)}
          confirmLabel={t("generic:save")}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};
