import React, { useState, useEffect } from "react";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import s from "./WorkflowCreationModal.module.less";

import { IWorkflowCreationModalProps } from "./WorkflowCreationModal.types";
import { IWorkflowItem, WorkflowItemCategoryType } from "@store/workflows/types";

const WorkflowCreationModal: React.FC<IWorkflowCreationModalProps> = ({ item, workflowType }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedWorkflowType, setSelectedWorkflowType] = useState<
    WorkflowItemCategoryType | undefined
  >(workflowType);
  const [formData, setFormData] = useState<IWorkflowItem>();

  useEffect(() => {
    if (currentStep === 0 && item) {
      setCurrentStep(1);
    } else if (currentStep === 0) {
      setFormData(undefined);
    }
  }, [item, currentStep]);

  return (
    <div className={s.bbWorkflowCreationModal} data-cy="workflow-creation-wrapper">
      {!item && currentStep === 0 && (
        <Step1
          setCurrentStep={setCurrentStep}
          selectedWorkflowType={selectedWorkflowType}
          setSelectedWorkflowType={setSelectedWorkflowType}
        />
      )}
      {item && currentStep === 1 ? (
        <Step2
          setCurrentStep={setCurrentStep}
          item={item}
          workflowType={item.category}
          setFormData={setFormData}
          savedFormData={formData}
        />
      ) : currentStep === 1 && selectedWorkflowType ? (
        <Step2
          setCurrentStep={setCurrentStep}
          item={item}
          workflowType={selectedWorkflowType}
          setFormData={setFormData}
          savedFormData={formData}
        />
      ) : null}
      {currentStep === 2 && formData && (
        <Step3 setCurrentStep={setCurrentStep} workflowData={formData} />
      )}
    </div>
  );
};

export default WorkflowCreationModal;
