import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { ReactNode, useState, useCallback, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import PublishingPostBody from "./PostBody";
import PublishingPostMetrics from "./PostMetrics";
import PostModal from "../../../_common/Social/Post/PostModal";
import { Tooltip, addToast, SentimentBar } from "@bbdevcrew/bb_ui_kit_fe";
import { PostPreviewTooltip } from "../../../_common/PostPreviewTooltip/PostPreviewTooltip";

import { getUrlPreviewsAction, getTiktokCreatorInfoAction } from "@store/publishings/actions";

import s from "./PublishingPost.module.less";

import {
  postHasImage,
  postHasError,
  isPostDraft,
  isPostEditable,
  isPostPublished,
  isPostBeingPublished,
  getPublishingPostTooltipProps,
  deleteSearchParam,
} from "./helpers";
import CalendarContext from "../context/CalendarContext";
import { IPublishingPost } from "@store/publishings/types";

interface IPublishingPostComponentProps {
  post: IPublishingPost;
}

export default function PublishingPostComponent({ post }: IPublishingPostComponentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setEditingPost, setIsPostModalOpen } = useContext(CalendarContext);

  const [isPublishedPostModalOpen, setPublishedPostModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchedPostId = searchParams.get("publication_id") || "";

  const getUrlPreviews = useCallback(
    (links: string[]) => dispatch(getUrlPreviewsAction(links)),
    [dispatch],
  );

  const getTiktokCreatorInfo = useCallback(
    (asset_id: string) => dispatch(getTiktokCreatorInfoAction(asset_id)),
    [dispatch],
  );

  const handleEditPost = () => {
    if (post.is_editable) {
      if (post.publishing_preview_link) {
        getUrlPreviews([post.publishing_preview_link]);
      }

      if (post.asset.platform === "tiktok") {
        getTiktokCreatorInfo(post.asset.id);
      }

      setIsPostModalOpen(true);
      setEditingPost(post);
      return;
    }

    if (isPostBeingPublished(post)) {
      addToast({
        type: "danger_accent",
        title: t("components:publish:calendar:errors:cantBeEdited"),
      });
    }
  };

  const togglePublishedPostModal = (open: boolean) => {
    setPublishedPostModalOpen(open);
    if (!open) {
      deleteSearchParam("publication_id", searchParams, setSearchParams);
    }
  };

  const handlePostClick = () => {
    if (isPostPublished(post)) togglePublishedPostModal(true);

    if (isPostEditable(post)) handleEditPost();
  };

  useEffect(() => {
    if (searchedPostId === post.id) {
      if (isPostPublished(post)) {
        togglePublishedPostModal(true);
      } else if (isPostEditable(post)) {
        handleEditPost();
      }
    }
    // eslint-disable-next-line
  }, [searchedPostId]);

  const PreviewTooltip = ({ children }: { children?: ReactNode }) =>
    isPostPublished(post) ? (
      <PostPreviewTooltip post={post} placement="right">
        {children}
      </PostPreviewTooltip>
    ) : (
      <>{children}</>
    );

  return (
    <>
      <PreviewTooltip>
        <Tooltip {...getPublishingPostTooltipProps(post, t)}>
          <div
            className={classNames(s.bbPost, {
              [s.bbPostWithPreview]: postHasImage(post),
              [s.bbPostWithError]: postHasError(post),
              [s.bbPostAvailable]: isPostEditable(post) || isPostPublished(post),
              [s.bbPostDraft]: isPostDraft(post),
            })}
            onClick={() => handlePostClick()}
          >
            <PublishingPostBody post={post} />
            {!!post.comment_metrics?.total_comments && (
              <div className={s.bbSentimentBarWrapper}>
                <SentimentBar data={post.comment_metrics} />
              </div>
            )}
            {!!post.post_metrics && <PublishingPostMetrics post={post} />}
          </div>
        </Tooltip>
      </PreviewTooltip>
      <PostModal
        post={post}
        showPostTags
        isOpen={isPublishedPostModalOpen}
        onClose={() => togglePublishedPostModal(false)}
      />
    </>
  );
}
