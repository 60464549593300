// Get prompts
export const GET_AI_HIGHLIGHT_PROMPTS = "AI_HIGHLIGHT_PROMPTS/GET_PROMPTS";
export const GET_AI_HIGHLIGHT_PROMPTS_SUCCESS = "AI_HIGHLIGHT_PROMPTS/GET_PROMPTS_SUCCESS";
export const GET_AI_HIGHLIGHT_PROMPTS_FAILURE = "AI_HIGHLIGHT_PROMPTS/GET_PROMPTS_FAILURE";

// Get prompt by ID
export const GET_AI_HIGHLIGHT_PROMPT_BY_ID = "AI_HIGHLIGHT_PROMPTS/GET_PROMPT_BY_ID";
export const GET_AI_HIGHLIGHT_PROMPT_BY_ID_SUCCESS =
  "AI_HIGHLIGHT_PROMPTS/GET_PROMPT_BY_ID_SUCCESS";
export const GET_AI_HIGHLIGHT_PROMPT_BY_ID_FAILURE =
  "AI_HIGHLIGHT_PROMPTS/GET_PROMPT_BY_ID_FAILURE";
export const GET_AI_HIGHLIGHT_PROMPT_BY_ID_RESET = "AI_HIGHLIGHT_PROMPTS/GET_PROMPT_BY_ID_RESET";

// Create prompt
export const CREATE_AI_HIGHLIGHT_PROMPT = "AI_HIGHLIGHT_PROMPTS/CREATE_PROMPT";
export const CREATE_AI_HIGHLIGHT_PROMPT_SUCCESS = "AI_HIGHLIGHT_PROMPTS/CREATE_PROMPT_SUCCESS";
export const CREATE_AI_HIGHLIGHT_PROMPT_FAILURE = "AI_HIGHLIGHT_PROMPTS/CREATE_PROMPT_FAILURE";

// Update prompt
export const UPDATE_AI_HIGHLIGHT_PROMPT = "AI_HIGHLIGHT_PROMPTS/UPDATE_PROMPT";
export const UPDATE_AI_HIGHLIGHT_PROMPT_SUCCESS = "AI_HIGHLIGHT_PROMPTS/UPDATE_PROMPT_SUCCESS";
export const UPDATE_AI_HIGHLIGHT_PROMPT_FAILURE = "AI_HIGHLIGHT_PROMPTS/UPDATE_PROMPT_FAILURE";

// Delete prompt
export const DELETE_AI_HIGHLIGHT_PROMPT = "AI_HIGHLIGHT_PROMPTS/DELETE_PROMPT";
export const DELETE_AI_HIGHLIGHT_PROMPT_SUCCESS = "AI_HIGHLIGHT_PROMPTS/DELETE_PROMPT_SUCCESS";
export const DELETE_AI_HIGHLIGHT_PROMPT_FAILURE = "AI_HIGHLIGHT_PROMPTS/DELETE_PROMPT_FAILURE";

// Set default prompt
export const SET_AI_HIGHLIGHT_PROMPT_DEFAULT = "AI_HIGHLIGHT_PROMPTS/SET_DEFAULT";
export const SET_AI_HIGHLIGHT_PROMPT_DEFAULT_SUCCESS = "AI_HIGHLIGHT_PROMPTS/SET_DEFAULT_SUCCESS";
export const SET_AI_HIGHLIGHT_PROMPT_DEFAULT_FAILURE = "AI_HIGHLIGHT_PROMPTS/SET_DEFAULT_FAILURE";

// Reset state
export const RESET_AI_HIGHLIGHT_PROMPTS_STATE = "AI_HIGHLIGHT_PROMPTS/RESET_STATE";
