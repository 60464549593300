import React from "react";
import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Row, Col, Tag } from "antd";
import { Button, CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./Sources.module.less";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import { RowActionsType, TableRowProps } from "./Sources.types";
import { getPlatformIconBasicColor } from "@bbdevcrew/bb_ui_kit_fe";

import { DeleteIcon, DisabledIcon, EditIcon } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

const SourcesTableRowWithActions = (actions: RowActionsType, isMobile: boolean) => {
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  const SourcesTableRow = React.memo(({ data: row, index }: TableRowProps) => (
    <Row
      className={cx(s.bbTableRow, {
        [s.bbTableRowColored]: index % 2 === 1,
      })}
    >
      <Col className={cx(s.bbColumn, s.bbSourceColumn)} span={isMobile ? 12 : 7}>
        {row.source_label}
      </Col>
      {!isMobile && (
        <>
          <Col className={s.bbColumn} span={4}>
            {row.type.label}
          </Col>
          <Col className={cx(s.bbColumn, s.bbAssetColumn)} span={5}>
            {getPlatformIconBasicColor(row.platform)}
            {row.asset_name}
          </Col>
          <Col className={s.bbColumn} span={4}>
            <Tag
              key={row.id + "-status-tag"}
              icon={row.asset_status === "active" ? <CheckIcon /> : <DisabledIcon />}
              color={row.asset_status === "active" ? palette.colorGreen100 : palette.colorGrey700}
              className={cx(s.bbStatusTag, `bbStatusTag-${row.asset_status}`)}
            >
              {row.asset_status === "active"
                ? t("components:listen:sources:statuses:monitoring")
                : t("components:listen:sources:statuses:notMonitoring")}
            </Tag>
          </Col>
        </>
      )}
      <Col className={cx(s.bbColumn, s.bbActionsColumn)} span={isMobile ? 12 : 4}>
        {row.can_manage ? (
          <div className={s.bbSourceActions}>
            <Button
              _type="secondary"
              _size="sm"
              className={s.bbSourceActionBtn}
              onClick={() => actions?.editSource(row)}
              disabled={row.platform === "tiktok" && !me?.is_bb_user}
            >
              <EditIcon />
            </Button>
            <Button
              _type="secondary"
              _size="sm"
              className={s.bbSourceActionBtn}
              onClick={() => actions?.deleteSource(row)}
            >
              <DeleteIcon />
            </Button>
          </div>
        ) : null}
      </Col>
    </Row>
  ));

  SourcesTableRow.displayName = "SourcesTableRow";

  return SourcesTableRow;
};

export default SourcesTableRowWithActions;
