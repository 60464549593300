import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useState, useCallback, useEffect } from "react";

import { Form } from "antd";
import { Input, Button, Tooltip, Checkbox, AIPromptSection } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AIPromptsForm.module.less";

import {
  createdCaptionsAIPromptSuccessfulSelector,
  creatingCaptionsAIPromptSelector,
  updatedCaptionsAIPromptSuccessfulSelector,
  updatingCaptionsAIPromptSelector,
} from "@store/captionsAIPrompts/selectors";
import {
  clearCaptionsPromptAction,
  createCaptionsPromptAction,
  updateCaptionsPromptAction,
} from "@store/captionsAIPrompts/actions";

import { getAIPromptSectionsConfig } from "./getAIPromptSectionsConfig";
import { ICaptionsAIPromptForm, ICaptionsAIPromptFormProps } from "./types";
import { ICaptionsAIPrompt } from "@store/captionsAIPrompts/types";

import { ChevronLeftIcon, HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const CaptionsAIPromptsForm: FC<ICaptionsAIPromptFormProps> = ({
  mode,
  editItem,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<ICaptionsAIPromptForm>();

  const [isDefault, setIsDefault] = useState(false);

  const sections = editItem ? getAIPromptSectionsConfig(t, editItem) : getAIPromptSectionsConfig(t);

  const creatingAIPrompt = useSelector(creatingCaptionsAIPromptSelector);
  const updatingAIPrompt = useSelector(updatingCaptionsAIPromptSelector);
  const createdAIPrompt = useSelector(createdCaptionsAIPromptSuccessfulSelector);
  const updatedAIPrompt = useSelector(updatedCaptionsAIPromptSuccessfulSelector);

  const clearPrompt = useCallback(() => dispatch(clearCaptionsPromptAction()), [dispatch]);

  const createPrompt = useCallback(
    (prompt: ICaptionsAIPrompt) => dispatch(createCaptionsPromptAction(prompt)),
    [dispatch],
  );

  const updatePrompt = useCallback(
    (prompt: ICaptionsAIPrompt) => dispatch(updateCaptionsPromptAction(prompt)),
    [dispatch],
  );

  useEffect(() => {
    if (mode === "edit" && editItem) {
      const newEditItem = {
        ...editItem,
        customize_hashtags: editItem.hashtags,
        customize_hashtags_enabled: !!editItem.hashtags?.length,
      };
      form.setFieldsValue(newEditItem);
      setIsDefault(editItem.is_default);
    }
  }, [mode, editItem, form]);

  useEffect(() => {
    if (createdAIPrompt || updatedAIPrompt) {
      clearPrompt();
      onCancel();
    }
    // eslint-disable-next-line
  }, [createdAIPrompt, updatedAIPrompt]);

  const onFinish = (values: ICaptionsAIPromptForm) => {
    // eslint-disable-next-line
    const { customize_hashtags: hashtags, customize_hashtags_enabled: _, ...restValues } = values;
    const filteredValues = { ...restValues, hashtags };

    if (mode === "create") {
      createPrompt({ ...filteredValues, is_default: isDefault });
    } else if (mode === "edit" && editItem) {
      updatePrompt({
        ...filteredValues,
        is_default: isDefault,
        id: editItem.id,
      });
    }
  };

  const onSetAsDefaultChange = () => {
    if (!isDefault) {
      form.resetFields(["is_default"]);
    } else {
      form.setFieldsValue({ is_default: true });
    }

    setIsDefault(!isDefault);
  };

  return (
    <>
      <div className={s.bbAIPromptFormHeader}>
        <Button _type="link" className={s.bbAIPromptFormHeaderBack} onClick={onCancel}>
          <ChevronLeftIcon />
        </Button>
        <div className={s.bbAIPromptFormHeaderTitle}>{t(`components:AIPrompts:${mode}:title`)}</div>
      </div>
      <div data-cy="edit-AI-prompt-config" className={s.bbAIPromptFormConfig}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
          className={s.bbAIPromptFormConfigForm}
        >
          <Form.Item
            name="name"
            data-cy="AIPrompt-name"
            label={t("components:AIPrompts:edit:name:label")}
            rules={[
              {
                required: true,
                message: t("components:AIPrompts:edit:name:error"),
              },
            ]}
          >
            <Input _size="md" data-cy="AIPrompts-name-input" />
          </Form.Item>

          <div className={s.bbSetAsDefaultFormItem}>
            <span>
              <Checkbox
                id="is_default"
                name="is_default"
                checked={isDefault}
                onChange={onSetAsDefaultChange}
                data-cy="AI-prompt-is_default"
              />

              <span className={s.bbSetAsDefaultFormItemTitle}>
                {t("components:AIPrompts:edit:setAsDefault")}
              </span>
              <Tooltip title={t("components:AIPrompts:edit:setAsDefaultTooltip")}>
                <span className={s.bbSetAsDefaultHelpIcon}>
                  <HelpIcon />
                </span>
              </Tooltip>
            </span>
          </div>

          <div className={s.bbPromptSettingsTitle}>
            {t("components:AIPrompts:edit:structureSectionName")}
          </div>

          {sections.map((section, index) => (
            <AIPromptSection key={index} form={form} {...section} />
          ))}

          <Form.Item>
            <div className={s.bbAIPromptFormConfigFormActions}>
              <Button _type="secondary" onClick={onCancel}>
                {t("generic:discard")}
              </Button>
              <Button _type="primary" type="submit" loading={creatingAIPrompt || updatingAIPrompt}>
                {t("generic:save")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
