import classNames from "classnames/bind";
import React from "react";

import { Col } from "antd";
import { ColumnWithGrowth } from "./ProfilesTableRow.helpers";

import s from "./ProfilesTable.module.less";

import { getPlatformIcon } from "./ProfilesTable.helpers";
import { EngagementsTableRowProps } from "./ProfilesTable.types";

const EngagementsTableRow = React.memo(
  ({ data: row, index, columns }: EngagementsTableRowProps) => {
    return (
      <>
        {!!columns.length && row && (
          <div
            data-cy="profiles-table-row"
            className={classNames(s.bbProfilesTableRow, {
              [s.bbProfilesTableRowColored]: index % 2 === 1,
            })}
          >
            <Col span={columns[0].colSpan} data-cy="profiles-column" className={s.bbColPlatform}>
              <div data-cy="profiles-platform" className={s.bbPlatformIconSmall}>
                {getPlatformIcon(row.platform)}
              </div>
              <span>{row.asset_name}</span>
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[1].colSpan}>
              <ColumnWithGrowth
                value={row.er_impressions}
                growth={row.er_impression_growth}
                showPercentage
                status={row.status}
              />
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[2].colSpan}>
              <ColumnWithGrowth
                value={row.engagements}
                growth={row.engagements_growth}
                status={row.status}
              />
            </Col>
          </div>
        )}
      </>
    );
  },
);

EngagementsTableRow.displayName = "EngagementsTableRow";

export default EngagementsTableRow;
