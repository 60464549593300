import React from "react";
import classNames from "classnames";

import { IModalProps, Modal } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./FileContextModal.module.less";

interface IFileContextModalProps extends IModalProps {
  className?: string;
}

export const FileContextModal: React.FC<IFileContextModalProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Modal
      noPadding
      hideHeader
      hideFooter
      responsive
      centered
      classes={{
        root: classNames(s.bbFileContextModal, className),
        modal: s.bbFileContextModalContent,
      }}
      width={1000}
      {...restProps}
    >
      <div className={s.bbFileContextModalInner}>{children}</div>
    </Modal>
  );
};
