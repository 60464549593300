import React from "react";

import s from "./BBTags.module.less";

import {
  ICommentTag,
  ICommentTagFilterItem,
  IObjectOption,
  ITagOption,
} from "@bbdevcrew/bb_ui_kit_fe";

import { PremiumIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const bbTagToOption = (tagOpt: ITagOption, isParent = true) => {
  const option: IObjectOption = {
    id: tagOpt.value || (tagOpt.id as string),
    label: tagOpt.title || (tagOpt.name as string),
    options: tagOpt.children?.map(child => bbTagToOption(child, false)),
    isNotSelectable: isParent || !!tagOpt.tag_id,
  };

  if (tagOpt.is_premium) {
    option.rightLabel = (
      <span className={s.bbTagPremiumIcon}>
        <PremiumIcon />
      </span>
    );
  }

  return option;
};

export const getTagsFormatted = (commentTags: ICommentTagFilterItem[]) =>
  commentTags.reduce(
    (acc, tag) => {
      if (tag.children) {
        const childrenTags = tag.children.flatMap(({ key, title, value, category, children }) => {
          const formattedChild = {
            id: key,
            label: title,
            value,
            category,
          };

          const grandChildrenTags = children
            ? children.map(
                ({
                  tag_id,
                  id,
                  name,
                  key: childKey,
                  title: childTitle,
                  value: childValue,
                  category: childCategory,
                }) => ({
                  id: childKey || (tag_id as string),
                  label: childTitle || (name as string),
                  value: childValue || (id as string),
                  category: childCategory,
                }),
              )
            : [];

          return [formattedChild, ...grandChildrenTags];
        });

        return [...acc, ...childrenTags];
      }

      return acc;
    },
    [] as (ICommentTag & { value: string })[],
  );
