import React from "react";
import { t } from "i18next";

import { Icon } from "@bbdevcrew/bb_ui_kit_fe";

import {
  IFilters,
  IFilterItem,
  DeleteFilledIcon,
  getSentimentOptions,
} from "@bbdevcrew/bb_ui_kit_fe";
import { IMe, ITwitterType } from "@store/me/types";
import { selectedPostTypes } from "./selectedPostTypes";

const GET_VALUES_FIELD_MAPPING = {
  ad_ids: "ads",
  post_ids: "post",
  author_ids: "author",
  asset_ids: "asset",
  languages: "language",
  campaign_ids: "campaign",
  adset_ids: "adset",
  post_tags: "post_tags",
  targetings: "ads_targeting",
};

export const mapFilterItemToSelectProps = (
  filterItems: IFilterItem[],
  onClickSuffixElem?: () => void,
) =>
  filterItems.map((filterItem: IFilterItem) => ({
    ...filterItem,
    value: filterItem.id,
    label: filterItem.name,
    suffixElem: onClickSuffixElem ? <Icon _size="sm" icon={DeleteFilledIcon} /> : undefined,
    onClickSuffixElem,
  }));

export const isTTorYTEnabled = (me?: IMe) => {
  return !!me?.client_data?.platform_types?.filter(
    platform => platform.id.includes("tiktok") || platform.id.includes("youtube"),
  ).length;
};

export const normalizeTwitterTypes = (twitterTypes: ITwitterType[]) =>
  twitterTypes.map(ttype => ({ ...ttype, value: ttype.id }));

export const getFetchValuesFieldName = (fieldName: string) => {
  let sanitizedFieldName = fieldName;

  sanitizedFieldName =
    GET_VALUES_FIELD_MAPPING[fieldName as keyof typeof GET_VALUES_FIELD_MAPPING] || fieldName;

  return sanitizedFieldName;
};

export const getDefaultFilterValue = (me?: IMe) => {
  const sentimentOptions = getSentimentOptions(t).map(o => o.value);
  const postTypes = selectedPostTypes(me?.post_types);

  const filteredOptions = isTTorYTEnabled(me)
    ? sentimentOptions
    : sentimentOptions.filter(option => option !== "not_applicable");

  const defaultFilters: IFilters = {
    data_range_option: "last_30_days",
    keyword_query_in_translation: true,
    post_types: postTypes?.length ? postTypes : undefined,
    platform_types: me?.client_data.platform_types.map(p => p.id) || [],
    sentiments: filteredOptions,
  };

  return defaultFilters;
};

export const isDeepEquals = (a: unknown, b: unknown) => JSON.stringify(a) === JSON.stringify(b);
