import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Grid } from "antd";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { ContextMenu } from "./ContextMenu";

import { workflowItemsSelector } from "@store/workflows/selectors";

import s from "./AlertsTable.module.less";

import { getFrequency } from "./AlertsTable.helpers";

import { IAlertNotificationProps } from "./AlertsTable.types";

const AlertNotification: React.FC<IAlertNotificationProps> = ({ alert, onEdit }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;

  const workflows = useSelector(workflowItemsSelector);
  const workflow = useMemo(
    () =>
      workflows.find(item => item.id === `notification_${alert.notification_id}`) ||
      workflows.find(item =>
        item.additional_actions?.some(
          action => action.notification_id === alert.notification_id.toString(),
        ),
      ),
    [workflows, alert.notification_id],
  );

  return (
    <div className={s.bbAlertsTableRowNotification}>
      <div className={s.bbAlertsTableRowNotificationName}>
        <Tooltip title={t("components:listen:alerts:tooltip:explore")}>
          <h3
            onClick={() => {
              window.location.href = alert.explore_url;
            }}
          >
            {alert.notification_name}
          </h3>
        </Tooltip>
        {workflow?.email_alert && <p>{getFrequency(workflow.email_alert)}</p>}
      </div>
      {!!(!isMobile && workflow && onEdit) && (
        <ContextMenu
          onEdit={() => {
            onEdit(workflow);
          }}
        />
      )}
    </div>
  );
};

export default AlertNotification;
