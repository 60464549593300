import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { useTagsContext } from "../TagsModal.helpers";

import s from "./ImageView.module.less";

import { IImageObjProps } from "./ImageView.types";

export const ImageObj = forwardRef<HTMLImageElement, IImageObjProps>((props, ref) => {
  const { t } = useTranslation();
  const { maxTagsReached } = useTagsContext();

  return maxTagsReached ? (
    <Tooltip title={t("components:publish:postCreationModal:mediaTags:limitReached")}>
      <img ref={ref} className={s.bbCursorNotAllowed} {...props} />
    </Tooltip>
  ) : (
    <img ref={ref} {...props} />
  );
});

ImageObj.displayName = "ImageObj";
