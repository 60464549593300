import { t } from "i18next";
import { createReducer, ActionType } from "typesafe-actions";
import { IAssetsState } from "./types";
import {
  getAssetsActionSuccess,
  deleteAssetActionSuccess,
  configureAssetMonitoringAction,
} from "./actions";
import {
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAILURE,
  CONFIGURE_ASSET_MONITORING,
  CONFIGURE_ASSET_MONITORING_SUCCESS,
  CONFIGURE_ASSET_MONITORING_FAILURE,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  PATCH_RECONNECT_ASSETS,
  PATCH_RECONNECT_ASSETS_SUCCESS,
  PATCH_RECONNECT_ASSETS_FAILURE,
} from "./actionTypes";
import { ASSET_EXPIRY_STATUS } from "./types";

const initialState = {
  assets: [],
  assetsSocial: [],
  adAccountLinkedPages: [],
  isAddedAssetLinked: false,
  fetchingGetAssets: false,
  fetchedGetAssets: false,
  fetchedGetAssetsFail: false,
  fetchingConfigureAssetMonitoring: false,
  fetchedConfigureAssetMonitoring: false,
  fetchedConfigureAssetMonitoringFail: false,
  fetchingDeleteAsset: false,
  fetchedDeleteAsset: false,
  fetchedDeleteAssetFail: false,
  fetchingPatchReconnectAssets: false,
  fetchedPatchReconnectAssets: false,
  fetchedPatchReconnectAssetsFail: false,
};

export const assetsReducer = createReducer<IAssetsState>(initialState, {
  [GET_ASSETS]: (state: IAssetsState) => ({
    ...state,
    fetchingGetAssets: true,
    fetchedGetAssets: false,
    fetchedGetAssetsFail: false,
  }),
  [GET_ASSETS_SUCCESS]: (
    state: IAssetsState,
    action: ActionType<typeof getAssetsActionSuccess>,
  ) => ({
    ...state,
    assets: [...action.payload],
    fetchingGetAssets: false,
    fetchedGetAssets: true,
    fetchedGetAssetsFail: false,
  }),
  [GET_ASSETS_FAILURE]: (state: IAssetsState) => ({
    ...state,
    fetchingGetAssets: false,
    fetchedGetAssets: false,
    fetchedGetAssetsFail: true,
  }),

  // CONFIGURE ASSET MONITORING
  [CONFIGURE_ASSET_MONITORING]: (state: IAssetsState) => ({
    ...state,
    fetchingConfigureAssetMonitoring: true,
    fetchedConfigureAssetMonitoring: false,
    fetchedConfigureAssetMonitoringFail: false,
  }),
  [CONFIGURE_ASSET_MONITORING_SUCCESS]: (
    state: IAssetsState,
    action: ActionType<typeof configureAssetMonitoringAction>,
  ) => {
    const assetsCopy = state.assets.map(asset => {
      if (asset.id && action.payload.id === asset.id) {
        asset.webhook_config = {
          ...asset.webhook_config,
          ad_comments_enabled: action.payload.webhook_ad_comments,
          main_monitoring_toggle: action.payload.webhook_main_monitoring,
          organic_comments_enabled: action.payload.webhook_organic_comments,
          dm_integration_enabled: action.payload.webhook_private_messages,
          mention_integration_enabled: action.payload.webhook_mentions,
          reviews_enabled: action.payload.webhook_reviews,
          ig_story_reply_integration_enabled: action.payload.webhook_ig_story_reply,
          media_tag_mention_integration_enabled: action.payload.webhook_media_tag_mentions,
        };

        asset.asset_status_id = action.payload.webhook_main_monitoring
          ? ASSET_EXPIRY_STATUS.monitoring
          : ASSET_EXPIRY_STATUS.not_monitoring;
        asset.asset_status = action.payload.webhook_main_monitoring
          ? t("components:assetManagementList:statuses:" + ASSET_EXPIRY_STATUS.monitoring)
          : t("components:assetManagementList:statuses:" + ASSET_EXPIRY_STATUS.not_monitoring);
      }

      return asset;
    });

    return {
      ...state,
      assets: assetsCopy,
      fetchingConfigureAssetMonitoring: false,
      fetchedConfigureAssetMonitoring: true,
      fetchedConfigureAssetMonitoringFail: false,
    };
  },
  [CONFIGURE_ASSET_MONITORING_FAILURE]: (state: IAssetsState) => ({
    ...state,
    fetchingConfigureAssetMonitoring: false,
    fetchedConfigureAssetMonitoring: false,
    fetchedConfigureAssetMonitoringFail: true,
  }),

  //REQUEST REMOVAL
  [DELETE_ASSET]: (state: IAssetsState) => ({
    ...state,
    fetchingDeleteAsset: true,
    fetchedDeleteAsset: false,
    fetchedDeleteAssetFail: false,
  }),
  [DELETE_ASSET_SUCCESS]: (
    state: IAssetsState,
    action: ActionType<typeof deleteAssetActionSuccess>,
  ) => {
    const assetsCopy = state.assets.map(ass => {
      if (ass.id && action.payload === ass.id) {
        ass.asset_status_id = ASSET_EXPIRY_STATUS.pending_removal;
        ass.asset_status = t(
          `components:assetManagementList:statuses:${ASSET_EXPIRY_STATUS.pending_removal}`,
        );
      }

      return ass;
    });

    return {
      ...state,
      assets: assetsCopy,
      fetchingDeleteAsset: false,
      fetchedDeleteAsset: true,
      fetchedDeleteAssetFail: false,
    };
  },
  [DELETE_ASSET_FAILURE]: (state: IAssetsState) => ({
    ...state,
    fetchingDeleteAsset: false,
    fetchedDeleteAsset: false,
    fetchedDeleteAssetFail: true,
  }),

  // RECONNECT ASSETS
  [PATCH_RECONNECT_ASSETS]: (state: IAssetsState) => {
    return {
      ...state,
      fetchingPatchReconnectAssets: true,
      fetchedPatchReconnectAssets: false,
      fetchedPatchReconnectAssetsFail: false,
    };
  },
  [PATCH_RECONNECT_ASSETS_SUCCESS]: (state: IAssetsState) => {
    return {
      ...state,
      fetchingPatchReconnectAssets: false,
      fetchedPatchReconnectAssets: true,
      fetchedPatchReconnectAssetsFail: false,
    };
  },
  [PATCH_RECONNECT_ASSETS_FAILURE]: (state: IAssetsState) => {
    return {
      ...state,
      fetchingPatchReconnectAssets: false,
      fetchedPatchReconnectAssets: false,
      fetchedPatchReconnectAssetsFail: true,
    };
  },
});
