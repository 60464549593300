import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";
import { StateObservable } from "redux-observable";

import {
  getTrendingHashtagsFiltersSuccessAction,
  getTrendingHashtagsFiltersFailureAction,
  getTrendingSongsFiltersFailureAction,
  getTrendingSongsFiltersSuccessAction,
  getTrendingVideosSuccessAction,
  getTrendingItemsSuccessAction,
  getTrendingItemsFailureAction,
  getTrendingVideosFailureAction,
} from "./actions";
import {
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS,
  TIKTOK_GET_TRENDING_ITEMS,
  TIKTOK_GET_TRENDING_SONGS_FILTERS,
  TIKTOK_GET_TRENDING_VIDEOS,
} from "./actionTypes";
import {
  TiktokTrendingActionsType,
  ITrendingFilters,
  ITrendingItem,
  ITrendingHashtagsVideoPayload,
  TrendingModeEnum,
} from "./types";
import { RootState } from "..";

import { tiktokTrending, trendingHashtagsFilters, trendingSongsFilters } from "@utils/paths";
import { getAuthAPIHeaders } from "@utils/headers";
import { handleError, generateQueryParams } from "@bbdevcrew/bb_ui_kit_fe";

export const getTrendingVideos = (
  action$: Observable<TiktokTrendingActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_VIDEOS)),
    switchMap(({ payload: { ids, filters, mode } }) => {
      const isHashtagMode = mode === TrendingModeEnum.HASHTAGS;

      const idParams = ids.map(id => `${isHashtagMode ? "hashtagIds" : "songIds"}=${id}`).join("&");

      const getVideosUrl =
        `${tiktokTrending(mode)}/videos?${idParams}&` +
        `countryId=${filters.countryId}&` +
        `dateRangeId=${filters.dateRangeId}`;

      return ajax
        .get<{ items: ITrendingHashtagsVideoPayload[] }>(getVideosUrl, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(videos => {
            return getTrendingVideosSuccessAction({ items: videos.items, mode });
          }),
          catchError(e => handleError(e, getTrendingVideosFailureAction)),
        );
    }),
  );
};

export const getTrendingItems = (
  action$: Observable<TiktokTrendingActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_ITEMS)),
    switchMap(a => {
      const { mode, ...payload } = a.payload;
      return ajax
        .get<
          ITrendingItem[]
        >(`${tiktokTrending(mode)}${generateQueryParams(payload)}`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => getTrendingItemsSuccessAction(data, mode)),
          catchError(e => handleError(e, getTrendingItemsFailureAction)),
        );
    }),
  );
};

export const getTrendingHashtagsFilters = (
  action$: Observable<TiktokTrendingActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS)),
    switchMap(() => {
      return ajax.get<ITrendingFilters>(trendingHashtagsFilters, getAuthAPIHeaders(state$)).pipe(
        map(e => e.response),
        map(data => getTrendingHashtagsFiltersSuccessAction(data)),
        catchError(e => handleError(e, getTrendingHashtagsFiltersFailureAction)),
      );
    }),
  );
};

export const getTrendingSongsFilters = (
  action$: Observable<TiktokTrendingActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_SONGS_FILTERS)),
    switchMap(() => {
      return ajax.get<ITrendingFilters>(trendingSongsFilters, getAuthAPIHeaders(state$)).pipe(
        map(e => e.response),
        map(data => getTrendingSongsFiltersSuccessAction(data)),
        catchError(e => handleError(e, getTrendingSongsFiltersFailureAction)),
      );
    }),
  );
};
