import { ISimpleSelectOptionProps, IAutocompleteOption } from "@bbdevcrew/bb_ui_kit_fe";

export const OUTLINED_LOADING_DEFAULT_SIZE = 20;

export const transformLocation = (location: IAutocompleteOption) => {
  return {
    id: location.value,
    value: location.value || "",
    description: location.label,
    label: location.id || "",
    tooltip: location.tooltip,
  } as ISimpleSelectOptionProps;
};
