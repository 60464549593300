import { RootState } from "..";

export const fromPostTags = (state: RootState) => state.postTags;

export const postTagsSelector = (state: RootState) => fromPostTags(state).postTags;

export const getPostTagsSuccessfulSelector = (state: RootState) =>
  fromPostTags(state).fetchedPostTags;
export const fetchingPostTagsSelector = (state: RootState) => fromPostTags(state).fetchingPostTags;
export const getPostTagsFailedSelector = (state: RootState) =>
  fromPostTags(state).fetchedPostTagsFail;

export const postTagsForFilterSelector = (state: RootState) =>
  fromPostTags(state).postTagsForFilter;
export const fetchingPostTagsForFilterSelector = (state: RootState) =>
  fromPostTags(state).fetchingPostTagsForFilter;
export const fetchedPostTagsForFilterSelector = (state: RootState) =>
  fromPostTags(state).fetchedPostTagsForFilter;
export const fetchingPostTagsForFilterFailSelector = (state: RootState) =>
  fromPostTags(state).fetchingPostTagsForFilterFail;

export const addingPostTagSelector = (state: RootState) => fromPostTags(state).postingPostTag;
export const addingPostTagLabelSelector = (state: RootState) =>
  fromPostTags(state).postingPostTagLabel;
export const addPostTagSuccessfulSelector = (state: RootState) => fromPostTags(state).postedPostTag;
export const addPostTagResultSelector = (state: RootState) =>
  fromPostTags(state).postedPostTagResult;
export const addPostTagFailedSelector = (state: RootState) => fromPostTags(state).postedPostTagFail;

export const deleteTagSelector = (state: RootState) => fromPostTags(state).deletingTag;
export const deleteTagSuccessfulSelector = (state: RootState) => fromPostTags(state).deletedTag;
export const deletedTagIdSelector = (state: RootState) => fromPostTags(state).deletedTagFromPostId;
export const deleteTagFailedSelector = (state: RootState) => fromPostTags(state).deletedTagFail;

export const deleteTagFromPostSelector = (state: RootState) =>
  fromPostTags(state).deletingTagFromPost;
export const deleteTagFromPostSuccessfulSelector = (state: RootState) =>
  fromPostTags(state).deletedTagFromPost;
export const deleteTagFromPostFailedSelector = (state: RootState) =>
  fromPostTags(state).deletedTagFromPostFail;
