import { action } from "typesafe-actions";
import {
  GET_TRENDING_POSTS,
  GET_TRENDING_POSTS_SUCCESS,
  GET_TRENDING_POSTS_FAILURE,
  GET_AD_TARGETINGS,
  GET_AD_TARGETINGS_SUCCESS,
  GET_AD_TARGETINGS_FAILURE,
} from "./actionTypes";
import { ITrendingPlatformPostsProps, IAdTargetingsProps } from "./types";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export const getTrendingPostsAction = (filterData: IFilters) =>
  action(GET_TRENDING_POSTS, filterData);
export const getTrendingPostsActionSuccess = (data: ITrendingPlatformPostsProps[]) =>
  action(GET_TRENDING_POSTS_SUCCESS, data);
export const getTrendingPostsActionFailure = () => action(GET_TRENDING_POSTS_FAILURE);

export const getAdTargetingsAction = (filterData: IFilters) =>
  action(GET_AD_TARGETINGS, filterData);
export const getAdTargetingsActionSuccess = (data: IAdTargetingsProps[]) =>
  action(GET_AD_TARGETINGS_SUCCESS, data);
export const getAdTargetingsActionFailure = () => action(GET_AD_TARGETINGS_FAILURE);
