import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Avatar } from "antd";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import ListeningChip from "../ListeningChip/ListeningChip";
import { PostPreviewTooltip } from "../PostPreviewTooltip/PostPreviewTooltip";

import s from "./CommentPostPreview.module.less";

import {
  cropText,
  getPostSubtext,
  isInstagramPlatform,
  PostPlacementEnum,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ICommentPostPreviewProps } from "./types";
import { isEarnedMessage } from "./helpers";

import { CommentIcon, HeartIcon } from "@bbdevcrew/bb_ui_kit_fe";

const CommentPostPreview: FC<ICommentPostPreviewProps> = ({ comment }) => {
  const { t } = useTranslation();
  const { post } = comment;
  const isIgHashtagMention =
    isInstagramPlatform(post?.platform_type.id) && post.type === "HASHTAG_MENTION";

  const getEarnedMessageType = () => {
    switch (post?.type) {
      case "MEDIA_TAG_MENTION":
        return t("components:comments:listeningChip:messageTypes:mediaTagMention");
      case "HASHTAG_MENTION":
        return t("components:comments:listeningChip:messageTypes:brandHashtagsMention");
      case "CAPTION_MENTION":
        return t("components:comments:listeningChip:messageTypes:captionMention");
      case "REVIEW":
        return t("components:comments:listeningChip:messageTypes:review");
      case "MENTION":
        return t("components:comments:listeningChip:messageTypes:mention");
      default:
        return t("components:comments:listeningChip:messageTypes:mention");
    }
  };

  return (
    <>
      {!!post && (
        <div className={s.bbCommentPostPreviewWrapper}>
          {isEarnedMessage(post.post_type) && (
            <Tooltip
              title={t("components:comments:listeningChip:tooltip", {
                message_type: getEarnedMessageType(),
              })}
            >
              <span className={s.bbListeningChipWrapper}>
                <ListeningChip />
              </span>
            </Tooltip>
          )}
          <PostPreviewTooltip placement="left" post={post}>
            <div className={s.bbCommentPostPreview}>
              {!!post.picture && (
                <div
                  className={classNames(s.bbCommentPostPreviewImage, {
                    [s.bbCommentPostPreviewImageStory]:
                      post.post_placement === PostPlacementEnum.STORY,
                  })}
                >
                  <Avatar
                    size={80}
                    shape="square"
                    style={{ fontSize: "12px" }}
                    src={post.picture}
                    className={s.bbCommentPostPreviewImageAvatar}
                  >
                    {t("generic:noImage")}
                  </Avatar>
                </div>
              )}

              <div className={s.bbCommentPostPreviewInfo}>
                <div className={s.bbCommentPostPreviewHeader}>
                  <div>
                    <Avatar
                      size={16}
                      shape="circle"
                      src={comment.asset_image}
                      className={s.bbPostLogo}
                    />
                    <span className={s.bbAssetName}>{post.author_name}</span>
                  </div>
                  <div className={s.bbPostInfo}>{getPostSubtext(post, "DD MMM, YYYY")}</div>
                </div>

                <div className={s.bbPostMessage}>{cropText(post.message, 60)}</div>

                {isIgHashtagMention && (
                  <div className={s.bbPostInsights}>
                    <div className={s.bbPostInsightsItem}>
                      <CommentIcon />
                      <div className={s.bbPostInsightsItemCount}>
                        {post.comment_metrics.total_comments}
                      </div>
                    </div>
                    <div className={s.bbPostInsightsItem}>
                      <HeartIcon />
                      <div className={s.bbPostInsightsItemCount}>{post.reactions?.like}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PostPreviewTooltip>
        </div>
      )}
    </>
  );
};

export default CommentPostPreview;
