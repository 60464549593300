import classNames from "classnames/bind";
import React from "react";

import { Col } from "antd";
import { ColumnWithGrowth } from "./ProfilesTableRow.helpers";

import s from "./ProfilesTable.module.less";

import { getPlatformIcon } from "./ProfilesTable.helpers";
import { ImpressionsProfileTableRowProps } from "./ProfilesTable.types";

const ImpressionsProfileTableRow = React.memo(
  ({ data: row, index, columns }: ImpressionsProfileTableRowProps) => {
    return (
      <>
        {!!columns.length && row && (
          <div
            data-cy="profiles-table-row"
            className={classNames(s.bbProfilesTableRow, {
              [s.bbProfilesTableRowColored]: index % 2 === 1,
            })}
          >
            <Col span={columns[0].colSpan} data-cy="profiles-column" className={s.bbColPlatform}>
              <div data-cy="profiles-platform" className={s.bbPlatformIconSmall}>
                {getPlatformIcon(row.platform)}
              </div>
              <span>{row?.asset_name}</span>
            </Col>
            <Col
              className={classNames(s.bbColumn, s.bbImpressionColumn)}
              data-cy="table-column"
              span={columns[1].colSpan}
            >
              <ColumnWithGrowth
                value={row.total_impressions}
                growth={row.growth}
                status={row.status}
              />
            </Col>
          </div>
        )}
      </>
    );
  },
);

ImpressionsProfileTableRow.displayName = "ImpressionsProfileTableRow";

export default ImpressionsProfileTableRow;
