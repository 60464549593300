import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Engage.module.less";

import { ReplyIcon, UnlockIcon } from "@bbdevcrew/bb_ui_kit_fe";
import ManagedEngageBottomWidget from "../../../../assets/ManagedEngageBottomWidget.svg";
import ManagedEngageTopWidget from "../../../../assets/ManagedEngageTopWidget.svg";

export const EngageUpgrade = () => {
  const { t } = useTranslation();
  return (
    <div className={s.bbUpgradeEngage}>
      <div className={s.bbUpgradeEngageModal}>
        <div className={s.bbUpgradeEngageIconWrapper}>
          <ReplyIcon />
        </div>
        <h5 className={s.bbUpgradeEngageModalTitle}>
          {t("components:managed:engage:upgradeModal:title")}
        </h5>
        <Tooltip title={t("components:managed:engage:upgradeModal:tooltip")}>
          <span className={s.bbUpgradeEngageModalButton}>
            <UnlockIcon />
            {t("components:managed:engage:upgradeModal:button")}
          </span>
        </Tooltip>
      </div>
      <ManagedEngageTopWidget />
      <ManagedEngageBottomWidget />
    </div>
  );
};
