import React from "react";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import AddTagButton from "../../AddTagButton";

import s from "./TagHeader.module.less";

import { ITagHeaderProps } from "./TagHeader.types";

export const TagHeader: React.FC<ITagHeaderProps> = ({ title, disabled, addLabel, onAddClick }) => {
  return (
    <div className={s.bbTagHeader}>
      {title && <h2 className={s.bbTagHeaderTitle}>{title}</h2>}
      {onAddClick &&
        (addTagButton =>
          !!disabled ? (
            <Tooltip title={disabled}>
              <span>{addTagButton}</span>
            </Tooltip>
          ) : (
            addTagButton
          ))(
          <AddTagButton
            disabled={!!disabled}
            onClick={onAddClick}
            label={addLabel}
            className={s.bbTagHeaderAdd}
          />,
        )}
    </div>
  );
};
