import { RootState } from "..";

export const fromAiHighlights = (state: RootState) => state.aiHighlights;

export const aiHighlightsDataSelector = (state: RootState) => fromAiHighlights(state).data;

export const aiHighlightsFetchingSelector = (state: RootState) =>
  fromAiHighlights(state).getAiHighlights.fetching;
export const aiHighlightsFetchedSelector = (state: RootState) =>
  fromAiHighlights(state).getAiHighlights.fetched;
export const aiHighlightsFetchFailSelector = (state: RootState) =>
  fromAiHighlights(state).getAiHighlights.fetchFail;

export const aiHighlightsLimitSelector = (state: RootState) => fromAiHighlights(state).data?.limit;
export const aiHighlightsConsumedSelector = (state: RootState) =>
  fromAiHighlights(state).data?.consumed;
export const aiHighlightsStartDateSelector = (state: RootState) =>
  fromAiHighlights(state).data?.start_date;
export const aiHighlightsEndDateSelector = (state: RootState) =>
  fromAiHighlights(state).data?.end_date;
export const aiHighlightsMessageSelector = (state: RootState) =>
  fromAiHighlights(state).data?.message;
export const aiHighlightsLastUpdatedSelector = (state: RootState) =>
  fromAiHighlights(state).data?.last_updated;
export const aiHighlightsInitialFetchDoneSelector = (state: RootState) =>
  fromAiHighlights(state).initialFetchDone;
export const aiHighlightsWidgetStateSelector = (state: RootState) =>
  fromAiHighlights(state).widgetState;
