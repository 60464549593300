/* eslint-disable max-len */
// P: Portrait, e.g. 1P = 1 Portrait
// L: Landscape, e.g. 2L means 2 Landscape Images
export const imageStyles = [
  {
    "0P": [
      {
        imageContainerStyle: {
          inset: "calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)",
        },
        wrapperStyle: { paddingTop: "50%" },
        rootStyle: { height: "calc(133.333%)", top: "0%", width: "100%" },
      },

      {
        imageContainerStyle: {
          inset: "calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)",
        },
        wrapperStyle: {
          paddingTop: "50%",
        },
        rootStyle: { height: "calc(133.185%)", top: "0%", width: "100%" },
      },
    ],

    "1P": [
      {
        imageContainerStyle: {
          inset: "calc(0% + 0px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)",
        },
        wrapperStyle: { paddingTop: "100%" },
        rootStyle: { height: "calc(150%)", top: "0%", width: "100%" },
      },

      {
        imageContainerStyle: {
          inset: "calc(0% + 0px) calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)",
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: { height: "calc(150%)", top: "0%", width: "100%" },
      },
    ],

    "2P": [
      {
        imageContainerStyle: {
          inset: "calc(0% + 0px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)",
        },
        wrapperStyle: { paddingTop: "200%" },
        rootStyle: {
          height: "100%",
          left: "-19.4143%",
          width: "calc(138.829%)",
        },
      },

      {
        imageContainerStyle: {
          inset: "calc(0% + 0px) calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)",
        },
        wrapperStyle: {
          paddingTop: "200%",
        },
        rootStyle: {
          height: "100%",
          left: "-21.4286%",
          width: "calc(142.857%)",
        },
      },
    ],
  },

  {
    //Portrait should be on the left:
    "0P": [
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(33.3333% + 1.01px)`,
        },
        wrapperStyle: { paddingTop: "66.6667%" },
        rootStyle: {
          height: "100%",
          left: "-0.0555556%",
          width: "calc(100.111%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)
                  calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                  calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: { height: "100%", left: "0%", width: "calc(100%)" },
      },
    ],

    "1P": [
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(33.3333% + 1.01px)`,
        },
        wrapperStyle: { paddingTop: "66.6667%" },
        rootStyle: { height: "calc(225%)", top: "-24.25%", width: "100%" },
      },

      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)
                    calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "-0.0555556%",
          width: "calc(100.111%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                    calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: { height: "100%", left: "0%", width: "calc(100%)" },
      },
    ],

    "2P": [
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
                    calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "150%" },
        rootStyle: { height: "100%", left: "0%", width: "calc(100%)" },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)
                    calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "150%",
        },
        rootStyle: { height: "100%", left: "-62.5%", width: "calc(225%)" },
      },
      {
        imageContainerStyle: {
          inset: `calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                    calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "150%",
        },
        rootStyle: { height: "100%", top: "0%", width: "calc(100%)" },
      },
    ],

    "3P": [
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
                      calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "150%" },
        rootStyle: {
          height: "100%",
          left: "-2.06074%",
          width: "calc(104.121%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(50% + 1.01px)
                    calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "150%",
        },
        rootStyle: { height: "100%", left: "0%", width: "calc(100%)" },
      },
      {
        imageContainerStyle: {
          inset: `calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                    calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "150%",
        },
        rootStyle: { height: "100%", top: "0%", width: "calc(100%)" },
      },
    ],
  },

  {
    "0P": [
      {
        imageContainerStyle: {
          inset: `calc((0% / 6) + 0px) calc((0% / 6) + 0px) calc((200% / 6) + 1.01px) calc((0% / 6) + 0px)`,
        },
        wrapperStyle: { paddingTop: "66.66666666666667%" },
        rootStyle: {
          height: "100%",
          left: "-0.05555555555555314%",
          width: "calc(((901 / 600) / 1.5) * 100%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc((400% / 6) + 1.01px) calc((400% / 6) + 1.01px) calc((0% / 6) + 0px) calc((0% / 6) + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(((900 / 600) / 1) * 100%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc((400% / 6) + 1.01px) calc((200% / 6) + 1.01px) calc((0% / 6) + 0px) calc((200% / 6) + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(((900 / 600) / 1) * 100%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc((400% / 6) + 1.01px) calc((0% / 6) + 0px) calc((0% / 6) + 0px) calc((400% / 6) + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(((900 / 600) / 1) * 100%)",
        },
      },
    ],

    "1P": [
      {
        //Should be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(33.3333% + 1.01px)`,
        },
        wrapperStyle: { paddingTop: "66.6667%" },
        rootStyle: {
          height: "calc(106.785%)",
          top: "0%",
          width: "100%",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(66.6667% + 1.01px) calc(0% + 0px)
          calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(33.3333% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25.0833%",
          width: "calc(150.167%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
            calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(150%)",
        },
      },
    ],

    "2P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
            calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "150%" },
        rootStyle: {
          height: "100%",
          left: "-2.06074%",
          width: "calc(104.121%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
            calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
            calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(150%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
            calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25.0833%",
          width: "calc(150.167%)",
        },
      },
    ],

    "3P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
            calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "150%" },
        rootStyle: {
          height: "100%",
          left: "-2.06074%",
          width: "calc(104.121%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
            calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
            calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(150%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
            calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
    ],

    "4P": [
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(33.3333% + 1.01px) calc(0% + 0px)
          calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "150%" },
        rootStyle: {
          height: "100%",
          left: "-2.06074%",
          width: "calc(104.121%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
          calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
          calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(150%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
          calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
    ],
  },

  {
    "0P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "100%" },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(150%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25.0833%",
          width: "calc(150.167%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
              calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
              calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
              calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },
    ],

    "1P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "100%" },
        rootStyle: {
          height: "calc(140%)",
          top: "0%",
          width: "100%",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(150%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "-0.0555556%",
          width: "calc(100.111%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },
    ],

    "2P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "100%" },
        rootStyle: {
          height: "calc(144.062%)",
          top: "0%",
          width: "100%",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(140%)",
          top: "0%",
          width: "100%",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "-0.0555556%",
          width: "calc(100.111%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },
    ],

    "3P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "100%" },
        rootStyle: {
          height: "calc(144.062%)",
          top: "0%",
          width: "100%",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(50% + 1.01px) calc(50% + 1.01px) calc(0% + 0px) calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(140%)",
          top: "0%",
          width: "100%",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(66.6667% + 1.01px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "calc(225%)",
          top: "-24.25%",
          width: "100%",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(33.3333% + 1.01px) calc(0% + 0px) calc(33.3333% + 1.01px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "0%",
          width: "calc(100%)",
        },
      },

      {
        imageContainerStyle: {
          inset: `calc(66.6667% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "66.6667%",
        },
        rootStyle: {
          height: "100%",
          left: "-0.0555556%",
          width: "calc(100.111%)",
        },
      },
    ],

    "4P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(50% + 1.01px) calc(40% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "100%" },
        rootStyle: {
          height: "calc(144.062%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(40% + 1.01px) calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(140%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(60% + 1.01px) calc(66.6667% + 1.01px) calc(0% + 0px)
                calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(60% + 1.01px) calc(33.3333% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "100%",
          left: "-25%",
          width: "calc(150%)",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(60% + 1.01px) calc(0% + 0px) calc(0% + 0px)
                calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },

        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
    ],

    "5P": [
      {
        //Must be Portrait
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(50% + 1.01px) calc(40% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: { paddingTop: "100%" },
        rootStyle: {
          height: "calc(144.062%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(0% + 0px) calc(0% + 0px) calc(40% + 1.01px) calc(50% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(140%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(60% + 1.01px) calc(66.6667% + 1.01px) calc(0% + 0px)
              calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(137.188%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(60% + 1.01px) calc(33.3333% + 1.01px) calc(0% + 0px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
      {
        imageContainerStyle: {
          inset: `calc(60% + 1.01px) calc(0% + 0px) calc(0% + 0px)
              calc(66.6667% + 1.01px)`,
        },
        wrapperStyle: {
          paddingTop: "100%",
        },
        rootStyle: {
          height: "calc(150%)",
          top: "0%",
          width: "100%",
        },
      },
    ],
  },
];

export default imageStyles;
