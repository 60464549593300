import { action } from "typesafe-actions";
import {
  GET_COMMENT_TAGS,
  GET_COMMENT_TAGS_SUCCESS,
  GET_COMMENT_TAGS_FAILURE,
  POST_COMMENT_TAG,
  POST_COMMENT_TAG_SUCCESS,
  POST_COMMENT_TAG_FAILURE,
  DELETE_COMMENT_TAG,
  DELETE_COMMENT_TAG_SUCCESS,
  DELETE_COMMENT_TAG_FAILURE,
} from "./actionTypes";
import { IPostCommentTagPayload } from "../commentTags/types";
import { ICommentTag, ICommentTagFilterItem } from "@bbdevcrew/bb_ui_kit_fe";

export const getCommentTagsAction = () => action(GET_COMMENT_TAGS);
export const getCommentTagsSuccessAction = (data: { items: ICommentTagFilterItem[] }) =>
  action(GET_COMMENT_TAGS_SUCCESS, data);
export const getCommentTagsFailureAction = () => action(GET_COMMENT_TAGS_FAILURE);

//POST COMMENT TAG
export const postCommentTagAction = (payload: IPostCommentTagPayload) =>
  action(POST_COMMENT_TAG, payload);

export const postCommentTagSuccessAction = (
  payload: IPostCommentTagPayload,
  response: ICommentTag,
) => action(POST_COMMENT_TAG_SUCCESS, { ...payload, tag: response });

export const postCommentTagFailureAction = (payload: IPostCommentTagPayload) =>
  action(POST_COMMENT_TAG_FAILURE, payload);

//DElETE COMMENT TAG
export const deleteCommentTagAction = (payload: IPostCommentTagPayload) =>
  action(DELETE_COMMENT_TAG, payload);

export const deleteCommentTagSuccessAction = (payload: IPostCommentTagPayload) =>
  action(DELETE_COMMENT_TAG_SUCCESS, payload);

export const deleteCommentTagFailureAction = (payload: IPostCommentTagPayload) =>
  action(DELETE_COMMENT_TAG_FAILURE, payload);
