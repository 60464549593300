import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import TopPostTagsChart from "./topPostTagsChart";
import TopPostTagsTable from "./TopPostTagsTable";

import { getTopPostTagsAction } from "@store/posts/actions";
import { getTopPostTagsSelector, getTopPostTagsSuccessfulSelector } from "@store/posts/selectors";

import s from "./TopPostTags.module.less";

import { generateChartData } from "./TopPostTags.helpers";
import { getPostsPageAllowedFilters } from "../postsTable/helpers";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";

import { ITopPostTagsProps } from "./TopPostTags.types";
import { InformationIcon, Loading, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { TopPostTagsEmpty } from "./TopPostTagsEmpty";
import { getPostTagsSuccessfulSelector, postTagsSelector } from "@store/postTags/selectors";
import { getPostTagsAction } from "@store/postTags/actions";

export const TopPostTags: FC<ITopPostTagsProps> = ({ filters, className }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultFilters = useGetDefaultFiltersConfig(t);

  const topPostTags = useSelector(getTopPostTagsSelector);
  const topPostTagsFetched = useSelector(getTopPostTagsSuccessfulSelector);
  const postTags = useSelector(postTagsSelector);
  const postTagsFetched = useSelector(getPostTagsSuccessfulSelector);

  useEffect(() => {
    if (!postTagsFetched) {
      dispatch(getPostTagsAction());
    }
  }, [postTagsFetched, dispatch]);

  useEffect(() => {
    if (filters) {
      dispatch(
        getTopPostTagsAction({ filters: getPostsPageAllowedFilters(filters, defaultFilters) }),
      );
    }
  }, [filters, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const chartData = useMemo(() => {
    return topPostTags ? generateChartData(topPostTags) : [];
  }, [topPostTags]);

  const heading = (
    <>
      <h2 className={s.bbTopPostTagsTitle}>
        {t("components:topPostTags:title")}
        <Tooltip title={t("components:topPostTags:tooltip")}>
          <i className={s.bbInformationIcon}>
            <InformationIcon />
          </i>
        </Tooltip>
      </h2>
      <p className={s.bbTopPostTagsSubtitle}>{t("components:topPostTags:subtitle")}</p>
    </>
  );

  if (!postTagsFetched || !topPostTagsFetched) {
    return (
      <section className={classNames(s.bbTopPostTags, className)}>
        {heading} <Loading />
      </section>
    );
  }

  const showEmptyNoTags = postTagsFetched && !postTags.length;
  const showEmptyNoData = topPostTagsFetched && !chartData.length;
  const showChart = !showEmptyNoTags && !showEmptyNoData;

  return (
    <section className={classNames(s.bbTopPostTags, className)}>
      {showChart ? (
        <>
          {heading}
          <div className={s.bbTopPostTagsPanelContainer}>
            <div className={classNames(s.bbTopPostTagsPanel, s.bbTopPostTagsPanelScrollView)}>
              <TopPostTagsChart data={chartData} />
            </div>
            <div className={classNames(s.bbTopPostTagsPanel, s.bbTopPostTagsPanelMetrics)}>
              <TopPostTagsTable data={topPostTags || []} />
            </div>
          </div>
        </>
      ) : showEmptyNoTags || showEmptyNoData ? (
        <div className={s.bbTopPostTagsPanel}>
          <div className={s.bbTopPostTagsPanelEmptyState}>
            {heading}
            {showEmptyNoTags && <TopPostTagsEmpty variant="noTags" />}
            {showEmptyNoData && <TopPostTagsEmpty variant="noData" />}
          </div>
        </div>
      ) : null}
    </section>
  );
};
