import React from "react";

export const formatWorkflow = (phrase: string, workflowCategory: string) => {
  const regex = /{{(.*?)}}/g;
  const parts = [];
  let lastIndex = 0;

  let match;

  while ((match = regex.exec(phrase)) !== null) {
    if (match.index > lastIndex) {
      parts.push(phrase.slice(lastIndex, match.index));
    }
    parts.push(
      <a
        key={match.index}
        target="_blank"
        rel="noreferrer"
        href={`/control_panel/?section=control_panel-workflows&category=${workflowCategory}`}
      >
        {match[1]}
      </a>,
    );
    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < phrase.length) {
    parts.push(phrase.slice(lastIndex));
  }

  return <>{parts}</>;
};
