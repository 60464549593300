import React, { forwardRef, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { Input, ModalFooter } from "@bbdevcrew/bb_ui_kit_fe";

import {
  USER_TAG_INPUT_ARROW_HEIGHT,
  USER_TAG_INPUT_WIDTH,
  validateUsernameChange,
} from "./UserTagInput.helpers";
import { useTagsContext } from "../../TagsModal.helpers";

import s from "./UserTagInput.module.less";

import { IUserTagInputProps } from "./UserTagInput.types";

export const UserTagInput = forwardRef<HTMLDivElement, IUserTagInputProps>(
  ({ x, y, onAdd, onCancel, ...props }, ref) => {
    const [username, setUsername] = useState("");
    const { offset } = useTagsContext();

    const { t } = useTranslation();

    const [left, top] = useMemo(
      () =>
        offset
          ? [
              Math.max(offset.width * x + offset.left - USER_TAG_INPUT_WIDTH / 2, 0),
              offset.height * y + offset.top + USER_TAG_INPUT_ARROW_HEIGHT,
            ]
          : [0, 0],
      [offset, x, y],
    );

    return createPortal(
      <div
        ref={ref}
        className={s.bbUserTagInput}
        style={{
          left: `${left}px`,
          top: `${top}px`,
        }}
        {...props}
      >
        <Input
          ref={el => el?.focus()}
          prefixEl={<span className={s.bbUserTagInputInputPrefix}>@</span>}
          wrapperClassName={s.bbUserTagInputInput}
          value={username}
          placeholder={t("generic:username")}
          onChange={event => {
            const value = event.target.value;
            if (validateUsernameChange(value)) {
              setUsername(value);
            }
          }}
        />
        <ModalFooter
          onOk={() => {
            if (!!username.length)
              onAdd({
                username,
                x,
                y,
              });
          }}
          confirmLabel={t("generic:add")}
          onCancel={onCancel}
          okButtonProps={{ disabled: !username }}
        />
      </div>,
      document.body,
    );
  },
);

UserTagInput.displayName = "UserTagInput";
