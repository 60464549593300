import { useSelector } from "react-redux";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Toggle } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./CareReviewTag.module.less";

import { IDynamicFilter, IInjectedCustomProps } from "@bbdevcrew/bb_ui_kit_fe";

// eslint-disable-next-line
interface ICareReviewTagProps extends IDynamicFilter<any>, IInjectedCustomProps {
  onCareReviewTagChange: (value: boolean) => void;
  onChange: (value?: string[]) => void;
  value?: string[];
}

function CareReviewTag(props: ICareReviewTagProps) {
  const { t } = useTranslation();
  const me = useSelector(meSelector);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(
      event.target.checked
        ? me?.client_data.care_review_tag_options.map(({ key }) => key) || []
        : undefined,
    );
    props.onCareReviewTagChange(event.target.checked);
  };

  return (
    <div className={s.bbCareReviewTag} data-cy="care-review-tags">
      <span className={s.bbCareReviewTagText}>
        {t("components:notificationsPanel:modals:create:careReviewTags")}
      </span>
      <Toggle id="care_review_tags" onChange={onChange} checked={!!props.value?.length} />
    </div>
  );
}

export { CareReviewTag };
