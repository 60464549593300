import { useSelector } from "react-redux";
import React, { useMemo } from "react";

import { Grid } from "antd";
import UpgradePlanButton from "./UpgradePlanButton";
import TrialTimeLeftCircle from "./TrialTimeLeftCircle";
import { LimitsCounter } from "../LimitsCounter/LimitsCounter";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";

import { meSelector } from "@store/me/selectors";

import s from "./AppLayoutContainer.module.less";

import { isTiktokApp } from "@utils/isTiktokApp";

interface ITrialSubscriptionProps {
  showSubscriptionModal: boolean;
  setShowSubscriptionModal: (value: boolean) => void;
}

const TrialSubscription: React.FC<ITrialSubscriptionProps> = ({
  showSubscriptionModal,
  setShowSubscriptionModal,
}) => {
  const screens = Grid.useBreakpoint();

  const me = useSelector(meSelector);

  const commentVolumeLimit = useMemo(
    () => me?.client?.subscription?.limits?.comment_volume_limit ?? -1,
    [me?.client?.subscription?.limits?.comment_volume_limit],
  );

  const commentVolumeUsed = useMemo(
    () => me?.client?.subscription?.limits?.comment_volume_used ?? -1,
    [me?.client?.subscription?.limits?.comment_volume_used],
  );

  const commentVolumeStatus = useMemo(
    () => me?.client?.subscription?.limits?.comment_volume_status,
    [me?.client?.subscription?.limits?.comment_volume_status],
  );

  const showLimitsCounter = useMemo(
    () =>
      isTiktokApp() && commentVolumeLimit >= 0 && commentVolumeUsed >= 0 && !!commentVolumeStatus,
    [commentVolumeLimit, commentVolumeUsed, commentVolumeStatus],
  );

  const onSubscriptionModalOpen = () => {
    setShowSubscriptionModal(true);
  };

  return (
    <>
      {!screens.lg ? (
        <div className={s.bbTrialSubscription}>
          <UpgradePlanButton
            data-stonly-target="app-global__header--upgrade-plan"
            onClick={onSubscriptionModalOpen}
          />
        </div>
      ) : (
        <>
          <div className={s.bbTrialSubscription}>
            <div className={s.bbTrialCounters}>
              {showLimitsCounter && commentVolumeStatus ? (
                <LimitsCounter
                  limit={commentVolumeLimit || 0}
                  used={commentVolumeUsed || 0}
                  status={commentVolumeStatus}
                />
              ) : (
                <TrialTimeLeftCircle
                  withTooltip
                  trialPeriodEnd={me?.client?.subscription?.period_end || ""}
                  stonlyTarget="app-global__header--trial-days"
                />
              )}
            </div>
            <UpgradePlanButton
              data-stonly-target="app-global__header--upgrade-plan"
              onClick={onSubscriptionModalOpen}
            />
          </div>
          <SubscriptionModal
            isVisible={showSubscriptionModal}
            onClose={() => setShowSubscriptionModal(false)}
          />
        </>
      )}
    </>
  );
};

export default TrialSubscription;
