import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import TTVideoPreview from "./VideoPreview";
import { Help } from "@bbdevcrew/bb_ui_kit_fe";

import { ITTPostPreviewProps } from "./types";

import s from "./TTPostPreview.module.less";
import postStyles from "../index.module.less";

const TTPostPreview: FC<ITTPostPreviewProps> = ({ post, videoSrc, creatorInfo }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={postStyles.bbPostCreationPreviewTitle}>
        {t("components:publish:postCreationModal:preview:title", {
          platform: t("generic:platforms:tiktok"),
        })}
        <Help
          iconType="info"
          iconClassName={postStyles.bbPostPreviewInfo}
          tooltipProps={{
            title: t("components:publish:postCreationModal:preview:tooltip"),
          }}
        />
      </div>
      <div className={s.bbTTPostPreview}>
        <div className={s.bbImagePreviewWrapper}>
          <TTVideoPreview
            videoSrc={videoSrc}
            caption={post.message}
            pageAvatar={creatorInfo?.avatar_url || post.asset.picture}
            pageName={creatorInfo?.nickname || post.asset.label}
          />
        </div>
      </div>
    </>
  );
};

export default TTPostPreview;
