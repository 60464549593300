import * as actions from "./actions";
import { ActionType } from "typesafe-actions";
import { IHistogramItem, IKpiData } from "../kpiData/types";
import { IBreakdownUnit, IBreakdownUnitItem } from "../aggregations/types";
import { IUserLanguagesItem, IWordCloudItems, ITopInfluencersItem } from "../overview/types";
import { IFilters, RecommendationType } from "@bbdevcrew/bb_ui_kit_fe";
import { ITimelineHistogram } from "@store/search/types";
import { DashboardComponentType } from "@store/modules/types";

export type Actions = ActionType<typeof actions>;

export type IOverviewMessageTypeDistribution = {
  total: number;
  categoriesDistribution: {
    category: {
      id: string;
      label: string;
    };
    count: number;
    percentage: number;
    percentageChange: number;
  }[];
};

export interface IOverviewMessageTypeDetailItem {
  id: string;
  category: {
    id: string;
    label: string;
  };
  messageType: {
    id: string;
    label: string;
  };
  volume: number;
  percentage: number;
  percentageChange: number;
}

export interface IOverviewPerformanceItem {
  id: string;
  name: string;
  count: number;
  change_percentage: number;
  percentage_of_total: number;
}

export interface IOverviewPerformanceChartItem
  extends Omit<IOverviewPerformanceTag, "name" | "percentage_of_total"> {
  name: string | JSX.Element;
  color?: string;
  percentage_of_total?: number;
}

export interface IOverviewPerformanceTag {
  id: string;
  name: string;
  count: number;
  count_pretty?: string;
  change_percentage: number;
  percentage_of_total: number;
  category: string;
  category_name: string;
}

export interface ISafetyOverview {
  safety_hidden_stats: {
    items: IOverviewPerformanceItem[];
  };
  harmful_stats: {
    items: IOverviewPerformanceItem[];
  };
  tags_hidden_stats: {
    items: IOverviewPerformanceTag[];
  };
}

export interface ICareOverview {
  care_replied_stats: {
    items: IOverviewPerformanceItem[];
  };
  care_bb_replied_stats: {
    items: IOverviewPerformanceItem[];
  };
  care_platform_replied_stats: {
    items: IOverviewPerformanceItem[];
  };

  tags_replied_stats: {
    items: IOverviewPerformanceItem[];
  };
  tags_bb_replied_stats: {
    items: IOverviewPerformanceItem[];
  };
  tags_platform_replied_stats: {
    items: IOverviewPerformanceItem[];
  };

  engagement_tags_stats: {
    items: IOverviewPerformanceTag[];
  };
  engagement_total_stats: {
    items: IOverviewPerformanceTag[];
  };
}

export interface IAgentPerformanceUserStat {
  id: string;
  name: string;
  avatar?: string;
  count: number;
  count_change_percentage: number;
  duration: string;
  duration_change_percentage: number;
  count_percentage_of_total: number;
}

export interface IAgentPerformanceResponseStat {
  id: string;
  name: string;
  count: number;
  pretty_count?: string;
  change_percentage: number;
}

export type AgentPerformanceReportType = "summary" | "detailed";

export interface IExplorerAgentPerformance {
  user_stats: {
    items: IAgentPerformanceUserStat[];
  };
  stats: {
    items: IAgentPerformanceResponseStat[];
  };
}

interface IReviewWidgetPercentageChange {
  value: number;
  display: "POSITIVE" | "NEGATIVE";
}

interface IReviewWidgetVolume {
  value: number;
  percentageChange?: IReviewWidgetPercentageChange;
}

export interface IReviewWidgetDistribution {
  type: RecommendationType;
  label: string;
  reviewCount: number;
  percentage: number;
  percentageChange?: IReviewWidgetPercentageChange;
}

export interface IPlatformDistribution {
  type: RecommendationType;
  percentage: number;
}

interface IReviewWidgetPlatformComparison {
  platform: {
    type: string;
    label: string;
  };
  reviewCount: number;
  percentage?: number;
  percentageChange?: IReviewWidgetPercentageChange;
  distribution: IPlatformDistribution[];
}

export interface IOverviewReviewWidget {
  volume: IReviewWidgetVolume;
  ratingDistribution: IReviewWidgetDistribution[];
  platformComparison: IReviewWidgetPlatformComparison[];
}

export interface ICommentBreakdownUnit extends Omit<IBreakdownUnit, "items"> {
  items: ICommentBreakDownItem[];
}

export interface IHiddenBreakdownUnit extends Omit<IBreakdownUnit, "items"> {
  items: IHiddenBreakDownItem[];
}

export interface IExplorerOverviewSentiments {
  id: string;
  name: string;
  count: number;
  change_percentage: number;
  percentage_of_total: number;
  histogram: {
    items: IHistogramItem[];
    reference_line_x: string;
  };
}

export interface ICommentBreakDownItem extends Omit<IBreakdownUnitItem, "comment_stats"> {
  comment_stats: {
    brand_safety: number;
    brand_suitability: number;
  };
}

export interface IHiddenBreakDownItem extends Omit<IBreakdownUnitItem, "comment_stats"> {
  comment_stats: {
    hidden: number;
  };
}

export interface IActionSummaryStat {
  id:
    | "tags"
    | "hidden_conversations"
    | "total_conversations"
    | "replies"
    | "archived_automatically"
    | "other_workflows"
    | "alerts";
  title: string;
  description: string;
  count: number;
  percentageChange?: IReviewWidgetPercentageChange;
  disabledReason?: string;
  displayText?: string;
  tooltip?: string;
}

export type SentimentType = "positive" | "negative" | "user_tags" | "neutral" | "not_applicable";

export interface TopTagSentimentItem {
  id: SentimentType;
  label: string;
  count: number;
}

export interface ITopTag {
  id: string;
  label: string;
  total: number;
  sentiment_breakdown: TopTagSentimentItem[];
}

export interface ISentimentExtended extends IKpiData {
  breakdowns: IBreakdownUnit[];
  date_histogram: ITimelineHistogram;
}

export interface ISentimentTimeline extends IKpiData {
  date_histogram: ITimelineHistogram;
}

export interface ISentimentBreakdown {
  items: IBreakdownUnit[];
}

export interface IOverviewMessageTypes {
  messageTypeDetail: IOverviewMessageTypeDetailItem[];
  messageTypeDistribution: IOverviewMessageTypeDistribution;
}

export interface IDashboard {
  explorer_overview_safety?: ISafetyOverview;
  explorer_overview_care?: ICareOverview;
  explorer_agent_performance?: IExplorerAgentPerformance;
  review_widgets?: IOverviewReviewWidget;
  hidden_comments?: ISafetyOverview;
  action_summary_stats?: IActionSummaryStat[];
  explorer_overview_sentiment?: {
    items: IExplorerOverviewSentiments[];
  };
  explorer_overview_platform?: {
    items: IOverviewPerformanceItem[];
  };
  explorer_overview_message_types?: IOverviewMessageTypes;
  safety_breakdown?: {
    items: ICommentBreakdownUnit[];
  };
  hidden_breakdown?: {
    items: IHiddenBreakdownUnit[];
  };
  hidden_timeline?: {
    date_histogram?: IDateHistogram;
  };
  explorer_overview_conversations?: {
    items: IOverviewPerformanceItem[];
  };
  explorer_overview_top_emojis?: {
    items: IWordCloudItems[];
  };
  explorer_overview_top_languages?: {
    items: IUserLanguagesItem[];
  };
  explorer_overview_top_influencers?: {
    items: ITopInfluencersItem[];
  };
  explorer_overview_top_keywords?: {
    items: IWordCloudItems[];
  };
  explorer_overview_top_keyword_phrases?: {
    items: ITopInfluencersItem[];
  };
  top_tags?: {
    id: string;
    items: ITopTag[];
  };
  sentiment_extended?: ISentimentExtended;
  sentiment_timeline?: ISentimentTimeline;
  sentiment_breakdown?: ISentimentBreakdown;
}

export type SentimentBreakdownSubComponentType =
  | "platform_type"
  | "language"
  | "post_type"
  | "custom_tags"
  | "asset_name"
  | "campaign_name"
  | "adset_name"
  | "ad_name"
  | "ad_targeting"
  | "adbastion_tags"
  | "post_id";

export interface IDashboardPayload {
  filters?: IFilters;
  components: DashboardComponentType[];
  options?: {
    sentiment_breakdown_sub_components?: SentimentBreakdownSubComponentType[];
    action_summmary_stats_extra_elements?: boolean;
  };
}

export interface IDashboardResponse {
  data: IDashboard;
}

export interface IDataSet {
  date: string;
  total: number;
  hidden: number;
  end_date: string;
  sensitive: number;
  non_harmful: number;
  universally_harmful: number;
}

export interface IDateHistogram {
  datasets: IDataSet[];
}

export enum ProfileDataStatusEnum {
  ACTIVE = "active",
  MISSING_PERMISSION = "missing_permissions",
  NO_DATA = "no_data",
  NOT_AVAILABLE = "not_available",
}

export interface IDashboardFollowersPlatform {
  id: string;
  name: string;
  followers_count: number;
  percentage: string | number;
  growth?: string | number | null;
}

export interface IDashboardFollowersRow extends Omit<IDashboardFollowersPlatform, "name"> {
  asset_name: string;
  platform: string | null;
  status: ProfileDataStatusEnum;
}

export interface IDashboardFollowers {
  items: IDashboardFollowersRow[];
  platforms: IDashboardFollowersPlatform[];
}

export interface IDashboardImpressionsPlatform {
  id: string;
  name: string;
  total_impressions: number | null;
  percentage: string | number;
  paid_impressions: number;
  organic_impressions: number;
  total_impression_growth: number | null;
  paid_impression_growth: number | null;
  organic_impression_growth: number | null;
  status: ProfileDataStatusEnum;
}

export interface IDashboardImpressionsRow {
  platform: string;
  total_impressions: number;
  growth?: string | number | null;
  asset_name: string;
  id: string;
  status: ProfileDataStatusEnum;
}

export interface IDashboardImpressions {
  items: IDashboardImpressionsRow[];
  platforms: IDashboardImpressionsPlatform[];
}

export interface IDashboardEngagementsRow {
  platform: string;
  er_impressions: number | null;
  er_impression_growth?: number | null;
  engagements: number;
  engagements_growth?: number | null;
  asset_name: string;
  id: string;
  status: ProfileDataStatusEnum;
}

export interface IDashboardEngagementsPlatform {
  id: string;
  engagements: number;
  engagements_growth?: number | null;
  percentage: string | number;
  name: string;
}

export interface IDashboardEngagements {
  items: IDashboardEngagementsRow[];
  platforms: IDashboardEngagementsPlatform[];
}

export interface IDashboardState {
  dashboard?: IDashboard;
  fetchingDashboard: boolean;
  fetchedDashboard: boolean;
  fetchedDashboardFail: boolean;

  followers?: IDashboardFollowers;
  fetchingFollowers: boolean;
  fetchedFollowers: boolean;
  fetchedFollowersFail: boolean;

  impressions?: IDashboardImpressions;
  fetchingImpressions: boolean;
  fetchedImpressions: boolean;
  fetchedImpressionsFail: boolean;

  engagements?: IDashboardEngagements;
  fetchingEngagements: boolean;
  fetchedEngagements: boolean;
  fetchedEngagementsFail: boolean;
}
