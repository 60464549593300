import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { mergeMap, map, filter, catchError, switchMap } from "rxjs/operators";

import { RootState } from "..";
import { AutoCompleteActionsType } from "./types";
import {
  getAutocompleteOptionsAction,
  updateAutocompleteOptionsAction,
  getAutocompleteOptionsFailureAction,
  getAutocompleteOptionsSearchAction,
  getAutocompleteOptionsSuccessAction,
} from "./actions";

import {
  handleError,
  IAutocompleteOption,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { getHeaders } from "@utils/headers";
import { autocomplete } from "@utils/paths";

const fetchAutocomplete = (
  payload: IGetAutocompleteOptionsPayload,
  state$: StateObservable<RootState>,
) => {
  return ajax
    .post<{ items: IAutocompleteOption[] }>(
      `${autocomplete}/searches`,
      payload,
      getHeaders({
        Authorization: state$.value.auth.session.accessToken.jwtToken,
      }),
    )
    .pipe(
      map(e => e.response),
      map(data => getAutocompleteOptionsSuccessAction(data.items, payload.field)),
      catchError(error => handleError(error, getAutocompleteOptionsFailureAction)),
    );
};

export const getAutocomleteOptions = (
  action$: Observable<AutoCompleteActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isActionOf(getAutocompleteOptionsAction)),
    mergeMap(a => {
      return fetchAutocomplete(a.payload, state$);
    }),
  );
};

export const getAutocomleteOptionsSearch = (
  action$: Observable<AutoCompleteActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isActionOf(getAutocompleteOptionsSearchAction)),
    switchMap(a => {
      return fetchAutocomplete(a.payload, state$);
    }),
  );
};

export const updateAutocompleteOptions = (
  action$: Observable<AutoCompleteActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(updateAutocompleteOptionsAction)),
    switchMap(a => {
      return fetchAutocomplete(a.payload, state$);
    }),
  );
