import { createReducer } from "typesafe-actions";
import * as actions from "./actions";

import { AlertsActionsType, IAlertsState } from "./types";

const initialState: IAlertsState = {
  alerts: [],
  fetchingAlerts: false,
  fetchedAlerts: false,
  fetchAlertsFailed: false,

  updatingAlert: false,
  updateAlertFailed: false,
  updateAlertSuccess: false,

  updatingBulkAlerts: false,
  updateBulkAlertsFailed: false,
  updateBulkAlertsSuccess: false,

  total: 0,
};

export const alertsReducer = createReducer<IAlertsState, AlertsActionsType>(initialState)
  .handleAction(actions.fetchAlerts, state => ({
    ...state,
    fetchingAlerts: true,
    fetchedAlerts: false,
    fetchAlertsFailed: false,
    updateAlertSuccess: false,
    updateAlertFailed: false,
    updateBulkAlertsSuccess: false,
    updateBulkAlertsFailed: false,
  }))
  .handleAction(actions.fetchAlertsSuccess, (state, action) => ({
    ...state,
    alerts: action.payload.items,
    total: action.payload.pagination.total,
    fetchingAlerts: false,
    fetchedAlerts: true,
  }))
  .handleAction(actions.fetchAlertsFail, state => ({
    ...state,
    fetchingAlerts: false,
    fetchAlertsFailed: true,
  }))

  .handleAction(actions.updateAlert, (state, { payload }) => ({
    ...state,
    previousAlerts: state.alerts,
    alerts: state.alerts.map(alert =>
      alert.id === payload.alertId ? { ...alert, ...payload.updates } : alert,
    ),
    updatingAlert: true,
    updateAlertFailed: false,
    updateAlertSuccess: false,
  }))
  .handleAction(actions.updateAlertSuccess, state => ({
    ...state,
    updateAlertSuccess: true,
    updatingAlert: false,
  }))
  .handleAction(actions.updateAlertFail, state => ({
    ...state,
    alerts: state.previousAlerts || [],
    previousAlerts: undefined,
    updatingAlert: false,
    updateAlertFailed: true,
  }))

  .handleAction(actions.updateBulkAlerts, state => ({
    ...state,
    updatingBulkAlerts: true,
    updateBulkAlertsFailed: false,
    updateBulkAlertsSuccess: false,
  }))
  .handleAction(actions.updateBulkAlertsSuccess, (state, action) => ({
    ...state,
    alerts: state.alerts.map(alert => ({ ...alert, status: action.payload })),
    updateBulkAlertsSuccess: true,
    updatingBulkAlerts: false,
  }))
  .handleAction(actions.updateBulkAlertsFail, state => ({
    ...state,
    updatingBulkAlerts: false,
    updateBulkAlertsFailed: true,
  }));
