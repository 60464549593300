import React, { FC } from "react";
import { Chip, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { useTranslation } from "react-i18next";

import s from "./TopPostTags.module.less";

import { cropText, getTagStyles } from "@bbdevcrew/bb_ui_kit_fe";
import { POST_TAG_MAX_LENGTH } from "@components/_common/Social/Post/PostTags/PostTagsList";
import { ITopPostTagsItem } from "@store/posts/types";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";
import nl2br from "@utils/nl2br";
import formatPercentage from "@utils/formatPercentage";

interface ITopPostTagsTableProps {
  data: ITopPostTagsItem[];
}

const TopPostTagsTable: FC<ITopPostTagsTableProps> = ({ data }) => {
  const { t } = useTranslation();

  const tableData = data.map(item => {
    const avgComments = item.breakdown.find(b => b.id === "comment_metrics_total_avg")?.count || 0;
    const avgNss = item.breakdown.find(b => b.id === "nss_score_avg")?.count || 0;
    const avgEr = item.breakdown.find(b => b.id === "engagement_rate_avg")?.count || 0;

    return {
      id: item.id,
      tag: item.label,
      color: item.color,
      avgComments,
      avgNss,
      avgEr,
    };
  });

  return (
    <div className={s.bbTopPostTagsTableContainer}>
      <div className={s.bbTopPostTagsInfo}>
        <div className={s.bbTopPostTagsTableTitle}>
          {t("components:topPostTags:title")}
          <Tooltip title={t("components:topPostTags:tooltip")}>
            <i className={s.bbInformationIcon}>
              <InformationIcon />
            </i>
          </Tooltip>
        </div>
      </div>

      {tableData.length > 0 && (
        <div className={s.bbTopPostTagsTableScrollView}>
          <table className={s.bbTopPostTagsTableNative}>
            <thead>
              <tr>
                <th>
                  <div className={s.bbTopPostTagsHeader}>{t("components:topPostTags:postTag")}</div>
                </th>
                <th>
                  <div className={s.bbTopPostTagsHeader}>
                    {t("components:topPostTags:avgComments")}
                  </div>
                </th>
                <th>
                  <div className={s.bbTopPostTagsHeader}>
                    {t("components:topPostTags:avgNss")}
                    <Tooltip title={nl2br(t("components:topPostTags:avgNssTooltip"))}>
                      <i className={s.bbInformationIcon}>
                        <InformationIcon />
                      </i>
                    </Tooltip>
                  </div>
                </th>
                <th>
                  <div className={s.bbTopPostTagsHeader}>
                    {t("components:topPostTags:avgEr")}
                    <Tooltip title={nl2br(t("components:topPostTags:avgErTooltip"))}>
                      <i className={s.bbInformationIcon}>
                        <InformationIcon />
                      </i>
                    </Tooltip>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map(item => (
                <tr key={item.id} className={s.bbTopPostTagsTableRow}>
                  <td>
                    <Chip
                      _size="xxs"
                      _type={item.color ? "default" : "grey"}
                      style={item.color ? getTagStyles(item.color) : {}}
                      text={cropText(item.tag, POST_TAG_MAX_LENGTH)}
                      className={s.bbTopPostTagsTableChip}
                    />
                  </td>
                  <td>{item.avgComments.toLocaleString()}</td>
                  <td>{formatPercentage(item.avgNss)}</td>
                  <td>{formatPercentage(item.avgEr)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TopPostTagsTable;
