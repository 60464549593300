import React, { FC } from "react";

import { ListItem } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CompareHeader.module.less";

// eslint-disable-next-line max-len
import { IListItemProps } from "@bbdevcrew/bb_ui_kit_fe/dist/components/generic/ListItem/ListItem.types";
import { IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { ICompareProject } from "@store/compareProjects/types";

import { EditIcon, RemoveIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ProjectListItemProps {
  onEditProjectClick: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    project: ICompareProject,
  ) => void;
  onRemoveCompareProjectClick: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    project: ICompareProject,
  ) => void;
}

const ProjectListItem = ({
  onEditProjectClick,
  onRemoveCompareProjectClick,
}: ProjectListItemProps) => {
  const ProjectListItemComponent: FC<IListItemProps & { option: string | IObjectOption }> = ({
    option,
    ...props
  }) => {
    if (typeof option === "string") return null;

    const project = option as unknown as ICompareProject;

    return (
      <ListItem
        key={option.id}
        {...props}
        value={option.id as string}
        data-cy="compare-panel-projet-option"
        className={s.bbProjectsSelectOption}
        rightLabel={
          <div>
            <span onClick={event => onEditProjectClick(event, project)}>
              <EditIcon />
            </span>
            <span onClick={event => onRemoveCompareProjectClick(event, project)}>
              <RemoveIcon />
            </span>
          </div>
        }
      >
        {option.name}
      </ListItem>
    );
  };

  ProjectListItemComponent.displayName = "ProjectListItem";

  return ProjectListItemComponent;
};

export default ProjectListItem;
