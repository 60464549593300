import cn from "classnames/bind";
import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Tooltip, SelectableTag } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AutomatedHidingMenu.module.less";

import { IHidingConfigItem } from "@store/hidingConfig/types";
import { getPlatformIconBasicColor, platformIconName } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

interface IAutomatedHidingPlatformsProps {
  disabled: boolean;
  selectedPlatforms: string[];
  selectedClassifiers: string[];
  selectedKeywords: string[];
  platforms: IHidingConfigItem[];
  onPlatformClick: (platform: string) => void;
}

export const AutomatedHidingPlatforms: FC<IAutomatedHidingPlatformsProps> = ({
  disabled,
  platforms,
  selectedKeywords,
  selectedPlatforms,
  selectedClassifiers,
  onPlatformClick,
}) => {
  const { t } = useTranslation();

  const platformsDisabled = (!selectedClassifiers.length && !selectedKeywords.length) || disabled;

  return (
    <div>
      <div className={s.bbPlatformsDisclaimer}>
        {t("components:automatedHiding:platformsDisclaimer")}
      </div>
      <div className={s.bbAutomatedHidingMenuPlatforms}>
        {platforms?.map(({ id, tooltip }, key) => {
          const isChecked = selectedPlatforms.includes(id);

          return (
            <Tooltip title={tooltip} key={id + key}>
              <div
                key={id + key}
                className={cx(s.bbPlatformWrapper, {
                  [s.bbPlatformWrapperDisabledTooltip]: platformsDisabled && tooltip,
                })}
              >
                <SelectableTag
                  id={id}
                  checked={isChecked}
                  defaultSelected={isChecked}
                  disabled={platformsDisabled}
                  onChange={() => onPlatformClick(id)}
                  data-cy={`automated-hiding-platforms-${id}`}
                  className={classNames(s.bbPlatformIcon, {
                    [s.bbPlatformIconDisabled]: disabled,
                    [s.bbPlatformIconYoutube]: id === "youtube",
                  })}
                >
                  {getPlatformIconBasicColor(platformIconName(id))}
                </SelectableTag>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

export default AutomatedHidingPlatforms;
