import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Form, FormInstance } from "antd";
import { KeywordsFilter, KeywordsDropdownBasic } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AutomatedHidingMenu.module.less";

interface IAutomatedHidingKeywordProps {
  form: FormInstance;
  keywords: string[];
  isDisabled: boolean;
  setKeywords: (keywords: string[]) => void;
}

const AutomatedHidingKeyword: FC<IAutomatedHidingKeywordProps> = ({
  form,
  keywords,
  setKeywords,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      size="small"
      colon={false}
      form={form}
      initialValues={{ keyword_blocker: keywords }}
      className={s.bbAutomatedHidingKeywords}
    >
      <Form.Item shouldUpdate>
        {formIns => {
          setKeywords(formIns.getFieldValue("keyword_blocker"));
          return (
            <KeywordsFilter
              id="keyword_blocker"
              menuClassName={s.bbAutomatedHidingKeywordsMenu}
              disabled={isDisabled}
              showTranslationSwitch={true}
              DropdownVariation={KeywordsDropdownBasic}
              maxChipCount={2}
              placeholder={t("components:automatedHiding:keywordPlaceholder")}
              customprops={{
                name: "keyword_blocker",
                form,
              }}
            />
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default AutomatedHidingKeyword;
