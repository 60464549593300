import React from "react";
import { useTranslation } from "react-i18next";

import { Accordion } from "@bbdevcrew/bb_ui_kit_fe";
import { ExpansionPanelBadge } from "./ExpansionPanelBadge";

import s from "./ExpansionPanel.module.less";

import { IExpansionPanelProps } from "./ExpansionPanel.types";

export const ExpansionPanel: React.FC<IExpansionPanelProps> = ({
  title,
  step,
  optional,
  children,
  open,
  onToggle,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <Accordion
      title={
        <div className={s.bbExpansionPanelTitle}>
          {step && (
            <ExpansionPanelBadge>
              {t("pages:workflows:form:triggers:reply:step", { count: step })}
            </ExpansionPanelBadge>
          )}
          {title}
          {optional && (
            <span className={s.bbExpansionPanelOptional}>{`(${t("generic:optional")})`}</span>
          )}
        </div>
      }
      type="white"
      body={<div className={s.bbExpansionPanelContent}>{children}</div>}
      collapsed={!open}
      setCollapsed={() => onToggle?.()}
      className={s.bbExpansionPanel}
      {...restProps}
    />
  );
};
