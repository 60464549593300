import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";

import { Empty } from "antd";
import { Card, Loading, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";
import UserLanguagesChart from "./userLanguagesChart/UserLanguagesChart";

import s from "./UserLanguages.module.less";

import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";
import { IUserLanguagesItem } from "@store/overview/types";
import { allColorData, positiveColorData, negativeColorData } from "./UserLanguagesConstants";

interface IUserLanguagesProps {
  loading: boolean;
  data: IUserLanguagesItem[];
  filterData: IFilters;
  filterToSpecificItem?: (item: IFilters) => void;
}

const UserLanguages: FC<IUserLanguagesProps> = ({
  filterData,
  data,
  loading,
  filterToSpecificItem,
}) => {
  const { t } = useTranslation();

  const [selectedTabKey, setSelectedTabKey] = useState("all");

  const onChangeTab = (activeKey: string) => {
    setSelectedTabKey(activeKey);
  };

  const getBarChartData = () => {
    if (data?.length) {
      const selectedTabDataIndex = data.findIndex(item => item.sentiment.id === selectedTabKey);

      return data[selectedTabDataIndex].sentiment.items;
    }
    return [];
  };

  const getColorDataBySentiment = () => {
    switch (selectedTabKey) {
      case "all":
        return allColorData;
      case "positive":
        return positiveColorData;
      case "negative":
        return negativeColorData;
      default:
        return allColorData;
    }
  };

  const generateTab = () => {
    return (
      <ReactResizeDetector
        handleWidth
        refreshMode={"throttle"}
        refreshOptions={{ leading: true, trailing: true }}
      >
        {(props: { width: number }) => (
          <UserLanguagesChart
            data={getBarChartData()}
            chartWidth={props.width}
            filterData={filterData}
            colorData={getColorDataBySentiment()}
            filterToSpecificItem={filterToSpecificItem}
          />
        )}
      </ReactResizeDetector>
    );
  };

  return (
    <div
      className={s.bbUserLanguages}
      data-cy="user-languages-container"
      data-stonly-target="intelligence-overview__user-languages"
    >
      <Card
        headerTitle={t("components:topLanguages:headerTitle")}
        headerSubTitle={t("components:topLanguages:headerSubTitle")}
      >
        {loading ? (
          <div className={s.bbUserLanguagesTrendsLoading}>
            <Loading isLoading />
          </div>
        ) : data?.length ? (
          <>
            <TabsScrollable
              centered
              noUnderline
              defaultActiveKey="all"
              activeKey={selectedTabKey}
              onChange={onChangeTab}
            >
              <TabPane tab={t(`components:topLanguages:tabs:all`)} tabKey="all">
                {generateTab()}
              </TabPane>
              <TabPane tab={t(`components:topLanguages:tabs:positive`)} tabKey="positive">
                {generateTab()}
              </TabPane>
              <TabPane tab={t(`components:topLanguages:tabs:negative`)} tabKey="negative">
                {generateTab()}
              </TabPane>
            </TabsScrollable>
          </>
        ) : (
          <Empty
            className={s.bbUserLanguagesEmpty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:empty:description")}
          />
        )}
      </Card>
    </div>
  );
};

export default UserLanguages;
