import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button, ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AccountsTrigger.module.less";

import { PlusIcon, EditIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IAccountsTriggerProps {
  hasAssetsSelected: boolean;
}

export function AccountsTrigger({ hasAssetsSelected }: IAccountsTriggerProps) {
  const { t } = useTranslation();

  if (!hasAssetsSelected) {
    return (
      <Button _size="sm" _type="primary">
        <span
          className={classNames(
            s.bbAccountsTrigger,
            s.bbAccountsTriggerPrimary,
            s.bbAccountsTriggerIconLeft,
          )}
        >
          <PlusIcon />
        </span>
        <span className={s.bbAccountsTriggerText}>
          {t("components:publish:postCreationModal:accounts")}
        </span>
        <span
          className={classNames(
            s.bbAccountsTrigger,
            s.bbAccountsTriggerPrimary,
            s.bbAccountsTriggerIconRight,
          )}
        >
          <ChevronDownIcon />
        </span>
      </Button>
    );
  }

  return (
    <Button _size="sm" _type="secondary">
      <span
        className={classNames(
          s.bbAccountsTrigger,
          s.bbAccountsTriggerSecondary,
          s.bbAccountsTriggerIconLeft,
        )}
      >
        <EditIcon />
      </span>
      <span className={s.bbAccountsTriggerText}>
        {t("components:publish:postCreationModal:accounts")}
      </span>
      <span
        className={classNames(
          s.bbAccountsTrigger,
          s.bbAccountsTriggerSecondary,
          s.bbAccountsTriggerIconRight,
        )}
      >
        <ChevronDownIcon />
      </span>
    </Button>
  );
}
