import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Toggle,
  ChipIcon,
  EyeIcon,
  ChipType,
  ButtonPermissionGuard,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Col, Row } from "antd";
import WorkflowActions from "./item/WorkflowActions";

import {
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesExplorerLinkSelector,
} from "@store/shortenedSearches/selectors";

import { postShortenedSearchesAction } from "@store/shortenedSearches/actions";

import s from "./Workflows.module.less";

import { getWorkflowCategoryLabel, WORKFLOW_CHIP_MAP } from "./helpers";
import usePermissions from "@utils/usePermissions";
import { IWorkflowItemProps, SUPPORTED_WORKFLOW_CATEGORIES } from "./item/helpers";
import { IWorkflowTableRowChipProps } from "./types";

const WorkflowsTableRowChip: React.FC<IWorkflowTableRowChipProps> = ({ category, enabled }) => {
  const { t } = useTranslation();

  const defaultChipColors = ["success", "risk-high", "risk-moderate", "grey"];
  const specialChipColors = ["lilac", "magenta", "mint", "slate", "azure"];
  const workflowIconColor = WORKFLOW_CHIP_MAP[category]?.type || "grey";

  const workflowLabel = useMemo(() => {
    if (category === "hide" && !enabled) return t("pages:workflows:workflowLabels:hideDisabled");
    return getWorkflowCategoryLabel(category);
  }, [enabled, category, t]);

  const workflowIcon = useMemo(() => {
    if (category === "hide" && !enabled) return <EyeIcon />;
    return WORKFLOW_CHIP_MAP[category]?.icon || <></>;
  }, [enabled, category]);

  return (
    <ChipIcon
      _size="xs"
      _type={
        enabled && defaultChipColors.includes(workflowIconColor)
          ? (workflowIconColor as ChipType)
          : "grey"
      }
      text={workflowLabel}
      icon={workflowIcon}
      iconPosition="left"
      wrapperClassName={
        enabled && specialChipColors.includes(workflowIconColor)
          ? classNames(s.bbWorkflowItemChipIcon, s[`bbWorkflowItemChipIcon-${workflowIconColor}`])
          : s.bbWorkflowItemChipIcon
      }
    />
  );
};

const WorkflowsTableRow: React.FC<
  IWorkflowItemProps & {
    index: number;
  }
> = ({ item: data, index, onEditClick, onDeleteClick, onWorkflowToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = usePermissions();

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const canViewOrEdit = useMemo(
    () => SUPPORTED_WORKFLOW_CATEGORIES.includes(data.category) && data.is_editable,
    [data.category, data.is_editable],
  );

  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);
  const shortenedSearchesExplorerLink = useSelector(shortenedSearchesExplorerLinkSelector);

  const goToExplore = useCallback(
    () => dispatch(postShortenedSearchesAction({ filters: data?.trigger?.filters || {} })),
    // eslint-disable-next-line
    [dispatch],
  );

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      window.location.href = shortenedSearchesExplorerLink;
    }
  }, [postShortenedSearchesFetched, shortenedSearchesExplorerLink]);

  const closeActionsDropdown = () => setIsActionsDropdownOpen(false);

  return (
    <Row className={classNames(s.bbTableRow, index % 2 === 0 && s.bbTableRowEven)} key={index}>
      <Col span={4} className={s.bbTableRowCell}>
        {data.show_enable_checkbox && (
          <div className={s.bbWorkflowItemToggle} onClick={event => event.stopPropagation()}>
            <ButtonPermissionGuard
              hasPermission={!!permissions["manageWorkflows"]}
              message={t("permissions:editWorkflows")}
            >
              <Toggle
                id={`enabled-${data.id}`}
                checked={data.enabled}
                onChange={onWorkflowToggle}
                data-cy="workflow-toggle"
              />
            </ButtonPermissionGuard>
          </div>
        )}
      </Col>
      <Col span={10} className={s.bbTableRowCell}>
        <WorkflowsTableRowChip category={data.category} enabled={data.enabled} />
        {data.additional_actions?.map(action => (
          <React.Fragment key={action.category}>
            {action.category && (
              <>
                {" + "}
                <WorkflowsTableRowChip category={action.category} enabled={data.enabled} />
              </>
            )}
          </React.Fragment>
        ))}
      </Col>
      <Col span={10} className={s.bbTableRowCell}>
        <div className={s.bbTableRowTitle}>
          <div>
            <h4>{data.title}</h4>
            <p>{data.description}</p>
          </div>
          {canViewOrEdit && (
            <WorkflowActions
              onEditClick={() => {
                onEditClick(closeActionsDropdown);
              }}
              onDeleteClick={() => {
                onDeleteClick(closeActionsDropdown);
              }}
              onExploreClick={goToExplore}
              isActionsDropdownOpen={isActionsDropdownOpen}
              setIsActionsDropdownOpen={setIsActionsDropdownOpen}
              showExploreAction={
                data.category === "email_alert" ||
                (data.category === "hide" &&
                  !!data.additional_actions?.some(action => action.category === "email_alert"))
              }
              className={s.bbTableRowActions}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default WorkflowsTableRow;
