import {
  AdIcon,
  BrokenLinkIcon,
  OrganicIcon,
  RocketIcon,
  TiktokColorIcon,
  TwitterColorIcon,
  FacebookColorIcon,
  InstagramColorIcon,
  LinkedinColorIcon,
  TrustpilotColorIcon,
  YoutubeColorIcon,
  GoogleBusinessIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

export const PlatformTypeIcon = {
  facebook: FacebookColorIcon,
  instagram: InstagramColorIcon,
  twitter: TwitterColorIcon,
  youtube: YoutubeColorIcon,
  tiktok: TiktokColorIcon,
  linkedin: LinkedinColorIcon,
  trustpilot: TrustpilotColorIcon,
  google_business: GoogleBusinessIcon,
};

export const PostTypeIcon = {
  pure_organic: OrganicIcon,
  pure_ads: AdIcon,
  unlinked_ads: BrokenLinkIcon,
  boosted_posts: RocketIcon,
};

export const LABEL_WIDTH = 180;
export const CHART_HEIGHT_LARGE = 436;
export const CHART_HEIGHT_SMALL = 303;
export const LABEL_SMALL = 150;
export const LABEL_LARGE = 200;
