import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";

import { Col, Avatar } from "antd";
import PostsTableRowActions from "./PostsTableRowActions";
import { HelpIcon, ITableRow, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import PostModal from "../../../_common/Social/Post/PostModal";
import PostTagsList from "@components/_common/Social/Post/PostTags/PostTagsList";
import PostTagsDropdown from "@components/_common/Social/Post/PostTags/PostTagsDropdown";

import { deleteTagFromPostAction } from "@store/postTags/actions";

import s from "./PostsTable.module.less";

import {
  IPost,
  getPlatformIconBasicColor,
  isTwitterPlatform,
  isYoutubePlatform,
  PostPlacementEnum,
  extractDatePlain,
  getCountriesFlags,
} from "@bbdevcrew/bb_ui_kit_fe";
import { isMetricNotAvailable, isMetricNotAvailableYet } from "./helpers";
import { getMediaTypeIcon } from "@components/_common/CommentDetail/helpers";
import { MAX_SHOW_FLAG_COUNT } from "@components/_common/CommentDetail/CommentDetailsConfig";

export type PostsTableRowProps = ITableRow<IPost>;

const PostsTableRow = React.memo(({ data: row, index, columns }: PostsTableRowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [postOpen, setPostOpen] = useState(false);

  const onClosePost = () => setPostOpen(false);
  const onOpenPostClick = () => setPostOpen(true);

  const deleteTagFromPost = useCallback(
    tagId => row?.id && dispatch(deleteTagFromPostAction({ post_tag_id: tagId, post_id: row?.id })),
    [dispatch, row?.id],
  );

  const isYTPost = row?.platform_type.id ? isYoutubePlatform(row?.platform_type.id) : false;
  const isXPost = row?.platform_type.id ? isTwitterPlatform(row?.platform_type.id) : false;
  const nssScore = row?.comment_metrics?.nss_score;

  const getMetricText = (
    metric?: string | number,
    format?: (metric?: string | number) => string,
  ) => {
    if (isMetricNotAvailableYet(metric)) {
      return t("components:posts:table:notAvailableYet");
    } else if (isMetricNotAvailable(metric)) {
      return t("components:posts:table:notAvailable", { platform: row?.platform_type?.label });
    } else return format ? format(metric) : metric;
  };

  const isUnavailableMetric = (metric?: string | number) =>
    isMetricNotAvailable(metric) || isMetricNotAvailableYet(metric);

  return (
    <>
      {!!columns.length && (
        <div
          data-cy="posts-table-row"
          className={classNames(s.bbPostsTableRow, {
            [s.bbPostsTableRowColored]: index % 2 === 1,
          })}
        >
          <Col
            span={columns[0].colSpan}
            data-cy="post-column"
            className={classNames(s.bbColPost, s.bbColumnSticky)}
          >
            <div className={s.bbPostViewWrapper} data-cy="post-wrapper">
              <div className={s.bbPostLeftSection}>
                <div
                  data-cy="post-platform"
                  className={classNames({
                    [s.bbPlatformIconLarge]: isYTPost || isXPost,
                    [s.bbPlatformIconSmall]: !isYTPost && !isXPost,
                  })}
                >
                  {row?.platform_type.id && getPlatformIconBasicColor(row.platform_type.id)}
                </div>
                <Avatar
                  size={40}
                  shape="square"
                  style={{ fontSize: "12px" }}
                  src={row?.picture || ""}
                  className={classNames(s.bbPostViewImagePreview, {
                    [s.bbPortraitImage]: row?.post_placement === PostPlacementEnum.STORY,
                  })}
                >
                  {t("generic:noImage")}
                </Avatar>
                <div className={classNames(s.bbPostTextSection, s.bbPostNoWrap)}>
                  <div className={s.bbPostNoWrap}>
                    <span className={s.bbPostAuthor}>{row?.author_name}</span>
                  </div>
                  <div data-cy="post-text" className={classNames(s.bbPostText, s.bbPostNoWrap)}>
                    {row?.message}
                  </div>
                </div>
              </div>

              <div
                data-cy="post-right-section"
                className={classNames(s.bbPostRightSection, s.bbPostNoWrap)}
              >
                <div className={s.bbPostRightSectionActions}>
                  <PostsTableRowActions
                    postId={row.id}
                    showAds={!!row.has_ads}
                    onOpenPostClick={onOpenPostClick}
                    triggerClassName={classNames(
                      index % 2 === 1 ? [s.bbActionBtnColored] : [s.bbActionBtnWhite],
                    )}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[1].colSpan}>
            <div className={s.bbPostDate}>
              {extractDatePlain(row?.created_time_pretty)}
              <Tooltip title={t("components:posts:table:dateTooltip")}>
                <i className={s.bbPostDateHelpIcon}>
                  <HelpIcon />
                </i>
              </Tooltip>
            </div>
          </Col>
          <Col
            className={classNames(s.bbColumn, s.bbColumnFormat)}
            data-cy="table-column"
            span={columns[2].colSpan}
          >
            {row?.post_media_type?.id && (
              <span
                className={classNames(
                  s.bbPostFormat,
                  s[`bbPostFormat-${row?.post_media_type?.id}`],
                )}
              >
                <span className={s.bbPostFormatIcon}>
                  {getMediaTypeIcon(row?.post_media_type?.id)}
                </span>
                <span>{row?.post_media_type?.label}</span>
              </span>
            )}
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[3].colSpan}>
            <div
              className={classNames(
                s.bbNssScore,
                s[
                  `bbNssScore-${!!nssScore ? (nssScore > 0 ? "positive" : "negative") : "neutral"}`
                ],
                {
                  [s.bbMetricNotAvailable]: isUnavailableMetric(nssScore),
                },
              )}
            >
              {getMetricText(nssScore, metric => (metric || 0) + "%")}
            </div>
          </Col>
          <Col
            className={classNames(s.bbColumn, {
              [s.bbMetricNotAvailable]: isUnavailableMetric(
                row.post_metrics?.engagement_rate_impressions,
              ),
            })}
            data-cy="table-column"
            span={columns[4].colSpan}
          >
            {getMetricText(row.post_metrics?.engagement_rate_impressions, metric =>
              metric ? parseFloat(Number(metric).toFixed(4)) + "%" : "0%",
            )}
          </Col>
          <Col
            className={classNames(s.bbColumn, {
              [s.bbMetricNotAvailable]: isUnavailableMetric(row.post_metrics?.engagement_rate),
            })}
            data-cy="table-column"
            span={columns[5].colSpan}
          >
            {getMetricText(row.post_metrics?.engagement_rate, metric =>
              metric ? parseFloat(Number(metric).toFixed(4)) + "%" : "0%",
            )}
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[6].colSpan}>
            {row.comment_metrics.total_comments}
          </Col>
          <Col
            className={classNames(s.bbColumn, {
              [s.bbMetricNotAvailable]: isUnavailableMetric(row.post_metrics?.total_reactions),
            })}
            data-cy="table-column"
            span={columns[7].colSpan}
          >
            {getMetricText(row.post_metrics?.total_reactions)}
          </Col>
          <Col
            className={classNames(s.bbColumn, {
              [s.bbMetricNotAvailable]: isUnavailableMetric(row.post_metrics?.total_views),
            })}
            data-cy="table-column"
            span={columns[8].colSpan}
          >
            {getMetricText(row.post_metrics?.total_views)}
          </Col>
          <Col
            className={classNames(s.bbColumn, {
              [s.bbMetricNotAvailable]: isUnavailableMetric(row.post_metrics?.total_reaches),
            })}
            data-cy="table-column"
            span={columns[9].colSpan}
          >
            {getMetricText(row.post_metrics?.total_reaches)}
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[10].colSpan}>
            {getMetricText(row.post_metrics?.total_shares)}
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[11].colSpan}>
            {row.comment_metrics.positive_comments || 0}
            <span className={s.bbWhiteChip}>
              {row.comment_metrics.positive_comments_percent || 0}%
            </span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[12].colSpan}>
            {row.comment_metrics.negative_comments || 0}
            <span className={s.bbWhiteChip}>
              {row.comment_metrics.negative_comments_percent || 0}%
            </span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[13].colSpan}>
            {row.comment_metrics.neutral_comments || 0}
            <span className={s.bbWhiteChip}>
              {row.comment_metrics.neutral_comments_percent || 0}%
            </span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[14].colSpan}>
            {row.comment_metrics.user_tags_comments || 0}
            <span className={s.bbWhiteChip}>
              {row.comment_metrics.user_tags_comments_percent || 0}%
            </span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[15].colSpan}>
            {row.comment_metrics.hidden_comments || 0}
            <span className={s.bbWhiteChip}>
              {row.comment_metrics.hidden_comments_percent || 0}%
            </span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[16].colSpan}>
            {row.comment_metrics.brand_comments || 0}
            <span className={s.bbWhiteChip}>
              {row.comment_metrics.brand_comments_percent || 0}%
            </span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[17].colSpan}>
            {!!row?.targetings?.length && (
              <span className={s.bbPostTag}>
                {getCountriesFlags(row?.targetings, MAX_SHOW_FLAG_COUNT)}
              </span>
            )}
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[18].colSpan}>
            <span className={s.bbWhiteChip}>{row?.post_type}</span>
          </Col>
          <Col
            className={classNames(s.bbColumn, s.bbPostTagsColumn)}
            data-cy="table-column"
            span={columns[19].colSpan}
          >
            {!!row?.post_tags.length && (
              <PostTagsList
                maxTagListLength={1}
                postTags={row?.post_tags}
                onDeleteTag={deleteTagFromPost}
                tagClassName={s.bbPostTagsListTag}
                tagIconClassName={s.bbPostTagsListIcon}
              />
            )}
            <div className={s.bbPostTagsDropdownBtnWrapper}>
              <PostTagsDropdown postTags={row?.post_tags} postId={row?.id} />
            </div>
          </Col>

          <PostModal post={row} showPostTags isOpen={postOpen} onClose={onClosePost} />
        </div>
      )}
    </>
  );
});

PostsTableRow.displayName = "PostsTableRow";

export default PostsTableRow;
