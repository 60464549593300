import React from "react";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Overview.module.less";

import { IWorkflowCategory } from "@store/workflows/types";
import { TFunction } from "react-i18next";
import { IActionSummaryStat } from "@store/dashboard/types";

import { ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";
import BgHideWorkflow from "@assets/workflowsWidget/BgHideWorkflow.svg";
import BgAlertWorkflow from "@assets/workflowsWidget/BgAlertWorkflow.svg";
import BgArchiveWorkflow from "@assets/workflowsWidget/BgArchiveWorkflow.svg";
import BgOtherWorkflow from "@assets/workflowsWidget/BgOtherWorkflow.svg";
import BgReplyWorkflow from "@assets/workflowsWidget/BgReplyWorkflow.svg";
import BgTagWorkflow from "@assets/workflowsWidget/BgTagWorkflow.svg";

export const workflowBgImagesMap = {
  hide: <BgHideWorkflow />,
  email_alert: <BgAlertWorkflow />,
  archive: <BgArchiveWorkflow />,
  other: <BgOtherWorkflow />,
  reply: <BgReplyWorkflow />,
  tag: <BgTagWorkflow />,
};

const CARD_IDS = [
  "replies",
  "alerts",
  "archived_automatically",
  "other_workflows",
  "tags",
  "hidden_conversations",
];

const ACTION_MAP = {
  replies: "reply",
  alerts: "email_alert",
  archived_automatically: "archive",
  tags: "tag",
  hidden_conversations: "hide",
  other_workflows: "assign,sentiment,zendesk",
};

export const getSummaryCardTooltips = (hasEssentialPlan: boolean, t: TFunction) => {
  return CARD_IDS.reduce(
    (acc, key) => {
      acc[key] = t(
        `components:controlPanel:overview:summary:cardTooltips:${
          hasEssentialPlan && key === "hidden_conversations" ? "hide_essential" : key
        }`,
      );
      return acc;
    },
    {} as Record<(typeof CARD_IDS)[number], string>,
  );
};

export const getShowActionCard = (
  item: IActionSummaryStat,
  workflowCategories: IWorkflowCategory[],
  t: TFunction,
  onClick: (id: string) => void,
  isNotManagedClient?: boolean,
) => {
  const { id, count, percentageChange, disabledReason } = item;
  if (count || !id || percentageChange?.value || disabledReason) return undefined;
  const subCategories = workflowCategories?.flatMap(c => c.sub_categories).filter(Boolean);
  const workflowIds = ACTION_MAP[id as keyof typeof ACTION_MAP]?.split(",");

  const showAction = workflowIds?.every(workflowId =>
    subCategories.some(sub => sub.id === workflowId && !sub.ids.length),
  );

  return showAction ? (
    <Button
      _type="secondary"
      _size="sm"
      className={s.bbOverviewCardAction}
      onClick={() => onClick(workflowIds.join(","))}
    >
      {t(
        `components:controlPanel:overview:summary:cardActions:${
          workflowIds.includes("email_alert")
            ? "alert"
            : isNotManagedClient && workflowIds.includes("hide")
              ? "hide"
              : "workflows"
        }`,
      )}
      <ChevronRightIcon />
    </Button>
  ) : null;
};
