import cn from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useContext, Fragment, useState } from "react";
import classNames from "classnames";

import { Form, Grid } from "antd";
import { PostCreationTabs } from "./PostCreationTabs";
import { PostCreationFooter } from "./PostCreationFooter";
import { PostCreationPreview } from "./PostCreationPreview";
import { PostCreationEmptyState } from "./PostCreationEmpty";
import { PostCreationAssetTab } from "./PostCreationAssetTab";
import { PostCreationFormBody } from "./PostCreationFormBody";
import { PostCreationTabBarExtra } from "./PostCreationTabBarExtra";
import { BBPlatformOptions } from "./BBPlatformOptions/BBPlatformOptions";
import TTDiscloseContentOptions from "./TTDiscloseContentOptions";
import YouTubeOptions from "./YouTubeOptions";
import { Loading, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";

import {
  urlPreviewsSelector,
  TTCreatorInfosSelector,
  fetchingTTCreatorInfoSelector,
  fetchingUrlPreviewsSuccessfulSelector,
  fetchedTTCreatorInfosSuccessfulSelector,
  fetchedTTCreatorInfoErrorDataSelector,
  fetchedTTCreatorInfoFailureSelector,
} from "@store/publishings/selectors";

import { resetInternalNotesAction } from "@store/internalNotes/actions";

import s from "./index.module.less";

import { isTT, isYT } from "./helpers/utilsValidations";
import useUpdatePost from "./helpers/useUpdatePost";
import useVideoMetadata from "./helpers/useVideoMetadata";
import PostCreationContext from "./context/PostCreationContext";
import CalendarContext from "../calendar/context/CalendarContext";
import { IPostItem, IPostCreationFormProps } from "./helpers/types";
import { generateExistingPostItem } from "./helpers/utilsGeneric";
import { IAutocompleteAsset, IYouTubeOptions } from "@bbdevcrew/bb_ui_kit_fe";
import { useDatePickerDefaultValue } from "./helpers/useDatePickerDefaultValue";

const cx = cn.bind(s);

export function PostCreationForm({ form, onFinish, onDelete }: IPostCreationFormProps) {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const {
    posts,
    setPosts,
    loading,
    selectedAssets,
    setSelectedAssets,
    activeAssetTab,
    setActiveAssetTab,
  } = useContext(PostCreationContext);
  const { editingPost, isPostModalOpen } = useContext(CalendarContext);
  const dispatch = useDispatch();

  const [isFileUploading, setIsFileUploading] = useState(false);

  const isEditMode = isPostModalOpen && !!editingPost;
  const isEmptyState = !selectedAssets.length && !loading;
  const datePickerDefaultValue = useDatePickerDefaultValue();

  const isMobile = screens.md === false;

  const { updatePost } = useUpdatePost();

  const urlPreviews = useSelector(urlPreviewsSelector);
  const urlPreviewsFetched = useSelector(fetchingUrlPreviewsSuccessfulSelector);

  const ttCreatorInfoList = useSelector(TTCreatorInfosSelector);
  const fetchingTTCreatorInfo = useSelector(state =>
    fetchingTTCreatorInfoSelector(state, activeAssetTab?.id),
  );
  const ttCreatorInfoFetched = useSelector(fetchedTTCreatorInfosSuccessfulSelector);
  const ttCreatorInfoFetchingFailed = useSelector(fetchedTTCreatorInfoFailureSelector);
  const ttCreatorInfoErrorData = useSelector(fetchedTTCreatorInfoErrorDataSelector);

  // Returning current selected tab/post
  const currentPost = useMemo(() => {
    const post = posts.find(({ asset }) => asset.id === activeAssetTab?.id);
    const creatorInfo = ttCreatorInfoList.find(
      currentCreatorInfo => currentCreatorInfo.asset_id === post?.asset.id,
    );

    return post ? { ...post, creatorInfo } : undefined;
  }, [posts, ttCreatorInfoList, activeAssetTab?.id]);

  useEffect(() => {
    if (urlPreviews.length || currentPost?.asset.preview_link) {
      const previewLink = urlPreviews.length ? urlPreviews?.at(-1) : undefined;

      updatePost({
        ...currentPost,
        asset: {
          ...currentPost?.asset,
          preview_link: previewLink,
        },
      } as IPostItem);
    }
    // eslint-disable-next-line
  }, [urlPreviewsFetched, urlPreviews, activeAssetTab]);

  useEffect(() => {
    if (ttCreatorInfoFetched.length && ttCreatorInfoList.length) {
      const lastUpdatedCreatorInfo = ttCreatorInfoList[ttCreatorInfoList.length - 1];
      const updatedPost = posts.find(post => post.asset.id === lastUpdatedCreatorInfo.asset_id);

      if (updatedPost) {
        updatePost({ ...updatedPost, creatorInfo: lastUpdatedCreatorInfo }, "creatorInfo");
      }
    }
    // eslint-disable-next-line
  }, [ttCreatorInfoFetched.length]);

  useEffect(() => {
    if (ttCreatorInfoFetchingFailed && ttCreatorInfoErrorData) {
      const updatedPost = posts.find(post => post.asset.id === ttCreatorInfoErrorData.assetId);

      if (updatedPost) updatePost({ ...updatedPost, error: "postingRestricted" }, "error");
    }
    // eslint-disable-next-line
  }, [ttCreatorInfoFetchingFailed, ttCreatorInfoErrorData]);

  useEffect(() => {
    if (isEditMode) {
      const {
        asset: { id, name, platform, type, picture, username },
      } = editingPost;

      const asset: IAutocompleteAsset = {
        id,
        label: name,
        platform,
        type: type as string,
        picture,
        username,
      };

      const post = generateExistingPostItem(editingPost, asset);

      setPosts([post]);
      setActiveAssetTab(asset);
      setSelectedAssets([asset]);
    }
    // eslint-disable-next-line
  }, [isEditMode]);

  useEffect(() => {
    form.setFieldsValue({
      scheduled_at: datePickerDefaultValue,
    });
  }, [form, datePickerDefaultValue]);

  useEffect(() => {
    dispatch(resetInternalNotesAction());
  }, [isPostModalOpen, dispatch]);

  useVideoMetadata(posts, updatePost);

  const onAssetTabChange = (activeKey: string) => {
    const activeTab = selectedAssets.find(selectedAsset => selectedAsset.id === activeKey);
    setActiveAssetTab(activeTab);
  };

  return (
    <Form
      form={form}
      initialValues={{
        scheduled_at: datePickerDefaultValue,
      }}
      className={s.bbPostCreationFormWrapper}
      layout="vertical"
      requiredMark="optional"
      onFieldsChange={values => {
        if (currentPost && isYT(currentPost)) {
          const nextYoutubeOptions: Partial<IYouTubeOptions> = {};
          values.forEach(currentValue => {
            const { name: namePath, value } = currentValue;
            const name = namePath.toString();
            if (name.startsWith("youtubeOptions.")) {
              const propertyName = name.split("youtubeOptions.")[1] as keyof IYouTubeOptions;
              nextYoutubeOptions[propertyName] = value;
            }
          });
          updatePost({
            ...currentPost,
            youtubeOptions: {
              ...(currentPost.youtubeOptions || { title: "" }),
              ...nextYoutubeOptions,
            },
          });
        }
      }}
    >
      <div
        className={classNames(s.bbPostCreationFormContent, {
          [s.bbPostCreationFormContentMobile]: isMobile,
        })}
      >
        <div
          className={classNames(s.bbPostCreationForm, { [s.bbPostCreationFormMobile]: isMobile })}
        >
          {!isMobile && (
            <div
              className={s.bbPostCreationBodyTitle}
              data-stonly-target="publishing-post__creation__modal--title"
            >
              {isEditMode
                ? t("components:publish:postCreationModal:editTitle")
                : t("components:publish:calendar:createPost")}
            </div>
          )}

          <div
            className={cx(s.bbPostCreationBodyDescription, {
              [s.bbPostCreationBodyDescriptionEmpty]: !selectedAssets.length,
              [s.bbPostCreationBodyDescriptionEmptyMobile]: !selectedAssets.length && isMobile,
            })}
          >
            {!selectedAssets.length && !isMobile && (
              <p className={s.bbAssetTextEmpty}>
                {t("components:publish:postCreationModal:empty:label")}
              </p>
            )}
            <TabsScrollable
              onChange={onAssetTabChange}
              className={classNames(s.bbPostCreationAssetTabs, {
                [s.bbPostCreationAssetTabsMobile]: isMobile,
              })}
              defaultActiveKey={selectedAssets[0]?.id}
              activeKey={activeAssetTab?.id || selectedAssets[0]?.id}
              tabContentClassName={s.bbPostCreationAssetTabsContent}
              tabHeadersClassName={classNames(s.bbPostCreationAssetTabsHeaders, {
                [s.bbPostCreationAssetTabsHeadersMobile]: isMobile,
              })}
              tabBarSuffix={!isEditMode && <PostCreationTabBarExtra form={form} />}
            >
              {posts?.map(post => {
                const creatorInfo = ttCreatorInfoList.find(item => item.asset_id === post.asset.id);

                return (
                  <TabPane
                    tabKey={post.asset.id}
                    key={post.asset.id}
                    wrapperClassName={s.bbPostCreationAssetTabWrapper}
                    className={cx(s.bbPostCreationAssetTab, {
                      [s.bbPostCreationAssetTabActive]: post.asset.id === activeAssetTab?.id,
                    })}
                    tab={<PostCreationAssetTab asset={post.asset} hasError={!!post.error} />}
                  >
                    {!!activeAssetTab && (
                      <Fragment>
                        <PostCreationFormBody
                          form={form}
                          post={post}
                          updatePost={updatePost}
                          setIsFileUploading={setIsFileUploading}
                        />
                        {isYT(post) && currentPost && (
                          <YouTubeOptions form={form} post={currentPost} updatePost={updatePost} />
                        )}
                        {currentPost && (
                          <BBPlatformOptions post={currentPost} updatePost={updatePost} />
                        )}
                        {isTT(post) && (
                          <>
                            {creatorInfo && currentPost ? (
                              <TTDiscloseContentOptions
                                post={currentPost}
                                updatePost={updatePost}
                                ttCreatorInfo={creatorInfo}
                              />
                            ) : fetchingTTCreatorInfo ? (
                              <div className={s.bbTTOptionsLoading}>
                                <Loading isLoading />
                              </div>
                            ) : null}
                          </>
                        )}
                      </Fragment>
                    )}
                  </TabPane>
                );
              })}
            </TabsScrollable>
          </div>

          {loading && (
            <div className={s.bbAssetsLoading}>
              <Loading isLoading />
            </div>
          )}
          {isEmptyState && <PostCreationEmptyState />}
          {!!selectedAssets.length && !isMobile && (
            <PostCreationTabs form={form} currentPost={currentPost} />
          )}
        </div>
        <PostCreationPreview post={currentPost} id={editingPost ? editingPost.id : undefined} />
        {!!selectedAssets.length && isMobile && (
          <PostCreationTabs form={form} currentPost={currentPost} />
        )}
      </div>
      <PostCreationFooter
        form={form}
        submitDisabled={isEmptyState || isFileUploading}
        hasSelectedAssets={!!selectedAssets.length}
        onFinish={onFinish}
        onDelete={onDelete}
      />
    </Form>
  );
}
