import { ISource } from "@store/sources/types";
import { t } from "../../../App";
import { ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";
import { ISourcesTableSort } from "./Sources.types";

export const TABLE_PAGE_SIZE = 25;

export const getTableColumns = ({ isMobile }: { isMobile: boolean }): ISimpleTableColumn[] => {
  const firstColumn = {
    name: t("components:listen:sources:table:columns:source"),
    id_name: "source_label",
    colSpan: isMobile ? 12 : 7,
  };

  if (isMobile) return [firstColumn];

  return [
    firstColumn,
    {
      name: t("components:listen:sources:table:columns:type"),
      id_name: "type_id",
      colSpan: 4,
    },
    {
      name: t("components:listen:sources:table:columns:asset"),
      id_name: "asset_name",
      colSpan: 5,
    },
    {
      name: t("components:listen:sources:table:columns:status"),
      id_name: "asset_status",
      colSpan: 4,
    },
  ];
};

export const getSourceTableData = (data: ISource[], page: number, sort?: ISourcesTableSort) => {
  const sortedData = sort
    ? [...data].sort((a, b) => {
        const valueA = a[sort.field];
        const valueB = b[sort.field];
        if (valueA === valueB) return 0;
        const comparison = valueA && valueB && valueA > valueB ? 1 : -1;
        return sort.order === "asc" ? comparison : -comparison;
      })
    : data;
  const startIndex = (page - 1) * TABLE_PAGE_SIZE;
  const endIndex = startIndex + TABLE_PAGE_SIZE;

  return sortedData.slice(startIndex, endIndex);
};
