import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, forwardRef } from "react";

import { Grid } from "antd";
import { Button, Filters, SavedFilter } from "@bbdevcrew/bb_ui_kit_fe";

import {
  postSavedFilterAction,
  deleteSavedFilterAction,
  resetSavedFiltersDataAction,
  putSavedFilterAction,
} from "@store/savedFilters/actions";
import { saveFilterAction, toggleOpenFiltersPanelAction } from "@store/filters/actions";

import {
  postSavedFilterIdSelector,
  postSavedFilterPendingSelector,
  postSavedFilterSuccessfulSelector,
  predefinedFiltersSelector,
} from "@store/savedFilters/selectors";
import {
  filterDataSelector,
  filtersOpenSelector,
  filtersPanelWidthSelector,
  savedFilterValueSelector,
} from "@store/filters/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import { isAIInsightsModalExpandedSelector } from "@store/aiInsights/selectors";
import { aiOnboardingModalExpanded } from "@store/accounts/selector";

import s from "./AppFilters.module.less";

import {
  debounce,
  IFilters,
  IFormHandler,
  ISavedFilter,
  IPostSavedFilterBodyProps,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { useGetDefaultFiltersConfig } from "./config";
import { IAppFiltersProps } from "./AppFilters.type";
import { getFetchValuesFieldName } from "@utils/filters";
import { useSavedFiltersList } from "@utils/useSavedFiltersList";
import { IPutSavedFilterBodyProps } from "@store/savedFilters/types";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { useFilterContext } from "../AppLayoutContainer/FilterContext";

import { FolderIcon, ChevronRightIcon, ChevronLeftIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AppFilters = forwardRef<IFormHandler, IAppFiltersProps>(
  (
    {
      clientData,
      hideSavedFilters,
      initialFilterData,
      hideFilterButtons,
      customFilters,
      isBottomReached = false,
      className,
      onFilter,
      onResetFilters,
      onSavedFilterChange,
      onSavedStickyChange,
      setStickyFilterValue,
    },
    filtersRef,
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const filterContext = useFilterContext();
    const screens = Grid.useBreakpoint();
    const isMobileView = screens.md === false;
    const savedFilters = useSavedFiltersList();
    const defaultFiltersConfig = useGetDefaultFiltersConfig(t, false, filterContext.form);

    const appFilters = useSelector(filterDataSelector);
    const filtersPanelOpen = useSelector(filtersOpenSelector);
    const filtersPanelWidth = useSelector(filtersPanelWidthSelector);
    const predefinedFilters = useSelector(predefinedFiltersSelector);
    const autocompleteOptions = useSelector(autocompleteOptionsSelector);
    const savedFilterValue = useSelector(savedFilterValueSelector);
    const isPostSavedPending = useSelector(postSavedFilterPendingSelector);
    const isPostSuccessful = useSelector(postSavedFilterSuccessfulSelector);
    const postSavedFilterId = useSelector(postSavedFilterIdSelector);
    const isAIInsightsModalOpen = useSelector(isAIInsightsModalExpandedSelector);
    const isAIOnboardingModalOpen = useSelector(aiOnboardingModalExpanded);

    const isAIModalOpen = isAIInsightsModalOpen || isAIOnboardingModalOpen;

    const filtersFormSizes = {
      width: isMobileView ? "100%" : isAIModalOpen && !filtersPanelOpen ? 0 : filtersPanelWidth,
      height: !filtersPanelOpen ? "100%" : undefined,
    };

    const toggleFiltersPanel = useCallback(
      (open: boolean) => dispatch(toggleOpenFiltersPanelAction(open)),
      [dispatch],
    );

    const setSavedFilterValue = useCallback(
      (id: string | number | null) => dispatch(saveFilterAction(id)),
      [dispatch],
    );

    // eslint-disable-next-line
    const getAutocompleteOptions = useCallback(
      debounce((payload: IGetAutocompleteOptionsPayload) => {
        dispatch(getAutocompleteOptionsAction(payload));
      }, 300),
      [dispatch],
    );

    useEffect(() => {
      if (initialFilterData.saved_filter && predefinedFilters.length) {
        // It's a sticky filter -> id = 'dsf766-sad324-asd3'
        if (Number.isNaN(Number(initialFilterData.saved_filter))) {
          setStickyFilterValue(initialFilterData.saved_filter);
        } else {
          setSavedFilterValue(Number(initialFilterData.saved_filter));
        }

        onSavedStickyChange(initialFilterData.saved_filter);
      }
      // eslint-disable-next-line
    }, [initialFilterData, predefinedFilters]);

    const onInternalFilter = (
      filterRequest: IFilters,
      param?: string | ISavedFilter | undefined,
    ) => {
      setSavedFilterValue(null);
      setStickyFilterValue(null);
      onFilter(filterRequest, param);
    };

    const renderFilterButtons = () => {
      return (
        <div
          style={{ width: isMobileView ? "100%" : filtersPanelWidth }}
          className={classNames(
            s.bbAppFilterFooterButtons,
            {
              [s.bbAppFilterFooterButtonsMobile]: isMobileView,
              [s.bbAppFilterFooterButtonsWithBottomReached]: !isMobileView && isBottomReached,
            },
            className,
          )}
        >
          <Button
            _size="sm"
            _type="secondary"
            type="submit"
            id="reset-filters"
            onClick={onResetFilters}
            className={s.bbFiltersResetBtn}
            data-cy="reset-filters-button"
            data-stonly-target="app-global__filters--clear-all-button"
          >
            {t("generic:clearAll")}
          </Button>
          <Button
            _size="sm"
            _type="primary"
            type="submit"
            id="apply-filter"
            form="bbFilterContainer"
            className={s.bbFiltersApplyBtn}
            data-cy="apply-filters-button"
            data-stonly-target="app-global__filters--apply-button"
          >
            {t("components:filters:applyBtn")}
          </Button>
        </div>
      );
    };

    return (
      <div
        className={classNames(s.bbAppFiltersWrapper, {
          [s.bbAppFiltersWrapperDesktop]: !isMobileView && !isAIModalOpen,
          [s.bbAppFiltersWrapperDesktopCollapsed]:
            !isMobileView && !filtersPanelOpen && !isAIModalOpen,
          [s.bbAppFiltersWrapperMobile]: isMobileView,
          [s.bbAppFiltersWrapperDesktopWithBottomReached]: !isMobileView && isBottomReached,
          [s.bbAppFiltersWrapperDesktopWithAIModal]: !isMobileView && isAIModalOpen,
        })}
      >
        <div className={s.bbAppFiltersForm} style={filtersFormSizes} data-cy="app-filters-form">
          {!isMobileView && !isAIModalOpen && (
            <Button
              className={s.bbToggleFiltersPanelBtn}
              style={{ right: filtersPanelWidth - 7 }}
              onClick={() => toggleFiltersPanel(!filtersPanelOpen)}
            >
              {filtersPanelOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
          )}
          {(filtersPanelOpen || isMobileView) && (
            <Filters
              ref={filtersRef}
              form={filterContext.form}
              onFilter={onInternalFilter}
              filtersConfig={customFilters || defaultFiltersConfig}
              initialFilterData={initialFilterData}
              filterPlacement={!screens.md ? "left" : "right"}
              clientPlatformTypes={clientData?.platform_types || []}
              sanitizeFieldName={getFetchValuesFieldName}
              getAutocompleteOptions={getAutocompleteOptions}
              autocompleteOptions={autocompleteOptions}
              className={classNames(s.bbAppFiltersFormWrapper, {
                [s.bbAppFiltersFormMobileWrapper]: isMobileView,
              })}
            >
              {{
                prefix: (
                  <div className={s.bbSavedFilterWrapper}>
                    <div className={s.bbSavedFilterTitle}>{t("generic:filters")}</div>
                    <div className={s.bbSavedFilterActions}>
                      {!hideSavedFilters && (
                        <SavedFilter
                          predefinedFilters={predefinedFilters}
                          sanitizeFieldName={getFetchValuesFieldName}
                          getAutocompleteOptions={getAutocompleteOptions}
                          autocompleteOptions={autocompleteOptions}
                          savedFilters={savedFilters}
                          savedFilterValue={savedFilterValue}
                          isPostSavedPending={isPostSavedPending}
                          isPostSuccessful={isPostSuccessful}
                          postSavedFilterId={postSavedFilterId}
                          appFilters={appFilters}
                          availablePlatformTypes={clientData?.platform_types || []}
                          onSavedFilterChange={onSavedFilterChange}
                          onResetFilters={() => dispatch(resetSavedFiltersDataAction())}
                          onDeleteSavedFilter={(id: string) =>
                            dispatch(deleteSavedFilterAction(id))
                          }
                          onCreateSavedFilter={(payload: IPostSavedFilterBodyProps) =>
                            dispatch(postSavedFilterAction(payload))
                          }
                          onUpdateSavedFilter={(payload: IPutSavedFilterBodyProps) =>
                            dispatch(putSavedFilterAction(payload))
                          }
                          filtersConfig={
                            customFilters?.length ? customFilters : defaultFiltersConfig
                          }
                        >
                          {({ open }: { open?: boolean }) => (
                            <Button
                              type="button"
                              _type="secondary"
                              className={classNames(s.bbSavedFilterSelect, {
                                [s.bbSavedFilterSelectActive]: open,
                              })}
                            >
                              <FolderIcon />
                            </Button>
                          )}
                        </SavedFilter>
                      )}
                      {isMobileView && (
                        <div
                          className={s.bbFilterCloseIconWrapper}
                          onClick={filterContext?.closeFilterModal}
                        >
                          <CloseIcon />
                        </div>
                      )}
                    </div>
                  </div>
                ),
                wrapperSuffix: <>{!hideFilterButtons ? renderFilterButtons() : null}</>,
              }}
            </Filters>
          )}
        </div>
      </div>
    );
  },
);

AppFilters.displayName = "AppFilters";

export default AppFilters;
