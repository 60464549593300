import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { SimpleTable } from "@bbdevcrew/bb_ui_kit_fe";
import { Grid } from "antd";

import TrendingTableRow from "./TrendingTableRow";

import { getSortedData, getTableColumns, TABLE_PAGE_SIZE } from "./TiktokTrending.helpers";
import { ISimpleTableColumn, SortType } from "@bbdevcrew/bb_ui_kit_fe";
import {
  ITrendingTableProps,
  ITrendingTableSort,
  TrendingColumnSortType,
} from "./TiktokTrending.types";

const TrendingTable: React.FC<ITrendingTableProps> = ({
  data = [],
  loading,
  onChangePage,
  page,
  mode,
}) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const [sort, setSort] = useState<ITrendingTableSort>();

  const onSort = (column: ISimpleTableColumn, _sort?: SortType) => {
    if (!_sort) {
      setSort(undefined);
    } else {
      setSort({
        order: _sort,
        field: column.id_name as TrendingColumnSortType,
      });
    }
  };

  return (
    <SimpleTable
      data={getSortedData(data, page, sort)}
      onSort={onSort}
      loading={loading}
      tableRow={args => <TrendingTableRow {...args} mode={mode} />}
      columns={getTableColumns(mode, screens, t)}
      pagination={{
        total: data.length,
        pageSize: TABLE_PAGE_SIZE,
        currentPage: page,
        onChangePage,
      }}
      emptyTableText={t("generic:emptyTable")}
      pageOutOfText={[
        t("components:sortableTable:pageOutOfPart1"),
        t("components:sortableTable:pageOutOfPart2"),
      ]}
    />
  );
};

export default TrendingTable;
