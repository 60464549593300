import React, { FC } from "react";

import s from "./MultiImagePreview.module.less";

import { IMultiImagePreviewProps } from "./types";

const MultiImagePreview: FC<IMultiImagePreviewProps> = ({
  image,
  style,
  showExcess,
  excessNumber,
}) => {
  return (
    <div className={s.bbImageContainer} style={style?.imageContainerStyle}>
      <a className={s.bbImageLink}>
        <div className={s.bbImageFlows}>
          <div style={style?.wrapperStyle} className={s.bbWrapper}>
            <div className={s.bbRoot} style={style?.rootStyle}>
              <img alt={image.id} src={image.src} className={s.bbImage} />
            </div>
          </div>
          <div className={s.bbBottomSupport}></div>
        </div>
        {showExcess && (
          <div className={s.bbNumberOfExcessImages}>
            <div className={s.bbExcessNumberText}>{`+${excessNumber}`}</div>
          </div>
        )}
        <div className={s.bbFlowSupporter} data-visualcompletion="ignore"></div>
      </a>
    </div>
  );
};

export default MultiImagePreview;
