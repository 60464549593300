import React, { useContext, useState } from "react";

import PostCreationContext from "./PostCreationContext";

import { IPostItem } from "../helpers/types";
import { IAutocompleteAsset } from "@bbdevcrew/bb_ui_kit_fe";
import { getInitialPostScheduleTab } from "../helpers/utilsGeneric";
import CalendarContext from "../../calendar/context/CalendarContext";

interface IContextWrapperProps {
  children: React.ReactNode;
}

export default function ContextWrapper(props: IContextWrapperProps) {
  const { editingPost, isPostModalOpen } = useContext(CalendarContext);
  const isEditMode = isPostModalOpen && !!editingPost;

  const [posts, setPosts] = useState<IPostItem[]>([]);
  const [isSynced, setIsSynced] = useState(true);
  const [loading, setLoading] = useState(!isEditMode);

  const [activeAssetTab, setActiveAssetTab] = useState<IAutocompleteAsset>();
  const [selectedAssets, setSelectedAssets] = useState<IAutocompleteAsset[]>([]);
  const [allAssets, setAllAssets] = useState<IAutocompleteAsset[]>([]);

  const [isScheduled, setIsScheduled] = useState(false);
  const [activePostScheduleTab, setActivePostScheduleTab] = useState<string>(
    getInitialPostScheduleTab(editingPost),
  );

  return (
    <PostCreationContext.Provider
      value={{
        posts,
        setPosts,
        loading,
        setLoading,
        isSynced,
        setIsSynced,
        activeAssetTab,
        setActiveAssetTab,
        selectedAssets,
        setSelectedAssets,
        allAssets,
        setAllAssets,
        isScheduled,
        setIsScheduled,
        activePostScheduleTab,
        setActivePostScheduleTab,
      }}
    >
      {props.children}
    </PostCreationContext.Provider>
  );
}
