import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InternalNotesProvider } from "@bbdevcrew/bb_ui_kit_fe";

import { getCommentHistorySelector } from "@store/comments/selectors";
import { fetchingCommentHistorySelector } from "@store/comments/selectors";
import { getCommentHistoryAction, resetCommentHistoryAction } from "@store/comments/actions";
import { meSelector } from "@store/me/selectors";
import { internalNotesSelector } from "@store/internalNotes/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import {
  deleteInternalNoteAction,
  getInternalNotesAction,
  postInternalNotesAction,
} from "@store/internalNotes/actions";
import {
  getAutocompleteOptionsSearchAction,
  updateAutocompleteOptionsAction,
} from "@store/autocomplete/actions";

import { debounce, IGetAutocompleteOptionsPayload } from "@bbdevcrew/bb_ui_kit_fe";

export const InternalNotesContext: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const commentHistory = useSelector(getCommentHistorySelector);
  const fetchingCommentHistory = useSelector(fetchingCommentHistorySelector);
  const me = useSelector(meSelector);
  const internalNotes = useSelector(internalNotesSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  const userId = me?.id;
  const showCommentHistory = !!me?.is_bb_user;

  const getCommentHistory = useCallback(
    id => {
      dispatch(getCommentHistoryAction(id));
    },
    [dispatch],
  );

  const getInternalNotes = useCallback(
    id => {
      dispatch(getInternalNotesAction({ id }));
    },
    [dispatch],
  );

  const resetCommentHistory = useCallback(() => {
    dispatch(resetCommentHistoryAction());
  }, [dispatch]);

  const postInternalNotes = useCallback(
    (id, message) => {
      dispatch(postInternalNotesAction({ id, message }));
    },
    [dispatch],
  );

  const getInitialAutocompleteOptions = useCallback(
    payload => {
      dispatch(getAutocompleteOptionsSearchAction(payload));
    },
    [dispatch],
  );

  // eslint-disable-next-line
  const updateAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(updateAutocompleteOptionsAction(payload));
    }, 500),
    [dispatch],
  );

  const deleteInternalNote = useCallback(
    noteId => {
      dispatch(deleteInternalNoteAction(noteId));
    },
    [dispatch],
  );

  return (
    <InternalNotesProvider
      reduxState={{
        commentHistory,
        fetchingCommentHistory,
        showCommentHistory,
        userId,
        internalNotes,
        autocompleteOptions,
      }}
      reduxActions={{
        getCommentHistory,
        getInternalNotes,
        postInternalNotes,
        resetCommentHistory,
        getInitialAutocompleteOptions,
        updateAutocompleteOptions,
        deleteInternalNote,
      }}
    >
      {children}
    </InternalNotesProvider>
  );
};
