import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ChipAction, SimpleSelectSingle } from "@bbdevcrew/bb_ui_kit_fe";

import { updateAlert } from "@store/alerts/actions";

import s from "./AlertsTable.module.less";

import { riskChipTypeMap, getRiskOptions } from "./AlertsTable.helpers";

import { IRiskTriggerProps, IRiskChipProps } from "./AlertsTable.types";
import { AlertRiskLevelType } from "@store/alerts/types";

import { ChevronDownIcon, ExclamationIcon } from "@bbdevcrew/bb_ui_kit_fe";
import classNames from "classnames";

const RiskTrigger: React.FC<IRiskTriggerProps> = ({ value }) => {
  const { t } = useTranslation();

  return (
    <ChipAction
      text={t(`components:listen:alerts:risk:${value}`)}
      _size="xs"
      _type={riskChipTypeMap[value]}
      className={classNames(s.bbAlertsTableChip, {
        [s.bbAlertsTableChipShadow]: value === "none",
      })}
      actionIcon={ChevronDownIcon}
      leftIcon={value === "none" ? null : <ExclamationIcon />}
    />
  );
};

const RiskChip: React.FC<IRiskChipProps> = ({ value, alertId }) => {
  const dispatch = useDispatch();

  const updateAlertRisk = useCallback(
    (riskLevel: AlertRiskLevelType) => {
      dispatch(updateAlert(alertId, { risk_level: riskLevel }));
    },
    [alertId, dispatch],
  );

  return (
    <SimpleSelectSingle
      options={getRiskOptions()}
      value={value}
      trigger={() => <RiskTrigger value={value} />}
      onChange={nextValue => {
        updateAlertRisk(nextValue as AlertRiskLevelType);
      }}
    />
  );
};

export default RiskChip;
