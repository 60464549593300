import React from "react";
import { useTranslation } from "react-i18next";

import s from "../index.module.less";

import PostPreviewEmpty from "@assets/PostPreview/PostPreview.svg";

const PreviewEmptyState = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.bbPostCreationPreviewTitle}>{t("generic:preview")}</div>
      <div className={s.bbPostPreviewEpmtyWrapper}>
        <PostPreviewEmpty />
      </div>
    </>
  );
};

export default PreviewEmptyState;
