import {
  GET_TAGS_LIST,
  GET_TAGS_LIST_SUCCESS,
  GET_TAGS_LIST_FAILURE,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  CREATE_SUBTAG,
  DELETE_SUBTAG,
  UPDATE_SUBTAG,
  RESET_TAG,
} from "./actionTypes";
import { createAction } from "typesafe-actions";
import {
  ICreateSubTagPayload,
  ICreateTagPayload,
  ICreateTagResponse,
  IDeleteTagPayload,
  IDeleteTagResponse,
  ITagsListResponse,
  IUpdateSubTagPayload,
  IUpdateTagPayload,
} from "./types";

export const getTagsListAction = createAction(GET_TAGS_LIST)();
export const getTagsListSuccessAction = createAction(GET_TAGS_LIST_SUCCESS)<ITagsListResponse>();
export const getTagsListFailureAction = createAction(GET_TAGS_LIST_FAILURE)<void>();

export const createTagAction = createAction(CREATE_TAG)<ICreateTagPayload>();
export const createSubTagAction = createAction(CREATE_SUBTAG)<ICreateSubTagPayload>();
export const createTagSuccessAction = createAction(CREATE_TAG_SUCCESS)<ICreateTagResponse>();
export const createTagFailureAction = createAction(CREATE_TAG_FAILURE)<string>();

export const updateTagAction = createAction(UPDATE_TAG)<IUpdateTagPayload>();
export const updateSubTagAction = createAction(UPDATE_SUBTAG)<IUpdateSubTagPayload>();
export const updateTagSuccessAction = createAction(UPDATE_TAG_SUCCESS)<ICreateTagResponse>();
export const updateTagFailureAction = createAction(UPDATE_TAG_FAILURE)<string>();

export const deleteTagAction = createAction(DELETE_TAG)<IDeleteTagPayload>();
export const deleteSubTagAction = createAction(DELETE_SUBTAG)<IDeleteTagPayload>();
export const deleteTagSuccessAction = createAction(DELETE_TAG_SUCCESS)<IDeleteTagResponse>();
export const deleteTagFailureAction = createAction(DELETE_TAG_FAILURE)<string>();

export const resetTagsManagementDataAction = createAction(RESET_TAG)();
