export const TIKTOK_GET_TRENDING_ITEMS = "TIKTOK/GET_TRENDING_ITEMS";
export const TIKTOK_GET_TRENDING_ITEMS_SUCCESS = "TIKTOK/GET_TRENDING_ITEMS_SUCCESS";
export const TIKTOK_GET_TRENDING_ITEMS_FAILURE = "TIKTOK/GET_TRENDING_ITEMS_FAILURE";

export const TIKTOK_GET_TRENDING_HASHTAGS_FILTERS = "TIKTOK/GET_TRENDING_HASHTAGS_FILTERS";
export const TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS =
  "TIKTOK/GET_TRENDING_HASHTAGS_FILTERS_SUCCESS";
export const TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE =
  "TIKTOK/GET_TRENDING_HASHTAGS_FILTERS_FAILURE";

export const TIKTOK_GET_TRENDING_VIDEOS = "TIKTOK/GET_TRENDING_VIDEOS";
export const TIKTOK_GET_TRENDING_VIDEOS_SUCCESS = "TIKTOK/GET_TRENDING_VIDEOS_SUCCESS";
export const TIKTOK_GET_TRENDING_VIDEOS_FAILURE = "TIKTOK/GET_TRENDING_VIDEOS_FAILURE";

export const TIKTOK_GET_TRENDING_SONGS_FILTERS = "TIKTOK/GET_TRENDING_SONGS_FILTERS";
export const TIKTOK_GET_TRENDING_SONGS_FILTERS_SUCCESS =
  "TIKTOK/GET_TRENDING_SONGS_FILTERS_SUCCESS";
export const TIKTOK_GET_TRENDING_SONGS_FILTERS_FAILURE =
  "TIKTOK/GET_TRENDING_SONGS_FILTERS_FAILURE";
