import React from "react";

import { FB_POST_CAPTION_MAX_LENGTH } from ".";

import LinkPreview from "../linkPreview/LinkPreview";
import { ILinkPreviewProps } from "../linkPreview/types";

import FbActions from "@assets/PostPreview/PostPreviewFbActions.svg";

const FBLinkPreview: React.FC<Pick<ILinkPreviewProps, "post" | "linkMetaData">> = ({
  ...props
}) => {
  return (
    <LinkPreview maxMessageLength={FB_POST_CAPTION_MAX_LENGTH} footer={<FbActions />} {...props} />
  );
};

export default FBLinkPreview;
