import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";

import { Panel, SideNav, ISideNavItem, numberToQuantity } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";
import { inboxStatsSelector } from "@store/inboxStats/selectors";

import s from "./SmartInboxMenu.module.less";

import { IInboxTypeItem } from "../types";
import { ISmartInboxMenuProps } from "./types";
import { InboxTypeIconMap, getSmartInboxOptions } from "../helpers";

const SmartInboxMenu: React.FC<ISmartInboxMenuProps> = ({
  onTabChange,
  activeTab,
  getInboxOptions = getSmartInboxOptions,
}) => {
  const { t } = useTranslation();

  const me = useSelector(meSelector);
  const stats = useSelector(inboxStatsSelector);

  const filterHiddenItems = useCallback(
    (items: IInboxTypeItem[]): IInboxTypeItem[] => {
      return items
        .filter(item => (item.hide ? !item.hide(me) : true))
        .map(item => {
          const { ...newItem } = item;
          delete newItem.hide;
          if (item.children) {
            return {
              ...newItem,
              children: filterHiddenItems(item.children),
            };
          }
          return newItem;
        });
    },
    [me],
  );
  const items = useMemo(
    () =>
      filterHiddenItems(
        getInboxOptions(key => ({
          key,
          label: t(`components:inboxMenu:tabs:${key}Label`),
          badge: numberToQuantity(stats?.stats[key] || 0),
          showBadge: true,
          active: key === activeTab,
          onClick: () => onTabChange(key),
          icon: InboxTypeIconMap?.[key],
        })) as IInboxTypeItem[],
      ) as ISideNavItem[],
    [activeTab, stats, filterHiddenItems, getInboxOptions, t, onTabChange],
  );

  return (
    <Panel className={s.bbSmartInboxMenu}>
      <SideNav items={items} />
    </Panel>
  );
};
export default SmartInboxMenu;
