import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  SavedRepliesProvider,
  SavedReplies as SavedRepliesComponent,
} from "@bbdevcrew/bb_ui_kit_fe";
import BrandBastionTags from "@components/_common/CommentCard/brandBastionTags/BBTags";
import CustomTagsList from "@components/_common/CommentCard/customTags/CustomTagsList";
import CommentTagsList from "@components/_common/CommentCard/commentTags/CommentTagsList";
import CustomTagsDropdown from "@components/_common/CommentCard/customTags/CustomTagsDropdown";

import {
  fetchedSavedRepliesVariationsSelector,
  fetchingSavedRepliesVariationsSelector,
  getCreateSavedReplyCustomTagsSelector,
  savedRepliesVariationsSelector,
  searchedSavedRepliesSelector,
} from "@store/replies/selectors";
import { getSavedReplyBBTagsSelector } from "@store/replies/selectors";
import { canManageBBTagsSelector, meSelector } from "@store/me/selectors";
import { getSavedRepliesSelector } from "@store/replies/selectors";
import { postSavedRepliesAction } from "@store/replies/actions";
import { deleteSavedRepliesAction } from "@store/replies/actions";
import { SavedReplyType } from "@store/replies/types";
import { clearSavedReplyAllTagsAction } from "@store/replies/actions";
import { clearSavedRepliesVariationsAction } from "@store/replies/actions";
import { setSavedReplyBBTagsAction } from "@store/replies/actions";
import { setSavedReplyCustomTagsAction } from "@store/replies/actions";
import { deleteReplyBBTagAction, getSavedRepliesVariationsAction } from "@store/replies/actions";
import { postReplyBBTagAction } from "@store/replies/actions";
import { postSavedReplyAction } from "@store/replies/actions";
import { putSavedReplyAction } from "@store/replies/actions";
import { deleteCustomTagFromReplyAction } from "@store/customTags/actions";

import { ISavedReplyPayload } from "@store/replies/types";
import { ISavedRepliesProps, ICommentTag } from "@bbdevcrew/bb_ui_kit_fe";

export const SavedReplies: React.FC<ISavedRepliesProps> = props => {
  const dispatch = useDispatch();
  const me = useSelector(meSelector);

  const savedReplies = useSelector(getSavedRepliesSelector);
  const bbTags = useSelector(getSavedReplyBBTagsSelector);
  const customTags = useSelector(getCreateSavedReplyCustomTagsSelector);
  const canManageBBTags = useSelector(canManageBBTagsSelector);
  const generatingVariations = useSelector(fetchingSavedRepliesVariationsSelector);
  const generatedVariations = useSelector(fetchedSavedRepliesVariationsSelector);
  const savedRepliesVariations = useSelector(savedRepliesVariationsSelector);
  const searchedReplies = useSelector(searchedSavedRepliesSelector);

  const providerValue = React.useMemo(() => {
    const generatedVariationsList = savedRepliesVariations || [];

    return {
      savedReplies,
      bbTags,
      customTags,
      canManageBBTags,
      generatingVariations,
      generatedVariations,
      generatedVariationsList,
      searchedReplies,
      repliesEnabled: !!me?.client?.bb_replies_enabled,
      putSavedReply: (payload: ISavedReplyPayload & { id: string }) =>
        dispatch(putSavedReplyAction({ ...payload, type: payload.type || "custom" })),
      postSavedReply: (payload: ISavedReplyPayload) =>
        dispatch(postSavedReplyAction({ ...payload, type: payload.type || "custom" })),
      postReplyBBTag: (tag: ICommentTag) => dispatch(postReplyBBTagAction(tag)),
      deleteReplyBBTag: (tagId: string) => dispatch(deleteReplyBBTagAction(tagId)),
      deleteCustomTagFromReply: (tagId: string) => dispatch(deleteCustomTagFromReplyAction(tagId)),
      getSavedRepliesVariations: (message: string) =>
        dispatch(getSavedRepliesVariationsAction(message)),
      setSavedReplyCustomTags: (tags: ICommentTag[]) =>
        dispatch(setSavedReplyCustomTagsAction(tags)),
      setSavedReplyBBTags: (tags: ICommentTag[]) => dispatch(setSavedReplyBBTagsAction(tags)),
      clearSavedRepliesVariations: () => dispatch(clearSavedRepliesVariationsAction()),
      clearSavedReplyAllTags: () => dispatch(clearSavedReplyAllTagsAction()),
      deleteSavedReplies: (id: string, type: SavedReplyType) =>
        dispatch(deleteSavedRepliesAction(id, type)),
      fetchSavedReplies: (query?: string, presetQuery?: string, commentId?: string) =>
        dispatch(postSavedRepliesAction(query, presetQuery, commentId)),
    };
  }, [
    savedReplies,
    bbTags,
    customTags,
    canManageBBTags,
    generatingVariations,
    generatedVariations,
    savedRepliesVariations,
    searchedReplies,
    me?.client?.bb_replies_enabled,
    dispatch,
  ]);

  return (
    <SavedRepliesProvider {...providerValue}>
      <SavedRepliesComponent {...props}>
        {{
          BrandBastionTags,
          CustomTagsList,
          CommentTagsList,
          CustomTagsDropdown,
        }}
      </SavedRepliesComponent>
    </SavedRepliesProvider>
  );
};

export default SavedReplies;
