import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { meSelector } from "@store/me/selectors";

import { getDefaultSortOptions } from "@bbdevcrew/bb_ui_kit_fe";

export const useCommentSortingOptions = () => {
  const { t } = useTranslation();
  const me = useSelector(meSelector);
  let options = getDefaultSortOptions(t);

  if (me?.client?.ig_followers_count_enabled) {
    options = options.concat([
      {
        id: "desc|author_followers",
        value: "desc|author_followers",
        label: t("components:comments:sort:mostFollowers"),
      },
      {
        id: "asc|author_followers",
        value: "asc|author_followers",
        label: t("components:comments:sort:leastFollowers"),
      },
    ]);
  }

  return {
    options,
  };
};
