import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tags from "./Tags";
import ChangeSentiment from "./ChangeSentiment";
import PostInsights from "./PostInsights/PostInsights";
import ConversationActions from "./ConversationActions";

import {
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesExplorerLinkSelector,
} from "@store/shortenedSearches/selectors";

import { resetShortenedSearchesAction } from "@store/shortenedSearches/actions";

import s from "./CommentDetails.module.less";

import {
  isPrivateConversation,
  isInstagramPlatform,
  isTiktokPlatform,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ICommentDetails } from "./CommentDetail.type";
import { useReplyingComment } from "@utils/comments";

export const CommentDetails: FC<ICommentDetails> = ({ filters, replyData, listId }) => {
  const dispatch = useDispatch();

  const postType = replyData.post_preview?.type;
  const platformId = replyData.author_detail.platform_type.id;
  const isTTMention =
    isTiktokPlatform(platformId) &&
    (postType === "CAPTION_MENTION" || postType === "HASHTAG_MENTION" || postType === "MENTION");
  const isIGHashtagMention = isInstagramPlatform(platformId) && postType === "HASHTAG_MENTION";
  const replyingComment = useReplyingComment(replyData, listId);
  const isPrivateMessage =
    !!replyData?.post_placement && isPrivateConversation(replyData.post_placement);

  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);
  const shortenedSearchesExplorerLink = useSelector(shortenedSearchesExplorerLinkSelector);

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      window.location.href = shortenedSearchesExplorerLink;
      dispatch(resetShortenedSearchesAction());
    }
    // eslint-disable-next-line
  }, [postShortenedSearchesFetched]);

  return (
    <div className={s.bbCommentDetails}>
      {!!replyingComment && (
        <>
          <ChangeSentiment comment={replyingComment} />
          <Tags
            comment={replyingComment}
            replyData={replyData}
            showMessageTags={isPrivateMessage}
          />
          <ConversationActions comment={replyingComment} filters={filters} />
          {(isTTMention || isIGHashtagMention) && (
            <PostInsights
              commentsCount={replyData.post_preview?.comment_metrics?.total_comments || 0}
              likesCount={replyData.post_preview?.reactions?.like || 0}
              sharesCount={
                isTTMention ? replyData.post_preview?.post_metrics.total_shares || 0 : undefined
              }
            />
          )}
        </>
      )}
    </div>
  );
};
