import cn from "classnames/bind";
import React, { FC } from "react";

import { Toggle } from "@bbdevcrew/bb_ui_kit_fe";

import AiBadge from "../aiBadge/AiBadge";

import s from "./AutomatedHidingMenu.module.less";

interface IAutomatedHidingMenuItemProps {
  id: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  showAIChip?: boolean;
  onChange: () => void;
}

const cx = cn.bind(s);

const AutomatedHidingMenuItem: FC<IAutomatedHidingMenuItemProps> = ({
  id,
  label,
  checked,
  disabled,
  showAIChip = true,
  onChange,
}) => {
  const onToggleChange = () => {
    if (!disabled) onChange();
  };

  return (
    <div className={s.bbAutomatedHidingMenuItem}>
      <div
        className={cx(s.bbAutomatedHidingLabelWrapper, {
          [s.bbAutomatedHidingLabelWrapperDisabled]: disabled,
        })}
        data-cy={`automated-hiding-classifier-${id}`}
      >
        <span className={s.bbAutomatedHidingMenuItemLabel}>{label}</span>
        {showAIChip && <AiBadge />}
      </div>
      <Toggle id={id} _size="md" checked={checked} disabled={disabled} onChange={onToggleChange} />
    </div>
  );
};

export default AutomatedHidingMenuItem;
