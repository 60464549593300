import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useMemo, useEffect, SyntheticEvent } from "react";

import { Button, Dropdown, NestedList, useSearch, useToggle } from "@bbdevcrew/bb_ui_kit_fe";

import { commentTagsSelector } from "@store/commentTags/selectors";

import { getCommentTagsAction } from "@store/commentTags/actions";

import s from "./BBTags.module.less";

import { ICommentTag, IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { bbTagToOption, getTagsFormatted } from "./BBTags.helpers";

import { PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IBrandBastionTagsProps {
  tags: ICommentTag[];
  onAddTag: (tag: ICommentTag) => void;
  onDeleteTag: (id: string) => void;
  type?: "default" | "custom";
}

export default function BrandBastionTags({
  tags,
  type = "default",
  onAddTag,
  onDeleteTag,
}: IBrandBastionTagsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { isToggleSelected, toggleSelected, setToggleSelected } = useToggle(false);

  const commentTags = useSelector(commentTagsSelector).filter(
    commentTag => commentTag.key !== "custom_tags" && commentTag.key !== "ai_tags",
  );

  const getCommentTags = useCallback(() => dispatch(getCommentTagsAction()), [dispatch]);

  const optionsFormatted = useMemo(() => {
    return commentTags.map(tag => bbTagToOption(tag, true));
  }, [commentTags]);

  const { options, SearchInput, searchTerm } = useSearch(optionsFormatted, t("generic:search"));

  const filteredOptions = useMemo(
    () =>
      options.filter(opt => {
        if (opt.options && searchTerm) {
          return (
            (opt.options = opt.options.filter(option =>
              option.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
            )) || opt.options
          );
        }
        return opt;
      }),
    [options, searchTerm],
  );

  const getSelectedTags = (selected?: string) => {
    const existingTags: string[] = [];
    let tag = {};

    const findTags = (opts: IObjectOption[], sel?: string) => {
      opts.forEach(option => {
        if (option.id.split("::")[1] === sel) {
          existingTags.push(option.id);
          tag = option;
        }

        if (option.options) {
          option.options.forEach(childOption => {
            if (childOption.id.split("::")[1] === sel) {
              existingTags.push(childOption.id);
              tag = childOption;
            }

            if (childOption.options) {
              childOption.options.forEach(grandChildOption => {
                if (grandChildOption.id === sel) {
                  existingTags.push(grandChildOption.id);
                  tag = grandChildOption;
                }
              });
            }
          });
        }
      });
    };

    if (selected) {
      findTags(options, selected);
    } else {
      tags.forEach(_tag => {
        findTags(options, _tag.id);
      });
    }

    return { existingTags, tag };
  };

  useEffect(() => {
    setSelectedTags([...getSelectedTags().existingTags]);
    // eslint-disable-next-line
  }, [tags]);

  useEffect(() => {
    if (isToggleSelected) getCommentTags();
    // eslint-disable-next-line
  }, [isToggleSelected]);

  const handleToggleDropdown = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleSelected();
  };

  const onChangeSelection = (selectedValue: string) => {
    const alreadyHasTag = selectedTags.includes(selectedValue);
    const selectedTag = getTagsFormatted(commentTags).find(tag => tag.value === selectedValue);

    if (selectedValue && selectedTag) {
      const { id, label, category } = selectedTag;

      alreadyHasTag ? onDeleteTag(id) : onAddTag({ id, label, category });

      setSelectedTags(currentValues => {
        if (currentValues.includes(selectedValue)) {
          return currentValues.filter(value => !value.includes(selectedValue));
        }
        return [...currentValues, ...getSelectedTags(selectedValue).existingTags];
      });
    }
  };

  const getBBTagsTriggerButton = () => {
    const classnames = classNames(s.bbBBTagsTrigger, {
      [s.bbBBTagsTriggerDefault]: type === "default",
      [s.bbBBTagsTriggerCustom]: type === "custom",
      [s.bbBBTagsTriggerToggleSelected]: isToggleSelected,
    });

    const content =
      type === "custom" ? (
        <span>{t("components:comments:bbTags:customButtonBBTag")}</span>
      ) : (
        <>
          <PlusIcon />
          <span>{t("components:comments:bbTags:bbTag")}</span>
        </>
      );

    return (
      <Button _size="sm" onClick={handleToggleDropdown} className={classnames}>
        {content}
      </Button>
    );
  };

  return (
    <Dropdown
      id="bbTag-dropdown"
      open={isToggleSelected}
      setOpen={setToggleSelected}
      dropdownClassName={classNames(s.bbTagsCustomDropdown, {
        [s.bbTagsCustomDropdownCustomClass]: type === "custom",
      })}
      menuClassName={s.bbTagsDropdownMenu}
      trigger={getBBTagsTriggerButton()}
    >
      <div className={s.bbTagsDropdownContent}>
        <h2 className={s.bbTagsDropdownContentTitle}>{t("components:comments:bbTags:addBBTag")}</h2>
        {SearchInput}
        <div>
          <NestedList
            highlightTerm={searchTerm}
            options={filteredOptions}
            value={selectedTags}
            onItemClick={(_, id) => onChangeSelection(id)}
          />
        </div>
      </div>
    </Dropdown>
  );
}
