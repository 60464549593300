import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { NewsItem } from "../_common/NewsItem";

import { getControlPanelNewsSelector } from "@store/controlPanel/selector";

import { getNewsAction } from "@store/controlPanel/actions";

import s from "./Overview.module.less";

import { HourGlassIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { BbLogoIcon } from "@assets/index";

export const OverviewBBNews: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const news = useSelector(getControlPanelNewsSelector);

  const internalNews = news?.internal_news;
  const comingSoon = news?.coming_soon;

  const showNewsSection = !!internalNews?.length;

  const getNewsData = useCallback(() => dispatch(getNewsAction()), [dispatch]);

  useEffect(() => {
    getNewsData();
  }, [getNewsData]);

  if (!showNewsSection) return null;
  return (
    <div className={s.bbOverviewNews}>
      <div className={s.bbOverviewNewsHeader}>
        <BbLogoIcon />
        <div className={s.bbOverviewNewsHeaderText}>
          <span>{t("components:controlPanel:overview:news:bbNews:title")}</span>
          <span>{t("components:controlPanel:overview:news:bbNews:subTitle")}</span>
        </div>
      </div>
      <div className={s.bbOverviewNewsSection}>
        <div className={s.bbOverviewNewsList}>
          {internalNews?.map(item => <NewsItem key={item.id} {...item} />)}
        </div>
        <div className={s.bbOverviewComingSoon}>
          <div className={s.bbOverviewComingSoonTitle}>
            <div className={s.bbOverviewComingSoonIcon}>
              <HourGlassIcon />
            </div>
            <span>{t("components:controlPanel:overview:news:coming_soon:title")}</span>
          </div>
          <div className={s.bbOverviewNewsList}>
            {comingSoon?.map(item => <NewsItem key={item.id} {...item} />)}
          </div>
          <span className={s.bbOverviewNewsFooter}>
            {t("components:controlPanel:overview:news:coming_soon:releaseNote:text")}

            <a
              target="_blank"
              href="https://brandbastion.stonly.com/kb/guide/en/release-notes-2025-Kgmk64vYod/Steps/4027400"
              rel="noreferrer"
            >
              {t("components:controlPanel:overview:news:coming_soon:releaseNote:link")}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
