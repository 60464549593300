import React from "react";
import { useTranslation } from "react-i18next";

import { ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ManageReportsModal.module.less";

import { IReportsSuffixProps } from "./ManageReportsModal.types";

import { CalendarIcon, UserIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ReportsSuffix: React.FC<IReportsSuffixProps> = ({ reportType, numberOfUsers }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbManagedReportsSuffix}>
      <ChipIcon
        _size="xxs"
        _type="white"
        icon={CalendarIcon}
        iconPosition="left"
        text={t(`components:managed:shareReportButton:reportModal:types:${reportType}`)}
      />
      <ChipIcon
        _size="xxs"
        _type="white"
        iconPosition="left"
        icon={UserIcon}
        text={numberOfUsers}
      />
    </div>
  );
};
