import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Form, FormInstance } from "antd";
import { PostTypesFilter } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

interface IAutomatedHidingPostTypesProps {
  form: FormInstance;
  postTypes: string[];
  setPostTypes: (postTypes: string[]) => void;
  disabled?: boolean;
}

const AutomatedHidingPostTypes: FC<IAutomatedHidingPostTypesProps> = ({
  form,
  postTypes,
  setPostTypes,
  disabled,
}) => {
  const me = useSelector(meSelector);

  const postTypesOptions = me?.post_types?.items || [];

  return (
    <Form size="small" colon={false} form={form} initialValues={{ post_types: postTypes }}>
      <Form.Item shouldUpdate>
        {formIns => {
          setPostTypes(formIns.getFieldValue("post_types") || []);
          return (
            <PostTypesFilter
              options={postTypesOptions}
              disabled={disabled}
              customprops={{ form }}
            />
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default AutomatedHidingPostTypes;
