import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import VirtualList from "rc-virtual-list";

import {
  AlertDanger,
  AlertWarning,
  Loading,
  ScrollView,
  SearchInput,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ProductRow } from "./ProductRow";

import {
  fetchingInstagramAssetInfoSelector,
  fetchedInstagramAssetInfoSuccessSelector,
  fetchedInstagramAssetInfoFailureSelector,
  instagramAssetInfoSelector,
  fetchingInstagramProductsSelector,
  fetchedInstagramProductsSuccessSelector,
  fetchedInstagramProductsFailureSelector,
  instagramProductsSelector,
} from "@store/publishings/selectors";

import { getInstagramAssetInfoAction } from "@store/publishings/actions";

import { IProductTagsProps } from "./ProductTags.types";
import { useTagsContext } from "../../TagsModal.helpers";
import { useFuseSearch } from "@utils/useFuseSearch";
import { IInstagramProduct } from "@store/publishings/types";

import s from "./ProductTags.module.less";

export const ProductTags: React.FC<IProductTagsProps> = ({ assetId, tags }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { addTag, deleteTag, maxTagsReached } = useTagsContext();

  const fetchingAssetInfo = useSelector(fetchingInstagramAssetInfoSelector);
  const fetchedAssetInfoSuccess = useSelector(fetchedInstagramAssetInfoSuccessSelector);
  const fetchedAssetInfoFailure = useSelector(fetchedInstagramAssetInfoFailureSelector);
  const instagramAssetInfo = useSelector(instagramAssetInfoSelector);

  const fetchingProducts = useSelector(fetchingInstagramProductsSelector);
  const fetchedProductsSuccess = useSelector(fetchedInstagramProductsSuccessSelector);
  const fetchedProductsFailure = useSelector(fetchedInstagramProductsFailureSelector);
  const allProducts = useSelector(instagramProductsSelector);

  const [query, setQuery] = useState("");
  const filteredProducts = useFuseSearch<IInstagramProduct>({
    collection: allProducts,
    query,
    fields: ["product_id", "product_name"],
  });

  useEffect(() => {
    dispatch(getInstagramAssetInfoAction(assetId));
  }, [dispatch, assetId]);

  if (fetchedAssetInfoSuccess && !instagramAssetInfo?.shopping_product_tag_eligibility) {
    return (
      <AlertWarning
        className={s.bbProductTagsAlert}
        message={t("components:publish:postCreationModal:mediaTags:products:noEligibility")}
        size="medium"
      />
    );
  }

  if (fetchingAssetInfo || fetchingProducts) {
    return (
      <div className={s.bbProductTagsLoading}>
        <Loading isLoading />
      </div>
    );
  }

  if (!!fetchedProductsFailure || !!fetchedAssetInfoFailure) {
    return (
      <AlertDanger
        className={s.bbProductTagsAlert}
        message={`${fetchedProductsFailure || fetchedAssetInfoFailure}`}
        size="medium"
      />
    );
  }

  return (
    <div className={s.bbProductTags}>
      {fetchedProductsSuccess && (
        <>
          <div className={s.bbProductTagsSearchInput}>
            <SearchInput
              searchTerm={query}
              handleSetSearchTerm={setQuery}
              placeholder={t(
                "components:publish:postCreationModal:mediaTags:products:searchPlaceholder",
              )}
            />
          </div>
          <div>
            <VirtualList
              data={filteredProducts.length > 0 ? filteredProducts : allProducts}
              height={412}
              itemHeight={52}
              itemKey="product_id"
              component={ScrollView}
              className={s.bbProductTagsSearchResults}
            >
              {item => {
                const tagIndex = tags.findIndex(tag => tag.product_id === item.product_id);
                return (
                  <ProductRow
                    key={item.product_id}
                    product={item}
                    onDelete={tagIndex > -1 ? () => deleteTag(tags[tagIndex]) : undefined}
                    onAdd={!maxTagsReached ? addTag : undefined}
                  />
                );
              }}
            </VirtualList>
          </div>
        </>
      )}
    </div>
  );
};
