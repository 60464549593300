export const RESET_WORKFLOWS = "RESET_WORKFLOWS";
export const GET_WORKFLOWS = "GET_WORKFLOWS";
export const GET_WORKFLOWS_SUCCESS = "GET_WORKFLOWS_SUCCESS";
export const GET_WORKFLOWS_FAILURE = "GET_WORKFLOWS_FAILURE";

export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const CREATE_WORKFLOW_SUCCESS = "CREATE_WORKFLOW_SUCCESS";
export const CREATE_WORKFLOW_FAILURE = "CREATE_WORKFLOW_FAILURE";

export const EDIT_WORKFLOW = "EDIT_WORKFLOW";
export const EDIT_WORKFLOW_SUCCESS = "EDIT_WORKFLOW_SUCCESS";
export const EDIT_WORKFLOW_FAILURE = "EDIT_WORKFLOW_FAILURE";

export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS";
export const DELETE_WORKFLOW_FAILURE = "DELETE_WORKFLOW_FAILURE";

export const ENABLE_WORKFLOW = "ENABLE_WORKFLOW";
export const ENABLE_WORKFLOW_SUCCESS = "ENABLE_WORKFLOW_SUCCESS";
export const ENABLE_WORKFLOW_FAILURE = "ENABLE_WORKFLOW_FAILURE";

export const DISABLE_WORKFLOW = "DISABLE_WORKFLOW";
export const DISABLE_WORKFLOW_SUCCESS = "DISABLE_WORKFLOW_SUCCESS";
export const DISABLE_WORKFLOW_FAILURE = "DISABLE_WORKFLOW_FAILURE";

export const SET_WORKFLOW_FORM_DIRTY = "SET_WORKFLOW_FORM_DIRTY";

export const GET_WORKFLOWS_USER_GROUPS = "GET_WORKFLOWS_USER_GROUPS";
export const GET_WORKFLOWS_USER_GROUPS_SUCCESS = "GET_WORKFLOWS_USER_GROUPS_SUCCESS";
export const GET_WORKFLOWS_USER_GROUPS_FAILURE = "GET_WORKFLOWS_USER_GROUPS_FAILURE";

export const GET_WORKFLOWS_SAVED_REPLIES = "GET_WORKFLOWS_SAVED_REPLIES";
export const GET_WORKFLOWS_SAVED_REPLIES_SUCCESS = "GET_WORKFLOWS_SAVED_REPLIES_SUCCESS";
export const GET_WORKFLOWS_SAVED_REPLIES_FAILURE = "GET_WORKFLOWS_SAVED_REPLIES_FAILURE";

export const GET_MATCHING_COMMENTS = "GET_MATCHING_COMMENTS";
export const GET_MATCHING_COMMENTS_SUCCESS = "GET_MATCHING_COMMENTS_SUCCESS";
export const GET_MATCHING_COMMENTS_FAILURE = "GET_MATCHING_COMMENTS_FAILURE";
export const CLEAR_MATCHING_COMMENTS = "CLEAR_MATCHING_COMMENTS";
