/* eslint-disable max-len */
import React from "react";
import { useTranslation } from "react-i18next";

import { Toggle, Tooltip, InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TTDiscloseContentOptions.module.less";

import { ITTDiscloseContentToggleProps } from "./types";

export function TTDiscloseContentToggle({ checked, onChange }: ITTDiscloseContentToggleProps) {
  const { t } = useTranslation();

  return (
    <div className={s.bbDicloseContentSectionTitle}>
      <div className={s.bbDicloseContentSectionTitleLabel}>
        <span className={s.bbDicloseContentSectionTitleLabelText}>
          {t("components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:title")}
        </span>
        <Tooltip
          title={t(
            "components:publish:postCreationModal:ttDisclosureOptions:discloseOptions:discloseOptionsTooltip",
          )}
        >
          <div className={s.bbDicloseContentInfoIcon}>
            <InformationIcon />
          </div>
        </Tooltip>
      </div>
      <Toggle id="disclose_content" name="disclose_content" onChange={onChange} checked={checked} />
    </div>
  );
}
