import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { ITagsState } from "./types";

const initialState: ITagsState = {
  tagsGroups: [],

  fetchingTagsList: false,
  fetchedTagsList: false,
  fetchedTagsListFail: false,

  fetchingTag: false,
  fetchedTag: false,
  fetchedTagFailed: false,
  fetchedTagFailedMessage: "",
};

export const tagsManagementReducer = createReducer<ITagsState, ActionType<typeof actions>>(
  initialState,
)
  .handleAction(actions.getTagsListAction, state =>
    produce(state, draft => {
      draft.fetchingTagsList = true;
      draft.fetchedTagsList = false;
      draft.fetchedTagsListFail = false;
    }),
  )
  .handleAction(actions.getTagsListSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.tagsGroups = action.payload.items;
      draft.fetchingTagsList = false;
      draft.fetchedTagsList = true;
      draft.fetchedTagsListFail = false;
    }),
  )
  .handleAction(actions.getTagsListFailureAction, state =>
    produce(state, draft => {
      draft.tagsGroups = [];
      draft.fetchingTagsList = false;
      draft.fetchedTagsList = false;
      draft.fetchedTagsListFail = true;
    }),
  )
  .handleAction(actions.createTagAction, state =>
    produce(state, draft => {
      draft.fetchingTag = true;
      draft.fetchedTag = false;
      draft.fetchedTagFailed = false;
    }),
  )
  .handleAction(actions.createTagSuccessAction, (state, action) =>
    produce(state, draft => {
      const { type, id, label, isSubTag, ...data } = action.payload;

      const categoryIndex = draft.tagsGroups.findIndex(category => category.key === type);
      if (categoryIndex !== -1) {
        if (isSubTag) {
          const parentTag = draft.tagsGroups[categoryIndex].children.find(tag => {
            return tag.key === data.parent_id;
          });
          if (parentTag) {
            if (!parentTag.children) {
              parentTag.children = [];
            }

            parentTag?.children?.unshift({
              parent_id: data.parent_id || "",
              id,
              name: data.name || "",
              ...data,
            });
          }
        } else {
          draft.tagsGroups[categoryIndex].children.unshift({
            key: id,
            title: label,
            is_editable: true,
            ...data,
          });
        }
      }

      draft.fetchingTag = false;
      draft.fetchedTag = true;
      draft.fetchedTagFailed = false;
      draft.fetchedTagFailedMessage = "";
    }),
  )
  .handleAction(actions.createTagFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingTag = false;
      draft.fetchedTag = false;
      draft.fetchedTagFailed = true;
      draft.fetchedTagFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.updateTagAction, state =>
    produce(state, draft => {
      draft.fetchingTag = true;
      draft.fetchedTag = false;
      draft.fetchedTagFailed = false;
    }),
  )
  .handleAction(actions.updateTagSuccessAction, (state, action) =>
    produce(state, draft => {
      const { type, id, label, isSubTag, ...data } = action.payload;

      const category = draft.tagsGroups.find(c => c.key === type);

      if (category) {
        if (isSubTag) {
          const parentTag = category.children.find(tag => tag.key === data.parent_id);
          if (parentTag) {
            if (!parentTag.children) {
              parentTag.children = [];
            }

            const childIndex = parentTag?.children?.findIndex(ch => ch.id === id);
            if (childIndex !== -1) {
              parentTag.children[childIndex] = {
                ...parentTag.children[childIndex],
                ...data,
              };
            }
          }
        } else {
          const tagIndex = category.children.findIndex(tag => tag.key === id);

          if (tagIndex !== -1) {
            category.children[tagIndex] = {
              ...category.children[tagIndex],
              title: label,
              ...data,
            };
          }
        }
      }
      draft.fetchingTag = false;
      draft.fetchedTag = true;
      draft.fetchedTagFailed = false;
    }),
  )
  .handleAction(actions.updateTagFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingTag = false;
      draft.fetchedTag = false;
      draft.fetchedTagFailed = true;
      draft.fetchedTagFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.deleteTagAction, state =>
    produce(state, draft => {
      draft.fetchingTag = true;
      draft.fetchedTag = false;
      draft.fetchedTagFailed = false;
    }),
  )
  .handleAction(actions.deleteTagSuccessAction, (state, action) =>
    produce(state, draft => {
      const { type, id, isSubTag, parent_id } = action.payload;

      const category = draft.tagsGroups.find(c => c.key === type);

      if (category) {
        if (isSubTag) {
          const parentTag = category.children.find(tag => tag.key === parent_id);
          if (parentTag) {
            parentTag.children = parentTag.children?.filter(ch => ch.id !== id);
          }
        } else category.children = category.children.filter(tag => tag.key !== id);
      }

      draft.fetchingTag = false;
      draft.fetchedTag = true;
      draft.fetchedTagFailed = false;
    }),
  )
  .handleAction(actions.deleteTagFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingTag = false;
      draft.fetchedTag = false;
      draft.fetchedTagFailed = true;
      draft.fetchedTagFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.resetTagsManagementDataAction, state =>
    produce(state, draft => {
      draft.fetchingTagsList = false;
      draft.fetchedTagsList = false;
      draft.fetchedTagsListFail = false;
      draft.fetchingTag = false;
      draft.fetchedTag = false;
      draft.fetchedTagFailed = false;
      draft.fetchedTagFailedMessage = "";
    }),
  );
