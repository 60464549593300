import React, { FC, useMemo } from "react";
import classNames from "classnames";

import s from "./UsageMeter.module.less";

import { UsageMeterVariantType, IUsageMeterProps } from "./UsageMeter.types";

export const UsageMeter: FC<IUsageMeterProps> = ({ title, current, max, className }) => {
  const percentage = useMemo(() => {
    return Math.min(Math.floor((current / max) * 100), 100);
  }, [current, max]);

  const variant = useMemo((): UsageMeterVariantType => {
    if (percentage === 100) return "danger";
    if (percentage >= 71 && percentage < 100) return "warning";
    return "normal";
  }, [percentage]);

  return (
    <div className={classNames(s.bbUsageMeter, className)}>
      <div className={s.bbUsageMeterHeader}>
        <div className={s.bbUsageMeterTitle}>{title}</div>
        <div className={s.bbUsageMeterCount}>
          {current} / {max}
        </div>
      </div>
      <div className={s.bbUsageMeterBar}>
        <div className={s.bbUsageMeterBarBackground}></div>
        <div
          className={classNames(s.bbUsageMeterBarFill, {
            [s.bbUsageMeterBarFillNormal]: variant === "normal",
            [s.bbUsageMeterBarFillWarning]: variant === "warning",
            [s.bbUsageMeterBarFillDanger]: variant === "danger",
          })}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};
