import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { pdfReportSelector } from "@store/reports/selectors";

import { generateEmailReportDocumentAction } from "@store/reports/actions";

import { forceFileDownload } from "@components/managed/Transparency/ShareReportButton/ShareReportButton.helpers"; // eslint-disable-line max-len
import { REPORT_PATH_MAPS } from "./Report.helpers";

export const Report: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const id = searchParams.get("id");

  const { filename, url, report } = useSelector(pdfReportSelector(id ?? ""));

  useEffect(() => {
    if (id) {
      dispatch(
        generateEmailReportDocumentAction({
          id,
        }),
      );
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (filename && url && report) {
      forceFileDownload(url, filename);
      if (report.creation_source_meta) {
        const { ui_path, search_id } = report.creation_source_meta;
        const mappedPath = REPORT_PATH_MAPS[ui_path];

        if (mappedPath) {
          const separator = mappedPath.includes("?") ? "&" : "?";
          const newPath = `${process.env.BASE_URL}/${mappedPath}${
            search_id ? `${separator}id=${search_id}` : ""
          }`;
          setTimeout(() => {
            window.location.href = newPath;
          }, 1000);
        }
      }
    }
  }, [filename, url, report]);

  return null;
};
