import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { Divider, Toggle } from "@bbdevcrew/bb_ui_kit_fe";
import ReplyPubliclyAction from "./ReplyPubliclyWorkflowAction";
import ReplyPrivatelyAction from "./ReplyPrivatelyWorkflowAction";

import s from "./ReplyWorkflowAction.module.less";

import { MIN_MINUTES } from "./ReplyWorkflowAction.helpers";
import { IReplyWorkflowActionProps } from "./ReplyWorkflowAction.types";

import { ReplyIcon, SendIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ReplyPrivatelyAndPubliclyWorkflowAction = ({ form, item }: IReplyWorkflowActionProps) => {
  const { t } = useTranslation();

  const [isPublicOn, setIsPublicOn] = useState(false);
  const [isPrivateOn, setIsPrivateOn] = useState(false);

  useEffect(() => {
    if (item?.reply) {
      setIsPublicOn(!!item?.reply?.reply_types?.includes("public"));
      setIsPrivateOn(!!item?.reply?.reply_types?.includes("private"));
    }
    // eslint-disable-next-line
  }, [item.reply]);

  // Case when you're coming back to the review action step (from the step 2)
  useEffect(() => {
    if (form.getFieldValue("action")) {
      setIsPublicOn(form.getFieldValue("action").reply_template_id !== undefined);
      setIsPrivateOn(form.getFieldValue("action").private_reply_template_id !== undefined);
    }
    // eslint-disable-next-line
  }, []);

  const onChangePublicToggle = () => {
    setIsPublicOn(!isPublicOn);

    if (!isPublicOn) {
      form.setFieldsValue({
        action: {
          reply_template_id: undefined,
        },
      });
    }
  };

  const onChangePrivateToggle = () => {
    setIsPrivateOn(!isPrivateOn);

    if (!isPrivateOn) {
      form.setFieldsValue({
        action: {
          private_reply_template_id: undefined,
          delay_private_reply: MIN_MINUTES,
          reply_only_first_private_message: true,
        },
      });
    }
  };

  return (
    <div className={s.bbReplyWorkflowAction}>
      <div className={s.bbReplyWorkflowActionTitle}>
        <div className={s.bbReplyWorkflowActionTitleText}>
          {t("pages:workflows:form:actions:reply:publicToPrivate:title")}
        </div>
      </div>

      {
        <div className={s.bbReplyWorkflowToggleWrapper}>
          <div className={s.bbReplyWorkflowActionTitle}>
            <div className={s.bbReplyWorkflowActionTitleIcon}>
              <ReplyIcon />
            </div>
            <div className={s.bbReplyWorkflowActionTitleText}>
              {t("pages:workflows:form:actions:reply:publicToPrivate:public")}
            </div>
          </div>

          <Toggle id="public" _size="md" checked={isPublicOn} onChange={onChangePublicToggle} />
        </div>
      }
      {isPublicOn && <ReplyPubliclyAction hideTitle />}

      <Divider className={s.bbWorkflowsDivider} />

      {
        <div className={s.bbReplyWorkflowToggleWrapper}>
          <div className={s.bbReplyWorkflowActionTitle}>
            <div className={s.bbReplyWorkflowActionTitleIcon}>
              <SendIcon />
            </div>
            <div className={s.bbReplyWorkflowActionTitleText}>
              {t("pages:workflows:form:actions:reply:publicToPrivate:private")}
            </div>
          </div>

          <Toggle id="private" _size="md" checked={isPrivateOn} onChange={onChangePrivateToggle} />
        </div>
      }
      {isPrivateOn && <ReplyPrivatelyAction hideTitle form={form} item={item} />}
    </div>
  );
};

export default ReplyPrivatelyAndPubliclyWorkflowAction;
