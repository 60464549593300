import React, { useState, ChangeEvent } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Tooltip, Checkbox } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./PostBased.module.less";

import { IDynamicFilter, IInjectedCustomProps } from "@bbdevcrew/bb_ui_kit_fe";

import { HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

// eslint-disable-next-line
interface IPostBasedProps extends IDynamicFilter<any>, IInjectedCustomProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

export function PostBased(props: IPostBasedProps) {
  const [isChecked, setIsChecked] = useState(props.checked);

  const { name } = props.customprops;
  const { t } = useTranslation();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event.target.checked);

    setIsChecked(event.target.checked);
  };

  return (
    <div className={s.bbPostBasedNotification}>
      <Checkbox id={name} name={name} _size="md" checked={isChecked} onChange={onChange} />
      <label htmlFor={name}>
        <span className={s.bbPostBasedNotificationTitle}>
          {t("components:notificationsPanel:modals:create:isPostBased:label")}
        </span>
        <Tooltip
          title={
            <Trans
              i18nKey={"components:notificationsPanel:modals:create:isPostBased:tooltip"}
              components={{
                br: <br />,
              }}
            />
          }
        >
          <span className={s.bbHelpIcon}>
            <HelpIcon />
          </span>
        </Tooltip>
      </label>
    </div>
  );
}
