import React from "react";

import s from "./AIModal.module.less";

import { IAIModalIconProps } from "./AIModal.types";

export const AIModalIcon: React.FC<IAIModalIconProps> = ({ onClick, className, src }) => {
  return (
    <div className={className} onClick={onClick}>
      <img src={src} data-cy="ai-modal-icon" />
      <div className={s.bbAIIconIndicator}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
