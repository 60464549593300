import React, { FC } from "react";
import classNames from "classnames/bind";

import { Icon, UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyPhrase.module.less";

import { IReplyPhrase, IReplyWorkflowInfo } from "@bbdevcrew/bb_ui_kit_fe";
import { formatWorkflow } from "../replyPhrase/ReplyPhrase.helpers";

import { FlowIcon, BbLogoColorIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IReplyPhraseProps {
  replyPhrase?: IReplyPhrase;
  className?: string;
  workflow?: IReplyWorkflowInfo;
}

export const ReplyPhrase: FC<IReplyPhraseProps> = ({ workflow, replyPhrase, className }) => (
  <>
    {replyPhrase && (
      <p className={classNames(s.bbReplyPhrase, className)}>
        {workflow ? (
          <span className={s.bbWorkflowIcon}>
            <FlowIcon />
          </span>
        ) : replyPhrase.platform_user ? (
          <UserAvatar
            className={s.bbReplyPhraseAvatar}
            img={replyPhrase.platform_user?.avatar}
            lastName={replyPhrase.platform_user?.last_name}
            firstName={replyPhrase.platform_user?.first_name}
          />
        ) : (
          <Icon _size="lg" icon={BbLogoColorIcon} className={s.bbReplyPhraseIcon} />
        )}
        {workflow
          ? formatWorkflow(replyPhrase.phrase, workflow?.category || "reply")
          : replyPhrase.phrase}
      </p>
    )}
  </>
);
