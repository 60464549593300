import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";

import {
  Input,
  RadioGroup,
  KeywordsFilter,
  KeywordsDropdownBasic,
  SelectStyledMulti,
  SearchInput,
  IClearableTriggerProps,
  ClearableTrigger,
  handleMultiselectState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";

import { compareTypesSelector } from "@store/me/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./CompareProjectForm.module.less";

import { FormInstance } from "antd/lib/form/Form";
import { IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { ICompareProject } from "@store/compareProjects/types";

interface ICompareProjectFormProps {
  form: FormInstance<ICompareProject>;
}

const CompareProjectForm: FC<ICompareProjectFormProps> = ({ form }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const compareTypeT = "components:comparesPanel:projectForm:compareType";

  const [searchTerm, setSearchTerm] = useState("");
  const [compareType, setCompareType] = useState<string>();
  const [selectedCompareValues, setSelectedCompareValues] = useState<string[]>([]);

  const compareTypeOptions = useSelector(compareTypesSelector);
  const compareValueOptions = useSelector(autocompleteOptionsSelector);

  const compareValuesOptions =
    (compareValueOptions[
      form.getFieldValue("compare_type") as keyof typeof compareValueOptions
    ] as IObjectOption[]) || [];

  const fetchCompareValues = useCallback(() => {
    if (form.getFieldValue("compare_type") !== "keyword") {
      dispatch(
        getAutocompleteOptionsAction({
          field: form.getFieldValue("compare_type"),
          query: searchTerm,
        }),
      );
    }
  }, [dispatch, form, searchTerm]);

  const onFormValuesChange = (changedValues: ICompareProject) => {
    if (changedValues.compare_type) {
      setSelectedCompareValues([]);
      form.resetFields(["compare_values"]);

      // We don't need to fetch any options for keywords
      if (changedValues.compare_type !== "keyword") {
        fetchCompareValues();
      }
    }
  };

  const onCompareTypeChange = (value: string) => {
    setCompareType(value);
    setSelectedCompareValues([]);
  };

  useEffect(() => {
    fetchCompareValues();
  }, [fetchCompareValues]);

  useEffect(() => {
    const compareValues = form.getFieldValue("compare_values");
    if (compareValues?.length) setSelectedCompareValues(compareValues);
    // eslint-disable-next-line
  }, [form.getFieldValue("compare_values")]);

  const onCompareValuesChange = (selectedValue: string) => {
    const newState = handleMultiselectState(
      compareValuesOptions,
      selectedCompareValues,
      selectedValue,
    );
    setSelectedCompareValues(newState || []);
    form.setFieldValue("compare_values", newState || []);
  };

  const _onClear = () => setSelectedCompareValues([]);

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    const tooltipElement = selectedCompareValues.map(value => (
      <span key={value} style={{ display: "block" }}>
        {value}
      </span>
    ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
      value: selectedCompareValues,
    });
  };

  return (
    <Form
      form={form}
      size="small"
      colon={false}
      layout="vertical"
      initialValues={{ compare_values: [] }}
      className={s.bbCompareProjectForm}
      onValuesChange={onFormValuesChange}
    >
      <Form.Item
        name="name"
        label={t("components:comparesPanel:projectForm:projectName:label")}
        rules={[
          {
            required: true,
            message: t("components:comparesPanel:projectForm:projectName:requiredMsg"),
          },
          {
            max: 60,
            message: t("components:comparesPanel:projectForm:projectName:maxCharMsg"),
          },
        ]}
      >
        <Input
          _size="md"
          placeholder={t("components:comparesPanel:projectForm:projectName:placeholder")}
        />
      </Form.Item>
      <Form.Item name="compare_type" label={t(`${compareTypeT}:label`)}>
        <RadioGroup value={compareType} onChange={onCompareTypeChange}>
          {(compareTypeOptions || []).map(({ id, label }) => (
            <RadioGroup.Button _size="sm" key={id} value={id} data-cy={id}>
              {label}
            </RadioGroup.Button>
          ))}
        </RadioGroup>
      </Form.Item>

      <Form.Item
        name="compare_values"
        rules={[
          {
            validator: () => {
              return !selectedCompareValues.length
                ? Promise.reject(new Error(t(`${compareTypeT}:reqMsg`)))
                : selectedCompareValues.length > 10
                  ? Promise.reject(new Error(t(`${compareTypeT}:maxLenMsg`)))
                  : Promise.resolve();
            },
          },
        ]}
      >
        {form.getFieldValue("compare_type") === "keyword" ? (
          <KeywordsFilter
            menuClassName={s.bbKeywordsMenu}
            showTranslationSwitch={false}
            DropdownVariation={KeywordsDropdownBasic}
            customprops={{
              form: form,
              onFocus: () => {}, // eslint-disable-line
              name: "compare_values",
            }}
          />
        ) : (
          <SelectStyledMulti
            id="compare_values"
            name="compare_values"
            _size="md"
            dropdownMatchSelectWidth
            options={compareValuesOptions}
            value={selectedCompareValues}
            onChange={onCompareValuesChange}
            TriggerVariation={TriggerWithTooltip}
            placeholder={t("generic:select")}
            DropdownHeader={
              <SearchInput searchTerm={searchTerm} handleSetSearchTerm={setSearchTerm} />
            }
          />
        )}
      </Form.Item>
    </Form>
  );
};

export default CompareProjectForm;
