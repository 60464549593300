import React from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Row, Col, Grid } from "antd";
import { Chip, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import StatusChip from "./StatusChip";
import RiskChip from "./RiskChip";
import AlertNotification from "./AlertNotification";
import { PostPreviewTooltip } from "@components/_common/PostPreviewTooltip/PostPreviewTooltip";

import s from "./AlertsTable.module.less";

import { IAlertsTableRowProps } from "./AlertsTable.types";

import { PlatformTypeIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AlertsTableRow: React.FC<IAlertsTableRowProps> = ({ data: alert, onEdit, index }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;

  if (isMobile) {
    return (
      <Row
        className={classNames(s.bbAlertsTableRow, index % 2 === 0 && s.bbAlertsTableRowEven)}
        key={index}
      >
        <Col span={14} className={s.bbAlertsTableRowCell}>
          <AlertNotification alert={alert} />
        </Col>
        <Col span={10} className={s.bbAlertsTableRowCell}>
          {dayjs(alert.sent_at).format("DD MMM YYYY hh:mm A")}
        </Col>
      </Row>
    );
  }
  return (
    <Row
      className={classNames(s.bbAlertsTableRow, index % 2 === 0 && s.bbAlertsTableRowEven)}
      key={index}
    >
      <Col span={4} className={s.bbAlertsTableRowCell}>
        <AlertNotification alert={alert} onEdit={onEdit} />
      </Col>
      <Col span={3} className={s.bbAlertsTableRowCell}>
        {dayjs(alert.sent_at).format("DD MMM YYYY hh:mm A")}
      </Col>
      <Col span={3} className={s.bbAlertsTableRowCell}>
        <StatusChip value={alert.status} alertId={alert.id} />
      </Col>
      <Col span={3} className={s.bbAlertsTableRowCell}>
        <RiskChip value={alert.risk_level} alertId={alert.id} />
      </Col>
      <Col span={2} className={s.bbAlertsTableRowCell}>
        {alert.volume}
      </Col>
      <Col span={3} className={s.bbAlertsTableRowCell}>
        {alert.posts.length > 0 ? (
          <>
            <PostPreviewTooltip placement="left" post={alert.posts[0]}>
              {alert.posts[0].picture ? (
                <img src={alert.posts[0].picture} className={s.bbAlertsTableRowPostPreview} />
              ) : (
                <div className={s.bbAlertsTableRowPostPreviewPlaceholder}>
                  <span>{t("generic:noImage")}</span>
                </div>
              )}
            </PostPreviewTooltip>
            {alert.posts.length > 1 && (
              <Tooltip
                title={
                  <>
                    {alert.posts.slice(1).map((post, i) => (
                      <React.Fragment key={post.id}>
                        {i > 0 && <br />}
                        {post.id}
                      </React.Fragment>
                    ))}
                  </>
                }
              >
                <span className={classNames(s.bbMoreItems, s.bbMoreItemsTooltip)}>
                  +{alert.posts.length - 1}
                </span>
              </Tooltip>
            )}
          </>
        ) : (
          t("generic:not_applicable")
        )}
      </Col>
      <Col span={3} className={s.bbAlertsTableRowCell}>
        <div className={s.bbAlertsTableRowPlatforms}>
          {alert.platform_types.slice(0, 2).map(platform => {
            const Icon = PlatformTypeIcon[platform as keyof typeof PlatformTypeIcon];
            return Icon && <Icon key={platform} />;
          })}
          {alert.platform_types.length > 2 && (
            <Tooltip
              title={
                <>
                  {alert.platform_types.slice(2).map((platform, i) => (
                    <React.Fragment key={platform}>
                      {i > 0 && <br />}
                      {t(`generic:platform_names:${platform}`)}
                    </React.Fragment>
                  ))}
                </>
              }
            >
              <span className={classNames(s.bbMoreItems, s.bbMoreItemsTooltip)}>
                +{alert.platform_types.length - 2}
              </span>
            </Tooltip>
          )}
        </div>
      </Col>
      <Col span={3} className={s.bbAlertsTableRowCell}>
        {alert.impact_area && alert.impact_area.length > 0 && (
          <Chip
            text={alert.impact_area[0]}
            _type="white"
            _size="xs"
            className={s.bbAlertsTableChipShadow}
          />
        )}
        {alert.impact_area && alert.impact_area.length > 1 && (
          <span className={s.bbMoreItems}>+{alert.impact_area.length - 1}</span>
        )}
      </Col>
    </Row>
  );
};

export default AlertsTableRow;
