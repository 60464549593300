import React from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./MobileFiltersButton.module.less";

import { useFilterContext } from "@components/_common/AppLayoutContainer/FilterContext";

import { FilterSlidersIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const MobileFiltersButton = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext();
  const screens = Grid.useBreakpoint();
  const isMobile = screens.md === false;

  if (!isMobile) return null;

  return (
    <Button
      _size="sm"
      _type="on-surface"
      onClick={filterContext?.showFilterModal}
      className={s.bbMobileFiltersButton}
    >
      <FilterSlidersIcon />
      {t("generic:filters")}
    </Button>
  );
};
