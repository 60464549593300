import React, { FC } from "react";
import classNames from "classnames";

import { Table } from "@bbdevcrew/bb_ui_kit_fe";
import { ProfilesTablePrefix } from "./ProfilesTablePrefix";
import FollowersTableRow from "./FollowersTableRow";
import ImpressionsPlatformTableRow from "./ImpressionsPlatformTableRow";
import ImpressionsProfileTableRow from "./ImpressionsProfileTableRow";

import s from "./ProfilesTable.module.less";

import {
  IProfilesEngagementsPlatformTableProps,
  IProfilesFollowersTableProps,
  IProfilesImpressionsPlatformTableProps,
  IProfilesImpressionsProfileTableProps,
} from "./ProfilesTable.types";
import { DEFAULT_PAGE_SIZE, useProfilesTableLogic } from "./ProfilesTable.helpers";
import {
  IDashboardEngagementsRow,
  IDashboardFollowersRow,
  IDashboardImpressionsPlatform,
  IDashboardImpressionsRow,
} from "@store/dashboard/types";
import EngagementsTableRow from "./EngagementsTableRow";

export const ProfilesFollowersTable: FC<IProfilesFollowersTableProps> = ({
  data,
  total,
  pagination,
  ...props
}) => {
  const { tableData, onSort, onChangePage, pageSize, currentPage } = useProfilesTableLogic({
    data,
    initialSort: {
      order: "desc",
      field: "followers_count",
    },
  });

  return (
    <div
      className={classNames(s.bbProfilesWrapper, {
        [s.bbProfilesWrapperMultiPages]: pageSize < DEFAULT_PAGE_SIZE,
      })}
      data-cy="followers-table"
    >
      <Table<IDashboardFollowersRow>
        data={tableData}
        onSort={onSort}
        tableRow={FollowersTableRow}
        pagination={
          data.length <= DEFAULT_PAGE_SIZE
            ? undefined
            : {
                total: data.length,
                pageSize,
                currentPage,
                onChangePage,
                ...pagination,
              }
        }
        rowsPrefix={<ProfilesTablePrefix value={total} type="followers" />}
        stonlySelector="intelligence-followers_table"
        {...props}
      />
    </div>
  );
};

export const ProfilesImpressionsPlatformTable: FC<IProfilesImpressionsPlatformTableProps> = ({
  data,
  total,
  ...props
}) => {
  const { tableData, onSort } = useProfilesTableLogic({
    data,
    initialSort: {
      order: "desc",
      field: "total_impressions",
    },
  });

  return (
    <div className={s.bbProfilesWrapper} data-cy="profiles-table">
      <Table<IDashboardImpressionsPlatform>
        data={tableData}
        onSort={onSort}
        tableRow={ImpressionsPlatformTableRow}
        rowsPrefix={<ProfilesTablePrefix value={total} type="impressions-platform" />}
        stonlySelector="intelligence-impressions_table"
        {...props}
        pagination={undefined}
      />
    </div>
  );
};

export const ProfilesImpressionsProfileTable: FC<IProfilesImpressionsProfileTableProps> = ({
  data,
  total,
  pagination,
  ...props
}) => {
  const { tableData, onSort, onChangePage, pageSize, currentPage } = useProfilesTableLogic({
    data,
    initialSort: {
      order: "desc",
      field: "total_impressions",
    },
  });

  return (
    <div
      className={classNames(s.bbProfilesWrapper, {
        [s.bbProfilesWrapperMultiPages]: pageSize < DEFAULT_PAGE_SIZE,
      })}
      data-cy="profiles-table"
    >
      <Table<IDashboardImpressionsRow>
        data={tableData}
        onSort={onSort}
        tableRow={ImpressionsProfileTableRow}
        pagination={
          data.length <= DEFAULT_PAGE_SIZE
            ? undefined
            : {
                total: data.length,
                pageSize,
                currentPage,
                onChangePage,
                ...pagination,
              }
        }
        rowsPrefix={<ProfilesTablePrefix value={total} type="impressions-profile" />}
        stonlySelector="intelligence-impressions_table"
        {...props}
      />
    </div>
  );
};

export const ProfilesEngagementsTable: FC<IProfilesEngagementsPlatformTableProps> = ({
  data,
  total,
  pagination,
  ...props
}) => {
  const { tableData, onSort, onChangePage, pageSize, currentPage } = useProfilesTableLogic({
    data,
    initialSort: {
      order: "desc",
      field: "er_impressions",
    },
  });

  return (
    <div
      className={classNames(s.bbProfilesWrapper, {
        [s.bbProfilesWrapperMultiPages]: pageSize < DEFAULT_PAGE_SIZE,
      })}
      data-cy="profiles-table"
    >
      <Table<IDashboardEngagementsRow>
        data={tableData}
        onSort={onSort}
        tableRow={EngagementsTableRow}
        pagination={
          data.length <= DEFAULT_PAGE_SIZE
            ? undefined
            : {
                total: data.length,
                pageSize,
                currentPage,
                onChangePage,
                ...pagination,
              }
        }
        rowsPrefix={<ProfilesTablePrefix value={total} type="engagements" />}
        stonlySelector="intelligence-impressions_table"
        {...props}
      />
    </div>
  );
};
