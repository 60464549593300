import React, { useCallback, useEffect, useMemo, useState } from "react";

import FileContextModal from "../FileContextModal";
import ImageView from "./ImageView";
import TagsView from "./TagsView";

import {
  MAX_TAGS_PER_POST,
  TagsContext,
  collaboratorsFilter,
  findProductTag,
  findUserTag,
  isProductTag,
  isUserTag,
} from "./TagsModal.helpers";

import {
  ITagsModalProps,
  IProductTag,
  IUserTag,
  TagCategoryType,
  AnyTagType,
} from "./TagsModal.types";

export const TagsModal: React.FC<ITagsModalProps> = ({
  tags,
  assetId,
  previewSrc,
  previewType,
  isReel = false,
  onSave,
  onCancel,
  visible,
  tagCategory = "user",
  showOnlyActiveCategory,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState<TagCategoryType>(tagCategory);
  const [coors, setCoors] = useState<[number, number] | false>(false);
  const [offset, setOffset] = useState<DOMRect>({} as DOMRect);
  const [localTags, setLocalTags] = useState<AnyTagType[]>(tags);

  const maxTagsReached = localTags.length >= MAX_TAGS_PER_POST;

  const addTag = useCallback(
    (tag: AnyTagType) => {
      const nextTags = [...localTags];

      const index = localTags.findIndex(
        isUserTag(tag) ? findUserTag(tag as IUserTag) : findProductTag(tag as IProductTag),
      );

      if (index > -1) {
        nextTags[index] = tag;
      } else {
        nextTags.push(tag);
      }

      setLocalTags(nextTags);
      if (showOnlyActiveCategory) {
        setActiveTab(isUserTag(tag) ? "user" : "product");
      }
    },
    [localTags, showOnlyActiveCategory],
  );

  const deleteTag = useCallback(
    (tag: AnyTagType) => {
      setLocalTags(localTags.filter(currentTag => currentTag !== tag));
    },
    [localTags],
  );

  const visibleTags = useMemo(
    () =>
      (showOnlyActiveCategory
        ? localTags.filter(tag => {
            return (
              (activeTab === "user" && isUserTag(tag)) ||
              (activeTab === "product" && isProductTag(tag))
            );
          })
        : localTags
      ).filter(collaboratorsFilter),
    [activeTab, localTags, showOnlyActiveCategory],
  );

  useEffect(() => {
    setLocalTags(tags);
  }, [tags]);

  useEffect(() => {
    if (visible) {
      setActiveTab(tagCategory);
    }
  }, [tagCategory, visible]);

  const tagsContext = {
    coors,
    setCoors,
    offset,
    setOffset,
    addTag,
    deleteTag,
    maxTagsReached,
    isReel,
  };

  return (
    <TagsContext.Provider value={tagsContext}>
      <FileContextModal onCancel={onCancel} open={visible} {...props}>
        <ImageView previewSrc={previewSrc} previewType={previewType} tags={visibleTags} />
        <TagsView
          assetId={assetId}
          tags={localTags}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          onSave={onSave}
          onCancel={onCancel}
        />
      </FileContextModal>
    </TagsContext.Provider>
  );
};
