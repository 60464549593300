import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import {
  Modal,
  Input,
  FormErrorMessage,
  RadioGroup,
  SelectStyledMulti,
  Divider,
  ScrollView,
  Button,
  Filters,
  SelectStyled,
  ClearableTrigger,
  SearchInput,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import CheckboxList from "../../../_common/CheckboxList";
import AmPmSwitch from "@components/_common/amPmSwitch/AmPmSwitch";
import { SingleMail } from "@components/_common/SingleMail/SingleMail";
import Timezone from "@components/_common/Timezone";

import {
  creatingScheduledReportSelector,
  updatingScheduledReportSelector,
} from "@store/reports/selectors";
import { usersSelector } from "@store/users/selectors";
import { filterDataSelector } from "@store/filters/selectors";
import { clientDataSelector } from "@store/me/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";

import s from "./ScheduledReportModal.module.less";

import { AmPmType } from "@store/notifications/types";
import { IScheduledReport } from "@store/reports/types";
import { getFetchValuesFieldName } from "@utils/filters";
import { checkboxListRequired } from "../../../_common/CheckboxList";
import { IScheduledReportModalProps } from "./ScheduledReportModal.types";
import {
  IFilters,
  IFormHandler,
  IClearableTriggerProps,
  IGetAutocompleteOptionsPayload,
  IFormFilterRequest,
  debounce,
  handleMultiselectState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { useGetDefaultFiltersConfig } from "@components/_common/AppFilter/config";
import { reportSections } from "../ShareReportButton/ShareReportButton.helpers";
import { generateTimeOptions, SCHEDULED_REPORT_TYPES } from "./ScheduledReportModal.helpers";
import { getSectionValues } from "@components/_common/DownloadReportModal/DownloadReportModal.helpers"; // eslint-disable-line max-len

import { ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ScheduledReportModal: React.FC<IScheduledReportModalProps> = ({
  open,
  mode,
  onCancel,
  onFinish,
  initialValues,
  reportDefaultValue,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const filtersRef = useRef<IFormHandler>(null);
  const [filtersForm] = Form.useForm<IFormFilterRequest>();
  const filtersConfig = useGetDefaultFiltersConfig(t, false, filtersForm);

  const [report, setReport] = useState<IScheduledReport | undefined>();
  const [type, setType] = useState(initialValues?.type || SCHEDULED_REPORT_TYPES[0]);
  const [meridiem, setMeridiem] = useState<AmPmType>(initialValues?.meridiem || "AM");
  const [isSingleEmailChecked, setSingleEmailChecked] = useState(!!initialValues?.is_single_email);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const users = useSelector(usersSelector);
  const clientData = useSelector(clientDataSelector);
  const filters = useSelector(filterDataSelector);
  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const creatingReport = useSelector(creatingScheduledReportSelector);
  const updatingReport = useSelector(updatingScheduledReportSelector);

  const usersOptions = users.map(user => ({
    id: user.id,
    value: user.id,
    label: user.email,
  }));

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    if (open) {
      setReport(initialValues);

      if (mode === "edit") {
        setType(initialValues?.type || SCHEDULED_REPORT_TYPES[0]);
        setMeridiem(initialValues?.meridiem || "AM");
        setSingleEmailChecked(!!initialValues?.is_single_email);
        setSelectedUsers(initialValues?.user_ids || []);
        form.setFieldsValue({
          ...initialValues,
          sections: getSectionValues(t, reportSections, initialValues?.sections).sections,
        });
      }
    } else {
      form.resetFields();
      setReport(reportDefaultValue);
      form.setFieldsValue({
        ...reportDefaultValue,
        sections: getSectionValues(t, reportSections, initialValues?.sections).sections,
      });
    }
    // eslint-disable-next-line
  }, [form, open]);

  const onClose = () => {
    onCancel();
    setType(report?.type || SCHEDULED_REPORT_TYPES[0]);
    setMeridiem(report?.meridiem || "AM");
    setSingleEmailChecked(!!report?.is_single_email);
  };

  const getCustomFilters = () => filtersConfig.filter(({ name }) => name !== "data_range_option");

  const onRepeatChange = (value: string) => {
    setType(value);
    form.setFieldValue("type", value);
  };

  const onMeridiemChange = (value: AmPmType) => {
    setMeridiem(value);
    form.setFieldValue("meridiem", value);
  };

  const onChangeSingleMail = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSingleEmailChecked(checked);
    form.setFieldsValue({
      is_single_email: checked,
    });
  };

  const onFilterChange = (filterValues: IFilters) => {
    form.setFieldsValue({
      filters: filterValues,
    });
  };

  const onSubmitFilters = (values: IFilters) => {
    const formValues = form.getFieldsValue();
    onFinish({ ...formValues, filters: values, id: report?.id });
  };

  const onInternalChangeUsers = (selectedValue: string) => {
    const newState = handleMultiselectState(usersOptions, selectedUsers, selectedValue);

    setSelectedUsers(newState);
    form.setFieldValue("user_ids", newState);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!selectedUsers?.length) return ClearableTrigger(tooltipProps);

    const tooltipElement = usersOptions
      ?.filter(opt => selectedUsers?.includes(opt.id))
      .map(group => (
        <span className={s.bbDisplayBlock} key={group.id}>
          {group.label}
        </span>
      ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: () => form.setFieldValue("user_ids", []),
      ...tooltipProps,
    });
  };

  return (
    <Modal
      width={600}
      onCancel={onClose}
      onOk={() => form.submit()}
      centered
      title={t(
        `components:managed:shareReportButton:reportModal:${report?.id ? "updateTitle" : "createTitle"}`, // eslint-disable-line max-len
      )}
      titlePrefix={
        report?.id ? (
          <Button _type="link" className={s.bbReportModalBack} onClick={onClose}>
            <ChevronLeftIcon />
          </Button>
        ) : undefined
      }
      open={open}
      confirmLabel={t(
        `components:managed:shareReportButton:reportModal:${report?.id ? "updateButton" : "createButton"}`, // eslint-disable-line max-len
      )}
      confirmLoading={creatingReport || updatingReport}
    >
      <ScrollView className={s.bbReportModal}>
        <>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            onFinish={() => {
              if (filtersRef.current) filtersRef.current.submit();
            }}
          >
            <Form.Item
              label={t("generic:name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: (
                    <FormErrorMessage>
                      {t("components:managed:shareReportButton:reportModal:nameError")}
                    </FormErrorMessage>
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="type"
              label={t("components:managed:shareReportButton:reportModal:repeat")}
              className={s.bbReportModalRepeat}
            >
              <RadioGroup
                onChange={onRepeatChange}
                className={s.bbReportModalRadioGroup}
                value={type}
              >
                {SCHEDULED_REPORT_TYPES.map(item => (
                  <RadioGroup.Button _size="sm" key={item} value={item}>
                    {t(`components:managed:shareReportButton:reportModal:types:${item}`)}
                  </RadioGroup.Button>
                ))}
              </RadioGroup>
              <span className={s.bbReportModalRadioGroupDescription}>
                {t(`components:managed:shareReportButton:reportModal:types:description:${type}`)}
              </span>
            </Form.Item>
            <div className={s.bbReportModalTimeSection}>
              <div className={s.bbReportModalTimeSectionHour}>
                <span>{t("components:managed:shareReportButton:reportModal:sendEmail")}</span>

                <div className={s.bbReportModalTimeSectionHourContent}>
                  <Form.Item name="hour" className={s.bbFormItemHour}>
                    <SelectStyled
                      useDynamicPlacement
                      tabIndex={-1}
                      options={generateTimeOptions()}
                      className={s.bbFormItemHourSelect}
                      wrapperClassName={s.bbFormItemHourSelectWrapper}
                    />
                  </Form.Item>
                  <Form.Item name="meridiem" className={s.bbMeridiem}>
                    <AmPmSwitch
                      checked={meridiem}
                      onChange={e => onMeridiemChange(e.target.value as AmPmType)}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={s.bbReportModalTimeSectionTimezone}>
                <p className={s.bbTimeZoneLabel}>{t("components:profileUser:timezone:label")}</p>
                <Form.Item name="timezone" className={s.bbTimeZoneWrapper}>
                  <Timezone className={s.bbTimeZone} />
                </Form.Item>
              </div>
            </div>
            <Divider className={s.bbDivider} />
            <Form.Item
              name="sections"
              valuePropName="options"
              label={t("components:pdfReport:sectionsTitle")}
              rules={checkboxListRequired(t("generic:validation:required"))}
              className={s.bbReportModalSections}
            >
              <CheckboxList />
            </Form.Item>
            <Divider className={s.bbDivider} />
            <Form.Item
              name="user_ids"
              className={s.bbFormItemUsers}
              label={t("components:managed:shareReportButton:reportModal:emailTo")}
              rules={[
                {
                  required: true,
                  message: (
                    <FormErrorMessage>
                      {t("components:managed:shareReportButton:reportModal:usersError")}
                    </FormErrorMessage>
                  ),
                },
              ]}
            >
              <SelectStyledMulti
                useDynamicPlacement
                dropdownMatchSelectWidth
                tabIndex={-1}
                _size="sm"
                options={usersOptions}
                className={s.bbSubscriberSelect}
                TriggerVariation={TriggerWithTooltip}
                value={selectedUsers}
                onChange={onInternalChangeUsers}
                highlightTerm={searchTerm}
                DropdownHeader={
                  <SearchInput searchTerm={searchTerm} handleSetSearchTerm={setSearchTerm} />
                }
              />
            </Form.Item>
            <Form.Item name="is_single_email">
              <SingleMail
                name="is_single_email"
                checked={isSingleEmailChecked}
                onChange={onChangeSingleMail}
              />
            </Form.Item>
            <Divider />
            <h3 className={s.bbFiltersModalReview}>
              {t("pages:dashboard:modals:savesSearch:form:reviewFilters")}
            </h3>
            <Form.Item name="filters">
              <Filters
                ref={filtersRef}
                form={filtersForm}
                initialFilterData={report?.filters || filters}
                filtersConfig={getCustomFilters()}
                onChangeCallback={onFilterChange}
                onFilter={onSubmitFilters}
                autocompleteOptions={autocompleteOptions}
                getAutocompleteOptions={getAutocompleteOptions}
                sanitizeFieldName={getFetchValuesFieldName}
                clientPlatformTypes={clientData?.platform_types || []}
              />
            </Form.Item>
          </Form>
        </>
      </ScrollView>
    </Modal>
  );
};
