import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";

import {
  AlertInfo,
  InputWhite,
  SelectStyled,
  Tooltip,
  InformationIcon,
  AssignToMeButton,
} from "@bbdevcrew/bb_ui_kit_fe";
import BatchModeButton from "@containers/BatchMode/BatchModeButton";
import BatchModeSelectAll from "@containers/BatchMode/BatchModeSelectAll";

import { assignToMeLoadingSelector } from "@store/assign/selectors";
import { inboxStatsSelector } from "@store/inboxStats/selectors";
import { commentsPageInfoSelector } from "@store/comments/selectors";

import s from "./SmartInboxHeader.module.less";

import { ISmartInboxHeaderProps } from "./types";
import { getDefaultSortOptions } from "@bbdevcrew/bb_ui_kit_fe";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { getSortOptions, getParentKeys, getSmartInboxOptions } from "../helpers";

import { LoadingIcon } from "@bbdevcrew/bb_ui_kit_fe";

const SmartInboxHeader: React.FC<ISmartInboxHeaderProps> = ({
  inboxType,
  sortValue,
  onSortChange,
  onClickAssignToMe,
}) => {
  const { t, i18n } = useTranslation();
  const { isBatchModeActive } = useBatchMode();

  const [showPendingAssetsAlert, setShowPendingAssetsAlert] = useState(true);

  const assignToMeLoading = useSelector(assignToMeLoadingSelector);
  const inboxStats = useSelector(inboxStatsSelector);
  const commentsInfo = useSelector(commentsPageInfoSelector);

  const tabDescription = useMemo(() => {
    if (i18n.exists(`components:inboxMenu:tabDescriptions:${inboxType}_insights`)) {
      return t(`components:inboxMenu:tabDescriptions:${inboxType}_insights`);
    } else if (i18n.exists(`components:inboxMenu:tabDescriptions:${inboxType}`)) {
      return t(`components:inboxMenu:tabDescriptions:${inboxType}`);
    }
    return false;
  }, [inboxType, t, i18n]);

  const queueNotEmpty = Number(inboxStats?.stats[inboxType]) > 0;

  const inboxParents = useMemo(
    () => getParentKeys(getSmartInboxOptions(), inboxType) || [],
    [inboxType],
  );
  return (
    <>
      <div data-cy="comments-grid-header" className={s.bbInboxHeader}>
        <h5 data-cy="comments-title" className={s.bbInboxHeaderTitle}>
          {inboxParents.length > 0 &&
            inboxParents.map(parentType => (
              <span key={parentType} className={s.bbInboxHeaderTitlePrefix}>
                {t(`components:inboxMenu:tabs:${parentType}Label`)}
              </span>
            ))}{" "}
          {t(`components:inboxMenu:tabs:${inboxType}Label`)}
          {tabDescription && (
            <Tooltip title={tabDescription}>
              <span className={s.bbInboxHeaderTitleInfo}>
                <InformationIcon />
              </span>
            </Tooltip>
          )}
        </h5>
        <div className={s.bbInboxHeaderFilterWrapper}>
          {["review", "ai_highlights"].includes(inboxType) && (
            <div className={s.bbInboxHeaderFilterWrapperActions}>
              {!isBatchModeActive && queueNotEmpty && (
                <AssignToMeButton
                  loading={assignToMeLoading}
                  loadingIndicatorPosition="center"
                  data-cy="care-inbox__start-shift_button"
                  onActionHandler={(closeModal: () => void) =>
                    onClickAssignToMe(inboxType, closeModal)
                  }
                />
              )}
              <div className={s.bbBatchModeBtnWrapper}>
                {isBatchModeActive && <BatchModeSelectAll />}
                <BatchModeButton />
              </div>
            </div>
          )}
          {!isBatchModeActive && (
            <SelectStyled
              _size="sm"
              value={sortValue}
              onChange={onSortChange}
              InputVariation={InputWhite}
              wrapperClassName={s.bbInboxHeaderSortWrapper}
              options={inboxType === "ai_highlights" ? getSortOptions() : getDefaultSortOptions(t)}
            />
          )}
        </div>
      </div>
      {commentsInfo && inboxType === "ai_highlights" && showPendingAssetsAlert && (
        <AlertInfo
          align="left"
          size="medium"
          icon={<LoadingIcon />}
          className={s.bbInboxHeaderInfoAlert}
          onClose={() => setShowPendingAssetsAlert(false)}
          message={t("components:inbox:commentsInitializing")}
        />
      )}
    </>
  );
};
export default SmartInboxHeader;
