import React from "react";

import { TFunction } from "react-i18next";
import { isPostFilterAllowed } from "@components/insights/posts/postsTable/helpers";

import {
  CalendarIcon,
  ChartIcon,
  CommentIcon,
  DiamondSimpleIcon,
  IDynamicFilter,
  SliderIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

export const getCustomFiltersBySection = (
  section: string | null,
  defaultFilters: IDynamicFilter<any>[], // eslint-disable-line
  t: TFunction,
) => {
  if (section === "report-posts") {
    return defaultFilters
      .filter(({ name, type }) => isPostFilterAllowed(type, name))
      .map(filter => {
        if (filter.name === "data_range_option") {
          return {
            ...filter,
            label: t("components:posts:filters:date:label"),
            tooltip: t("components:posts:table:tableHeader:tooltips:post"),
          };
        }
        return filter;
      });
  } else if (section === "report-profiles") {
    return defaultFilters
      .filter(({ name }) => ["data_range_option", "platform_types", "asset_ids"].includes(name))
      .map(filter => {
        if (filter.name === "data_range_option") {
          return {
            ...filter,
            tooltip: "",
            props: {
              ...filter.props,
              hideAllTimeOption: true,
            },
          };
        } else if (filter.name === "platform_types") {
          return {
            ...filter,
            props: {
              ...filter.props,
              hiddenOptions: ["trustpilot", "google_business"],
            },
          };
        }
        return filter;
      });
  } else if (section === "report-alerts") {
    return defaultFilters.filter(({ name }) =>
      [
        "data_range_option",
        "start_time",
        "end_time",
        "asset_ids",
        "platform_types",
        "post_types",
        "post_ids",
        "post_tags",
      ].includes(name),
    );
  }

  return defaultFilters;
};

export const AppHeaderIconMap = {
  insights: <ChartIcon />,
  care: <CommentIcon />,
  publish: <CalendarIcon />,
  managed: <DiamondSimpleIcon />,
  control_panel: <SliderIcon />,
};
