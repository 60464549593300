import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button, Chip, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import PostTagsDropdown from "./PostTags/PostTagsDropdown";
import PostTagsList from "./PostTags/PostTagsList";

import { deleteTagFromPostAction } from "@store/postTags/actions";

import s from "./Post.module.less";

import {
  getPostMediaTypeIcon,
  getPostMediaTypeLabel,
} from "@components/_common/PostPreviewTooltip/helpers";
import { MAX_TAG_LIST_LENGTH } from "./helpers";

import { IPostDetailsProps } from "./Post.type";

import { InformationIcon, PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const PostDetails = ({ post, showPostTags }: IPostDetailsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deleteTagFromPost = (tagId: string) => {
    post?.id && dispatch(deleteTagFromPostAction({ post_tag_id: tagId, post_id: post?.id }));
  };

  return (
    <>
      <div className={s.bbMediaTypeWrapper}>
        <Chip
          _size="xxs"
          _type="secondary"
          text={
            <>
              <div className={s.bbMediaTypeIcon}>{getPostMediaTypeIcon(post)}</div>
              <div className={s.bbMediaTypeLabel}>{getPostMediaTypeLabel(post)}</div>
            </>
          }
        />
        <Chip _size="xxs" _type="grey" text={post.post_category_type?.label} />
      </div>
      <div className={s.bbPostDetailsInfoLabel}>{t("components:comments:post:postIdLabel")}</div>
      <div className={s.bbPostDetailsInfoValue}>{post.id}</div>
      {post.asset?.id && (
        <>
          <div className={s.bbPostDetailsInfoLabel}>
            {t("components:comments:post:assetIdLabel")}
          </div>
          <div className={s.bbPostDetailsInfoValue}>{post.asset?.id}</div>
        </>
      )}
      {showPostTags && (
        <>
          <div className={s.bbPostTagsListingWrapper}>
            <div className={s.bbPostDetailsInfoPostTagsLabelWrapper}>
              <div className={s.bbPostDetailsInfoPostTagsLabel}>
                {t("components:comments:post:postTagsLabel")}
              </div>
              <Tooltip placement="top" title={t("components:postTags:tooltip")}>
                <span className={s.bbPostInfoIcon}>
                  <InformationIcon />
                </span>
              </Tooltip>
            </div>
            <PostTagsDropdown
              postTags={post.post_tags}
              postId={post.id}
              trigger={
                <Button _size="sm" _type="secondary" className={s.bbPostTagsDropdownTrigger}>
                  <PlusIcon />
                </Button>
              }
            />
          </div>
          {(!!post.post_tags.length || !!post.ai_post_tags?.length) && (
            <PostTagsList
              aiPostTags={post.ai_post_tags}
              postTags={post.post_tags}
              maxTagListLength={MAX_TAG_LIST_LENGTH}
              onDeleteTag={deleteTagFromPost}
            />
          )}
        </>
      )}
    </>
  );
};

export default PostDetails;
