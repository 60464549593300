import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Avatar } from "antd";
import { Help } from "@bbdevcrew/bb_ui_kit_fe";

import { isYouTubeShort } from "../helpers/utilsGeneric";
import { YOUTUBE_VALIDATION_MAX_TITLE } from "../YouTubeOptions/YouTubeOptions.helpers";

import { IPreviewYTProps } from "./PreviewYT.types";

import s from "./PreviewYT.module.less";
import postStyles from "../index.module.less";

import { MoreActionsIcon } from "@bbdevcrew/bb_ui_kit_fe";
import YTShortUIOverlay from "@assets/PostPreview/PostPreviewYTShortUI.svg";

const PreviewYTLong: React.FC<IPreviewYTProps> = ({ post }) => (
  <div className={s.bbPreviewYT}>
    {post.coverImage ? (
      <img
        src={post.coverImage.src}
        className={classNames(s.bbPreviewYTVideo, s.bbPreviewYTVideoHasCover)}
      />
    ) : (
      <video
        src={post.previewVideoFiles[0].object_src}
        preload="metadata"
        className={s.bbPreviewYTVideo}
      />
    )}
    <div className={s.bbPreviewYTFooter}>
      <Avatar size={32} src={post.asset.picture} shape="circle" className={s.bbPreviewYTAvatar} />
      <div className={s.bbPreviewYTInfo}>
        <h3 className={s.bbPreviewYTInfoTitle}>
          {post.youtubeOptions?.title.substring(0, YOUTUBE_VALIDATION_MAX_TITLE)}
        </h3>
        <div className={s.bbPreviewYTInfoCreator}>{post.asset.label}</div>
      </div>
      <div className={s.bbPreviewYTMore}>
        <MoreActionsIcon />
      </div>
    </div>
  </div>
);

const PreviewYTShort: React.FC<IPreviewYTProps> = ({ post }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPreviewYTShort}>
      <div className={s.bbPreviewYTShortUIOverlay}>
        <YTShortUIOverlay />
      </div>
      <div className={s.bbPreviewYTShortFooter}>
        <div className={s.bbPreviewYTFooter}>
          <Avatar
            size={22}
            src={post.asset.picture}
            shape="circle"
            className={s.bbPreviewYTAvatar}
          />
          <div className={s.bbPreviewYTInfoCreator}>{post.asset.label}</div>
          <button className={s.bbPreviewYTShortFooterSubscribeBtn}>{t("generic:subscribe")}</button>
        </div>
        <div className={s.bbPreviewYTShortFooterDescription}>{post.message}</div>
      </div>
      <video
        src={post.previewVideoFiles[0].object_src}
        preload="metadata"
        className={s.bbPreviewYTShortVideo}
        autoPlay
        loop
        muted
      />
    </div>
  );
};

export const PreviewYT: React.FC<IPreviewYTProps> = ({ post }) => {
  const { t } = useTranslation();

  const isShort = isYouTubeShort(post);

  return (
    <>
      <div className={postStyles.bbPostCreationPreviewTitle}>
        {t("components:publish:postCreationModal:preview:title", {
          platform: t("generic:platforms:youtube"),
        })}
        <Help
          iconType="info"
          iconClassName={postStyles.bbPostPreviewInfo}
          tooltipProps={{
            title: t("components:publish:postCreationModal:preview:tooltip"),
          }}
        />
      </div>
      <div className={s.bbPreviewYTWrapper}>
        {isShort ? <PreviewYTShort post={post} /> : <PreviewYTLong post={post} />}
      </div>
    </>
  );
};
