import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import ReelPreview from "./ReelPreview";
import StoryPreview from "./StoryPreview";
import ImagePreview from "./ImagePreview";
import { Help } from "@bbdevcrew/bb_ui_kit_fe";
import PostCard from "../../../insights/_common/postCard/PostCard";
import PostCardMessage from "../../../insights/_common/postCard/PostCardMessage";

import { isProductTag, isUserTag } from "../TagsModal/TagsModal.helpers";
import { isIGReel } from "../helpers/utilsValidations";

import { IIGPostPreviewProps } from "./types";

import s from "./IgPostPreview.module.less";
import postStyles from "../index.module.less";

import VideoPlayerIcon from "@assets/VideoPlayer.svg";
import IGSave from "@assets/PostPreview/PostPreviewIGSave.svg";
import IGActions from "@assets/PostPreview/PostPreviewIGActions.svg";
import IGCarousel from "@assets/PostPreview/PostPreviewIGCarousel.svg"; // eslint-disable-line
import classNames from "classnames/bind";

import { IProductTag, IUserTag } from "../TagsModal/TagsModal.types";

const IG_POST_CAPTION_MAX_LENGTH = 100;

const IGPostPreview: FC<IIGPostPreviewProps> = ({ post, images = [], videos = [], coverImage }) => {
  const { t } = useTranslation();

  const caption = post.message;
  const isReel = isIGReel(post);
  const { uid } = post[isReel ? "previewVideoFiles" : "previewImageFiles"][0] || { uid: undefined };

  const allTags = post.mediaTags?.[uid] || [];
  const userTags: IUserTag[] = (allTags as IUserTag[]).filter(isUserTag);
  const productTags: IProductTag[] = (allTags as IProductTag[]).filter(isProductTag);
  const collaborators = userTags.reduce<string[]>((result, tag) => {
    if (tag?.collaborator) {
      result.push(tag.username);
    }
    return result;
  }, []);

  const imagePreview = (
    <div className={s.bbImagePreviewWrapper}>
      <ImagePreview
        image={images[0]}
        hasUserTags={userTags.length > 0}
        hasProductTags={productTags.length > 0}
      />
    </div>
  );

  const reelPreview = (
    <div className={s.bbImagePreviewWrapper}>
      <ReelPreview
        video={videos[0]}
        coverImage={coverImage}
        caption={post.message}
        location={post.location?.id}
        pageAvatar={post.asset.picture}
        pageName={post.asset.username || post.asset.label}
      />
    </div>
  );

  const storyPreview = (
    <div className={s.bbImagePreviewWrapper}>
      <StoryPreview
        video={videos[0]}
        image={images.at(-1)}
        pageAvatar={post.asset.picture}
        pageName={post.asset.username || post.asset.label}
      />
    </div>
  );

  const multiVideoPreview = (
    <div className={s.bbVideoPlayerPreview}>
      <video width="282">
        <source src={videos[0]?.object_src} />
      </video>
      <VideoPlayerIcon />
    </div>
  );

  const renderPostCardFooter = () => (
    <div className={s.bbIgPostPreviewFooter}>
      <div className={s.bbIgPostPreviewActions}>
        <IGActions />
        <div className={s.bbIGCarouselIcon}>
          {(images.length > 1 || videos.length > 1) && <IGCarousel />}
        </div>
        <IGSave />
      </div>
      <div className={s.bbIgPostPreviewCaption}>
        {!!caption && (
          <>
            <PostCardMessage
              showMoreText
              message={caption}
              username={post.asset.username}
              maxMessageLength={IG_POST_CAPTION_MAX_LENGTH}
            />
          </>
        )}
      </div>
    </div>
  );

  if (!images.length && !videos.length) return null;

  return (
    <>
      <div className={postStyles.bbPostCreationPreviewTitle}>
        {t("components:publish:postCreationModal:preview:title", {
          platform: t("generic:platforms:instagram"),
        })}
        <Help
          iconType="info"
          iconClassName={postStyles.bbPostPreviewInfo}
          tooltipProps={{
            title: t("components:publish:postCreationModal:preview:tooltip"),
          }}
        />
      </div>
      <div className={s.bbIgPostPreviewWrapper}>
        <div
          className={classNames(s.bbIgPostPreview, {
            [s.bbIgPostPreviewWithLocation]: !!post.location?.id,
          })}
        >
          {post.publishing_type === "STORY" ? (
            storyPreview
          ) : videos.length === 1 ? (
            reelPreview
          ) : (
            <PostCard
              message={""}
              postTypeText={post.location?.id}
              className={s.bbPostPreviewCard}
              renderCardFooter={renderPostCardFooter}
              pageImageProps={{
                pageName: post.asset.username || "",
                pageImage: post.asset.picture,
                pageImageShape: "circle",
                pageCollaborators: collaborators,
              }}
              postImageProps={{
                customImageRender: (
                  <>{images.length ? imagePreview : videos.length ? multiVideoPreview : null}</>
                ),
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default IGPostPreview;
