import { action } from "typesafe-actions";
import { IRepliesStatsResponse } from "../inboxStats/types";
import {
  POST_COMMENTS,
  POST_COMMENTS_SUCCESS,
  POST_COMMENTS_FAILURE,
  GET_COMMENT_BY_ID,
  GET_COMMENT_BY_ID_SUCCESS,
  GET_COMMENT_BY_ID_FAILURE,
  TRANSLATE_COMMENT,
  LOAD_COMMENTS_PAGE_SUCCESS,
  GET_INBOX_REPLIES,
  GET_INBOX_REPLIES_PAGE,
  PERFORM_COMMENT_ACTION,
  PERFORM_COMMENT_ACTION_SUCCESS,
  PERFORM_COMMENT_ACTION_FAILURE,
  GET_COMMENTS_CSV,
  GET_COMMENTS_CSV_SUCCESS,
  GET_COMMENTS_CSV_FAILURE,
  UPDATE_COMMENT_SENTIMENT,
  UPDATE_COMMENT_SENTIMENT_SUCCESS,
  UPDATE_COMMENT_SENTIMENT_FAILURE,
  ASSIGN_COMMENT_USER,
  ASSIGN_COMMENT_USER_SUCCESS,
  ASSIGN_COMMENT_USER_FAILURE,
  PERFORM_BULK_COMMENT_ACTION,
  PERFORM_BULK_COMMENT_ACTION_FAILURE,
  PERFORM_BULK_COMMENT_ACTION_SUCCESS,
  CURRENT_USER_ASSIGNED,
  CURRENT_USER_UNASSIGNED,
  TRIGGER_NEXT_BATCH,
  POST_COMMENTS_SEARCH,
  POST_COMMENTS_SEARCH_SUCCESS,
  POST_COMMENTS_SEARCH_FAILURE,
  RESET_COMMENTS_CSV_STATE,
  GET_INBOX_REPLIES_ATTACHMENTS_SUCCESS,
  GET_INBOX_REPLIES_ATTACHMENTS_FAILURE,
  GET_INBOX_REPLIES_ATTACHMENTS,
  GET_COMMENT_HISTORY,
  GET_COMMENT_HISTORY_FAILURE,
  GET_COMMENT_HISTORY_SUCCESS,
  RESET_COMMENT_HISTORY,
} from "./actionTypes";
import {
  IPostCommentBody,
  IGetInboxRepliesPayload,
  ICommentActionPayload,
  ICommentsCSVPayload,
  IBulkCommentActionPayload,
  IPostCommentsSearchBody,
  DEFAULT_COMMENT_LIST_KEY,
  ICommentHistoryPayload,
} from "./types";
import { ICommentWithPostProps } from "./types";
import { IComment, CommentListType } from "@bbdevcrew/bb_ui_kit_fe";

export const postCommentsAction = (body: IPostCommentBody) => action(POST_COMMENTS, body);
export const postCommentsSuccessAction = (
  comments: IComment[],
  conversation_list_id?: CommentListType,
  cursor?: string,
  navigation_id?: string,
  info?: string,
  isReplyModal?: boolean,
) =>
  action(POST_COMMENTS_SUCCESS, {
    comments,
    cursor,
    navigation_id,
    info,
    isReplyModal,
    conversation_list_id,
  });
export const postListenProjectCommentsSuccessAction = (
  comments: IComment[],
  cursor?: string,
  navigation_id?: string,
  info?: string,
) => action(POST_COMMENTS_SUCCESS, { comments, cursor, navigation_id, info });
export const postCommentsFailureAction = (payload: IPostCommentBody) =>
  action(POST_COMMENTS_FAILURE, payload);

// Get a comment by id
export const getCommentByIdAction = (commentId: string) => action(GET_COMMENT_BY_ID, commentId);
export const getCommentByIdSuccessAction = (comment: ICommentWithPostProps) =>
  action(GET_COMMENT_BY_ID_SUCCESS, comment);
export const getCommentByIdFailureAction = () => action(GET_COMMENT_BY_ID_FAILURE);

// Page load success
export const loadCommentsPageSuccessAction = (
  comments: IComment[],
  conversation_list_id?: CommentListType,
  cursor?: string,
  navigation_id?: string,
  info?: string,
  replacePrevPage?: boolean,
) =>
  action(LOAD_COMMENTS_PAGE_SUCCESS, {
    comments,
    conversation_list_id,
    cursor,
    navigation_id,
    info,
    replacePrevPage,
  });

export const translateCommentAction = (comment_id: string, isParent: boolean) =>
  action(TRANSLATE_COMMENT, { comment_id, isParent });

// Inbox
export const getInboxRepliesAction = (payload: IGetInboxRepliesPayload, url?: string) =>
  action(GET_INBOX_REPLIES, { ...payload, url });
export const getInboxRepliesPageAction = (payload: IPostCommentBody) =>
  action(GET_INBOX_REPLIES_PAGE, payload);

export const getInboxRepliesAttachmentsAction = ({
  cursor,
  conversation_list_id,
}: {
  cursor?: string;
  conversation_list_id?: CommentListType;
}) => action(GET_INBOX_REPLIES_ATTACHMENTS, { cursor, conversation_list_id });
export const getInboxRepliesAttachmentsSuccessAction = (
  data: IComment[],
  conversation_list_id = DEFAULT_COMMENT_LIST_KEY,
) => action(GET_INBOX_REPLIES_ATTACHMENTS_SUCCESS, { data, conversation_list_id });
export const getInboxRepliesAttachmentsFailureAction = () =>
  action(GET_INBOX_REPLIES_ATTACHMENTS_FAILURE);

export const performCommentAction = (payload: ICommentActionPayload) =>
  action(PERFORM_COMMENT_ACTION, payload);
export const performCommentActionSuccessAction = (
  commentAction: ICommentActionPayload,
  stats: IRepliesStatsResponse,
) => action(PERFORM_COMMENT_ACTION_SUCCESS, { commentAction, stats });
export const performCommentActionFailureAction = (commentId: string) =>
  action(PERFORM_COMMENT_ACTION_FAILURE, commentId);

// Comments CSV download
export const getCommentsCSVAction = (payload: ICommentsCSVPayload) =>
  action(GET_COMMENTS_CSV, payload);
export const getCommentsCSVSuccessAction = (data: Blob) => action(GET_COMMENTS_CSV_SUCCESS, data);
export const getCommentsCSVFailureAction = () => action(GET_COMMENTS_CSV_FAILURE);
export const resetCommentsCSVState = () => action(RESET_COMMENTS_CSV_STATE);

// Patch comment
export const assignCommentUserAction = (id: string, data: Partial<IComment>, userId?: string) =>
  action(ASSIGN_COMMENT_USER, { id, data, userId });
export const assignCommentUserSuccessAction = () => action(ASSIGN_COMMENT_USER_SUCCESS);
export const assignCommentUserFailureAction = () => action(ASSIGN_COMMENT_USER_FAILURE);

// Manage inbox stats for assign to me queue
export const currentUserAssigned = () => action(CURRENT_USER_ASSIGNED);
export const currentUserUnassigned = () => action(CURRENT_USER_UNASSIGNED);

// Patch comment
export const updateCommentSentimentAction = (id: string, data: Partial<IComment>) =>
  action(UPDATE_COMMENT_SENTIMENT, { id, data });
export const updateCommentSentimentSuccessAction = (id: string, data: Partial<IComment>) =>
  action(UPDATE_COMMENT_SENTIMENT_SUCCESS, { id, data });
export const updateCommentSentimentFailureAction = () => action(UPDATE_COMMENT_SENTIMENT_FAILURE);

//PATCH BULK COMMENT ACTIONS
export const performBulkCommentAction = (payload: IBulkCommentActionPayload) =>
  action(PERFORM_BULK_COMMENT_ACTION, payload);
export const performBulkCommentActionSuccessAction = (commentAction: IBulkCommentActionPayload) =>
  action(PERFORM_BULK_COMMENT_ACTION_SUCCESS, { commentAction });
export const performBulkCommentActionFailureAction = () =>
  action(PERFORM_BULK_COMMENT_ACTION_FAILURE);

// GET REPLYING COMMENT
export const triggerNextCommentBatch = (id: string) => action(TRIGGER_NEXT_BATCH, id);

// POST COMMENTS SEARCH
export const postCommentsSearchAction = (body: IPostCommentsSearchBody) =>
  action(POST_COMMENTS_SEARCH, body);
export const postCommentsSearchSuccessAction = (
  comments: IComment[],
  conversation_list_id?: CommentListType,
  cursor?: string,
  navigation_id?: string,
  info?: string,
  isReplyModal?: boolean,
) =>
  action(POST_COMMENTS_SEARCH_SUCCESS, {
    comments,
    conversation_list_id,
    cursor,
    navigation_id,
    info,
    isReplyModal,
  });
export const postCommentsSearchFailureAction = (payload: IPostCommentsSearchBody) =>
  action(POST_COMMENTS_SEARCH_FAILURE, payload);

export const getCommentHistoryAction = (payload: string) => action(GET_COMMENT_HISTORY, payload);
export const getCommentHistorySuccessAction = (payload: ICommentHistoryPayload) =>
  action(GET_COMMENT_HISTORY_SUCCESS, payload);
export const getCommentHistoryFailureAction = () => action(GET_COMMENT_HISTORY_FAILURE);
export const resetCommentHistoryAction = () => action(RESET_COMMENT_HISTORY);
