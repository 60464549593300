import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Button, Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Overview.module.less";

import { meSelector } from "@store/me/selectors";
import { workflowsFetchingSelector } from "@store/workflows/selectors";

import { useWorkflows } from "../workflows/helpers";
import { workflowBgImagesMap } from "./Overview.helpers";
import { WorkflowCategoryIconMap } from "../workflows/menu/helpers";

import { WorkflowIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { LoadingOutlined } from "@ant-design/icons";

export const OverviewWorkflows: React.FC = () => {
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { items: workflowItems } = useWorkflows();
  const overviewWorkflowTransBase = "components:controlPanel:overview:workflows";

  const me = useSelector(meSelector);
  const workflowsLoading = useSelector(workflowsFetchingSelector);

  const categories = ["hide", "email_alert", "reply", "tag", "archive", "other"];
  const totalWorkflows = workflowItems.length;
  const categorizedWorkflows = categories.reduce(
    (acc, category) => ({
      ...acc,
      [category]: workflowItems.filter(item => item.category === category).length,
    }),
    {} as Record<string, number>,
  );

  categorizedWorkflows["other"] =
    totalWorkflows - Object.values(categorizedWorkflows).reduce((a, b) => a + b, 0);

  const getWorkflowStatus = useCallback(
    (category: string) => (categorizedWorkflows[category] === 0 ? "inactive" : "active"),
    [categorizedWorkflows],
  );

  const findWorkflowNumberByCategory = useCallback(
    (category: string): number => {
      if (category === "other") {
        return (
          totalWorkflows -
          categories.reduce(
            (acc, cat) => acc + (cat === "other" ? 0 : findWorkflowNumberByCategory(cat)),
            0,
          )
        );
      }
      return workflowItems.filter(item => item.category === category).length;
    },
    // eslint-disable-next-line
    [workflowItems, totalWorkflows],
  );

  const setActiveSection = (id: string, category?: string) => () => {
    setSearchParams(
      {
        ...Object.fromEntries(new URL(window.location.href).searchParams),
        section: id,
        category: !category || category === "other" ? "all" : category,
      },
      { replace: true },
    );
  };

  return (
    <>
      {!workflowsLoading && workflowItems?.length ? (
        <div className={s.bbOverviewWorkflows}>
          <div className={s.bbOverviewWorkflowsTitle}>
            <div>
              <WorkflowIcon />
              <span>{t(`${overviewWorkflowTransBase}:title`)}</span>
            </div>
            <div className={s.bbOverviewWorkflowsSubTitle}>
              <span className={s.bbBadgeNumber}>{totalWorkflows}</span>
              {t(`${overviewWorkflowTransBase}:subTitle`)}
            </div>
          </div>

          <div className={s.bbOverviewWorkflowsList}>
            {categories.map(category => {
              const Icon = WorkflowCategoryIconMap[category];

              return (
                <>
                  {category === "reply" &&
                  (getWorkflowStatus(category) === "inactive" ||
                    !me?.client?.reply_workflows_enabled) ? null : (
                    <div className={s.bbOverviewWorkflowsListItemWrapper}>
                      <div
                        key={category}
                        className={classNames(
                          s.bbOverviewWorkflowsListItem,
                          s[`bbOverviewWorkflowsListItem-${category}`],
                          {
                            [s.bbOverviewWorkflowsListItemInactive]:
                              getWorkflowStatus(category) === "inactive",
                          },
                        )}
                        onClick={setActiveSection("control_panel-workflows", category)}
                      >
                        <div>
                          <div className={s.bbWorkflowCategoryIcon}>{!!Icon && <Icon />}</div>
                          <div className={s.bbWorkflowCategoryText}>
                            {t(
                              `${overviewWorkflowTransBase}:${category}:` +
                                getWorkflowStatus(category),
                            )}
                          </div>
                        </div>
                        <div className={s.bbWorkflowBadgeNumberContainer}>
                          <span
                            className={classNames(s.bbBadgeNumber, {
                              [s.bbBadgeNumberInactive]: getWorkflowStatus(category) === "inactive",
                            })}
                          >
                            {findWorkflowNumberByCategory(category)}
                          </span>
                          <ChevronRightIcon />
                        </div>
                        {getWorkflowStatus(category) === "active" && (
                          <div className={s.bbOverviewWorkflowsListItemBg}>
                            {workflowBgImagesMap[category as keyof typeof workflowBgImagesMap]}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>

          <Button
            _type="secondary"
            _size="sm"
            className={s.bbOverviewWorkflowsAction}
            onClick={setActiveSection("control_panel-workflows")}
          >
            {t(`${overviewWorkflowTransBase}:actionBtn`)}
            <ChevronRightIcon />
          </Button>
        </div>
      ) : (
        <div className={s.bbOverviewWorkflowsLoading}>
          <Loading
            isLoading
            size="large"
            tip={t("pages:loading:spinText")}
            indicator={<LoadingOutlined color="primary" style={{ fontSize: 24 }} />}
          />
        </div>
      )}
    </>
  );
};
