import dayjs from "dayjs";
import React from "react";

import { ChevronDownIcon, TimePicker } from "@bbdevcrew/bb_ui_kit_fe";
import AmPmSwitch from "@components/_common/amPmSwitch/AmPmSwitch";

import s from "./TagWorkflowTrigger.module.less";

import {
  getDisabledHours,
  dayjsToTimeString,
  SHORT_TIME_FORMAT,
  TIME_FORMAT,
  timeStringToDayjs,
} from "./TagWorkflowTrigger.helpers";
import { ITagWorkflowTimePickerProps } from "./TagWorkflowTrigger.types";
import { AmPmType } from "@store/notifications/types";

export const TagWorkflowTimePicker: React.FC<ITagWorkflowTimePickerProps> = ({
  value,
  onChange,
  name,
}) => {
  const format =
    value?.time && timeStringToDayjs(value.time).hour() < 10 ? SHORT_TIME_FORMAT : TIME_FORMAT;

  const onTimeChange = (time: dayjs.Dayjs) => {
    onChange?.({ ...(value ?? {}), time: dayjsToTimeString(time) });
  };

  const onTimePeriodChange = (period: AmPmType) => {
    onChange?.({ ...(value ?? {}), period });
  };

  return (
    <div className={s.bbTimePickerWrapper}>
      <TimePicker
        disabledHours={getDisabledHours}
        showNow={false}
        use12Hours={false}
        showSecond={false}
        format={format}
        placeholder=""
        name={name}
        hideDisabledOptions
        className={s.bbTimePicker}
        onSelect={onTimeChange}
        allowClear={false}
        suffixIcon={<ChevronDownIcon />}
        // eslint-disable-next-line
        // @ts-ignore
        value={timeStringToDayjs(value?.time)}
      />
      <div className={s.bbTimePeriodWrapper}>
        <AmPmSwitch
          checked={value?.period || "AM"}
          name={name}
          key={name}
          onChange={e => onTimePeriodChange(e.target.value as AmPmType)}
        />
      </div>
    </div>
  );
};
