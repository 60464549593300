import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useState, useMemo } from "react";

import { Tooltip, SimpleSelectSingle } from "@bbdevcrew/bb_ui_kit_fe";

import {
  autocompleteOptionsFetchingSelector,
  autocompleteOptionsSelector,
} from "@store/autocomplete/selectors";
import {
  clearAutocompleteOptionsAction,
  getAutocompleteOptionsSearchAction,
  updateAutocompleteOptionsAction,
} from "@store/autocomplete/actions";

import s from "./LocationSelect.module.less";

import {
  debounce,
  IAutocompleteOption,
  AllowedAutocompleteFieldType,
  IGetAutocompleteOptionsPayload,
} from "@bbdevcrew/bb_ui_kit_fe";
import { transformLocation } from "./LocationSelect.helpers";
import { ILocationSelectProps } from "./LocationSelect.types";

import { MapPinIcon, MapPinFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

export function LocationSelect({ pageIdentifier, initialValue, onChange }: ILocationSelectProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedLocation, setSelectedLocation] = useState<IAutocompleteOption | undefined>(
    initialValue,
  );

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const autocompleteOptionsLoading = useSelector(autocompleteOptionsFetchingSelector);

  // eslint-disable-next-line
  const updateAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(updateAutocompleteOptionsAction(payload));
    }, 500),
    [dispatch],
  );

  const getInitialAutocompleteOptions = useCallback(
    (payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsSearchAction(payload));
    },
    [dispatch],
  );

  const clearAutocompleteOptions = useCallback(
    (field: AllowedAutocompleteFieldType) => dispatch(clearAutocompleteOptionsAction(field)),
    [dispatch],
  );

  const locationOptions = useMemo(() => {
    if (selectedLocation) {
      return [
        transformLocation({
          ...selectedLocation,
          tooltip: t("components:publish:postCreationModal:location:tooltipRemove"),
        }),
      ];
    }

    return autocompleteOptions.facebook_page.map(location => transformLocation(location));
    // eslint-disable-next-line
  }, [autocompleteOptions.facebook_page]);

  const fetchValues = (term: string) => {
    const payload: IGetAutocompleteOptionsPayload = {
      field: "facebook_page",
      query: term,
      identifier: pageIdentifier,
    };

    if (term) updateAutocompleteOptions(payload);
    else getInitialAutocompleteOptions(payload);
  };

  const onLocationSearch = (searchTerm?: string) => {
    fetchValues(searchTerm || "");
  };

  const onLocationChange = (value?: string) => {
    const location = autocompleteOptions.facebook_page.find(l => l.value === value);
    setSelectedLocation(location);
    onChange(location);
  };

  const onDropdownClose = () => {
    clearAutocompleteOptions("facebook_page");
  };

  return (
    <div className={s.bbPostLocationSelectWrapper}>
      <SimpleSelectSingle
        allowClear
        allowSearch={!selectedLocation?.value}
        options={locationOptions}
        value={selectedLocation?.value}
        onChange={value => onLocationChange(value as string)}
        onSearch={onLocationSearch}
        onDropdownClose={onDropdownClose}
        optionsLoading={autocompleteOptionsLoading}
        placeholder={t("generic:select")}
        searchInputPlaceholder={t("generic:search")}
        emptyStatePlaceholder={t("components:publish:postCreationModal:location:placeholder")}
        trigger={() => (
          <Tooltip
            title={t(
              "components:publish:postCreationModal:location:" +
                (!!selectedLocation?.value ? "tooltipChange" : "tooltipAdd"),
            )}
          >
            <div className={s.bbPostLocationSelectTrigger}>
              {!!selectedLocation?.value ? <MapPinFilledIcon /> : <MapPinIcon />}
            </div>
          </Tooltip>
        )}
      />
    </div>
  );
}
