import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { isImage, isVideo } from "@bbdevcrew/bb_ui_kit_fe";

import TagCountTooltip from "../TagCountTooltip";

import useUpdatePost from "./useUpdatePost";
import { isFB, isIGPost } from "./utilsValidations";
import { isProductTag, isUserTag } from "../TagsModal/TagsModal.helpers";

import { AnyTagType, FileContextType, IUserTag } from "../TagsModal/TagsModal.types";
import { IPostItem, IUploadFile } from "./types";
import { UploadFile } from "antd/lib/upload/interface";

import { ShoppingBagIGIcon } from "@assets/index";
import { TagIcon, TypeIcon, UserIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const useFileContextMenu = (post: IPostItem) => {
  const [contextFile, setContextFile] = useState<IUploadFile>();
  const [contextType, setContextType] = useState<FileContextType>("user");
  const [previewSrc, setPreviewSrc] = useState<string | undefined>(undefined);
  const [previewType, setPreviewType] = useState<"image" | "video">("image");

  const { t } = useTranslation();
  const { updatePost } = useUpdatePost();

  const { mediaTags = {} } = post;
  const setMediaTags = (localMediaTags: { [fileId: string]: AnyTagType[] }) => {
    updatePost({
      ...post,
      mediaTags: localMediaTags,
    });
  };

  const onContextMenuClick = useCallback(
    (category: FileContextType, file: UploadFile) => () => {
      if (file) {
        setContextType(category);
        if (file.type?.startsWith("video")) {
          setPreviewSrc(
            post.coverImage
              ? post.coverImage.src
              : post.previewVideoFiles.find(({ uid }) => uid === file.uid)?.object_src,
          );
          setPreviewType(post.coverImage ? "image" : "video");
        } else {
          setPreviewSrc(post.previewImageFiles.find(({ uid }) => uid === file.uid)?.src);
          setPreviewType("image");
        }
        setContextFile(file);
      }
    },
    [post],
  );

  const cancelFileContext = () => {
    setContextFile(undefined);
  };

  const tagStats = useCallback(
    (file: IUploadFile) => {
      const allTags = mediaTags?.[file.uid] || [];
      const allUserTags = allTags.filter(isUserTag) as IUserTag[];
      return {
        total: allTags.length,
        users: allUserTags.filter(({ collaborator = false }) => !collaborator).length,
        collaborators: allUserTags.filter(({ collaborator = false }) => collaborator).length,
        products: allTags.filter(isProductTag).length,
      };
    },
    [mediaTags],
  );

  const saveTags = (localTags: AnyTagType[]) => {
    if (contextFile) {
      setMediaTags({ ...mediaTags, [contextFile.uid]: localTags });
      setContextFile(undefined);
    }
  };

  const getFileContextFeatures = useCallback(
    (file: UploadFile) => {
      const isVideoFile = !!file.type && isVideo(file.type);
      const isImageFile = !!file.type && isImage(file.type);

      const tags =
        isIGPost(post) && (isImageFile || (isVideoFile && post.uploadedFiles.length === 1));
      const altText = isFB(post) && isImage;

      return {
        tags,
        altText,
      };
    },
    [post],
  );

  const getContextMenu = useCallback(
    (file: UploadFile) => {
      const { tags: canTag, altText: canAltText } = getFileContextFeatures(file);

      const items = [];

      if (canTag) {
        items.push(
          ...[
            {
              id: "users",
              label: t("components:publish:postCreationModal:mediaTags:tabs:users"),
              icon: <UserIcon />,
              onClick: onContextMenuClick("user", file),
            },
            {
              id: "collaborators",
              label: t("components:publish:postCreationModal:mediaTags:tabs:collaborators"),
              icon: <UserIcon />,
              onClick: onContextMenuClick("user", file),
            },
            {
              id: "products",
              label: t("components:publish:postCreationModal:mediaTags:tabs:products"),
              icon: <ShoppingBagIGIcon />,
              onClick: onContextMenuClick("product", file),
            },
          ],
        );
      }

      if (canAltText) {
        items.push({
          id: "alttext",
          label: t("components:publish:postCreationModal:altText:menuLink"),
          icon: <TypeIcon />,
          onClick: onContextMenuClick("alt", file),
        });
      }

      const { total } = canTag ? tagStats(file) : { total: 0 };
      const tooltip = canTag
        ? total > 0
          ? t("components:publish:postCreationModal:mediaTags:editTooltip")
          : t("components:publish:postCreationModal:mediaTags:addTooltip")
        : undefined;

      return {
        items,
        tooltip,
      };
    },
    [t, tagStats, onContextMenuClick, getFileContextFeatures],
  );

  const getTagBadge = useCallback(
    file => {
      const { tags: canTag } = getFileContextFeatures(file);
      const stats = tagStats(file);

      return canTag && stats.total > 0
        ? {
            tooltip: <TagCountTooltip count={tagStats(file)} />,
            icon: <TagIcon />,
          }
        : undefined;
    },
    [tagStats, getFileContextFeatures],
  );

  return {
    contextFile,
    previewSrc,
    previewType,
    contextType,
    getContextMenu,
    cancelFileContext,
    mediaTags,
    tagStats,
    saveTags,
    getTagBadge,
    getFileContextFeatures,
  };
};
