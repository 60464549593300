import { RootState } from "../";

export const fromHidingConfig = (state: RootState) => state.hidingConfig;
export const getStatsSelector = (state: RootState) => fromHidingConfig(state).data;

export const getHidingConfigSelector = (state: RootState) => fromHidingConfig(state).data;

export const getHidingConfigSelectorPending = (state: RootState) =>
  fromHidingConfig(state).fetchingHidingConfig;
export const getHidingConfigSelectorSuccessful = (state: RootState) =>
  fromHidingConfig(state).fetchedHidingConfig;
export const getHidingConfigSelectorFailure = (state: RootState) =>
  fromHidingConfig(state).fetchedHidingConfigFail;

export const updateHidingConfigSelectorPending = (state: RootState) =>
  fromHidingConfig(state).updatingHidingConfig;
export const updateHidingConfigSelectorSuccessful = (state: RootState) =>
  fromHidingConfig(state).updatedHidingConfig;
export const updateHidingConfigSelectorFailure = (state: RootState) =>
  fromHidingConfig(state).updatedHidingConfigFail;
