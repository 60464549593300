import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Grid } from "antd";

import s from "./index.module.less";

import { ArrowUpIcon } from "@assets/index";
import ChooseAcountIcon from "@assets/ChooseAccountsIllustration.svg";

export function PostCreationEmptyState() {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  const isMobile = screens.md === false;

  return (
    <div className={classNames(s.bbEmptyState, { [s.bbEmptyStateMobile]: isMobile })}>
      <div className={s.bbEmptyStateIllustration}>
        <ChooseAcountIcon />
      </div>
      <div className={s.bbEmptyStateText}>
        {t("components:publish:postCreationModal:empty:text")}
        <span className={s.bbEmptyStateArrowUpIcon}>
          <ArrowUpIcon />
        </span>
      </div>
    </div>
  );
}
