import cn from "classnames/bind";
import React, { FC, useState } from "react";
import { Document, Page } from "react-pdf";
import { useTranslation } from "react-i18next";

import { ChevronLeftIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./LIPDFPreview.module.less";

import { defaultPDFDocOptions } from "../../calendar/post/helpers";

const cx = cn.bind(s);

interface ILIPDFPreviewProps {
  fileSrc: string;
}

const LIPDFPreview: FC<ILIPDFPreviewProps> = ({ fileSrc }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const goToPrevPage = () => setCurrentPage(currentPage - 1 <= 1 ? 1 : currentPage - 1);

  const goToNextPage = () =>
    setCurrentPage(currentPage + 1 >= totalPages ? totalPages : currentPage + 1);

  return (
    <div hidden={loading} className={s.bbPDFPreviewWrapper}>
      <div className={cx(s.bbPDFPreviewElement, s.bbPDFPreviewHeader)}>
        <span> · </span>
        {t("components:publish:postCreationModal:preview:page", {
          count: totalPages,
        })}
      </div>

      <div className={s.bbPDFNavigation}>
        {currentPage > 1 && (
          <button
            onClick={goToPrevPage}
            className={cx(s.bbPDFNavigationBtn, s.bbPDFNavigationBtnLeft)}
          >
            <span className={s.bbPDFNavigationBtnContent}>
              <ChevronLeftIcon />
            </span>
          </button>
        )}
        {currentPage < totalPages && (
          <button
            onClick={goToNextPage}
            className={cx(s.bbPDFNavigationBtn, s.bbPDFNavigationBtnRight)}
          >
            <span className={s.bbPDFNavigationBtnContent}>
              <ChevronRightIcon />
            </span>
          </button>
        )}
      </div>

      <Document
        file={fileSrc}
        renderMode="canvas"
        options={defaultPDFDocOptions}
        onLoadSuccess={pdfDocProxy => setTotalPages(pdfDocProxy.numPages)}
      >
        <Page
          width={272}
          key={currentPage}
          pageNumber={currentPage}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          onLoadSuccess={() => setLoading(false)}
          onRenderError={() => setLoading(false)}
        />
      </Document>

      <div className={cx(s.bbPDFPreviewElement, s.bbPDFPreviewFooter)}>
        {currentPage} / {totalPages}
      </div>
    </div>
  );
};

export default LIPDFPreview;
