import classNames from "classnames/bind";
import React from "react";

import { Col } from "antd";
import { ColumnWithGrowth } from "./ProfilesTableRow.helpers";

import s from "./ProfilesTable.module.less";

import { FollowersTableRowProps } from "./ProfilesTable.types";
import { getPlatformIcon } from "./ProfilesTable.helpers";
import { formatter } from "@components/_common/CardStat/helpers";

const FollowersTableRow = React.memo(({ data: row, index, columns }: FollowersTableRowProps) => {
  return (
    <>
      {!!columns.length && row && (
        <div
          data-cy="profiles-table-row"
          className={classNames(s.bbProfilesTableRow, {
            [s.bbProfilesTableRowColored]: index % 2 === 1,
          })}
        >
          <Col span={columns[0].colSpan} data-cy="profiles-column" className={s.bbColPlatform}>
            <div data-cy="profiles-platform" className={s.bbPlatformIconSmall}>
              {row?.platform && getPlatformIcon(row.platform)}
            </div>
            <span>{row.asset_name}</span>
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[1].colSpan}>
            <ColumnWithGrowth
              value={row.followers_count != null ? formatter(row.followers_count) : ""}
              status={row.status}
            />
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[2].colSpan}>
            <ColumnWithGrowth value={row.percentage} status={row.status} showPercentage />
          </Col>
          <Col className={s.bbColumn} data-cy="table-column" span={columns[3].colSpan}>
            <ColumnWithGrowth growth={row.growth} status={row.status} />
          </Col>
        </div>
      )}
    </>
  );
});

FollowersTableRow.displayName = "FollowersTableRow";

export default FollowersTableRow;
