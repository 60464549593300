import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Form } from "antd";
import {
  Button,
  ColorPicker,
  FormErrorMessage,
  IFormFilterRequest,
  IFormHandler,
  Input,
  Modal,
} from "@bbdevcrew/bb_ui_kit_fe";
import { SubTagModalSection } from "./SubTagModalSection";

import s from "./TagsManagement.module.less";

import { DEFAULT_COLOR_PRESETS, DEFAULT_TAG_COLOR } from "./TagsManagement.helpers";
import { ITagModalProps } from "./TagsManagement.types";
import { ISubTag, ITag } from "@store/tagsManagement/types";

const TagModal = ({
  isOpen,
  isSubmitting,
  editingTag,
  onCloseModal,
  onSubmit,
  tagsList,
  title,
  isSubTag,
}: ITagModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [filtersForm] = Form.useForm<IFormFilterRequest>();
  const filtersRef = useRef<IFormHandler>(null);

  const [selectedColor, setSelectedColor] = useState<string | undefined>(DEFAULT_TAG_COLOR);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      filtersRef.current?.resetFields();
      setSelectedColor(DEFAULT_TAG_COLOR);
      setIsFilterEnabled(false);
    }

    setSelectedColor(editingTag?.color || DEFAULT_TAG_COLOR);

    if (editingTag) {
      form.setFieldsValue(editingTag);
      if ("quick_automation_enabled" in editingTag)
        setIsFilterEnabled(!!editingTag.quick_automation_enabled);
    }
  }, [form, editingTag, isOpen]);

  const onCancel = () => {
    form.resetFields();
    onCloseModal();
    filtersRef.current?.resetFields();
  };

  const onInternalSubmit = (data: ITag | ISubTag) => {
    if ("parent_id" in data) {
      const formValues = filtersForm.getFieldsValue();
      onSubmit({
        ...data,
        quick_automation_filters: isFilterEnabled
          ? Object.fromEntries(Object.entries(formValues).filter(([, field]) => field?.length))
          : undefined,
      });
    } else onSubmit(data);
  };

  const options = tagsList
    ? tagsList.map(item => {
        return {
          id: item.key,
          label: item.title,
        };
      })
    : [];

  return (
    <Modal
      centered
      responsive
      hideFooter
      onCancel={onCancel}
      open={isOpen}
      title={title}
      width={600}
      classes={{
        root: classNames({ [s.bbTagsManagementModal]: isFilterEnabled }),
        modal: classNames({ [s.bbTagsManagementModalContent]: isFilterEnabled }),
        body: s.bbTagsManagementModalBody,
      }}
    >
      <Form
        requiredMark="optional"
        scrollToFirstError
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onInternalSubmit}
        initialValues={editingTag}
      >
        <Form.Item
          name={isSubTag ? "name" : "title"}
          label={t("components:controlPanel:tagsManagement:modal:form:name")}
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage>
                  {t("components:controlPanel:tagsManagement:modal:form:nameError")}
                </FormErrorMessage>
              ),
            },
            {
              max: 50,
              validateTrigger: "onBlur",
              message: <FormErrorMessage>{t("generic:validation:max")}</FormErrorMessage>,
            },
          ]}
        >
          <Input _size="md" name={isSubTag ? "name" : "title"} maxLength={50} />
        </Form.Item>
        {isSubTag && editingTag && (
          <Form.Item
            name="tag_id"
            label={t("components:controlPanel:tagsManagement:modal:form:id")}
          >
            <Input _size="md" name="tag_id" disabled />
          </Form.Item>
        )}
        <Form.Item
          name="description"
          label={t("components:controlPanel:tagsManagement:modal:form:description")}
        >
          <Input _size="md" name="description" />
        </Form.Item>
        {isSubTag && (
          <Form.Item
            name="examples"
            label={t("components:controlPanel:tagsManagement:modal:form:examples")}
          >
            <Input _size="md" name="examples" />
          </Form.Item>
        )}
        {!isSubTag && (
          <Form.Item
            name="color"
            label={t("components:controlPanel:tagsManagement:modal:form:color")}
            requiredMark={false}
          >
            <ColorPicker
              key="color"
              hex={selectedColor}
              onChange={setSelectedColor}
              presets={[
                {
                  label: t("pages:dashboard:modals:savesSearch:form:color:presets"),
                  open: true,
                  colors: DEFAULT_COLOR_PRESETS,
                },
              ]}
            />
          </Form.Item>
        )}
        {isSubTag && (
          <SubTagModalSection
            form={form}
            filtersForm={filtersForm}
            options={options}
            disabled={!!editingTag}
            isFilterEnabled={isFilterEnabled}
            onFilterSwitch={e => setIsFilterEnabled(e.target.checked)}
            initialFilterData={(editingTag as ISubTag)?.quick_automation_filters}
            ref={filtersRef}
          />
        )}
        <div className={s.bbTagsManagementModalFooter}>
          <Button _type="secondary" onClick={onCloseModal}>
            {t("generic:cancel")}
          </Button>
          <Button _type="primary" type="submit" loading={isSubmitting}>
            {t("generic:save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default TagModal;
