import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";

import { staticDataSelector } from "@store/me/selectors";

import { IObjectOption, useSearch } from "@bbdevcrew/bb_ui_kit_fe";

interface ITimezone {
  className?: string;
  onChange?: (value: string) => void;
  value?: string;
}

export const Timezone: React.FC<ITimezone> = ({ className, ...props }) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<IObjectOption[]>([]);

  const {
    options: searchedOptions,
    SearchInput,
    searchTerm,
    setSearchTerm,
  } = useSearch(options, t("generic:search"));

  const staticData = useSelector(staticDataSelector);

  useEffect(() => {
    const timezones = staticData?.timezones.map(({ id, name }) => ({ id, label: name })) || [];
    setOptions(timezones);
  }, [staticData]);

  return (
    <SelectStyled
      options={searchedOptions}
      wrapperClassName={className}
      DropdownHeader={SearchInput}
      highlightTerm={searchTerm}
      onDropdownClose={() => setSearchTerm("")}
      dropdownMatchSelectWidth
      {...props}
    />
  );
};
