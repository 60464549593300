import React from "react";

import { InputWhite, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CommentSortingDropdown.module.less";

import { useCommentSortingOptions } from "./CommentSortingDropdown.helpers";

interface ICommentSortingDropdownProps {
  value?: string;
  onChange: (value: string) => void;
}

export const CommentSortingDropdown: React.FC<ICommentSortingDropdownProps> = ({
  value,
  onChange,
}) => {
  const { options } = useCommentSortingOptions();

  return (
    <SelectStyled
      _size="sm"
      value={value}
      onChange={onChange}
      InputVariation={InputWhite}
      options={options}
      wrapperClassName={s.bbCommentSortingDropdown}
    />
  );
};
