import { useEffect } from "react";
import { debounce } from "@bbdevcrew/bb_ui_kit_fe";

export const DEFAULT_RESIZE_DEBOUNCE_TIME = 100;
export const OFFSET_UPDATE_DEBOUNCE_TIME = 300;
export const MODAL_TRANSITION_DURATION = 1000;

export const useWindowResizeListener = (
  listener: () => () => void,
  debounceTime = DEFAULT_RESIZE_DEBOUNCE_TIME,
) => {
  useEffect(() => {
    const debouncedListener = debounce(listener(), debounceTime);
    const resizeListener = () => (listener(), debouncedListener());
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [listener, debounceTime]);
};
