import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "antd";
import { CommentsFilter } from "@bbdevcrew/bb_ui_kit_fe";

import {
  commentTagsSelector,
  getCommentTagsProcessingSelector,
  getCommentTagsSuccessfulSelector,
  getCommentTagsFailedSelector,
} from "@store/commentTags/selectors";

import { getCommentTagsAction } from "@store/commentTags/actions";

import s from "./../../WorkflowForm.module.less";

import { IFormComponent } from "@components/control-panel/workflows/types";

export const CommentTagsTrigger: React.FC<IFormComponent<string[]>> = ({ value, onChange }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation(undefined, { keyPrefix: "components:filters:comment_tags" });

  const commentTags = useSelector(commentTagsSelector);
  const commentTagsFetching = useSelector(getCommentTagsProcessingSelector);
  const commentTagsFetched = useSelector(getCommentTagsSuccessfulSelector);
  const commentTagsFetchFailed = useSelector(getCommentTagsFailedSelector);

  const getCommentTags = useCallback(() => dispatch(getCommentTagsAction()), [dispatch]);

  return (
    <Form form={form} initialValues={{ comment_tags: value }}>
      <span className={s.bbWorkflowItemFormTagLabel}>{t("label")}</span>
      <CommentsFilter
        customprops={{
          form,
          name: "comment_tags",
          disableExclusionOptions: true,
        }}
        value={value}
        options={commentTags}
        commentTagsFetching={commentTagsFetching}
        commentTagsFetchFailed={commentTagsFetchFailed}
        commentTagsFetched={commentTagsFetched}
        commentTags={commentTags}
        getCommentTags={getCommentTags}
        onChange={val => onChange?.(val as string[])}
        placeholder={t("placeholder")}
      />
    </Form>
  );
};
