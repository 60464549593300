import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { useCallback, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Form, Grid } from "antd";
import PostCreationForm from ".";
import { Modal, addToast } from "@bbdevcrew/bb_ui_kit_fe";
import PostCreationContextWrapper from "./context/ContextWrapper";

import {
  createdPostPublishingSelector,
  scheduledPostPublishingSelector,
  createdPostPublishingDraftSelector,
  updatedPostPublishingDraftSelector,
  creatingPostPublishingFailedSelector,
  updatedPostPublishingSelector,
  scheduledUpdatedPostSelector,
  updatingPostPublishingSelectorFailure,
  postPublishingFailedMessageSelector,
  deletingPostSelectorSuccessful,
  deletingPostFailedMessageSelector,
} from "@store/publishings/selectors";

import {
  clearUrlPreviewsAction,
  createPostPublishingAction,
  deletePostPublishingAction,
  updatePostPublishingAction,
} from "@store/publishings/actions";

import s from "./index.module.less";

import { IEditPublishingPostPayload, ICreatePublishingPayload } from "@store/publishings/types";
import { IPostCreationFormType } from "./helpers/types";
import CalendarContext from "../calendar/context/CalendarContext";
import { deleteSearchParam } from "../calendar/post/helpers";
import classNames from "classnames";

export function PostCreationModal() {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<IPostCreationFormType>();
  const { isPostModalOpen, setIsPostModalOpen, editingPost, setEditingPost, setDaySelected } =
    useContext(CalendarContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = screens.md === false;

  const postPublished = useSelector(createdPostPublishingSelector);
  const postScheduled = useSelector(scheduledPostPublishingSelector);
  const postDraftCreated = useSelector(createdPostPublishingDraftSelector);
  const failedMessage = useSelector(postPublishingFailedMessageSelector);
  const postPublishingFailed = useSelector(creatingPostPublishingFailedSelector);

  const isEditMode = isPostModalOpen && !!editingPost;
  const editPostPublished = useSelector(updatedPostPublishingSelector);
  const editPostScheduled = useSelector(scheduledUpdatedPostSelector);
  const postDraftUpdated = useSelector(updatedPostPublishingDraftSelector);
  const editFailedMessage = useSelector(postPublishingFailedMessageSelector);
  const editPostPublishingFailed = useSelector(updatingPostPublishingSelectorFailure);

  const postDeleted = useSelector(deletingPostSelectorSuccessful);
  const deleteFailedMessage = useSelector(deletingPostFailedMessageSelector);

  const title = isEditMode
    ? t("components:publish:postCreationModal:editTitle")
    : t("components:publish:postCreationModal:title");

  const clearUrlPreviews = useCallback(() => dispatch(clearUrlPreviewsAction()), [dispatch]);

  const editPost = useCallback(
    (data: IEditPublishingPostPayload) => dispatch(updatePostPublishingAction(data)),
    [dispatch],
  );

  const createPost = useCallback(
    (data: ICreatePublishingPayload) => dispatch(createPostPublishingAction(data)),
    [dispatch],
  );

  useEffect(() => {
    if (postPublishingFailed && failedMessage) {
      addToast({
        type: "danger_accent",
        title: failedMessage,
      });
    }
  }, [postPublishingFailed, failedMessage]);

  useEffect(() => {
    if (editPostPublishingFailed && editFailedMessage) {
      addToast({
        type: "danger_accent",
        title: editFailedMessage,
      });
    }
  }, [editPostPublishingFailed, editFailedMessage]);

  useEffect(() => {
    if (deleteFailedMessage) {
      addToast({
        type: "danger_accent",
        title: deleteFailedMessage,
      });
    }
  }, [deleteFailedMessage]);

  const _onCancel = useCallback(() => {
    setIsPostModalOpen(false);
    setDaySelected(undefined);

    form.resetFields();
    clearUrlPreviews();

    if (isEditMode) setEditingPost(null);
    // eslint-disable-next-line
  }, [isEditMode]);

  useEffect(() => {
    if (postDeleted) {
      addToast({
        type: "success_accent",
        title: t("components:publish:postCreationModal:delete:successMessage"),
      });
    }

    _onCancel();
    // eslint-disable-next-line
  }, [postDeleted]);

  useEffect(() => {
    if (postPublished || editPostPublished) {
      addToast({
        type: "success_accent",
        title: t("components:publish:postCreationModal:toastMessages:published:title"),
        message: t(
          "components:publish:postCreationModal:toastMessages:published:subtitle", // eslint-disable-line
        ),
      });
    } else if (postDraftCreated || postDraftUpdated) {
      addToast({
        type: "success_accent",
        title: t("components:publish:postCreationModal:toastMessages:draft"),
      });
    } else if (postScheduled || editPostScheduled) {
      addToast({
        type: "success_accent",
        title: t("components:publish:postCreationModal:toastMessages:schedule"),
      });
    }

    _onCancel();
    // eslint-disable-next-line
  }, [
    postPublished,
    postScheduled,
    postDraftCreated,
    postDraftUpdated,
    editPostPublished,
    editPostScheduled,
  ]);

  const onModalCancel = () => {
    _onCancel();
    deleteSearchParam("publication_id", searchParams, setSearchParams);
  };

  const publishPost = (data: ICreatePublishingPayload) => {
    const { assets, scheduled_at, is_draft, initial_notes } = data;

    if (isEditMode) {
      editPost({
        id: editingPost.id,
        asset: assets[0],
        scheduled_at,
        is_draft,
        publishing_type: assets[0].publishing_type,
      });
    } else {
      createPost({
        assets,
        is_draft,
        scheduled_at,
        initial_notes,
      });
    }
  };

  const handleDeletePost = (id: string) => dispatch(deletePostPublishingAction(id));

  return (
    <Modal
      centered
      responsive
      hideFooter
      hideHeader={!isMobile}
      onCancel={onModalCancel}
      open={isPostModalOpen}
      title={title}
      classes={{
        wrapper: classNames(s.bbPostCreationModalWrapper, {
          [s.bbPostCreationModalWrapperMobile]: isMobile,
        }),
        root: classNames(s.bbPostCreationModal, {
          [s.bbPostCreationModalMobile]: isMobile,
        }),
        modal: classNames(s.bbPostCreationModalContent, {
          [s.bbPostCreationModalContentMobile]: isMobile,
        }),
        body: s.bbPostCreationModalBody,
      }}
    >
      <PostCreationContextWrapper>
        <PostCreationForm onFinish={publishPost} onDelete={handleDeletePost} form={form} />
      </PostCreationContextWrapper>
    </Modal>
  );
}
