import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { postDashboardAction } from "@store/dashboard/actions";

import { getDashboardSelectorPending, getDashboardSelector } from "@store/dashboard/selectors";
import { filterDataSelector } from "@store/filters/selectors";

import { IDashboardPayload } from "@store/dashboard/types";
import { DashboardComponentType } from "@store/modules/types";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

export const useDashboardData = (
  components: DashboardComponentType[],
  customFilters?: IFilters,
) => {
  const dispatch = useDispatch();
  const data = useSelector(getDashboardSelector);
  const isFetching = useSelector(getDashboardSelectorPending);
  const filters = useSelector(filterDataSelector);

  const getDashboardData = useCallback(
    (payload: IDashboardPayload) => dispatch(postDashboardAction(payload)),
    [dispatch],
  );

  const requestFilters = customFilters || filters;

  useEffect(() => {
    getDashboardData({ filters: requestFilters, components });
  }, [getDashboardData, requestFilters, components]);

  return { data, isFetching };
};
