import { createReducer, ActionType } from "typesafe-actions";
import { IShortenedSearchesState } from "./types";

import { getShortenedSearchesSuccessAction, postShortenedSearchesSuccessAction } from "./actions";

import {
  GET_SHORTENED_SEARCHES,
  GET_SHORTENED_SEARCHES_SUCCESS,
  GET_SHORTENED_SEARCHES_FAILURE,
  POST_SHORTENED_SEARCHES,
  POST_SHORTENED_SEARCHES_SUCCESS,
  POST_SHORTENED_SEARCHES_FAILURE,
  RESET_SHORTENED_SEARCHES,
} from "./actionTypes";
import { IFilters } from "@bbdevcrew/bb_ui_kit_fe";

const initialState = {
  filters: {} as IFilters,
  id: "",
  explorer_link: "",
  is_client_switched: false,
  start_day: "",
  end_day: "",
  fetchingGetShortenedSearches: false,
  fetchedGetShortenedSearches: false,
  fetchedGetShortenedSearchesFail: false,
  fetchingPostShortenedSearches: false,
  fetchedPostShortenedSearches: false,
  fetchedPostShortenedSearchesFail: false,
};

export const shortenedSearchesReducer = createReducer<IShortenedSearchesState>(initialState, {
  [GET_SHORTENED_SEARCHES]: (state: IShortenedSearchesState) => ({
    ...state,
    fetchingGetShortenedSearches: true,
    fetchedGetShortenedSearches: false,
    fetchedGetShortenedSearchesFail: false,
  }),
  [GET_SHORTENED_SEARCHES_SUCCESS]: (
    state: IShortenedSearchesState,
    action: ActionType<typeof getShortenedSearchesSuccessAction>,
  ) => ({
    ...state,
    id: action.payload.id,
    filters: action.payload.filters,
    is_client_switched: action.payload.is_client_switched,
    start_day: action.payload.start_day,
    end_day: action.payload.end_day,
    fetchingGetShortenedSearches: false,
    fetchedGetShortenedSearches: true,
    fetchedGetShortenedSearchesFail: false,
  }),
  [GET_SHORTENED_SEARCHES_FAILURE]: (state: IShortenedSearchesState) => ({
    ...state,
    fetchingGetShortenedSearches: false,
    fetchedGetShortenedSearches: false,
    fetchedGetShortenedSearchesFail: true,
  }),
  // POST
  [POST_SHORTENED_SEARCHES]: (state: IShortenedSearchesState) => ({
    ...state,
    fetchingPostShortenedSearches: true,
    fetchedPostShortenedSearches: false,
    fetchedPostShortenedSearchesFail: false,
  }),
  [POST_SHORTENED_SEARCHES_SUCCESS]: (
    state: IShortenedSearchesState,
    action: ActionType<typeof postShortenedSearchesSuccessAction>,
  ) => {
    const { id, explorer_link } = action.payload;
    return {
      ...state,
      id,
      explorer_link,
      fetchingPostShortenedSearches: false,
      fetchedPostShortenedSearches: true,
      fetchedPostShortenedSearchesFail: false,
    };
  },
  [POST_SHORTENED_SEARCHES_FAILURE]: (state: IShortenedSearchesState) => ({
    ...state,
    fetchingPostShortenedSearches: false,
    fetchedPostShortenedSearches: false,
    fetchedPostShortenedSearchFailes: true,
  }),
  [RESET_SHORTENED_SEARCHES]: (state: IShortenedSearchesState) => ({
    ...state,
    fetchingPostShortenedSearches: false,
    fetchedPostShortenedSearches: false,
    fetchedPostShortenedSearchFailes: false,
    fetchingGetShortenedSearches: false,
    fetchedGetShortenedSearches: false,
    fetchedGetShortenedSearchesFail: false,
    id: "",
    explorer_link: "",
    is_client_switched: false,
    filter: {} as IFilters,
  }),
});
