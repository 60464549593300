import React from "react";
import classNames from "classnames";

import { Textarea, ITextareaProps } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ExpandableTextInput.module.less";

const cn = classNames.bind(s);

export const ExpandableTextInput: React.FC<ITextareaProps> = ({ className, ...props }) => (
  <Textarea {...props} autoSize className={cn(s.bbExpandableTextInput, className)} rows={1} />
);
