import React, { useState } from "react";
import classNames from "classnames";

import { Grid } from "antd";
import { Accordion } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./OptionsPanel.module.less";

import { IOptionsPanelProps } from "./OptionsPanel.types";

export const OptionsPanel: React.FC<IOptionsPanelProps> = ({
  defaultOpenOnMobile,
  title,
  titleElement,
  children,
  className,
}) => {
  const screens = Grid.useBreakpoint();
  const [open, setOpen] = useState(defaultOpenOnMobile || false);

  const isMobile = screens.md === false;

  if (isMobile) {
    return (
      <Accordion
        title={titleElement || title}
        type="white"
        body={children}
        collapsed={!open}
        setCollapsed={() => setOpen(prevState => !prevState)}
        className={classNames(s.bbOptionsPanel, className, s.bbOptionsPanelMobile, {
          [s.bbOptionsPanelMobileOpen]: open,
        })}
      />
    );
  }

  return (
    <div className={classNames(s.bbOptionsPanel, className)}>
      <div className={s.bbOptionsPanelTitle}>{title}</div>
      <div className={s.bbOptionsPanelBody}>{children}</div>
    </div>
  );
};
