import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./SanitizedInputValidation.module.less";

import { WarningIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const SanitizedInputRegex = /^[a-zA-Z0-9\s\-]*$/;

const SanitizedInputValidation: FC = () => {
  const { t } = useTranslation();

  return (
    <span className={s.bbValidationError}>
      <WarningIcon />
      {t("generic:sanitizedInputValidationError")}
    </span>
  );
};

export default {
  pattern: SanitizedInputRegex,
  message: <SanitizedInputValidation />,
};
