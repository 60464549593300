import React from "react";
import { useTranslation } from "react-i18next";

import { ITagCountTooltipProps } from "./TagCountTooltip.types";

export const TagCountTooltip: React.FC<ITagCountTooltipProps> = ({ count }) => {
  const { t } = useTranslation();
  return (
    <>
      {count.users > 0 && (
        <>
          {t(`components:publish:postCreationModal:mediaTags:count:users`, { count: count.users })}
          <br />
        </>
      )}
      {count.collaborators > 0 && (
        <>
          {t(`components:publish:postCreationModal:mediaTags:count:collaborators`, {
            count: count.collaborators,
          })}
          <br />
        </>
      )}
      {count.products > 0 && (
        <>
          {t(`components:publish:postCreationModal:mediaTags:count:products`, {
            count: count.products,
          })}
        </>
      )}
    </>
  );
};
