import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import * as actions from "./actions";

import { RootState } from "..";
import { controlPanel } from "@utils/paths";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getAuthAPIHeaders } from "@utils/headers";
import { ControlPanelActionsType, IControlPanelState } from "./types";

export const getNewsEpic = (
  action$: Observable<ControlPanelActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getNewsAction)),
    switchMap(() => {
      return ajax
        .get<IControlPanelState["news"]>(`${controlPanel}/news`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => actions.getNewsSuccessAction(data)),
          catchError(e => handleError(e, actions.getNewsFailureAction)),
        );
    }),
  );
