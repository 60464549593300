import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { IAIPromptsResponse, AiPromptsActions } from "./types";
import { IAIPrompt } from "@bbdevcrew/bb_ui_kit_fe";
import { RootState } from "../";
import {
  createPromptSuccessAction,
  createPromptFailureAction,
  updatePromptSuccessAction,
  updatePromptFailureAction,
  getPromptsSuccessAction,
  getPromptsFailureAction,
  deletePromptSuccessAction,
  deletePromptFailureAction,
  setPromptAsDefaultFailureAction,
  setPromptAsDefaultSuccessAction,
  getAIPromptSuggestionSuccessAction,
  getAIPromptSuggestionFailureAction,
} from "./actions";
import {
  CREATE_AI_PROMPT,
  UPDATE_AI_PROMPT,
  DELETE_AI_PROMPT,
  GET_AI_PROMPTS,
  SET_AI_PROMPT_AS_DEFAULT,
  GET_PROMPT_AI_SUGGESTION,
} from "./actionTypes";

import { promptTemplates, promptTemplatesList, promptComment } from "@utils/paths";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getHeaders } from "@utils/headers";

const normalizeDataForPutRequest = (payload: IAIPrompt): IAIPrompt => {
  return {
    ...payload,
    id: undefined,
    brand_name: payload.brand_name || "",
    brand_industry: payload.brand_industry || "",
    voice_tone: payload.voice_tone || "",
    language: payload.language || "",
    additional_data: payload.additional_data || "",
  };
};

export const createAIPrompt = (
  action$: Observable<AiPromptsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(CREATE_AI_PROMPT)),
    switchMap(a => {
      return ajax
        .post<IAIPrompt>(
          `${promptTemplates}`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => createPromptSuccessAction(data)),
          catchError(e => handleError(e, createPromptFailureAction)),
        );
    }),
  );

export const updateAIPrompt = (
  action$: Observable<AiPromptsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UPDATE_AI_PROMPT)),
    switchMap(a => {
      return ajax
        .put<IAIPrompt>(
          `${promptTemplates}/${a.payload.id}`,
          normalizeDataForPutRequest(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => updatePromptSuccessAction(data)),
          catchError(e => handleError(e, updatePromptFailureAction)),
        );
    }),
  );

export const getAIPrompts = (
  action$: Observable<AiPromptsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_AI_PROMPTS)),
    switchMap(a => {
      return ajax
        .post<IAIPromptsResponse>(
          `${promptTemplatesList}`,
          {
            query: a?.payload?.query,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => {
            return getPromptsSuccessAction(data);
          }),
          catchError(e => handleError(e, getPromptsFailureAction)),
        );
    }),
  );

export const deleteAIPrompt = (
  action$: Observable<AiPromptsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_AI_PROMPT)),
    switchMap(a => {
      return ajax
        .delete<IAIPrompt>(
          `${promptTemplates}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deletePromptSuccessAction(a.payload)),
          catchError(e => handleError(e, deletePromptFailureAction)),
        );
    }),
  );

export const setPromptAsDefault = (
  action$: Observable<AiPromptsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(SET_AI_PROMPT_AS_DEFAULT)),
    switchMap(a => {
      return ajax
        .patch<IAIPrompt>(
          `${promptTemplates}/${a.payload}/default`,
          {
            id: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => setPromptAsDefaultSuccessAction(a.payload)),
          catchError(e => handleError(e, setPromptAsDefaultFailureAction)),
        );
    }),
  );

export const promptAISuggestion = (
  action$: Observable<AiPromptsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_PROMPT_AI_SUGGESTION)),
    switchMap(a => {
      let url = `${promptComment}/${a.payload.comment_id}/ai-suggestion`;

      if (a.payload.prompt_id) url += `?prompt_id=${a.payload.prompt_id}`;

      return ajax
        .get<{ text: string }>(
          url,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getAIPromptSuggestionSuccessAction(data.text)),
          catchError(e => handleError(e, getAIPromptSuggestionFailureAction)),
        );
    }),
  );
