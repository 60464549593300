import { RootState } from "..";

export const fromMe = (state: RootState) => state.me;

export const meSelector = (state: RootState) => fromMe(state).data;
export const meSuccessfulSelector = (state: RootState) => fromMe(state).fetchedGetMe;
export const meLoadingSelector = (state: RootState) => fromMe(state).fetchingGetMe;

export const patchMeSuccessfulSelector = (state: RootState) => fromMe(state).fetchedPatchMe;
export const patchMeFailedSelector = (state: RootState) => fromMe(state).fetchedPatchMeFail;

export const clientDataSelector = (state: RootState) => fromMe(state).data?.client_data;

export const staticDataSelector = (state: RootState) => fromMe(state).data?.static_data;

export const compareTypesSelector = (state: RootState) =>
  fromMe(state).data?.static_data.compare_types;

export const patchMeClientSuccessfulSelector = (state: RootState) =>
  fromMe(state).fetchedPatchMeClient;

export const patchMeClientFetchingSelector = (state: RootState) =>
  fromMe(state).fetchingPatchMeClient;

export const patchMeClientFailureSelector = (state: RootState) =>
  fromMe(state).fetchedPatchMeClientFail;

export const postMeEmailSuccessfulSelector = (state: RootState) => fromMe(state).fetchedPostMeEmail;
export const postMeEmailFailureSelector = (state: RootState) =>
  fromMe(state).fetchedPostMeEmailFail;

export const patchMeEmailSuccessfulSelector = (state: RootState) =>
  fromMe(state).fetchedPatchMeEmail;
export const patchMeEmailFailureSelector = (state: RootState) =>
  fromMe(state).fetchedPatchMeEmailFail;

export const postMePasswordSuccessfulSelector = (state: RootState) =>
  fromMe(state).fetchedPostMePassword;
export const postMePasswordFailureSelector = (state: RootState) =>
  fromMe(state).fetchedPostMePasswordFail;

export const deleteMeAvatarSuccessfulSelector = (state: RootState) =>
  fromMe(state).fetchedDeleteMeAvatar;
export const deleteMeAvatarFailureSelector = (state: RootState) =>
  fromMe(state).fetchedDeleteMeAvatarFail;

export const canManageBBTagsSelector = (state: RootState) => fromMe(state).data?.can_manage_bb_tags;

export const genAiTermsAcceptedSelector = (state: RootState) =>
  fromMe(state).data?.client?.chat_enabled;

// App selectors
export const insightsAppFeature = (state: RootState) => {
  return fromMe(state).data?.features?.find(({ id }) => id === "insights");
};

export const careAppFeature = (state: RootState) => {
  return fromMe(state).data?.features?.find(({ id }) => id === "care");
};

export const moderationAppFeature = (state: RootState) => {
  return fromMe(state).data?.features?.find(({ id }) => id === "moderation");
};

export const publishAppFeature = (state: RootState) => {
  return fromMe(state).data?.features?.find(({ id }) => id === "publish");
};

export const managedAppFeature = (state: RootState) => {
  return fromMe(state).data?.features?.find(({ id }) => id === "managed");
};
