import React from "react";
import { TooltipProps } from "antd";
import { TFunction } from "react-i18next";

import s from "./PublishingPost.module.less";

import {
  getEditTooltipConfig,
  TOOLTIP_ERROR_WIDTH,
  TOOLTIP_HIDDEN_CONFIG,
} from "../helpers/tooltips";
import { IPublishingPost } from "@store/publishings/types";

export const defaultPDFDocOptions = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export const isPostBeingPublished = (post: IPublishingPost) => post.publishing_status === "pending";

export const isPostPublished = (post: IPublishingPost) => post.publishing_status === "published";

export const isPostDraft = (post: IPublishingPost) => post.publishing_status === "draft";

export const isPostEditable = (post: IPublishingPost) => post.is_editable || isPostBeingPublished;

export const postHasImage = (post: IPublishingPost) => !!post.picture || !!post.attachments.length;

export const postHasError = (post: IPublishingPost) =>
  !!post.publishing_error && post.publishing_status === "failed";

export const getPublishingPostTooltipProps = (
  post: IPublishingPost,
  t: TFunction,
): TooltipProps => {
  const errorTooltip = (
    <div className={s.bbErrorTooltip}>
      {t("components:publish:calendar:genericError")}
      <div className={s.bbTooltipDivider}></div>
      <div className={s.bbErrorMessage}>{post?.publishing_error?.message}</div>
    </div>
  );

  if (post?.publishing_error?.message)
    return {
      title: errorTooltip,
      overlayInnerStyle: { width: TOOLTIP_ERROR_WIDTH },
    };

  if (isPostPublished(post))
    return {
      title: t("components:comments:actions:showPost"),
    };

  if (isPostEditable(post)) return getEditTooltipConfig(t);

  return TOOLTIP_HIDDEN_CONFIG;
};

export const deleteSearchParam = (
  paramKey: string,
  searchParams: URLSearchParams,
  setSearchParams: (params: URLSearchParams) => void,
) => {
  const updatedSearchParams = new URLSearchParams(searchParams);
  updatedSearchParams.delete(paramKey);
  setSearchParams(updatedSearchParams);
};
